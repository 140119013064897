import React, { useState, useEffect } from "react";
import { Rating, Form } from "semantic-ui-react";

const SafetyOptions = ({ options, result }) => {
  const [radio, setRadio] = useState(null);
  const [rating, setRating] = useState(null);
  const [selection, setSelection] = useState(null);

  useEffect(() => {
    if (result) {
      if (result.Options === "No,Yes" || result.Options === "Low,OK") {
        setRadio(result.Value);
      } else if (result.Options === "0,1,2,3,4") {
        setRating(result.Value);
      } else if (result.Options === ">300,-300,-200,-100,C") {
        setSelection(result.Value);
      }
    }
  }, [result]);

  if (options === "0,1,2,3,4") {
    return (
      <Rating
        disabled={true}
        onRate={(e, { rating }) => setRating(rating)}
        size="huge"
        clearable
        icon="star"
        maxRating={5}
        rating={rating}
      />
    );
  } else if (options === "No,Yes") {
    return (
      <Form>
        <Form.Group style={{ marginBottom: 0 }} inline>
          <Form.Radio
            readOnly={true}
            onChange={() => setRadio("No")}
            label="No"
            checked={radio === "No"}
          />
          <Form.Radio
            readOnly={true}
            onChange={() => setRadio("Yes")}
            label="Yes"
            checked={radio === "Yes"}
          />
        </Form.Group>
      </Form>
    );
  } else if (options === "Low,OK") {
    return (
      <Form>
        <Form.Group style={{ marginBottom: 0 }} inline>
          <Form.Radio
            readOnly={true}
            onChange={() => setRadio("Low")}
            label="Low"
            checked={radio === "Low"}
          />
          <Form.Radio
            readOnly={true}
            onChange={() => setRadio("OK")}
            label="OK"
            checked={radio === "OK"}
          />
        </Form.Group>
      </Form>
    );
  } else if (options === ">300,-300,-200,-100,C") {
    return (
      <Form>
        <Form.Dropdown
          // className="readonly"
          readOnly
          // disabled={true}
          style={{ width: 110 }}
          //   width={110}
          selectOnBlur={false}
          selection
          compact
          // onChange={(e, { value }) => setSelection(value)}
          value={selection}
          options={[
            { key: ">300", text: ">300", value: ">300" },
            { key: "-300", text: "-300", value: "-300" },
            { key: "-200", text: "-200", value: "-200" },
            { key: "-100", text: "-100", value: "-100" },
            { key: "C", text: "Current", value: "C" },
          ]}
        />
      </Form>
    );
  } else {
    return <>{options}</>;
  }
};

export default SafetyOptions;
