import numeral from "numeral";
const isNumeric = (num) => {
  return !isNaN(num);
};

export const columnDefs = [
  {
    headerName: "Leadership Incentive",
    children: [
      {
        headerName: "Year",
        field: "year",
        // pinned: "left",
        // minWidth: 80,
        // maxWidth: 80,
      },
      {
        headerName: "Quarter",
        field: "quarter",
        // pinned: "left",
        // minWidth: 80,
        // maxWidth: 80,
      },
      {
        headerName: "Team Member",
        field: "empfullname",
        sortable: true,
        // pinned: "left",
        // minWidth: 80,
        // maxWidth: 80,
      },
      {
        headerName: "Division",
        field: "division",
        sortable: true,
        // pinned: "left",
        // minWidth: 80,
        // maxWidth: 80,
      },
      {
        headerName: "Incentive %",
        field: "IncentivePercent",
        editable: true,
        valueFormatter: (params) => {
          return numeral(params.value).format("0.0000");
        },
        valueParser: (params) => {
          const { newValue, oldValue } = params;
          if (!newValue) {
            return 0;
          } else if (isNumeric(parseFloat(newValue))) {
            return parseFloat(newValue);
          } else {
            return oldValue;
          }
        },
        sortable: true,
        // pinned: "left",
        // minWidth: 80,
        // maxWidth: 80,
      },
    ],
  },
];
