import React, { useState } from "react";
import "./SurveyQuestions.scss";
import { Table, Grid, Form, Segment, Button } from "semantic-ui-react";
import SurveyQuestion from "./SurveyQuestion/SurveyQuestion";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";

const SurveyQuestions = ({ questions, dispatch, info, kpiEventID, type }) => {
  console.log(info);
  const disabled =
    questions.find((question) => question.value) === undefined ? true : false;

  const [comments, setComments] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    Axios.post(`${API_ROOT}/survey/complete`, {
      kpiEventID,
      comments,
      projectID: info.projectid,
      sourceSystem: type,
    })
      .then((res) => {
        setLoading(false);
        dispatch({ type: "SET_COMPLETE" });
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Grid className="SurveyQuestions">
      <Grid.Row>
        <Grid.Column
          style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
          width={16}
        >
          {Array.isArray(questions) && questions.length ? (
            <>
              <Table unstackable>
                <Table.Body>
                  {questions.map((question) => {
                    return (
                      <SurveyQuestion
                        kpiEventID={kpiEventID}
                        key={question.id}
                        question={question}
                        dispatch={dispatch}
                      />
                    );
                  })}
                  <Table.Row>
                    <Table.Cell>
                      <Form>
                        <Form.TextArea
                          onChange={(e) => setComments(e.target.value)}
                          value={comments}
                          rows="4"
                          placeholder="Comments"
                        />
                      </Form>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Segment textAlign="center" basic>
                <Button
                  onClick={handleSubmit}
                  loading={loading}
                  primary
                  disabled={disabled}
                >
                  Submit Survey
                </Button>
              </Segment>
            </>
          ) : null}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SurveyQuestions;
