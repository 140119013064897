import React, { useState } from "react";
import {
  Table,
  Label,
  Popup,
  Icon,
  Dropdown,
  Modal,
  Button,
  Header,
} from "semantic-ui-react";
import Moment from "moment";
import { API_ROOT } from "../../../../../api-config";
import Axios from "axios";

const InactiveSubContracts = (props) => {
  const [activateModalOpen, setActivateModalOpen] = useState(false);
  const [contractGUIDToActivate, setContractGUIDToActivate] = useState(null);
  // const [pdfLoading, setPdfLoading] = useState(false);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const openActivateContractModal = (guid) => {
    console.log(guid);
    setContractGUIDToActivate(guid);
    setActivateModalOpen(true);
  };

  const activateContract = () => {
    const guid = contractGUIDToActivate;
    Axios.post(`${API_ROOT}/api/contract/subcontractpending`, {
      params: {
        guid,
      },
    })
      .then((res) => {
        props.loadContracts(res);
        setActivateModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const previewPDF = (recipient, guid, companyID) => {
  //   setPdfLoading(true);

  //   Axios.get(`${API_ROOT}/contract/previewcontract`, {
  //     params: {
  //       recipient,
  //       guid,
  //       companyID
  //     },
  //     responseType: "blob"
  //   })
  //     .then(res => {
  //       console.log(res);
  //       setPdfLoading(false);
  //       const file = new Blob([res.data], { type: "application/pdf" });
  //       const fileURL = URL.createObjectURL(file);
  //       window.open(fileURL);
  //     })
  //     .catch(err => {
  //       setPdfLoading(false);
  //       console.log(err);
  //     });
  // };

  const { inactiveContracts, handleInclusionChange } = props;
  return (
    <React.Fragment>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="2" style={{ color: "rgb(254, 80, 0)" }}>
            Inactive Contracts
          </Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Array.isArray(inactiveContracts) && inactiveContracts.length ? (
          inactiveContracts[0].SubContractor.map((sub, index) => {
            return (
              <React.Fragment key={index}>
                {sub.SubContracts.map((contract) => {
                  return contract.displayType === "data" ? (
                    <Table.Row key={contract.GUID}>
                      <Table.Cell>
                        <Label>{contract.SubContractType}</Label>
                        <Popup
                          content="Reactivate Contract"
                          trigger={
                            <Icon
                              color="red"
                              link
                              name="linkify"
                              onClick={() =>
                                openActivateContractModal(contract.GUID)
                              }
                            />
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>{contract.CompanyName}</Table.Cell>
                      <Table.Cell textAlign="center">
                        <Popup
                          position="top center"
                          content="Upload Bid"
                          trigger={
                            <Icon
                              link
                              onClick={() =>
                                props.handleBidIconClick(contract.GUID)
                              }
                              name="cloud upload"
                              // size="large"
                            />
                          }
                        />
                        {contract.BidDate ? (
                          <Popup
                            position="top center"
                            content="Download Bid"
                            trigger={
                              <Icon
                                // size="large"
                                name="download"
                                link
                                onClick={() => props.downloadBid(contract.GUID)}
                              />
                            }
                          />
                        ) : null}
                      </Table.Cell>
                      <Table.Cell></Table.Cell>

                      <Table.Cell></Table.Cell>

                      <Table.Cell textAlign="center">
                        <Popup
                          content={"View Proposal"}
                          position="top center"
                          trigger={
                            <a
                              href={`/contract/?r=s&id=${contract.GUID}&c=${contract.CompanyID}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Icon
                                // size="large"
                                link
                                name="external alternate"
                              />
                            </a>
                          }
                        />
                        {/* <Popup
                          content="Generate proposal pdf"
                          position="top center"
                          trigger={
                            <Icon
                              link
                              loading={pdfLoading ? true : false}
                              onClick={() =>
                                previewPDF(
                                  "s",
                                  contract.GUID,
                                  contract.CompanyID
                                )
                              }
                              // size="large"
                              color="red"
                              name={pdfLoading ? "spinner" : "file pdf outline"}
                            />
                          }
                        /> */}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.SubWorkDays}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.Retention
                          ? contract.Retention.toFixed(2) + "%"
                          : "10%"}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {formatter.format(contract.Total || 0)}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {formatter.format(contract.Invoiced || 0)}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {formatter.format(contract.Received || 0)}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.SubBidDate
                          ? Moment(contract.BidDate).format("l")
                          : ""}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.SubContractSentDate
                          ? Moment(contract.SubContractSentDate).format("l")
                          : ""}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.SubApprovalDate
                          ? Moment(contract.ApprovalDate).format("l")
                          : ""}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.Inclusions[0].text ? (
                          <Dropdown
                            selection
                            selectOnNavigation={false}
                            selectOnBlur={false}
                            options={contract.Inclusions}
                            onChange={(event, data) =>
                              handleInclusionChange(event, data)
                            }
                          />
                        ) : null}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    <Table.Row
                      key={contract.GUID}
                      style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}
                    >
                      <Table.Cell colSpan="8"></Table.Cell>
                      <Table.Cell
                        style={{ color: "blue", fontWeight: "bold" }}
                        textAlign="center"
                      >
                        {formatter.format(contract.Total || 0)}
                      </Table.Cell>
                      <Table.Cell
                        style={{ color: "blue", fontWeight: "bold" }}
                        textAlign="center"
                      >
                        {formatter.format(contract.Invoiced || 0)}
                      </Table.Cell>
                      <Table.Cell
                        style={{ color: "blue", fontWeight: "bold" }}
                        textAlign="center"
                      >
                        {formatter.format(contract.Received || 0)}
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })
        ) : (
          <Table.Row style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}>
            <Table.Cell colSpan="15">No Inactive contracts</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      {/* Confirm activate contract modal */}
      <Modal
        basic
        size="tiny"
        open={activateModalOpen}
        onClose={() => setActivateModalOpen(false)}
      >
        <Header icon="linkify" content="Activate Contract" />
        <Modal.Content>
          <p>
            Are you sure you want to reactivate this contract? The contract's
            status will change to pending.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="red"
            inverted
            onClick={() => setActivateModalOpen(false)}
          >
            <Icon name="remove" /> No
          </Button>
          <Button color="green" inverted onClick={() => activateContract()}>
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};

export default InactiveSubContracts;
