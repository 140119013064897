import React, { Fragment, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
// import { useLocation, useHistory, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setIncentiveAnalysisMenuSelection,
  resetIncentiveAnalysisMenuSelection,
} from "../../../../actions/topMenu/incentiveAnalysisActions";

function generate_year_range(start, end) {
  var years = [];
  for (var year = start; year <= end; year++) {
    years.push(year);
  }
  return years;
}

const IncentiveAnalysisItems = (props) => {
  // const [dropdownValue, setDropdownValue] = useState("");
  const { year, quarter } = useSelector((state) => state.incentiveAnalysis);

  const dispatch = useDispatch();

  var my_years = generate_year_range(2020, year).sort((a, b) => b - a);
  const quarters = [4, 3, 2, 1];

  //   let my_years = [2019, 2018, 2020, 2014].sort((a, b) => b - a);
  //   console.log(my_years);

  //   const options = my_years.map((y) =>
  //     quarters.map((q) => {
  //       return q === "divider" ? (
  //         my_years.length > 1 ? (
  //           <Dropdown.Divider key={`${q}${y}`} />
  //         ) : null
  //       ) : (
  //         <Dropdown.Item
  //           key={`${q}${y}`}
  //           value={`Q${q} ${y}`}
  //           text={`Q${q} ${y}`}
  //         />
  //       );
  //     })
  //   );

  const optionsArrays = my_years.map((y) =>
    quarters.map((q) => {
      // let value = `Q${q} ${y}`;
      return q === 5
        ? {
            key: `Q${q} ${y}`,
            // text: `Q${q} ${y}`,
            disabled: true,
            // value: JSON.stringify({ q, y }),
            value: "",
            content: <hr className="dropdown-hr" />,
            // className: "divider",
          }
        : {
            key: `Q${q} ${y}`,
            text: `Q${q} ${y}`,
            value: JSON.stringify({ q, y }),
          };
    })
  );

  const options = [].concat(...optionsArrays);

  // useEffect(() => {
  //   setDropdownValue(JSON.stringify({ q: quarter, y: year }));
  // }, []);

  const handleChange = (e, { value }) => {
    const parsedValue = JSON.parse(value);
    // setQuarter(parsedValue.q);
    // setYear(parsedValue.y);
    // setDropdownValue(value);
    dispatch(
      setIncentiveAnalysisMenuSelection({
        year: parsedValue.y,
        quarter: parsedValue.q,
      })
    );
  };

  useEffect(() => {
    dispatch(resetIncentiveAnalysisMenuSelection());
  }, []);

  return (
    <Fragment>
      <Dropdown
        scrolling
        text={`Showing Q${quarter} ${year}`}
        value={JSON.stringify({ q: quarter, y: year })}
        item
        options={options}
        onChange={handleChange}
      />
    </Fragment>
  );
};

export default IncentiveAnalysisItems;
