import React from "react";
import { Table, Message } from "semantic-ui-react";
import numeral from "numeral";
import moment from "moment";

const ResponseHeader = ({ selectedData }) => {
  return selectedData ? (
    <Table style={{ border: "none" }}>
      <Table.Body>
        <Table.Row>
          <Table.Cell style={{ padding: 0 }} width={10}>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Team Member</Table.HeaderCell>
                  <Table.Cell
                    style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}
                  >
                    {selectedData.n5representative}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Client</Table.HeaderCell>
                  <Table.Cell
                    style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}
                  >
                    {selectedData.clientdescription}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Project</Table.HeaderCell>
                  <Table.Cell
                    style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}
                  >
                    {selectedData.projectdescription}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.Cell
                    style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}
                  >
                    {selectedData.projecttype}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.Cell
                    style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}
                  >
                    {selectedData.longprojectdescription}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Invite Date</Table.HeaderCell>
                  <Table.Cell
                    style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}
                  >
                    {moment(selectedData.senddate).format("l")}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell style={{ borderBottom: "none" }}>
                    Response Date
                  </Table.HeaderCell>
                  <Table.Cell>
                    {moment(selectedData.ResponseDate).format("l")}
                  </Table.Cell>
                </Table.Row>
              </Table.Header>
            </Table>
          </Table.Cell>

          <Table.Cell
            verticalAlign="top"
            style={{ paddingLeft: 15, paddingTop: 0 }}
          >
            <Message color="orange" style={{ height: "100%" }}>
              <h1>{numeral(selectedData.percentScore).format("0.00 %")}</h1>
            </Message>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  ) : null;
};

export default ResponseHeader;
