import React, { useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import Axios from "axios";

import { API_ROOT } from "../../../../api-config";
import useIsMounted from "../../../../hooks/isMounted";

const getOptions = async () => {
  try {
    const options = await Axios.get(`${API_ROOT}/api/project/contacts`);
    return options.data.contacts || [];
  } catch (err) {
    console.error(Error(`Error fetching results list: ${err.message}`));
  }
};

const BillingContact = ({ projectID, saveValue, valueProp }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(valueProp);

  const isMounted = useIsMounted();

  useEffect(async () => {
    setIsFetching(true);
    const optionsResponse = await getOptions();

    if (isMounted.current && optionsResponse) {
      setOptions(optionsResponse);
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const handleChange = (e, { value }) => {
    setValue(value);
    saveValue(projectID, "billingContactID", value);
  };

  return (
    <Form.Dropdown
      autoComplete="off"
      width={16}
      label="Invoice Attention to this Person"
      clearable
      selectOnBlur={false}
      fluid
      selection
      multiple={false}
      search={true}
      options={options || []}
      value={value}
      placeholder=""
      onChange={handleChange}
      disabled={isFetching}
      loading={isFetching}
      onFocus={(e) => e.target.setAttribute("autocomplete", "nope")}
    />
  );
};

export default BillingContact;
