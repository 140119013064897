import {
  FETCH_PROJECTS_BEGIN,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILURE,
  SET_FORECAST_BEGIN,
  SET_FORECAST_SUCCESS,
  SET_FORECAST_FAILURE,
  SET_PROBABILITY_BEGIN,
  SET_PROBABILITY_SUCCESS,
  SET_PROBABILITY_FAILURE,
  SET_STAGETYPE_BEGIN,
  SET_STAGETYPE_SUCCESS,
  SET_STAGETYPE_FAILURE,
  SET_PROJECTINFO
} from "../actions/project/projectActions";

// import produce from 'immer';

const initialState = {
  projects: [],
  loading: false,
  error: null
};

export default function projectReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROJECTS_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_PROJECTS_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        projects: action.payload.projects
      };

    case FETCH_PROJECTS_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have items to display anymore, so set `projects` empty.
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        projects: []
      };

    case SET_FORECAST_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case SET_FORECAST_SUCCESS:
      const payload = JSON.parse(action.payload.project);
      return {
        ...state,
        projects: state.projects.map(proj => {
          if (proj.ProjectID === payload[0].ProjectID) {
            return payload[0];
          }

          return proj;
        })
      };

    case SET_FORECAST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case SET_PROBABILITY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case SET_PROBABILITY_SUCCESS:
      return {
        ...state,
        projects: state.projects.map(proj => {
          if (proj.ProjectID === action.payload.project.ProjectID) {
            return action.payload.project;
          }

          return proj;
        })
      };

    case SET_PROBABILITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case SET_STAGETYPE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case SET_STAGETYPE_SUCCESS:
      return {
        ...state,
        projects: state.projects.map(proj => {
          if (proj.ProjectID === action.payload.project.ProjectID) {
            return action.payload.project;
          }

          return proj;
        })
      };

    case SET_STAGETYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case SET_PROJECTINFO:
      return {
        ...state,
        projects: state.projects.map(proj => {
          if (proj.ProjectID === action.payload.ProjectID) {
            return action.payload;
          }

          return proj;
        })
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
