import React, { Component } from "react";
import { Tab } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Icon, Menu } from "semantic-ui-react";

import ProjectInfo from "./ProjectInfo/ProjectInfo";
import Notes from "./Notes/Notes";
import ClientContractTracking from "./ContractTracking/ClientContractTracking/ClientContractTracking";
import SubContractTracking from "./ContractTracking/SubContractTracking/SubContractTracking";
import Invoice from "./Invoice/Invoice";
import Tracking from "./Tracking/Tracking";

import VersionBanner from "../V2/VersionBanner";
import CurrentProject from "../../screens/projects/CurrentProject";

import InclusionsWrapper from "./Inclusions/InclusionsWrapper";

import "./Project.scss";

class Project extends Component {
  state = {
    activeIndex: 0,
  };

  componentDidMount() {
    this._isMounted = true;
    const values = queryString.parse(this.props.location.search);
    if (values.i && values.i >= 0 && values.i <= 6) {
      const activeIndex = parseInt(values.i);
      this.setState({ activeIndex });
    } else {
      this.setState({ activeIndex: 0 });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleTabChange = (e, { activeIndex }) => {
    if (this._isMounted) {
      this.setState({ activeIndex }, () => {
        this.props.history.replace(
          `${this.props.location.pathname}?i=${activeIndex}`
        );
      });
    }
  };

  getPanes() {
    const projectID = parseInt(this.props.match.params.id);
    return [
      {
        menuItem: "Project Info",
        render: () => (this._isMounted ? <ProjectInfo /> : null),
      },
      {
        menuItem: (
          <Menu.Item key="inclusions_v2">
            Inclusions{" "}
            {this.context.useV2() && <Icon name="fire" color="orange" />}
          </Menu.Item>
        ),
        render: () => <InclusionsWrapper />,
      },
      { menuItem: "Notes", render: () => <Notes /> },
      {
        menuItem: (
          <Menu.Item key="tracking_v2">
            Tracking
            {this.context.useV2() && <Icon name="fire" color="orange" />}
          </Menu.Item>
        ),
        render: () => <Tracking projectID={projectID} />,
      },
      {
        menuItem: (
          <Menu.Item key=" client_contract_v2">
            Client Contract
            {this.context.useV2() && <Icon name="fire" color="orange" />}
          </Menu.Item>
        ),
        render: () =>
          this._isMounted ? (
            <ClientContractTracking projectID={projectID} />
          ) : null,
      },
      {
        menuItem: "Sub Contract",
        render: () =>
          this._isMounted ? (
            <SubContractTracking projectID={projectID} />
          ) : null,
      },
      { menuItem: "Invoice", render: () => <Invoice projectID={projectID} /> },
    ];
  }

  render() {
    const { activeIndex } = this.state;

    // wait till we have the project loaded up first
    if (this.context.loading) return null;

    return (
      <>
        <VersionBanner />
        <Tab
          className="ProjectTabs"
          activeIndex={activeIndex}
          panes={this.getPanes()}
          onTabChange={this.handleTabChange}
        />
      </>
    );
  }
}

Project.contextType = CurrentProject;

export default withRouter(Project);
