import React from 'react'
import { isEmpty } from 'lodash'

const headers = {
  Scope: [''],
  Totals: ['Pre-Tax Total', 'Taxable Total', 'Tax', 'Grand Total'],
  Labor: ['Hrs', 'Rate', 'Cost', 'MU', 'Total'],
  Material: ['Cost', 'MU', 'Total'],
  Equipment: ['Cost', 'MU', 'Total'],
  Other: ['Cost', 'MU', 'Total'],
  Subcontractors: ['Cost', 'MU', 'Total', ''],
}

const money = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const TableHeader = () => {
  return (
    <thead>
      <tr>
        {Object.keys(headers).map((label, idx) => {
          const colspan = headers[label].length
          const style = idx === 0 ? { minWidth: '300px' } : {}
          return (
            <th
              className="center aligned"
              colSpan={colspan}
              key={label}
              style={style}
            >
              {label}
            </th>
          )
        })}
      </tr>
      <tr>
        {Object.keys(headers).map((label) =>
          headers[label].map((column, idx) => {
            let className = 'center aligned'
            if (idx === 0 && label !== 'Scope') className += ' border-left'
            return (
              <th className={className} key={column}>
                {column}
              </th>
            )
          }),
        )}
      </tr>
    </thead>
  )
}

export const CategoryHeader = ({ name, colspan, children }) => {
  return (
    <>
      <thead>
        <tr style={{ borderTop: '1px solid rgba(34,36,38,.15)' }}>
          <th
            colSpan={colspan}
            style={{
              backgroundColor: 'transparent',
              color: '#fe4e00',
              borderTop: '1px solid rgba(34, 36, 38, 0.1) !important',
            }}
          >
            {name}
          </th>
        </tr>
      </thead>
      {children}
    </>
  )
}

export const LoadingAndEmpty = ({ categories, loading }) => (
  <>
    {!loading && isEmpty(categories) && (
      <tbody>
        <tr>
          <td style={{ color: 'gray', fontWeight: 'bold', fontSize: '12px' }}>
            No inclusions
          </td>
          <td colSpan="21" style={{ borderLeft: 'none' }}></td>
        </tr>
      </tbody>
    )}
    {loading && (
      <tbody>
        <tr>
          <td style={{ color: 'gray', fontWeight: 'bold', fontSize: '12px' }}>
            Loading...
          </td>
          <td colSpan="21" style={{ borderLeft: 'none' }}></td>
        </tr>
      </tbody>
    )}
  </>
)

export const Cell = (props) => {
  const { as: Component = 'td', children, className, style } = props
  const css = className ? 'right aligned ' + className : 'right aligned'

  if (children === undefined || isNaN(children))
    return (
      <Component style={style} className={css}>
        {children}
      </Component>
    )

  return (
    <Component style={style} className={css}>
      {money.format(children)}
    </Component>
  )
}

export const Percent = (props) => {
  const { as: Component = 'td', children, className, style } = props
  return (
    <Component style={style} className={'right aligned ' + className}>
      {children
        ? children.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            style: 'percent',
          })
        : '0.00%'}
    </Component>
  )
}
