import React, { Fragment, useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import { useLocation, useHistory, withRouter } from "react-router-dom";
import moment from "moment";

function generate_year_range(start, end) {
  var years = [];
  for (var year = start; year <= end; year++) {
    years.push(year);
  }
  return years;
}

let date = moment();

const DashboardYearOnly = (props) => {
  // A custom hook that builds on useLocation to parse
  // the query string for you.
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let history = useHistory();
  let location = useLocation();
  const [dropdownValue, setDropdownValue] = useState("");
  const [year, setYear] = useState(parseInt(query.get("y")) || date.year());

  const yearEnd = moment().month() >= 6 ? moment().year() + 1 : moment().year();
  var my_years = generate_year_range(2020, yearEnd).sort((a, b) => b - a);

  const optionsArrays = my_years.map((y) => {
    return {
      key: y,
      text: y,
      value: y,
    };
  });

  //   const optionsArrays = my_years.map((y) =>
  //     quarters.map((q) => {
  //       // let value = `Q${q} ${y}`;
  //       return q === 5
  //         ? {
  //             key: `Q${q} ${y}`,
  //             // text: `Q${q} ${y}`,
  //             disabled: true,
  //             // value: JSON.stringify({ q, y }),
  //             value: "",
  //             content: <hr className="dropdown-hr" />,
  //             // className: "divider",
  //           }
  //         : {
  //             key: `Q${q} ${y}`,
  //             text: `Q${q} ${y}`,
  //             value: JSON.stringify({ q, y }),
  //           };
  //     })
  //   );

  const options = [].concat(...optionsArrays);

  useEffect(() => {
    setDropdownValue(year);
    // history.replace(`${location.pathname}?y=${year}&q=${quarter}`);
    // setQuarter(year);
    // setYear(quarter);
  }, []);

  useEffect(() => {
    setYear(query.get("y") || date.year());
    setDropdownValue(query.get("y") || date.year());
  }, [query.get("y"), query.get("d")]);

  useEffect(() => {
    history.replace(`${location.pathname}?y=${year}`);
  }, [year]);

  //   useEffect(() => {
  //     if (
  //       quarter &&
  //       year &&
  //       (quarter !== query.get("y") || year !== query.get("q"))
  //     ) {
  //       console.log(query.get("y"));
  //       history.replace(`${location.pathname}?y=${year}&q=${quarter}`);
  //     }
  //   }, []);

  const handleChange = (e, { value }) => {
    setYear(value);
    setDropdownValue(value);
    // history.replace(
    //   `${location.pathname}?y=${parsedValue.y}&q=${parsedValue.q}`
    // );
  };

  return (
    <Fragment>
      <Dropdown
        scrolling
        selectOnBlur={false}
        text={`Showing ${year}`}
        value={dropdownValue}
        item
        options={options}
        onChange={handleChange}
      />
      {/* <Dropdown.Menu>{options}</Dropdown.Menu>
      </Dropdown> */}
    </Fragment>
  );
};

export default withRouter(DashboardYearOnly);
