import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import logo from "./surveyfooter.jpg";
import { Image, Container } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../api-config";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Unsubscribe = () => {
  const [email, setEmail] = useState(null);
  let query = useQuery();

  useEffect(() => {
    setEmail(query.get("e"));
  }, []);

  useEffect(() => {
    if (email) {
      console.log(email);
      Axios.post(`${API_ROOT}/survey/unsubscribe`, {
        email,
      })
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }
  }, [email]);

  return (
    <Container style={{ textAlign: "center", paddingTop: 100 }}>
      <h1>You got it.</h1>
      <h5>{email} will receive no more survey invites from Nexus 5 Group.</h5>
      <p>
        In the meantime, please let us know if there is anything we can do to
        make your life more awesome!
      </p>

      <br />
      <br />
      <div style={{ width: "100%", textAlign: "center", paddingBottom: 60 }}>
        <Image src={logo} centered style={{ width: "50%", maxWidth: 350 }} />

        <p>
          Copyright © 2020 Nexus 5 Group, All rights reserved.
          <br />
          8500 Shawnee Mission Pkwy, Suite 130 | Merriam, KS 66202 |{" "}
          <a href="https://www.nexus5group.com">www.Nexus5Group.com</a>
        </p>
      </div>
    </Container>
  );
};

export default Unsubscribe;
