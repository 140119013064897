import React, { useEffect, useState, useContext } from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";

import axios from "../../../../../api/axios";
import useCurrentUser from "../../../../../hooks/useCurrentUser";
import CurrentProject from "../../../../../screens/projects/CurrentProject";

const SetToPendingModal = ({ guid }) => {
  const { project } = useContext(CurrentProject);
  const user = useCurrentUser();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleYesClick = () => {
    setLoading(true);
    axios
      .post(`/projects/${project.project_id}/contracts/${guid}/sync-acumatica`)
      .then((res) => {
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        alert(err);
      });
    setOpen(false);
  };

  return user.hasRole("Acumatica Errors") ? (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={
        <Icon
          title="Force re-sync project to Acumatica"
          link
          color="yellow"
          name="refresh"
        />
      }
    >
      <Header icon>
        <Icon name="warning" />
        Force re-sync project to Acumatica
      </Header>
      <Modal.Content>
        <p>
          This will force this project into acumatia. It might overwrite the
          current state of Acumatica. Are you sure you want to continue?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> No
        </Button>
        <Button
          disabled={loading}
          loading={loading}
          color="green"
          inverted
          onClick={handleYesClick}
        >
          <Icon name="checkmark" /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  ) : null;
};

export default SetToPendingModal;
