import React, { useEffect, useState } from "react";
// import MonthInput from "../../Browser/ProjectsTable/ForecastMonth/MonthInput/MonthInput";
import MonthInput from "./MonthInput/MonthInput";

// import "./ForecastMonth.scss";

const ForecastMonth = ({
  gridApi,
  projectID,
  month,
  rowData,
  setRowData,
  color,
}) => {
  const [project, setProject] = useState(
    rowData.find((obj) => obj.ProjectID === projectID)
  );

  useEffect(() => {
    setProject(rowData.find((obj) => obj.ProjectID === projectID));
  }, [rowData]);

  const month1 = month + 1;
  const month2 = month + 2;
  const month3 = month + 3;

  var monthsArr = [month1, month2, month3];

  return (
    <div className="ForecastMonth">
      {monthsArr.map((monthh) => (
        <MonthInput
          gridApi={gridApi}
          key={projectID + monthh}
          forecast={project[monthh]}
          color={color}
          projectID={projectID}
          monthProp={monthh}
          total={project.total}
          rowData={rowData}
          setRowData={setRowData}
        />
      ))}
    </div>
  );
};

// class ForecastMonth extends Component {
//   render() {
//     const {
//       gridApi,
//       projectID,
//       month,
//       rowData,
//       setRowData,
//       color,
//     } = this.props;
//     const [project, setProject] = useState(
//       rowData.find((obj) => obj.ProjectID === projectID)
//     );

//     useEffect(() => {
//       setProject(rowData.find((obj) => obj.ProjectID === projectID));
//     }, [rowData]);

//     const month1 = month + 1;
//     const month2 = month + 2;
//     const month3 = month + 3;

//     var monthsArr = [month1, month2, month3];

//     console.log(rowData);

//     return (
//       <div className="ForecastMonth">
//         {monthsArr.map((monthh) => (
//           <MonthInput
//             gridApi={gridApi}
//             key={projectID + monthh}
//             forecast={project[monthh]}
//             color={color}
//             projectID={projectID}
//             monthProp={monthh}
//             total={project.total}
//             rowData={rowData}
//             setRowData={setRowData}
//           />
//         ))}
//       </div>
//     );
//   }
// }

export default ForecastMonth;
