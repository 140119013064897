import Axios from "axios";
import React, { useState } from "react";
import { Confirm } from "semantic-ui-react";
import { API_ROOT } from "../../../api-config";

import "./StageType.scss";

const StageType = ({ projectID, stage, stageType, rowData, setRowData }) => {
  const [approvedConfirmOpen, setApprovedConfirmOpen] = useState(false);
  const [WIPConfirmOpen, setWIPConfirmOpen] = useState(false);

  const handleApprovedConfirm = () => {
    setStageType(projectID, "In Progress");
    setApprovedConfirmOpen(false);
    // setTimeout(() => props.loadProjectCounts(), 3000);
  };

  const handleWIPConfirm = () => {
    setStageType(projectID, "Completed");
    setWIPConfirmOpen(false);
    // setTimeout(() => props.loadProjectCounts(), 2000);
  };

  const setStageType = (projectID, stageType) => {
    Axios.post(`${API_ROOT}/api/project/stagetype`, {
      params: {
        projectID,
        stageType,
      },
    })
      .then((res) => {
        console.log(JSON.parse(res.data.project)[0]);

        if (res) {
          const project = JSON.parse(res.data.project)[0];
          let updatedRowData = rowData.map((item) => {
            if (item.ProjectID === projectID) {
              const updated = {
                ...item,
                ProjectStageAbbr: project.ProjectStageAbbr,
                StageType: project.StageType,
              };
              return updated; //gets everything that was already in item, and updates "done"
            }

            return item; // else return unmodified item
          });

          setRowData(updatedRowData); // set state to new object with updated list
        }
      })
      .catch((err) => alert(err));
  };

  if (stageType === "Approved") {
    return (
      <>
        <span
          className="span-project-stage-advance"
          onClick={() => setApprovedConfirmOpen(true)}
        >
          APRVD
        </span>
        <Confirm
          size="mini"
          open={approvedConfirmOpen}
          content="Are you sure you want to advance to WIP?"
          onCancel={() => setApprovedConfirmOpen(false)}
          onConfirm={() => handleApprovedConfirm()}
        />
      </>
    );
  } else if (stageType === "In Progress") {
    return (
      <>
        <span
          className="span-project-stage-advance"
          onClick={() => setWIPConfirmOpen(true)}
        >
          WIP
        </span>
        <Confirm
          size="mini"
          open={WIPConfirmOpen}
          content="Are you sure you want to advance to Completed?"
          onCancel={() => setWIPConfirmOpen(false)}
          onConfirm={() => handleWIPConfirm()}
        />
      </>
    );
  } else {
    return stage.substr(1);
  }
};

export default StageType;
