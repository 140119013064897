import React, { Fragment } from "react";
import { Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getQueryStringValue } from "../../../../util";

const ConditionApprovalMenuITems = () => {
  const type = getQueryStringValue("t");

  return (
    <Fragment>
      <Menu.Item active={type !== "o"} as={Link} to="/condition-approval">
        New Condition Template Approval
      </Menu.Item>
      <Menu.Item active={type === "o"} as={Link} to="/condition-approval?t=o">
        Condition Template Overwrite Approval
      </Menu.Item>
    </Fragment>
  );
};

export default ConditionApprovalMenuITems;
