import React from "react";
import { Table } from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import "./SubContractDetail.scss";
import { useDispatch } from "react-redux";
import { setInclusionMenuSelection } from "../../../../../../actions/project/inclusion/inclusionActions";

// Create our number formatter.
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const SubContractDetail = (props) => {
  // const [COQty, setCOQty] = useState(0);
  // const [COTotalsQty, setCOTotalsQty] = useState(0);

  const subContractDetail = props.subContractDetail
    ? props.subContractDetail
    : [];

  const { subContractDetailTotals } = props;

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (Array.isArray(subContractDetail) && subContractDetail.length) {
  //     setCOQty(subContractDetail[0].COQty ? subContractDetail[0].COQty : 0);
  //   }
  // }, [props.subContractDetail]);

  // useEffect(() => {
  //   if (subContractDetailTotals && subContractDetailTotals.COQty) {
  //     setCOTotalsQty(subContractDetailTotals.COQty);
  //   }
  // }, [props.subContractDetailTotals]);

  return (
    <div className="SubContractDetail" style={{ marginTop: 60 }}>
      <h1 style={{ color: "rgb(254, 80, 0)" }}>Sub Contract Detail</h1>
      <div>
        <Table
          unstackable
          style={{ minWidth: 1103, fontSize: 12 }}
          structured
          compact="very"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell
                textAlign="center"
                //   className="border-left"
              ></Table.HeaderCell>
              <Table.HeaderCell
                textAlign="center"
                //   className="border-left"
                colSpan={3}
              >
                Current
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="center"
                //   className="border-left"
                colSpan={2}
              >
                Pending
              </Table.HeaderCell>
              {/* {(COQty ? COQty : 0) > 0 ? (
                <Table.HeaderCell
                  className="border-left"
                  colSpan={COQty ? COQty : 0}
                  textAlign="center"
                >
                  Change Orders
                </Table.HeaderCell>
              ) : null} */}
            </Table.Row>
            <Table.Row style={{ fontSize: 12 }}>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell className="border-left">
                Subcontractor
              </Table.HeaderCell>
              <Table.HeaderCell className="border-left" textAlign="center">
                Base
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Approved Changes
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Adjusted (Current)
              </Table.HeaderCell>
              <Table.HeaderCell className="border-left" textAlign="center">
                Pending Changes
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Adj + Pending
              </Table.HeaderCell>
              {/* {(COQty ? COQty : 0) > 0
                ? [...Array(COQty ? COQty : 0)].map((e, i) => (
                    <Table.HeaderCell
                      className="border-left"
                      textAlign="center"
                      key={i}
                    >
                      CO {i + 1}
                    </Table.HeaderCell>
                  ))
                : null} */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Array.isArray(subContractDetail) && subContractDetail.length ? (
              subContractDetail.map((category) => (
                <React.Fragment key={category.categoryCode}>
                  <Table.Row>
                    <Table.Cell
                      style={{
                        color: "rgb(254, 80, 0)",
                        fontWeight: "bold",
                        fontSize: 12,
                      }}
                      // colSpan={7 + (COQty ? COQty : 0)}
                      colSpan={7}
                    >
                      {category.categoryCode
                        ? category.categoryCode.toUpperCase()
                        : ""}
                    </Table.Cell>
                  </Table.Row>
                  {category.subCategories.map((subCategory) => (
                    <Table.Row
                      key={
                        subCategory.subCategoryCode +
                        (subCategory.companyName ? subCategory.companyName : "")
                      }
                    >
                      <Table.Cell style={{ paddingLeft: 20 }}>
                        <Link
                          onClick={() =>
                            dispatch(
                              setInclusionMenuSelection({
                                selectedCategoryID: category.mainCategoryID,
                                selectedSubCategoryCode:
                                  subCategory.subCategoryCode,
                              })
                            )
                          }
                          to={`/browser/projects/inclusions/${props.projectID}`}
                        >
                          {subCategory.subCategoryCode === "Total"
                            ? ""
                            : subCategory.subCategoryCode}
                        </Link>
                      </Table.Cell>
                      <Table.Cell
                        className={
                          subCategory.subCategoryCode === "Total"
                            ? ""
                            : "border-left"
                        }
                      >
                        {subCategory.subCategoryCode === "Total"
                          ? ""
                          : subCategory.companyName}
                      </Table.Cell>
                      <Table.Cell
                        className="border-left"
                        textAlign="center"
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                      >
                        {subCategory.base !== 0
                          ? formatter.format(subCategory.base)
                          : ""}
                      </Table.Cell>
                      <Table.Cell
                        textAlign="center"
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                      >
                        {subCategory.approvedChanges !== 0
                          ? formatter.format(subCategory.approvedChanges)
                          : ""}
                      </Table.Cell>
                      <Table.Cell
                        textAlign="center"
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : { color: "rgb(254, 80, 0)" }
                        }
                      >
                        {subCategory.adjusted !== 0
                          ? formatter.format(subCategory.adjusted)
                          : ""}
                      </Table.Cell>
                      <Table.Cell
                        className="border-left"
                        textAlign="center"
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                      >
                        {subCategory.pendingChanges !== 0
                          ? formatter.format(subCategory.pendingChanges)
                          : ""}
                      </Table.Cell>
                      <Table.Cell
                        textAlign="center"
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : { color: "rgb(254, 80, 0)" }
                        }
                      >
                        {formatter.format(subCategory.adjustedPlusPending)}
                      </Table.Cell>
                      {/* {(COQty ? COQty : 0) > 0
                        ? [...Array(COQty ? COQty : 0)].map((e, i) => (
                            <Table.Cell
                              style={
                                subCategory.subCategoryCode === "Total"
                                  ? { color: "blue", fontWeight: "bold" }
                                  : {}
                              }
                              className="border-left"
                              textAlign="center"
                              key={i}
                            >
                              {subCategory["CO " + (i + 1)] !== 0
                                ? formatter.format(subCategory["CO " + (i + 1)])
                                : ""}
                            </Table.Cell>
                          ))
                        : null} */}
                    </Table.Row>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={7}>No contract details</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell
                className="border-left"
                style={{ color: "purple", fontWeight: "bold" }}
                textAlign="center"
              >
                {subContractDetailTotals && subContractDetailTotals.base !== 0
                  ? formatter.format(subContractDetailTotals.base)
                  : ""}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ color: "purple", fontWeight: "bold" }}
                textAlign="center"
              >
                {subContractDetailTotals &&
                subContractDetailTotals.approvedChanges !== 0
                  ? formatter.format(subContractDetailTotals.approvedChanges)
                  : ""}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ color: "purple", fontWeight: "bold" }}
                textAlign="center"
              >
                {subContractDetailTotals &&
                subContractDetailTotals.adjusted !== 0
                  ? formatter.format(subContractDetailTotals.adjusted)
                  : ""}
              </Table.HeaderCell>
              <Table.HeaderCell
                className="border-left"
                style={{ color: "purple", fontWeight: "bold" }}
                textAlign="center"
              >
                {subContractDetailTotals &&
                subContractDetailTotals.pendingChanges !== 0
                  ? formatter.format(subContractDetailTotals.pendingChanges)
                  : ""}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ color: "purple", fontWeight: "bold" }}
                textAlign="center"
              >
                {subContractDetailTotals &&
                subContractDetailTotals.adjustedPlusPending !== 0
                  ? formatter.format(
                      subContractDetailTotals.adjustedPlusPending
                    )
                  : ""}
              </Table.HeaderCell>
              {/* {COTotalsQty > 0
                ? [...Array(COQty ? COQty : 0)].map((e, i) => (
                    <Table.HeaderCell
                      style={{ color: "purple", fontWeight: "bold" }}
                      className="border-left"
                      textAlign="center"
                      key={i}
                    >
                      {subContractDetailTotals["CO " + (i + 1)] !== 0
                        ? formatter.format(
                            subContractDetailTotals["CO " + (i + 1)]
                          )
                        : ""}
                    </Table.HeaderCell>
                  ))
                : null} */}
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    </div>
  );
};

export default withRouter(SubContractDetail);
