import React, { Fragment, useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import { useLocation, useHistory, withRouter } from "react-router-dom";
import moment from "moment";
import "./Dashboard.scss";

function generate_year_range(start, end) {
  var years = [];
  for (var yearr = start; yearr <= end; yearr++) {
    years.push(yearr);
  }
  return years;
}

let date = moment();

const Dashboard = (props) => {
  // A custom hook that builds on useLocation to parse
  // the query string for you.
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let history = useHistory();
  let location = useLocation();
  const [dropdownValue, setDropdownValue] = useState("");
  const [year, setYear] = useState(parseInt(query.get("y")) || date.year());
  const [quarter, setQuarter] = useState(
    parseInt(query.get("q")) || date.quarter()
  );
  const yearEnd =
    moment().month() >= 10 ? moment().year() + 1 : moment().year();
  var my_years = generate_year_range(2020, yearEnd).sort((a, b) => b - a);
  const quarters = [4, 3, 2, 1];

  //   let my_years = [2019, 2018, 2020, 2014].sort((a, b) => b - a);
  //   console.log(my_years);

  //   const options = my_years.map((y) =>
  //     quarters.map((q) => {
  //       return q === "divider" ? (
  //         my_years.length > 1 ? (
  //           <Dropdown.Divider key={`${q}${y}`} />
  //         ) : null
  //       ) : (
  //         <Dropdown.Item
  //           key={`${q}${y}`}
  //           value={`Q${q} ${y}`}
  //           text={`Q${q} ${y}`}
  //         />
  //       );
  //     })
  //   );

  const optionsArrays = my_years.map((y) =>
    quarters.map((q) => {
      // let value = `Q${q} ${y}`;
      return q === 5
        ? {
            key: `Q${q} ${y}`,
            // text: `Q${q} ${y}`,
            disabled: true,
            // value: JSON.stringify({ q, y }),
            value: "",
            content: <hr className="dropdown-hr" />,
            // className: "divider",
          }
        : {
            key: `Q${q} ${y}`,
            text: `Q${q} ${y}`,
            value: JSON.stringify({ q, y }),
          };
    })
  );

  const options = [].concat(...optionsArrays);

  useEffect(() => {
    setDropdownValue(JSON.stringify({ q: quarter, y: year }));
    // history.replace(`${location.pathname}?y=${year}&q=${quarter}`);
    // setQuarter(year);
    // setYear(quarter);
  }, []);

  useEffect(() => {
    setYear(query.get("y") || date.year());
    setQuarter(query.get("q") || date.quarter());
  }, [query.get("q"), query.get("y")]);

  useEffect(() => {
    history.replace(`${location.pathname}?y=${year}&q=${quarter}`);
  }, [quarter, year]);

  //   useEffect(() => {
  //     if (
  //       quarter &&
  //       year &&
  //       (quarter !== query.get("y") || year !== query.get("q"))
  //     ) {
  //       console.log(query.get("y"));
  //       history.replace(`${location.pathname}?y=${year}&q=${quarter}`);
  //     }
  //   }, []);

  const handleChange = (e, { value }) => {
    const parsedValue = JSON.parse(value);
    setQuarter(parsedValue.q);
    setYear(parsedValue.y);
    setDropdownValue(value);
    history.replace(
      `${location.pathname}?y=${parsedValue.y}&q=${parsedValue.q}`
    );
  };

  return (
    <Fragment>
      <Dropdown
        scrolling
        text={`Showing Q${quarter} ${year}`}
        value={dropdownValue}
        item
        options={options}
        onChange={handleChange}
      />
      {/* <Dropdown.Menu>{options}</Dropdown.Menu>
      </Dropdown> */}
    </Fragment>
  );
};

export default withRouter(Dashboard);
