import React from "react";
import { Header, Container, Table, Label, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";

import "./InclusionTable.scss";
import { useShowV1 } from "../../../../hooks/useQuery";

const InclusionTable = (props) => {
  let preTax = 0;
  let tax = 0;
  let grandTotal = 0;
  let laborHours = 0;
  let laborRate = 0;
  let laborCost = 0;
  let laborMarkup = 0;
  let laborTotal = 0;
  let materialCost = 0;
  let materialMarkup = 0;
  let materialTotal = 0;
  let equipmentCost = 0;
  let equipmentMarkup = 0;
  let equipmentTotal = 0;
  let otherCostsCost = 0;
  let otherCostsMarkup = 0;
  let otherCostsTotal = 0;
  let subCost = 0;
  let subMarkup = 0;
  let subTotal = 0;
  let { inclusions } = props;

  const { summary } = props;
  if (summary.length) {
    const totals = summary[0].summary[0];

    preTax = totals.preTax;
    tax = totals.tax;
    grandTotal = totals.grandTotal;
    laborHours = totals.laborHours;
    laborRate = totals.laborRate;
    laborCost = totals.laborCost;
    laborMarkup = totals.laborMarkup;
    laborTotal = totals.laborTotal;
    materialCost = totals.materialCost;
    materialMarkup = totals.materialMarkup;
    materialTotal = totals.materialTotal;
    equipmentCost = totals.equipmentCost;
    equipmentMarkup = totals.equipmentMarkup;
    equipmentTotal = totals.equipmentTotal;
    otherCostsCost = totals.otherCostsCost;
    otherCostsMarkup = totals.otherCostsMarkup;
    otherCostsTotal = totals.otherCostsTotal;
    subCost = totals.subCost;
    subMarkup = totals.subMarkup;
    subTotal = totals.subTotal;
  }

  const showV1 = useShowV1();

  return (
    <Container
      className="InclusionTable"
      style={{
        paddingTop: 40,
        paddingBottom: 40,
      }}
      fluid
    >
      <Header
        // floated="left"
        style={{ color: "#fe5000", display: "block" }}
        as="h1"
      >
        Inclusions - {props.stage} {showV1 && "(OLD)"}
      </Header>
      <Table unstackable structured compact="very" collapsing={false}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Scope</Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              colSpan="3"
              className="border-left"
            >
              Totals
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              colSpan="5"
              className="border-left"
            >
              Labor
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              colSpan="3"
              className="border-left"
            >
              Material
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              colSpan="3"
              className="border-left"
            >
              Equipment
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              colSpan="3"
              className="border-left"
            >
              Other
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              colSpan="4"
              className="border-left"
            >
              Subs
            </Table.HeaderCell>
          </Table.Row>

          <Table.Row style={{ fontSize: 12 }}>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="border-left">
              Pre-Tax Total
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Tax</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Grand Total</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="border-left">
              Hrs
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Rate</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Cost</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">MU</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Total</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="border-left">
              Cost
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">MU</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Total</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="border-left">
              Cost
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">MU</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Total</Table.HeaderCell>

            <Table.HeaderCell textAlign="center" className="border-left">
              Cost
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">MU</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Total</Table.HeaderCell>

            <Table.HeaderCell textAlign="center" className="border-left">
              Cost
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">MU</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Total</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {inclusions.length ? (
            inclusions[0].category.map((category) => {
              return (
                <React.Fragment key={category.mainCategoryID}>
                  <Table.Row>
                    <Table.Cell
                      style={{
                        color: "rgb(254, 80, 0)",
                        fontWeight: "bold",
                        fontSize: 12,
                      }}
                    >
                      {category.categoryCode.toUpperCase()}
                    </Table.Cell>
                    <Table.Cell
                      style={{ borderLeft: "none" }}
                      colSpan={21}
                    ></Table.Cell>
                  </Table.Row>
                  {category.subcategory.map((subCategory) => {
                    return (
                      <Table.Row
                        key={subCategory.subCategoryCode}
                        style={{ fontSize: 12 }}
                      >
                        <Table.Cell style={{ paddingLeft: 30 }}>
                          <Link
                            onClick={() =>
                              props.setInclusionMenuSelection({
                                selectedCategoryID: category.mainCategoryID,
                                selectedSubCategoryCode:
                                  subCategory.subCategoryCode,
                              })
                            }
                            to={`/browser/projects/inclusions/${props.propertyID}`}
                          >
                            {subCategory.subCategoryCode}
                          </Link>
                        </Table.Cell>
                        <Table.Cell textAlign="center" className="border-left">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(subCategory.preTax)}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(subCategory.tax)}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(subCategory.grandTotal)}
                        </Table.Cell>
                        <Table.Cell textAlign="center" className="border-left">
                          {subCategory.laborHours.toFixed(2)}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(subCategory.laborRate)}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(subCategory.laborCost)}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {subCategory.laborMarkup}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(subCategory.laborTotal)}
                        </Table.Cell>
                        <Table.Cell textAlign="center" className="border-left">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(subCategory.materialCost)}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {subCategory.materialMarkup}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(subCategory.materialTotal)}
                        </Table.Cell>

                        <Table.Cell textAlign="center" className="border-left">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(subCategory.equipmentCost)}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {subCategory.equipmentMarkup}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(subCategory.equipmentTotal)}
                        </Table.Cell>

                        <Table.Cell textAlign="center" className="border-left">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(subCategory.otherCostsCost)}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {subCategory.otherCostsMarkup}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(subCategory.otherCostsTotal)}
                        </Table.Cell>

                        <Table.Cell textAlign="center" className="border-left">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(subCategory.subCost)}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {subCategory.subMarkup}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(subCategory.subTotal)}
                        </Table.Cell>
                        <Table.Cell
                          style={{ paddingLeft: 0, paddingRight: 0, width: 44 }}
                        >
                          {subCategory.subList ? (
                            <Popup
                              position="top right"
                              trigger={
                                <Label size="tiny" color="orange">
                                  Subs
                                </Label>
                              }
                              content={
                                <ul
                                  style={{
                                    listStyle: "none",
                                    padding: 0,
                                  }}
                                >
                                  {[
                                    ...new Set(subCategory.subList.split(",")),
                                  ].map((sub) => (
                                    <li style={{ paddingBottom: 2 }} key={sub}>
                                      {sub}
                                    </li>
                                  ))}
                                </ul>
                              }
                            />
                          ) : null}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </React.Fragment>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell
                style={{
                  // color: "rgb(254, 80, 0)",
                  color: "gray",
                  fontWeight: "bold",
                  fontSize: 12,
                }}
              >
                No inclusions
              </Table.Cell>
              <Table.Cell
                style={{ borderLeft: "none" }}
                colSpan={21}
              ></Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              className="border-left footer-text"
            >
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(preTax)}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="footer-text">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(tax)}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="footer-text">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(grandTotal)}
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              className="border-left footer-text"
            >
              {laborHours.toFixed(2)}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="footer-text">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(laborRate)}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="footer-text">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(laborCost)}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="footer-text">
              {laborMarkup === 0 ? "0%" : laborMarkup}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="footer-text">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(laborTotal)}
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              className="border-left footer-text"
            >
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(materialCost)}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="footer-text">
              {materialMarkup === 0 ? "0%" : materialMarkup}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="footer-text">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(materialTotal)}
            </Table.HeaderCell>

            <Table.HeaderCell
              textAlign="center"
              className="border-left footer-text"
            >
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(equipmentCost)}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="footer-text">
              {equipmentMarkup === 0 ? "0%" : equipmentMarkup}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="footer-text">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(equipmentTotal)}
            </Table.HeaderCell>

            <Table.HeaderCell
              textAlign="center"
              className="border-left footer-text"
            >
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(otherCostsCost)}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="footer-text">
              {equipmentMarkup === 0 ? "0%" : otherCostsMarkup}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="footer-text">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(otherCostsTotal)}
            </Table.HeaderCell>

            <Table.HeaderCell
              textAlign="center"
              className="border-left footer-text"
            >
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(subCost)}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="footer-text">
              {subMarkup === 0 ? "0%" : subMarkup}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="footer-text">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(subTotal)}
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Container>
  );
};

export default InclusionTable;
