import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Menu, Icon, Image } from "semantic-ui-react";
import MenuItems from "./MenuItems/MenuItems";

import "./TopMenu.scss";
import logo from "./N5Group.png";
import { API_ROOT } from "../../api-config";
import { logout } from "../../actions/auth/actions";

class TopMenu extends Component {
  render() {
    return (
      <div onClick={this.props.click}>
        <Menu fixed="top" className="TopMenu" size="small">
          <Menu.Item
            as="a"
            header
            onClick={this.props.handleAnimationChange("overlay")}
          >
            <Icon link className="icon-bars" size="large" name="bars" />
          </Menu.Item>

          <Menu.Item as={Link} to="/home">
            <Image className="logo" src={logo} />
          </Menu.Item>
          <MenuItems />
          <Menu.Menu position="right">
            <Menu.Item>{this.props.name}</Menu.Item>
            <Menu.Item
              href={`${API_ROOT}/auth/logout`}
              onClick={() => {
                this.props.logout();
              }}
            >
              <Icon size="large" title="Log Out" name="sign-out" />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    name: state.auth.name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopMenu)
);
