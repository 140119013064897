import React, { Fragment } from "react";
import { Button } from "semantic-ui-react";

const FilterButtons = ({ filterButton, setFilterButton }) => {
  const handleClick = (filter) => {
    if (filterButton !== filter) {
      setFilterButton(filter);
    }
  };
  return (
    <Fragment>
      <Button.Group size="large" primary fluid style={{ borderRadius: 0 }}>
        <Button
          style={{ borderRadius: 0 }}
          onClick={() => handleClick("Active")}
          active={filterButton === "Active"}
        >
          Active
        </Button>
        <Button
          style={{ borderRadius: 0 }}
          onClick={() => handleClick("Archived")}
          active={filterButton === "Archived"}
        >
          Archived
        </Button>
      </Button.Group>

      <div style={{ width: "100%", overflowY: "auto" }}>
        <Button.Group
          basic
          fluid
          size="mini"
          style={{ borderRadius: 0, height: 40 }}
        >
          <Button
            onClick={() => handleClick("WIP")}
            active={filterButton === "WIP"}
          >
            WIP
          </Button>
          <Button
            onClick={() => handleClick("APRVD")}
            active={filterButton === "APRVD"}
          >
            APRVD
          </Button>
          <Button
            onClick={() => handleClick("CLIENT")}
            active={filterButton === "CLIENT"}
          >
            CLIENT
          </Button>
          <Button
            onClick={() => handleClick("PREP")}
            active={filterButton === "PREP"}
          >
            PREP
          </Button>
          <Button
            onClick={() => handleClick("ME")}
            active={filterButton === "ME"}
          >
            ME
          </Button>
        </Button.Group>
      </div>
    </Fragment>
  );
};

export default FilterButtons;
