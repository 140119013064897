import React, { useState, useEffect } from "react";
import SecurityRoles from "./SecurityRoles";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";

const SecurityRolesWrapper = () => {
  const [roles, setRoles] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState(null);
  const [roleName, setRoleName] = useState("");
  const [roleID, setRoleID] = useState(null);
  const [displayNames, setDisplayNames] = useState([]);

  const getRoles = () => {
    Axios.get(`${API_ROOT}/api/user/roles`)
      .then((res) => {
        setRoles(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getDisplayNames = () => {
    Axios.get(`${API_ROOT}/api/user/displaynames`)
      .then((res) => {
        setDisplayNames(res.data.users ? JSON.parse(res.data.users) : []);
      })
      .catch((err) => console.log(err));
  };

  const deleteUserRole = (roleID, userID) => {
    Axios.post(`${API_ROOT}/api/user/delete-user-role`, {
      roleID,
      userID,
    })
      .then((res) => setUsers(res.data))
      .catch((err) => console.log(err));
  };

  const addUserRole = (roleID, userID) => {
    Axios.post(`${API_ROOT}/api/user/add-user-role`, {
      roleID,
      userID,
    })
      .then((res) => setUsers(res.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getRoles();
    getDisplayNames();
  }, []);

  const getRoleByID = (roleID, name) => {
    Axios.get(`${API_ROOT}/api/user/role-by-id`, {
      params: {
        roleID,
      },
    })
      .then((res) => {
        setUsers(res.data);
        setRoleName(name);
        setRoleID(roleID);
        setOpen(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <SecurityRoles
      roles={roles}
      loading={loading}
      open={open}
      setOpen={setOpen}
      users={users}
      getRoleByID={getRoleByID}
      roleName={roleName}
      displayNames={displayNames}
      roleID={roleID}
      deleteUserRole={deleteUserRole}
      addUserRole={addUserRole}
    />
  );
};

export default SecurityRolesWrapper;
