import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import { API_ROOT } from "../../../../../api-config";
import { getUser } from "../../../../../util";

const SetToPendingModal = ({ guid, type }) => {
  const user = useSelector((state) => getUser(state));
  const [isAdmin, setIsAdmin] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setIsAdmin(
        user.hasOwnProperty("Roles") && user.Roles
          ? user.Roles.some(
              (role) => role.Role === "Admin" || role.Role === "Superuser"
            )
          : false
      );
    }
  }, [user.UserID]);

  const handleYesClick = () => {
    console.log(guid, type);
    setLoading(true);
    Axios.post(`${API_ROOT}/api/contract/set-contract-to-pending`, {
      params: {
        guid,
        type,
      },
    })
      .then((res) => {
        console.log(res);
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        alert(err);
      });
    setOpen(false);
  };
  return isAdmin ? (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={
        <Icon
          title="Return contract to pending status"
          link
          color="red"
          name="backward"
        />
      }
    >
      <Header icon>
        <Icon name="warning" />
        Return Contract Status to Pending
      </Header>
      <Modal.Content>
        <p>
          This will erase the contract signature. Are you sure you want to set
          the project status back to pending?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> No
        </Button>
        <Button
          disabled={loading}
          loading={loading}
          color="green"
          inverted
          onClick={handleYesClick}
        >
          <Icon name="checkmark" /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  ) : null;
};

export default SetToPendingModal;
