import React, { Component } from "react";
import { Modal, Icon, Button } from "semantic-ui-react";
import NotesContainer from "./NotesContainer/NotesContainer";

// import { fetchProjects } from "../../../actions/project/projectActions";

class NotesModal extends Component {
  state = {
    open: false,
    currentProject: null,
    currentProjectName: "",
    notesColor: "green",
  };

  close = () => {
    // this.props.dispatch(fetchProjects());
    this.setState({ open: false });
  };

  open = () => {
    this.setState({ open: true });
  };

  handleNextProject = () => {
    let array = this.props.projects;

    let i = array
      .map(function(x) {
        return x.ProjectID;
      })
      .indexOf(this.state.currentProject);

    // // let l = array.length;
    // let previous = array[i === 0 ? array.length - 1 : i - 1];
    // let current = array[i];
    let next = array[i === array.length - 1 ? 0 : i + 1];

    this.setState({
      currentProject: next.ProjectID,
      currentProjectName: next.ProjectName,
    });
  };

  handlePrevProject = () => {
    let array = this.props.projects;

    let i = array
      .map(function(x) {
        return x.ProjectID;
      })
      .indexOf(this.state.currentProject);

    // let l = array.length;
    let previous = array[i === 0 ? array.length - 1 : i - 1];
    // let current = array[i];
    // let next = array[i === array.length - 1 ? 0 : i + 1];

    this.setState({
      currentProject: previous.ProjectID,
      currentProjectName: previous.ProjectName,
    });
  };

  componentDidMount() {
    let array = this.props.projects;

    let i = array
      .map(function(x) {
        return x.ProjectID;
      })
      .indexOf(this.props.projectID);

    this.setState({
      notesColor: this.props.color,
      currentProject: this.props.projectID,
      currentProjectName: this.props.projects
        ? this.props.projects[i].ProjectName
        : "",
    });
  }

  render() {
    const { open } = this.state;
    return (
      <div>
        <Icon
          onClick={this.open}
          link
          bordered
          inverted
          color={this.state.notesColor}
          name="comment"
        />
        <Modal open={open} onClose={this.close} size="small">
          <Modal.Header>
            {this.state.currentProjectName
              ? this.state.currentProjectName + " "
              : "Project "}
            Notes #{this.state.currentProject}
          </Modal.Header>
          <Modal.Content>
            <NotesContainer
              projectID={this.state.currentProject}
              getProjectNotifications={this.getProjectNotifications}
            />
            {/* <NotesContainer projectID={this.props.projectID} /> */}
          </Modal.Content>
          <Modal.Actions>
            <Button color="teal" onClick={this.handlePrevProject}>
              <Icon style={{ color: "#fff" }} name="backward" /> Previous
            </Button>
            <Button color="teal" onClick={this.handleNextProject}>
              <Icon style={{ color: "#fff" }} name="forward" /> Next
            </Button>
            <Button onClick={this.close}>
              <Icon name="remove" /> Close
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

// const mapStateToProps = (state) => {
//   const projects = state.project.projects.sort((a, b) =>
//     a.ProjectStageAbbr > b.ProjectStageAbbr
//       ? 1
//       : a.ProjectStageAbbr < b.ProjectStageAbbr
//       ? -1
//       : 0
//   );
//   // .filter(
//   //   (obj) => obj.StageType !== "Completed" && obj.StageType !== "Canceled"
//   // );
//   return {
//     projects,
//   };
// };

export default NotesModal;
