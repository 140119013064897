const STAGING = "staging";
const PROD = "production";
const LOCAL = "localhost";

const backendDomains = {
  [LOCAL]: "localhost:5000",
  [STAGING]: "nexsys1api-staging.azurewebsites.net",
  [PROD]: "api.nexus5group.com",
};

export const getEnv = () => {
  const hostname = window.location.hostname;
  if (hostname === "nexsys1-staging.azurewebsites.net") {
    return STAGING;
  } else if (hostname === "ns1.nexus5group.com") {
    return PROD;
  } else {
    return LOCAL;
  }
};

export const toBackendUri = (url) => {
  const currentEnv = getEnv();
  const backend = backendDomains[currentEnv];
  let protocal = "https";
  if (currentEnv === LOCAL) protocal = "http";

  return `${protocal}://${backend}${url}`;
};
