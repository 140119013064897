import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Button } from "semantic-ui-react";
import { API_ROOT } from "../../../api-config";

const StageButtons = ({ type, externalFilterChanged }) => {
  const [stageCounts, setStageCounts] = useState(null);
  useEffect(() => {
    Axios.get(`${API_ROOT}/api/project/projectstagecount`)
      .then((res) => {
        setStageCounts(JSON.parse(res.data.projectCounts)[0]);
      })
      .catch((err) => console.log(err));
  }, []);

  const buttonSize = "mini";

  return (
    // <ButtonGroup>
    <Fragment>
      <Button
        size={buttonSize}
        active={type === "1WIP"}
        onClick={() => externalFilterChanged("1WIP")}
      >
        WIP{" "}
        <span style={{ fontSize: 10 }}>
          {stageCounts ? stageCounts.WIPCount : 0}
        </span>
      </Button>
      <Button
        size={buttonSize}
        active={type === "2APRVD"}
        onClick={() => externalFilterChanged("2APRVD")}
      >
        APRVD{" "}
        <span style={{ fontSize: 10 }}>
          {stageCounts ? stageCounts.ApprovedCount : 0}
        </span>
      </Button>
      <Button
        size={buttonSize}
        active={type === "3CLIENT"}
        onClick={() => externalFilterChanged("3CLIENT")}
      >
        CLIENT{" "}
        <span style={{ fontSize: 10 }}>
          {stageCounts ? stageCounts.ClientCount : 0}
        </span>
      </Button>
      <Button
        size={buttonSize}
        active={type === "4PREP"}
        onClick={() => externalFilterChanged("4PREP")}
      >
        PREP{" "}
        <span style={{ fontSize: 10 }}>
          {stageCounts ? stageCounts.PrepCount : 0}
        </span>
      </Button>
      <Button
        size={buttonSize}
        active={type === "ME"}
        onClick={() => externalFilterChanged("ME")}
      >
        ME{" "}
        <span style={{ fontSize: 10 }}>
          {stageCounts ? stageCounts.MeCount : 0}
        </span>
      </Button>
      {/* // </ButtonGroup> */}
    </Fragment>
  );
};

export default StageButtons;
