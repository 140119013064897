import Axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import formReducer from "./FormReducer";
import { API_ROOT } from "../../../../api-config";

const intialFormState = {
  courseName: "",
  courseDescription: "",
  courseStartDate: "",
  courseDuration: 0.25,
  courseOwner: "",
};

const CreateModal = ({
  createModalOpen,
  setCreateModalOpen,
  uid,
  setOptions,
}) => {
  const [formState, dispatch] = useReducer(formReducer, intialFormState);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Axios.get(`${API_ROOT}/api/user/displaynames`)
      .then((res) => {
        const data = JSON.parse(res.data.users);
        setUsers(
          data.map((obj) => {
            return {
              text: obj.DisplayName,
              value: obj.UserID,
            };
          })
        );
      })
      .catch((err) => console.log(err));
  }, []);

  const handleOpen = () => {
    setCreateModalOpen(true);
  };

  const handleClose = () => {
    setCreateModalOpen(false);
    reset();
  };

  const handleTextChange = (e) => {
    dispatch({
      type: "HANDLE INPUT TEXT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSelectChange = (e, { name, value }) => {
    dispatch({
      type: "HANDLE SELECT",
      field: name,
      payload: value,
    });
  };

  const reset = () => dispatch({ type: "RESET", payload: intialFormState });

  // const handleMultiSelectChange = (e) => {
  //   let { options } = e.target;
  //   options = Array.apply(null, options);
  //   const selectedValues = options
  //     .filter((x) => x.selected)
  //     .map((x) => x.value);
  //   dispatch({
  //     type: "HANDLE MULTI SELECT",
  //     field: e.target.name,
  //     payload: selectedValues,
  //   });
  // };

  const handleSave = () => {
    setLoading(true);
    Axios.get(
      `https://www.ce-knet.com/DesktopModules/XModPro/Feed.aspx?xfd=NS1_SaveSafetyTrainingCourse`,
      {
        withCredentials: false,
        params: {
          pid: 0,
          CourseName: formState.courseName,
          CourseDescription: formState.courseDescription,
          CourseStartDate: formState.courseStartDate,
          Duration: formState.courseDuration,
          Owner: formState.courseOwner,
          CreatedBy: uid,
        },
      }
    )
      .then((res) => {
        setOptions(
          res.data.map((item) => {
            return {
              value: item.CourseID,
              text: item.CourseName,
            };
          })
        );

        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const disabled =
    !formState.courseName ||
    !formState.courseDescription ||
    !formState.courseDuration ||
    !formState.courseStartDate ||
    !formState.courseOwner;

  return (
    <Modal
      size="small"
      open={createModalOpen}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Modal.Header>Create New Training Course</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            required
            type="text"
            label="Course Name"
            name="courseName"
            onChange={(e) => handleTextChange(e)}
            value={formState.courseName}
          />
          <Form.Input
            required
            type="text"
            label="Course Description"
            name="courseDescription"
            onChange={(e) => handleTextChange(e)}
            value={formState.courseDescription}
          />
          <Form.Input
            required
            label="Course Start Date"
            type="date"
            name="courseStartDate"
            value={formState.courseStartDate}
            onChange={(e) => handleTextChange(e)}
          />
          <Form.Select
            required
            label="Course Duration"
            selectOnBlur={false}
            name="courseDuration"
            options={[
              { text: "15 Minutes", value: 0.25 },
              { text: "30 Minutes", value: 0.5 },
              { text: "45 Minutes", value: 0.75 },
              { text: "60 Minutes", value: 1 },
              { text: "90 Minutes", value: 1.5 },
              { text: "2 Hours", value: 2 },
              { text: "3 Hours", value: 3 },
              { text: "4 Hours", value: 4 },
              { text: "5 Hours", value: 5 },
              { text: "6 Hours", value: 6 },
              { text: "7 Hours", value: 7 },
              { text: "1 Day", value: 8 },
              { text: "2 Days", value: 16 },
              { text: "3 Days", value: 24 },
            ]}
            value={formState.courseDuration}
            onChange={handleSelectChange}
          />
          <Form.Select
            required
            label="Course Owner"
            selectOnBlur={false}
            name="courseOwner"
            options={users}
            value={formState.courseOwner}
            onChange={handleSelectChange}
          />
          {/* <Form.Field>
            <label>Required Positions</label>
            <select
              name="requiredPositions"
              value={formState.requiredPositions}
              onChange={handleMultiSelectChange}
              multiple
            >
              <option value="Accountant">Accountant</option>
            </select>
          </Form.Field> */}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          loading={loading}
          disabled={disabled}
          color="green"
          onClick={handleSave}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CreateModal;
