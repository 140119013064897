import React, { useState, useEffect } from "react";
import Axios from "axios";
import { API_ROOT } from "../../../../../api-config";
import { connect } from "react-redux";
import { setInclusionMenuSelection } from "../../../../../actions/project/inclusion/inclusionActions";
import { bindActionCreators } from "redux";

const SubContractDetailWrapper = props => {
  const [projectID, setProjectID] = useState(props.projectID);
  const [subContractDetail, setSubContractDetail] = useState([]);
  const [subContractDetailTotals, setSubContractDetailTotals] = useState(null);

  const { WrappedComponent } = props;

  useEffect(() => {
    setProjectID(props.projectID);
  }, [props.projectID]);

  const getSubContractDetail = () => {
    Axios.get(`${API_ROOT}/api/contract/sub-contract-detail`, {
      params: {
        projectID
      }
    })
      .then(res => {
        if (res.data.subContractDetail) {
          setSubContractDetail(res.data.subContractDetail);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getSubContractDetailTotals = () => {
    Axios.get(`${API_ROOT}/api/contract/sub-contract-detail-totals`, {
      params: {
        projectID
      }
    })
      .then(res => {
        if (res) {
          setSubContractDetailTotals(res.data.subContractDetailTotals);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSubContractDetail();
    getSubContractDetailTotals();
  }, [projectID]);

  return (
    <WrappedComponent
      subContractDetail={subContractDetail}
      subContractDetailTotals={subContractDetailTotals}
      setInclusionMenuSelection={setInclusionMenuSelection}
      projectID={projectID}
    />
  );
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setInclusionMenuSelection
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(SubContractDetailWrapper);
