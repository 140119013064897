import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { Icon, Popup } from "semantic-ui-react";
import { ContractValidationWarningModal } from "./ContractValidationWarningModal";

const UploadProposalPopup = ({ contract, uploadClientContract }) => {
  const [open, setOpen] = useState(false);
  const fileUpload = useRef(null);
  const proposalUploadInput = useRef(null);

  const onDrop = useCallback((acceptedFile) => {
    const guid = proposalUploadInput.current.dataset.guid;
    uploadClientContract(acceptedFile, guid);
  }, []);

  useEffect(() => {
    console.log("open changed === '" + open + "'");
  }, [open]);

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    // isDragReject,
    // acceptedFiles,
    // rejectedFiles
  } = useDropzone({
    onDrop,
    accept: "application/pdf",
    minSize: 0,
  });

  return (
    <Fragment>
      <span ref={proposalUploadInput} data-guid={contract.GUID}></span>
      <div style={{ display: "inline-block" }} {...getRootProps()}>
        <input
          {...getInputProps()}
          ref={(el) => (fileUpload.current = el)}
        ></input>
      </div>

      <Popup
        content={"Upload approved proposal pdf"}
        position="top center"
        trigger={
          <Icon
            link
            name="cloud upload"
            onClick={() => setOpen(true)}
            color={isDragActive ? "orange" : "black"}
            size={isDragActive ? "big" : null}
          />
        }
      />
      <ContractValidationWarningModal
        show={open}
        guid={contract.GUID}
        callback={() => fileUpload.current.click()}
        onClose={() => setOpen(false)}
      />
    </Fragment>
  );
};

export default UploadProposalPopup;
