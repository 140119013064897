import Axios from "axios";
import moment from "moment";
import React, { forwardRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { ButtonGroup, Form, Table } from "semantic-ui-react";
import { API_ROOT } from "../../../../api-config";
import CsvDownloader from "react-csv-downloader";

const InvoiceLog = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [csvData, setCsvData] = useState(null);
  const [loading, setLoading] = useState(false);
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick}>
      <Form>
        <Form.Input value={value} className="example-custom-input" />
      </Form>
    </div>
  ));

  const handleClick = () => {
    setLoading(true);
    setCsvData(null);

    const date = moment(startDate).format("YYYY-MM-DD");

    Axios.get(`${API_ROOT}/api/project/invoice-log?date=${date}`).then(
      (res) => {
        // console.log(res);
        setCsvData(res.data);
        setLoading(false);
      }
    );
  };

  return (
    <Table.Row>
      <Table.Cell>Invoice Log</Table.Cell>
      <Table.Cell>
        <ReactDatePicker
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            setCsvData(null);
          }}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          customInput={<CustomInput />}
        />
      </Table.Cell>
      <Table.Cell>
        <ButtonGroup>
          <Form.Button
            style={{ marginRight: 10 }}
            loading={loading}
            onClick={handleClick}
          >
            Generate Report
          </Form.Button>
          {csvData ? (
            <CsvDownloader
              // columns={columns}
              datas={csvData}
              filename={`InvoiceLog`}
              separator=","
              wrapColumnChar='"'
            >
              <Form.Button color="green">Export CSV</Form.Button>
            </CsvDownloader>
          ) : null}
        </ButtonGroup>
      </Table.Cell>
    </Table.Row>
  );
};

export default InvoiceLog;
