import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import AutoSuggest from "../../../../../sharedComponents/AutoSuggest/AutoSuggest";
import { API_ROOT } from "../../../../../../api-config";

import LabelList from "./LabelList/LabelList";

Axios.defaults.withCredentials = true;

// import { setNotification } from '../../../../../../actions/project/projectActions';

class Notifications extends PureComponent {
  state = {
    options: [],
    notifications: []
  };

  getDisplayNames = () => {
    Axios.get(`${API_ROOT}/api/user/displaynames`).then(res => {
      const users = JSON.parse(res.data.users);
      const options = users.map(user => {
        return {
          value: user.UserID,
          label: user.DisplayName
        };
      });
      this.setState({ options });
    });
  };

  getProjectNotifications = projectID => {
    Axios.get(`${API_ROOT}/api/project/projectnotifications/${projectID}`).then(
      res => {
        this.setState({ notifications: JSON.parse(res.data.notifications) });
      }
    );
  };

  addNotification = userID => {
    const projectID = this.props.projectID;

    let userExists = false;
    if (this.state.notifications) {
      userExists = this.state.notifications.some(
        notification => notification.UserID === userID
      );
    }

    if (!userExists) {
      Axios.post(`${API_ROOT}/api/project/projectnotification`, {
        params: { projectID, userID }
      }).then(res => {
        this.setState({ notifications: JSON.parse(res.data.notifications) });
        this.props.getProjectNotifications
          ? this.props.getProjectNotifications(projectID)
          : console.log("");
      });
    }
  };

  deleteNotification = userID => {
    const projectID = this.props.projectID;
    Axios.delete(`${API_ROOT}/api/project/projectnotification`, {
      params: { projectID, userID }
    }).then(res => {
      this.setState({ notifications: JSON.parse(res.data.notifications) });
    });
    this.props.getProjectNotifications
      ? this.props.getProjectNotifications(projectID)
      : console.log("");
  };

  componentDidUpdate(prevProps) {
    if (this.props.projectID !== prevProps.projectID) {
      this.getProjectNotifications(this.props.projectID);
    }
  }

  componentDidMount() {
    this.getDisplayNames();
    this.getProjectNotifications(this.props.projectID);
  }

  render() {
    return (
      <div className="AutoSuggest">
        <AutoSuggest
          placeholder="Add a recipient"
          options={this.state.options}
          addLabel={this.addNotification}
        />
        <LabelList
          deleteLabel={this.deleteNotification}
          labels={this.state.notifications}
          projectID={this.props.projectID}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    projects: state.project.projects
  };
};

export default connect(mapStateToProps)(Notifications);
