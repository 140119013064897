import React from "react";
import dayjs from "dayjs";

import SignatureModal from "./SignatureModal";
import n5Signature from "../../../components/ContractWrapper/N5Group_Signature.png";

export default ({ summary, onSignatureUpload }) => {
  return (
    <div className="signatures">
      {summary.approved_ind && (
        <div className="client">
          <h3>Client Signature</h3>
          <div className="signature">
            {summary.approved_by === "Manual contract upload" ? null : (
              <img
                alt={`${summary.approved_by} signature`}
                src={summary.signature}
              />
            )}
          </div>
          <div>
            <h5>
              {summary.approved_by}
              <small>
                {dayjs(summary.approval_date).format("MMMM D, YYYY")}
              </small>
            </h5>
          </div>
        </div>
      )}
      {!summary.approved_ind && <SignatureModal onSubmit={onSignatureUpload} />}
      <div className="nexus">
        <h3>Nexus 5 Group</h3>
        <div className="signature">
          <img alt={`John Hinman signature`} src={n5Signature} />
        </div>
        <div>
          <h5>
            John Hinman
            <small>{dayjs(summary.contract_date).format("MMMM D, YYYY")}</small>
          </h5>
        </div>
      </div>
    </div>
  );
};
