import React, { useRef, useState } from "react";
import {
  Container,
  Image,
  Divider,
  Message,
  Modal,
  Button,
  Form,
  Icon,
} from "semantic-ui-react";
import dayjs from "dayjs";
import SignaturePad from "react-signature-canvas";

import n5logo from "../N5Group.png";
import n5Signature from "../N5Group_Signature.png";
import DetailedContractRowNew from "./DetailedContractRow/DetailedContractRowNew";
import ContractRequirements from "../ContractRequirements/ContractRequirements";
import ExclusionsMessage from "../ExclusionsMessage/ExclusionsMessage";

import "./DetailedContractNew.scss";

const DetailedContractNew = ({ setSignature, contract }) => {
  const conditions = contract.conditions;
  const summary = contract.summary;
  const categories = [...new Set(conditions.map((item) => item.Category))];

  const [modalOpen, setModalOpen] = useState(false);
  const [approvedBy, setApprovedBy] = useState("");
  const [loading, setLoading] = useState(false);

  const sigCanvas = useRef({});

  const clear = () => sigCanvas.current.clear();
  const save = () => {
    // const recipient = summary.Recipient;
    const isEmpty = sigCanvas.current.isEmpty();

    if (approvedBy && !isEmpty) {
      setLoading(true);
      const image = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      setSignature(image, approvedBy);
      // handleModalClose();
    } else {
      return;
    }
  };
  // setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));

  const handleModalOpen = () => {
    setApprovedBy("");
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3];
    }
    return null;
  };
  return (
    <Container className="DetailedContract" style={{ position: "relative" }}>
      <div className="DetailedContract__row">
        <div className="col-30">
          {summary.Recipient === "client" ? (
            <p style={{ maxWidth: 300 }}>
              <span style={{ fontWeight: "bold" }}>
                {summary.ClientContact || ""}
              </span>
              <br />
              {summary.ClientCompanyName || ""}
              <br />
              {summary.ClientContactOfficePhone
                ? formatPhoneNumber(summary.ClientContactOfficePhone)
                : ""}
              <br />
              {summary.ClientContactEmail || ""}
            </p>
          ) : (
            <p style={{ maxWidth: 300 }}>
              <span style={{ fontWeight: "bold" }}>
                {summary.SubContactName || ""}
              </span>
              <br />
              {summary.SubCompanyName || ""}
              <br />
              {summary.SubContactPhone
                ? formatPhoneNumber(summary.SubContactPhone)
                : ""}
              <br />
              {summary.SubContactEmail || ""}
            </p>
          )}
        </div>
        <div className="col-40">
          <Image
            fluid
            centered
            src={n5logo}
            style={{ maxWidth: 240 }}
            alt="Nexus 5 Group logo"
          />
        </div>
        <div className="col-30">
          <p style={{ textAlign: "right" }}>
            <span style={{ fontWeight: "bold" }}>
              {summary.ProjectManagerName || ""}
            </span>
            <br />
            Nexus 5 Group
            <br />
            {summary.ProjectManagerPhone || ""}
            <br />
            {summary.ProjectManagerEmail || ""}
          </p>
        </div>
      </div>
      <div className="DetailedContract__row">
        <div className="col-100">
          <Divider />
        </div>
      </div>
      <div className="DetailedContract__row">
        <div className="col-50">
          <p style={{ maxWidth: 340 }}>
            {dayjs(summary.ContractDate).format("MMMM D, YYYY")}
            <br />
            <span style={{ fontWeight: "bold" }}>{summary.ProjectName}</span> #
            {summary.ProjectID}
            <br />
            {summary.WorkLocationName}
            <br />
            {summary.WorkLocationAddress}
            <br />
            <span
              dangerouslySetInnerHTML={{
                __html: summary.ProjectDescription,
              }}
            ></span>
          </p>
        </div>
        <div className="col-50">
          <h2 style={{ textAlign: "right", marginTop: 10 }}>
            {summary.Recipient === "sub" ? "Subcontract" : "Project Proposal"}
          </h2>
        </div>
      </div>
      <div className="DetailedContract__row">
        <table width="100%" className="DetailedContract__table">
          <thead>
            <tr>
              <th
                style={{
                  minWidth: 200,
                  textAlign: "left",
                  fontSize: 14,
                }}
              >
                Scope - {summary.ContractType}
              </th>
              {summary.Recipient === "sub" ? <th>Description</th> : null}
              <th
                style={{
                  textAlign: "right",
                }}
              >
                Price
              </th>
            </tr>
          </thead>
          {categories.map((category, index) => {
            return (
              <React.Fragment key={category}>
                <tbody>
                  <tr
                    style={{
                      fontSize: 12,
                      backgroundColor: "lightyellow",
                    }}
                  >
                    {/* <Table.HeaderCell></Table.HeaderCell> */}
                    <th
                      style={{
                        padding: "4px 8px",
                        // border: "1px solid rgba(34, 36, 38, 0.1)",
                        boxShadow: "none",
                      }}
                      colSpan={summary.Recipient === "sub" ? 3 : 2}
                    >
                      {category.toUpperCase()}
                    </th>
                    {/* {summary.Recipient === "sub" ? (
                            <Table.HeaderCell></Table.HeaderCell>
                          ) : null}
                          <Table.HeaderCell></Table.HeaderCell> */}
                  </tr>
                </tbody>
                <tbody style={{ fontSize: 12 }}>
                  <DetailedContractRowNew
                    category={category}
                    conditions={conditions}
                    summary={summary}
                  />
                </tbody>
              </React.Fragment>
            );
          })}
          <tbody>
            <tr>
              {/* <Table.Cell style={{ paddingTop: 40 }}></Table.Cell> */}
              <td
                colSpan={summary.Recipient === "sub" ? 2 : 1}
                style={{
                  paddingTop: 40,
                  paddingLeft: 7.2,
                  fontSize: 22,
                  paddingBottom: 10,
                  // color: '#fe5000',
                  fontWeight: "bold",
                }}
              >
                Proposed Total Price
              </td>
              {/* {summary.Recipient === "sub" ? (
                      <Table.Cell></Table.Cell>
                    ) : null} */}
              <td
                style={{
                  paddingTop: 40,
                  paddingBottom: 10,
                  paddingRight: 7.2,
                  fontSize: 22,
                  color: "#fe5000",
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(summary.GrandTotal)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div className="right floated col-100">
          <table className="DetailedContract__table">
            <tbody>
              <tr>
                <td
                  style={{
                    background: "rgba(0,0,0,.03)",
                    fontWeight: 700,
                  }}
                >
                  Approximate Project Duration
                </td>
                <td
                  style={{
                    textAlign: "right",
                    borderLeft: "1px solid rgba(34,36,38,.15)",
                  }}
                >
                  {summary.WorkDays ? summary.WorkDays + " Days" : ""}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    background: "rgba(0,0,0,.03)",
                    fontWeight: 700,
                  }}
                >
                  Net Payment Due
                </td>
                <td
                  style={{
                    textAlign: "right",
                    borderLeft: "1px solid rgba(34,36,38,.15)",
                  }}
                >
                  30 Days
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    background: "rgba(0,0,0,.03)",
                    fontWeight: 700,
                  }}
                >
                  Retention
                </td>
                <td
                  style={{
                    textAlign: "right",
                    borderLeft: "1px solid rgba(34,36,38,.15)",
                  }}
                >
                  {!summary.Retention && summary.Retention !== 0
                    ? ""
                    : summary.Retention + "%"}
                </td>
              </tr>
              {summary.Recipient === "client" ? (
                <tr>
                  <td
                    style={{
                      background: "rgba(0,0,0,.03)",
                      fontWeight: 700,
                    }}
                  >
                    Sales Tax
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      borderLeft: "1px solid rgba(34,36,38,.15)",
                    }}
                  >
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(summary.SalesTax)}
                  </td>
                </tr>
              ) : null}
              {summary.Recipient === "client" ? (
                <tr>
                  <td
                    style={{
                      background: "rgba(0,0,0,.03)",
                      fontWeight: 700,
                    }}
                  >
                    Kansas Remodel Tax
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      borderLeft: "1px solid rgba(34,36,38,.15)",
                    }}
                  >
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(summary.KansasRemodelTax)}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
      <div className="DetailedContract__row">
        <div className="col-50">
          {summary.Signature ||
          summary.ApprovedBy === "Manual contract upload" ? (
            <table className="DetailedContract__table signatures first">
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      fontWeight: "bold",
                      borderBottom: "1px solid rgba(34,36,38,.1)",
                      paddingTop: 7,
                      paddingBottom: 7,
                      pageBreakInside: "avoid",
                      background: "#fff",
                    }}
                  >
                    {summary.Recipient === "client"
                      ? "Client"
                      : "Subcontractor"}{" "}
                    Signature
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ background: "rgba(0,0,0,.05)", padding: 0 }}>
                    {summary.ApprovedBy === "Manual contract upload" ? null : (
                      <img
                        alt={`${summary.ApprovedBy} signature`}
                        src={summary.Signature}
                        style={{ display: "block", width: "100%" }}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <td
                  style={{
                    padding: "8px 10px",
                    borderTop: "1px solid rgba(34,36,38,.1)",
                  }}
                >
                  <h5 style={{ fontSize: "1.28571429em", marginBottom: 0 }}>
                    {summary.ApprovedBy}
                  </h5>
                  <span
                    className="date"
                    style={{
                      pageBreakInside: "avoid",
                      color: "rgba(0,0,0,.4)",
                    }}
                  >
                    {" "}
                    {dayjs(summary.ApprovalDate).format("MMM D, YYYY")}
                  </span>
                </td>
              </tfoot>
            </table>
          ) : (
            // </>
            <Modal
              closeOnDimmerClick={false}
              open={modalOpen}
              onClose={handleModalClose}
              size={"tiny"}
              trigger={
                <Button primary onClick={handleModalOpen}>
                  Sign Approval
                </Button>
              }
            >
              <Modal.Header>Sign Approval</Modal.Header>
              <Modal.Content>
                <Form style={{ marginBottom: 10 }}>
                  <label>Your name</label>
                  <Form.Input
                    value={approvedBy}
                    onChange={(e) => setApprovedBy(e.target.value)}
                  />
                </Form>

                <div>
                  <label>Please sign below</label>
                  <SignaturePad
                    ref={sigCanvas}
                    canvasProps={{ className: "contractSignatureCanvas" }}
                  />
                </div>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  disabled={loading}
                  loading={loading}
                  color="green"
                  onClick={save}
                >
                  <Icon name="save" /> Save
                </Button>
                <Button color="yellow" onClick={clear}>
                  <Icon name="eraser" /> Clear
                </Button>
                <Button onClick={() => setModalOpen(false)}>
                  <Icon name="remove" /> Close
                </Button>
              </Modal.Actions>
            </Modal>
          )}
        </div>
        <div className="col-50">
          <table className="DetailedContract__table signatures">
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: "bold",
                    borderBottom: "1px solid rgba(34,36,38,.1)",
                    paddingTop: 7,
                    paddingBottom: 7,
                    pageBreakInside: "avoid",
                    background: "#fff",
                  }}
                >
                  Nexus 5 Group
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ background: "rgba(0,0,0,.05)", padding: 0 }}>
                  <img
                    alt={`John Hinman signature`}
                    src={n5Signature}
                    style={{ display: "block", width: "100%" }}
                  />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <td
                style={{
                  padding: "8px 10px",
                  borderTop: "1px solid rgba(34,36,38,.1)",
                }}
              >
                <h5 style={{ fontSize: "1.28571429em", marginBottom: 0 }}>
                  John Hinman
                </h5>
                <span
                  className="date"
                  style={{
                    pageBreakInside: "avoid",
                    color: "rgba(0,0,0,.4)",
                  }}
                >
                  {dayjs(summary.ContractDate).format("MMMM D, YYYY")}
                </span>
              </td>
            </tfoot>
          </table>
        </div>
        {summary.Recipient === "client" && (
          <div style={{ marginTop: "10px", marginRight: "10px" }}>
            <p style={{ fontSize: "10px" }}>
              <strong>CONSENT OF OWNER:</strong>
            </p>
            <p style={{ fontSize: "10px", textAlign: "justify" }}>
              <strong>
                FOR PROJECTS LOCATED IN MISSOURI. CONSENT IS HEREBY GIVEN FOR
                FILING OF MECHANIC’S LIENS BY ANY PERSON WHO SUPPLIES MATERIALS
                OR SERVICES FOR THE WORK DESCRIBED IN THIS CONTRACT ON THE
                PROPERTY ON WHICH IT IS LOCATED IF HE IS NOT PAID.
              </strong>
            </p>
          </div>
        )}
      </div>

      <div className="DetailedContract__row" style={{ position: "relative" }}>
        {summary.Recipient === "client" &&
        summary.Exclusions &&
        summary.Exclusions.length > 0 ? (
          <ExclusionsMessage exclusions={summary.Exclusions} />
        ) : null}
        <Message size="mini" style={{ pageBreakInside: "avoid" }}>
          <Message.Header style={{ fontSize: 14 }}>
            Contract Documents & General Conditions
          </Message.Header>
        </Message>

        <ContractRequirements summary={summary} />
      </div>
    </Container>
  );
};

export default DetailedContractNew;
