import {
  SET_INCLUSION_MENU_SELECTION,
  GET_CONDITION_LIST_BEGIN,
  GET_CONDITION_LIST_SUCCESS,
  GET_CONDITION_LIST_FAILURE,
  ADD_CONDITION_BEGIN,
  ADD_CONDITION_SUCCESS,
  ADD_CONDITION_FAILURE,
  DEL_CONDITION_BEGIN,
  DEL_CONDITION_SUCCESS,
  DEL_CONDITION_FAILURE,
  SET_CONDITION_SUCCESS,
} from "../actions/project/inclusion/inclusionActions";

const INITIAL_DATA = {
  selectedCategoryID: "",
  selectedSubCategoryCode: "",
};

const inclusionReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case SET_INCLUSION_MENU_SELECTION:
      if (
        state.selectedCategoryID !== action.payload.selectedCategoryID ||
        state.selectedSubCategoryCode !== action.payload.selectedSubCategoryCode
      )
        return {
          ...state,
          returnUrl: action.payload.returnUrl,
          selectedCategoryID: action.payload.selectedCategoryID,
          selectedSubCategoryCode: action.payload.selectedSubCategoryCode,
        };

      // default return the current state...
      return state;
    case GET_CONDITION_LIST_BEGIN:
      return {
        ...state,
        conditionList: [],
        loading: true,
        error: null,
      };
    case GET_CONDITION_LIST_SUCCESS:
      return {
        ...state,
        conditionList: action.payload,
        loading: false,
        error: null,
      };
    case GET_CONDITION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_CONDITION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_CONDITION_SUCCESS:
      return {
        ...state,
        loading: false,
        conditionList: action.payload,
        error: null,
      };
    case ADD_CONDITION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DEL_CONDITION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DEL_CONDITION_SUCCESS:
      return {
        ...state,
        loading: false,
        conditionList: action.payload,
        error: null,
      };
    case DEL_CONDITION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_CONDITION_SUCCESS:
      return {
        ...state,
        loading: false,
        conditionList: action.payload,
      };
    default:
      return state;
  }
};

export default inclusionReducer;
