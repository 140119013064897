import React from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import Inclusions from "./Inclusions/Inclusions";
import Dashboard from "./Dashboard/Dashboard";
import DashboardYearOnly from "./DashboardYearOnly/DashboardYearOnly";

import Project from "./Project/Project";
import SurveyListItems from "./SurveyList/SurveyListItems";
import CompanyGoalsItems from "./CompanyGoalsItems/CompanyGoalsItems";
import { useSelector } from "react-redux";
import { userIsInRole } from "../../../util";
import TeamMemberGoalsItems from "./TeamMemberGoalsItems/TeamMemberGoalsItems";
import TeamMemberBonusPoolItems from "./TeamMemberBonusPoolItems/TeamMemberBonusPoolItems";
import PayoutIncentive from "./PayoutIncentive/PayoutIncentive";
import IncentiveAnalysisItems from "./IncentiveAnalysis/IncentiveAnalysisItems";
import ConditionApprovalMenuITems from "./ConditionApprovalMenuItems/ConditionApprovalMenuItems";

const MenuItems = (props) => {
  const state = useSelector((state) => state);

  let userIsAdmin = userIsInRole(state, 2);
  let userIsSuperuser = userIsInRole(state, 1);

  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path={[
            "/browser/projects/inclusions/:id",
            "/browser/projects/:id/inclusions/:mainCategory/:subCategory",
          ]}
        >
          <Inclusions />
        </Route>
        <Route exact path="/browser/projects/:id">
          <Project />
        </Route>
        <Route path="/dashboard/kpi">
          <Dashboard />
        </Route>
        <Route path="/dashboard">
          <DashboardYearOnly />
        </Route>

        <Route path="/survey-list">
          <SurveyListItems />
        </Route>
        <Route path="/incentive-analysis">
          <IncentiveAnalysisItems />
        </Route>
        {/* ADMIN ROUTES */}

        <Route path="/company-goals">
          {userIsAdmin || userIsSuperuser ? <CompanyGoalsItems /> : null}
        </Route>
        <Route path="/team-member-goals">
          {userIsAdmin || userIsSuperuser ? <TeamMemberGoalsItems /> : null}
        </Route>
        <Route path="/team-member-bonus-pool">
          {userIsAdmin || userIsSuperuser ? <TeamMemberBonusPoolItems /> : null}
        </Route>
        <Route path="/incentive-payout">
          {userIsAdmin || userIsSuperuser ? <PayoutIncentive /> : null}
        </Route>
        <Route path="/condition-approval">
          {userIsAdmin || userIsSuperuser ? (
            <ConditionApprovalMenuITems />
          ) : null}
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default withRouter(MenuItems);
