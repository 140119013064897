import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { Button, Form, Header, Modal, Table } from "semantic-ui-react";
import CourseCheckboxes from "../EditCourseModal/CourseCheckboxes/CourseCheckboxes";

const AddCourseModal = ({
  addModalOpen,
  setAddModalOpen,
  uid,
  options,
  getSafetyTrainingHistory,
  gridApi,
}) => {
  const [courseID, setCourseID] = useState("");
  const [courseDate, setCourseDate] = useState("");
  const [attendanceData, setAttendanceData] = useState([]);

  useEffect(() => {
    if (courseID && courseDate) {
      // Axios call to get course Attendance
      Axios.get(
        `https://www.ce-knet.com/DesktopModules/XModPro/Feed.aspx?xfd=NS1_GetSafetyAttendance&pid=0&c=${courseID}&d=${moment(
          courseDate
        ).format("L")}`,
        { withCredentials: false }
      ).then((res) => {
        setAttendanceData(res.data);
        return () => {
          gridApi.refreshCells();
        };
      });
    }
  }, [courseID, courseDate]);

  const handleOpen = () => {
    setAddModalOpen(true);
  };

  const handleClose = () => {
    setCourseDate("");
    setCourseID("");
    getSafetyTrainingHistory();
    setAddModalOpen(false);
  };

  return (
    <Modal open={addModalOpen} onOpen={handleOpen} onClose={handleClose}>
      <Header content="Log Training Attendance" />
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Dropdown
              label="Select Course"
              search
              selection
              selectOnBlur={false}
              options={options}
              onChange={(e, { value }) => setCourseID(value)}
              value={courseID}
            />
            {/* <Form.Field> */}
            {/* <label>Select Date</label> */}
            <SemanticDatepicker
              datePickerOnly={true}
              format="MM-DD-YYYY"
              clearable={false}
              label="Course Date"
              value={courseDate}
              onChange={(e, { value }) => setCourseDate(value)}
            />
            {/* </Form.Field> */}
          </Form.Group>
        </Form>
        {Array.isArray(attendanceData) && attendanceData.length ? (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Participant</Table.HeaderCell>
                <Table.HeaderCell width="2">Required</Table.HeaderCell>
                <Table.HeaderCell width="2">Completed</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Array.isArray(attendanceData) && attendanceData.length
                ? attendanceData.map((user) => (
                    <CourseCheckboxes
                      user={user}
                      courseID={courseID}
                      courseDate={courseDate}
                      uid={uid}
                      setAttendanceData={setAttendanceData}
                    />
                  ))
                : null}
            </Table.Body>
          </Table>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AddCourseModal;
