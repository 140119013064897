import React, { useEffect, useState } from "react";
import CompanyGoals from "./CompanyGoals";

import { useSelector } from "react-redux";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";

const CompanyGoalsWrapper = () => {
  const year = useSelector((state) => state.companyGoalsMenu.year);
  const [data, setData] = useState(null);

  const getFinancials = (callback) => {
    Axios.get(`${API_ROOT}/api/dashboard/financials?year=${year}`)
      .then((res) => {
        setData(res.data.financials || null);
        if (callback) {
          callback();
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (year) {
      getFinancials();
    }
  }, [year]);
  return (
    <CompanyGoals
      rowData={data}
      getFinancials={getFinancials}
      setData={setData}
    />
  );
};

export default CompanyGoalsWrapper;
