import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.dark.css";

import "react-table-6/react-table.css";
import "semantic-ui-css/semantic.min.css";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { isNotEnv, getEnv } from "./util";

if (isNotEnv("local")) {
  Sentry.init({
    dsn:
      "https://593fd79e65db4c8288490423410849d2@o1281079.ingest.sentry.io/6489441",
    integrations: [new BrowserTracing()],
    environment: getEnv(),
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.5,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
