import React, { Component } from "react";
import { Comment, Form, Button } from "semantic-ui-react";
import Note from "../Note/Note";
import Notifications from "../Notifications/Notifications";
import Axios from "axios";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { API_ROOT } from "../../../../api-config";

Axios.defaults.withCredentials = true;

class NotesContainer extends Component {
  state = {
    projectNotes: [],
    currentProject: null,
    noteText: "",
    loading: false,
    includeClient: false,
    isAdmin: 0,
    isUserAssociated: false,
    userID: "",
    project: null,
  };

  getProject = () => {
    if (this.state.currentProject) {
      Axios.get(
        `${API_ROOT}/api/project/project?projectID=${this.state.currentProject}`
      )
        .then((res) => {
          if (res.data) {
            this.setState({ project: res.data.projects[0] });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  getProjectNotes = () => {
    Axios.get(`${API_ROOT}/api/project/projectnotes`, {
      params: {
        projectID: this.state.currentProject,
      },
    })
      .then((res) => {
        this.setState({
          projectNotes: res.data ? JSON.parse(res.data.projectNotes) : [],
        });
      })
      .catch((error) => {
        console.log(error);
      });

    // const projectData = this.props.projects.find(
    //   project => project.ProjectID === this.state.currentProject
    // );

    if (this.state.user && this.state.project) {
      if (
        this.state.userID === this.state.project.EstimatedBy ||
        this.state.userID === this.state.project.SoldBy ||
        this.state.userID === this.state.project.ManagedBy
      ) {
        this.setState({
          isUserAssociated: true,
        });
      } else {
        this.setState({
          isUserAssociated: false,
        });
      }
    }
  };

  componentDidMount() {
    this.setState(
      {
        currentProject: this.props.projectID,
        userID: this.props.user.UserID,
      },
      () => {
        this.getProjectNotes(this.props.projectID);
      }
    );

    // Check to see if user is NS1Admin or Administrator
    const roles = this.props.user[0].Roles;
    const isAdmin =
      _.some(roles, { RoleID: 1 }) || _.some(roles, { RoleID: 2 });
    this.setState({
      isAdmin,
    });

    // this.getProjectNotifications(this.props.projectID);
  }

  componentDidUpdate(prevProps) {
    if (this.props.projectID !== prevProps.projectID) {
      this.setState(
        {
          currentProject: this.props.projectID,
        },
        () => {
          this.getProjectNotes();
          // this.getProjectNotifications(this.props.projectID);
        }
      );
    }
  }

  handleChange = (e) => {
    this.setState({
      noteText: e.target.value,
    });
  };

  deleteNote = (noteID) => {
    Axios.delete(`${API_ROOT}/api/project/projectnote/${noteID}`).then(
      (res) => {
        if (res.data.deletedNote) {
          const projectNotes = this.state.projectNotes.filter(
            (note) => note.NoteID !== noteID
          );
          this.setState({ projectNotes });
        }
      }
    );
  };

  getNoteEmailDetails = () => {
    Axios.get(`${API_ROOT}/api/project/note-email-details`, {
      params: {
        projectID: this.props.projectID,
      },
    }).then((res) => {
      const emailDetails = res.data.emailDetails;
      let clientEmail = emailDetails.clientEmail;
      const subject = emailDetails.subject;
      let email =
        (emailDetails.emailList ? emailDetails.emailList + ";" : "") +
        (this.state.includeClient && clientEmail ? clientEmail : "");

      const displayName = this.props.user[0].DisplayName;
      const enteredBy =
        "<br /><br />Entered by " + displayName + ", " + moment().format("l");

      let html = this.state.noteText + enteredBy;

      Axios.post(`${API_ROOT}/send-email`, {
        email: email,
        cc: "",
        bcc: "SoftwareDevelopment@cohenesrey.com",
        subject,
        text: "",
        html,
      })
        .then((res) => {
          this.setState({
            noteText: "",
            loading: false,
            includeClient: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  handleAddNoteButtonClick = () => {
    if (!this.state.noteText) {
      return;
    }

    this.setState({ loading: true });

    Axios.post(`${API_ROOT}/api/project/projectnote`, {
      params: {
        projectID: this.state.currentProject,
        note: this.state.noteText,
        ADID: this.props.user.ADID,
        includeClientInd: this.state.includeClient ? 1 : 0,
      },
    })
      .then((res) => {
        if (res.data.projectNotes) {
          let projectNotes = JSON.parse(res.data.projectNotes);
          this.getNoteEmailDetails();
          this.setState({
            projectNotes,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });

    // clear note in state
    // this.setState({ noteText: "", loading: false });
  };

  handleCheckbox = (e) => {
    this.setState({
      includeClient: !this.state.includeClient,
    });
  };

  // getProjectNotifications = projectID => {
  //   // const clientContactEmail = project.ClientContactEmail;

  //   Axios.get(
  //     `${API_ROOT}/api/project/projectnotifications/${parseInt(projectID)}`
  //   ).then(res => {
  //     const notifications = res.data.notifications
  //       ? JSON.parse(res.data.notifications)
  //       : [];
  //     // this.setState({ notifications: JSON.parse(res.data.notifications) });
  //     const emailaddresses = notifications
  //       .map(e => {
  //         return e.EmailAddress;
  //       })
  //       .filter(Boolean)
  //       .join(";");
  //     this.setState({ emailList: emailaddresses });
  //   });
  // };

  render() {
    return (
      <Comment.Group style={{ maxWidth: "none" }}>
        <div style={{ maxHeight: 300, overflow: "auto", maxWidth: "none" }}>
          {!this.state.projectNotes
            ? ""
            : this.state.projectNotes.map((note) => (
                <Note
                  deleteNote={this.deleteNote}
                  key={note.NoteID}
                  noteID={note.NoteID}
                  projectID={note.ProjectID}
                  author={note.DisplayName}
                  text={note.NoteText}
                  date={note.CreatedDate}
                  includeClientInd={note.IncludeClientInd}
                />
              ))}
        </div>
        <Form reply>
          <Form.TextArea
            value={this.state.noteText}
            onChange={this.handleChange}
          />
          <Form.Field>
            <Notifications projectID={this.props.projectID} />
          </Form.Field>

          {this.state.isAdmin || this.state.isUserAssociated ? (
            <Form.Checkbox
              checked={this.state.includeClient}
              onChange={this.handleCheckbox}
              label="Include client"
            />
          ) : (
            ""
          )}

          <Button
            onClick={this.handleAddNoteButtonClick}
            content="Add Note"
            loading={this.state.loading ? true : false}
            labelPosition="left"
            icon="edit"
            primary
          />
        </Form>
      </Comment.Group>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: JSON.parse(state.auth.user),
    // projects: state.project.projects
  };
};

export default connect(mapStateToProps)(NotesContainer);
