import React, { useContext } from "react";

import { useShowV1 } from "../../../hooks/useQuery";
import Inclusions from "./Inclusions";
import InclusionsV2 from "../../../screens/projects/inclusions/List";
import CurrentProject from "../../../screens/projects/CurrentProject";

export default () => {
  const { useV2 } = useContext(CurrentProject);
  const showV1 = useShowV1();

  return (
    <>
      {(!useV2() || showV1) && (
        <>
          <Inclusions />
          <hr
            style={{
              borderColor: "orange",
              borderStyle: "dashed",
              borderWidth: "medium",
            }}
          />
        </>
      )}
      {useV2() && <InclusionsV2 />}
    </>
  );
};
