export const SET_INCENTIVE_ANALYSIS_MENU_SELECTION =
  "SET_INCENTIVE_ANALYSIS_MENU_SELECTION";
export const RESET_INCENTIVE_ANALYSIS_MENU_SELECTION =
  "RESET_INCENTIVE_ANALYSIS_MENU_SELECTION";

export const setIncentiveAnalysisMenuSelection = (selection) => ({
  type: SET_INCENTIVE_ANALYSIS_MENU_SELECTION,
  payload: selection,
});

export const resetIncentiveAnalysisMenuSelection = () => ({
  type: RESET_INCENTIVE_ANALYSIS_MENU_SELECTION,
});
