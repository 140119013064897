import React from "react";
import DataGrid, { Column } from "devextreme-react/data-grid";
import { Icon } from "semantic-ui-react";

import numeral from "numeral";
import { API_ROOT } from "../../../../api-config";
import Axios from "axios";
import FileSaver from "file-saver";
import { parse } from "json2csv";

const RevenueLeaderboard = (props) => {
  const { dataSource, division, userRole, year } = props;

  const getCSVData = (userID) => {
    Axios.get(
      `${API_ROOT}/api/dashboard/jobs-by-team-member-by-month?u=${userID}&y=${year}&d=${division}&g=Revenue+Actual&r=${userRole}`
    ).then((response) => {
      const fields = [
        "Job",
        "JobName",
        "KeyType",
        "Year",
        "TeamMember",
        "Division",
        "Role",
        "GoalType",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
        "Total",
      ];
      const opts = { fields };

      try {
        const csv = parse(response.data.data, opts);

        const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        FileSaver.saveAs(csvData, "N5JobsByTeamMember.csv");
      } catch (err) {
        console.error(err);
      }
    });

    // console.log(response.data.data);
  };

  return (
    <DataGrid
      showBorders
      showColumnLines
      showRowLines
      sorting={{ mode: "none" }}
      paging={{ enabled: false }}
      dataSource={dataSource}
    >
      <Column
        width={200}
        dataField="TeamMember"
        cellRender={(options) => {
          const fieldValue = options.value;
          if (
            options.rowType === "data" &&
            options.data.precedence > 2 &&
            options.columnIndex === 0
          ) {
            return (
              <div>
                <div>
                  <Icon
                    link
                    onClick={() =>
                      getCSVData(options.data.userid, options.data.Type)
                    }
                    color="blue"
                    name="cloud download"
                  />
                  {fieldValue}
                </div>
              </div>
            );
          } else if (options.data.precedence === 1) {
            return (
              <div
                style={{ color: "#2185d0", fontSize: 16, fontWeight: "bold" }}
              >
                {fieldValue}
              </div>
            );
          } else if (options.data.precedence === 2) {
            return (
              <div
                style={{
                  color: "white",
                  //   color: "rgb(254, 80, 0)",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                {fieldValue}
              </div>
            );
          }
        }}
      />
      {[
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
        "Total",
      ].map((month) => {
        return (
          <Column
            key={month}
            dataField={month}
            cellRender={(options) => {
              const fieldValue = options.value;
              if (options.data.precedence === 1) {
                return (
                  <div
                    style={{
                      color: "#2185d0",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    {numeral(fieldValue).format("0,0")}
                  </div>
                );
              } else if (options.data.precedence === 2) {
                return (
                  <div
                    style={{
                      color: "white",
                      //   color: "rgb(254, 80, 0)",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    {numeral(fieldValue).format("0,0")}
                  </div>
                );
              } else {
                return <div>{numeral(fieldValue).format("0,0")}</div>;
              }
            }}
          />
        );
      })}
    </DataGrid>
  );
};

export default RevenueLeaderboard;
