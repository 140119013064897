export const SET_TEAM_MEMBER_BONUS_POOL_MENU_SELECTION =
  "SET_TEAM_MEMBER_BONUS_POOL_MENU_SELECTION";
export const RESET_TEAM_MEMBER_BONUS_POOL_MENU_SELECTION =
  "RESET_TEAM_MEMBER_BONUS_POOL_MENU_SELECTION";

export const setTeamMemberBonusPoolMenuSelection = (selection) => ({
  type: SET_TEAM_MEMBER_BONUS_POOL_MENU_SELECTION,
  payload: selection,
});

export const resetTeamMemberBonusPoolMenuSelection = () => ({
  type: RESET_TEAM_MEMBER_BONUS_POOL_MENU_SELECTION,
});
