import {
  SET_TEAM_MEMBER_BONUS_POOL_MENU_SELECTION,
  RESET_TEAM_MEMBER_BONUS_POOL_MENU_SELECTION,
} from "../actions/topMenu/teamMemberBonusPoolActions";
import * as moment from "moment";

const year = moment().year();
const quarter = moment().quarter();

const INITIAL_DATA = {
  year,
  quarter,
};

const teamMembersBonusPoolReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case SET_TEAM_MEMBER_BONUS_POOL_MENU_SELECTION:
      return {
        ...state,
        year: action.payload.year,
        quarter: action.payload.quarter,
      };
    case RESET_TEAM_MEMBER_BONUS_POOL_MENU_SELECTION:
      return INITIAL_DATA;
    default:
      return state;
  }
};

export default teamMembersBonusPoolReducer;
