import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Modal, Header, Button, Icon } from "semantic-ui-react";

import { API_ROOT } from "../../../../../api-config";

export const ContractValidationWarningModal = ({
  show,
  guid,
  callback,
  onClose,
}) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (show)
      Axios.get(`${API_ROOT}/api/contract/checkclientcontractready`, {
        params: {
          guid,
        },
      })
        .then((res) => {
          const {
            contractDescriptionDoneInd,
            clientWorkDaysDoneInd,
            baseContractReadyInd,
            hasAlternateConditionsInd,
            hasBudgetConditionsInd,
          } = res.data.doneInds;

          const err = [];
          if (clientWorkDaysDoneInd === 0)
            err.push("Client work days must be filled out.");
          if (contractDescriptionDoneInd === 0)
            err.push("Contract description is missing.");
          if (baseContractReadyInd === 0)
            err.push("Base contract must be approved before CO can be sent.");
          if (hasAlternateConditionsInd === 1)
            err.push("This project has unresolved alternate inclusions.");
          if (hasBudgetConditionsInd === 1)
            err.push("This project still has placeholder budget inclusions.");
          setErrors(err);

          setTitle("Project Errors Detected");

          if (
            clientWorkDaysDoneInd === 0 ||
            contractDescriptionDoneInd === 0 ||
            baseContractReadyInd === 0 ||
            hasAlternateConditionsInd === 1 ||
            hasBudgetConditionsInd === 1
          ) {
            setOpen(true);
          } else {
            callback();
            if (onClose) onClose();
          }
        })
        .catch((err) => {
          console.log(err);
        });
  }, [show]);

  return show ? (
    <Modal open={open} onClose={() => setOpen(false)} basic size="tiny">
      <Header icon="exclamation" content={title} />
      <Modal.Content>
        <ul>
          {errors.map((x, idx) => (
            <li key={idx}>
              <h3>{x}</h3>
            </li>
          ))}
        </ul>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="green"
          onClick={() => {
            setOpen(false);
            if (onClose) onClose();
          }}
        >
          <Icon name="checkmark" /> Got it
        </Button>
      </Modal.Actions>
    </Modal>
  ) : null;
};
