import React, { useState, Fragment, useEffect } from "react";
import { Header, Segment } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";
// import NotesWrapper from "./NotesWrapper/NotesWrapper";
import { Switch, Route } from "react-router-dom";
import ProjectMenu from "./ProjectMenu/ProjectMenu";
import ProjectInfo from "./ProjectInfo/ProjectInfo";
import JobStartup from "./JobStartup/JobStartup";
import NotesWrapper from "./NotesWrapper/NotesWrapper";
import ConditionInfoWrapper from "./ConditionInfo/ConditionInfoWrapper";

const Project = ({ projectInfo, projectID, fetchProjectInfo }) => {
  // const [activeIndex, setActiveIndex] = useState(null);
  const [projectCategories, setProjectCategories] = useState([]);

  // const handleClick = (e, titleProps) => {
  //   const { index } = titleProps;
  //   const newIndex = activeIndex === index ? -1 : index;

  //   setActiveIndex(newIndex);
  // };

  const fetchProjectCategories = () => {
    Axios.get(`${API_ROOT}/api/project/project-categories`, {
      params: {
        projectID,
      },
    })
      .then((res) => {
        if (res && res.data) {
          setProjectCategories(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (projectID) {
      fetchProjectCategories();
    }
  }, [projectID]);

  return (
    <Fragment>
      <Segment
        inverted
        color="blue"
        style={{ borderRadius: 0, paddingTop: 20, marginBottom: 0 }}
      >
        <Header as="h5">
          {`${projectInfo.companyName} - ${projectInfo.workLocationName}`}
          <br />
          {`${projectInfo.projectID} - ${projectInfo.projectName}`}
        </Header>
      </Segment>
      <Switch>
        <Route exact path="/m/projects/:id">
          <ProjectMenu
            projectID={projectID}
            projectCategories={projectCategories}
          />
        </Route>
        <Route exact path="/m/projects/:id/project-info">
          <ProjectInfo projectID={projectID} projectInfo={projectInfo} />
        </Route>
        <Route exact path="/m/projects/:id/job-startup">
          <JobStartup projectID={projectID} />
        </Route>
        <Route exact path="/m/projects/:id/notes">
          <NotesWrapper projectID={projectID} projectInfo={projectInfo} />
        </Route>
        <Route exact path="/m/projects/:id/:category">
          <ConditionInfoWrapper
            projectInfo={projectInfo}
            fetchProjectInfo={fetchProjectInfo}
          />
        </Route>
      </Switch>
    </Fragment>
  );
};

export default Project;
