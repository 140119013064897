import React from "react";
import {
  Container,
  Card,
  Table,
  CardDescription,
  Button,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

const IncentivePayout = ({
  incentiveSummary,
  payoutWorkSheetQ1,
  payoutWorkSheetQ2,
  payoutWorkSheetQ3,
  payoutWorkSheetQ4,
  incentivePool,
  year,
}) => {
  return (
    <Container
      style={{
        paddingLeft: 10,
        paddingRight: 10,
        minWidth: 1000,
      }}
      fluid
    >
      <div>
        <h1 style={{ color: "rgb(254, 80, 0)", marginLeft: 40 }}>
          Nexus 5 Group Incentive Payouts
        </h1>
      </div>
      <Card fluid style={{ maxWidth: 1000 }}>
        <Card.Content>
          <Card.Header content="Incentive Summary by Team Member" />
          <Card.Description>
            <Table unstackable compact="very" celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Position</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Q1</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Q2</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Q3</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Q4</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Total</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Array.isArray(incentiveSummary) && incentiveSummary.length
                  ? incentiveSummary.map((obj, idx) => (
                      <Table.Row key={`${obj.Name}_${idx}`}>
                        <Table.Cell>{obj.Name}</Table.Cell>
                        <Table.Cell>{obj.Position}</Table.Cell>
                        <Table.Cell>{obj.Q1}</Table.Cell>
                        <Table.Cell>{obj.Q2}</Table.Cell>
                        <Table.Cell>{obj.Q3}</Table.Cell>
                        <Table.Cell>{obj.Q4}</Table.Cell>
                        <Table.Cell>{obj.Total}</Table.Cell>
                      </Table.Row>
                    ))
                  : null}
              </Table.Body>
            </Table>
          </Card.Description>
        </Card.Content>
      </Card>
      <Card fluid style={{ maxWidth: 1000 }}>
        <Card.Content>
          <Card.Header content="Pool Calculation" />
          <CardDescription>
            <Table unstackable compact="very" celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Q1</Table.HeaderCell>
                  <Table.HeaderCell>Q2</Table.HeaderCell>
                  <Table.HeaderCell>Q3</Table.HeaderCell>
                  <Table.HeaderCell>Q4</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Array.isArray(incentivePool) && incentivePool.length
                  ? incentivePool.map((obj, idx) => (
                      <Table.Row key={`${obj.name}_${idx}`}>
                        <Table.Cell>{obj.name}</Table.Cell>
                        <Table.Cell>{obj.q1}</Table.Cell>
                        <Table.Cell>{obj.q2}</Table.Cell>
                        <Table.Cell>{obj.q3}</Table.Cell>
                        <Table.Cell>{obj.q4}</Table.Cell>
                      </Table.Row>
                    ))
                  : null}
              </Table.Body>
            </Table>
          </CardDescription>
        </Card.Content>
      </Card>
      {[
        payoutWorkSheetQ1,
        payoutWorkSheetQ2,
        payoutWorkSheetQ3,
        payoutWorkSheetQ4,
      ].map((arr, i) => (
        <Card key={i} fluid style={{ maxWidth: 1000 }}>
          <Card.Content>
            <Card.Header
              content={`Individual Incentive Worksheet - Quarter ${i + 1}`}
            />
            <CardDescription>
              <Table unstackable compact="very" celled style={{ fontSize: 10 }}>
                <Table.Header>
                  <Table.Row style={{ fontSize: 10 }}>
                    <Table.HeaderCell style={{ width: 100 }}>
                      Team Member
                    </Table.HeaderCell>
                    <Table.HeaderCell>Ldrshp Division</Table.HeaderCell>
                    <Table.HeaderCell>Stars</Table.HeaderCell>
                    <Table.HeaderCell>X</Table.HeaderCell>
                    <Table.HeaderCell>Hours</Table.HeaderCell>
                    <Table.HeaderCell>=</Table.HeaderCell>
                    <Table.HeaderCell>Points</Table.HeaderCell>
                    <Table.HeaderCell>/</Table.HeaderCell>
                    <Table.HeaderCell>Total Points</Table.HeaderCell>
                    <Table.HeaderCell>=</Table.HeaderCell>
                    <Table.HeaderCell>Profit Share %</Table.HeaderCell>
                    <Table.HeaderCell>X</Table.HeaderCell>
                    <Table.HeaderCell>Profit Share Pool</Table.HeaderCell>
                    <Table.HeaderCell>=</Table.HeaderCell>
                    <Table.HeaderCell>Profit Share</Table.HeaderCell>
                    <Table.HeaderCell>Division Gross Profit</Table.HeaderCell>
                    <Table.HeaderCell>X</Table.HeaderCell>
                    <Table.HeaderCell>Division Goal %</Table.HeaderCell>
                    <Table.HeaderCell>=</Table.HeaderCell>
                    <Table.HeaderCell>Ldrshp Pool</Table.HeaderCell>
                    <Table.HeaderCell>X</Table.HeaderCell>
                    <Table.HeaderCell>Ldrshp Share %</Table.HeaderCell>
                    <Table.HeaderCell>=</Table.HeaderCell>
                    <Table.HeaderCell>Ldrshp Profit Share</Table.HeaderCell>
                    <Table.HeaderCell>Total Profit Share</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {Array.isArray(arr) && arr.length
                    ? arr.map((obj) => (
                        <Table.Row key={obj["Team Member"]}>
                          <Table.Cell>{obj["Team Member"]}</Table.Cell>
                          <Table.Cell>{obj["Ldrshp Division"]}</Table.Cell>
                          <Table.Cell>{obj["Stars"]}</Table.Cell>
                          <Table.Cell>X</Table.Cell>
                          <Table.Cell>{obj["Hours"]}</Table.Cell>
                          <Table.Cell>=</Table.Cell>
                          <Table.Cell>{obj["Points"]}</Table.Cell>
                          <Table.Cell>/</Table.Cell>
                          <Table.Cell>{obj["Total Points"]}</Table.Cell>
                          <Table.Cell>=</Table.Cell>
                          <Table.Cell>{obj["Profit Share %"]}</Table.Cell>
                          <Table.Cell>X</Table.Cell>
                          <Table.Cell>{obj["Profit Share Pool"]}</Table.Cell>
                          <Table.Cell>=</Table.Cell>
                          <Table.Cell>{obj["Profit Share"]}</Table.Cell>
                          <Table.Cell>
                            {obj["Division Gross Profit"]}
                          </Table.Cell>
                          <Table.Cell>X</Table.Cell>
                          <Table.Cell>{obj["Division Goal %"]}</Table.Cell>
                          <Table.Cell>=</Table.Cell>
                          <Table.Cell>{obj["Ldrshp Pool"]}</Table.Cell>
                          <Table.Cell>X</Table.Cell>
                          <Table.Cell>{obj["Ldrshp Share %"]}</Table.Cell>
                          <Table.Cell>=</Table.Cell>
                          <Table.Cell>{obj["Ldrshp Profit Share"]}</Table.Cell>
                          <Table.Cell>{obj["Total Profit Share"]}</Table.Cell>
                        </Table.Row>
                      ))
                    : null}
                </Table.Body>
              </Table>
            </CardDescription>
          </Card.Content>
        </Card>
      ))}
      <div style={{ marginBottom: 20 }}>
        <Link
          target="_blank"
          to={`/incentive-payout-certificate?y=${year}&q=1`}
        >
          <Button primary>Q1 Certificate</Button>
        </Link>
        <Link
          target="_blank"
          to={`/incentive-payout-certificate?y=${year}&q=2`}
        >
          <Button primary>Q2 Certificate</Button>
        </Link>
        <Link
          target="_blank"
          to={`/incentive-payout-certificate?y=${year}&q=3`}
        >
          <Button primary>Q3 Certificate</Button>
        </Link>
        <Link
          target="_blank"
          to={`/incentive-payout-certificate?y=${year}&q=4`}
        >
          <Button primary>Q4 Certificate</Button>
        </Link>
      </div>
    </Container>
  );
};

export default IncentivePayout;
