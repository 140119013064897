import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { DataGrid, Column } from "devextreme-react/data-grid";

import { API_ROOT } from "../../../api-config";

export default (options) => {
  const {
    data: { data: parentRow },
  } = options;

  const [loading, setLoading] = useState(true);
  const [datasource, setDatasource] = useState([]);

  useEffect(async () => {
    setLoading(true);
    const data = await Axios.get(
      `${API_ROOT}/api/kpi/timely-invoicing/${parentRow.userId}`,
      {
        params: {
          year: 2022,
          quarter: 2,
        },
      }
    ).then((results) => results.data);
    setDatasource(data);
    setLoading(false);
  }, [parentRow]);

  if (loading) return <h1>loading...</h1>;

  return (
    <DataGrid dataSource={datasource}>
      <Column
        caption="Project Url"
        dataField="projectId"
        cellRender={(options) => (
          <Link
            target="_blank"
            to={`/browser/projects/${options.data.projectId}`}
          >
            {options.data.projectId}
          </Link>
        )}
      />
      <Column caption="Type" dataField="jobType" />
      <Column
        caption="Invoiced On Time?"
        dataField="invoicedOnTime"
        cellRender={(options) => options.data.invoicedOnTime ? "Yes" : "No"}
      />
    </DataGrid>
  );
};
