import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./NewConditionApprovalTable.scss";
import numeral from "numeral";
import { Icon, Modal, Button } from "semantic-ui-react";

// const isNumeric = (num) => {
//   return !isNaN(num);
// };

const NewConditionApprovalTable = ({ data }) => {
  const [gridApi, setGridApi] = useState();

  const handleConfirmClick = (conditionID) => {
    console.log(conditionID);
  };

  const columnDefs = [
    {
      headerName: "Approve",
      field: "ConditionID",
      pinned: "left",
      width: 74,
      cellRendererFramework: (params) => {
        return (
          <Modal
            basic="very"
            trigger={<Icon color="blue" link name="checkmark" />}
          >
            <Modal.Actions>
              <Button onClick={() => handleConfirmClick(params.value)}>
                Confirm
              </Button>
            </Modal.Actions>
          </Modal>
        );
      },
    },
    { headerName: "Category", field: "SubCategoryCode", pinned: "left" },
    {
      headerName: "Condition Name",
      field: "ConditionName",
      pinned: "left",
      editable: true,
    },
    { headerName: "Created By", field: "CreatedByLastName", width: 120 },
    {
      headerName: "Internal Description",
      field: "InternalComments",
      editable: true,
    },
    {
      headerName: "Quantity",
      field: "TotalQuantities",
      width: 100,
      editable: true,
    },
    {
      headerName: "Measurement Type",
      field: "MeasurementType",
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["Each", "SqFt"],
      },
    },
    {
      headerName: "Subcontractor Description",
      field: "SubcontractorScope",
      editable: true,
    },
    {
      headerName: "Labor Hrs",
      field: "LaborHours",
      width: 80,
      editable: true,
    },
    {
      headerName: "Labor Rate",
      field: "LaborRate",
      width: 100,
      editable: true,
      valueFormatter: (params) => {
        return numeral(params.value).format("0,0.00");
      },
    },
    { headerName: "Labor Markup", field: "LaborMarkup", width: 110 },
    {
      headerName: "Labor Total",
      field: "LaborTotal",
      editable: true,
      width: 120,
      valueFormatter: (params) => {
        return numeral(params.value).format("0,0.00");
      },
    },
    {
      headerName: "Material Cost",
      field: "MaterialCost",
      width: 100,
      editable: true,
      valueFormatter: (params) => {
        return numeral(params.value).format("0,00.00");
      },
    },
    {
      headerName: "Material Markup",
      field: "MaterialMarkup",
      width: 120,
      editable: true,
    },
    {
      headerName: "Material Total",
      field: "MaterialTotal",
      width: 120,
      valueFormatter: (params) => {
        return numeral(params.value).format("0,0.00");
      },
    },
    {
      headerName: "Equipment Cost",
      field: "EquipmentCost",
      editable: true,
      width: 130,
      valueFormatter: (params) => {
        return numeral(params.value).format("0,0.00");
      },
    },
    {
      headerName: "Equipment Markup",
      field: "EquipmentMarkup",
      width: 130,
      editable: true,
    },
    { headerName: "Equipment Total", field: "EquipmentTotal", width: 120 },
  ];

  const onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleChange = (params) => {
    if (params.oldValue !== params.newValue) {
      console.log(params);
      const { field } = params.colDef;
      const value = params.newValue;

      const conditionID = params.data.ConditionID;
      console.log(field, value, conditionID);
      var rowNode = gridApi.getDisplayedRowAtIndex(params.rowIndex);
      rowNode.setData(data[0]);
      gridApi.flashCells({ rowNodes: [rowNode] });
    } else {
      return false;
    }
  };

  return (
    <div
      className="ag-theme-balham NewConditionApprovalTable"
      style={{
        height: "calc(100vh - 120px)",

        width: "100%",
      }}
    >
      <AgGridReact
        defaultColDef={{
          autoHeight: true,
          cellClass: "cell-wrap-text",
          // enableCellChangeFlash: true,
        }}
        columnDefs={columnDefs}
        rowData={data}
        onColumnResized={onColumnResized}
        onGridReady={onGridReady}
        onCellValueChanged={(params) => {
          // const { newValue} = params;
          // console.log(newValue);

          handleChange(params);
        }}
      />
    </div>
  );
};

export default NewConditionApprovalTable;
