import React, { useEffect, useState } from "react";
import { Grid, Segment, Header } from "semantic-ui-react";
import moment from "moment";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import { setForecast } from "../../../../actions/project/projectActions";

import "./Forecasting.scss";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import useIsMounted from "../../../../hooks/isMounted";

const Forecasting = (props) => {
  const [project, setProject] = useState({});
  const [forecastError, setForecastError] = useState(false);
  const [forecastErrorName, setForecastErrorName] = useState("");
  const [date] = useState(new Date());
  const [currentProjectID, setCurrentProjectID] = useState(
    parseInt(props.match.params.id)
  );

  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted.current) {
      setCurrentProjectID(parseInt(props.match.params.id));
    }
  }, [parseInt(props.match.params.id)]);

  useEffect(() => {
    Axios.get(
      `${API_ROOT}/api/project/project-forecasting?projectID=${currentProjectID}`
    )
      .then((res) => {
        // const project = res.data.projects[0];
        if (isMounted.current) {
          setProject(res.data.forecasting);
        }
      })
      .catch((err) => console.log(err));
  }, [currentProjectID]);

  const saveForecast = (values) => {
    let year;

    switch (values.yearNum) {
      case 1:
        year = date.getFullYear() - 1;
        break;
      case 2:
        year = date.getFullYear();
        break;
      case 3:
        year = date.getFullYear() + 1;
        break;
      default:
        return;
    }

    const month =
      new Date(Date.parse(values.month + " 1, 2012")).getMonth() + 1;
    const projectForecast = values.forecast === "" ? 0 : values.forecast / 100;

    Axios.post(`${API_ROOT}/api/project/forecast`, {
      params: {
        projectID: values.projectID,
        forecastYear: year,
        forecastMonth: month,
        forecastPercent: projectForecast,
        monthStr: values.monthStr,
      },
    })
      .then((res) => {
        if (isMounted.current) {
          setProject(JSON.parse(res.data.project)[0]);
        }
      })
      .catch((err) => console.log(err));

    setForecastError(false);
    setForecastErrorName("");
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (value > 100 || value < 0) {
      return;
    }

    if (!value) {
      value = 0;
    }

    const updatedProject = { ...project };
    updatedProject[name] = value / 100;
    setProject(updatedProject);
  };

  const calculateTotal = () => {
    const {
      jan1,
      jan2,
      jan3,
      feb1,
      feb2,
      feb3,
      mar1,
      mar2,
      mar3,
      apr1,
      apr2,
      apr3,
      may1,
      may2,
      may3,
      jun1,
      jun2,
      jun3,
      jul1,
      jul2,
      jul3,
      aug1,
      aug2,
      aug3,
      sep1,
      sep2,
      sep3,
      oct1,
      oct2,
      oct3,
      nov1,
      nov2,
      nov3,
      dec1,
      dec2,
      dec3,
    } = project;
    const total =
      jan1 +
      jan2 +
      jan3 +
      feb1 +
      feb2 +
      feb3 +
      mar1 +
      mar2 +
      mar3 +
      apr1 +
      apr2 +
      apr3 +
      may1 +
      may2 +
      may3 +
      jun1 +
      jun2 +
      jun3 +
      jul1 +
      jul2 +
      jul3 +
      aug1 +
      aug2 +
      aug3 +
      sep1 +
      sep2 +
      sep3 +
      oct1 +
      oct2 +
      oct3 +
      nov1 +
      nov2 +
      nov3 +
      dec1 +
      dec2 +
      dec3;
    return total;
  };

  const handleBlur = (e) => {
    const name = e.target.name;

    const projectID = project.ProjectID;
    const monthStr = name;
    const month = name.substring(0, 3);
    const yearNum = parseInt(name.substring(3, 4));
    const forecast = e.target.value ? parseInt(e.target.value) : 0;

    const total = calculateTotal();

    if (total > 1.0) {
      setForecastError(true);
      setForecastErrorName(name);
      return false;
    }

    const values = {
      projectID,
      month,
      yearNum,
      forecast,
      monthStr,
    };

    saveForecast(values);
  };

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  const monthsArr = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];

  return (
    <Grid
      className="Forecasting"
      style={{
        width: "35%",
        minWidth: 500,
        overflow: "auto",
        paddingRight: 30,
        marginRight: 30,
        paddingLeft: 20,
      }}
    >
      <Segment clearing basic style={{ margin: 0, padding: 0 }}>
        <Header
          floated="left"
          style={{
            color: "#fe5000",
            display: "inline-block",
            // marginLeft: 20
          }}
          as="h1"
        >
          Forecasting
        </Header>
        <Header
          floated="right"
          style={{
            color: "black",
            display: "inline-block",
            marginTop: 4,
          }}
          as="h2"
        >
          {calculateTotal() ? Math.round(calculateTotal() * 100) + "%" : "0%"}
        </Header>
      </Segment>
      <Grid.Row columns={13}>
        <Grid.Column style={{ padding: 0 }}>
          <table>
            <tbody>
              <tr>
                <td style={{ height: 23 }} />
              </tr>
              <tr>
                <td style={{ height: 24, paddingBottom: 9, color: "gray" }}>
                  {moment().year() - 1}
                </td>
              </tr>
              <tr>
                <td style={{ height: 24, paddingBottom: 8, color: "gray" }}>
                  {moment().year()}
                </td>
              </tr>
              <tr>
                <td style={{ height: 24, color: "gray" }}>
                  {moment().year() + 1}
                </td>
              </tr>
            </tbody>
          </table>
        </Grid.Column>

        {monthsArr.map((month) => {
          const val1 =
            // eslint-disable-next-line
            project[`${month}1`] == 0 || !project[`${month}1`]
              ? ""
              : (project[`${month}1`] * 100).toFixed(0);

          const val2 =
            // eslint-disable-next-line
            project[`${month}2`] == 0 || !project[`${month}2`]
              ? ""
              : (project[`${month}2`] * 100).toFixed(0);

          const val3 =
            // eslint-disable-next-line
            project[`${month}3`] == 0 || !project[`${month}3`]
              ? ""
              : (project[`${month}3`] * 100).toFixed(0);

          return (
            <Grid.Column style={{ padding: 0 }} key={`${month}1`}>
              <table style={{ textAlign: "center" }}>
                <tbody>
                  <tr>
                    <td style={{ color: "gray" }}>{month.toUpperCase()}</td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        style={
                          forecastError && forecastErrorName === `${month}1`
                            ? {
                                color: "red",
                                backgroundColor: "pink",
                                width: 31,
                                textAlign: "center",
                                fontWeight: 700,
                              }
                            : {
                                color: "#fe5000",
                                backgroundColor: "white",
                                width: 31,
                                textAlign: "center",
                                fontWeight: 700,
                              }
                        }
                        min="0"
                        max="100"
                        type="number"
                        name={`${month}1`}
                        value={val1 || ""}
                        // style={{ width: 30, textAlign: 'center' }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={handleEnter}
                        autoComplete="off"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        name={`${month}2`}
                        value={val2 || ""}
                        style={
                          forecastError && forecastErrorName === `${month}2`
                            ? {
                                color: "red",
                                backgroundColor: "pink",
                                width: 31,
                                textAlign: "center",
                                fontWeight: 700,
                              }
                            : {
                                color: "#fe5000",
                                backgroundColor: "white",
                                width: 31,
                                textAlign: "center",
                                fontWeight: 700,
                              }
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={handleEnter}
                        autoComplete="off"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        name={`${month}3`}
                        value={val3 || ""}
                        style={
                          forecastError && forecastErrorName === `${month}3`
                            ? {
                                color: "red",
                                backgroundColor: "pink",
                                width: 31,
                                textAlign: "center",
                                fontWeight: 700,
                              }
                            : {
                                color: "#fe5000",
                                backgroundColor: "white",
                                width: 31,
                                textAlign: "center",
                                fontWeight: 700,
                              }
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={handleEnter}
                        autoComplete="off"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid.Column>
          );
        })}
      </Grid.Row>
    </Grid>
  );
};

export default withRouter(Forecasting);
