import React from "react";
import { Table, Label, Popup, Icon, Dropdown } from "semantic-ui-react";
import Moment from "moment";
// import UploadSubContractPopup from "../PendingSubContracts/UploadSubContractPopup";
// import PreviewContractPDF from "../../PreviewContractPDF/PreviewContractPDF";
import { downloadContract } from "../../../../../util";
import SetToPendingModal from "../../ClientContractTracking/ApprovedClientContracts/SetToPendingModal";

const ApprovedSubContracts = (props) => {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const { approvedContracts, handleInclusionChange } = props;

  return (
    <>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="2"></Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Bid</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Proposal</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Upload</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">View</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Days</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Retention %</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Contract</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Invoiced</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Paid</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Bid Rcvd</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Cont Sent</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Cont Rcvd</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Inclusions</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell colSpan="2" style={{ color: "rgb(254, 80, 0)" }}>
            Approved Contracts
          </Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Array.isArray(approvedContracts) && approvedContracts.length ? (
          approvedContracts[0].SubContractor.map((sub, index) => {
            return (
              <React.Fragment key={index}>
                {sub.SubContracts.map((contract) => {
                  return contract.displayType === "data" ? (
                    <Table.Row key={contract.GUID}>
                      <Table.Cell>
                        <Label>{contract.SubContractType}</Label>
                        <SetToPendingModal
                          guid={contract.GUID}
                          type="Subcontractor"
                        />
                      </Table.Cell>
                      <Table.Cell>{contract.CompanyName}</Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.BidDate ? (
                          <Popup
                            position="top center"
                            content="Download Bid"
                            trigger={
                              <Icon
                                // size="large"
                                name="download"
                                link
                                onClick={() => props.downloadBid(contract.GUID)}
                              />
                            }
                          />
                        ) : (
                          <Popup
                            position="top center"
                            content="Upload Bid"
                            trigger={
                              <Icon
                                link
                                onClick={() =>
                                  props.handleBidIconClick(contract.GUID)
                                }
                                name="cloud upload"
                                // size="large"
                              />
                            }
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Popup
                          content="Proposal has been sent"
                          position="top center"
                          trigger={
                            <Icon
                              // size="large"
                              color="green"
                              name="check"
                            />
                          }
                        />
                      </Table.Cell>

                      <Table.Cell textAlign="center">
                        {/* <UploadSubContractPopup
                          contract={contract}
                          uploadSubContract={props.uploadSubContract}
                        /> */}
                        {/* {showDownloadButton ? ( */}
                        <Popup
                          content={"Save uploaded proposal pdf"}
                          position="top center"
                          trigger={
                            <Icon
                              link
                              // size="large"
                              onClick={() => downloadContract(contract.GUID)}
                              name="download"
                            />
                          }
                        />
                        {/* ) : null} */}
                      </Table.Cell>

                      <Table.Cell textAlign="center">
                        <Popup
                          content={"View Proposal"}
                          position="top center"
                          trigger={
                            <a
                              href={`/contract/?r=s&id=${contract.GUID}&c=${contract.CompanyID}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Icon
                                // size="large"
                                link
                                name="external alternate"
                              />
                            </a>
                          }
                        />
                        {/* <PreviewContractPDF contract={contract} recipient="s" /> */}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.SubWorkDays}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.Retention
                          ? contract.Retention.toFixed(2) + "%"
                          : "0%"}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {formatter.format(contract.Total || 0)}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {formatter.format(contract.Invoiced || 0)}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {formatter.format(contract.Received || 0)}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.SubBidDate
                          ? Moment(contract.BidDate).format("l")
                          : ""}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.SubContractSentDate
                          ? Moment(contract.SubContractSentDate).format("l")
                          : ""}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.SubApprovalDate
                          ? Moment(contract.SubApprovalDate).format("l")
                          : ""}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.Inclusions[0].text ? (
                          <Dropdown
                            selection
                            selectOnNavigation={false}
                            selectOnBlur={false}
                            options={contract.Inclusions}
                            onChange={(event, data) =>
                              handleInclusionChange(event, data)
                            }
                          />
                        ) : null}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    <Table.Row
                      key={contract.GUID}
                      style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}
                    >
                      <Table.Cell colSpan="8"></Table.Cell>
                      <Table.Cell
                        style={{ color: "blue", fontWeight: "bold" }}
                        textAlign="center"
                      >
                        {formatter.format(contract.Total || 0)}
                      </Table.Cell>
                      <Table.Cell
                        style={{ color: "blue", fontWeight: "bold" }}
                        textAlign="center"
                      >
                        {formatter.format(contract.Invoiced || 0)}
                      </Table.Cell>
                      <Table.Cell
                        style={{ color: "blue", fontWeight: "bold" }}
                        textAlign="center"
                      >
                        {formatter.format(contract.Received || 0)}
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })
        ) : (
          <Table.Row style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}>
            <Table.Cell colSpan="15">No approved contracts</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </>
  );
};

export default ApprovedSubContracts;
