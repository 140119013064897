import {
  SET_INCENTIVE_PAYOUT_MENU_SELECTION,
  RESET_INCENTIVE_PAYOUT_MENU_SELECTION,
} from "../actions/topMenu/incentivePayoutMenuActions";
import * as moment from "moment";

const year = moment().year();

const INITIAL_DATA = {
  year,
};

const incentivePayoutMenuReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case SET_INCENTIVE_PAYOUT_MENU_SELECTION:
      return {
        ...state,
        year: action.payload.year,
      };
    case RESET_INCENTIVE_PAYOUT_MENU_SELECTION:
      return INITIAL_DATA;
    default:
      return state;
  }
};

export default incentivePayoutMenuReducer;
