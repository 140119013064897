import React from "react";
import {
  Button,
  Modal,
  Header,
  Form,
  Select,
  Dimmer,
  Loader,
} from "semantic-ui-react";

import {
  get as getLeaders,
  create as createLeader,
} from "../../../../api/leaders";

const divisions = [
  { text: "Construction", value: "Construction" },
  { text: "Group", value: "Group" },
  { text: "Mechanical", value: "Mechanical" },
  { text: "Mechanical/Service", value: "Mechanical/Service" },
];

const defaultUser = { empUserId: "", division: "" };

export default ({ open, onClose, onSave }) => {
  const [user, setUser] = React.useState(defaultUser);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [userOptions, setUserOptions] = React.useState([]);

  React.useEffect(() => {
    setIsLoading(true);
    getLeaders()
      .then((users) =>
        users.map((x) => ({
          key: x.userid,
          text: x.name,
          value: x.userid,
        }))
      )
      .then((options) => setUserOptions(options))
      .then(() => setIsLoading(false));
  }, [open]);

  React.useEffect(() => {
    if (isDirty) setErrors(getErrors());
  }, [user]);

  const getErrors = () =>
    ["empUserId", "division"].reduce((err, field) => {
      err[field] = user[field] === "";
      return err;
    }, {});

  const handleFormChange = (field, val) => {
    setIsDirty(true);
    setUser({ ...user, [field]: val });
  };

  const handleSave = async () => {
    const newErrors = getErrors();

    setErrors(newErrors);
    const isValid = Object.keys(newErrors).reduce((acc, key) => {
      if (!acc || errors[key]) return false;
      return true;
    }, true);

    if (isValid) {
      try {
        setIsLoading(true);
        // get the full name of the user
        const name = userOptions.find((x) => x.value === user.empUserId).text;
        await createLeader({ ...user, name });

        // if we have a call back
        if (onSave) onSave(user);

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        alert("Oh no...Something went wrong!");
      }
    }
  };

  const handleCancel = () => {
    setUser(defaultUser);
    setIsDirty(false);
    setErrors({});
    onClose();
  };

  return (
    <Modal onClose={handleCancel} open={open} size="tiny">
      <Modal.Header>Add User</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Header>
            Find the users you want to add to the leadership incentive pool
          </Header>
          <Dimmer isLoading>
            <Loader />
          </Dimmer>
          <Form>
            <Form.Field
              required
              control={Select}
              options={userOptions}
              label={{
                children: "Leader",
                htmlFor: "empUserId",
              }}
              placeholder="Select One"
              search
              searchInput={{ id: "empUserId" }}
              value={user.empUserId}
              onChange={(e, { value }) => handleFormChange("empUserId", value)}
              error={errors["empUserId"]}
            />
            <Form.Field
              required
              id="division"
              control={Select}
              options={divisions}
              label="Division"
              placeholder="Construction"
              value={user.division}
              onChange={(e, { value }) => handleFormChange("division", value)}
              error={errors["division"]}
            />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="grey" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          disabled={!isDirty}
          content="Add Leader"
          labelPosition="right"
          icon="checkmark"
          onClick={handleSave}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};
