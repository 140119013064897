import React, { useState, useEffect, Fragment } from "react";
import { Form, Header, Icon, Menu, Accordion } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import LaborTimeModal from "./LaborTimeModal/LaborTimeModal";
import MaterialModal from "./MaterialModal/MaterialModal";
import EquipmentModal from "./EquipmentModal/EquipmentModal";
import moment from "moment";
import OtherCostsModal from "./OtherCostsModal/OtherCostsModal";
import InventoryModal from "./InventoryModal/InventoryModal";

const ConditionInfo = ({
  conditionOptions,
  date,
  setDate,
  category,
  isAdmin,
  user,
  projectID,
  projectInfo,
  fetchProjectInfo,
}) => {
  const [description, setDescription] = useState("");
  const [subScope, setSubScope] = useState("");
  const [userID, setUserID] = useState("");
  const [proxyUserID, setProxyUserID] = useState("");
  const [proxyUserOptions, setProxyUserOptions] = useState([]);
  const [conditionID, setConditionID] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState();
  const [materialModalOpen, setMaterialModalOpen] = useState(false);
  const [inventoryModalOpen, setInventoryModalOpen] = useState(false);
  const [equipmentModalOpen, setEquipmentModalOpen] = useState(false);
  const [otherCostsModalOpen, setOtherCostsModalOpen] = useState(false);
  const [conditionInfo, setConditionInfo] = useState(null);
  const [disableLoggingInd, setDisableLoggingInd] = useState(1);

  useEffect(() => {
    if (user && user.UserID) {
      setUserID(user.UserID);
    }
  }, [user.UserID]);

  const handleConditionChange = (e, data) => {
    setConditionID(data.value);
    setDescription(
      data.options.find((option) => option.value === data.value)["desc"]
    );
    setSubScope(
      data.options.find((option) => option.value === data.value)["subscope"]
    );
    setDisableLoggingInd(
      data.options.find((option) => option.value === data.value)[
        "disablelogging"
      ]
    );
  };

  useEffect(() => {
    if (userID) {
      Axios.get(`${API_ROOT}/api/user/displaynames`)
        .then((res) => {
          setProxyUserOptions(
            JSON.parse(res.data.users).filter((el) => el.UserID !== userID)
          );
        })
        .catch((err) => console.log(err));
    }
  }, [userID]);

  const fetchConditionInfo = () => {
    Axios.get(`${API_ROOT}/api/condition/condition-info-mobile`, {
      params: {
        conditionID,
      },
    })
      .then((res) => {
        if (res.data) {
          setConditionInfo(res.data[0]);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (conditionID) {
      fetchConditionInfo();
    }
  }, [conditionID]);

  const conditionName =
    conditionOptions.length && conditionID
      ? conditionOptions.find((condition) => {
          return condition.value === conditionID;
        })["text"]
      : "";

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  const proxyUserOption = proxyUserOptions.find(
    (option) => option.UserID === proxyUserID
  );

  return (
    <div
      style={{
        // margin: 10,
        overflowY: "auto",
        height: "calc(100vh - 150px)",
        maxHeight: "calc(100vh - 150px)",
        maxWidth: "100%",
      }}
    >
      <Header style={{ marginTop: 10 }} as="h3" textAlign="center">
        {category}
      </Header>
      <Form style={{ margin: 10 }}>
        <Form.Dropdown
          label="Select Condition"
          selection
          options={conditionOptions}
          value={conditionID}
          onChange={handleConditionChange}
        />
        {description || subScope ? (
          <Accordion style={{ marginBottom: 20 }}>
            <Fragment>
              {description ? (
                <Fragment>
                  <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    <strong>Condition Description</strong>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 0}>
                    <p>{description}</p>
                  </Accordion.Content>
                </Fragment>
              ) : null}
              {description ? (
                <Fragment>
                  <Accordion.Title
                    active={activeIndex === 1}
                    index={1}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    <strong>Tech/Subcontractor Description</strong>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 1}>
                    <p>{subScope}</p>
                  </Accordion.Content>
                </Fragment>
              ) : null}
            </Fragment>
          </Accordion>
        ) : // <Form.Field>
        //   <label>Internal Description</label>
        //   <p>{description}</p>
        // </Form.Field>
        null}
        {disableLoggingInd ? null : (
          <Fragment>
            {isAdmin ? (
              <Form.Dropdown
                clearable
                label="Proxy User"
                // placeholder="Proxy user"
                selectOnBlur={false}
                selection
                loading={!proxyUserOptions.length}
                value={proxyUserID}
                onChange={(e, data) => setProxyUserID(data.value)}
                options={proxyUserOptions.map((option) => {
                  return {
                    value: option.UserID,
                    text: option.DisplayName,
                    key: option.UserID,
                  };
                })}
              />
            ) : null}
            <Form.Field>
              <label>Date</label>
              <Form.Input
                type="date"
                value={date}
                onChange={(e, data) => setDate(data.value)}
                max={moment().format("YYYY-MM-DD")}
              />
            </Form.Field>
            {conditionID && date ? (
              <Menu style={{ textAlign: "center" }} fluid vertical>
                <Menu.Item link onClick={() => setModalOpen(true)}>
                  <Header as="h3">
                    <Icon.Group size="large">
                      <Icon color="orange" name="clock" />
                      <Icon color="grey" corner name="pencil" />
                    </Icon.Group>
                    Log Labor Time
                  </Header>
                </Menu.Item>
                <Menu.Item link onClick={() => setMaterialModalOpen(true)}>
                  <Header as="h3">
                    <Icon.Group size="large">
                      <Icon color="orange" name="shopping cart" />
                      <Icon color="grey" corner name="pencil" />
                    </Icon.Group>
                    Log Material
                  </Header>
                </Menu.Item>
                <Menu.Item link onClick={() => setInventoryModalOpen(true)}>
                  <Header as="h3">
                    <Icon.Group size="large">
                      <Icon color="orange" name="warehouse" />
                      <Icon color="grey" corner name="pencil" />
                    </Icon.Group>
                    Log Inventory
                  </Header>
                </Menu.Item>
                <Menu.Item link onClick={() => setEquipmentModalOpen(true)}>
                  <Header as="h3">
                    <Icon.Group size="large">
                      <Icon color="orange" name="wrench" />
                      <Icon color="grey" corner name="pencil" />
                    </Icon.Group>
                    Log Equipment
                  </Header>
                </Menu.Item>
                <Menu.Item link onClick={() => setOtherCostsModalOpen(true)}>
                  <Header as="h3">
                    <Icon.Group size="large">
                      <Icon color="orange" name="clipboard" />
                      <Icon color="grey" corner name="pencil" />
                    </Icon.Group>
                    Log Other Costs
                  </Header>
                </Menu.Item>
              </Menu>
            ) : null}
          </Fragment>
        )}
      </Form>

      <LaborTimeModal
        projectID={projectID}
        project={projectInfo}
        conditionID={conditionID}
        date={date}
        open={modalOpen}
        setOpen={setModalOpen}
        userID={proxyUserID ? proxyUserID : userID}
        category={category}
        conditionName={conditionName}
        fetchProjectInfo={fetchProjectInfo}
        conditionInfo={conditionInfo}
        fetchConditionInfo={fetchConditionInfo}
      />
      <MaterialModal
        userID={proxyUserID ? proxyUserID : userID}
        proxyUserDisplayName={
          proxyUserOption ? proxyUserOption.DisplayName : null
        }
        project={projectInfo}
        projectID={projectID}
        conditionID={conditionID}
        date={date}
        open={materialModalOpen}
        category={category}
        conditionName={conditionName}
        fetchProjectInfo={fetchProjectInfo}
        setOpen={setMaterialModalOpen}
        conditionInfo={conditionInfo}
        fetchConditionInfo={fetchConditionInfo}
      />
      <InventoryModal
        projectID={projectID}
        project={projectInfo}
        conditionID={conditionID}
        date={date}
        open={inventoryModalOpen}
        setOpen={setInventoryModalOpen}
        userID={proxyUserID ? proxyUserID : userID}
        category={category}
        conditionName={conditionName}
        fetchProjectInfo={fetchProjectInfo}
        conditionInfo={conditionInfo}
        fetchConditionInfo={fetchConditionInfo}
      />
      <EquipmentModal
        userID={userID}
        project={projectInfo}
        projectID={projectID}
        conditionID={conditionID}
        date={date}
        open={equipmentModalOpen}
        category={category}
        conditionName={conditionName}
        fetchProjectInfo={fetchProjectInfo}
        setOpen={setEquipmentModalOpen}
        conditionInfo={conditionInfo}
        fetchConditionInfo={fetchConditionInfo}
      />
      <OtherCostsModal
        userID={userID}
        project={projectInfo}
        projectID={projectID}
        conditionID={conditionID}
        date={date}
        open={otherCostsModalOpen}
        category={category}
        conditionName={conditionName}
        fetchProjectInfo={fetchProjectInfo}
        setOpen={setOtherCostsModalOpen}
        conditionInfo={conditionInfo}
        fetchConditionInfo={fetchConditionInfo}
      />
    </div>
  );
};

export default ConditionInfo;
