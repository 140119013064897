import React from "react";
import { Container, Grid } from "semantic-ui-react";
import LeadershipIncentive from "./LeadershipIncentive/LeadershipIncentive";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";
import PositionIncentive from "./PositionIncentive/PositionIncentive";

const TeamMemberBonusPool = ({
  leadershipIncentiveData,
  positionIncentiveData,
  onAddNewLeader,
}) => {
  // year, quarter, empUserID, positionID, value, type

  const save = (year, quarter, empUserID, positionID, value, type) => {
    Axios.post(`${API_ROOT}/api/kpi/bonus-pool`, {
      year,
      quarter,
      empUserID,
      positionID,
      value,
      type,
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container
      style={{ paddingLeft: 10, paddingRight: 10, width: "100%" }}
      fluid
    >
      <h1 style={{ color: "rgb(254, 80, 0)", marginLeft: 40 }}>
        Team Member Bonus Pool
      </h1>
      <Grid columns={2}>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={16}
          largeScreen={8}
          widescreen={8}
        >
          <LeadershipIncentive
            rowData={leadershipIncentiveData}
            save={save}
            onUpdate={onAddNewLeader}
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={16}
          largeScreen={8}
          widescreen={8}
        >
          <PositionIncentive rowData={positionIncentiveData} save={save} />
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default TeamMemberBonusPool;
