import React, { useState, useEffect, useRef, memo } from "react";
import { Input } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";

const AdditionalCostInput = memo(
  ({
    additionalCost,
    setTrackingData,
    projectID,
    subCategoryCode,
    getTrackingTotalsData,
  }) => {
    const [cost, setCost] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (additionalCost && additionalCost !== cost) {
        setCost(additionalCost);
      }
    }, [additionalCost]);

    const prevCostRef = useRef();
    useEffect(() => {
      prevCostRef.current = cost;
    });
    const prevCost = prevCostRef.current;

    const handleChange = (e, { value }) => {
      setCost(value);
    };

    const handleBlur = () => {
      if (cost !== prevCost) {
        setLoading(true);
        const parsedCost = cost ? parseFloat(cost) : null;
        Axios.post(`${API_ROOT}/api/condition/additional-cost`, {
          projectID,
          subCategoryCode,
          additionalCost: parsedCost,
        })
          .then((res) => {
            if (res.data) {
              setTrackingData(res.data);
              getTrackingTotalsData();
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    };

    const onKeyPress = (e) => {
      if (e.key === "Enter") {
        handleBlur();
      }
    };

    return (
      <Input
        loading={loading}
        style={{ width: 90 }}
        size="mini"
        value={cost}
        type="number"
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={onKeyPress}
      />
    );
  }
);

export default AdditionalCostInput;
