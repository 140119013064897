import React from "react";
import { Header, Table, Segment, Button, Label } from "semantic-ui-react";
import "./ClientInvoicing.scss";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setInclusionMenuSelection } from "../../../../actions/project/inclusion/inclusionActions";

import InvoicingPopup from "./InvoicingPopup/InvoicingPopup";
import SendPayAppModal from "./SendPayAppModal/SendPayAppModal";
import StatusDropdown from "./StatusDropdown/StatusDropdown";

const ClientInvoicing = (props) => {
  const payAppQty =
    Array.isArray(props.clientInvoicing) && props.clientInvoicing.length > 0
      ? props.clientInvoicing[0].subCategories[0].paqty
      : 0;

  // Create our number formatter.
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const dispatch = useDispatch();

  const clientInvoicing = props.clientInvoicing || [];
  const clientInvoicingTotals = props.clientInvoicingTotals || {};

  let invoicedPayAppTypes = [];

  if (
    Array.isArray(clientInvoicing) &&
    clientInvoicing.length &&
    clientInvoicing[0].invoicedPayAppTypes
  ) {
    invoicedPayAppTypes = clientInvoicing[0].invoicedPayAppTypes.split(",");
  }

  const { paymentApplicationStatuses } = props;

  return (
    <div
      className="ClientInvoicing"
      style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
    >
      <Segment style={{ padding: 0, marginBottom: 0 }} basic clearing>
        <Header
          style={{ color: "#fe5000", display: "block" }}
          as="h1"
          floated="left"
        >
          Client Invoicing
        </Header>
        <Button
          onClick={() => props.addPaymentApplication()}
          disabled={props.addPayAppLoading}
          loading={props.addPayAppLoading}
          style={{ marginRight: 20 }}
          floated="right"
          primary
        >
          Create New Pay App
        </Button>
      </Segment>

      <div>
        <Table
          style={{ minWidth: 1200, fontSize: 12 }}
          structured
          unstackable
          compact="very"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>

              <Table.HeaderCell
                textAlign="center"
                className="border-left"
                colSpan={3}
              >
                Current
              </Table.HeaderCell>
              {/* <Table.HeaderCell
                textAlign="center"
                className="border-left"
                colSpan={2}
              >
                Pending
              </Table.HeaderCell> */}
              <Table.HeaderCell
                textAlign="center"
                className="border-left"
                colSpan={3}
              >
                Invoicing
              </Table.HeaderCell>
              {payAppQty > 0 ? (
                <Table.HeaderCell
                  className="border-left"
                  colSpan={payAppQty}
                  textAlign="center"
                >
                  Payment Applications
                </Table.HeaderCell>
              ) : null}
            </Table.Row>
            <Table.Row style={{ fontSize: 12 }}>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell className="border-left" textAlign="center">
                Base
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Approved Changes
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Adjusted (Current)
              </Table.HeaderCell>
              {/* <Table.HeaderCell className="border-left" textAlign="center">
                Pending Changes
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Adj + Pending
              </Table.HeaderCell> */}
              <Table.HeaderCell className="border-left" textAlign="center">
                Total Invoiced
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Balance to Invoice
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                % Left to Invoice
              </Table.HeaderCell>
              {payAppQty > 0 && paymentApplicationStatuses
                ? [...Array(payAppQty)].map((e, i) => (
                    <Table.HeaderCell
                      className="border-left"
                      style={
                        // invoicedPayAppTypes.includes("App #" + (i + 1))
                        paymentApplicationStatuses[`App #${i + 1}`] ===
                          "Invoiced" ||
                        paymentApplicationStatuses[`App #${i + 1}`] === "Paid"
                          ? // "Preparing"
                            { backgroundColor: "gray" }
                          : {}
                      }
                      textAlign="center"
                      //   style={
                      //     approvedContractTypes.includes("CO " + (i + 1))
                      //       ? { backgroundColor: "green" }
                      //       : { backgroundColor: "red" }
                      //   }
                      key={i}
                    >
                      {paymentApplicationStatuses &&
                      paymentApplicationStatuses[`App #${i + 1}`] ===
                        "Preparing" ? (
                        <SendPayAppModal
                          setClientInvoicing={props.setClientInvoicing}
                          projectID={props.projectID}
                          paymentApplicationType={`App #${i + 1}`}
                          getPaymentApplicationStatuses={
                            props.getPaymentApplicationStatuses
                          }
                        />
                      ) : (
                        ""
                      )}{" "}
                      App #{i + 1}
                      {" - "}
                      <StatusDropdown
                        paymentApplicationStatuses={paymentApplicationStatuses}
                        getPaymentApplicationStatuses={
                          props.getPaymentApplicationStatuses
                        }
                        projectID={props.projectID}
                        i={i}
                        setClientInvoicing={props.setClientInvoicing}
                      />
                    </Table.HeaderCell>
                  ))
                : null}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Array.isArray(clientInvoicing) && clientInvoicing.length ? (
              clientInvoicing.map((category) => (
                <React.Fragment key={category.categoryCode}>
                  <Table.Row>
                    <Table.Cell
                      style={{
                        color: "rgb(254, 80, 0)",
                        fontWeight: "bold",
                        fontSize: 12,
                      }}
                      colSpan={7 + (payAppQty ? payAppQty : 0)}
                    >
                      {category.categoryCode
                        ? category.categoryCode.toUpperCase()
                        : ""}
                    </Table.Cell>
                  </Table.Row>
                  {category.subCategories.map((subCategory) => (
                    <Table.Row key={subCategory.subCategoryCode}>
                      <Table.Cell style={{ paddingLeft: 20 }}>
                        <Link
                          onClick={() =>
                            dispatch(
                              setInclusionMenuSelection({
                                selectedCategoryID: category.mainCategoryID,
                                selectedSubCategoryCode:
                                  subCategory.subCategoryCode,
                              })
                            )
                          }
                          to={`/browser/projects/inclusions/${props.projectID}`}
                        >
                          {subCategory.subCategoryCode === "Total"
                            ? ""
                            : subCategory.subCategoryCode}
                        </Link>
                      </Table.Cell>
                      <Table.Cell
                        className="border-left"
                        textAlign="center"
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                      >
                        {subCategory.base !== 0
                          ? formatter.format(subCategory.base)
                          : ""}
                      </Table.Cell>
                      <Table.Cell
                        textAlign="center"
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                      >
                        {subCategory.approvedChanges !== 0
                          ? formatter.format(subCategory.approvedChanges)
                          : ""}
                      </Table.Cell>
                      <Table.Cell
                        textAlign="center"
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : { color: "rgb(254, 80, 0)" }
                        }
                      >
                        {subCategory.adjusted !== 0
                          ? formatter.format(subCategory.adjusted)
                          : ""}
                      </Table.Cell>
                      {/* <Table.Cell
                        className="border-left"
                        textAlign="center"
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                      >
                        {subCategory.pendingChanges !== 0
                          ? formatter.format(subCategory.pendingChanges)
                          : ""}
                      </Table.Cell> */}
                      {/* <Table.Cell
                        textAlign="center"
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : { color: "rgb(254, 80, 0)" }
                        }
                      >
                        {subCategory.adjustedPlusPending !== 0
                          ? formatter.format(subCategory.adjustedPlusPending)
                          : ""}
                      </Table.Cell> */}
                      <Table.Cell
                        className="border-left"
                        textAlign="center"
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                      >
                        {subCategory.payapps
                          ? formatter.format(subCategory.payapps.totalInvoiced)
                          : ""}
                      </Table.Cell>
                      <Table.Cell
                        textAlign="center"
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                      >
                        {subCategory.payapps
                          ? formatter.format(
                              Math.round(
                                (subCategory.payapps.balanceToInvoice +
                                  Number.EPSILON) *
                                  100
                              ) /
                                100 ===
                                0.0
                                ? 0
                                : subCategory.payapps.balanceToInvoice
                            )
                          : null}
                      </Table.Cell>
                      <Table.Cell
                        textAlign="center"
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                      >
                        {subCategory.payapps
                          ? subCategory.payapps.pctLeftToInvoice.toFixed(1) +
                            "%"
                          : null}
                      </Table.Cell>
                      {payAppQty > 0
                        ? [...Array(payAppQty)].map((e, i) => (
                            <Table.Cell
                              style={
                                subCategory.subCategoryCode === "Total"
                                  ? {
                                      color: "blue",
                                      fontWeight: "bold",
                                    }
                                  : {}
                              }
                              className="border-left"
                              //   textAlign="center"
                              key={i}
                            >
                              {subCategory.subCategoryCode === "Total" ? (
                                <>
                                  <Label
                                    size="large"
                                    style={{
                                      color: "blue",
                                      // width: 50,
                                      marginRight: "10%",
                                    }}
                                  >
                                    {subCategory.payapps
                                      ? subCategory.payapps[
                                          "appPct" + (i + 1)
                                        ].toFixed(0) + "%"
                                      : "0%"}
                                  </Label>

                                  {subCategory.payapps
                                    ? formatter.format(
                                        subCategory.payapps["app" + (i + 1)]
                                      )
                                    : formatter.format(0)}
                                </>
                              ) : (
                                <InvoicingPopup
                                  invoicedPayAppTypes={invoicedPayAppTypes}
                                  projectID={props.projectID}
                                  setClientInvoicing={props.setClientInvoicing}
                                  getClientInvoicingTotals={
                                    props.getClientInvoicingTotals
                                  }
                                  subCategory={subCategory}
                                  i={i}
                                />
                              )}
                            </Table.Cell>
                          ))
                        : null}
                    </Table.Row>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={7}>No invoice</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell
                className="border-left"
                style={{ color: "purple", fontWeight: "bold" }}
                textAlign="center"
              >
                {clientInvoicingTotals.baseTotal ||
                clientInvoicingTotals.baseTotal === 0
                  ? formatter.format(clientInvoicingTotals.baseTotal)
                  : ""}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ color: "purple", fontWeight: "bold" }}
                textAlign="center"
              >
                {clientInvoicingTotals.approvedChangesTotal ||
                clientInvoicingTotals.approvedChangesTotal === 0
                  ? formatter.format(clientInvoicingTotals.approvedChangesTotal)
                  : ""}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ color: "purple", fontWeight: "bold" }}
                textAlign="center"
              >
                {clientInvoicingTotals.adjustedTotal ||
                clientInvoicingTotals.adjustedTotal === 0
                  ? formatter.format(clientInvoicingTotals.adjustedTotal)
                  : ""}
              </Table.HeaderCell>
              {/* <Table.HeaderCell
                className="border-left"
                style={{ color: "purple", fontWeight: "bold" }}
                textAlign="center"
              >
                {clientInvoicingTotals &&
                clientInvoicingTotals.pendingChangesTotal !== 0
                  ? formatter.format(clientInvoicingTotals.pendingChangesTotal)
                  : ""}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ color: "purple", fontWeight: "bold" }}
                textAlign="center"
              >
                {clientInvoicingTotals &&
                clientInvoicingTotals.adjustedPlusPendingTotal !== 0
                  ? formatter.format(
                    clientInvoicingTotals.adjustedPlusPendingTotal
                    )
                  : ""}
              </Table.HeaderCell> */}
              <Table.HeaderCell
                className="border-left"
                style={{ color: "purple", fontWeight: "bold" }}
                textAlign="center"
              >
                {clientInvoicingTotals.totalInvoicedTotal ||
                clientInvoicingTotals.totalInvoicedTotal === 0
                  ? formatter.format(clientInvoicingTotals.totalInvoicedTotal)
                  : ""}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ color: "purple", fontWeight: "bold" }}
                textAlign="center"
              >
                {clientInvoicingTotals.balanceToInvoiceTotal ||
                clientInvoicingTotals.balanceToInvoiceTotal === 0
                  ? formatter.format(
                      clientInvoicingTotals.balanceToInvoiceTotal
                    )
                  : ""}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ color: "purple", fontWeight: "bold" }}
                textAlign="center"
              >
                {clientInvoicingTotals.pctLeftToInvoiceTotal ||
                clientInvoicingTotals.pctLeftToInvoiceTotal === 0
                  ? clientInvoicingTotals.pctLeftToInvoiceTotal.toFixed(1) + "%"
                  : ""}
              </Table.HeaderCell>
              {clientInvoicingTotals.payAppCount > 0
                ? [...Array(clientInvoicingTotals.payAppCount)].map((e, i) => (
                    <Table.HeaderCell
                      style={{ color: "purple", fontWeight: "bold" }}
                      className="border-left"
                      // textAlign="center"
                      key={i}
                    >
                      <>
                        <Label
                          size="large"
                          style={{
                            color: "purple",
                            // width: 50,
                            marginRight: "10%",
                          }}
                        >
                          {clientInvoicingTotals["appTotalPct" + (i + 1)]
                            ? clientInvoicingTotals[
                                "appTotalPct" + (i + 1)
                              ].toFixed(0) + "%"
                            : "0%"}
                        </Label>

                        {formatter.format(
                          clientInvoicingTotals["app" + (i + 1)]
                        )}
                      </>

                      {/* {clientInvoicingTotals["appTotal" + (i + 1)] !== 0
                        ? formatter.format(
                            clientInvoicingTotals["appTotal" + (i + 1)]
                          )
                        : ""} */}
                    </Table.HeaderCell>
                  ))
                : null}
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    </div>
  );
};

export default ClientInvoicing;
