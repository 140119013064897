import React, { useState } from "react";
import { Modal, Header, Button, Icon } from "semantic-ui-react";

const ConfirmDeleteUserRoleModal = ({ user, handleConfirmDelete }) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  return (
    <Modal
      open={confirmModalOpen}
      onOpen={() => setConfirmModalOpen(true)}
      onClose={() => setConfirmModalOpen(false)}
      trigger={<Icon link name="remove user" />}
      basic
      size="tiny"
    >
      <Header icon="remove user" content="Remove user from role" />
      <Modal.Content>
        <p>
          Are you sure you want to remove {user.DisplayName} from this security
          role?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          color="red"
          inverted
          onClick={() => setConfirmModalOpen(false)}
        >
          <Icon name="remove" /> No
        </Button>
        <Button
          color="green"
          inverted
          onClick={() => {
            handleConfirmDelete(user.UserID);
            setConfirmModalOpen(false);
          }}
        >
          <Icon name="checkmark" /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmDeleteUserRoleModal;
