import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Header, Modal, Table } from "semantic-ui-react";
import CourseCheckboxes from "./CourseCheckboxes/CourseCheckboxes";
import "./EditCourseModal.scss";

const EditCourseModal = ({
  uid,
  editModalOpen,
  setEditModalOpen,
  selectedCourseID,
  selectedCourseDate,
  selectedCourseName,
  gridApi,
}) => {
  const [courseID, setCourseID] = useState("");
  const [courseDate, setCourseDate] = useState("");
  const [attendanceData, setAttendanceData] = useState([]);

  useEffect(() => {
    if (selectedCourseID && selectedCourseDate) {
      setCourseID(selectedCourseID);
      setCourseDate(moment(selectedCourseDate).format("L"));
    }
  }, [selectedCourseID, selectedCourseDate]);

  useEffect(() => {
    if (courseID && courseDate) {
      // Axios call to get course Attendance
      Axios.get(
        `https://www.ce-knet.com/DesktopModules/XModPro/Feed.aspx?xfd=NS1_GetSafetyAttendance&pid=0&c=${courseID}&d=${courseDate}`,
        { withCredentials: false }
      ).then((res) => {
        setAttendanceData(res.data);
        return () => {
          gridApi.refreshCells();
        };
      });
    }
  }, [courseID, courseDate]);

  const handleOpen = () => {
    setEditModalOpen(true);
  };

  const handleClose = () => {
    setCourseDate("");
    setCourseID("");
    setEditModalOpen(false);
  };

  return (
    <Modal
      open={editModalOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      className="EditCourseModal"
    >
      <Header
        content={`${selectedCourseName} ${
          selectedCourseDate
            ? " - " + moment(selectedCourseDate).format("L")
            : ""
        }`}
      />
      <Modal.Content>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Participant</Table.HeaderCell>
              <Table.HeaderCell width="2">Required</Table.HeaderCell>
              <Table.HeaderCell width="2">Completed</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Array.isArray(attendanceData) && attendanceData.length
              ? attendanceData.map((user) => (
                  <CourseCheckboxes
                    user={user}
                    courseID={courseID}
                    courseDate={courseDate}
                    uid={uid}
                    setAttendanceData={setAttendanceData}
                  />
                ))
              : null}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EditCourseModal;
