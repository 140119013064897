import React, { useEffect, useState } from "react";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import { Container, Header, Table } from "semantic-ui-react";
import Alternate from "./Alternate";
import useIsMounted from "../../../../hooks/isMounted";

const AlternatesWrapper = ({ projectID }) => {
  const [alternateContractTypes, setAlternateContractTypes] = useState([]);
  const isMounted = useIsMounted();
  useEffect(() => {
    if (projectID) {
      Axios.get(`${API_ROOT}/api/contract/alternate-contract-types`, {
        params: {
          projectID,
        },
      })
        .then((res) => {
          if (res.data && isMounted.current) {
            setAlternateContractTypes(res.data.split(","));
          }
        })
        .catch((err) => console.log(err));
    }
  }, [projectID]);

  return Array.isArray(alternateContractTypes) &&
    alternateContractTypes.length ? (
    <Container
      className="InclusionTable"
      style={{
        // paddingLeft: 30,
        paddingTop: 40,
        // paddingRight: 30,
        paddingBottom: 40,
      }}
      fluid
    >
      <Header
        // floated="left"
        style={{ color: "#fe5000", display: "block" }}
        as="h1"
      >
        Inclusions - Alternates
      </Header>
      <Table unstackable structured compact="very" collapsing={false}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              rowSpan={2}
              textAlign="center"
              className="border-left"
            >
              Scope
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              colSpan="3"
              className="border-left"
            >
              Totals
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              colSpan="5"
              className="border-left"
            >
              Labor
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              colSpan="3"
              className="border-left"
            >
              Material
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              colSpan="3"
              className="border-left"
            >
              Equipment
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              colSpan="3"
              className="border-left"
            >
              Other
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              colSpan="4"
              className="border-left"
            >
              Subs
            </Table.HeaderCell>
          </Table.Row>

          <Table.Row style={{ fontSize: 12 }}>
            {/* <Table.HeaderCell className="border-left"></Table.HeaderCell> */}
            <Table.HeaderCell textAlign="center" className="border-left">
              Pre-Tax Total
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Tax</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Grand Total</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="border-left">
              Hrs
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Rate</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Cost</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">MU</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Total</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="border-left">
              Cost
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">MU</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Total</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="border-left">
              Cost
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">MU</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Total</Table.HeaderCell>

            <Table.HeaderCell textAlign="center" className="border-left">
              Cost
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">MU</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Total</Table.HeaderCell>

            <Table.HeaderCell textAlign="center" className="border-left">
              Cost
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">MU</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Total</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {alternateContractTypes.map((alternate) => (
            <Alternate key={alternate} projectID={projectID} type={alternate} />
          ))}
        </Table.Body>
      </Table>
    </Container>
  ) : null;
};

export default AlternatesWrapper;
