import React, { useState, useEffect } from "react";
import Axios from "axios";
import { API_ROOT } from "../../../../../api-config";
import { connect } from "react-redux";
import { setInclusionMenuSelection } from "../../../../../actions/project/inclusion/inclusionActions";
import { bindActionCreators } from "redux";
import useIsMounted from "../../../../../hooks/isMounted";

const ClientContractDetailWrapper = (props) => {
  const [projectID, setProjectID] = useState(props.projectID);
  const [clientContractDetail, setClientContractDetail] = useState([]);
  const [clientContractDetailTotals, setClientContractDetailTotals] = useState(
    null
  );

  const isMounted = useIsMounted();

  const { WrappedComponent } = props;

  useEffect(() => {
    setProjectID(props.projectID);
  }, [props.projectID]);

  const getClientContractDetail = () => {
    Axios.get(`${API_ROOT}/api/contract/client-contract-detail`, {
      params: {
        projectID,
      },
    })
      .then((res) => {
        if (res.data.clientContractDetail && isMounted.current) {
          setClientContractDetail(res.data.clientContractDetail);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClientContractDetailTotals = () => {
    Axios.get(`${API_ROOT}/api/contract/client-contract-detail-totals`, {
      params: {
        projectID,
      },
    })
      .then((res) => {
        if (res && isMounted.current) {
          setClientContractDetailTotals(res.data.clientContractDetailTotals);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getClientContractDetail();
    getClientContractDetailTotals();
  }, [projectID]);

  return (
    <WrappedComponent
      clientContractDetail={clientContractDetail}
      clientContractDetailTotals={clientContractDetailTotals}
      setInclusionMenuSelection={setInclusionMenuSelection}
      projectID={projectID}
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setInclusionMenuSelection,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(ClientContractDetailWrapper);
