import React, { useEffect, useState } from "react";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";
import { Dimmer, Loader } from "semantic-ui-react";
import NewConditionApprovalTable from "./NewConditionApprovalTable";

const NewConditionApproval = () => {
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    Axios.get(`${API_ROOT}/api/condition/new-templates-approval`)
      .then((res) => {
        if (res.data) {
          setTemplates(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader size="big" inverted />
      </Dimmer>
    );
  }

  return <NewConditionApprovalTable data={templates} />;
};

export default NewConditionApproval;
