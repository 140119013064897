import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form, Grid, Header, Segment } from "semantic-ui-react";
import CsvDownloader from "react-csv-downloader";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import { userIsInRole } from "../../../../util";

const ProjectTaskSpreadsheet = ({ projectID }) => {
  const [csvData, setCsvData] = useState(null);
  const [loading, setLoading] = useState(false);
  const userIsAccounting = useSelector((state) => userIsInRole(state, 9));

  const handleClick = () => {
    setLoading(true);
    setCsvData(null);

    Axios.get(
      `${API_ROOT}/api/acumatica/project-task-spreadsheet?projectID=${projectID}`
    ).then((res) => {
      // console.log(res);
      setCsvData(res.data);
      setLoading(false);
    });
  };

  return userIsAccounting && projectID ? (
    <Grid
      style={{
        width: "35%",
        minWidth: 500,
        overflow: "auto",
        paddingRight: 30,
        marginRight: 30,
        paddingLeft: 5,
      }}
    >
      <Segment clearing basic style={{ margin: 0, padding: "0, 0, 20px, 0" }}>
        <Header
          floated="left"
          style={{
            color: "#fe5000",
            display: "inline-block",
            // marginLeft: 20
          }}
          as="h1"
        >
          Export Project Task Budget
        </Header>
      </Segment>
      <Form.Button
        style={{ marginRight: 10 }}
        loading={loading}
        onClick={handleClick}
      >
        Generate CSV
      </Form.Button>
      {csvData ? (
        <CsvDownloader
          // columns={columns}
          datas={csvData}
          filename={`Project ${projectID} Tasks`}
          separator=","
          wrapColumnChar='"'
        >
          <Form.Button color="green">Export CSV</Form.Button>
        </CsvDownloader>
      ) : null}
    </Grid>
  ) : null;
};

export default ProjectTaskSpreadsheet;
