import React from "react";
import dayjs from "dayjs";

export default ({ summary }) => {
  return (
    <div className="project-summary">
      <div className="summary">
        <div>{dayjs(summary.contract_date).format("MMMM D, YYYY")}</div>
        <div>
          <strong>{summary.project_name}</strong>#{summary.project_id}
        </div>
        <div>{summary.work_location_name}</div>
        <div>{summary.work_location_address}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: summary.project_description,
          }}
        ></div>
      </div>
      <h2 className="title">
        {summary.recipient === "sub" ? "Subcontract" : "Project Proposal"}
      </h2>
    </div>
  );
};
