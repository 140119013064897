export const SET_SURVEY_MENU_SELECTION = "SET_SURVEY_MENU_SELECTION";
export const RESET_SURVEY_MENU_SELECTION = "RESET_SURVEY_MENU_SELECTION";

export const setSurveyMenuSelection = (selection) => ({
  type: SET_SURVEY_MENU_SELECTION,
  payload: selection,
});

export const resetSurveyMenuSelection = () => ({
  type: RESET_SURVEY_MENU_SELECTION,
});
