import Axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Confirm, Icon, Table } from "semantic-ui-react";
import { API_ROOT } from "../../../../../api-config";
import { userIsInRole } from "../../../../../util";

const DeleteMaterialPurchaseModal = ({
  purchaseOrderID,
  // setData,
  dispatch,
  projectID,
  setLoading,
  status,
}) => {
  const [open, setOpen] = useState(false);

  const userIsAccounting = useSelector((state) =>
    state.auth.user ? userIsInRole(state, 9) : false
  );

  const handleConfirm = () => {
    console.log(purchaseOrderID);
    setLoading(true);
    Axios.delete(`${API_ROOT}/api/project/material-purchase`, {
      data: {
        purchaseOrderID,
        projectID,
      },
    })
      .then((res) => {
        // setData(res.data);
        dispatch({ type: "LOAD_DATA", payload: res.data });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    setOpen(false);
  };

  return status === "Pending" && userIsAccounting ? (
    <Table.Cell>
      <Icon link name="remove" onClick={() => setOpen(true)} />
      <Confirm
        size="mini"
        content="Are you sure you want to delete this purchase?"
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={handleConfirm}
      />
    </Table.Cell>
  ) : null;
};

export default DeleteMaterialPurchaseModal;
