import React, { useState, useEffect } from "react";
import { Rating, Form } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";

const CriteriaOptions = ({
  options,
  id,
  KPIEventID,
  userID,
  van,
  surveyDate,
  setEdited,
}) => {
  const [radio, setRadio] = useState("");
  const [rating, setRating] = useState(null);
  const [selection, setSelection] = useState("");

  useEffect(() => {
    if (radio) {
      save(radio);
    }
  }, [radio]);

  useEffect(() => {
    if (rating || rating === 0 || rating === "0") {
      save(rating > 0 ? rating - 1 : null);
    }
  }, [rating]);

  useEffect(() => {
    if (selection) {
      save(selection);
    }
  }, [selection]);

  const save = (value) => {
    Axios.post(`${API_ROOT}/api/kpi/van-results`, {
      params: {
        KPIEventID,
        userID,
        van,
        surveyDate,
        criteriaID: id,
        value,
      },
    })
      .then((res) => {
        // console.log(res);
        setEdited(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const disabled = !userID || !van || !surveyDate;

  if (options === "0,1,2,3,4") {
    return (
      <Rating
        disabled={disabled}
        onRate={(e, { rating }) => setRating(rating)}
        size="huge"
        clearable
        icon="star"
        maxRating={5}
        rating={rating}
      />
    );
  } else if (options === "No,Yes") {
    return (
      <Form>
        <Form.Group style={{ marginBottom: 0 }} inline>
          <Form.Radio
            disabled={disabled}
            onChange={() => setRadio("No")}
            label="No"
            checked={radio === "No"}
          />
          <Form.Radio
            disabled={disabled}
            onChange={() => setRadio("Yes")}
            label="Yes"
            checked={radio === "Yes"}
          />
        </Form.Group>
      </Form>
    );
  } else if (options === "Low,OK") {
    return (
      <Form>
        <Form.Group style={{ marginBottom: 0 }} inline>
          <Form.Radio
            disabled={disabled}
            onChange={() => setRadio("Low")}
            label="Low"
            checked={radio === "Low"}
          />
          <Form.Radio
            disabled={disabled}
            onChange={() => setRadio("OK")}
            label="OK"
            checked={radio === "OK"}
          />
        </Form.Group>
      </Form>
    );
  } else if (options === ">300,-300,-200,-100,C") {
    return (
      <Form>
        <Form.Dropdown
          disabled={disabled}
          style={{ width: 110 }}
          //   width={110}
          selectOnBlur={false}
          selection
          compact
          onChange={(e, { value }) => setSelection(value)}
          value={selection}
          options={[
            { key: ">300", text: ">300", value: ">300" },
            { key: "-300", text: "-300", value: "-300" },
            { key: "-200", text: "-200", value: "-200" },
            { key: "-100", text: "-100", value: "-100" },
            { key: "C", text: "Current", value: "C" },
          ]}
        />
      </Form>
    );
  } else {
    return <>{options}</>;
  }
};

export default CriteriaOptions;
