import React from "react";
import { withRouter, Route, Link } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import Fleet from "../../Admin/Fleet/Fleet";
import SafetySurvey from "../../Admin/SafetySurvey/SafetySurvey";
import UniformAndPresentation from "../../Admin/UniformAndPresentation/UniformAndPresentation";
import Warehouse from "../../Admin/Warehouse/Warehouse";

const Surveys = () => {
  return (
    <div
      style={{
        width: "100%",
        msOverflowX: "hidden",
      }}
    >
      <Route exact path="/m/surveys">
        <Menu
          icon="labeled"
          size="massive"
          style={{ textAlign: "center", borderRadius: 0, marginBottom: 40 }}
          fluid
          color="grey"
          inverted
          vertical
        >
          <Link to="/m/surveys/fleet">
            <Menu.Item link name="Fleet Survey">
              <Icon name="truck" />
              Fleet Survey
            </Menu.Item>
          </Link>
          <Link to="/m/surveys/safety">
            <Menu.Item
              link
              name="Safety Inspection"
              style={{
                borderTop: "1px solid rgba(34,36,38,.1)",
              }}
            >
              <Icon name="heartbeat" />
              Safety Inspection
            </Menu.Item>
          </Link>
          <Link to="/m/surveys/presentation">
            <Menu.Item
              link
              name="Uniform and Presentation Survey"
              style={{
                borderTop: "1px solid rgba(34,36,38,.1)",
              }}
            >
              <Icon name="gem" />
              Uniform and Presentation Survey
            </Menu.Item>
          </Link>
          <Link to="/m/surveys/warehouse">
            <Menu.Item
              link
              name="Warehouse Survey"
              style={{
                borderTop: "1px solid rgba(34,36,38,.1)",
              }}
            >
              <Icon name="warehouse" />
              Warehouse Survey
            </Menu.Item>
          </Link>
        </Menu>
      </Route>
      <Route exact path="/m/surveys/fleet">
        <div style={{ marginTop: 27, marginBottom: 27 }}>
          <Fleet />
        </div>
      </Route>
      <Route exact path="/m/surveys/safety">
        <div style={{ marginTop: 27, marginBottom: 27 }}>
          <SafetySurvey />
        </div>
      </Route>
      <Route exact path="/m/surveys/presentation">
        <div style={{ marginTop: 27, marginBottom: 27 }}>
          <UniformAndPresentation />
        </div>
      </Route>
      <Route exact path="/m/surveys/warehouse">
        <div style={{ marginTop: 27, marginBottom: 27 }}>
          <Warehouse />
        </div>
      </Route>
    </div>
  );
};

export default withRouter(Surveys);
