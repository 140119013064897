import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import DataGrid, { Column } from "devextreme-react/data-grid";
import numeral from "numeral";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import SuperintendentReport from "../../SuperintendentReport/SuperintendentReport";

function isEven(n) {
  return n % 2 === 0;
}

const RevenueGoals = ({
  dataSource: initialDataSource,
  division,
  userRole,
  year,
}) => {
  const [dataSource, setDataSource] = useState(initialDataSource);
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    setDataSource(initialDataSource);
  }, [initialDataSource]);

  const isExpanded = ({ data: { userid: userID, Type: type } }) => {
    const rowConfig = expandedRows.find(
      (x) => x.userID === userID && x.type === type
    );

    return rowConfig && rowConfig.active;
  };

  const hideDetails = ({ data: { userid: userID, Type: type } }) => {
    const rowConfig = expandedRows.find(
      (x) => x.userID === userID && x.type === type
    );

    const startData = dataSource.slice(0, rowConfig.startIdx + 1);
    const endData = dataSource.slice(rowConfig.endIdx + 1);

    rowConfig.active = false;

    setExpandedRows([...expandedRows, rowConfig]);
    setDataSource([...startData, ...endData]);
  };

  const getDetailRows = async ({
    rowIndex: rowIdx,
    data: { userid: userID, Type: type, UserKey },
  }) => {
    const goalType =
      type === "Pipeline" ? "Gross+Margin+Forecast" : "Gross+Margin+Actual";
    try {
      const rowConfig = expandedRows.find(
        (x) => x.userID === userID && x.type === type
      );

      if (!rowConfig) {
        const data = await Axios.get(
          `${API_ROOT}/api/dashboard/jobs-by-team-member-by-month?u=${userID}&y=${year}&d=${division}&g=${goalType}&r=${userRole}`
        )
          .then((results) => results.data.data)
          .then((results) =>
            results.map((x) => ({ ...x, UserKey, detailsRow: true }))
          );

        // save for later
        expandedRows.push({
          startIdx: rowIdx,
          endIdx: rowIdx + data.length,
          userID,
          type,
          data,
          active: true,
        });
        setExpandedRows(expandedRows);

        const startData = dataSource.slice(0, rowIdx + 1);
        const endData = dataSource.slice(rowIdx + 1);

        setDataSource([...startData, ...data, ...endData]);
      } else {
        const { data } = rowConfig;
        const startData = dataSource.slice(0, rowIdx + 1);
        const endData = dataSource.slice(rowIdx + 1);
        setDataSource([...startData, ...data, ...endData]);
        setExpandedRows(
          expandedRows.map((x) => {
            if (x.userID === userID && x.type === type) x.active = true;
            return x;
          })
        );
      }

      // stitch this in to the main datasource
    } catch (err) {
      console.error(err);
    }

    // console.log(response.data.data);
  };

  return (
    <DataGrid
      showBorders
      showRowLines
      showColumnLines
      sorting={{ mode: "none" }}
      paging={{ enabled: false }}
      onCellPrepared={(options) => {
        if (options.data && isEven(options.data.UserKey)) {
          options.cellElement.style.backgroundColor = "#1b1c1d";
        }
      }}
      dataSource={dataSource ? dataSource : []}
    >
      <Column
        dataField="TeamMember"
        width={200}
        cellRender={(options) => {
          if (options.rowType !== "data") {
            return <div>{options.value}</div>;
          } else {
            if (
              userRole === "SI" &&
              options.data.p2 === "S" &&
              options.data.Type === "Pipeline"
            ) {
              return (
                <div>
                  <SuperintendentReport year={year} division={division} />
                  {options.value}
                </div>
              );
            } else if (
              options.data.p === 10 &&
              options.columnIndex === 0 &&
              options.data.Type === "Pipeline"
            ) {
              return (
                <div>
                  <Icon
                    link
                    onClick={() =>
                      isExpanded(options)
                        ? hideDetails(options)
                        : getDetailRows(options)
                    }
                    color="blue"
                    name={
                      isExpanded(options) ? "chevron down" : "chevron right"
                    }
                  />
                  {options.value}
                </div>
              );
            } else if (
              options.data.p === 10 &&
              options.columnIndex === 0 &&
              options.data.Type === "Actual"
            ) {
              return (
                <div>
                  <Icon
                    link
                    onClick={() =>
                      isExpanded(options)
                        ? hideDetails(options)
                        : getDetailRows(options)
                    }
                    color="blue"
                    name={
                      isExpanded(options) ? "chevron down" : "chevron right"
                    }
                  />
                  {options.value}
                </div>
              );
            } else if (
              options.data.p === 10 &&
              // options.data.Type &&
              options.columnIndex === 0 &&
              [
                "Goal",
                "Pipeline Variance",
                "Actual Variance",
                "Act+Pipeline",
                "Act+Goal",
              ].indexOf(options.data.Type) > -1
            ) {
              return (
                <div>
                  <Icon style={{ opacity: 0 }} name="check" />
                  {options.value}
                </div>
              );
              // options.data.TeamMember;
            }
          }
        }}
      />
      <Column
        dataField="Type"
        width={200}
        cellRender={(options) => {
          if (options.data.detailsRow)
            return (
              <div
                style={{
                  fontWeight: options.data.p2 === "S" ? "bold" : "normal",
                  fontSize: options.data.p2 === "S" ? 16 : 14,
                  color: options.data.p2 === "S" ? "#2185d0" : "white",
                }}
              >
                <a
                  href={`/browser/projects/${options.data.Job}`}
                  target="_blank"
                  title={`${options.data.JobName} #${options.data.Job}`}
                >
                  {options.data.JobName}
                </a>
              </div>
            );

          return (
            <div
              style={{
                fontWeight: options.data.p2 === "S" ? "bold" : "normal",
                fontSize: options.data.p2 === "S" ? 16 : 14,
                color: options.data.p2 === "S" ? "#2185d0" : "white",
              }}
            >
              {options.value}
            </div>
          );
        }}
      />
      <Column
        dataField="Jan"
        cellRender={(options) => {
          let fieldData = numeral(options.value).format("0,0");

          return (
            <div
              style={{
                fontWeight: options.data.p2 === "S" ? "bold" : "normal",
                fontSize: options.data.p2 === "S" ? 16 : 14,
                color:
                  options.value < 0
                    ? "#db2828"
                    : options.data.p2 === "S"
                    ? "#2185d0"
                    : "white",
              }}
            >
              {fieldData}
            </div>
          );
        }}
      />
      <Column
        dataField="Feb"
        cellRender={(options) => {
          let fieldData = numeral(options.value).format("0,0");

          return (
            <div
              style={{
                fontWeight: options.data.p2 === "S" ? "bold" : "normal",
                fontSize: options.data.p2 === "S" ? 16 : 14,
                color:
                  options.value < 0
                    ? "#db2828"
                    : options.data.p2 === "S"
                    ? "#2185d0"
                    : "white",
              }}
            >
              {fieldData}
            </div>
          );

          //    if (options.rowType === "data") {
          //   if (options.data.p2 === "S") {
          //     options.cellElement.style.fontWeight = "bold";
          //     options.cellElement.style.fontSize = "16px";
          //   }

          //   if (isEven(options.data.UserKey) === false) {
          //     options.cellElement.style.backgroundColor = "#1b1c1d";
          //   }

          //   if (fieldData && fieldData < 0) {
          //     options.cellElement.style.color = "red";
          //   }

          //   if (
          //     options.data.Type === "Percent" &&
          //     options.columnIndex > 1
          //   ) {
          //     options.cellElement.html(
          //       numeral(fieldData).format("0.00%")
          //     );
          //   }

          //   if (
          //     options.data.p === 10 &&
          //     options.columnIndex === 0 &&
          //     options.data.Type === "Pipeline"
          //   ) {
          //     options.cellElement.innerHTML = ` <a href='${API_ROOT}/api/dashboard/jobs-by-team-member-by-month?y=${year}&d=construction&u=${options.data.userid}&g=Gross+Margin+Forecast&r=PM'><i class='cloud download icon' aria-hidden='true'></i></a> ${options.data.TeamMember}`;
          //   }

          //   if (
          //     options.data.p === 10 &&
          //     options.columnIndex === 0 &&
          //     options.data.Type === "Actual"
          //   ) {
          //     options.cellElement.innerHTML =
          //       " <a href='/DesktopModules/XModPro/Feed.aspx?xfd=ST18DB_JobsByTeamMemberByMonth&pid=0&y=" +
          //       year +
          //       "&d=construction&u=" +
          //       options.data.userid +
          //       "&g=Gross+Margin+Actual&r=" +
          //       "PM" +
          //       "'><i class='cloud download icon' aria-hidden='true'></i></a> " +
          //       options.data.TeamMember;
          //   }

          //   if (
          //     options.data.p === 10 &&
          //     // options.data.Type &&
          //     options.columnIndex === 0 &&
          //     [
          //       "Goal",
          //       "Pipeline Variance",
          //       "Actual Variance",
          //     ].indexOf(options.data.Type) > -1
          //   ) {
          //     options.cellElement.innerHTML =
          //       "<i class='fw icon' aria-hidden='true'></i> " +
          //       options.data.TeamMember;
          //   }
          // }}
        }}
      />
      <Column
        dataField="Mar"
        cellRender={(options) => {
          let fieldData = numeral(options.value).format("0,0");

          return (
            <div
              style={{
                fontWeight: options.data.p2 === "S" ? "bold" : "normal",
                fontSize: options.data.p2 === "S" ? 16 : 14,
                color:
                  options.value < 0
                    ? "#db2828"
                    : options.data.p2 === "S"
                    ? "#2185d0"
                    : "white",
              }}
            >
              {fieldData}
            </div>
          );
        }}
      />
      <Column
        dataField="Apr"
        cellRender={(options) => {
          let fieldData = numeral(options.value).format("0,0");

          return (
            <div
              style={{
                fontWeight: options.data.p2 === "S" ? "bold" : "normal",
                fontSize: options.data.p2 === "S" ? 16 : 14,
                color:
                  options.value < 0
                    ? "#db2828"
                    : options.data.p2 === "S"
                    ? "#2185d0"
                    : "white",
              }}
            >
              {fieldData}
            </div>
          );
        }}
      />
      <Column
        dataField="May"
        cellRender={(options) => {
          let fieldData = numeral(options.value).format("0,0");

          return (
            <div
              style={{
                fontWeight: options.data.p2 === "S" ? "bold" : "normal",
                fontSize: options.data.p2 === "S" ? 16 : 14,
                color:
                  options.value < 0
                    ? "#db2828"
                    : options.data.p2 === "S"
                    ? "#2185d0"
                    : "white",
              }}
            >
              {fieldData}
            </div>
          );
        }}
      />
      <Column
        dataField="Jun"
        cellRender={(options) => {
          let fieldData = numeral(options.value).format("0,0");

          return (
            <div
              style={{
                fontWeight: options.data.p2 === "S" ? "bold" : "normal",
                fontSize: options.data.p2 === "S" ? 16 : 14,
                color:
                  options.value < 0
                    ? "#db2828"
                    : options.data.p2 === "S"
                    ? "#2185d0"
                    : "white",
              }}
            >
              {fieldData}
            </div>
          );
        }}
      />
      <Column
        dataField="Jul"
        cellRender={(options) => {
          let fieldData = numeral(options.value).format("0,0");

          return (
            <div
              style={{
                fontWeight: options.data.p2 === "S" ? "bold" : "normal",
                fontSize: options.data.p2 === "S" ? 16 : 14,
                color:
                  options.value < 0
                    ? "#db2828"
                    : options.data.p2 === "S"
                    ? "#2185d0"
                    : "white",
              }}
            >
              {fieldData}
            </div>
          );
        }}
      />
      <Column
        dataField="Aug"
        cellRender={(options) => {
          let fieldData = numeral(options.value).format("0,0");

          return (
            <div
              style={{
                fontWeight: options.data.p2 === "S" ? "bold" : "normal",
                fontSize: options.data.p2 === "S" ? 16 : 14,
                color:
                  options.value < 0
                    ? "#db2828"
                    : options.data.p2 === "S"
                    ? "#2185d0"
                    : "white",
              }}
            >
              {fieldData}
            </div>
          );
        }}
      />
      <Column
        dataField="Sep"
        cellRender={(options) => {
          let fieldData = numeral(options.value).format("0,0");

          return (
            <div
              style={{
                fontWeight: options.data.p2 === "S" ? "bold" : "normal",
                fontSize: options.data.p2 === "S" ? 16 : 14,
                color:
                  options.value < 0
                    ? "#db2828"
                    : options.data.p2 === "S"
                    ? "#2185d0"
                    : "white",
              }}
            >
              {fieldData}
            </div>
          );
        }}
      />
      <Column
        dataField="Oct"
        cellRender={(options) => {
          let fieldData = numeral(options.value).format("0,0");

          return (
            <div
              style={{
                fontWeight: options.data.p2 === "S" ? "bold" : "normal",
                fontSize: options.data.p2 === "S" ? 16 : 14,
                color:
                  options.value < 0
                    ? "#db2828"
                    : options.data.p2 === "S"
                    ? "#2185d0"
                    : "white",
              }}
            >
              {fieldData}
            </div>
          );
        }}
      />
      <Column
        dataField="Nov"
        cellRender={(options) => {
          let fieldData = numeral(options.value).format("0,0");

          return (
            <div
              style={{
                fontWeight: options.data.p2 === "S" ? "bold" : "normal",
                fontSize: options.data.p2 === "S" ? 16 : 14,
                color:
                  options.value < 0
                    ? "#db2828"
                    : options.data.p2 === "S"
                    ? "#2185d0"
                    : "white",
              }}
            >
              {fieldData}
            </div>
          );
        }}
      />
      <Column
        dataField="Dec"
        cellRender={(options) => {
          let fieldData = numeral(options.value).format("0,0");

          return (
            <div
              style={{
                fontWeight: options.data.p2 === "S" ? "bold" : "normal",
                fontSize: options.data.p2 === "S" ? 16 : 14,
                color:
                  options.value < 0
                    ? "#db2828"
                    : options.data.p2 === "S"
                    ? "#2185d0"
                    : "white",
              }}
            >
              {fieldData}
            </div>
          );
        }}
      />
      <Column
        dataField="Total"
        cellRender={(options) => {
          let fieldData = numeral(options.value).format("0,0");

          return (
            <div
              style={{
                fontWeight: options.data.p2 === "S" ? "bold" : "normal",
                fontSize: options.data.p2 === "S" ? 16 : 14,
                color:
                  options.value < 0
                    ? "#db2828"
                    : options.data.p2 === "S"
                    ? "#2185d0"
                    : "white",
              }}
            >
              {fieldData}
            </div>
          );
        }}
      />
    </DataGrid>
  );
};

export default RevenueGoals;
