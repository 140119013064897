import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import _ from "lodash";
import { API_ROOT } from "../../../../api-config";

import ReactExport from "react-export-excel";
import moment from "moment";
import { useSelector } from "react-redux";
import { userIsInRole } from "../../../../util";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const months = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

const PipelineReport = () => {
  const [dataSet, setDataSet] = useState([]);

  // Check if user is in CRM Sub Add role
  const userIsAdmin = useSelector((state) =>
    state.auth.user ? userIsInRole(state, 8) : false
  );

  useEffect(() => {
    if (userIsAdmin) {
      Axios.get(`${API_ROOT}/api/project/pipeline-report`)
        .then((res) => {
          setDataSet(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [userIsAdmin]);

  return Array.isArray(dataSet) && dataSet.length && userIsAdmin ? (
    <ExcelFile
      filename={`Pipeline Report ${moment(new Date()).format("MM-DD-YYYY")}`}
      element={
        <Button
          icon
          labelPosition="left"
          size="mini"
          color="grey"
          style={{ marginLeft: 20 }}
        >
          <Icon name="download" />
          Pipeline Report
        </Button>
      }
    >
      <ExcelSheet data={dataSet} name="Pipeline Report">
        <ExcelColumn label="Division" value="Division" />
        <ExcelColumn label="Bid Type" value="BidType" />
        <ExcelColumn label="Stage" value="Stage" />
        <ExcelColumn label="Project" value="Project" />
        <ExcelColumn label="Project Manager" value="ProjectManager" />
        <ExcelColumn label="Contract Amount" value="ContractAmount" />
        <ExcelColumn
          label="Estimated Margin Percent"
          value="EstMarginPercent"
        />
        <ExcelColumn label="Estimated Margin" value="EstMargin" />
        {[1, 2, 3].map((year) =>
          months.map((month) => {
            return (
              <ExcelColumn
                label={_.capitalize(month) + " " + year}
                value={`${month}${year}`}
              />
            );
          })
        )}
        <ExcelColumn
          label="Total Forcast"
          value="total"
        />
      </ExcelSheet>
    </ExcelFile>
  ) : null;
};

export default PipelineReport;
