import React, { useState } from "react";
import { Button, Popup, Form, Input } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../../../api-config";
import { userIsInRole } from "../../../../../util";
import { useSelector } from "react-redux";

// Create our number formatter.
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const InvoicingPopup = (props) => {
  const [currentPayAppAmt, setCurrentPayAppAmt] = useState("");
  const [payAppAmtLoading, setPayAppAmtLoading] = useState(false);
  const [isAmtOpen, setIsAmountOpen] = useState(false);
  const [isPctOpen, setIsPctOpen] = useState(false);
  const [currentPayAppPct, setCurrentPayAppPct] = useState("");
  const [payAppPctLoading, setPayAppPctLoading] = useState(false);

  const { invoicedPayAppTypes, subCategory, i } = props;

  //   Check if user is in the accounting role
  const userIsAccounting = useSelector((state) => userIsInRole(state, 3));

  //   Check if user is in the Superuser role
  const userIsSuperuser = useSelector((state) => userIsInRole(state, 1));

  const handlePayAppAmtBtnClick = (paymentApplicationType, subCategoryCode) => {
    setPayAppAmtLoading(true);
    const payAppAmt = currentPayAppAmt;

    Axios.post(`${API_ROOT}/api/invoice/payment-application-amount`, {
      params: {
        projectID: props.projectID,
        paymentApplicationType,
        subCategoryCode,
        amount: payAppAmt,
      },
    }).then((res) => {
      props.setClientInvoicing(res.data.invoicing || []);
      props.getClientInvoicingTotals();
      setPayAppAmtLoading(false);
      setIsAmountOpen(false);
    });
  };

  const handlePayAppPctBtnClick = (
    paymentApplicationType,
    subCategoryCode,
    adjustedAmt
  ) => {
    setPayAppPctLoading(true);
    const payAppPct = currentPayAppPct;
    const amount =
      adjustedAmt * ((payAppPct ? parseFloat(payAppPct) : 0) / 100.0);

    Axios.post(`${API_ROOT}/api/invoice/payment-application-amount`, {
      params: {
        projectID: props.projectID,
        paymentApplicationType,
        subCategoryCode,
        amount,
      },
    }).then((res) => {
      props.setClientInvoicing(res.data.invoicing || []);
      props.getClientInvoicingTotals();
      setPayAppPctLoading(false);
      setIsPctOpen(false);
    });
  };

  return (
    <Popup
      open={isAmtOpen}
      disabled={
        (!userIsAccounting || !userIsSuperuser) &&
        invoicedPayAppTypes.includes("App #" + (i + 1))
      }
      trigger={
        <Input
          className="pay-app-display-input"
          size="mini"
          style={{ minWidth: 160 }}
          fluid
          label={
            <Popup
              disabled={
                (!userIsAccounting || !userIsSuperuser) &&
                invoicedPayAppTypes.includes("App #" + (i + 1))
              }
              content={
                <Form>
                  <Form.Group>
                    <Input
                      disabled={payAppPctLoading}
                      style={{ width: 140 }}
                      type="number"
                      icon="percent"
                      autoFocus
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setCurrentPayAppPct(parseFloat(e.target.value));
                        } else {
                          return;
                        }
                      }}
                      value={currentPayAppPct}
                      // defaultValue={
                      //   subCategory[
                      //     "appPct" + (i + 1)
                      //   ]
                      // }
                    />
                    <Button
                      disabled={payAppPctLoading}
                      loading={payAppPctLoading}
                      icon="check"
                      size="tiny"
                      onClick={() =>
                        handlePayAppPctBtnClick(
                          `App #${i + 1}`,
                          subCategory.subCategoryCode,
                          subCategory.adjusted
                        )
                      }
                    ></Button>
                  </Form.Group>
                </Form>
              }
              onOpen={() => {
                setCurrentPayAppPct(
                  subCategory.payapps
                    ? subCategory.payapps["appPct" + (i + 1)] === 0
                      ? ""
                      : subCategory.payapps["appPct" + (i + 1)]
                    : ""
                );
                setIsAmountOpen(false);
                setIsPctOpen(true);
              }}
              on="click"
              open={isPctOpen}
              onClose={() => setIsPctOpen(false)}
              trigger={
                <Button size="tiny">
                  {subCategory.payapps
                    ? subCategory.payapps["appPct" + (i + 1)].toFixed(0) + "%"
                    : "0%"}
                </Button>
              }
            />
          }
          readOnly
          value={
            subCategory.payapps
              ? formatter.format(subCategory.payapps["app" + (i + 1)])
              : formatter.format(0)
          }
        />
      }
      position="top center"
      content={
        <Form>
          <Form.Group>
            <Input
              style={{ width: 180 }}
              type="number"
              icon="dollar"
              autoFocus
              iconPosition="left"
              value={currentPayAppAmt}
              onChange={(e) => {
                if (!isNaN(e.target.value)) {
                  setCurrentPayAppAmt(e.target.value);
                } else {
                  return;
                }
              }}
              disabled={payAppAmtLoading}
            />
            <Button
              disabled={payAppAmtLoading}
              loading={payAppAmtLoading}
              icon={"check"}
              onClick={() => {
                handlePayAppAmtBtnClick(
                  `App #${i + 1}`,
                  subCategory.subCategoryCode
                );
              }}
            />
          </Form.Group>
        </Form>
      }
      onOpen={() => {
        setCurrentPayAppAmt(
          subCategory.payapps
            ? subCategory.payapps["app" + (i + 1)] === 0
              ? ""
              : subCategory.payapps["app" + (i + 1)]
            : ""
        );
        setIsAmountOpen(true);
      }}
      onClose={() => {
        setCurrentPayAppAmt("");
        setIsAmountOpen(false);
      }}
      on="click"
    />
  );
};

export default InvoicingPopup;
