import React, { createContext, useEffect, useState } from "react";

const WorksheetContext = createContext();
export default WorksheetContext;

export const WorksheetProvider = ({ data, children }) => {
  const [worksheet, setWorksheet] = useState([]);

  useEffect(() => {
    const results = Object.keys(data)
      .filter((key) => key !== "totals")
      .reduce(
        (carry, key) =>
          carry.concat(data[key].subs).map((x) => ({
            ...x,
            estimated_cost: 0,
            estimatedCost() {
              return this.committed_cost + this.estimated_cost;
            },
            estimatedProfit() {
              return this.adjusted_total - this.estimatedCost();
            },
            estimatedProfitPercent(format = true) {
              if (!this.adjusted_total || this.adjusted_total === 0)
                return format ? "0.00%" : 0;

              const percent = this.estimatedProfit() / this.adjusted_total;

              if (!format) return percent;

              return percent
                ? percent.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    style: "percent",
                  })
                : "0.00%";
            },
          })),
        []
      );
    setWorksheet(results);
  }, [data]);

  const update = (subCategory, data) => {
    setWorksheet(
      worksheet.map((w) => {
        if (w.sub_category_code === subCategory)
          w.estimated_cost = isNaN(data) || !data ? 0 : parseInt(data);
        return w;
      })
    );
  };

  const get = (subCategory) =>
    worksheet.find((x) => {
      return x.sub_category_code === subCategory;
    });

  const sum = (category, field, defaultValue = 0) => {
    const results = worksheet
      .filter((x) => x.category_code === category)
      .reduce((carry, item) => {
        if (typeof field === "function") return carry + field(item);
        if (typeof item[field] === "function") return carry + item[field]();
        return carry + item[field];
      }, 0);

    if (isNaN(results)) return defaultValue;
    return results;
  };

  return (
    <WorksheetContext.Provider value={{ worksheet, update, get, sum }}>
      {children}
    </WorksheetContext.Provider>
  );
};
