import React, { useState, useEffect } from "react";
import {
  Grid,
  Form,
  Button,
  Icon,
  Modal,
  Header,
  Table,
  Segment,
  Dropdown,
  Popup,
} from "semantic-ui-react";
import Axios from "axios";

import { API_ROOT } from "../../../../api-config";

import "./Condition.scss";

const Condition = (props) => {
  // State
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [subModalOpen, setSubModalOpen] = useState(false);

  const [subcontractorBidHistory, setSubcontractorBidHistory] = useState([]);

  const [conditionName, setConditionName] = useState(
    props.condition.ConditionName || ""
  );
  const [clientContractID, setClientContractID] = useState(
    props.condition.ClientContractID || ""
  );
  const [subContractID, setSubContractID] = useState(
    props.condition.SubContractID || ""
  );

  const createCO = (type) => {
    if (type === "ClientContractID") {
      Axios.post(`${API_ROOT}/api/project/addcontractclient`, {
        params: {
          projectID: props.condition.ProjectID,
        },
      }).then((res) => {
        props.setClientContractOptions(
          res.data.results[0].ClientContractOptions
        );
        setClientContractID(res.data.results[0].ClientContractID);
        props.handleBlur(
          type,
          res.data.results[0].ClientContractID,
          props.conditionid
        );
        props.getInclusionDescriptions();
      });
    } else if (type === "SubContractID") {
      Axios.post(`${API_ROOT}/api/contract/addcontractsub`, {
        params: {
          projectID: props.condition.ProjectID,
          subCompanyID: selectedSubcontractor,
        },
      }).then((res) => {
        props.setSubContractOptions(
          res.data.contractOptions.subContractOptions
        );

        props.getInclusionDescriptions();
      });
    }
  };

  const createAlternate = (type) => {
    Axios.post(`${API_ROOT}/api/contract/add-contract-alternate`, {
      params: {
        projectID: props.condition.ProjectID,
      },
    }).then((res) => {
      // console.log(res.data.);
      props.setClientContractOptions(res.data.results[0].ClientContractOptions);
      setClientContractID(res.data.results[0].ClientContractID);
      props.handleBlur(
        type,
        res.data.results[0].ClientContractID,
        props.conditionid
      );
      props.getInclusionDescriptions();
    });
  };

  const handleClientContractChange = (name, value) => {
    if (value === 0) {
      createCO(name);
    } else if (value === -1) {
      createAlternate(name);
    } else {
      props.handleBlur(name, value, props.conditionid);

      setClientContractID(value);
    }
  };

  const handleSubContractChange = (name, value) => {
    if (value === 0) {
      createCO(name);
    } else {
      props.handleBlur(name, value, props.conditionid);
      setSubContractID(value);
    }
  };

  const [totalQuantities, setTotalQuantities] = useState(
    props.condition.TotalQuantities === null ||
      props.condition.TotalQuantities === undefined
      ? ""
      : props.condition.TotalQuantities
  );
  const [measurementType, setMeasurementType] = useState(
    props.condition.MeasurementType || ""
  );

  const [internalComments, setInternalComments] = useState(
    props.condition.InternalComments || ""
  );
  const [subcontractorScope, setSubcontractorScope] = useState(
    props.condition.SubcontractorScope || ""
  );
  // const [internalWorkDays, setInternalWorkDays] = useState(
  //   props.condition.InternalWorkDays || ''
  // );
  const [laborHours, setLaborHours] = useState(
    props.condition.LaborHours === null ||
      props.condition.LaborHours === undefined
      ? ""
      : props.condition.LaborHours
  );
  const [laborRate, setLaborRate] = useState(
    props.condition.LaborRate === null ||
      props.condition.LaborRate === undefined
      ? ""
      : props.condition.LaborRate.toFixed(2)
  );
  const [laborMarkup, setLaborMarkup] = useState(
    props.condition.LaborMarkup === null ||
      props.condition.LaborMarkup === undefined
      ? ""
      : props.condition.LaborMarkup
  );

  const [materialCost, setMaterialCost] = useState(
    props.condition.MaterialCost === null ||
      props.condition.MaterialCost === undefined
      ? ""
      : props.condition.MaterialCost.toFixed(0)
  );
  const [materialMarkup, setMaterialMarkup] = useState(
    props.condition.MaterialMarkup === null ||
      props.condition.MaterialMarkup === undefined
      ? ""
      : props.condition.MaterialMarkup
  );
  const [equipmentCost, setEquipmentCost] = useState(
    props.condition.EquipmentCost === null ||
      props.condition.EquipmentCost === undefined
      ? ""
      : props.condition.EquipmentCost.toFixed(0)
  );
  const [equipmentMarkup, setEquipmentMarkup] = useState(
    props.condition.EquipmentMarkup === null ||
      props.condition.EquipmentMarkup === undefined
      ? ""
      : props.condition.EquipmentMarkup
  );

  const [otherCostsCost, setOtherCostsCost] = useState(
    props.condition.OtherCostsCost === null ||
      props.condition.OtherCostsCost === undefined
      ? ""
      : props.condition.OtherCostsCost.toFixed(0)
  );
  const [otherCostsMarkup, setOtherCostsMarkup] = useState(
    props.condition.OtherCostsMarkup === null ||
      props.condition.OtherCostsMarkup === undefined
      ? ""
      : props.condition.OtherCostsMarkup
  );

  const [sub1CompanyID, setSub1CompanyID] = useState("");
  const [sub1BidAmount, setSub1BidAmount] = useState("");
  const [sub1Markup, setSub1Markup] = useState("");
  const [sub1Total, setSub1Total] = useState("");

  const [sub2CompanyID, setSub2CompanyID] = useState("");
  const [sub2BidAmount, setSub2BidAmount] = useState("");
  const [sub2Markup, setSub2Markup] = useState("");
  const [sub2Total, setSub2Total] = useState("");

  const [sub3CompanyID, setSub3CompanyID] = useState("");
  const [sub3BidAmount, setSub3BidAmount] = useState("");
  const [sub3Markup, setSub3Markup] = useState("");
  const [sub3Total, setSub3Total] = useState("");

  const [subcontractorOptions, setSubcontractorOptions] = useState([]);

  const [selectedSubcontractor, setSelectedSubcontractor] = useState("");

  const [focusValue, setFocusValue] = useState("");

  const [conditionTemplateOptions, setConditionTemplateOptions] = useState([]);

  const data = props.subContractOptions
    ? props.subContractOptions.find(
        (obj) => obj.subcompanyid === selectedSubcontractor
      )
    : null;

  const subOptions = data ? data.options : [];

  if (
    subOptions.length > 0 &&
    subOptions.filter((e) => e.value === 0).length <= 0
  ) {
    subOptions.push({ text: "Add new CO", value: 0, key: 0 });
  }

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const handleSubModalOpen = () => {
    //Get subcontractor bid history
    Axios.get(`${API_ROOT}/api/condition/subcontractorbidhistory`, {
      params: {
        conditionName,
        measurementType,
      },
    })
      .then((res) => {
        setSubcontractorBidHistory(res.data.subcontractorBidHistory || []);
        setSubModalOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubModalClose = () => {
    setSubModalOpen(false);
  };

  const [
    overwriteConditionTemplateID,
    setOverwriteConditionTemplateID,
  ] = useState("");
  const [overwriteModalOpen, setOverwriteModalOpen] = useState(false);

  const [overwriteCostModalOpen, setOverwriteCostModalOpen] = useState(false);

  useEffect(() => {
    let missingSubDescription = false;
    if (selectedSubcontractor && !subcontractorScope) {
      missingSubDescription = true;
    }
    props.setMissingSubDescription(missingSubDescription);
  }, []);

  useEffect(() => {
    let missingInternalDescription = false;
    if (!internalComments) {
      missingInternalDescription = true;
    }
    props.setMissingInternalDescription(missingInternalDescription);
  });

  useEffect(() => {
    let missingSubDescription = false;
    if (selectedSubcontractor && !subcontractorScope) {
      missingSubDescription = true;
    }
    props.setMissingSubDescription(missingSubDescription);
  }, [selectedSubcontractor, subcontractorScope]);

  useEffect(() => {
    setLaborMarkup(props.condition.LaborMarkup);
    setMaterialMarkup(props.condition.MaterialMarkup);
    setEquipmentMarkup(props.condition.EquipmentMarkup);
  }, [props.condition.LaborMarkup]);

  useEffect(() => {
    setMaterialMarkup(props.condition.MaterialMarkup);
  }, [props.condition.MaterialMarkup]);

  const handleOverwriteModalOpen = () => {
    let mainCategory = props.condition.MainCategory;
    let subCategory = props.condition.SubCategory;

    // get condition template options
    Axios.get(`${API_ROOT}/api/condition/templateoptions`, {
      params: {
        mainCategory,
        subCategory,
      },
    })
      .then((res) => {
        // set conditionTemplateOptions array
        setConditionTemplateOptions(res.data.options || []);

        // open modal
        setOverwriteModalOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleOverwriteModalClose = () => {
    setOverwriteConditionTemplateID("");
    setOverwriteModalOpen(false);
  };

  const handleConfirmOverwrite = () => {
    if (!overwriteConditionTemplateID) {
      return;
    }
    let sourceConditionID = props.conditionid;
    let targetConditionID = overwriteConditionTemplateID;

    // API call to overwrite template
    Axios.post(`${API_ROOT}/api/condition/templateoverwrite`, {
      params: {
        sourceConditionID,
        targetConditionID,
      },
    })
      .then((res) => {
        // console.log(res);
        // close modal
        setOverwriteModalOpen(false);
        // reset overwriteConditionTemplateID
        setOverwriteConditionTemplateID("");

        // SEND NOTIFICATION EMAIL
        Axios.post(`${API_ROOT}/send-email`, {
          email: "tcowan@nexus5group.com",
          cc: "",
          bcc: "SoftwareDevelopment@cohenesrey.com",
          subject: "Condition Template Overwrite Request Submitted",
          text: `A condition template overwrite request has been submitted for the following condition template: ${res.data.conditionTemplate[0].ConditionName}.`,
          html: `<p>A condition template overwrite request has been submitted for the following condition template: ${res.data.conditionTemplate[0].ConditionName}.</p>`,
        })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [
    newTemplateSuccessModalOpen,
    setNewTemplateSuccessModalOpen,
  ] = useState(false);

  const handleNewTemplateClick = () => {
    let sourceConditionID = props.conditionid;
    Axios.post(`${API_ROOT}/api/condition/templatenew`, {
      params: {
        sourceConditionID,
      },
    })
      .then((res) => {
        // console.log(res);
        // close modal
        setNewTemplateSuccessModalOpen(true);

        // SEND NOTIFICATION EMAIL
        Axios.post(`${API_ROOT}/send-email`, {
          email: "tcowan@nexus5group.com",
          cc: "",
          bcc: "SoftwareDevelopment@cohenesrey.com",
          subject: "Condition Template Request Submitted",
          text: `A condition template overwrite request has been submitted for your approval: ${res.data.conditionTemplate[0].ConditionName}.`,
          html: `<p>A new condition template has been submitted for your approval: ${res.data.conditionTemplate[0].ConditionName}.</p>`,
        })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleConfirmClick = () => {
    props.handleDelete(props.conditionid);
    handleClose();
  };

  const onBlur = (e) => {
    // check to see if value has changed by comparing it with the value on focus
    if (e.target.value !== focusValue) {
      const value = e.target.value;
      const field = e.target.name;
      const conditionID = props.conditionid;

      // save the value
      props.handleBlur(field, value, conditionID);
    }
  };

  const onSelect = (name, value) => {
    setMeasurementType(value);
    const field = name;

    props.handleBlur(field, value, props.conditionid);
  };

  useEffect(() => {
    // get subcontractor options
    if (props.conditionid) {
      Axios.get(`${API_ROOT}/api/condition/subcontractors`, {
        params: {
          conditionID: props.conditionid,
        },
      })
        .then((res) => {
          const conditionSubcontractor = res.data.conditionSubcontractor;

          const subcontractors = conditionSubcontractor.subcontractors;

          setSubcontractorOptions(conditionSubcontractor.subcontractorOptions);

          setSub1CompanyID(
            subcontractors.sub1 ? subcontractors.sub1.CompanyID : ""
          );
          setSub1BidAmount(
            subcontractors.sub1 ? subcontractors.sub1.BidAmount : ""
          );
          setSub1Markup(subcontractors.sub1 ? subcontractors.sub1.Markup : "");
          setSub1Total(subcontractors.sub1 ? subcontractors.sub1.Total : "");

          setSub2CompanyID(
            subcontractors.sub2 ? subcontractors.sub2.CompanyID : ""
          );
          setSub2BidAmount(
            subcontractors.sub2 ? subcontractors.sub2.BidAmount : ""
          );
          setSub2Markup(subcontractors.sub2 ? subcontractors.sub2.Markup : "");
          setSub2Total(subcontractors.sub2 ? subcontractors.sub2.Total : "");

          setSub3CompanyID(
            subcontractors.sub3 ? subcontractors.sub3.CompanyID : ""
          );
          setSub3BidAmount(
            subcontractors.sub3 ? subcontractors.sub3.BidAmount : ""
          );
          setSub3Markup(subcontractors.sub3 ? subcontractors.sub3.Markup : "");
          setSub3Total(subcontractors.sub3 ? subcontractors.sub3.Total : "");

          const initSelectedSubcontractor =
            conditionSubcontractor.selectedSubcontractor.SelectedSubcontractor;

          setSelectedSubcontractor(initSelectedSubcontractor);

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
      // then set subcontractor values

      // then set selected subcontractor
    }
  }, []);

  const saveSelectedSubcontractor = (companyID) => {
    const projectID = props.condition.ProjectID;
    const conditionID = props.conditionid;
    Axios.post(`${API_ROOT}/api/condition/selectedsubcontractor`, {
      params: {
        projectID,
        conditionID,
        companyID,
      },
    })
      .then((res) => {
        props.setSubContractOptions(
          res.data.contractOptions.subContractOptions
        );

        props.handleBlur("LaborHours", laborHours, props.condition.ConditionID);

        setOverwriteCostModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubDropdownChange = (value, precedence) => {
    let conditionID = props.conditionid;
    let companyID = value ? value : null;
    let bidAmount = null;
    let markup = null;

    saveConditionSubcontractor(
      conditionID,
      companyID,
      bidAmount,
      markup,
      precedence
    );
  };

  const saveConditionSubcontractor = (
    conditionID,
    companyID,
    bidAmount,
    markup,
    precedence
  ) => {
    Axios.post(`${API_ROOT}/api/condition/conditionsubcontractor`, {
      params: {
        conditionID,
        companyID: companyID ? companyID : null,
        bidAmount: bidAmount ? bidAmount : null,
        markup: markup ? parseFloat(markup) : null,
        precedence,
      },
    }).then((res) => {
      const conditionSubcontractor = res.data.conditionSubcontractor;

      const subcontractors = conditionSubcontractor.subcontractors;

      setSubcontractorOptions(conditionSubcontractor.subcontractorOptions);

      setSub1CompanyID(
        subcontractors.sub1 ? subcontractors.sub1.CompanyID : ""
      );
      setSub1BidAmount(
        subcontractors.sub1 ? subcontractors.sub1.BidAmount : ""
      );
      setSub1Markup(subcontractors.sub1 ? subcontractors.sub1.Markup : "");
      setSub1Total(subcontractors.sub1 ? subcontractors.sub1.Total : "");

      setSub2CompanyID(
        subcontractors.sub2 ? subcontractors.sub2.CompanyID : ""
      );
      setSub2BidAmount(
        subcontractors.sub2 ? subcontractors.sub2.BidAmount : ""
      );
      setSub2Markup(subcontractors.sub2 ? subcontractors.sub2.Markup : "");
      setSub2Total(subcontractors.sub2 ? subcontractors.sub2.Total : "");

      setSub3CompanyID(
        subcontractors.sub3 ? subcontractors.sub3.CompanyID : ""
      );
      setSub3BidAmount(
        subcontractors.sub3 ? subcontractors.sub3.BidAmount : ""
      );
      setSub3Markup(subcontractors.sub3 ? subcontractors.sub3.Markup : "");
      setSub3Total(subcontractors.sub3 ? subcontractors.sub3.Total : "");
    });
  };

  const handleSubInputBlur = (value, precedence) => {
    if (focusValue !== value) {
      let conditionID = props.conditionid;
      let companyID = null;
      let bidAmount = null;
      let markup = null;

      if (precedence === 1) {
        companyID = sub1CompanyID;
        bidAmount = parseFloat(sub1BidAmount);
        markup = sub1Markup;
      } else if (precedence === 2) {
        companyID = sub2CompanyID;
        bidAmount = parseFloat(sub2BidAmount);
        markup = sub2Markup;
      } else if (precedence === 3) {
        companyID = sub3CompanyID;
        bidAmount = parseFloat(sub3BidAmount);
        markup = sub3Markup;
      }

      saveConditionSubcontractor(
        conditionID,
        companyID,
        bidAmount,
        markup,
        precedence
      );
    }
  };

  const handleSubButtonClick = (companyID, precedence) => {
    if (!companyID && precedence) {
      return false;
    } else if (!companyID && !precedence) {
      if (selectedSubcontractor && props.condition.N5SelectedAtSigning === 0) {
        // if (selectedSubcontractor) {
        // open overwrite cost modal
        setOverwriteCostModalOpen(true);
      } else {
        setSelectedSubcontractor(null);
        saveSelectedSubcontractor(null);
      }
    } else if (companyID === selectedSubcontractor) {
      return false;
    } else {
      // save companyID as selectedSubcontractor
      setSelectedSubcontractor(companyID);
      saveSelectedSubcontractor(companyID);
    }
  };

  const handleConfirmCostOverwrite = () => {
    setSelectedSubcontractor(null);
    saveSelectedSubcontractor(null);
  };

  const clientApprovedInd = props.condition.ClientApprovedInd;
  const subContractApprovedInd = props.condition.SubContractApprovedInd;

  let lockIcon = "";
  let lockIconMessage = "";

  if (
    props.condition.ClientApprovedInd === 1 &&
    props.condition.OverwriteCostInd !== 1
  ) {
    lockIcon = "lock";
    lockIconMessage =
      "Cost estimates are locked and cannot be edited because Nexus 5 was selected at signing";
  } else if (
    props.condition.ClientApprovedInd === 1 &&
    props.condition.OverwriteCostInd === 1
  ) {
    lockIcon = "lock open";
    lockIconMessage =
      "Cost estimates are unlocked and can be edited because Nexus 5 was not selected at signing";
  }

  const formatType = props.condition
    ? props.condition.ProposalFormatType
    : "Summary";

  if (loading) {
    return (
      <>
        <p>
          <Icon size="big" loading name="spinner" />
        </p>
      </>
    );
  } else {
    return (
      <React.Fragment>
        {/* Template save success toast */}

        <hr
          style={{
            borderStyle: "solid",
            borderWidth: "thin",
            marginBottom: 20,
            marginTop: 20,
          }}
        />
        <Grid columns="1" className="Condition">
          <Grid.Row style={{ padding: 0 }}>
            <Grid.Column>
              <div>
                {lockIcon ? (
                  <Segment basic compact floated="left" clearing>
                    <Popup
                      content={lockIconMessage}
                      trigger={<Icon name={lockIcon} />}
                    />
                  </Segment>
                ) : null}

                <Segment
                  style={{ padding: 5 }}
                  basic
                  floated="right"
                  clearing
                  compact
                >
                  <Dropdown
                    icon={null}
                    trigger={<Icon link name="sidebar" size="large" />}
                  >
                    <Dropdown.Menu direction="left">
                      <Dropdown.Item
                        text="View subcontractor bid history"
                        onClick={handleSubModalOpen}
                        icon="history"
                      />
                      <Dropdown.Item
                        icon="edit"
                        text="Overwrite existing template"
                        onClick={handleOverwriteModalOpen}
                      />
                      <Dropdown.Item
                        icon="copy"
                        text="Create a new template from this condition"
                        onClick={handleNewTemplateClick}
                      />

                      {clientApprovedInd === 1 ||
                      subContractApprovedInd === 1 ? null : (
                        <Dropdown.Item
                          icon="remove"
                          text="Delete this condition"
                          onClick={handleOpen}
                        />
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </Segment>

                {/* Subcontractor History Modal */}
                <Modal
                  closeIcon
                  open={subModalOpen}
                  onClose={handleSubModalClose}
                >
                  <Header
                    // color="orange"
                    icon="history"
                    content="Subcontractor History"
                  />
                  <Modal.Content>
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Vendor</Table.HeaderCell>
                          <Table.HeaderCell>Unit Price</Table.HeaderCell>
                          <Table.HeaderCell>UOM</Table.HeaderCell>
                          <Table.HeaderCell>Bid Date</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {subcontractorBidHistory.map((item, index) => {
                          return (
                            <Table.Row key={index}>
                              <Table.Cell>{item.Vendor}</Table.Cell>
                              <Table.Cell>
                                {item.UnitPrice
                                  ? `$${item.UnitPrice.toFixed(2)}`
                                  : ""}
                              </Table.Cell>
                              <Table.Cell>{item.MeasurementType}</Table.Cell>
                              <Table.Cell>{item.BidDate}</Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button color="green" onClick={handleSubModalClose}>
                      <Icon name="checkmark" /> Got it
                    </Button>
                  </Modal.Actions>
                </Modal>

                {/* Delete condition modal */}
                <Modal open={modalOpen} onClose={handleClose} basic size="mini">
                  <Header
                    icon="trash alternate outline"
                    content="Delete Condition"
                  />
                  <Modal.Content>
                    <p>Are you sure you want to delete this condition?</p>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button onClick={handleClose} basic color="red" inverted>
                      <Icon name="remove" /> No
                    </Button>
                    <Button color="green" inverted onClick={handleConfirmClick}>
                      <Icon name="checkmark" /> Yes
                    </Button>
                  </Modal.Actions>
                </Modal>

                {/* Overwrite condition template modal */}
                <Modal
                  open={overwriteModalOpen}
                  onClose={handleOverwriteModalClose}
                  size="tiny"
                >
                  <Header
                    icon="edit"
                    content="Overwrite an existing template"
                  />
                  <Modal.Content>
                    <label>
                      Select the condition template you would like to overwrite
                    </label>
                    <Dropdown
                      fluid
                      search
                      selection
                      selectOnBlur={false}
                      options={conditionTemplateOptions}
                      value={overwriteConditionTemplateID}
                      onChange={(e, { value }) =>
                        setOverwriteConditionTemplateID(value)
                      }
                    />
                  </Modal.Content>
                  <Modal.Actions>
                    <Button onClick={handleOverwriteModalClose} color="yellow">
                      <Icon name="remove" /> Cancel
                    </Button>
                    <Button
                      disabled={overwriteConditionTemplateID ? false : true}
                      onClick={handleConfirmOverwrite}
                      color="green"
                    >
                      <Icon name="save" /> Submit for Approval
                    </Button>
                  </Modal.Actions>
                </Modal>

                <Modal
                  open={newTemplateSuccessModalOpen}
                  basic
                  size="mini"
                  onClose={() => setNewTemplateSuccessModalOpen(false)}
                >
                  <Header icon="thumbs up outline" content="Success" />
                  <Modal.Content>
                    <p>The template has been sent for approval</p>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      color="green"
                      inverted
                      onClick={() => setNewTemplateSuccessModalOpen(false)}
                    >
                      <Icon name="checkmark" /> Okay
                    </Button>
                  </Modal.Actions>
                </Modal>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column width={6}>
              <Form.Input
                conditionid={props.conditionid}
                name="ConditionName"
                width={16}
                label="Condition Name"
                readOnly={clientApprovedInd === 1 ? true : false}
                onChange={(e) => setConditionName(e.target.value)}
                value={conditionName}
                onFocus={(e) => setFocusValue(e.target.value)}
                onBlur={onBlur}
              />
              <Form.Group>
                <Form.Dropdown
                  className="dropdown-contract"
                  width={4}
                  label="Client Contract"
                  name="ClientContractID"
                  fluid
                  disabled={clientApprovedInd === 1 ? true : false}
                  selection
                  clearable
                  selectOnBlur={false}
                  value={clientContractID}
                  options={props.clientContractOptions}
                  onChange={(e, { name, value }) => {
                    handleClientContractChange(name, value);
                  }}
                />
                <Form.Dropdown
                  className="dropdown-contract"
                  width={4}
                  label="Sub Contract"
                  name="SubContractID"
                  fluid
                  selection
                  // style={{ backgroundColor: "red" }}
                  style={
                    selectedSubcontractor &&
                    (!subContractID || subContractID === "")
                      ? { backgroundColor: "red" }
                      : null
                  }
                  disabled={
                    !selectedSubcontractor || subContractApprovedInd === 1
                      ? true
                      : false
                  }
                  clearable
                  selectOnBlur={false}
                  value={subContractID}
                  options={subOptions}
                  onChange={(e, { name, value }) => {
                    handleSubContractChange(name, value);
                  }}
                />
                <Form.Input
                  width={4}
                  fluid
                  error={
                    (clientContractID && !totalQuantities) ||
                    (selectedSubcontractor && !totalQuantities)
                  }
                  label="Quantity"
                  // style={
                  //   (clientContractID && !totalQuantities) ||
                  //   (selectedSubcontractor && !totalQuantities)
                  //     ? { backgroundColor: "red" }
                  //     : null
                  // }
                  className={
                    clientApprovedInd === 1 || subContractApprovedInd === 1
                      ? "input-total"
                      : ""
                  }
                  readOnly={
                    clientApprovedInd === 1 || subContractApprovedInd === 1
                      ? true
                      : false
                  }
                  type="number"
                  name="TotalQuantities"
                  value={totalQuantities}
                  onFocus={(e) => setFocusValue(e.target.value)}
                  onChange={(e) => setTotalQuantities(e.target.value)}
                  onBlur={onBlur}
                />
                <Form.Select
                  width={4}
                  fluid
                  error={
                    (clientContractID && !measurementType) ||
                    (selectedSubcontractor && !measurementType)
                  }
                  label="UOM"
                  selectOnBlur={false}
                  className={
                    clientApprovedInd === 1 || subContractApprovedInd === 1
                      ? "input-total-select"
                      : ""
                  }
                  disabled={
                    clientApprovedInd === 1 || subContractApprovedInd === 1
                      ? true
                      : false
                  }
                  name="MeasurementType"
                  selection
                  search
                  value={measurementType}
                  onChange={(e, { name, value }) => {
                    onSelect(name, value);
                  }}
                  options={[
                    { key: 1, value: "EA", text: "Each" },
                    { key: 2, value: "LS", text: "Lump Sum" },
                    { key: 3, value: "SQFT", text: "Sq. Ft" },
                    { key: 4, value: "SQYD", text: "Sq. Yd" },
                    { key: 5, value: "LINFT", text: "L. Ft" },
                    { key: 6, value: "LINYD", text: "L. Yd" },
                    { key: 7, value: "FT", text: "B. Ft" },
                    { key: 8, value: "CUBFT", text: "Cubic Feet" },
                    { key: 9, value: "CUBYD", text: "C. Yd" },
                    { key: 10, value: "GAL", text: "Gallon" },
                    { key: 11, value: "MINUTE", text: "Minute" },
                    { key: 12, value: "MANHR", text: "Man Hr" },
                    { key: 13, value: "HR", text: "Hour" },
                    { key: 14, value: "WK", text: "Week" },
                    { key: 15, value: "MO", text: "Month" },
                    { key: 16, value: "YR", text: "Year" },
                    { key: 17, value: "LB", text: "Pounds" },
                    { key: 18, value: "MILE", text: "Miles" },
                    { key: 19, value: "OZ", text: "Ounce" },
                    { key: 20, value: "PA", text: "Pair" },
                    { key: 21, value: "QT", text: "Quart" },
                    { key: 22, value: "SACK", text: "Sack" },
                    { key: 23, value: "IN", text: "Inch" },
                  ]}
                />
              </Form.Group>
              {/* <Form.Group>
                <Form.Input
                  width={5}
                  fluid
                  label="Quantity"
                  type="number"
                  name="TotalQuantities"
                  value={totalQuantities}
                  onFocus={e => setFocusValue(e.target.value)}
                  onChange={e => setTotalQuantities(e.target.value)}
                  onBlur={onBlur}
                />
                <Form.Select
                  width={6}
                  fluid
                  label="UOM"
                  name="MeasurementType"
                  selection
                  search
                  value={measurementType}
                  onChange={(e, { name, value }) => {
                    onSelect(name, value);
                  }}
                  options={[
                    { key: 1, value: 'Each', text: 'Each' },
                    { key: 2, value: 'SqFt', text: 'SqFt' }
                  ]}
                />
                <Form.Input
                  width={5}
                  label="Work Days"
                  type="number"
                  name="InternalWorkDays"
                  value={internalWorkDays}
                  onFocus={e => setFocusValue(e.target.value)}
                  onChange={e => setInternalWorkDays(e.target.value)}
                  onBlur={onBlur}
                />
              </Form.Group> */}
            </Grid.Column>
            <Grid.Column width={10}>
              <Form.Group>
                <Form.TextArea
                  width={8}
                  label={
                    formatType === "Detail" ? (
                      <span>
                        Condition Description{" "}
                        <span style={{ color: "red" }}>
                          (To be displayed on the client contract)
                        </span>
                      </span>
                    ) : (
                      "Condition Description"
                    )
                  }
                  rows="5"
                  name="InternalComments"
                  value={internalComments}
                  onFocus={(e) => setFocusValue(e.target.value)}
                  onChange={(e) => setInternalComments(e.target.value)}
                  onBlur={onBlur}
                />
                <Form.TextArea
                  width={8}
                  label="Tech/Subcontractor Description"
                  rows="5"
                  name="SubcontractorScope"
                  value={subcontractorScope}
                  onFocus={(e) => setFocusValue(e.target.value)}
                  onChange={(e) => setSubcontractorScope(e.target.value)}
                  onBlur={onBlur}
                />
              </Form.Group>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={3}>
            <Grid.Column mobile={16} tablet={16} computer={6}>
              {/* Per Unit Inputs */}
              <Form.Group widths="equal" unstackable>
                <Form.Input
                  width={3}
                  label="Labor Hrs"
                  className={
                    clientApprovedInd === 1 &&
                    props.condition.OverwriteCostInd !== 1
                      ? "input-total"
                      : ""
                  }
                  readOnly={
                    clientApprovedInd === 1 &&
                    props.condition.OverwriteCostInd !== 1
                      ? true
                      : false
                  }
                  type="number"
                  name="LaborHours"
                  value={laborHours}
                  onChange={(e) => setLaborHours(e.target.value)}
                  onFocus={(e) => setFocusValue(e.target.value)}
                  onBlur={onBlur}
                />
                <Form.Input
                  width={3}
                  label="Labor Rate"
                  type="number"
                  className={
                    clientApprovedInd === 1 &&
                    props.condition.OverwriteCostInd !== 1
                      ? "input-total"
                      : ""
                  }
                  readOnly={
                    clientApprovedInd === 1 &&
                    props.condition.OverwriteCostInd !== 1
                      ? true
                      : false
                  }
                  name="LaborRate"
                  value={laborRate}
                  onChange={(e) => setLaborRate(e.target.value)}
                  onFocus={(e) => setFocusValue(e.target.value)}
                  onBlur={onBlur}
                />
                <Form.Input
                  width={3}
                  label="Labor Cost"
                  readOnly
                  className="input-total"
                  type="number"
                  value={
                    props.condition.LaborCost
                      ? props.condition.LaborCost.toFixed(2)
                      : ""
                  }
                />
                <Form.Input
                  width={3}
                  label="Labor MU"
                  type="number"
                  className={clientApprovedInd === 1 ? "input-total" : ""}
                  readOnly={clientApprovedInd === 1 ? true : false}
                  name="LaborMarkup"
                  // icon="percent"
                  value={laborMarkup}
                  onChange={(e) => setLaborMarkup(e.target.value)}
                  onFocus={(e) => setFocusValue(e.target.value)}
                  onBlur={onBlur}
                />
                <Form.Input
                  width={3}
                  label="Labor Total"
                  type="number"
                  readOnly
                  className="input-total"
                  value={
                    props.condition.LaborTotal !== null &&
                    props.condition.LaborTotal !== undefined
                      ? props.condition.LaborTotal.toFixed(2)
                      : (0).toFixed(2)
                  }
                />
              </Form.Group>

              {/* Labor Totals */}
              <Form.Group widths="equal" unstackable>
                {/* Labor Hours */}
                <Form.Input
                  className="input-total"
                  width={3}
                  type="number"
                  name="LaborHours"
                  value={props.condition.LaborHoursTotal}
                  readOnly
                />
                {/* Labor Rate */}
                <Form.Input
                  className="input-total"
                  width={3}
                  type="number"
                  name="LaborRate"
                  value={laborRate}
                  readOnly
                />
                {/* Labor Cost */}
                <Form.Input
                  className="input-total"
                  width={3}
                  type="number"
                  value={props.condition.LaborCostTotal}
                  readOnly
                />
                {/* Labor Markup */}
                <Form.Input
                  className="input-total"
                  width={3}
                  type="number"
                  name="LaborMarkup"
                  // icon="percent"
                  value={props.condition.LaborMarkup}
                  readOnly
                />
                {/* Labor Total */}
                <Form.Input
                  className="input-total"
                  width={3}
                  type="number"
                  value={
                    props.condition.LaborGrandTotal !== null &&
                    props.condition.LaborGrandTotal !== undefined
                      ? props.condition.LaborGrandTotal.toFixed(2)
                      : (0).toFixed(2)
                  }
                  readOnly
                />
              </Form.Group>
            </Grid.Column>
            <Grid.Column tablet={16} computer={3}>
              <Form.Group unstackable widths="equal">
                <Form.Input
                  width={2}
                  fluid
                  label="Mat. Cost"
                  className={
                    clientApprovedInd === 1 &&
                    props.condition.OverwriteCostInd !== 1
                      ? "input-total"
                      : ""
                  }
                  readOnly={
                    clientApprovedInd === 1 &&
                    props.condition.OverwriteCostInd !== 1
                      ? true
                      : false
                  }
                  type="number"
                  name="MaterialCost"
                  value={materialCost}
                  onChange={(e) => setMaterialCost(e.target.value)}
                  onFocus={(e) => setFocusValue(e.target.value)}
                  onBlur={onBlur}
                />
                <Form.Input
                  width={2}
                  fluid
                  label="Mat. MU"
                  className={clientApprovedInd === 1 ? "input-total" : ""}
                  readOnly={clientApprovedInd === 1 ? true : false}
                  type="number"
                  name="MaterialMarkup"
                  // icon="percent"
                  value={materialMarkup}
                  onFocus={(e) => setFocusValue(e.target.value)}
                  onChange={(e) => setMaterialMarkup(e.target.value)}
                  onBlur={onBlur}
                />
                <Form.Input
                  width={2}
                  fluid
                  label="Mat. Total"
                  readOnly
                  className="input-total"
                  type="number"
                  value={
                    props.condition.MaterialTotal !== null &&
                    props.condition.MaterialTotal !== undefined
                      ? props.condition.MaterialTotal.toFixed(2)
                      : (0).toFixed(2)
                  }
                />
              </Form.Group>
              <Form.Group unstackable widths="equal">
                {/* Material Cost */}
                <Form.Input
                  className="input-total"
                  width={2}
                  fluid
                  readOnly
                  type="number"
                  name="MaterialCost"
                  value={props.condition.MaterialCostTotal}
                />
                {/* Material Markup */}
                <Form.Input
                  className="input-total"
                  width={2}
                  fluid
                  readOnly
                  // icon="percent"
                  type="number"
                  name="MaterialMarkup"
                  value={props.condition.MaterialMarkup}
                />
                {/* Material Total */}
                <Form.Input
                  className="input-total"
                  width={2}
                  fluid
                  readOnly
                  type="number"
                  value={
                    props.condition.MaterialGrandTotal !== null &&
                    props.condition.MaterialGrandTotal !== undefined
                      ? props.condition.MaterialGrandTotal.toFixed(2)
                      : (0).toFixed(2)
                  }
                />
              </Form.Group>
            </Grid.Column>
            <Grid.Column tablet={16} computer={3}>
              <Form.Group unstackable widths="equal">
                <Form.Input
                  width={2}
                  label="Equip. Cost"
                  type="number"
                  className={
                    clientApprovedInd === 1 &&
                    props.condition.OverwriteCostInd !== 1
                      ? "input-total"
                      : ""
                  }
                  readOnly={
                    clientApprovedInd === 1 &&
                    props.condition.OverwriteCostInd !== 1
                      ? true
                      : false
                  }
                  fluid
                  name="EquipmentCost"
                  value={equipmentCost}
                  onFocus={(e) => setFocusValue(e.target.value)}
                  onChange={(e) => setEquipmentCost(e.target.value)}
                  onBlur={onBlur}
                />
                <Form.Input
                  width={2}
                  label="Equip. MU"
                  type="number"
                  className={clientApprovedInd === 1 ? "input-total" : ""}
                  readOnly={clientApprovedInd === 1 ? true : false}
                  fluid
                  name="EquipmentMarkup"
                  // icon="percent"
                  value={equipmentMarkup}
                  onFocus={(e) => setFocusValue(e.target.value)}
                  onChange={(e) => setEquipmentMarkup(e.target.value)}
                  onBlur={onBlur}
                />
                <Form.Input
                  width={2}
                  label="Equip. Total"
                  type="number"
                  readOnly
                  className="input-total"
                  fluid
                  value={
                    props.condition.EquipmentTotal !== null &&
                    props.condition.EquipmentTotal !== undefined
                      ? props.condition.EquipmentTotal.toFixed(2)
                      : (0).toFixed(2)
                  }
                />
              </Form.Group>
              <Form.Group unstackable widths="equal">
                {/* Equipment Cost */}
                <Form.Input
                  className="input-total"
                  readOnly
                  width={2}
                  type="number"
                  fluid
                  name="EquipmentCost"
                  value={props.condition.EquipmentCostTotal}
                />
                {/* Equipment Markup */}
                <Form.Input
                  className="input-total"
                  width={2}
                  readOnly
                  type="number"
                  fluid
                  name="EquipmentMarkup"
                  // icon="percent"
                  value={props.condition.EquipmentMarkup}
                />
                {/* Equipment Total */}
                <Form.Input
                  className="input-total"
                  readOnly
                  width={2}
                  type="number"
                  fluid
                  value={
                    props.condition.EquipmentGrandTotal !== null &&
                    props.condition.EquipmentGrandTotal !== undefined
                      ? props.condition.EquipmentGrandTotal.toFixed(2)
                      : (0).toFixed(2)
                  }
                />
              </Form.Group>
            </Grid.Column>
            <Grid.Column tablet={16} computer={3}>
              <Form.Group unstackable widths="equal">
                <Form.Input
                  width={2}
                  label="Other Cost"
                  type="number"
                  className={
                    clientApprovedInd === 1 &&
                    props.condition.OverwriteCostInd !== 1
                      ? "input-total"
                      : ""
                  }
                  readOnly={
                    clientApprovedInd === 1 &&
                    props.condition.OverwriteCostInd !== 1
                      ? true
                      : false
                  }
                  fluid
                  name="OtherCostsCost"
                  value={otherCostsCost}
                  onFocus={(e) => setFocusValue(e.target.value)}
                  onChange={(e) => setOtherCostsCost(e.target.value)}
                  onBlur={onBlur}
                />
                <Form.Input
                  width={2}
                  label="Other MU"
                  type="number"
                  className={clientApprovedInd === 1 ? "input-total" : ""}
                  readOnly={clientApprovedInd === 1 ? true : false}
                  fluid
                  name="OtherCostsMarkup"
                  // icon="percent"
                  value={otherCostsMarkup}
                  onFocus={(e) => setFocusValue(e.target.value)}
                  onChange={(e) => setOtherCostsMarkup(e.target.value)}
                  onBlur={onBlur}
                />
                <Form.Input
                  width={2}
                  label="Other Total"
                  type="number"
                  readOnly
                  className="input-total"
                  fluid
                  value={
                    props.condition.OtherCostsTotal !== null &&
                    props.condition.OtherCostsTotal !== undefined
                      ? props.condition.OtherCostsTotal.toFixed(2)
                      : (0).toFixed(2)
                  }
                />
              </Form.Group>
              <Form.Group unstackable widths="equal">
                {/* Other Costs Cost */}
                <Form.Input
                  className="input-total"
                  readOnly
                  width={2}
                  type="number"
                  fluid
                  name="OtherCostsCost"
                  value={props.condition.OtherCostsCostTotal}
                />
                {/* Other Costs Markup */}
                <Form.Input
                  className="input-total"
                  width={2}
                  readOnly
                  type="number"
                  fluid
                  name="OtherCostsMarkup"
                  // icon="percent"
                  value={props.condition.OtherCostsMarkup}
                />
                {/* Other Costs Total */}
                <Form.Input
                  className="input-total"
                  readOnly
                  width={2}
                  type="number"
                  fluid
                  value={
                    props.condition.OtherCostsGrandTotal !== null &&
                    props.condition.OtherCostsGrandTotal !== undefined
                      ? props.condition.OtherCostsGrandTotal.toFixed(2)
                      : (0).toFixed(2)
                  }
                />
              </Form.Group>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width="12">
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column width={15}>
                    {/* NEXUS 5  */}
                    <Form.Group unstackable>
                      <Form.Button
                        onClick={() =>
                          subContractApprovedInd === 1
                            ? false
                            : handleSubButtonClick(null, null)
                        }
                        // companyid={sub1CompanyID}
                        color="orange"
                        width={1}
                        size={"small"}
                        label="Chosen"
                        icon={
                          !selectedSubcontractor ? "check" : "circle outline"
                        }
                      />
                      <Form.Input
                        width={6}
                        style={{ opacity: 1 }}
                        readOnly
                        className="N5SubInput input-total"
                        label="Subcontractor"
                        value="Nexus 5"
                      />
                      <Form.Input
                        width={3}
                        label="Bid Amount"
                        type="number"
                        readOnly
                        className="input-total"
                        value={props.condition.TotalCost}
                      />
                      <Form.Input
                        width={3}
                        label="MU"
                        type="number"
                        name="Markup"
                        icon="percent"
                        className="input-total"
                        readOnly
                        value={props.condition.TotalMarkup}
                      />
                      <Form.Input
                        width={3}
                        label="Total"
                        type="number"
                        className="input-total"
                        readOnly
                        value={
                          props.condition.PreTax !== null &&
                          props.condition.PreTax !== undefined
                            ? props.condition.PreTax.toFixed(2)
                            : ""
                        }
                      />
                      <Form.Input
                        width={3}
                        fluid
                        readOnly
                        label="Unit Cost"
                        type="number"
                        name="UnitPrice"
                        className="input-total"
                        value={
                          props.condition.UnitPrice !== null &&
                          props.condition.UnitPrice !== undefined
                            ? props.condition.UnitPrice.toFixed(2)
                            : ""
                        }
                      />
                    </Form.Group>

                    {/* Subcontractors */}
                    <Form.Group unstackable>
                      <Form.Button
                        onClick={() =>
                          subContractApprovedInd === 1
                            ? false
                            : handleSubButtonClick(sub1CompanyID, 1)
                        }
                        companyid={sub1CompanyID}
                        color="orange"
                        width={1}
                        size={"small"}
                        icon={
                          selectedSubcontractor === sub1CompanyID
                            ? "check"
                            : "circle outline"
                        }
                      />
                      <Form.Dropdown
                        width={6}
                        search
                        clearable
                        selection
                        selectOnBlur={false}
                        style={{ opacity: 1 }}
                        disabled={
                          selectedSubcontractor === sub1CompanyID ||
                          subContractApprovedInd === 1
                            ? true
                            : false
                        }
                        name="CompanyID"
                        precedence={1}
                        value={sub1CompanyID}
                        options={
                          subcontractorOptions
                            ? subcontractorOptions.filter(
                                (option) =>
                                  option.value !== sub2CompanyID &&
                                  option.value !== sub3CompanyID
                              )
                            : []
                        }
                        onChange={(e, { value }) => {
                          setSub1CompanyID(value);
                          handleSubDropdownChange(value, 1);
                        }}
                      />
                      <Form.Input
                        width={3}
                        type="number"
                        name="BidAmount"
                        className={
                          subContractApprovedInd === 1 ? "input-total" : ""
                        }
                        readOnly={subContractApprovedInd === 1 ? true : false}
                        disabled={sub1CompanyID ? false : true}
                        precedence={1}
                        value={sub1BidAmount ? parseFloat(sub1BidAmount) : ""}
                        onFocus={(e) => setFocusValue(e.target.value)}
                        onChange={(e) => setSub1BidAmount(e.target.value)}
                        onBlur={(e) => handleSubInputBlur(e.target.value, 1)}
                      />
                      <Form.Input
                        width={3}
                        type="number"
                        name="Markup"
                        className={
                          clientApprovedInd === 1 ||
                          subContractApprovedInd === 1
                            ? "input-total"
                            : ""
                        }
                        readOnly={clientApprovedInd === 1 ? true : false}
                        disabled={sub1CompanyID ? false : true}
                        precedence={1}
                        icon="percent"
                        value={sub1Markup}
                        onFocus={(e) => setFocusValue(e.target.value)}
                        onChange={(e) => setSub1Markup(e.target.value)}
                        onBlur={(e) => handleSubInputBlur(e.target.value, 1)}
                      />
                      <Form.Input
                        width={3}
                        type="number"
                        readOnly
                        className="input-total"
                        value={sub1Total ? sub1Total.toFixed(2) : ""}
                      />
                      <Form.Input
                        width={3}
                        fluid
                        readOnly
                        type="number"
                        name="UnitPrice"
                        className="input-total"
                        value={
                          sub1BidAmount !== null &&
                          sub1BidAmount !== undefined &&
                          totalQuantities
                            ? (
                                parseFloat(sub1BidAmount) / totalQuantities
                              ).toFixed(2)
                            : ""
                        }
                      />
                    </Form.Group>
                    <Form.Group unstackable>
                      <Form.Button
                        onClick={() =>
                          subContractApprovedInd === 1
                            ? false
                            : handleSubButtonClick(sub2CompanyID, 2)
                        }
                        companyid={sub2CompanyID}
                        color="orange"
                        width={1}
                        size={"small"}
                        icon={
                          selectedSubcontractor === sub2CompanyID
                            ? "check"
                            : "circle outline"
                        }
                      />
                      <Form.Dropdown
                        width={6}
                        search
                        selection
                        clearable
                        name="CompanyID"
                        disabled={
                          selectedSubcontractor === sub2CompanyID ||
                          subContractApprovedInd === 1
                            ? true
                            : false
                        }
                        precedence={2}
                        selectOnBlur={false}
                        value={sub2CompanyID}
                        options={
                          subcontractorOptions
                            ? subcontractorOptions.filter(
                                (option) =>
                                  option.value !== sub1CompanyID &&
                                  option.value !== sub3CompanyID
                              )
                            : []
                        }
                        onChange={(e, { value }) => {
                          setSub2CompanyID(value);
                          handleSubDropdownChange(value, 2);
                        }}
                      />
                      <Form.Input
                        name="BidAmount"
                        precedence={2}
                        readOnly={subContractApprovedInd === 1 ? true : false}
                        disabled={sub2CompanyID ? false : true}
                        value={sub2BidAmount ? parseFloat(sub2BidAmount) : ""}
                        onFocus={(e) => setFocusValue(e.target.value)}
                        onChange={(e) => setSub2BidAmount(e.target.value)}
                        onBlur={(e) => handleSubInputBlur(e.target.value, 2)}
                        width={3}
                        type="number"
                      />
                      <Form.Input
                        width={3}
                        type="number"
                        name="Markup"
                        icon="percent"
                        className={
                          clientApprovedInd === 1 ||
                          subContractApprovedInd === 1
                            ? "input-total"
                            : ""
                        }
                        readOnly={clientApprovedInd === 1 ? true : false}
                        disabled={sub2CompanyID ? false : true}
                        precedence={2}
                        value={sub2Markup}
                        onChange={(e) => setSub2Markup(e.target.value)}
                        onFocus={(e) => setFocusValue(e.target.value)}
                        onBlur={(e) => handleSubInputBlur(e.target.value, 2)}
                      />
                      <Form.Input
                        width={3}
                        type="number"
                        readOnly
                        className="input-total"
                        value={sub2Total ? sub2Total.toFixed(2) : ""}
                      />
                      <Form.Input
                        width={3}
                        fluid
                        readOnly
                        type="number"
                        name="UnitPrice"
                        className="input-total"
                        value={
                          sub2BidAmount !== null &&
                          sub2BidAmount !== undefined &&
                          totalQuantities
                            ? (
                                parseFloat(sub2BidAmount) / totalQuantities
                              ).toFixed(2)
                            : ""
                        }
                      />
                    </Form.Group>
                    <Form.Group unstackable>
                      <Form.Button
                        onClick={() =>
                          subContractApprovedInd === 1
                            ? false
                            : handleSubButtonClick(sub3CompanyID, 3)
                        }
                        companyid={sub3CompanyID}
                        color="orange"
                        width={1}
                        size={"small"}
                        icon={
                          selectedSubcontractor === sub3CompanyID
                            ? "check"
                            : "circle outline"
                        }
                      />
                      <Form.Dropdown
                        width={6}
                        search
                        clearable
                        selection
                        disabled={
                          selectedSubcontractor === sub3CompanyID ||
                          subContractApprovedInd === 1
                            ? true
                            : false
                        }
                        selectOnBlur={false}
                        options={
                          subcontractorOptions
                            ? subcontractorOptions.filter((option) => {
                                return (
                                  option.value !== sub1CompanyID &&
                                  option.value !== sub2CompanyID
                                );
                              })
                            : []
                        }
                        precedence={3}
                        name="CompanyID"
                        value={sub3CompanyID}
                        onChange={(e, { value }) => {
                          setSub3CompanyID(value);
                          handleSubDropdownChange(value, 3);
                        }}
                      />
                      <Form.Input
                        width={3}
                        type="number"
                        name="BidAmount"
                        readOnly={subContractApprovedInd === 1 ? true : false}
                        disabled={sub3CompanyID ? false : true}
                        precedence={3}
                        onFocus={(e) => setFocusValue(e.target.value)}
                        value={sub3BidAmount ? parseFloat(sub3BidAmount) : ""}
                        onChange={(e) => setSub3BidAmount(e.target.value)}
                        onBlur={(e) => handleSubInputBlur(e.target.value, 3)}
                      />
                      <Form.Input
                        width={3}
                        type="number"
                        name="Markup"
                        icon="percent"
                        precedence={3}
                        className={
                          clientApprovedInd === 1 ||
                          subContractApprovedInd === 1
                            ? "input-total"
                            : ""
                        }
                        readOnly={clientApprovedInd === 1 ? true : false}
                        disabled={sub3CompanyID ? false : true}
                        onFocus={(e) => setFocusValue(e.target.value)}
                        value={sub3Markup}
                        onChange={(e) => setSub3Markup(e.target.value)}
                        onBlur={(e) => handleSubInputBlur(e.target.value, 3)}
                      />
                      <Form.Input
                        width={3}
                        type="number"
                        readOnly
                        className="input-total"
                        value={sub3Total ? sub3Total.toFixed(0) : ""}
                      />
                      <Form.Input
                        width={3}
                        fluid
                        readOnly
                        type="number"
                        name="UnitPrice"
                        className="input-total"
                        value={
                          sub3BidAmount !== null &&
                          sub3BidAmount !== undefined &&
                          totalQuantities !== null &&
                          totalQuantities !== undefined
                            ? (
                                parseFloat(sub3BidAmount) / totalQuantities
                              ).toFixed(2)
                            : ""
                        }
                      />
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column width={1}></Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Modal
            size="mini"
            open={overwriteCostModalOpen}
            onClose={() => setOverwriteCostModalOpen(false)}
          >
            <Header icon="warning sign" content="Job buyout" />
            <Modal.Content>
              Please confirm that Nexus 5 will be completing this condition
              in-house. This will overwrite the existing markups and allow you
              to edit job costs.
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="yellow"
                onClick={() => setOverwriteCostModalOpen(false)}
              >
                <Icon name="remove" /> Cancel
              </Button>
              <Button
                color="green"
                onClick={() => handleConfirmCostOverwrite()}
              >
                <Icon name="checkmark" /> Confirm
              </Button>
            </Modal.Actions>
          </Modal>
        </Grid>
      </React.Fragment>
    );
  }
};

export default Condition;
