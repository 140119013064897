import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Divider, Segment } from "semantic-ui-react";
import NonProjectReceipts from "./NonProjectReceipts/NonProjectReceipts";
import ProjectReceipts from "./ProjectReceipts/ProjectReceipts";

const Receipts = () => {
  const history = useHistory();
  const { type } = useParams();

  return !type || (type !== "project" && type !== "nonproject") ? (
    <Segment basic textAlign="center" style={{ marginTop: "25vh" }}>
      <Button
        size="big"
        color="grey"
        content="Non-Project Receipt"
        icon="add"
        labelPosition="left"
        style={{ marginBottom: 40 }}
        onClick={() => history.push("/m/receipts/nonproject/")}
      />

      <Divider horizontal style={{ fontSize: 18 }}>
        Or
      </Divider>

      <Button
        size="big"
        color="grey"
        content="Project Receipt"
        icon="add"
        labelPosition="left"
        style={{ marginTop: 40 }}
        onClick={() => history.push("/m/receipts/project/")}
      />
    </Segment>
  ) : type === "nonproject" ? (
    <div>
      <NonProjectReceipts />
    </div>
  ) : (
    <div>
      <ProjectReceipts />
    </div>
  );
};

export default Receipts;
