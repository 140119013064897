import React from "react";

const DetailedContractRow = (props) => {
  function groupBy(objectArray, property) {
    return objectArray.reduce(function(acc, obj) {
      var key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  const conditions = props.conditions;
  const summary = props.summary;

  const filteredConditions = conditions.filter(
    (item) => item.Category === props.category
  );
  const grouped = groupBy(filteredConditions, "SubCategory");
  const subCategories = [
    ...new Set(filteredConditions.map((item) => item.SubCategory)),
  ];

  return (
    <>
      {subCategories.map((subCategory, index) => (
        <React.Fragment key={index}>
          <tr key={subCategory}>
            {/* <Table.Cell>{summary.ContractType}</Table.Cell> */}
            <td
              colSpan={summary.Recipient === "sub" ? 3 : 2}
              style={{ color: "gray", fontWeight: "bold" }}
            >
              {subCategory}
            </td>
            {/* <Table.Cell></Table.Cell>
            {summary.Recipient === "sub" ? <Table.Cell></Table.Cell> : null} */}
          </tr>
          {grouped[subCategory].map((condition, index) => {
            return (
              <tr key={index}>
                {/* <Table.Cell></Table.Cell> */}
                <td
                // style={{
                //   fontWeight: "bold",
                //   minWidth: 140,
                //   fontStyle: "italic",
                // }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        condition.ConditionName +
                        " " +
                        (condition.InternalComments
                          ? "- " + condition.InternalComments
                          : ""),
                    }}
                  ></span>
                  {/* {condition.ConditionName +
                    " " +
                    (condition.InternalComments
                      ? "- " + condition.InternalComments
                      : "")} */}
                </td>
                {summary.Recipient === "sub" ? (
                  <td>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: condition.DetailDescription,
                      }}
                    ></span>
                  </td>
                ) : null}
                <td
                  style={{
                    color: "#fe5000",
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(condition.Price)}
                </td>
              </tr>
            );
          })}
        </React.Fragment>
      ))}
    </>
  );
};

export default DetailedContractRow;
