import React from "react";
import { Container } from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
import { columnDefs } from "./columnDefs";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./TeamMemberGoals.scss";

const TeamMemberGoals = ({ rowData, handleChange, division, role }) => {
  // const [gridApi, setGridApi] = useState();
  return (
    <Container
      className="TeamMemberGoals"
      style={{ paddingLeft: 10, paddingRight: 10 }}
      fluid
    >
      <h1 style={{ color: "rgb(254, 80, 0)", marginLeft: 40 }}>
        Team Member Goals
        <span style={{ color: "#000" }}>{` - ${division} - ${role}`}</span>
      </h1>

      <div
        className="ag-theme-balham"
        style={{
          height: "calc(100vh - 170px)",
          // height: "100%",
          width: "100%",
          minHeight: 300,
          // maxWidth: 1600,
        }}
      >
        <AgGridReact
          enableCellChangeFlash={true}
          defaultColDef={{ sortable: false }}
          suppressRowDrag={true}
          suppressMovableColumns={true}
          lockPinned={true}
          columnDefs={columnDefs}
          rowData={rowData}
          rowSelection="multiple"
          onCellValueChanged={(params) => {
            // const { newValue} = params;
            // console.log(newValue);

            handleChange(params);
          }}
          // getRowStyle={(params) => {
          //   return params.data.division === "Total" ? { color: "gray" } : {};
          // }}
          onGridReady={(params) => {
            // setGridApi(params.api);
            params.api.sizeColumnsToFit();
            window.onresize = () => {
              params.api.sizeColumnsToFit();
            };
            // setColumnApi(params.columnApi);
          }}
        />
      </div>
    </Container>
  );
};

export default TeamMemberGoals;
