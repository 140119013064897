import React from "react";

const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }
  return null;
};

export default ({ data, className, ...props }) => {
  if (className) className = `contact ${className}`;
  className = "contact";

  return (
    <div className={className} {...props}>
      <div>
        <strong>{data.name || ""}</strong>
      </div>
      <div>{data.company || ""}</div>
      <div>{data.phone ? formatPhoneNumber(data.phone) : ""}</div>
      <div>{data.email || ""}</div>
    </div>
  );
};
