import Axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { Dimmer, Loader, Table } from "semantic-ui-react";
import { API_ROOT } from "../../../../api-config";
import { userIsInRole } from "../../../../util";
import DeleteMaterialPurchaseModal from "./DeleteMaterialPurchaseModal/DeleteMaterialPurchaseModal";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

function materialReducer(state, action) {
  switch (action.type) {
    case "CHANGE_SORT":
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
        };
      }

      return {
        column: action.column,
        data:
          action.column === "purchaseOrderAmount"
            ? state.data.sort((a, b) => a[action.column] - b[action.column])
            : action.column === "date"
            ? state.data.sort(
                (a, b) =>
                  new Date(a[action.column]) - new Date(b[action.column])
              )
            : state.data.sort((a, b) =>
                a[action.column].localeCompare(b[action.column])
              ),
        direction: "ascending",
      };
    case "LOAD_DATA":
      return {
        ...state,
        data: action.payload,
      };
    default:
      throw new Error();
  }
}

const MaterialPurchases = ({ projectID }) => {
  const [loading, setLoading] = useState(false);

  let userIsAccounting = useSelector((state) =>
    state.auth.user ? userIsInRole(state, 9) : false
  );

  const [state, dispatch] = useReducer(materialReducer, {
    column: null,
    data: [],
    direction: null,
  });
  const { column, data, direction } = state;

  const fetchMaterialPurchases = () => {
    setLoading(true);
    Axios.get(
      `${API_ROOT}/api/project/material-purchase-tracking?projectID=${projectID}`
    )
      .then((res) => {
        // setData(res.data);
        dispatch({ type: "LOAD_DATA", payload: res.data });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchMaterialPurchases();
  }, []);

  return loading ? (
    <Dimmer active inverted>
      <Loader inverted style={{ paddingBottom: 60 }} />
    </Dimmer>
  ) : (
    <Table sortable celled compact="very" unstackable style={{ fontSize: 12 }}>
      <Table.Header>
        <Table.Row>
          {userIsAccounting ? <Table.HeaderCell></Table.HeaderCell> : null}

          <Table.HeaderCell
            sorted={column === "date" ? direction : null}
            onClick={() => dispatch({ type: "CHANGE_SORT", column: "date" })}
          >
            Date
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "displayName" ? direction : null}
            onClick={() =>
              dispatch({ type: "CHANGE_SORT", column: "displayName" })
            }
          >
            Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "subCategoryCode" ? direction : null}
            onClick={() =>
              dispatch({ type: "CHANGE_SORT", column: "subCategoryCode" })
            }
          >
            Cost Code
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "conditionName" ? direction : null}
            onClick={() =>
              dispatch({ type: "CHANGE_SORT", column: "conditionName" })
            }
          >
            Condition
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "vendor" ? direction : null}
            onClick={() => dispatch({ type: "CHANGE_SORT", column: "vendor" })}
          >
            Vendor
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "purchaseOrderAmount" ? direction : null}
            onClick={() =>
              dispatch({ type: "CHANGE_SORT", column: "purchaseOrderAmount" })
            }
          >
            Amount
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "purchaseOrderNumber" ? direction : null}
            onClick={() =>
              dispatch({ type: "CHANGE_SORT", column: "purchaseOrderNumber" })
            }
          >
            PO Number
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "status" ? direction : null}
            onClick={() => dispatch({ type: "CHANGE_SORT", column: "status" })}
          >
            Status
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Array.isArray(data) && data.length ? (
          data.map((obj) => (
            <Table.Row key={obj.purchaseOrderID}>
              {userIsAccounting ? (
                <DeleteMaterialPurchaseModal
                  // setData={setData}
                  dispatch={dispatch}
                  purchaseOrderID={obj.purchaseOrderID}
                  projectID={projectID}
                  setLoading={setLoading}
                  status={obj.status}
                />
              ) : null}
              <Table.Cell>{obj.date}</Table.Cell>
              <Table.Cell>{obj.displayName}</Table.Cell>
              <Table.Cell>{obj.subCategoryCode}</Table.Cell>
              <Table.Cell>{obj.conditionName}</Table.Cell>
              <Table.Cell>{obj.vendor}</Table.Cell>
              <Table.Cell>
                {formatter.format(obj.purchaseOrderAmount)}
              </Table.Cell>
              <Table.Cell>{obj.purchaseOrderNumber}</Table.Cell>
              <Table.Cell
                style={{
                  color: obj.status === "Pending" ? "blue" : "green",
                }}
              >
                {obj.status}
              </Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row>
            <Table.Cell colSpan={userIsAccounting ? 9 : 8}>
              No data to display
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default MaterialPurchases;
