import React from "react";
import "./SurveyHeader.scss";
import sixtySecondSurvey from "./60secsurveyi.png";
import { Image, Grid, Message, Table } from "semantic-ui-react";

const SurveyHeader = ({ info, success }) => {
  if (info && Object.entries(info).length > 1) {
    return (
      <Grid style={{ padding: 0, margin: 0 }} className="SurveyHeader">
        <Grid.Row>
          <Grid.Column style={{ textAlign: "center" }} width="16">
            <Image
              centered
              src={sixtySecondSurvey}
              style={{ maxWidth: 300, width: "50%" }}
            />
            <h2>
              Nexus 5 just completed the following{" "}
              {info.projecttype.toLowerCase()}. How did we do?
            </h2>
            <br />

            <Table
              compact
              celled
              style={{ backgroundColor: "#f9f9f9", fontSize: 16 }}
            >
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <b>Project </b>
                  </Table.Cell>
                  <Table.Cell width={12}>
                    {` ${info.projectnumber}-${info.projectname}`}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <b>Company </b>
                  </Table.Cell>
                  <Table.Cell>{info.company}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <b>Nexus 5 Representative </b>
                  </Table.Cell>
                  <Table.Cell>{info.n5representative}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            {success ? (
              <Message color="green" style={{ textAlign: "center" }}>
                <Message.Header>
                  <h1>Thank you so much.</h1>
                </Message.Header>
                <Message.Content style={{ color: "#1aa62a", fontSize: 18 }}>
                  <p>
                    We have received your survey answers. <br />
                    <br />
                    Please know that your feedback matters a lot to us. We
                    personally read through every survey response to look for
                    ways to improve.
                  </p>
                </Message.Content>
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  } else if (info && info.hasOwnProperty("hide") && info.hide === 1) {
    return (
      <Grid className="SurveyHeader">
        <Grid.Row>
          <Grid.Column width="16">
            <Message color="red">
              <h1>
                It looks like this survey has already been completed. Please
                contact your Nexus 5 Representative if you have questions.
              </h1>
            </Message>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  } else {
    return null;
  }
};

export default SurveyHeader;
