import React, { useState, useEffect } from "react";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Container,
  Grid,
  Comment,
  Form,
  Button,
  Header,
  Modal,
  Icon,
} from "semantic-ui-react";
import { API_ROOT } from "../../../api-config";
import Note from "../../Browser/ProjectsTable/IconGrid/NotesModal/Note/Note";
import Notifications from "../../Browser/ProjectsTable/IconGrid/NotesModal/Notifications/Notifications";

import "./Notes.scss";
import moment from "moment";
import useIsMounted from "../../../hooks/isMounted";

Axios.defaults.withCredentials = true;

const Notes = (props) => {
  const [projectID, setProjectID] = useState(parseInt(props.match.params.id));
  const [project, setProject] = useState();
  const user = useSelector((state) => {
    return JSON.parse(state.auth.user)[0];
  });

  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted.current) {
      setProjectID(parseInt(props.match.params.id));
    }
  }, [props.match.params.id]);

  useEffect(() => {
    if (projectID) {
      Axios.get(`${API_ROOT}/api/project/project?projectID=${projectID}`)
        .then((res) => {
          if (res.data && isMounted.current) {
            setProject(res.data.projects[0]);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [projectID]);

  const [isAdmin, setIsAdmin] = useState(false);
  const [isUserAssociated, setIsUserAssociated] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [includeClientInd, setIncludeClientInd] = useState(false);
  const [projectNotes, setProjectNotes] = useState([]);
  const [internalInd, setInternalInd] = useState(true);
  const [customerInd, setCustomerInd] = useState(false);
  const [emailList, setEmailList] = useState("");
  const [clientContactEmail, setClientContactEmail] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [missingEmailModalOpen, setMissingEmailModalOpen] = useState(false);

  useEffect(() => {
    getProjectNotes();
    setIsAdmin(
      user.Roles.some(
        (role) => role.Role === "Admin" || role.Role === "Superuser"
      )
    );
  }, []);

  const getProjectNotes = () => {
    Axios.get(`${API_ROOT}/api/project/projectnotes`, {
      params: {
        projectID,
      },
    })
      .then((res) => {
        if (isMounted.current) {
          setProjectNotes(
            res.data
              ? res.data.projectNotes
                ? JSON.parse(res.data.projectNotes)
                : []
              : []
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
    if (user && project) {
      if (
        user.userID === project.EstimatedBy ||
        user.userID === project.SoldBy ||
        user.userID === project.ManagedBy
      ) {
        setIsUserAssociated(true);
      } else {
        setIsUserAssociated(false);
      }
    }
  };

  const deleteNote = (noteID) => {
    Axios.delete(`${API_ROOT}/api/project/projectnote/${noteID}`).then(
      (res) => {
        if (res.data.deletedNote) {
          setProjectNotes(
            projectNotes.filter((note) => note.NoteID !== noteID)
          );
        }
      }
    );
  };

  const handleIncludeClientClick = () => {
    if (!includeClientInd) {
      if (clientContactEmail) {
        setIncludeClientInd(!includeClientInd);
      } else {
        setMissingEmailModalOpen(true);
      }
    } else {
      setIncludeClientInd(!includeClientInd);
    }
  };

  const sendNoteEmail = () => {
    let email = emailList;
    email +=
      clientContactEmail && includeClientInd ? ";" + clientContactEmail : "";

    const enteredBy = `<br /><br />Entered by ${
      user.DisplayName
    }, ${moment().format("l")}`;
    const html = `${noteText} ${enteredBy}`;

    Axios.post(`${API_ROOT}/send-email`, {
      email: email,
      cc: "",
      bcc: "SoftwareDevelopment@cohenesrey.com",
      subject: emailSubject,
      text: "",
      html,
    })
      .then((res) => {
        setNoteText("");
        setIncludeClientInd(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddNoteButtonClick = () => {
    if (!noteText) {
      return;
    }

    Axios.post(`${API_ROOT}/api/project/projectnote`, {
      params: {
        projectID,
        note: noteText,
        ADID: user.ADID,
        includeClientInd: includeClientInd ? 1 : 0,
      },
    })
      .then((res) => {
        if (res.data.projectNotes) {
          sendNoteEmail();
          setProjectNotes(JSON.parse(res.data.projectNotes));
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // clear note in state
    setNoteText("");
  };

  const handleInternalClick = () => {
    setCustomerInd(false);
    setInternalInd(true);
  };

  const handleCustomerClick = () => {
    setInternalInd(false);
    setCustomerInd(true);
  };

  const getProjectNotifications = (projectID) => {
    // const clientContactEmail = project.ClientContactEmail;

    Axios.get(
      `${API_ROOT}/api/project/projectnotifications/${parseInt(
        props.match.params.id
      )}`
    ).then((res) => {
      const notifications = res.data.notifications
        ? JSON.parse(res.data.notifications)
        : [];
      // this.setState({ notifications: JSON.parse(res.data.notifications) });
      const emailaddresses = notifications
        .map((e) => {
          return e.EmailAddress;
        })
        .filter(Boolean)
        .join(";");

      setEmailList(emailaddresses);
    });
  };

  useEffect(() => {
    getProjectNotifications();
  }, []);

  useEffect(() => {
    Axios.get(`${API_ROOT}/api/project/client-contact-email`, {
      params: {
        projectID,
      },
    }).then((res) => {
      setClientContactEmail(res.data.email.email);
      setEmailSubject(res.data.email.subject);
    });
  }, [projectID]);

  const filteredProjectNotes =
    internalInd === true
      ? projectNotes.length
        ? projectNotes.filter((project) => project.IncludeClientInd === 0)
        : []
      : projectNotes.length
      ? projectNotes.filter((project) => project.IncludeClientInd === 1)
      : [];

  return (
    <Container fluid style={{ overflow: "auto" }}>
      <Grid style={{ width: "100%", margin: 0 }}>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header style={{ color: "#fe5000" }} as="h1">
              Notes
            </Header>
            <Button.Group inverted>
              <Button
                onClick={handleInternalClick}
                inverted
                active={internalInd}
                color="orange"
              >
                Internal
              </Button>
              <Button.Or />
              <Button
                style={{ color: "grey" }}
                onClick={handleCustomerClick}
                inverted
                active={customerInd}
                color="grey"
              >
                Client
              </Button>
            </Button.Group>
            <Comment.Group>
              <div
                style={{ maxHeight: 300, overflow: "auto", maxWidth: "none" }}
              >
                {!projectNotes.length
                  ? null
                  : filteredProjectNotes.map((note) => (
                      <Note
                        deleteNote={deleteNote}
                        key={note.NoteID}
                        noteID={note.NoteID}
                        projectID={note.ProjectID}
                        author={note.DisplayName}
                        text={note.NoteText}
                        date={note.CreatedDate}
                        includeClientInd={false}
                      />
                    ))}
              </div>
              <Form reply>
                <Form.TextArea
                  value={noteText}
                  onChange={(e) => setNoteText(e.target.value)}
                />
                <Form.Field>
                  <Notifications
                    getProjectNotifications={getProjectNotifications}
                    projectID={projectID}
                  />
                </Form.Field>

                {isAdmin || isUserAssociated ? (
                  <Form.Checkbox
                    checked={includeClientInd}
                    onChange={() => handleIncludeClientClick()}
                    label="Include client"
                  />
                ) : (
                  ""
                )}

                <Button
                  onClick={handleAddNoteButtonClick}
                  content="Add Note"
                  // loading={this.state.loading ? true : false}
                  labelPosition="left"
                  icon="edit"
                  primary
                />
              </Form>
            </Comment.Group>
            <Modal
              onClose={() => setMissingEmailModalOpen(false)}
              basic
              size="mini"
              open={missingEmailModalOpen}
            >
              <Modal.Header>
                <Icon color="yellow" name="warning sign" /> Missing client
                contact email
              </Modal.Header>
              <Modal.Content>
                There is no client contact set for this project.
              </Modal.Content>
              <Modal.Actions>
                <Button
                  color="green"
                  inverted
                  onClick={() => setMissingEmailModalOpen(false)}
                >
                  <Icon name="remove" /> OK
                </Button>
              </Modal.Actions>
            </Modal>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default withRouter(Notes);
