import React from 'react';
import { Button, Form, Grid, Image, Segment, Icon } from 'semantic-ui-react';
import logo from './N5Group.png';
import { API_ROOT } from '../../api-config';
import './Login.scss';

const Login = () => {
  return (
    <Grid
      textAlign="center"
      style={{ height: '100vh' }}
      verticalAlign="middle"
      className="Login"
    >
      <Grid.Column style={{ maxWidth: 450 }}>
        {/* <Header as="h2" color="teal" textAlign="center">
          <Image src={logo} /> Log-in to your account
        </Header> */}
        <Form size="large">
          <Segment stacked>
            <Image src={logo} />
            <Button
              icon
              labelPosition="left"
              color="grey"
              fluid
              size="large"
              as="a"
              href={`${API_ROOT}/auth/login`}
            >
              <Icon name="sign-in" />
              Sign in with Microsoft
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default Login;
