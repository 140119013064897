import React, { useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";

import { ContractValidationWarningModal } from "../ContractValidationWarningModal";

const PushContractModal = ({ setUnsignedContract, contract }) => {
  const [open, setOpen] = useState(false);
  const [pushContractModalOpen, setPushContractModalOpen] = useState(false);

  return (
    <>
      <ContractValidationWarningModal
        show={open}
        guid={contract.GUID}
        callback={() => setPushContractModalOpen(true)}
        onClose={() => setOpen(false)}
      />
      <Modal
        size="tiny"
        basic
        onClose={() => setPushContractModalOpen(false)}
        onOpen={() => setOpen(true)}
        open={pushContractModalOpen}
        trigger={
          <Icon
            color="green"
            title="Push contract"
            link
            name="angle double right"
          />
        }
      >
        <Modal.Header>
          <Icon name="warning sign" /> Push contract without signature
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              Are you sure you want to push this contract to Acumatica without
              the client's approval?
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="yellow"
            onClick={() => setPushContractModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            content="Yep, go for it"
            labelPosition="right"
            icon="checkmark"
            onClick={() => {
              setPushContractModalOpen(false);
              setUnsignedContract(contract.GUID, contract.CompanyID);
            }}
            negative
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default PushContractModal;
