import React, { useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { Container, Segment, Dimmer, Loader, Image } from "semantic-ui-react";
import { surveyReducer } from "./surveyReducer";
import { API_ROOT } from "../../api-config";
import axios from "axios";
import SurveyHeader from "./SurveyHeader/SurveyHeader";
import "./Survey.scss";
import SurveyQuestions from "./SurveyQuestions/SurveyQuestions";
import logo from "./surveyfooter.jpg";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Survey = () => {
  const [state, dispatch] = useReducer(surveyReducer, {
    isLoading: false,
    isError: false,
    questions: [],
    info: {},
    success: false,
  });

  let query = useQuery();

  const projectID = query.get("p");
  const kpiEventID = query.get("eid");
  const type = query.get("mt");

  const fetchData = async () => {
    dispatch({ type: "FETCH_INIT" });

    try {
      const result = await axios.post(`${API_ROOT}/survey/questions`, {
        projectID,
        type,
        kpiEventID,
        clientDisplayInd: 1,
      });

      dispatch({
        type: "FETCH_SUCCESS",
        payload: result.data ? result.data.surveyData : [],
      });
    } catch (error) {
      dispatch({ type: "FETCH_FAILURE" });
    }
  };

  useEffect(() => {
    document.title = "Survey";

    if (!type || (type !== "KN" && type !== "PP" && type !== "SM")) {
      dispatch({ type: "INVALID_URL" });
    } else {
      fetchData();
    }
  }, []);

  return state.isLoading ? (
    <Segment basic style={{ height: "100vh" }}>
      <Dimmer inverted active>
        <Loader inverted size="large">
          Loading...
        </Loader>
      </Dimmer>
    </Segment>
  ) : (
    <div
      style={{
        // margin: 0,
        // padding: 0,
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "gray",
      }}
    >
      <Container style={{ minWidth: 479 }} className="Survey">
        <SurveyHeader info={state.info} success={state.success} />
        {state.success || state.info.hasOwnProperty("hide") ? null : (
          <SurveyQuestions
            questions={state.questions}
            info={state.info}
            dispatch={dispatch}
            kpiEventID={kpiEventID}
            type={type}
          />
        )}

        <div style={{ width: "100%", textAlign: "center", paddingBottom: 60 }}>
          <Image src={logo} centered style={{ width: "50%", maxWidth: 350 }} />

          <p>
            Copyright © 2020 Nexus 5 Group, All rights reserved.
            <br />
            8500 Shawnee Mission Pkwy, Suite 130 | Merriam, KS 66202 |{" "}
            <a href="https://www.nexus5group.com">www.Nexus5Group.com</a>
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Survey;
