import React from "react";
import { Button } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../../../api-config";

const SurveyOptions = ({ question, dispatch, kpiEventID }) => {
  const optionsArray = question.options.split(",");

  const handleClick = (value) => {
    dispatch({ type: "SET_VALUE", payload: { value, id: question.id } });
    Axios.post(`${API_ROOT}/survey/results`, {
      kpiEventID,
      criteriaID: question.id,
      value,
      projectID: question.projectid,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  return question.options === "0,1,2,3,4" ? (
    <div className="SurveyOptions" style={{ marginTop: 20 }}>
      <Button.Group>
        <Button
          style={{ width: 100, height: 50 }}
          color={question.value === "0" ? "green" : "blue"}
          onClick={() => (question.value === "0" ? null : handleClick("0"))}
        >
          Strongly Disagree
        </Button>
        <Button
          style={{ width: 100, height: 50 }}
          color={question.value === "1" ? "green" : "blue"}
          onClick={() => (question.value === "1" ? null : handleClick("1"))}
        >
          Disagree
        </Button>
        <Button
          style={{ width: 100, height: 50 }}
          color={question.value === "2" ? "green" : "blue"}
          onClick={() => (question.value === "2" ? null : handleClick("2"))}
        >
          Neutral
        </Button>
        <Button
          style={{ width: 100, height: 50 }}
          color={question.value === "3" ? "green" : "blue"}
          onClick={() => (question.value === "3" ? null : handleClick("3"))}
        >
          Agree
        </Button>
        <Button
          style={{ width: 100, height: 50 }}
          color={question.value === "4" ? "green" : "blue"}
          onClick={() => (question.value === "4" ? null : handleClick("4"))}
        >
          Strongly Agree
        </Button>
      </Button.Group>
    </div>
  ) : question.options === "0,1,2,3,4,5,6,7,8,9,10" ? (
    <div
    // style={{ marginTop: 20 }}
    >
      <table unstackable="true">
        <tbody>
          <tr>
            <td style={{ paddingBottom: 0, paddingLeft: 0 }}>
              Not at all likely
            </td>
            <td style={{ textAlign: "right", paddingBottom: 0 }}>
              Extremely likely
            </td>
          </tr>
          <tr>
            <td style={{ border: "none", paddingLeft: 0 }} colSpan="2">
              {optionsArray.map((option) => (
                <Button
                  size="tiny"
                  key={option}
                  color={question.value === option ? "green" : "blue"}
                  style={{ width: option === "10" ? 33 : 31 }}
                  icon
                  circular
                  onClick={() =>
                    question.value === option ? null : handleClick(option)
                  }
                >
                  {option}
                </Button>
              ))}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : null;
};

export default SurveyOptions;
