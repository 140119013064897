import React, { useEffect, useState } from "react";
import {
  Modal,
  Loader,
  Segment,
  Dimmer,
  Button,
  Icon,
} from "semantic-ui-react";
import ResponseHeader from "./ResponseHeader";
import ResponseQuestions from "./ResponseQuestions";

const ResponseModal = ({ modalOpen, setModalOpen, gridApi }) => {
  const [KPIEventID, setKPIEventID] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (modalOpen && gridApi) {
      const selectedData = gridApi.getSelectedRows();
      setKPIEventID(selectedData[0].surveyguid);
      setSelectedData(selectedData[0]);
    }
  }, [modalOpen]);

  return (
    <Modal
      open={modalOpen}
      onOpen={() => {
        setLoading(true);
        setModalOpen(true);
      }}
      onClose={() => setModalOpen(false)}
    >
      <Modal.Header>STAR Satisfaction Survey</Modal.Header>
      <Modal.Content>
        {loading ? (
          <Segment basic style={{ height: 200 }}>
            <Dimmer inverted active>
              <Loader size="large" inverted />
            </Dimmer>
          </Segment>
        ) : (
          <>
            <ResponseHeader selectedData={selectedData} />
            <ResponseQuestions
              KPIEventID={KPIEventID}
              setLoading={setLoading}
              comments={
                selectedData && selectedData.comments
                  ? selectedData.comments
                  : ""
              }
            />
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          labelPosition="left"
          onClick={() => setModalOpen(false)}
          icon
        >
          <Icon name="close" />
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ResponseModal;
