import React, { useState, useContext } from "react";
import {
  Table,
  Label,
  Popup,
  Icon,
  Input,
  Modal,
  Button,
  Header,
  Dropdown,
} from "semantic-ui-react";
import Axios from "axios";
import moment from "moment";

import { API_ROOT } from "../../../../../api-config";
import CurrentProject from "../../../../../screens/projects/CurrentProject";
import { useShowV1 } from "../../../../../hooks/useQuery";

const InactiveClientContracts = (props) => {
  const { useV2 } = useContext(CurrentProject);
  const splitScreen = useShowV1();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const [activateModalOpen, setActivateModalOpen] = useState(false);
  const [contractGUIDToActivate, setContractGUIDToActivate] = useState(null);
  // const [pdfLoading, setPdfLoading] = useState(false);

  const {
    inactiveContracts,
    loadContracts,
    handleInclusionChange,
    getContractByGUIDTotal,
    getContractsByTypeTotal,
  } = props;

  const openActivateContractModal = (guid) => {
    console.log(guid);
    setContractGUIDToActivate(guid);
    setActivateModalOpen(true);
  };

  const activateContract = () => {
    const guid = contractGUIDToActivate;
    Axios.post(`${API_ROOT}/api/contract/clientcontractpending`, {
      params: {
        guid,
      },
    })
      .then((res) => {
        loadContracts(res);
        setActivateModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDescriptionBlur = (guid, contractDescription) => {
    Axios.post(`${API_ROOT}/api/contract/clientcontractdescription`, {
      params: {
        guid,
        contractDescription,
      },
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="2" style={{ color: "rgb(254, 80, 0)" }}>
            Inactive Contracts
          </Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Array.isArray(inactiveContracts) && inactiveContracts.length ? (
          inactiveContracts[0].Client.map((client, index) => {
            return (
              <React.Fragment key={index}>
                {client.Contracts.map((contract) => {
                  return contract.displayType === "data" ? (
                    <Table.Row key={contract.GUID}>
                      <Table.Cell>
                        <Label>{contract.ContractType}</Label>
                        <Popup
                          content="Reactivate Contract"
                          trigger={
                            <Icon
                              color="red"
                              link
                              name="linkify"
                              onClick={() =>
                                openActivateContractModal(contract.GUID)
                              }
                            />
                          }
                        />
                      </Table.Cell>
                      <Table.Cell style={{ width: 340 }}>
                        <Input
                          placeholder="Add description"
                          fluid
                          readOnly={contract.ContractSentInd ? true : false}
                          defaultValue={contract.ContractDescription || ""}
                          onBlur={(e) =>
                            handleDescriptionBlur(contract.GUID, e.target.value)
                          }
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="center"></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign="center">
                        <Popup
                          content={"View client proposal link"}
                          position="top center"
                          trigger={
                            <a
                              href={`/contract/?r=c&id=${contract.GUID}&c=${contract.CompanyID}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Icon
                                // size="large"
                                link
                                name="external alternate"
                              />
                            </a>
                          }
                        />
                      </Table.Cell>

                      <Table.Cell textAlign="center">
                        <Input
                          style={{ minWidth: 70, width: 70 }}
                          defaultValue={contract.ClientWorkDays}
                          readOnly={contract.ContractSentInd ? true : false}
                          onBlur={(e) =>
                            props.handleDaysBlur(contract.GUID, e.target.value)
                          }
                          type="number"
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.Retention
                          ? contract.Retention.toFixed(2) + "%"
                          : "0.00%"}
                      </Table.Cell>
                      {splitScreen && (
                        <Table.Cell textAlign="center">
                          <span title="OLD">
                            {formatter.format(contract.GrandTotal || 0)}
                          </span>
                          &nbsp;&nbsp;|&nbsp;&nbsp;
                          <span title="NEW">
                            {formatter.format(
                              getContractByGUIDTotal(contract.GUID)
                            )}
                          </span>
                        </Table.Cell>
                      )}
                      {!splitScreen && (
                        <Table.Cell textAlign="center">
                          {useV2 &&
                            formatter.format(
                              getContractByGUIDTotal(contract.GUID)
                            )}
                          {!useV2 && formatter.format(contract.GrandTotal || 0)}
                        </Table.Cell>
                      )}
                      <Table.Cell textAlign="center">
                        {contract.ContractSentDate
                          ? moment(contract.ContractSentDate).format("l")
                          : ""}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.ClientApprovalDate
                          ? moment(contract.ClientApprovalDate).format("l")
                          : ""}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.Inclusions[0].text ? (
                          <Dropdown
                            selection
                            selectOnNavigation={false}
                            selectOnBlur={false}
                            options={contract.Inclusions}
                            onChange={(event, data) =>
                              handleInclusionChange(event, data)
                            }
                          />
                        ) : null}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    <Table.Row
                      key={contract.GUID}
                      style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}
                    >
                      <Table.Cell colSpan="7"></Table.Cell>
                      {splitScreen && (
                        <Table.Cell
                          style={{ color: "blue", fontWeight: "bold" }}
                          textAlign="center"
                        >
                          <span title="OLD">
                            {formatter.format(contract.GrandTotal || 0)}
                          </span>
                          &nbsp;&nbsp;|&nbsp;&nbsp;
                          <span title="NEW">
                            {formatter.format(getContractsByTypeTotal())}
                          </span>
                        </Table.Cell>
                      )}
                      {!splitScreen && (
                        <Table.Cell
                          style={{ color: "blue", fontWeight: "bold" }}
                          textAlign="center"
                        >
                          {useV2 && formatter.format(getContractsByTypeTotal())}
                          {!useV2 && formatter.format(contract.GrandTotal || 0)}
                        </Table.Cell>
                      )}
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })
        ) : (
          <Table.Row style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}>
            <Table.Cell colSpan="11">No Inactive contracts</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      {/* Confirm activate contract modal */}
      <Modal
        basic
        size="tiny"
        open={activateModalOpen}
        onClose={() => setActivateModalOpen(false)}
      >
        <Header icon="linkify" content="Activate Contract" />
        <Modal.Content>
          <p>
            Are you sure you want to reactivate this contract? The contract's
            status will change to pending.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="red"
            inverted
            onClick={() => setActivateModalOpen(false)}
          >
            <Icon name="remove" /> No
          </Button>
          <Button color="green" inverted onClick={() => activateContract()}>
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};

export default InactiveClientContracts;
