import React from "react";

export default ({ summary }) => {
  return (
    <div className="clarifications-exclusions">
      <div className="ui message">
        <div className="header">Clarifications/Exclusions</div>
        <p>{summary.exclusions}</p>
      </div>
    </div>
  );
};
