import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
// import "devextreme/dist/css/dx.common.css";
// import "devextreme/dist/css/dx.dark.css";

import {
  Message,
  Container,
  Grid,
  Card,
  Loader,
  Segment,
  Accordion,
  Icon,
} from "semantic-ui-react";

import Axios from "axios";
import { API_ROOT } from "../../../api-config";

import Chart, {
  Label,
  Format,
  Series,
  CommonSeriesSettings,
  Legend,
} from "devextreme-react/chart";
import DataGrid from "devextreme-react/data-grid";
import RevenueGoals from "../Construction/RevenueGoals/RevenueGoals";
import GMPLeaderboard from "../Construction/GMPLeaderboard/GMPLeaderboard";
import RevenueLeaderboard from "../Construction/RevenueLeaderboard/RevenueLeaderboard";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Service = () => {
  let query = useQuery();
  let date = moment();
  const [year, setYear] = useState(parseInt(query.get("y")) || date.year());
  const [loading, setLoading] = useState(true);
  const [divisionSummary, setDivisionSummary] = useState(null);
  const [monthlyDivisionSummary, setMonthlyDivisionSummary] = useState(null);
  const [revenuePIVOT, setRevenuePIVOT] = useState(null);
  const [grossPIVOT, setGrossPIVOT] = useState(null);
  const [netPIVOT, setNetPIVOT] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [revenueGoalsPM, setRevenueGoalsPM] = useState(null);
  const [revenueGoalsPE, setRevenueGoalsPE] = useState(null);
  const [revenueGoalsSI, setRevenueGoalsSI] = useState(null);
  const [GMPLeaderboardPM, setGMPLeaderboardPM] = useState(null);
  const [RevenueLeaderboardPM, setRevenueLeaderboardPM] = useState(null);

  if (year < 2020 || year > date.year() + 1) {
    return <Message warning>That year is out of range</Message>;
  }

  useEffect(() => {
    setLoading(false);
  }, [divisionSummary, monthlyDivisionSummary, revenuePIVOT]);

  useEffect(() => {
    getDivisionSummary();
    getMonthlyDivisionSummary();
    getMonthlyDivisionSummaryPIVOT("RV");
    getMonthlyDivisionSummaryPIVOT("GM");
    getMonthlyDivisionSummaryPIVOT("NP");
    getRevenueGoals("PM");
    getRevenueGoals("PE");
    getRevenueGoals("SI");
    getGMPLeaderboard("PM");
    getRevenueLeaderboard("PM");
  }, [year]);

  useEffect(() => {
    setYear(query.get("y") || date.year());
  }, [query.get("y"), query.get("d")]);

  const getDivisionSummary = async () => {
    const response = await Axios.get(
      `${API_ROOT}/api/dashboard/division-summary-by-division?y=${year}&d=service`
    );

    setDivisionSummary(response.data.summary);
  };

  const getMonthlyDivisionSummary = async () => {
    const response = await Axios.get(
      `${API_ROOT}/api/dashboard/division-summary-by-month?y=${year}&d=service`
    );

    setMonthlyDivisionSummary(response.data.summary);
  };

  const getMonthlyDivisionSummaryPIVOT = async (type) => {
    if (type === "RV") {
      const response = await Axios.get(
        `${API_ROOT}/api/dashboard/division-summary-by-month-pivot?y=${year}&d=service&t=RV`
      );

      setRevenuePIVOT(response.data.summary);
    } else if (type === "GM") {
      let response = await Axios.get(
        `${API_ROOT}/api/dashboard/division-summary-by-month-pivot?y=${year}&d=service&t=GM`
      );

      setGrossPIVOT(response.data.summary);
    } else if (type === "NP") {
      let response = await Axios.get(
        `${API_ROOT}/api/dashboard/division-summary-by-month-pivot?y=${year}&d=service&t=NP`
      );

      setNetPIVOT(response.data.summary);
    }
  };

  const getRevenueGoals = async (type) => {
    if (type === "PM") {
      const response = await Axios.get(
        `${API_ROOT}/api/dashboard/pipeline-leaderboard-variance-by-division?y=${year}&d=service&r=PM`
      );

      setRevenueGoalsPM(response.data.data);
    } else if (type === "PE") {
      let response = await Axios.get(
        `${API_ROOT}/api/dashboard/pipeline-leaderboard-variance-by-division?y=${year}&d=service&r=PE`
      );

      setRevenueGoalsPE(response.data.data);
    } else if (type === "SI") {
      let response = await Axios.get(
        `${API_ROOT}/api/dashboard/pipeline-leaderboard-variance-by-division?y=${year}&d=service&r=SI`
      );

      setRevenueGoalsSI(response.data.data);
    }
  };

  const getGMPLeaderboard = async (type) => {
    if (type === "PM") {
      const response = await Axios.get(
        `${API_ROOT}/api/dashboard/gmp-leaderboard-by-division?y=${year}&d=service&r=PM`
      );

      setGMPLeaderboardPM(response.data.data);
    }
  };

  const getRevenueLeaderboard = async (type) => {
    if (type === "PM") {
      const response = await Axios.get(
        `${API_ROOT}/api/dashboard/revenue-leaderboard-by-division?y=${year}&d=service&r=PM`
      );

      setRevenueLeaderboardPM(response.data.data);
    }
  };

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  return (
    <Container
      style={{
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 20,
        // backgroundColor: "#2a2a2a",
      }}
      fluid
    >
      <Grid stackable centered>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  Service YTD Revenue
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <Chart
                    animation={{ enabled: false }}
                    legend={{ visible: false }}
                    // theme="generic.dark"
                    dataSource={[
                      {
                        arg: "Goal",
                        goal: divisionSummary ? divisionSummary.RevGoal : null,
                      },
                      {
                        arg: "Pipeline",
                        pipeline: divisionSummary
                          ? divisionSummary.RevForecast
                          : null,
                      },
                      {
                        arg: "Actual",
                        actual: divisionSummary
                          ? divisionSummary.RevActual
                          : null,
                      },
                    ]}
                  >
                    <CommonSeriesSettings
                      barPadding={0}
                      argumentField="state"
                      type="bar"
                      hoverMode="allArgumentPoints"
                      selectionMode="allArgumentPoints"
                    >
                      <Label visible={true}>
                        <Format type="currency" />
                      </Label>
                    </CommonSeriesSettings>
                    <Series
                      width="33%"
                      name="Goal"
                      color="gray"
                      argumentField="arg"
                      valueField="goal"
                    />
                    <Series
                      label={{
                        font: {
                          color: "#000",
                          weight: 600,
                        },
                      }}
                      name="Pipeline"
                      color="#fff"
                      argumentField="arg"
                      valueField="pipeline"
                    />
                    <Series
                      name="Actual"
                      color="rgb(254, 80, 0)"
                      argumentField="arg"
                      valueField="actual"
                    />
                  </Chart>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  Service YTD Gross Profit
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <Chart
                    animation={{ enabled: false }}
                    legend={{ visible: false }}
                    // theme="generic.dark"
                    dataSource={[
                      {
                        arg: "Goal",
                        goal: divisionSummary ? divisionSummary.GMGoal : null,
                      },
                      {
                        arg: "Pipeline",
                        pipeline: divisionSummary
                          ? divisionSummary.GMForecast
                          : null,
                      },
                      {
                        arg: "Actual",
                        actual: divisionSummary
                          ? divisionSummary.GMActual
                          : null,
                      },
                    ]}
                  >
                    <CommonSeriesSettings
                      barPadding={0}
                      argumentField="state"
                      type="bar"
                      hoverMode="allArgumentPoints"
                      selectionMode="allArgumentPoints"
                    >
                      <Label visible={true}>
                        <Format type="currency" />
                      </Label>
                    </CommonSeriesSettings>
                    <Series
                      width="33%"
                      name="Goal"
                      color="gray"
                      argumentField="arg"
                      valueField="goal"
                    />
                    <Series
                      label={{
                        font: {
                          color: "#000",
                          weight: 600,
                        },
                      }}
                      name="Pipeline"
                      color="#fff"
                      argumentField="arg"
                      valueField="pipeline"
                    />
                    <Series
                      name="Actual"
                      color="rgb(254, 80, 0)"
                      argumentField="arg"
                      valueField="actual"
                    />
                  </Chart>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  Service YTD Net Profit
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <Chart
                    animation={{ enabled: false }}
                    legend={{ visible: false }}
                    // theme="generic.dark"
                    dataSource={[
                      {
                        arg: "Goal",
                        goal: divisionSummary
                          ? divisionSummary.NetProfitGoal
                          : null,
                      },
                      {
                        arg: "Pipeline",
                        pipeline: divisionSummary
                          ? divisionSummary.NetProfitforecast
                          : null,
                      },
                      {
                        arg: "Actual",
                        actual: divisionSummary
                          ? divisionSummary.NetProfitActual
                          : null,
                      },
                    ]}
                  >
                    <CommonSeriesSettings
                      barPadding={0}
                      argumentField="state"
                      type="bar"
                      hoverMode="allArgumentPoints"
                      selectionMode="allArgumentPoints"
                    >
                      <Label visible={true}>
                        <Format type="currency" />
                      </Label>
                    </CommonSeriesSettings>
                    <Series
                      width="33%"
                      name="Goal"
                      color="gray"
                      argumentField="arg"
                      valueField="goal"
                    />
                    <Series
                      label={{
                        font: {
                          color: "#000",
                          weight: 600,
                        },
                      }}
                      name="Pipeline"
                      color="#fff"
                      argumentField="arg"
                      valueField="pipeline"
                    />
                    <Series
                      name="Actual"
                      color="rgb(254, 80, 0)"
                      argumentField="arg"
                      valueField="actual"
                    />
                  </Chart>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  Service Monthly Revenue
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <div className="dx-viewport">
                    <Chart
                      animation={{ enabled: false }}
                      // theme="generic.dark"
                      dataSource={
                        monthlyDivisionSummary ? monthlyDivisionSummary : []
                      }
                    >
                      <CommonSeriesSettings
                        argumentField="ShortMonth"
                        type="line"
                      >
                        <Label visible={true} backgroundColor="transparent">
                          <Format type="currency" />
                        </Label>
                      </CommonSeriesSettings>
                      <Legend
                        verticalAlignment="bottom"
                        horizontalAlignment={"center"}
                      />
                      <Series name="Goal" color="gray" valueField="RevGoal" />
                      <Series
                        name="Pipeline"
                        color="#fff"
                        valueField="RevForecast"
                      />
                      <Series
                        name="Actual"
                        color="rgb(254, 80, 0)"
                        valueField="RevActual"
                      />
                      {/* <Tooltip
                        color="white"
                        font={{ color: "black" }}
                        format="currency"
                        enabled={true}
                        zIndex="5000"
                      /> */}
                    </Chart>
                    <DataGrid
                      dataSource={revenuePIVOT ? revenuePIVOT : []}
                      // defaultColumns={columns}
                      showBorders={true}
                      columns={[
                        "type",
                        { dataField: "Jan", format: "fixedPoint" },
                        { dataField: "Feb", format: "fixedPoint" },
                        { dataField: "Mar", format: "fixedPoint" },
                        { dataField: "Apr", format: "fixedPoint" },
                        { dataField: "May", format: "fixedPoint" },
                        { dataField: "Jun", format: "fixedPoint" },
                        { dataField: "Jul", format: "fixedPoint" },
                        { dataField: "Aug", format: "fixedPoint" },
                        { dataField: "Sep", format: "fixedPoint" },
                        { dataField: "Oct", format: "fixedPoint" },
                        { dataField: "Nov", format: "fixedPoint" },
                        { dataField: "Dec", format: "fixedPoint" },
                        { dataField: "Total", format: "fixedPoint" },
                      ]}
                    ></DataGrid>
                  </div>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  Service Monthly Gross Profit
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <div className="dx-viewport">
                    <Chart
                      animation={{ enabled: false }}
                      // theme="generic.dark"
                      dataSource={
                        monthlyDivisionSummary ? monthlyDivisionSummary : []
                      }
                    >
                      <CommonSeriesSettings
                        argumentField="ShortMonth"
                        type="line"
                      >
                        <Label visible={true} backgroundColor="transparent">
                          <Format type="currency" />
                        </Label>
                      </CommonSeriesSettings>
                      <Legend
                        verticalAlignment="bottom"
                        horizontalAlignment={"center"}
                      />
                      <Series name="Goal" color="gray" valueField="GMGoal" />
                      <Series
                        name="Pipeline"
                        color="#fff"
                        valueField="GMForecast"
                      />
                      <Series
                        name="Actual"
                        color="rgb(254, 80, 0)"
                        valueField="GMActual"
                      />
                      {/* <Tooltip
                        color="white"
                        font={{ color: "black" }}
                        format="currency"
                        enabled={true}
                        zIndex="5000"
                      /> */}
                    </Chart>
                    <DataGrid
                      dataSource={grossPIVOT ? grossPIVOT : []}
                      // defaultColumns={columns}
                      showBorders={true}
                      columns={[
                        "type",
                        { dataField: "Jan", format: "fixedPoint" },
                        { dataField: "Feb", format: "fixedPoint" },
                        { dataField: "Mar", format: "fixedPoint" },
                        { dataField: "Apr", format: "fixedPoint" },
                        { dataField: "May", format: "fixedPoint" },
                        { dataField: "Jun", format: "fixedPoint" },
                        { dataField: "Jul", format: "fixedPoint" },
                        { dataField: "Aug", format: "fixedPoint" },
                        { dataField: "Sep", format: "fixedPoint" },
                        { dataField: "Oct", format: "fixedPoint" },
                        { dataField: "Nov", format: "fixedPoint" },
                        { dataField: "Dec", format: "fixedPoint" },
                        { dataField: "Total", format: "fixedPoint" },
                      ]}
                    ></DataGrid>
                  </div>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  Service Monthly Net Profit
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <div className="dx-viewport">
                    <Chart
                      animation={{ enabled: false }}
                      // theme="generic.dark"
                      dataSource={
                        monthlyDivisionSummary ? monthlyDivisionSummary : []
                      }
                    >
                      <CommonSeriesSettings
                        argumentField="ShortMonth"
                        type="line"
                      >
                        <Label visible={true} backgroundColor="transparent">
                          <Format type="currency" />
                        </Label>
                      </CommonSeriesSettings>
                      <Legend
                        verticalAlignment="bottom"
                        horizontalAlignment={"center"}
                      />
                      <Series
                        name="Goal"
                        color="gray"
                        valueField="NetProfitGoal"
                      />
                      <Series
                        name="Pipeline"
                        color="#fff"
                        valueField="NetProfitforecast"
                      />
                      <Series
                        name="Actual"
                        color="rgb(254, 80, 0)"
                        valueField="NetProfitActual"
                      />
                      {/* <Tooltip
                        color="white"
                        font={{ color: "black" }}
                        format="currency"
                        enabled={true}
                        zIndex="5000"
                      /> */}
                    </Chart>
                    <DataGrid
                      dataSource={netPIVOT ? netPIVOT : []}
                      // defaultColumns={columns}
                      showBorders={true}
                      columns={[
                        "type",
                        { dataField: "Jan", format: "fixedPoint" },
                        { dataField: "Feb", format: "fixedPoint" },
                        { dataField: "Mar", format: "fixedPoint" },
                        { dataField: "Apr", format: "fixedPoint" },
                        { dataField: "May", format: "fixedPoint" },
                        { dataField: "Jun", format: "fixedPoint" },
                        { dataField: "Jul", format: "fixedPoint" },
                        { dataField: "Aug", format: "fixedPoint" },
                        { dataField: "Sep", format: "fixedPoint" },
                        { dataField: "Oct", format: "fixedPoint" },
                        { dataField: "Nov", format: "fixedPoint" },
                        { dataField: "Dec", format: "fixedPoint" },
                        { dataField: "Total", format: "fixedPoint" },
                      ]}
                    ></DataGrid>
                  </div>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment className="segment-devextreme-datagrid" inverted>
              <Accordion fluid inverted>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={handleClick}
                >
                  <h3>
                    <Icon name="dropdown" />
                    Service Project Manager Goals
                  </h3>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <RevenueGoals
                    dataSource={revenueGoalsPM}
                    division="service"
                    userRole="PM"
                    year={year}
                  />
                  <br />
                </Accordion.Content>
              </Accordion>
              <Accordion fluid inverted>
                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={handleClick}
                >
                  <h3>
                    <Icon name="dropdown" />
                    Service Project Engineer Goals
                  </h3>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <RevenueGoals
                    division="service"
                    userRole="PE"
                    year={year}
                    dataSource={revenueGoalsPE}
                  />
                  <br />
                </Accordion.Content>
              </Accordion>
              <Accordion fluid inverted>
                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={handleClick}
                >
                  <h3>
                    <Icon name="dropdown" />
                    Service Superintendent Goals
                  </h3>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <RevenueGoals
                    dataSource={revenueGoalsSI}
                    division="service"
                    userRole="SI"
                    year={year}
                  />
                  <br />
                </Accordion.Content>
              </Accordion>
              <Accordion fluid inverted>
                <Accordion.Title
                  active={activeIndex === 3}
                  index={3}
                  onClick={handleClick}
                >
                  <h3>
                    <Icon name="dropdown" />
                    Service Gross Margin % Leaders
                  </h3>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 3}>
                  <GMPLeaderboard
                    year={year}
                    division="service"
                    userRole="PM"
                    dataSource={GMPLeaderboardPM}
                  />
                  <br />
                </Accordion.Content>
              </Accordion>
              <Accordion fluid inverted>
                <Accordion.Title
                  active={activeIndex === 4}
                  index={4}
                  onClick={handleClick}
                >
                  <h3>
                    <Icon name="dropdown" />
                    Service Revenue Leaders
                  </h3>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 4}>
                  <RevenueLeaderboard
                    year={year}
                    division="service"
                    userRole="PM"
                    dataSource={RevenueLeaderboardPM}
                  />
                  <br />
                </Accordion.Content>
              </Accordion>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default Service;
