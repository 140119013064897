import React, { Fragment, useState, useEffect } from "react";
import SearchBar from "./SearchBar/SearchBar";
import ProjectList from "./ProjectLIst/ProjectList";
import { Dimmer, Loader } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";
import FilterButtons from "./FilterButtons/FilterButtons";

const ProjectWrapper = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [projects, setProjects] = useState(null);
  const [filterTerm, setFilterTerm] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filterButton, setFilterButton] = useState("Active");
  const [finalProjectsArray, setFinalProjectsArray] = useState([]);

  useEffect(() => {
    setLoading(true);
    Axios.get(`${API_ROOT}/api/project/projects-mobile`)
      .then((res) => {
        if (res.data) {
          setError(false);
          setProjects(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let filtered = [];
    if (filterTerm) {
      filtered = projects.filter(
        (project) =>
          project.searchText.toLowerCase().indexOf(filterTerm.toLowerCase()) !==
          -1
      );
    } else {
      filtered = projects;
    }

    setFilteredProjects(filtered);
  }, [projects, filterTerm]);

  useEffect(() => {
    // console.log(filteredProjects);
    // console.log(filterButton);
    if (filteredProjects) {
      switch (filterButton) {
        case "Active":
          setFinalProjectsArray(
            filteredProjects.filter(
              (project) =>
                project.stageType !== "Completed" &&
                project.stageType !== "Canceled" &&
                project.stageType !== "Rejected"
            )
          );
          break;
        case "Archived":
          setFinalProjectsArray(
            filteredProjects.filter(
              (project) =>
                project.stageType === "Completed" ||
                project.stageType === "Canceled" ||
                project.stageType === "Rejected"
            )
          );
          break;
        case "WIP":
          setFinalProjectsArray(
            filteredProjects.filter(
              (project) => project.stageType === "In Progress"
            )
          );
          break;
        case "APRVD":
          setFinalProjectsArray(
            filteredProjects.filter(
              (project) => project.stageType === "Approved"
            )
          );
          break;
        case "CLIENT":
          setFinalProjectsArray(
            filteredProjects.filter(
              (project) => project.stageType === "Awaiting Approval"
            )
          );
          break;
        case "PREP":
          setFinalProjectsArray(
            filteredProjects.filter(
              (project) => project.stageType === "Preparing Proposal"
            )
          );
          break;
        case "ME":
          setFinalProjectsArray(
            filteredProjects.filter((project) => project.myProjectInd === 1)
          );
          break;
        default:
          setFinalProjectsArray(filteredProjects);
      }
    }
  }, [filteredProjects, filterButton]);

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader inverted />
      </Dimmer>
    );
  }

  if (error) {
    return (
      <div>
        <p style={{ textAlign: "center", paddingTop: 20 }}>
          There was an error loading the weekly assignment data. Please contact
          IT.
        </p>
      </div>
    );
  }

  return (
    <Fragment>
      <SearchBar filterTerm={filterTerm} setFilterTerm={setFilterTerm} />
      <FilterButtons
        filterButton={filterButton}
        setFilterButton={setFilterButton}
      />
      <ProjectList loading={loading} projects={finalProjectsArray} />
    </Fragment>
  );
};

export default ProjectWrapper;
