import React, { Fragment, useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  setSurveyMenuSelection,
  resetSurveyMenuSelection,
} from "../../../../actions/surveyMenu/surveyMenuActions";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";

function generate_year_range(start, end) {
  var years = [];
  for (var year = start; year <= end; year++) {
    years.push(year);
  }
  return years;
}

let date = moment();

let currentYear = date.year();

const SurveyListItems = (props) => {
  const [teamMembersOptions, setTeamMembersOptions] = useState([]);
  const quarter = useSelector((state) => state.surveyMenu.quarter);
  const year = useSelector((state) => state.surveyMenu.year);
  const userID = useSelector((state) => state.surveyMenu.userID);
  const surveyStatus = useSelector((state) => state.surveyMenu.surveyStatus);
  const dispatch = useDispatch();

  var my_years = generate_year_range(2020, currentYear).sort((a, b) => b - a);
  const quarters = [1, 2, 3, 4];

  const optionsArrays = my_years.map((y) =>
    quarters.map((q) => {
      // let value = `Q${q} ${y}`;
      return q === 5
        ? {
            key: `Q${q} ${y}`,
            // text: `Q${q} ${y}`,
            disabled: true,
            // value: JSON.stringify({ q, y }),
            value: "",
            content: <hr className="dropdown-hr" />,
            // className: "divider",
          }
        : {
            key: `Q${q} ${y}`,
            text: `Q${q} ${y}`,
            value: JSON.stringify({ q, y }),
          };
    })
  );

  const getTeamMembers = () => {
    Axios.get(`${API_ROOT}/api/crm/users`)
      .then((res) => {
        let users = [...res.data.users];
        users.unshift({
          value: 1,
          text: "All Team Members",
          key: 1,
        });
        setTeamMembersOptions(users);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    dispatch(resetSurveyMenuSelection());
    getTeamMembers();
  }, []);

  // useEffect(() => {
  //   newTeamMemberOptions = [
  //     {
  //       value: 1,
  //       text: "All Team Members",
  //       key: 1,
  //     },
  //   ];

  //   // console.log(teamMembersOptions);

  //   if (Array.isArray(teamMembersOptions) && teamMembersOptions.length) {
  //     newTeamMemberOptions = [...teamMembersOptions];
  //     newTeamMemberOptions.unshift({
  //       value: 1,
  //       text: "All Team Members",
  //       key: 1,
  //     });

  //     console.log(newTeamMemberOptions);
  //   }
  // }, [teamMembersOptions]);

  const options = [].concat(...optionsArrays);

  // const dateValue = `Q${quarter} ${year}`;

  const dateValue = JSON.stringify({ q: quarter, y: year });

  const handleDateChange = (data) => {
    const parsedValue = JSON.parse(data.value);

    const newState = {
      quarter: parsedValue.q,
      year: parsedValue.y,
      userID,
      surveyStatus,
    };

    dispatch(setSurveyMenuSelection(newState));

    // console.log(parsedValue);
  };

  const handleTeamMemberChange = (data) => {
    const newState = {
      quarter,
      year,
      userID: data.value,
      surveyStatus,
    };

    dispatch(setSurveyMenuSelection(newState));
  };

  const handleStatusChange = (data) => {
    const newState = {
      quarter,
      year,
      userID,
      surveyStatus: data.value,
    };

    dispatch(setSurveyMenuSelection(newState));
  };

  return (
    <Fragment>
      <Dropdown
        scrolling
        selectOnBlur={false}
        text={`Showing Q${quarter} ${year}`}
        value={dateValue}
        item
        options={options}
        onChange={(e, data) => handleDateChange(data)}
      />
      <Dropdown
        scrolling
        selectOnBlur={false}
        placeholder="Team Member"
        value={userID}
        item
        options={teamMembersOptions}
        onChange={(e, data) => handleTeamMemberChange(data)}
      />
      <Dropdown
        scrolling
        selectOnBlur={false}
        placeholder="Survey Status"
        value={surveyStatus}
        item
        options={[
          { key: "All Projects", text: "All Projects", value: 0 },
          { key: "Not Invited", text: "Not Invited", value: 1 },
          { key: "Invited", text: "Invited", value: 2 },
          { key: "Responded", text: "Responded", value: 3 },
        ]}
        onChange={(e, data) => handleStatusChange(data)}
      />
    </Fragment>
  );
};

export default SurveyListItems;
