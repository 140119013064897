import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { API_ROOT } from "../../../../api-config";
import { saveAs } from "file-saver";
import Axios from "axios";
import "./SubContractTracking.scss";
import ApprovedSubContracts from "./ApprovedSubContracts/ApprovedSubContracts";
import PendingSubContracts from "./PendingSubContracts/PendingSubContracts";
import {
  Table,
  Modal,
  Button,
  Icon,
  Header,
  Dropdown,
  Form,
  TextArea,
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import InactiveSubContracts from "./InactiveSubContracts/InactiveSubContracts";
import { setInclusionMenuSelection } from "../../../../actions/project/inclusion/inclusionActions";
import SubContractDetailWrapper from "./SubContractDetailWrapper/SubContractDetailWrapper";
import SubContractDetail from "./SubContractDetailWrapper/SubContractDetail/SubContractDetail";

const SubContractTracking = (props) => {
  const [approvedContracts, setApprovedContracts] = useState([]);
  const [pendingContracts, setPendingContracts] = useState([]);
  const [inactiveContracts, setInactiveContracts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [confirmProposalModalOpen, setConfirmProposalModalOpen] = useState(
    false
  );
  const [proposalToSend, setProposalToSend] = useState("");
  const [proposalSending, setProposalSending] = useState(false);
  const [proposalCompanyID, setProposalCompanyID] = useState(null);
  const [grandTotal, setGrandTotal] = useState(0);
  const [invoicedTotal, setInvoicedTotal] = useState(0);
  const [receivedTotal, setReceivedTotal] = useState(0);
  const [contractRecipients, setContractRecipients] = useState([]);
  const [currentRecipientValue, setCurrentRecipientValue] = useState([]);
  const [emailMessage, setEmailMessage] = useState("");
  const [bidUploadmodalOpen, setBidUploadModalOpen] = useState(false);
  const [bidDate, setBidDate] = useState("");
  const [bidFile, setBidFile] = useState([]);
  const [bidFilePath, setBidFilePath] = useState("");
  const [bidGUID, setbidGUID] = useState(null);
  const [PMEmail, setPMEmail] = useState("");

  // Create our number formatter.
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  useEffect(() => {
    Axios.get(`${API_ROOT}/api/contract/subcontracts`, {
      params: {
        projectID: props.projectID,
      },
    })
      .then((res) => {
        loadContracts(res);
      })
      .catch((err) => {
        console.log(err);
      });
    getSubContractsTotals();
    getProjectManagerEmail();
  }, [props.ProjectID]);

  const getCompanyEmail = (guid) => {
    if (guid) {
      Axios.get(`${API_ROOT}/api/contract/sub-company-email`, {
        params: {
          guid,
        },
      })
        .then((res) => {
          const subEmail = res.data.email;
          if (subEmail) {
            setContractRecipients([{ text: subEmail, value: subEmail }]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getProjectManagerEmail = () => {
    Axios.get(`${API_ROOT}/api/project/project-manager-email`, {
      params: {
        projectID: props.projectID,
      },
    }).then((res) => {
      const PMEmail = res.data.emailAddress.emailAddress;
      if (PMEmail) {
        setPMEmail(PMEmail);
      }
    });
  };

  const loadContracts = (res) => {
    if (res.data.subContracts) {
      const subContracts = res.data.subContracts;
      let approved = [];
      let pending = [];
      let inactive = [];
      approved = subContracts.filter(
        (contract) => contract.ContractStageCategory === "Approved"
      );
      pending = subContracts.filter(
        (contract) => contract.ContractStageCategory === "Pending"
      );
      inactive = subContracts.filter(
        (contract) => contract.ContractStageCategory === "Inactive"
      );
      setApprovedContracts(approved);
      setPendingContracts(pending);
      setInactiveContracts(inactive);
    }
  };

  const getSubContractsTotals = () => {
    Axios.get(`${API_ROOT}/api/contract/subcontractstotals`, {
      params: {
        projectID: props.projectID,
      },
    })
      .then((res) => {
        setGrandTotal(res.data.subContractsTotals.GrandTotal || 0);
        setInvoicedTotal(res.data.subContractsTotals.InvoicedTotal || 0);
        setReceivedTotal(res.data.subContractsTotals.ReceivedTotal || 0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkSubContractReady = (guid, companyID) => {
    setProposalCompanyID(null);
    setCurrentRecipientValue([]);

    getCompanyEmail(guid);

    Axios.get(`${API_ROOT}/api/contract/checksubcontractready`, {
      params: {
        guid,
      },
    })
      .then((res) => {
        const subContractReady = res.data.subContractReady;
        const {
          clientContractApprovedInd,
          subContractReadyInd,
          message,
        } = subContractReady;

        setMessage(message);

        if (subContractReadyInd === 0 || clientContractApprovedInd === 0) {
          setModalOpen(true);
          setProposalToSend("");
        } else {
          setProposalToSend(guid);
          setProposalCompanyID(companyID);

          setConfirmProposalModalOpen(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDaysBlur = (guid, subWorkDays) => {
    Axios.post(`${API_ROOT}/api/contract/subworkdays`, {
      params: {
        guid,
        subWorkDays,
      },
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRetentionBlur = (guid, retention) => {
    Axios.post(`${API_ROOT}/api/contract/sub-retention`, {
      params: {
        guid,
        retention: retention || retention === 0 ? retention : 0,
      },
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setContractSubmitted = (guid) => {
    Axios.post(`${API_ROOT}/api/contract/setsubcontractsubmitted`, {
      params: {
        guid,
        projectID: props.projectID,
      },
    })
      .then((res) => {
        loadContracts(res);
        handleClose();
        setProposalSending(false);
        setProposalToSend("");
      })
      .catch((err) => {
        console.log(err);
      });
    getSubContractsTotals();
  };

  const sendProposal = (guid, companyID) => {
    const email = contractRecipients.map((elem) => elem.value).join(";");

    if (!email.length || !emailMessage.length) {
      return;
    }

    setProposalSending(true);

    let baseUrl = "";

    const hostname = window && window.location && window.location.hostname;

    if (hostname === "nexsys1-staging.azurewebsites.net") {
      baseUrl = "https://nexsys1-staging.azurewebsites.net";
    } else if (hostname === "ns1.nexus5group.com") {
      baseUrl = "https://ns1.nexus5group.com";
    } else if (hostname === "nexsys1.azurewebsites.net") {
      baseUrl = "https://nexsys1.azurewebsites.net";
    } else {
      // backEndHost = 'https://nexsys1api-staging.azurewebsites.net';
      baseUrl = "http://localhost:3000";
    }

    const company =
      pendingContracts[0] && proposalCompanyID
        ? pendingContracts[0].SubContractor.find(
            (sub) => sub.companyid === proposalCompanyID
          )
        : null;
    const proposal = company
      ? company.SubContracts.find(
          (contract) =>
            contract.GUID === proposalToSend && contract.displayType === "data"
        )
      : null;
    const projectName = proposal ? proposal.projectName : "";
    // const workLocationName = proposal ? proposal.workLocationName : "";
    const contractType = proposal ? proposal.SubContractType : "";

    let subject = "";
    if (contractType === "Base") {
      subject = `Nexus 5 Subcontractor Contract for Project - ${props.projectID}-${projectName}`;
    } else {
      subject = `Nexus 5 Subcontractor ${contractType} for Project - ${props.projectID}-${projectName}`;
    }

    let message = "";
    if (contractType === "Base") {
      message = `${emailMessage}<br /><br />Click <a href='${baseUrl}/contract/?r=s&id=${guid}&c=${companyID}'>here</a> to view the contract.</p>`;
    } else {
      message = `${emailMessage}<br /><br />Click <a href='${baseUrl}/contract/?r=s&id=${guid}&c=${companyID}'>here</a> to view ${contractType}.</p>`;
    }

    // SEND PROPOSAL EMAIL
    Axios.post(`${API_ROOT}/send-email`, {
      email,
      cc: PMEmail,
      bcc: "SoftwareDevelopment@cohenesrey.com",
      subject,
      // text: `<p>${emailMessage} </p>`,
      html: message,
    })
      .then((res) => {
        setContractSubmitted(guid);
      })
      .catch((err) => {
        setProposalSending(true);
        alert(err);
      });
  };

  const dispatch = useDispatch();

  const handleInclusionChange = (event, data) => {
    const subCategoryCode = data.value;

    const options = data.options;
    const selectedOption = options.filter(
      (option) => option.text === subCategoryCode
    );
    const categoryID = selectedOption[0].categoryid;
    dispatch(
      setInclusionMenuSelection({
        selectedCategoryID: categoryID,
        selectedSubCategoryCode: subCategoryCode,
      })
    );
    props.history.push(`/browser/projects/inclusions/${props.projectID}`);
  };

  const handleAddition = (e, { value }) => {
    setContractRecipients((contractRecipients) => [
      ...contractRecipients,
      {
        text: value,
        value,
      },
    ]);
  };

  const handleChange = (e, { value }) => {
    setCurrentRecipientValue(value);
  };

  const handleClose = () => {
    setConfirmProposalModalOpen(false);
    setContractRecipients([]);
    setEmailMessage("");
  };

  const handleBidIconClick = (guid) => {
    setBidUploadModalOpen(true);
    setbidGUID(guid);
  };

  const handleBidDateChange = (event, { name, value }) => {
    setBidDate(value);
  };

  const handleBidFileChange = (event, { value }) => {
    setBidFilePath(value);
    setBidFile(event.target.files);
  };

  const handleBidUploadModalClose = () => {
    setBidDate("");
    setBidFilePath("");
    setBidFile([]);
    setbidGUID(null);
    setBidUploadModalOpen(false);
  };

  const uploadBid = () => {
    const blobName = bidGUID + ".pdf";

    const form = new FormData();
    form.append("bidGUID", bidGUID);
    form.append("bidDate", bidDate);
    form.append("file", bidFile[0], blobName);

    Axios.post(`${API_ROOT}/api/contract/bid-file`, form).then((res) => {
      loadContracts(res);
      handleBidUploadModalClose();
    });
  };

  const downloadBid = (guid) => {
    const fileName = guid + ".pdf";
    Axios.get(`${API_ROOT}/api/contract/bid-uri`, {
      params: {
        fileName,
      },
    })
      .then((res) => {
        saveAs(res.data, fileName);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadSubContract = (contractFile, contractGUID) => {
    const form = new FormData();
    form.append("file", contractFile[0], contractGUID + ".pdf");
    form.append("contractGUID", contractGUID);

    Axios.post(`${API_ROOT}/api/contract/uploadsubcontract`, form).then(
      (res) => {
        loadContracts(res);
      }
    );
  };

  const setUnsignedContract = (guid, companyID) => {
    Axios.post(`${API_ROOT}/api/contract/no-signature-sub`, {
      params: {
        recipient: "s",
        guid,
        companyID,
      },
    })
      .then((res) => {
        console.log(res);
        loadContracts(res);
        // window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="SubContractTracking" style={{ padding: "1rem" }}>
      <h1 style={{ color: "rgb(254, 80, 0)" }}>Sub Contracts</h1>
      <Table structured unstackable compact="very" style={{ fontSize: 12 }}>
        <ApprovedSubContracts
          downloadBid={downloadBid}
          handleBidIconClick={handleBidIconClick}
          approvedContracts={approvedContracts}
          handleInclusionChange={handleInclusionChange}
          uploadSubContract={uploadSubContract}
        />
        <PendingSubContracts
          downloadBid={downloadBid}
          handleBidIconClick={handleBidIconClick}
          checkSubContractReady={checkSubContractReady}
          handleDaysBlur={handleDaysBlur}
          handleRetentionBlur={handleRetentionBlur}
          pendingContracts={pendingContracts}
          handleInclusionChange={handleInclusionChange}
          loadContracts={loadContracts}
          uploadSubContract={uploadSubContract}
          setUnsignedContract={setUnsignedContract}
        />
        <InactiveSubContracts
          downloadBid={downloadBid}
          handleBidIconClick={handleBidIconClick}
          inactiveContracts={inactiveContracts}
          handleInclusionChange={handleInclusionChange}
          loadContracts={loadContracts}
        />
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="8"></Table.HeaderCell>
            <Table.HeaderCell
              style={{ color: "purple", fontWeight: "bold" }}
              textAlign="center"
            >
              {formatter.format(grandTotal)}
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ color: "purple", fontWeight: "bold" }}
              textAlign="center"
            >
              {formatter.format(invoicedTotal)}
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ color: "purple", fontWeight: "bold" }}
              textAlign="center"
            >
              {formatter.format(receivedTotal)}
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <SubContractDetailWrapper
        projectID={props.projectID}
        WrappedComponent={SubContractDetail}
      />
      <Modal
        size="small"
        open={confirmProposalModalOpen}
        onClose={() => handleClose()}
      >
        <Modal.Header>Send Contract</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Add Contract Recipients' Email Addresses</label>
              <Dropdown
                options={contractRecipients}
                search
                noResultsMessage=""
                selection
                selectOnBlur={false}
                fluid
                multiple
                allowAdditions
                value={currentRecipientValue}
                onAddItem={handleAddition}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Add Message</label>
              <TextArea
                onChange={(e, { value }) => setEmailMessage(value)}
                value={emailMessage}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="yellow" onClick={() => handleClose()}>
            <Icon name="cancel" /> Cancel
          </Button>
          <Button
            loading={proposalSending}
            color="green"
            onClick={() => sendProposal(proposalToSend, proposalCompanyID)}
          >
            <Icon name="checkmark" /> Go for it
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        basic
        size="tiny"
      >
        <Header icon="exclamation" content="Missing Contract Information" />
        <Modal.Content>
          <h3>{message}</h3>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={() => setModalOpen(false)}>
            <Icon name="checkmark" /> Got it
          </Button>
        </Modal.Actions>
      </Modal>

      {/* Bid upload modal */}
      <Modal
        open={bidUploadmodalOpen}
        onClose={() => handleBidUploadModalClose}
        size="tiny"
      >
        <Header icon="cloud upload" content="Upload Bid" />
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Bid Date</label>
              <DateInput
                id="bidDate"
                name="date"
                placeholder="Date"
                value={bidDate}
                iconPosition="left"
                onChange={handleBidDateChange}
                dateFormat={"MM/DD/YYYY"}
              />
            </Form.Field>
            <Form.Field>
              <label>Bid File</label>
              <Form.Input
                value={bidFilePath}
                type="file"
                onChange={handleBidFileChange}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="yellow" onClick={() => handleBidUploadModalClose()}>
            Cancel
          </Button>
          <Button
            color="green"
            onClick={() => uploadBid()}
            // inverted
          >
            <Icon name="checkmark" /> Got it
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default withRouter(SubContractTracking);
