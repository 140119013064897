import Axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { Dimmer, Loader, Table } from "semantic-ui-react";
import { API_ROOT } from "../../../../api-config";
import { userIsInRole } from "../../../../util";
import DeleteInventoryModal from "./DeleteInventoryModal/DeleteInventoryModal";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

function inventoryReducer(state, action) {
  switch (action.type) {
    case "CHANGE_SORT":
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
        };
      }

      return {
        column: action.column,
        data:
          action.column === "totalCost" || action.column === "quantity"
            ? state.data.sort((a, b) => a[action.column] - b[action.column])
            : action.column === "date"
            ? state.data.sort(
                (a, b) =>
                  new Date(a[action.column]) - new Date(b[action.column])
              )
            : state.data.sort((a, b) =>
                a[action.column].localeCompare(b[action.column])
              ),
        direction: "ascending",
      };
    case "LOAD_DATA":
      return {
        ...state,
        data: action.payload,
      };
    default:
      throw new Error();
  }
}

const Inventory = ({ projectID }) => {
  const [loading, setLoading] = useState(false);

  let userIsAccounting = useSelector((state) =>
    state.auth.user ? userIsInRole(state, 9) : false
  );

  const [state, dispatch] = useReducer(inventoryReducer, {
    column: null,
    data: [],
    direction: null,
  });
  const { column, data, direction } = state;

  const fetchInventoryTracking = () => {
    setLoading(true);
    Axios.get(
      `${API_ROOT}/api/project/inventory-tracking?projectID=${projectID}`
    )
      .then((res) => {
        // setData(res.data);
        dispatch({ type: "LOAD_DATA", payload: res.data });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchInventoryTracking();
  }, []);

  return loading ? (
    <Dimmer active inverted>
      <Loader inverted style={{ paddingBottom: 60 }} />
    </Dimmer>
  ) : (
    <Table sortable celled compact="very" unstackable style={{ fontSize: 12 }}>
      <Table.Header>
        <Table.Row>
          {userIsAccounting ? <Table.HeaderCell></Table.HeaderCell> : null}

          <Table.HeaderCell
            sorted={column === "date" ? direction : null}
            onClick={() => dispatch({ type: "CHANGE_SORT", column: "date" })}
          >
            Date
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "name" ? direction : null}
            onClick={() => dispatch({ type: "CHANGE_SORT", column: "name" })}
          >
            Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "subCategoryCode" ? direction : null}
            onClick={() =>
              dispatch({ type: "CHANGE_SORT", column: "subCategoryCode" })
            }
          >
            Cost Code
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "conditionName" ? direction : null}
            onClick={() =>
              dispatch({ type: "CHANGE_SORT", column: "conditionName" })
            }
          >
            Condition
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "inventoryID" ? direction : null}
            onClick={() =>
              dispatch({ type: "CHANGE_SORT", column: "inventoryID" })
            }
          >
            Inventory ID
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "description" ? direction : null}
            onClick={() =>
              dispatch({ type: "CHANGE_SORT", column: "description" })
            }
          >
            Description
          </Table.HeaderCell>

          <Table.HeaderCell
            sorted={column === "quantity" ? direction : null}
            onClick={() =>
              dispatch({ type: "CHANGE_SORT", column: "quantity" })
            }
          >
            Quantity
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "totalCost" ? direction : null}
            onClick={() =>
              dispatch({ type: "CHANGE_SORT", column: "totalCost" })
            }
          >
            Amount
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Array.isArray(data) && data.length ? (
          data.map((obj) => (
            <Table.Row key={obj.id}>
              {userIsAccounting ? (
                <DeleteInventoryModal
                  dispatch={dispatch}
                  id={obj.id}
                  projectID={projectID}
                  setLoading={setLoading}
                />
              ) : null}
              <Table.Cell>{obj.date}</Table.Cell>
              <Table.Cell>{obj.name}</Table.Cell>
              <Table.Cell>{obj.subCategoryCode}</Table.Cell>
              <Table.Cell>{obj.conditionName}</Table.Cell>
              <Table.Cell>{obj.inventoryID}</Table.Cell>
              <Table.Cell>{obj.description}</Table.Cell>

              <Table.Cell>{obj.quantity}</Table.Cell>
              <Table.Cell>{formatter.format(obj.totalCost)}</Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row>
            <Table.Cell colSpan={userIsAccounting ? 9 : 8}>
              No data to display
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default Inventory;
