import axios from "./axios";
import dayjs from "dayjs";

export const getProject = (projectId) =>
  axios
    .get(`/projects/${projectId}`)
    .then((results) => results.data)
    .then((results) => ({
      ...results,
      created_date: dayjs(results.created_date, "YYYY-MM-DD"),
    }));

export const getTracking = (projectId) =>
  axios.get(`/projects/${projectId}/tracking`).then((results) => results.data);

export const getContracts = (projectId) =>
  axios.get(`/projects/${projectId}/contracts`).then((results) => results.data);
