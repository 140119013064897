import React from "react";

const DetailedCORowNew = (props) => {
  function groupBy(objectArray, property) {
    return objectArray.reduce(function(acc, obj) {
      var key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  const conditions = props.conditions;
  const summary = props.summary;

  const filteredConditions = conditions.filter(
    (item) => item.Category === props.category
  );

  const grouped = groupBy(filteredConditions, "SubCategory");

  const subCategories = [
    ...new Set(filteredConditions.map((item) => item.SubCategory)),
  ];

  return (
    <>
      {Array.isArray(subCategories) && subCategories.length
        ? subCategories.map((subCategory, index) => (
            <React.Fragment key={index}>
              <tr>
                {/* <Table.Cell>{summary.ContractType}</Table.Cell> */}
                <td
                  colSpan={summary.Recipient === "sub" ? 3 : 2}
                  style={{ color: "gray", fontWeight: "bold" }}
                >
                  {subCategory}
                </td>
                {/* {summary.Recipient === "sub" ? <Table.Cell></Table.Cell> : null}
                <Table.Cell></Table.Cell> */}
              </tr>
              {Array.isArray(grouped[subCategory]) &&
              grouped[subCategory].length
                ? grouped[subCategory].map((condition, index) => {
                    return (
                      <tr key={index}>
                        {/* <Table.Cell verticalAlign="top"></Table.Cell> */}
                        <td
                          // style={{
                          //   fontWeight: "bold",
                          //   minWidth: 140,
                          //   fontStyle: "italic",
                          // }}
                          style={{ verticalAlign: "top" }}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                condition.ConditionName +
                                " " +
                                (condition.InternalComments &&
                                summary.Recipient !== "sub"
                                  ? "- " + condition.InternalComments
                                  : ""),
                            }}
                          ></span>
                        </td>
                        {summary.Recipient === "sub" ? (
                          <td style={{ verticalAlign: "top" }}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: condition.DetailDescription,
                              }}
                            ></span>
                          </td>
                        ) : null}
                        <td
                          style={{
                            color: "#fe5000",
                            textAlign: "right",
                            fontWeight: "bold",
                          }}
                        >
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(condition.Price)}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </React.Fragment>
          ))
        : null}
    </>
  );
};

export default DetailedCORowNew;
