import Axios from "axios";
import React, { useState } from "react";
import { Icon, Table } from "semantic-ui-react";

const CourseCheckboxes = ({
  user,
  uid,
  courseID,
  courseDate,
  setAttendanceData,
}) => {
  const [requiredLoading, setRequiredLoading] = useState(false);
  const [completedLoading, setCompletedLoading] = useState(false);

  const handleRequiredClick = (userID, checked) => {
    console.log(userID, checked);
    setRequiredLoading(true);
    Axios.get(
      `https://www.ce-knet.com/DesktopModules/XModPro/Feed.aspx?xfd=NS1_SaveUserCourseRequired`,
      {
        withCredentials: false,
        params: {
          pid: 0,
          uid,
          userid: userID,
          courseid: courseID,
          cdate: courseDate,
          checked: checked,
        },
      }
    )
      .then((res) => {
        setAttendanceData(res.data);
        setRequiredLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setRequiredLoading(false);
      });
  };

  const handleCompletedClick = (userID, checked) => {
    console.log(userID, checked);
    setCompletedLoading(true);
    Axios.get(
      `https://www.ce-knet.com/DesktopModules/XModPro/Feed.aspx?xfd=NS1_SaveUserCourse`,
      {
        withCredentials: false,
        params: {
          pid: 0,
          uid,
          userid: userID,
          courseid: courseID,
          cdate: courseDate,
          checked: checked,
        },
      }
    )
      .then((res) => {
        setAttendanceData(res.data);
        setCompletedLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setCompletedLoading(false);
      });
  };

  return (
    <Table.Row>
      <Table.Cell>{user.name}</Table.Cell>
      <Table.Cell textAlign="center">
        <Icon
          onClick={() => handleRequiredClick(user.userid, !user.requiredInd)}
          link
          size="large"
          name={
            requiredLoading
              ? "spinner"
              : user.requiredInd
              ? "check square outline"
              : "square outline"
          }
          loading={requiredLoading}
        />
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Icon
          onClick={() => handleCompletedClick(user.userid, !user.completedind)}
          link
          size="large"
          name={
            completedLoading
              ? "spinner"
              : user.completedind
              ? "check square outline"
              : "square outline"
          }
          loading={completedLoading}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default CourseCheckboxes;
