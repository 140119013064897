import React, { useEffect, useState, Fragment } from "react";
import { useRouteMatch } from "react-router-dom";
import { Menu } from "semantic-ui-react";
// import { useSelector } from "react-redux";
import useIsMounted from "../../../../hooks/isMounted";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import DuplicateProjectModal from "./DuplicateProjectModal/DuplicateProjectModal";

const Project = () => {
  const [projectName, setProjectName] = useState();
  const match = useRouteMatch("/browser/projects/:id");

  const [projectID, setProjectID] = useState(parseInt(match.params.id));
  const [open, setOpen] = useState(false);

  const isMounted = useIsMounted();

  // const projectID = match.params.id ? parseInt(match.params.id) : null;

  useEffect(() => {
    if (match.params.id) {
      setProjectID(parseInt(match.params.id));
    }
  }, [match.params.id]);

  useEffect(() => {
    if (projectID) {
      fetchProject();
    }
  }, [projectID]);

  const fetchProject = () => {
    Axios.get(`${API_ROOT}/api/project/project-name?projectID=${projectID}`)
      .then((res) => {
        if (isMounted.current && res) {
          setProjectName(res.data.projectName);
        }
      })
      .catch((err) => console.log(err));
  };

  return projectName ? (
    <Fragment>
      <Menu.Item>
        <span style={{ fontWeight: "bold" }}>{projectName}</span>
        <span>&nbsp;&nbsp;#{projectID}</span>
      </Menu.Item>
      <Menu.Item onClick={() => setOpen(true)}>Duplicate Project</Menu.Item>
      <DuplicateProjectModal
        open={open}
        setOpen={setOpen}
        projectID={projectID}
      />
    </Fragment>
  ) : null;
};

export default Project;
