import React from "react";
import { Image, Button, Icon } from "semantic-ui-react";
import "./Home.scss";
import bg from "./black-paper-11.jpg";
import logo from "./n5groupshadow.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { userIsInRole } from "../../util";

const Home = () => {
  const userIsInOfficeRole = useSelector((state) =>
    state.auth.user ? userIsInRole(state, 5) : false
  );

  return (
    <div
      className="Home"
      style={{
        height: "100vh",
        width: "100%",
        backgroundColor: "#000",
        background: `url(${bg}) no-repeat center center fixed`,
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "50vh",
          width: "100%",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <Image
          fluid
          style={{ width: "100%", maxWidth: 1000 }}
          // centered
          src={logo}
        />
      </div>
      <div style={{ height: "50vh" }}>
        <div style={{ textAlign: "center" }}>
          {userIsInOfficeRole ? (
            <Button
              as={Link}
              to="/browser"
              style={{ width: 200, marginBottom: 20 }}
              icon
              color="orange"
              size="big"
            >
              NS1 <Icon name="arrow alternate circle right outline" />
            </Button>
          ) : null}

          <Button
            as={Link}
            to="/m"
            style={{ width: 200 }}
            inverted
            // color="white"
            size="big"
          >
            NS1 Mobile
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Home;
