import React, { Component } from "react";
import { connect } from "react-redux";
import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/Login/Login";
import loginRequest from "./actions/auth/actions";
import SidebarContainer from "./components/SidebarContainer/SidebarContainer";
import FourOhFour from "./components/404/404";
import ContractWrapper from "./components/ContractWrapper/ContractWrapper";
import PaymentApplicationApproval from "./components/PaymentApplicationApproval/PaymentApplicationApproval";
import Survey from "./components/Survey/Survey";
import Unsubscribe from "./components/Unsubscribe/Unsubscribe";
import IncentivePayoutCertificate from "./components/Admin/IncentivePayout/IncentivePayoutCertificate";
import Home from "./components/Home/Home";
import Mobile from "./components/Mobile/Mobile";
import AlternateApprovalWrapper from "./components/AlternateApproval/AlternateApprovalWrapper";
import IncentiveAnalysisWrapper from "./components/Admin/IncentiveAnalysis/IncentiveAnalysisWrapper";

import ContractsV2 from "./screens/contracts";
import { NexusConfigProvider } from "./NexusConfig";

class App extends Component {
  componentDidMount() {
    this.props.onComponentIsMounted();
  }

  user = this.props.user ? JSON.parse(this.props.user)[0] : null;

  roles = this.user && this.user.Roles ? this.user.Roles : [];

  render() {
    if (this.roles.some((e) => e.Role === "Office")) {
      return (
        <NexusConfigProvider>
          <Router>
            <Switch>
              {/* If not logged in, only render Login component */}
              <Route exact path="/">
                {this.props.loggedIn ? <Home /> : <Login />}
              </Route>
              <Route path="/incentive-payout-certificate">
                <IncentivePayoutCertificate />
              </Route>
              <Route exact path="/home">
                {this.props.loggedIn ? <Home /> : <Login />}
              </Route>
              <Route path="/m">
                {this.props.loggedIn ? <Mobile /> : <Login />}
              </Route>
              <Route
                path={[
                  "/accounting-reports",
                  "/browser",
                  // "/browser2",
                  "/browser-archive",
                  "/company-goals",
                  "/condition-template-approval",
                  "/crm",
                  "/dashboard",
                  "/incentive-analysis",
                  "/incentive-payout",
                  "/fleet",
                  "/kpi-entry",
                  "/security-roles",
                  "/safety-training",
                  "/survey-list",
                  "/safety-inspection",
                  "/team-member-goals",
                  "/team-member-bonus-pool",
                  "/uniform-and-presentation",
                  "/warehouse",
                  "/condition-approval",
                  "/weekly-timesheet",
                ]}
                component={this.props.loggedIn ? SidebarContainer : Login}
              />
              <Route path="/incentive-analysis">
                <IncentiveAnalysisWrapper />
              </Route>
              <Route path="/contract" component={ContractWrapper} />
              <Route path="/contracts" component={ContractsV2} />
              <Route path="/payapp" component={PaymentApplicationApproval} />
              <Route path="/survey">
                <Survey />
              </Route>
              <Route exact path="/alternate-approval/:guid">
                <AlternateApprovalWrapper />
              </Route>
              <Route path="/unsubscribe">
                <Unsubscribe />
              </Route>
              <Route component={FourOhFour} />
            </Switch>
          </Router>
        </NexusConfigProvider>
      );
    } else {
      return (
        <Router>
          <Switch>
            {/* If not logged in, only render Login component */}
            <Route exact path="/">
              {this.props.loggedIn ? <Home /> : <Login />}
            </Route>
            <Route path="/incentive-payout-certificate">
              {this.props.loggedIn ? <IncentivePayoutCertificate /> : <Login />}
            </Route>
            <Route exact path="/home">
              {this.props.loggedIn ? <Home /> : <Login />}
            </Route>
            <Route path="/m">
              {this.props.loggedIn ? <Mobile /> : <Login />}
            </Route>
            <Route path="/contract" component={ContractWrapper} />
            <Route path="/contracts" component={ContractsV2} />
            <Route path="/payapp" component={PaymentApplicationApproval} />
            <Route path="/survey">
              <Survey />
            </Route>
            <Route exact path="/alternate-approval/:guid">
              <AlternateApprovalWrapper />
            </Route>
            <Route path="/unsubscribe">
              <Unsubscribe />
            </Route>
            <Route component={FourOhFour} />
          </Switch>
        </Router>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onComponentIsMounted: () => {
      dispatch(loginRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
