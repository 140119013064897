import React from "react";
import { Segment, Dimmer, Loader } from "semantic-ui-react";

export default () => (
  <Segment style={{ height: "100vh" }}>
    <Dimmer inverted active>
      <Loader size="massive" inverted indeterminate>
        Finding Contract
      </Loader>
    </Dimmer>
  </Segment>
);
