import React, { useEffect, useState } from "react";
import { Button, Icon, Modal, Select } from "semantic-ui-react";
import { useSelector } from "react-redux";
import Axios from "axios";

import TeamMemberGoals from "./TeamMemberGoals";
import { API_ROOT } from "../../../api-config";
import {
  getAll as getAllTeamMembers,
  add as addTeamMembers,
} from "../../../api/teamMembers";

const TeamMemberGoalsWrapper = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [teamMemberOptions, setTeamMemberOptions] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [reloadGoals, setReloadGoals] = useState(false);

  const year = useSelector((state) => state.teamMemberGoalsMenu.year);
  const division = useSelector((state) => state.teamMemberGoalsMenu.division);
  const role = useSelector((state) => state.teamMemberGoalsMenu.role);

  const [data, setData] = useState(null);

  const handleChange = (params) => {
    console.log(params);
    const userid = params.data.EstimatorID;
    const field = params.colDef.field;
    const value =
      params.colDef.field === "GoalAmount"
        ? params.newValue
        : params.newValue / 100;
    // console.log(year, division, field, role, value);

    Axios.post(`${API_ROOT}/api/kpi/team-member-goal`, {
      year,
      division,
      role,
      userid,
      field,
      value: value ? value : null,
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // console.log(year, division, role);
    Axios.get(`${API_ROOT}/api/kpi/team-member-goals`, {
      params: {
        year,
        division,
        role,
      },
    })
      .then((res) => {
        if (!!res.data) {
          setData(res.data.teamMemberGoals);
        } else {
          setData(null);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setReloadGoals(false));
  }, [year, division, role, reloadGoals]);

  useEffect(() => {
    if (open) {
      setLoading(true);
      getAllTeamMembers()
        .then((results) =>
          setTeamMemberOptions(
            results.map((obj) => ({
              key: obj.id,
              value: obj.id,
              text: obj.full_name,
            }))
          )
        )
        .finally(() => setLoading(false));
    }
  }, [open]);

  const handleTeamMembersUpdate = () => {
    setLoading(true);
    addTeamMembers(teamMembers)
      .then(() => handleCancel())
      .finally(() => {
        setReloadGoals(true);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setOpen(false);
    setTeamMembers([]);
  };
  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", right: 10, top: "0" }}>
        <Button icon labelPosition="left" onClick={() => setOpen(true)}>
          <Icon name="plus" />
          Add Team Member
        </Button>
      </div>

      <Modal
        centered={false}
        open={open}
        size="tiny"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Add Team Member</Modal.Header>
        <Modal.Content>
          <Select
            multiple
            fluid
            search
            loading={loading}
            options={teamMemberOptions}
            onChange={(e, component) => setTeamMembers(component.value)}
            placeholder="Select team members"
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => handleCancel()}>Cancel</Button>
          <Button
            primary
            color="orange"
            onClick={() => handleTeamMembersUpdate()}
          >
            Add Members
          </Button>
        </Modal.Actions>
      </Modal>

      <TeamMemberGoals
        rowData={data}
        handleChange={handleChange}
        division={division}
        role={role}
      />
    </div>
  );
};

export default TeamMemberGoalsWrapper;
