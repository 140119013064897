import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import ClientContract from "./clients.js";

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:contractId/client`} component={ClientContract} />
    </Switch>
  );
};
