import React, { useState, useRef } from "react";

import n5logo from "../N5Group.png";
import {
  Table,
  Button,
  Modal,
  Icon,
  Card,
  Image,
  Message,
  Container,
  Grid,
  Form,
  Divider,
} from "semantic-ui-react";
import SignaturePad from "react-signature-canvas";
import SummaryContractRow from "./SummaryContractRow/SummaryContractRow";

import moment from "moment";

import "./SummaryContract.scss";
import ContractRequirements from "../ContractRequirements/ContractRequirements";
import ExclusionsMessage from "../ExclusionsMessage/ExclusionsMessage";

const SummaryContract = (props) => {
  // const [imageURL, setImageURL] = useState(null);
  const conditions = props.contract.conditions;
  const summary = props.contract.summary;
  const categories = [...new Set(conditions.map((item) => item.Category))];

  const [modalOpen, setModalOpen] = useState(false);
  const [approvedBy, setApprovedBy] = useState("");
  const [loading, setLoading] = useState(false);

  const sigCanvas = useRef({});

  const clear = () => sigCanvas.current.clear();
  const save = () => {
    // const recipient = summary.Recipient;
    const isEmpty = sigCanvas.current.isEmpty();

    if (approvedBy && !isEmpty) {
      setLoading(true);
      const image = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      props.setSignature(image, approvedBy);
      // handleModalClose();
    } else {
      return;
    }
  };
  // setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));

  const handleModalOpen = () => {
    setApprovedBy("");
    setModalOpen(true);
  };

  console.log(summary);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3];
    }
    return null;
  };

  return (
    <>
      <Container className="SummaryContract">
        <Grid id="divToPrint" style={{ margin: 20 }}>
          <Grid.Row
            style={{
              fontSize: 11,
            }}
          >
            <Grid.Column width={4}>
              {summary.Recipient === "client" ? (
                <p style={{ maxWidth: 300 }}>
                  <span style={{ fontWeight: "bold" }}>
                    {summary.ClientContact || ""}
                  </span>
                  <br />
                  {summary.ClientCompanyName || ""}
                  <br />
                  {summary.ClientContactOfficePhone
                    ? formatPhoneNumber(summary.ClientContactOfficePhone)
                    : ""}
                  <br />
                  {summary.ClientContactEmail || ""}
                </p>
              ) : (
                <p style={{ maxWidth: 300 }}>
                  <span style={{ fontWeight: "bold" }}>
                    {summary.SubContactName || ""}
                  </span>
                  <br />
                  {summary.SubCompanyName || ""}
                  <br />
                  {summary.SubContactPhone
                    ? formatPhoneNumber(summary.SubContactPhone)
                    : ""}
                  <br />
                  {summary.SubContactEmail || ""}
                </p>
              )}

              {/* <p style={{ maxWidth: 300 }}>
                <span style={{ fontWeight: "bold" }}>
                  {summary.ProjectName}
                </span>{" "}
                #{summary.ProjectID}
                <br />
                {summary.ProjectDescription}
              </p> */}
            </Grid.Column>
            <Grid.Column width={8}>
              <img
                src={n5logo}
                width={240}
                alt="Nexus 5 Group logo"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <p style={{ textAlign: "right" }}>
                <span style={{ fontWeight: "bold" }}>
                  {summary.ProjectManagerName || ""}
                </span>
                <br />
                Nexus 5 Group
                <br />
                {summary.ProjectManagerPhone || ""}
                <br />
                {summary.ProjectManagerEmail || ""}
              </p>
              {/* <h2 style={{ textAlign: "right", marginTop: 10 }}>
                Project Proposal
              </h2> */}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Divider />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            style={{
              fontSize: 11,
            }}
          >
            <Grid.Column width={4}>
              <p style={{ maxWidth: 300 }}>
                {moment(summary.ContractDate).format("MMMM D, YYYY")}
                <br />
                <span style={{ fontWeight: "bold" }}>
                  {summary.ProjectName}
                </span>{" "}
                #{summary.ProjectID}
                <br />
                <span
                  dangerouslySetInnerHTML={{
                    __html: summary.ProjectDescription,
                  }}
                ></span>
                {/* {summary.ProjectDescription} */}
              </p>
            </Grid.Column>
            <Grid.Column width={8}></Grid.Column>
            <Grid.Column width={4}>
              <h2 style={{ textAlign: "right", marginTop: 10 }}>
                Project Proposal
              </h2>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Table unstackable compact="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Contract</Table.HeaderCell>
                    <Table.HeaderCell>Scope</Table.HeaderCell>
                    <Table.HeaderCell>Price</Table.HeaderCell>
                    <Table.HeaderCell>Conditions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {categories.map((category) => {
                  return (
                    <React.Fragment key={category}>
                      <Table.Body>
                        <Table.Row
                          style={{
                            fontSize: 12,
                            backgroundColor: "lightyellow",
                          }}
                        >
                          <Table.HeaderCell></Table.HeaderCell>
                          <Table.HeaderCell>
                            {category.toUpperCase()}
                          </Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                          <Table.HeaderCell />
                        </Table.Row>
                      </Table.Body>
                      <Table.Body style={{ fontSize: 12 }}>
                        <SummaryContractRow
                          category={category}
                          conditions={conditions}
                          summary={summary}
                        />
                      </Table.Body>
                    </React.Fragment>
                  );
                })}
              </Table>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <Table unstackable basic="very" compact="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell style={{ fontSize: 22, width: "42%" }}>
                      Proposed Total Price
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      style={{
                        fontSize: 22,
                        color: "#fe5000",
                        fontWeight: "bold",
                      }}
                    >
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(summary.GrandTotal)}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
              </Table>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row
            // centered
            style={{
              fontSize: 11,
            }}
          >
            <Grid.Column width={8}>
              {summary.Signature ||
              summary.ApprovedBy === "Manual contract upload" ? (
                <Card centered>
                  <Card.Header
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      fontWeight: "bold",
                      borderBottom: "1px solid rgba(34,36,38,.1)",
                      paddingTop: 7,
                      paddingBottom: 7,
                    }}
                  >
                    {summary.Recipient === "client"
                      ? "Client"
                      : "Subcontractor"}{" "}
                    Signature
                  </Card.Header>
                  {summary.ApprovedBy === "Manual contract upload" ? null : (
                    <Image
                      src={summary.Signature}
                      // style={{ width: '100%' }}
                    />
                  )}
                  <Card.Content>
                    <Card.Header>{summary.ApprovedBy}</Card.Header>
                    <Card.Meta>
                      <span className="date">
                        {" "}
                        {moment(summary.ApprovalDate).format("MMM Do YYYY")}
                      </span>
                    </Card.Meta>
                  </Card.Content>
                </Card>
              ) : (
                <Modal
                  closeOnDimmerClick={false}
                  open={modalOpen}
                  onClose={handleModalClose}
                  size={"tiny"}
                  trigger={
                    <Button primary onClick={handleModalOpen}>
                      Sign Approval
                    </Button>
                  }
                >
                  <Modal.Header>Sign Approval</Modal.Header>
                  <Modal.Content>
                    <Form style={{ marginBottom: 10 }}>
                      <label>Your name</label>
                      <Form.Input
                        value={approvedBy}
                        onChange={(e) => setApprovedBy(e.target.value)}
                      />
                    </Form>

                    <div>
                      <label>Please sign below</label>
                      <SignaturePad
                        ref={sigCanvas}
                        canvasProps={{ className: "contractSignatureCanvas" }}
                      />
                    </div>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      disabled={loading}
                      loading={loading}
                      color="green"
                      onClick={save}
                    >
                      <Icon name="save" /> Save
                    </Button>
                    <Button color="yellow" onClick={clear}>
                      <Icon name="eraser" /> Clear
                    </Button>
                    <Button onClick={() => setModalOpen(false)}>
                      <Icon name="remove" /> Close
                    </Button>
                  </Modal.Actions>
                </Modal>
              )}
            </Grid.Column>
            <Grid.Column width={8}>
              <Table definition basic compact="very" style={{ maxWidth: 400 }}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Approximate Project Duration</Table.Cell>
                    <Table.Cell>
                      {summary.WorkDays ? summary.WorkDays + " Days" : ""}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Net Payment Due</Table.Cell>
                    <Table.Cell>30 Days</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Retention</Table.Cell>
                    <Table.Cell>
                      {!summary.Retention && summary.Retention !== 0
                        ? ""
                        : summary.Retention + "%"}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Sales Tax</Table.Cell>
                    <Table.Cell>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(summary.SalesTax)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Kansas Remodel Tax</Table.Cell>
                    <Table.Cell>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(summary.KansasRemodelTax)}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              width={16}
              style={{
                fontSize: 10,
              }}
            >
              {summary.Recipient === "client" &&
              summary.Exclusions &&
              summary.Exclusions.length > 0 ? (
                <ExclusionsMessage exclusions={summary.Exclusions} />
              ) : null}
              <Message size="mini">
                <Message.Header style={{ fontSize: 14 }}>
                  Contract Documents & General Conditions
                </Message.Header>
              </Message>

              <ContractRequirements summary={summary} />
              {/* <div className="exclusions">
                <p>
                  <sup>1</sup> Only the items specifically listed above are
                  included in the pricing.
                </p>
                <p>
                  <sup>2</sup> Additional items are subject to additional cost
                </p>
                <p>
                  <sup>3</sup> Bonds (unless specifically express otherwise in
                  the scope above)
                </p>
                <p>
                  <sup>4</sup> Overtime (unless specifically express otherwise
                  in the scope above)
                </p>
                <p>
                  <sup>5</sup> Phasing (unless specifically express otherwise in
                  the scope above)
                </p>
                <p>
                  <sup>6</sup> Permits (unless specifically express otherwise in
                  the scope above)
                </p>
                <p>
                  <sup>7</sup> Dumpsters (unless specifically express otherwise
                  in the scope above)
                </p>
              </div>
              <Message size="mini">
                <Message.Header style={{ fontSize: 14 }}>
                  Conditions
                </Message.Header>
              </Message>
              <div className="exclusions">
                <p>
                  <sup>1</sup> A signed proposal/contract will be required prior
                  to the procurement of any materials, dispatch of any labor and
                  engaging with any subcontractors or vendors.
                </p>
                <p>
                  <sup>2</sup> All work to be completed in a workman like manor
                  according to standard practices.
                </p>
                <p>
                  <sup>3</sup> Any alterations or deviation from the
                  specifications involving additional costs will be executed
                  only upon a written/ signed change order and will increase the
                  total contract amount
                </p>
                <p>
                  <sup>4</sup> Any changes requested after installation by
                  Service Technologies shall incur additional charges
                </p>
                <p>
                  <sup>5</sup> Any additions to the scope of work, or work
                  having to be performed more than once due to defective
                  materials not supplied by Nexus 5 Group will be completed on a
                  cost plus basis.
                </p>
                <p>
                  <sup>6</sup> All work progress is contingent upon weather
                  and/or delays beyond our control. Delays may result in
                  additional days needed to complete the work and additional
                  charges if the delays cause multiple mobilizations.
                </p>
                <p>
                  <sup>7</sup> If issues concerning asbestos, lead, mold, or
                  other unabated safety hazards arise, additional charges may be
                  written as a change order if Nexus 5 Group is the contractor
                  to remedy the issue or is affected in any way as it pertains
                  to cost including, but limited to, multiple mobilization.
                </p>
                <p>
                  <sup>8</sup> This proposal is predicated on the existing
                  surrounding structures, i.e. floors, joists, walls, etc., and
                  the existing collateral and connecting structures remaining
                  intact when modifications are being made. In the event that
                  additional extensive work needs to be completed to bolster
                  these structures or to alter, repair or renovate these
                  structures to comply with updated building codes, extra
                  charges will be incurred. Also, this price does not include
                  repairing or patching any gypsum board, walls, ceilings or
                  flooring work that might be unseen due to such situations. No
                  labor or materials are included in the price beyond that which
                  is described in the scope above. Additional costs for
                  unforeseen conditions behind walls, underground, hazardous
                  materials or municipal code changes are not included in this
                  proposal.
                </p>
                <p>
                  <sup>9</sup> Payment is due Net 30 upon receipt of all
                  invoices including progress billing. If collection procedures
                  are necessary to obtain payment, the owner will be responsible
                  for costs of collection, including interests and attorney's
                  fees.
                </p>
                <p>
                  <sup>10</sup> If you have any questions with this proposal,
                  please do not hesitate to contact us
                </p>
                <p>
                  <sup>11</sup> Service Technologies reserves the right to
                  withdraw this proposal or change order if not signed as
                  accepted within 15 days of delivery.
                </p>
                <p>
                  <sup>12</sup> Any cancelation directive to a signed (as
                  accepted) proposal or change order will incur the cost plus of
                  any completion of the scopes, including but not limited to,
                  mobilization.
                </p>
                <p>
                  <sup>13</sup> All labor and material shall have a one-year
                  warranty upon substantial completion. Warranty does not
                  include any items outside of manufacture or craftsman defects
                  which may include, but not limited to, misuse, severe weather,
                  unforeseen stability or maintenance neglect.
                </p>
                <p>
                  <sup>14</sup> Compaction and cylinder testing are not included
                  for concrete repairs unless specifically proposed in the
                  quote.
                </p>
                <p>
                  <sup>15</sup> Upon final finish work on new concrete, the
                  property will be responsible for public alterations and
                  vandalism such as, but not limited to, markings and writing in
                  the new concrete. A roped or taped off barricade will be
                  supplied by Service Technologies during the time for curing,
                  however, no Service Technologies staff will be on site to
                  watch for public alterations and vandalism afterwards.
                </p>
                <p>
                  <sup>16</sup> Service Technologies will require to have a
                  designated lay down area for the demo debris and concrete
                  materials including some equipment if necessary. This will
                  need to be coordinated prior to work being performed.
                </p>
                <p>
                  <sup>17</sup> Standard back fill along the new concrete
                  sections will be filled with the existing dirt and seeded
                  unless otherwise noted above. Typically, many existing
                  sections have insufficient fill along walkways and lots. If
                  you are needing additional fill, please contact your Service
                  Technologies project manager to discuss options and pricing.
                </p>
                <p>
                  <sup>18</sup> All watering, barricades, and/or alternate
                  routes around the newly seeded areas will be the
                  responsibility of the property after seeding unless otherwise
                  noted above.
                </p>
                <p>
                  <sup>19</sup> Exclusions for concrete projects are, unless
                  otherwise noted in the inclusions, ADA signage, detectable ADA
                  pavers, asphalt paving, irrigation relocates & repairs,
                  landscape lighting, engineering, engineered traffic control,
                  concrete & soil testing’s, sodding or seeding, caulking,
                  relocating existing utilities, sub-base
                  stabilization/undercutting of unsuitable soils, erosion
                  control, damages due to unmarked or mis-located utilities,
                  access/temporary roads to site and crack repair not caused by
                  Service Technologies per ACI 224R, 224.1R, &224.2R
                </p>
                <p>
                  <sup>20</sup> All new materials are to be matched as closely
                  as possible and are not guaranteed to match exactly. Any
                  material that might be in question of matching must be
                  requested as a submittal by the client. Submittals may incur
                  additional days to the schedule including the submittal
                  process duration and procurement of accepted materials
                </p>
                <p>
                  <sup>21</sup> Any material that has been delivered or
                  installed and is in the controlling environment of the client,
                  i.e. after hours, phases or completion, cannot be warranted by
                  Service Technologies due to vandalism or theft.
                </p>
                <p>
                  <sup>22</sup>{" "}
                  <del>
                    If the situation occurs where outstanding invoices are not
                    paid in a timely manner, Service Tech reserves the right to
                    utilize a mechanics lien toward the owner of the property.
                    Mechanics liens will be placed on all properties, with
                    outstanding balances, 30 days prior to lien right
                    expiration.
                  </del>
                </p>
                <p>
                  <sup>23</sup> This proposal is confidential and proprietary
                  information and shall not be shared with other parties other
                  that who this proposal is addressed to.
                </p>
              </div> */}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
};

export default SummaryContract;
