export const surveyReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_INIT":
      return { ...state, isLoading: true };
    case "FETCH_SUCCESS":
      const questions = action.payload.questions || [];
      const info = action.payload.info || { hide: 1 };
      return { ...state, questions, info, isError: false, isLoading: false };
    case "FETCH_FAILURE":
      return { ...state, isLoading: false, isError: true };
    case "SET_VALUE":
      const id = action.payload.id;
      const value = action.payload.value;
      let newQuestions = state.questions.map((el) => {
        if (el.id === id) {
          return Object.assign({}, el, { value });
        }
        return el;
      });
      return { ...state, questions: [...newQuestions] };
    case "INVALID_URL":
      return { ...state, info: { hide: 1 } };
    case "SET_COMPLETE":
      return { ...state, success: true };
    default:
      throw new Error();
  }
};
