import React from "react";
import { Message } from "semantic-ui-react";

const ExclusionsMessage = ({ exclusions }) => {
  return (
    <Message
      style={{
        fontSize: 14,
        backgroundColor: "lightyellow",
        textAlign: "left",
        marginBottom: 60,
      }}
    >
      <Message.Header style={{ textAlign: "left" }}>
        Clarifications/Exclusions
      </Message.Header>
      <p dangerouslySetInnerHTML={{ __html: exclusions }}></p>
    </Message>
  );
};

export default ExclusionsMessage;
