import React, { useRef } from "react";
import { HotTable } from "@handsontable/react";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";

const InclusionClientHOT = props => {
  const hotEl = useRef(null);

  return (
    <HotTable
      id={"hot"}
      ref={hotEl}
      style={{ marginRight: 60 }}
      licenseKey="2d7bf-c2d78-d2021-a4e39-19b58"
      settings={{
        colWidths: [10],
        colHeaders: ["Contract", "ReadOnlyInd", "ContractID", "Description"],
        wordWrap: true,
        hiddenColumns: {
          columns: [1, 2],
          indicators: false
        },
        columns: [
          {
            type: "text",
            data: "ContractType",
            readOnly: true
          },
          {
            type: "numeric",
            data: "ReadOnlyInd"
          },
          {
            type: "numeric",
            data: "ContractID"
          },
          {
            type: "text",
            data: "Description"
          }
        ],
        rowHeaders: false,
        autoColumnSize: true,
        width: 800,
        height: 200,
        data: props.inclusionDescriptions,
        stretchH: "all",
        afterChange: (changes, source) => {
          if (source === "loadData") {
            return;
          }

          changes.forEach(([row, prop, oldValue, newValue]) => {
            if (newValue === oldValue) {
              return;
            }
            const rowData = hotEl.current.hotInstance.getDataAtRow(row);

            const contractID = rowData[2];
            const description = newValue;

            Axios.post(`${API_ROOT}/api/inclusion/setdescription`, {
              params: {
                projectID: parseInt(props.projectID),
                contractID,
                subCategoryCode: props.subCategoryCode,
                description
              }
            })
              .then(res => {
                let missingDescription = false;

                if (res.data.inclusionDescriptions) {
                  res.data.inclusionDescriptions.forEach(item =>
                    !item.Description ? (missingDescription = true) : null
                  );
                }

                props.setMissingInclusionDescription(missingDescription);
              })
              .catch(err => {
                console.log(err);
              });
          });
        },
        cells: (row, col, prop) => {
          const cellProperties = {};
          if (hotEl.current) {
            const readOnlyInd = hotEl.current.hotInstance.getDataAtRow(row)[1];
            if (readOnlyInd === 1) {
              cellProperties.readOnly = true;
            }

            return cellProperties;
          }
        }
      }}
    />
  );
};

export default InclusionClientHOT;
