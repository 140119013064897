import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Message,
  Table,
  Button,
  Modal,
  Form,
  Icon,
  Segment,
  Dimmer,
  Loader,
  Confirm,
} from "semantic-ui-react";
import N5Logo from "./N5Group.png";
import { useLocation } from "react-router-dom";
import FourOhFour from "../404/404";
import Axios from "axios";
import { API_ROOT } from "../../api-config";

import { getBaseUrl, sendEmailBccGroup } from "../../util";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const isGuid = (stringToTest) => {
  if (stringToTest[0] === "{") {
    stringToTest = stringToTest.substring(1, stringToTest.length - 1);
  }
  var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi;
  return regexGuid.test(stringToTest);
};

// Create our number formatter.
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const PaymentApplicationApproval = () => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [guid, setGuid] = useState(null);
  const [subTotals, setSubTotals] = useState(null);
  const [grandTotals, setGrandTotals] = useState(null);

  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [rejectionMessage, setRejectionMessage] = useState("");
  const [rejectionLoading, setRejectionLoading] = useState(false);

  const [confirmOpen, setConfirmOpen] = useState(false);

  let query = useQuery();

  const id = query.get("i");

  const getData = (id) => {
    let sub = `${API_ROOT}/payapp/payment-application-approval-subtotals?guid=${id}`;
    let grand = `${API_ROOT}/payapp/payment-application-approval-grand-totals?guid=${id}`;

    const requestSub = Axios.get(sub);
    const requestGrand = Axios.get(grand);
    Axios.all([requestSub, requestGrand])
      .then(
        Axios.spread((...responses) => {
          const responseSub = responses[0];
          const responseGrand = responses[1];
          setSubTotals(
            responseSub.data.payAppApprovalSubtotals
              ? responseSub.data.payAppApprovalSubtotals
              : null
          );
          setGrandTotals(
            responseGrand.data
              ? responseGrand.data.payAppApprovalGrandTotals
              : null
          );
          setLoading(false);
        })
      )
      .catch((errors) => {
        console.log(errors);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (grandTotals) {
      setStatus(grandTotals.Status);
    }
  }, [grandTotals]);

  useEffect(() => {
    if (id && isGuid(id)) {
      setGuid(id);
      getData(id);
    } else {
      setLoading(false);
    }
  }, []);

  const handleClose = () => {
    setRejectModalOpen(false);
    setRejectionMessage("");
  };

  const handleOpen = () => {
    setRejectModalOpen(true);
    setRejectionMessage("");
  };

  const handleMessageChange = (e, { value }) => {
    setRejectionMessage(value);
  };

  const baseUrl = getBaseUrl();
  const projectID = grandTotals ? grandTotals.projectID : "";
  const payAppType = grandTotals ? grandTotals.paymentApplicationType : "";
  const projectName = grandTotals ? grandTotals.projectName : "";
  const workLocationName = grandTotals ? grandTotals.workLocationName : "";
  const projectDescription = grandTotals ? grandTotals.projectDescription : "";
  const PMEmail = grandTotals ? grandTotals.PMEmail : "";

  const handleRejectButton = () => {
    setRejectionLoading(true);
    const message = rejectionMessage;
    // const payAppType = grandTotals ? grandTotals.paymentApplicationType : "";
    // const projectName = grandTotals ? grandTotals.projectName : "";
    // const PMEmail = grandTotals ? grandTotals.PMEmail : "";
    // const bcc = "SoftwareDevelopment@cohenesrey.com";
    // const baseUrl = getBaseUrl();
    // const projectID = grandTotals ? grandTotals.projectID : "";

    Axios.post(`${API_ROOT}/payapp/payment-application-rejected`, {
      params: {
        guid,
      },
    })
      .then((res) => {
        setRejectionLoading(false);
        setLoading(true);
        getData(guid);
        handleClose();
        sendEmailBccGroup(
          "Pay App Notifications",
          PMEmail,
          "",
          `Pay ${payAppType} for ${projectName} has been Rejected`,
          "",
          `<p>Pay ${payAppType} for ${projectName} has been rejected. The client attached this message: </p><p>${message}</p><p>To view the payment application details, <a href="${baseUrl}/browser/projects/${projectID}?i=6">click here</a></p>`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleApproveButton = () => {
    // const payAppType = grandTotals ? grandTotals.paymentApplicationType : "";
    // const projectName = grandTotals ? grandTotals.projectName : "";
    // const PMEmail = grandTotals ? grandTotals.PMEmail : "";
    // const bcc = "SoftwareDevelopment@cohenesrey.com";
    // const baseUrl = getBaseUrl();
    // const projectID = grandTotals ? grandTotals.projectID : "";

    Axios.post(`${API_ROOT}/payapp/payment-application-approved`, {
      params: {
        guid,
      },
    })
      .then((res) => {
        setConfirmOpen(false);
        setLoading(true);
        getData(guid);

        sendEmailBccGroup(
          "Pay App Notifications",
          PMEmail,
          "",
          `Pay ${payAppType} for ${projectName} has been Approved`,
          "",
          `<p>Pay ${payAppType} for ${projectName} has been approved by the client. To view the payment application details, <a href="${baseUrl}/browser/projects/${projectID}?i=6">click here</a></p>`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const header = `Payment Application for ${projectName} — ${workLocationName}: ${projectDescription}`;

  return loading ? (
    <Segment style={{ height: "100vh" }}>
      <Dimmer inverted active>
        <Loader size="big" content="Loading" />
      </Dimmer>
    </Segment>
  ) : Array.isArray(subTotals) && subTotals.length && grandTotals ? (
    <Container
      fluid
      style={{ overflow: "auto", padding: 20, height: "100vh" }}
      className="PaymentApplicationApprovalWrapper"
    >
      <p>
        <Image src={N5Logo} centered size="medium" alt="Nexus 5 Logo" />
      </p>
      <Message
        style={{ minWidth: 757 }}
        color="blue"
        // header="header"
        // content={
        //   status === "Preparing"
        //     ? "Payment Application is being prepared."
        //     : status === "Approved" || status === "Paid"
        //     ? "Payment Application has been approved"
        //     : "Please review the payment application below. If everything looks good, click the 'Approve' button below. If there is an error or an issue that needs to be corrected, click 'Reject' below and provide an explanation."
        // }
        attached
      >
        <h4>{header}</h4>
        {status === "Preparing" ? (
          <div></div>
        ) : status === "Approved" || status === "Paid" ? (
          "Payment Application has been approved"
        ) : (
          "Please review the payment application below. If everything looks good, click the 'Approve' button below. If there is an error or an issue that needs to be corrected, click 'Reject' below and provide an explanation."
        )}
      </Message>
      <Table compact="very" attached structured unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center" rowSpan="2">
              Description of Work
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" rowSpan="2">
              Original Scheduled Value
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" rowSpan="2">
              Change Orders
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" rowSpan="2">
              Revised Scheduled Values
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan="2">
              Work Completed
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" rowSpan="2">
              Total Completed
            </Table.HeaderCell>
            {/* <Table.HeaderCell textAlign="center" rowSpan="2">
              %
            </Table.HeaderCell> */}
            <Table.HeaderCell textAlign="center" rowSpan="2">
              Balance to Finish
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
              From Previous Application
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">This Period</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {subTotals.map((category) => (
            <React.Fragment key={category.mainCategoryID}>
              <Table.Row>
                <Table.Cell
                  style={{
                    color: "rgb(254, 80, 0)",
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                >
                  {category.categoryCode.toUpperCase()}
                </Table.Cell>
                <Table.Cell textAlign="center"></Table.Cell>
                <Table.Cell textAlign="center"></Table.Cell>
                <Table.Cell textAlign="center"></Table.Cell>
                <Table.Cell textAlign="center"></Table.Cell>
                <Table.Cell textAlign="center"></Table.Cell>
                <Table.Cell textAlign="center"></Table.Cell>
                {/* <Table.Cell textAlign="center"></Table.Cell> */}
                <Table.Cell textAlign="center"></Table.Cell>
              </Table.Row>
              {category.subCategories.map((subcategory) => (
                <Table.Row key={subcategory.subCategoryCode}>
                  <Table.Cell style={{ paddingLeft: 20 }}>
                    {subcategory.subCategoryCode === "Total"
                      ? ""
                      : subcategory.subCategoryCode}
                  </Table.Cell>
                  <Table.Cell
                    style={
                      subcategory.subCategoryCode === "Total"
                        ? { color: "blue", fontWeight: "bold" }
                        : {}
                    }
                    textAlign="center"
                  >
                    {formatter.format(subcategory.base)}
                  </Table.Cell>
                  <Table.Cell
                    style={
                      subcategory.subCategoryCode === "Total"
                        ? { color: "blue", fontWeight: "bold" }
                        : {}
                    }
                    textAlign="center"
                  >
                    {formatter.format(subcategory.approvedChanges)}
                  </Table.Cell>
                  <Table.Cell
                    style={
                      subcategory.subCategoryCode === "Total"
                        ? { color: "blue", fontWeight: "bold" }
                        : {}
                    }
                    textAlign="center"
                  >
                    {formatter.format(subcategory.revisedValues)}
                  </Table.Cell>
                  <Table.Cell
                    style={
                      subcategory.subCategoryCode === "Total"
                        ? { color: "blue", fontWeight: "bold" }
                        : {}
                    }
                    textAlign="center"
                  >
                    {formatter.format(subcategory.previousPeriod)}
                  </Table.Cell>
                  <Table.Cell
                    style={
                      subcategory.subCategoryCode === "Total"
                        ? { color: "blue", fontWeight: "bold" }
                        : {}
                    }
                    textAlign="center"
                  >
                    {formatter.format(subcategory.thisPeriod)}
                  </Table.Cell>
                  <Table.Cell
                    style={
                      subcategory.subCategoryCode === "Total"
                        ? { color: "blue", fontWeight: "bold" }
                        : {}
                    }
                    textAlign="center"
                  >
                    {formatter.format(subcategory.totalCompleted)}
                  </Table.Cell>
                  {/* <Table.Cell
                    style={
                      subcategory.subCategoryCode === "Total"
                        ? { color: "blue", fontWeight: "bold" }
                        : {}
                    }
                    textAlign="center"
                  >
                    {subcategory.pctComplete.toFixed(2) + "%"}
                  </Table.Cell> */}
                  <Table.Cell
                    style={
                      subcategory.subCategoryCode === "Total"
                        ? { color: "blue", fontWeight: "bold" }
                        : {}
                    }
                    textAlign="center"
                  >
                    {formatter.format(subcategory.balanceToFinish)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </React.Fragment>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell style={{ fontWeight: "bold" }}>
              GRAND TOTALS
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ color: "purple", fontWeight: "bold" }}
              textAlign="center"
            >
              {formatter.format(grandTotals.base)}
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ color: "purple", fontWeight: "bold" }}
              textAlign="center"
            >
              {formatter.format(grandTotals.approvedChanges)}
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ color: "purple", fontWeight: "bold" }}
              textAlign="center"
            >
              {formatter.format(grandTotals.revisedValues)}
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ color: "purple", fontWeight: "bold" }}
              textAlign="center"
            >
              {formatter.format(grandTotals.previousPeriod)}
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ color: "purple", fontWeight: "bold" }}
              textAlign="center"
            >
              {formatter.format(grandTotals.thisPeriod)}
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ color: "purple", fontWeight: "bold" }}
              textAlign="center"
            >
              {formatter.format(grandTotals.totalCompleted)}
            </Table.HeaderCell>
            {/* <Table.HeaderCell
              style={{ color: "purple", fontWeight: "bold" }}
              textAlign="center"
            >
              {grandTotals.pctComplete.toFixed(2) + "%"}
            </Table.HeaderCell> */}
            <Table.HeaderCell
              style={{ color: "purple", fontWeight: "bold" }}
              textAlign="center"
            >
              {formatter.format(grandTotals.balanceToFinish)}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <Message attached="bottom" warning style={{ minWidth: 757 }}>
        {status === "Preparing" ? (
          <span>Payment Application is being prepared.</span>
        ) : status === "Approved" ||
          status === "Paid" ||
          status === "Invoiced" ? (
          <span
            style={{
              color: "green",
              fontSize: 48,
              fontWeight: "bold",
              display: "inline-block",
              paddingTop: 10,
              paddingBottom: 20,
            }}
          >
            Thank you!
          </span>
        ) : (
          <Button.Group>
            <Button
              // loading={approvalLoading}
              onClick={() => setConfirmOpen(true)}
              positive
            >
              Approve
            </Button>
            <Button.Or />
            <Button onClick={handleOpen}>Reject</Button>
          </Button.Group>
        )}
      </Message>
      {/* Rejection Modal */}
      <Modal
        size="small"
        open={rejectModalOpen}
        onClose={handleClose}
        onOpen={handleOpen}
      >
        <Modal.Header>Reject Payment Application</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.TextArea
              required
              onChange={handleMessageChange}
              value={rejectionMessage}
              label="Please provide a rejection reason"
              rows="7"
            ></Form.TextArea>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button icon onClick={handleClose} color="yellow">
            <Icon name="close" /> Cancel
          </Button>
          <Button
            loading={rejectionLoading}
            onClick={handleRejectButton}
            disabled={rejectionMessage.length < 5}
            negative
          >
            <Icon name="send" /> Send Rejection
          </Button>
        </Modal.Actions>
      </Modal>

      <Confirm
        size="tiny"
        open={confirmOpen}
        onConfirm={handleApproveButton}
        onCancel={() => setConfirmOpen(false)}
        content="Are you sure you want to approve this payment application?"
        confirmButton="Yes"
      />
    </Container>
  ) : (
    <FourOhFour />
  );
};

//   if (guid) {
//     return (

//     );
//   } else {
//     return <FourOhFour />;
//   }
// };

export default PaymentApplicationApproval;
