import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Menu, Dropdown } from "semantic-ui-react";
import Axios from "axios";
import { connect } from "react-redux";
import { API_ROOT } from "../../../../api-config";

import {
  setInclusionMenuSelection,
  addCondition,
} from "../../../../actions/project/inclusion/inclusionActions";

let defaultSubCategoryOptions = [];

class Inclusions extends Component {
  _isMounted = false;

  state = {
    contractOptions: [],
    categoryOptions: [],
    subCategoryOptions: [],
    selectedCategoryID: "",
    selectedSubCategoryCode: "",
    projectID: parseInt(this.props.match.params.id),
    conditionOptions: [],
    conditionValue: "",
    menuFixed: false,
    project: {},
  };

  fetchProject() {
    Axios.get(
      `${API_ROOT}/api/project/project?projectID=${this.state.projectID}`
    )
      .then((res) => {
        const project = res.data.projects[0];
        if (this._isMounted) {
          this.setState({ project });
        }
      })
      .catch((err) => console.log(err));
  }

  componentDidMount() {
    this._isMounted = true;
    // this.getOptions();
    this.setSelectedOptions();
  }

  setSelectedOptions = () => {
    this.setState(
      {
        project: this.state.project,
        selectedCategoryID:
          this.props.inclusion.selectedCategoryID === 0
            ? 0
            : this.props.inclusion.selectedCategoryID
            ? this.props.inclusion.selectedCategoryID
            : "",
        selectedSubCategoryCode:
          this.props.inclusion.selectedSubCategoryCode === 0
            ? 0
            : this.props.inclusion.selectedSubCategoryCode
            ? this.props.inclusion.selectedSubCategoryCode
            : "",
      },
      () => {
        this.getOptions();
      }
    );
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    this.abortController.abort();
  };

  abortController = new window.AbortController();

  getOptions = () => {
    // Get inclusion category options
    Axios.get(`${API_ROOT}/api/project/inclusioncategoryoptions`, {
      signal: this.abortController.signal,
    })
      .then((res) => {
        if (res.data && this._isMounted) {
          defaultSubCategoryOptions =
            res.data.inclusionCategoryOptions[0].subCategories;
          this.setState(
            {
              categoryOptions: res.data.inclusionCategoryOptions[0].categories,
            },
            () => {
              if (
                this.state.selectedCategoryID &&
                this.state.selectedSubCategoryCode
              ) {
                this.getConditionOptions();
              }
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getConditionOptions = () => {
    Axios.get(`${API_ROOT}/api/condition/options`, {
      params: {
        projectID: this.state.projectID,
        subCategoryCode: this.state.selectedSubCategoryCode,
      },
      signal: this.abortController.signal,
    })
      .then((res) => {
        if (this._isMounted && res.data.options) {
          this.setState({
            conditionOptions: res.data.options,
          });
        } else {
          this.setState({ conditionOptions: [] });
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          return;
        }
        console.log(error);
      });
  };

  fireDispatch = () => {
    // console.log(this.state)
    const { selectedCategoryID, selectedSubCategoryCode } = this.state;

    if (
      (selectedCategoryID || selectedCategoryID === 0) &&
      (selectedSubCategoryCode || selectedSubCategoryCode === 0)
    ) {
      this.getConditionOptions();
    }
    this.props.dispatch(
      setInclusionMenuSelection({
        selectedCategoryID,
        selectedSubCategoryCode,
      })
    );
  };

  // CREATE THIS NEXT!!!
  handleCategoryChange = (e, { value }) => {
    if (this._isMounted) {
      this.setState(
        {
          selectedCategoryID: value,
          selectedSubCategoryCode: "",
        },
        () => {
          this.fireDispatch();
        }
      );
    }
  };

  handleSubCategoryChange = (e, { value }) => {
    // console.log(value);
    this.setState(
      {
        selectedSubCategoryCode: value,
      },
      () => {
        // check if all three are selected

        this.fireDispatch();
      }
    );
  };

  handleConditionClick = (e, { value }) => {
    const conditionID = value;
    const { projectID, selectedSubCategoryCode } = this.state;

    // Create new condition
    this.props.dispatch(
      addCondition(conditionID, projectID, selectedSubCategoryCode)
    );
    this.setState({
      conditionValue: "",
    });
  };

  handleCloseClick = () => {
    const { projectID } = this.state;
    if (this.props.returnUrl) this.props.history.push(this.props.returnUrl);
    else this.props.history.push(`/browser/projects/${projectID}?i=1`);
  };

  stickTopMenu = () => this.setState({ menuFixed: true });
  unStickTopMenu = () => this.setState({ menuFixed: false });

  render() {
    // Filter dropdown options according to selection
    let {
      selectedCategoryID,
      selectedSubCategoryCode,
      categoryOptions,
      conditionOptions,
    } = this.state;

    // if (!selectedContractID) {
    //   categoryOptions = [];
    // }
    return (
      <React.Fragment>
        <Dropdown
          placeholder="Main Category"
          selectOnBlur={false}
          onChange={this.handleCategoryChange}
          value={selectedCategoryID}
          item
          search
          scrolling
          options={categoryOptions}
        />
        {/* </Menu.Item> */}

        {/* <Menu.Item> */}
        {/* <label>Subcategory:&nbsp;</label> */}
        <Dropdown
          placeholder="Subcategory"
          selectOnBlur={false}
          value={selectedSubCategoryCode}
          onChange={this.handleSubCategoryChange}
          item
          search
          scrolling
          options={defaultSubCategoryOptions.filter(
            (option) => option.categoryid === selectedCategoryID
          )}
        />

        {(selectedCategoryID || selectedCategoryID === 0) &&
        (selectedSubCategoryCode || selectedSubCategoryCode === 0) &&
        conditionOptions ? (
          <Dropdown
            text="Add Condition"
            scrolling
            selectOnBlur={false}
            item
            value={this.state.conditionValue}
            onChange={this.handleConditionClick}
            options={conditionOptions}
          />
        ) : (
          ""
        )}
        <Menu.Item onClick={this.handleCloseClick}>Close</Menu.Item>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // project: state.project.projects.find(
    //   (item) => item.ProjectID === parseInt(ownProps.match.params.id)
    // ),
    returnUrl: state.inclusion.returnUrl,
    inclusion: state.inclusion,
    selectedCategoryID: state.inclusion.selectedCategoryID,
    selectedSubCategoryID: state.inclusion.selectedSubCategoryID,
  };
};

export default withRouter(connect(mapStateToProps)(Inclusions));
