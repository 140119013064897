import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Table } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../../../api-config";
import moment from "moment";

const LaborTimeModal = ({
  conditionID,
  open,
  setOpen,
  date,
  project,
  userID,
  projectID,
  category,
  conditionName,
  fetchProjectInfo,
  conditionInfo,
  fetchConditionInfo,
}) => {
  const [loading, setLoading] = useState(false);
  const [regularHours, setRegularHours] = useState("");
  const [overtimeHours, setOvertimeHours] = useState("");
  const [estimateToCompleteHours, setEstimateToCompleteHours] = useState("");

  const handleOpen = () => {
    // fetchAssignmentHours();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setRegularHours("");
    setOvertimeHours("");
    setEstimateToCompleteHours("");
  };

  const fetchAssignmentHours = () => {
    Axios.get(`${API_ROOT}/api/project/assignment-hours-by-condition`, {
      params: {
        conditionID,
        userID,
        date,
      },
    })
      .then((res) => {
        setRegularHours(
          res.data.actualHoursRegular || res.data.actualHoursRegular === 0
            ? res.data.actualHoursRegular
            : ""
        );
        setOvertimeHours(
          res.data.actualHoursOT || res.data.actualHoursOT === 0
            ? res.data.actualHoursOT
            : ""
        );
        setEstimateToCompleteHours(
          res.data.estimateToCompleteHours ||
            res.data.estimateToCompleteHours === 0
            ? res.data.estimateToCompleteHours
            : ""
        );
      })
      .catch((err) => console.log(err));
  };

  // useEffect(() => {
  //   if (conditionID) {
  //     fetchAssignmentHours();
  //   }
  // }, [conditionID]);

  useEffect(() => {
    if (open && conditionID) {
      fetchAssignmentHours();
    }
  }, [open, conditionID]);

  const handleSave = () => {
    setLoading(true);
    Axios.post(`${API_ROOT}/api/project/assignment-task`, {
      userID,
      conditionID,
      date,
      regularHours:
        !!regularHours || regularHours === 0 ? parseFloat(regularHours) : null,
      overtimeHours:
        !!overtimeHours || overtimeHours === 0
          ? parseFloat(overtimeHours)
          : null,
      estimateToCompleteHours:
        !!estimateToCompleteHours || estimateToCompleteHours === 0
          ? parseFloat(estimateToCompleteHours)
          : null,
    })
      .then((res) => {
        fetchProjectInfo();
        setLoading(false);
        setOpen(false);
        resetForm();
        fetchConditionInfo();
      })
      .catch((err) => {
        // setError(true);
        setLoading(false);

        console.log(err);
      });
    // handleClose();
  };

  return projectID && userID && date ? (
    <Modal
      size="small"
      dimmer="blurring"
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Modal.Header>{`${moment(date).format(
        "l"
      )} | Log Labor Time`}</Modal.Header>
      <Modal.Content>
        <h4 style={{ color: "#2185d0" }}>
          {`${project.projectID} - ${project.companyName}`}
          <br />
          {`${project.projectName}`}
        </h4>
        <h4 style={{ color: "rgba(254, 80, 0, 0.8)" }}>
          {category} - {conditionName ? conditionName : ""}
        </h4>
        <Table compact="very" basic unstackable>
          <Table.Body>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold" }}>
                Est. Labor (Hrs)
              </Table.Cell>
              <Table.Cell>
                {conditionInfo && conditionInfo.laborHoursTotal
                  ? conditionInfo.laborHoursTotal.toFixed(2)
                  : (0).toFixed(2)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold", color: "red" }}>
                Remaining Labor
              </Table.Cell>
              <Table.Cell style={{ color: "red" }}>
                {conditionInfo && conditionInfo.laborHoursRemaining
                  ? conditionInfo.laborHoursRemaining.toFixed(2)
                  : (0).toFixed(2)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Form>
          <Form.Input
            value={regularHours}
            onChange={(e, { value }) => setRegularHours(value)}
            type="number"
            label="Regular hours"
          />
          <Form.Input
            value={overtimeHours}
            onChange={(e, { value }) => setOvertimeHours(value)}
            type="number"
            label="Overtime hours"
          />
          <Form.Input
            value={estimateToCompleteHours}
            onChange={(e, { value }) => setEstimateToCompleteHours(value)}
            type="number"
            label="Estimate to complete hours"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="yellow" onClick={handleClose}>
          Close
        </Button>
        <Button loading={loading} primary onClick={handleSave}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  ) : null;
};

export default LaborTimeModal;
