import { useSelector } from "react-redux";

export const useLoggedInUser = (field = null) => {
  const user = useSelector((state) =>
    state.auth.user ? JSON.parse(state.auth.user)[0] : null
  );

  if (user === null) return null;
  if (field === null) return user;

  if (user[field] === undefined) return null;

  return user[field];
};
