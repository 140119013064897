import React, { Fragment } from "react";
import {
  Container,
  Grid,
  Table,
  Icon,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import SecurityRolesModal from "./SecurityRolesModal/SecurityRolesModal";

const SecurityRoles = ({
  roles,
  loading,
  open,
  setOpen,
  getRoleByID,
  users,
  roleName,
  displayNames,
  roleID,
  deleteUserRole,
  addUserRole,
}) => {
  const handleRoleClick = (roleID, name) => {
    getRoleByID(roleID, name);
  };

  return !loading ? (
    <Fragment>
      <Container fluid>
        <Grid>
          <Grid.Row>
            <Grid.Column style={{ padding: 40 }}>
              <Table unstackable style={{ maxWidth: 600 }}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                    <Table.HeaderCell>Role Name</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {Array.isArray(roles) && roles.length
                    ? roles.map((role) => (
                        <Table.Row key={role.RoleID}>
                          <Table.Cell>
                            <Icon
                              link
                              name="magnify"
                              onClick={() =>
                                handleRoleClick(role.RoleID, role.Role)
                              }
                            />
                          </Table.Cell>
                          <Table.Cell>{role.Role}</Table.Cell>
                          <Table.Cell>{role.Description}</Table.Cell>
                        </Table.Row>
                      ))
                    : null}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <SecurityRolesModal
        open={open}
        setOpen={setOpen}
        users={users}
        roleName={roleName}
        displayNames={displayNames}
        roleID={roleID}
        deleteUserRole={deleteUserRole}
        addUserRole={addUserRole}
      />
    </Fragment>
  ) : (
    <Container>
      <Dimmer active inverted>
        <Loader size="big" inverted>
          Loading
        </Loader>
      </Dimmer>
    </Container>
  );
};

export default SecurityRoles;
