import React, { useEffect, useState } from "react";
import Notes from "./Notes";
import { useSelector } from "react-redux";
import { getUser } from "../../../../util";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";

const NotesWrapper = ({ projectID, projectInfo }) => {
  const user = useSelector((state) => getUser(state));
  // const user = useMemo(() => getUser(state));
  const [projectNotes, setProjectNotes] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (projectID && projectInfo && user) {
      getProjectNotes(projectID);
      getProjectNotifications(projectID);

      setIsAdmin(
        user.hasOwnProperty("Roles") && Array.isArray(user.Roles)
          ? user.Roles.some(
              (role) => role.Role === "Admin" || role.Role === "Superuser"
            ) ||
              projectInfo.projectManager === user.UserID ||
              projectInfo.projectEngineer === user.UserID ||
              projectInfo.superintendent === user.UserID
          : false
      );
    }
  }, [projectID, projectInfo.projectID, user.UserID]);

  const getProjectNotes = (projectID) => {
    Axios.get(`${API_ROOT}/api/project/projectnotes`, {
      params: {
        projectID,
      },
    })
      .then((res) => {
        setProjectNotes(
          res.data
            ? res.data.projectNotes
              ? JSON.parse(res.data.projectNotes)
              : []
            : []
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectNotifications = (projectID) => {
    Axios.get(`${API_ROOT}/api/project/projectnotifications/${projectID}`).then(
      (res) => {
        setNotifications(JSON.parse(res.data.notifications));
      }
    );
  };

  return (
    <Notes
      isAdmin={isAdmin}
      projectID={projectID}
      projectNotes={projectNotes}
      user={user}
      notifications={notifications}
      setProjectNotes={setProjectNotes}
    />
  );
};

export default NotesWrapper;
