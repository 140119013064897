import React, { Fragment, useEffect, useState } from "react";
import { Container, Icon, Input, Segment } from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
// import courseHistoryData from "./courseHistory";
import moment from "moment";
import numeral from "numeral";
import AddCourseModal from "./AddCourseModal/AddCourseModal";
import EditCourseModal from "./EditCourseModal/EditCourseModal";
import Axios from "axios";
import { useSelector } from "react-redux";
import CreateModal from "./CreateModal/CreateModal";
import { useLoggedInUser } from "../../Mobile/hooks";
// import Axios from "axios";

const percentageFormatter = (params) => {
  return numeral(params.data.score).format("0 %");
};

const dateFormatter = (params) => {
  return moment(params.data.date).format("L");
};

function dateComparator(date1, date2) {
  var date1Number = _monthToNum(date1);
  var date2Number = _monthToNum(date2);

  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }

  return date1Number - date2Number;
}

// HELPER FOR DATE COMPARISON
function _monthToNum(date) {
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }

  var yearNumber = date.substring(0, 4);
  var dayNumber = date.substring(9, 11);
  var monthNumber = date.substring(5, 7);

  var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
  // 29/08/2004 => 20040829
  return result;
}

const SafetyTrainingWrapper = () => {
  const [gridApi, setGridApi] = useState();
  const [rowData, setRowData] = useState();
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedCourseID, setSelectedCourseID] = useState("");
  const [selectedCourseDate, setSelectedCourseDate] = useState("");
  const [selectedCourseName, setSelectedCourseName] = useState("");
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const uid = useLoggedInUser("UserID");

  const getOptions = () => {
    Axios.get(
      "https://www.ce-knet.com/DesktopModules/XModPro/Feed.aspx?xfd=NS1_GetSafetyTrainingCourseList&pid=0",
      { withCredentials: false }
    ).then((res) => {
      setOptions(
        res.data.map((item) => {
          return {
            value: item.CourseID,
            text: item.CourseName,
          };
        })
      );
    });
  };

  useEffect(() => {
    getOptions();
  }, []);

  const getSafetyTrainingHistory = () => {
    Axios.get(
      "https://www.ce-knet.com/DesktopModules/XModPro/Feed.aspx?xfd=NS1_GetSafetyTrainingHistory&pid=0",
      { withCredentials: false }
    )
      .then((res) => {
        setRowData(res.data);
      })
      .catch((err) => {
        console.log(err);
        setRowData([]);
      });
  };

  useEffect(() => {
    getSafetyTrainingHistory();
  }, []);

  const handleClick = (params) => {
    // console.log(params.data);
    setSelectedCourseID(params.data.courseid);
    setSelectedCourseDate(new Date(moment(params.data.date).format("L")));
    setSelectedCourseName(params.data.coursename);
    setEditModalOpen(true);
  };

  const columnDefs = [
    {
      headerName: "",
      width: 100,
      field: "courseid",
      headerComponentFramework: () => {
        return (
          <Fragment>
            <span>
              <Icon
                title="Log Attendance"
                link
                size="large"
                name="pencil alternate"
                onClick={() => setAddModalOpen(true)}
              />
              <Icon
                title="Create New Course"
                link
                size="large"
                name="plus"
                onClick={() => setCreateModalOpen(true)}
              />
            </span>
          </Fragment>
        );
      },
      cellRendererFramework: function(params) {
        return (
          <span>
            <Icon
              size="large"
              title="View inspection"
              link
              onClick={() => handleClick(params)}
              name="search"
            />
            {/* <Icon
              size="large"
              title="Delete inspection"
              link
              onClick={() => handleDeleteClick(params.value)}
              name="close"
            /> */}
          </span>
        );
      },
    },
    {
      headerName: "Course",
      field: "coursename",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Date",
      field: "date",
      sortable: true,
      sort: "desc",
      filter: "agDateColumnFilter",
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: dateComparator,
      },
      valueFormatter: dateFormatter,
      filterValueGetter: dateFormatter,
      // valueGetter: dateFormatter,
    },
    {
      headerName: "Score",
      field: "score",
      sortable: true,
      filter: true,
      // valueGetter: percentageFormatter,
      valueFormatter: percentageFormatter,
    },
  ];

  const onFilterTextBoxChanged = (e, { value }) => {
    gridApi.setQuickFilter(value);
  };

  return (
    <Container
      style={{ paddingLeft: 10, paddingRight: 10 }}
      fluid
      className="SafetyTrainingWrapper"
    >
      <div>
        <h1 style={{ color: "rgb(254, 80, 0)", marginLeft: 40 }}>
          Training
        </h1>
        <Segment style={{ marginTop: -64 }} basic textAlign="right">
          <Input
            type="text"
            id="filter-text-box"
            placeholder="Filter..."
            onChange={onFilterTextBoxChanged}
          />
        </Segment>
      </div>
      <div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>
        <AgGridReact
          enableCellChangeFlash={true}
          columnDefs={columnDefs}
          rowData={rowData}
          rowSelection="single"
          // onRowSelected={(params) => {
          //   console.log(params);
          // }}
          onGridReady={(params) => {
            setGridApi(params.api);
            params.api.sizeColumnsToFit();
            window.onresize = () => {
              params.api.sizeColumnsToFit();
            };
            // setColumnApi(params.columnApi);
          }}
        />
      </div>
      <AddCourseModal
        addModalOpen={addModalOpen}
        setAddModalOpen={setAddModalOpen}
        uid={uid}
        options={options}
        getSafetyTrainingHistory={getSafetyTrainingHistory}
        gridApi={gridApi}
      />
      <EditCourseModal
        uid={uid}
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        selectedCourseID={selectedCourseID}
        selectedCourseDate={selectedCourseDate}
        selectedCourseName={selectedCourseName}
        getSafetyTrainingHistory={getSafetyTrainingHistory}
        gridApi={gridApi}
      />
      <CreateModal
        uid={uid}
        createModalOpen={createModalOpen}
        setCreateModalOpen={setCreateModalOpen}
        setOptions={setOptions}
      />
    </Container>
  );
};

export default SafetyTrainingWrapper;
