import {
  SET_TEAM_MEMBER_GOALS_MENU_SELECTION,
  RESET_TEAM_MEMBER_GOALS_MENU_SELECTION,
} from "../actions/topMenu/teamMemberGoalsMenuActions";
import * as moment from "moment";

const year = moment().year();

const INITIAL_DATA = {
  year,
  division: "Construction",
  role: "Project Manager",
};

const teamMembersGoalsMenuReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case SET_TEAM_MEMBER_GOALS_MENU_SELECTION:
      return {
        ...state,
        year: action.payload.year,
        division: action.payload.division,
        role: action.payload.role,
      };
    case RESET_TEAM_MEMBER_GOALS_MENU_SELECTION:
      return INITIAL_DATA;
    default:
      return state;
  }
};

export default teamMembersGoalsMenuReducer;
