import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react";

export default ({ projectID, value, saveValue }) => {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleBlur = () =>
    saveValue(projectID, "workLocationAddress", internalValue);

  const handleChange = (e, { value }) => setInternalValue(value);

  return (
    <Form.Input
      width={16}
      label={
        <label>
          Wo<span style={{ display: "none" }}>_</span>rk Add
          <span style={{ display: "none" }}>_</span>ress
        </label>
      }
      value={internalValue}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};
