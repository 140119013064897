import React from "react";

import { Image } from "semantic-ui-react";

import n5logo from "../../../components/ContractWrapper/N5Group.png";
import Contact from "./Contact";

export default ({ client, pm }) => {
  return (
    <div className="heading">
      <Contact data={client} />
      <Image
        fluid
        centered
        src={n5logo}
        style={{ maxWidth: 240 }}
        alt="Nexus 5 Group logo"
      />
      <Contact data={pm} />
    </div>
  );
};
