import React from "react";
import converter from "number-to-words";

export default ({ summary }) => {
  if (summary.contract_type !== "Base") return null;

  return (
    <div className="contract-requirements">
      <div className="concent-of-owner">
        <p>
          <strong>CONSENT OF OWNER:</strong>
        </p>
        <p>
          <strong>
            FOR PROJECTS LOCATED IN MISSOURI. CONSENT IS HEREBY GIVEN FOR FILING
            OF MECHANIC’S LIENS BY ANY PERSON WHO SUPPLIES MATERIALS OR SERVICES
            FOR THE WORK DESCRIBED IN THIS CONTRACT ON THE PROPERTY ON WHICH IT
            IS LOCATED IF HE IS NOT PAID.
          </strong>
        </p>
      </div>
      <div className="ui mini message">
        <div className="header">
          Contract Documents &amp; General Conditions
        </div>
      </div>
      <div className="requirements">
        <p>The Client and Contractor agree as follows.</p>
        <p>
          <strong>1. THE CONTRACT DOCUMENTS </strong>
          The Contract Documents consist of this Agreement, Drawings,
          Specifications, Addenda issued prior to execution of this Agreement,
          other documents listed in this Agreement and Modifications issued
          after execution of this Agreement, all of which form the Contract, and
          are as fully a part of the Contract as if attached to this Agreement
          or repeated herein. The Contract represents the entire and integrated
          agreement between the parties hereto and supersedes prior
          negotiations, representations or agreements, either written or oral.
          An enumeration of the Contract Documents, other than a Modification,
          appears in Section 9.
        </p>
        <p>
          <strong>2. THE WORK OF THIS CONTRACT </strong>
          The Contractor shall fully execute the Work described in the Contract
          Documents, except as specifically indicated in the Contract Documents
          to be the responsibility of others.
        </p>
        <p>
          <strong>3. DATE OF COMMENCEMENT AND SUBSTANTIAL COMPLETION</strong>
        </p>
        <p>
          <strong>3.1</strong> The date of commencement of the Work shall be the
          date of this Agreement unless a different date is stated below or
          provision is made for the date to be fixed in a notice to proceed
          issued by the Client.
        </p>
        <p>
          <strong>3.2</strong> The Contract Time shall be measured from the date
          of commencement.
        </p>
        <p>
          <strong>3.3</strong> The Contractor shall achieve Substantial
          Completion of the entire Work not later than {`${summary.work_days}`}{" "}
          days from the date of commencement.
        </p>
        <p>
          <strong>4. CONTRACT AMOUNT</strong>
        </p>
        <p>
          <strong>4.1</strong> The Client shall pay the Contractor the Contract
          Sum in current funds for the Contractor’s performance of the Contract.
          The Contract Sum shall be{" "}
          <strong>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(summary.grand_total)}
          </strong>{" "}
          ({`${converter.toWords(summary.grand_total)}`}), subject to additions
          and deductions as provided in the Contract Documents.
        </p>
        <p>
          <strong>4.2</strong> Any Alternates, Unit Pricing, or Allowances that
          are included in this Contract Sum are listed in the attached proposal
          listed as Exhibit A.
        </p>
        <p>
          <strong>5. PROGRESS PAYMENTS</strong>
        </p>
        <p>
          <strong>5.1</strong> Provided an invoice is received by the Client not
          later than the first (1) day of a month, the Client shall make payment
          to the Contractor not later than the tenth (10) day of the same month.
          If an Invoice is received by the Client after the invoice date fixed
          above, payment shall be made by the Client not later than ten (10)
          days after the receipt of the current invoice.
        </p>
        <strong>5.2</strong> Each Invoice shall be based on the work completed
        in the current month. This work shall be described on the invoice.
        <p></p>
        <strong>5.3</strong> Invoices shall show the percentage of completion of
        each portion of the Work as of the end of the period covered by the
        Invoice.
        <p></p>
        <strong>5.4</strong> Except with the Client’s prior approval, the
        Contractor shall not make advance payments to suppliers for materials or
        equipment which have not been delivered and stored at the site.
        <p></p>
        <p>
          <strong>5.5</strong> The Client’s final payment to the Contractor
          shall be made no later than 10 days after the issuance of the final
          invoice and upon the Client’s acceptance of the work as described in
          this Contract.
        </p>
        <p>
          <strong>6. DISPUTE RESOLUTION </strong>
          For any Claim subject to, but not resolved by mediation, the method of
          binding dispute resolution shall be thru Arbitration.
        </p>
        <p>
          <strong>7. TERMINATION </strong>
          The Contract may be terminated by the Client or the Contractor for
          probable cause or convenience. If the Client terminates the contract
          for probable cause or convenience, the contractor shall be paid any
          and all cost for work completed prior to the termination plus the cost
          for demobilization.
        </p>
        <p>
          <strong>8. MISCELLANEOUS PROVISIONS</strong>
        </p>
        <p>
          <strong>8.1</strong> Payments due and unpaid under the Contract shall
          bear interest from the date payment is due at the rate stated below,
          or in the absence thereof, at the legal rate prevailing from time to
          time at the place where the Project is located.
          <br />
          Two percent (2%) above prime rate
        </p>
        <p>
          <strong>8.2</strong> Standard Exclusions:
        </p>
        <p>
          <strong>8.2.1</strong> Architectural Services
          <br />
          <strong>8.2.2</strong> Structural Engineering Services
          <br />
          <strong>8.2.3</strong> Civil Engineering Services
          <br />
          <strong>8.2.4</strong> Mechanical Engineering Services
          <br />
          <strong>8.2.5</strong> Plumbing Engineering Services
          <br />
          <strong>8.2.6</strong> Electrical Engineering Services
          <br />
          <strong>8.2.7</strong> Geotechnical Engineering Services
          <br />
          <strong>8.2.8</strong> Landscape Architectural Services
          <br />
          <strong>8.2.9</strong> Hazardous Material abatement
          <br />
          <strong>8.2.10</strong> Performance & Payment Bonds
          <br />
          <strong>8.2.11</strong> Builders’ Risk Insurance
          <br />
          <strong>8.2.12</strong> Mud Bonds
          <br />
          <strong>8.2.13</strong> Special Inspections
          <br />
          <strong>8.2.14</strong> Liquidated Damages
          <br />
          <strong>8.2.15</strong> Material increases due to material shortages,
          allocation, natural disasters, terrorist attacks, shifts in the
          economy, or acts of God.
          <br />
          <strong>8.2.16</strong> Any and all code upgrades
          <br />
          <strong>8.2.17</strong> Cost for any permits required
        </p>
        <p>
          <strong>8.3</strong> Project Specific Exclusions
        </p>
        <p>
          <strong>8.3.1</strong> {`${summary.exclusions}`}
        </p>
        <p>
          <strong>8.3.2</strong> Anything NOT specifically spelled out on the
          contract documents or the attached Nexus 5 Group Proposal.
        </p>
        <p>EPIDEMIC RIDER:</p>
        <p>
          Notwithstanding any provision(s) of this contract, if as a direct or
          indirect result of any virus, disease, contagion, including but not
          limited to COVID-19 (individually or collectively, “Epidemic”),
          Contractor’s work is delayed, disrupted, suspended, or otherwise
          impacted, including, but limited to, by (1) disruptions to material
          and/or equipment supply; (2) illness of Contractor’s workforce and/or
          unavailability of labor; (3) government quarantines, shelter-in-place
          orders, closures, or other mandates, restrictions, and/or directives;
          (4) Owner or Contractor restrictions and/or directives; and/or (5)
          fulfillment of Contractor’s contractual or legal health and safety
          obligations associated with an Epidemic; then Contractor shall be
          entitled to an equitable adjustment to the contract schedule and
          duration to account for such disruptions, suspensions, and impacts. To
          the extent any of the causes identified herein results in an increase
          in the price of labor, materials, or equipment used in the performance
          of this contract, or other costs of performance of the contract,
          Contractor shall be entitled to an equitable adjustment to the
          contract price for such increases, provided contractor presents
          documentation of such increases (including the original prices and/or
          estimates) and evidence of contractor’s reasonable efforts to find
          alternative sources of material or equipment supply and/or labor at
          the original/nonimpacted prices and/or estimate
        </p>
        <p>
          <strong style={{ fontSize: "10px" }}>NOTICE TO OWNER:</strong>
        </p>
        <p style={{ fontSize: "10px", textAlign: "justify" }}>
          <strong>
            FAILURE OF THIS CONTRACTOR TO PAY THOSE PERSONS SUPPLYING MATERIAL
            OR SERVICES TO COMPLETE THIS CONTRACT CAN RESULT IN THE FILING OF A
            MECHANIC'S LIEN ON THE PROPERTY WHICH IS THE SUBJECT OF THIS
            CONTRACT PURSUANT TO CHAPTER 429, RSMO. TO AVOID THIS RESULT YOU MAY
            ASK THIS CONTRACTOR FOR "LIEN WAIVERS" FROM ALL PERSONS SUPPLYING
            MATERIAL OR SERVICES FOR THE WORK DESCRIBED IN THIS CONTRACT.
            FAILURE TO SECURE LIEN WAIVERS MAY RESULT IN YOUR PAYING FOR LABOR
            AND MATERIAL TWICE.
          </strong>
        </p>
      </div>
    </div>
  );
};
