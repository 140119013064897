import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Icon, Form } from "semantic-ui-react";
import { userIsInRole } from "../../../util";
import states from "./states";

const ContactFormModal = (props) => {
  useEffect(() => {
    props.fetchOptions();
  }, []);

  const { contact } = props;

  // Check if user is in CRM Sub Add role
  const userIsAdmin = useSelector((state) =>
    state.auth.user ? userIsInRole(state, 7) : false
  );

  return (
    <Modal
      open={props.formModalOpen}
      // onOpen={() => fetchContact()}
      onClose={() => props.handleModalClose()}
    >
      <Modal.Content>
        <Form loading={props.formModalLoading}>
          <Form.Group>
            <Form.Dropdown
              label="Contact Name"
              name="ContactName"
              width={8}
              selection
              selectOnBlur={false}
              required
              error={props.error && props.errorArray.includes("ContactName")}
              search
              allowAdditions
              options={props.contactOptions || []}
              value={contact.ContactID}
              onChange={props.handleContactNameChange}
              onAddItem={props.handleContactNameAddition}
            />
            <Form.Dropdown
              label="Company"
              name="CompanyName"
              width={8}
              selection
              selectOnBlur={false}
              required
              error={props.error && props.errorArray.includes("CompanyName")}
              search
              allowAdditions
              options={props.companyOptions || []}
              value={contact.CompanyID}
              onChange={props.handleCompanyNameChange}
              onAddItem={props.handleCompanyNameAddition}
            />
          </Form.Group>
          <Form.Group>
            <Form.Dropdown
              label="Contact Relationship Owner"
              name="ContactRelationshipOwner"
              width={8}
              selection
              selectOnBlur={false}
              search
              options={props.userOptions || []}
              value={contact.ContactRelationshipOwner}
              onChange={props.handleChange}
            />
            <Form.Group inline className="field">
              <label>Company Type</label>
              <Form.Checkbox
                name="ClientInd"
                // width={8}
                label="Client"
                checked={contact.ClientInd === 1 ? true : false}
                onChange={props.handleCheckboxChange}
              />
              <Form.Checkbox
                // width={8}
                label="Vendor"
                name="VendorInd"
                disabled={!userIsAdmin}
                checked={contact.VendorInd === 1 ? true : false}
                onChange={props.handleCheckboxChange}
              />
              <Form.Checkbox
                // width={8}
                disabled={!userIsAdmin}
                label="Subcontractor"
                name="SubcontractorInd"
                checked={contact.SubcontractorInd === 1 ? true : false}
                onChange={props.handleCheckboxChange}
              />
            </Form.Group>
          </Form.Group>
          <Form.Group>
            <Form.Dropdown
              label="ReferredBy"
              name="ReferredByContactID"
              width={8}
              selection
              selectOnBlur={false}
              search
              options={props.contactOptions || []}
              value={contact.ReferredByContactID}
              onChange={props.handleChange}
            />
            <Form.Dropdown
              label="Company Industry"
              name="IndustryType"
              width={8}
              selection
              selectOnBlur={false}
              options={props.industryOptions || []}
              value={contact.IndustryType}
              onChange={props.handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Dropdown
              label="Status"
              name="Status"
              width={8}
              selection
              selectOnBlur={false}
              options={[
                { text: "Active", key: "Active", value: "Active" },
                { text: "Inactive", key: "Inactive", value: "Inactive" },
              ]}
              value={contact.Status}
              onChange={props.handleChange}
            />
            <Form.Input
              label="Corporate Address"
              name="CorporateAddress"
              width={8}
              required
              error={
                props.error && props.errorArray.includes("CorporateAddress")
              }
              value={contact.CorporateAddress}
              onChange={props.handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label="Contact Title"
              name="ContactTitle"
              width={8}
              value={contact.ContactTitle}
              onChange={props.handleChange}
            />
            <Form.Input
              label="Corporate City"
              name="CorporateCity"
              width={8}
              required
              error={props.error && props.errorArray.includes("CorporateCity")}
              value={contact.CorporateCity}
              onChange={props.handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              required
              label="Contact Email"
              name="ContactEmail"
              type="email"
              placeholder="___@___.com"
              pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$"
              width={8}
              error={props.error && props.errorArray.includes("ContactEmail")}
              value={contact.ContactEmail}
              onChange={props.handleChange}
            />
            <Form.Dropdown
              label="Corporate State"
              name="CorporateState"
              width={8}
              selection
              search
              selectOnBlur={false}
              required
              error={props.error && props.errorArray.includes("CorporateState")}
              options={states}
              value={contact.CorporateState}
              onChange={props.handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label="Contact Office Phone"
              name="ContactOfficePhone"
              type="tel"
              placeholder="000-000-0000"
              pattern="^[2-9]\d{2}-\d{3}-\d{4}$"
              required
              error={
                props.error && props.errorArray.includes("ContactOfficePhone")
              }
              width={8}
              value={contact.ContactOfficePhone}
              onChange={props.handleChange}
            />
            <Form.Input
              label="Corporate Zip"
              name="CorporateZip"
              type="tel"
              pattern="\d{5}"
              required
              error={props.error && props.errorArray.includes("CorporateZip")}
              width={8}
              value={contact.CorporateZip}
              onChange={props.handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label="Contact Cell"
              name="ContactCellPhone"
              type="tel"
              placeholder="000-000-0000"
              pattern="^[2-9]\d{2}-\d{3}-\d{4}$"
              width={8}
              value={contact.ContactCellPhone}
              onChange={props.handleChange}
            />
            <Form.Input
              label="Corporate Phone"
              name="CorporatePhone"
              type="tel"
              placeholder="000-000-0000"
              pattern="^[2-9]\d{2}-\d{3}-\d{4}$"
              width={8}
              value={contact.CorporatePhone}
              onChange={props.handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Dropdown
              label="Referrer"
              name="ContactType"
              width={8}
              selection
              selectOnBlur={false}
              options={[
                { text: "No", key: "Client", value: "Client" },
                { text: "Yes", key: "Referrer", value: "Referrer" },
              ]}
              value={contact.ContactType}
              onChange={props.handleChange}
            />
            <Form.Input
              label="Corporate Email"
              name="CorporateEmail"
              type="email"
              placeholder="___@___.com"
              pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$"
              required
              error={props.error && props.errorArray.includes("CorporateEmail")}
              width={8}
              value={contact.CorporateEmail}
              onChange={props.handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="yellow" onClick={() => props.handleModalClose()}>
          <Icon name="remove" /> Cancel
        </Button>
        <Button form="contactForm" primary onClick={() => props.handleSubmit()}>
          <Icon name="save" /> Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ContactFormModal;
