import React, { useState, useContext } from "react";
import { API_ROOT } from "../../../../../api-config";
import {
  Table,
  Label,
  Popup,
  Icon,
  Modal,
  Header,
  Button,
  Input,
  Dropdown,
  Form,
} from "semantic-ui-react";
import Axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";

import useIsMounted from "../../../../../hooks/isMounted";
import UploadProposalPopup from "./UploadProposalPopup";
import { userIsInRole } from "../../../../../util";
import PushContractModal from "./PushContractModal/PushContractModal";

import CurrentProject from "../../../../../screens/projects/CurrentProject";
import { useShowV1 } from "../../../../../hooks/useQuery";

const PendingClientContracts = (props) => {
  const { useV2 } = useContext(CurrentProject);
  const splitScreen = useShowV1();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const [contractGUIDToDeactivate, setContractGUIDToDeactivate] = useState(
    null
  );
  const [alternateModalOpen, setAlternateModalOpen] = useState(false);
  const [alternateEmailLoading, setAlternateEmailLoading] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [currentRecipientValue, setCurrentRecipientValue] = useState([]);
  const [emailMessage, setEmailMessage] = useState("");
  const [alternateEmailSent, setAlternateEmailSent] = useState(false);

  // const [proposalUploadGuid, setproposalUploadGuid] = useState(null);

  const { getContractByGUIDTotal, getContractsByTypeTotal } = props;

  const userIsContractApprover = useSelector((state) =>
    userIsInRole(state, 10)
  );

  const isMounted = useIsMounted();

  const openDeactivateContractModal = (guid) => {
    setContractGUIDToDeactivate(guid);
    setDeactivateModalOpen(true);
  };

  const deactivateContract = () => {
    const guid = contractGUIDToDeactivate;
    Axios.post(`${API_ROOT}/api/contract/clientcontractinactive`, {
      params: {
        guid,
      },
    })
      .then((res) => {
        loadContracts(res);
        setDeactivateModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDescriptionBlur = (guid, contractDescription) => {
    Axios.post(`${API_ROOT}/api/contract/clientcontractdescription`, {
      params: {
        guid,
        contractDescription,
      },
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearForm = () => {
    setRecipients([]);
    setCurrentRecipientValue([]);
    setEmailMessage("");
  };

  const handleAlternateModalClose = () => {
    setAlternateModalOpen(false);
    clearForm();
  };

  const getClientContactEmail = (projectID) => {
    Axios.get(`${API_ROOT}/api/project/client-contact-email`, {
      params: {
        projectID,
      },
    }).then((res) => {
      const clientEmail = res.data.email.email;
      if (clientEmail && isMounted.current) {
        setRecipients([
          // ...contractRecipients,
          { text: clientEmail, value: clientEmail },
        ]);
      }
    });
  };

  const handleAlternatesSubmit = (guid) => {
    setAlternateEmailLoading(true);
    const email = currentRecipientValue ? currentRecipientValue.join(";") : "";
    Axios.post(`${API_ROOT}/api/contract/send-alternate-selection-email`, {
      guid,
      recipients: email,
      text: emailMessage,
    })
      .then((res) => {
        setAlternateEmailSent(true);
        setAlternateEmailLoading(false);
        handleAlternateModalClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAlternateIconClick = (projectID) => {
    getClientContactEmail(projectID);
  };

  const handleChange = (e, { value }) => {
    setCurrentRecipientValue(value);
  };

  const handleAddition = (e, { value }) => {
    setRecipients((recipients) => [
      ...recipients,
      {
        text: value,
        value,
      },
    ]);
  };

  const {
    pendingContracts,
    loadContracts,
    checkContractReady,
    handleDaysBlur,
    handleInclusionChange,
  } = props;

  return (
    <>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="2" style={{ color: "rgb(254, 80, 0)" }}>
            Pending Contracts
          </Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Array.isArray(pendingContracts) && pendingContracts.length ? (
          pendingContracts[0].Client.map((client, index) => {
            return (
              <React.Fragment key={index}>
                {client.Contracts.map((contract) => {
                  return contract.displayType === "data" ? (
                    <Table.Row key={contract.GUID}>
                      <Table.Cell>
                        <Label
                          color={contract.ContractSentInd ? "grey" : "red"}
                        >
                          {contract.ContractType}
                        </Label>
                        <Popup
                          content="Deactivate Contract"
                          trigger={
                            <Icon
                              color="red"
                              link
                              name="unlink"
                              onClick={() =>
                                openDeactivateContractModal(contract.GUID)
                              }
                            />
                          }
                        />
                        {userIsContractApprover ? (
                          <PushContractModal
                            setUnsignedContract={props.setUnsignedContract}
                            contract={contract}
                          />
                        ) : null}
                      </Table.Cell>
                      <Table.Cell style={{ width: 340 }}>
                        <Input
                          placeholder="Add description"
                          fluid
                          // readOnly={contract.ContractSentInd ? true : false}
                          defaultValue={contract.ContractDescription || ""}
                          onBlur={(e) =>
                            handleDescriptionBlur(contract.GUID, e.target.value)
                          }
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Popup
                          content={
                            contract.ContractSentInd
                              ? "Proposal has been sent. Click to resend."
                              : "Send proposal to client"
                          }
                          position="top center"
                          trigger={
                            contract.ContractSentInd ? (
                              <Icon
                                // size="large"
                                color="green"
                                name="check"
                                link
                                onClick={() =>
                                  checkContractReady(
                                    contract.GUID,
                                    contract.CompanyID
                                  )
                                }
                              />
                            ) : (
                              <Icon
                                onClick={() =>
                                  checkContractReady(
                                    contract.GUID,
                                    contract.CompanyID
                                  )
                                }
                                // size="large"
                                color="red"
                                link
                                name="send"
                              />
                            )
                          }
                        />
                        {!contract.ContractSentInd && contract.AlternatesInd ? (
                          <Modal
                            open={alternateModalOpen}
                            onOpen={() => setAlternateModalOpen(true)}
                            onClose={handleAlternateModalClose}
                            size="small"
                            trigger={
                              <Icon
                                onClick={() =>
                                  handleAlternateIconClick(contract.projectid)
                                }
                                title="Send alternate selection form"
                                link
                                color="green"
                                name={
                                  alternateEmailSent
                                    ? "check square outline"
                                    : "share square"
                                }
                              />
                            }
                          >
                            <Header>
                              <Icon name="share square" />
                              Send Alternate Selection Form
                            </Header>
                            <Modal.Content>
                              <Form>
                                <Form.Select
                                  required
                                  label="Recipients (PM will be cc'd)"
                                  search
                                  noResultsMessage=""
                                  closeOnChange={true}
                                  selection
                                  selectOnBlur={false}
                                  fluid
                                  multiple
                                  allowAdditions
                                  options={recipients}
                                  value={currentRecipientValue}
                                  onAddItem={handleAddition}
                                  onChange={handleChange}
                                />
                                <Form.TextArea
                                  required
                                  label="Add message to client"
                                  onChange={(e, { value }) =>
                                    setEmailMessage(value)
                                  }
                                  value={emailMessage}
                                />
                              </Form>
                            </Modal.Content>
                            <Modal.Actions>
                              <Button
                                color="yellow"
                                onClick={handleAlternateModalClose}
                              >
                                <Icon name="remove" /> Cancel
                              </Button>
                              <Button
                                disabled={
                                  !currentRecipientValue.length || !emailMessage
                                }
                                loading={alternateEmailLoading}
                                color="green"
                                onClick={() =>
                                  handleAlternatesSubmit(contract.GUID)
                                }
                              >
                                <Icon name="checkmark" /> Send
                              </Button>
                            </Modal.Actions>
                          </Modal>
                        ) : null}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <UploadProposalPopup
                          contract={contract}
                          uploadClientContract={props.uploadClientContract}
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {splitScreen && (
                          <>
                            <Popup
                              content={"OLD: View client proposal link"}
                              position="top center"
                              trigger={
                                <a
                                  href={`/contract/?r=c&id=${contract.GUID}&c=${contract.CompanyID}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Icon
                                    // size="large"
                                    link
                                    name="external alternate"
                                  />
                                </a>
                              }
                            />
                            &nbsp;|&nbsp;&nbsp;
                            <Popup
                              content={"NEW: View client proposal link"}
                              position="top center"
                              trigger={
                                <a
                                  href={`/contracts/${contract.GUID}/client`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Icon
                                    // size="large"
                                    link
                                    name="external alternate"
                                  />
                                </a>
                              }
                            />
                          </>
                        )}
                        {!splitScreen && (
                          <Popup
                            content={"View client proposal link"}
                            position="top center"
                            trigger={
                              <a
                                href={
                                  useV2()
                                    ? `/contracts/${contract.GUID}/client`
                                    : `/contract/?r=c&id=${contract.GUID}&c=${contract.CompanyID}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Icon
                                  // size="large"
                                  link
                                  name="external alternate"
                                />
                              </a>
                            }
                          />
                        )}
                        {/* <PreviewContractPDF contract={contract} recipient="c" /> */}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Input
                          style={{ minWidth: 70, width: 70 }}
                          defaultValue={contract.ClientWorkDays}
                          // readOnly={contract.ContractSentInd ? true : false}
                          onBlur={(e) =>
                            handleDaysBlur(contract.GUID, e.target.value)
                          }
                          type="number"
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.Retention
                          ? contract.Retention.toFixed(2) + "%"
                          : "0.00%"}
                      </Table.Cell>
                      {splitScreen && (
                        <Table.Cell textAlign="center">
                          <span title="OLD">
                            {formatter.format(contract.GrandTotal || 0)}
                          </span>
                          &nbsp;&nbsp;|&nbsp;&nbsp;
                          <span title="NEW">
                            {formatter.format(
                              getContractByGUIDTotal(contract.GUID)
                            )}
                          </span>
                        </Table.Cell>
                      )}
                      {!splitScreen && (
                        <Table.Cell textAlign="center">
                          {useV2 &&
                            formatter.format(
                              getContractByGUIDTotal(contract.GUID)
                            )}
                          {!useV2 && formatter.format(contract.GrandTotal || 0)}
                        </Table.Cell>
                      )}
                      <Table.Cell textAlign="center">
                        {contract.ContractSentDate
                          ? moment(contract.ContractSentDate).format("l")
                          : ""}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.ClientApprovalDate
                          ? moment(contract.ClientApprovalDate).format("l")
                          : ""}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.Inclusions[0].text ? (
                          <Dropdown
                            selection
                            selectOnNavigation={false}
                            selectOnBlur={false}
                            options={contract.Inclusions}
                            onChange={(event, data) =>
                              handleInclusionChange(event, data)
                            }
                          />
                        ) : null}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    <Table.Row
                      key={contract.GUID}
                      style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}
                    >
                      <Table.Cell colSpan="7"></Table.Cell>
                      {splitScreen && (
                        <Table.Cell
                          style={{ color: "blue", fontWeight: "bold" }}
                          textAlign="center"
                        >
                          <span title="OLD">
                            {formatter.format(contract.GrandTotal || 0)}
                          </span>
                          &nbsp;&nbsp;|&nbsp;&nbsp;
                          <span title="NEW">
                            {formatter.format(getContractsByTypeTotal())}
                          </span>
                        </Table.Cell>
                      )}
                      {!splitScreen && (
                        <Table.Cell
                          style={{ color: "blue", fontWeight: "bold" }}
                          textAlign="center"
                        >
                          {useV2 && formatter.format(getContractsByTypeTotal())}
                          {!useV2 && formatter.format(contract.GrandTotal || 0)}
                        </Table.Cell>
                      )}
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })
        ) : (
          <Table.Row style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}>
            <Table.Cell colSpan="11">No pending contracts</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      {/* Confirm deactivate contract modal */}
      <Modal
        basic
        size="tiny"
        open={deactivateModalOpen}
        onClose={() => setDeactivateModalOpen(false)}
      >
        <Header icon="unlink" content="Deactivate Contract" />
        <Modal.Content>
          <p>
            Are you sure you want to set this contract's status to inactive?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="red"
            inverted
            onClick={() => setDeactivateModalOpen(false)}
          >
            <Icon name="remove" /> No
          </Button>
          <Button color="green" inverted onClick={() => deactivateContract()}>
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default PendingClientContracts;
