import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Menu,
  Sidebar,
  Modal,
  Header,
  Button,
  Icon,
  Form,
} from "semantic-ui-react";
import { Link, Route, withRouter, Switch } from "react-router-dom";
import "./SidebarContainer.scss";
import { connect } from "react-redux";
import { patchUser } from "../../hooks/useCurrentUser";

// import { fetchProjects } from "../../actions/project/projectActions";

// import Browser from "../Browser/Browser";
import TopMenu from "../TopMenu/TopMenu";
import Project from "../Project/Project";
import Inclusions from "../Inclusions/Inclusions";
import Axios from "axios";
import { API_ROOT } from "../../api-config";
import CRM from "../CRM/CRM";
import FourOhFour from "../404/404";
import Group from "../Dashboard/Group/Group";
import Construction from "../Dashboard/Construction/Construction";
import Mechanical from "../Dashboard/Mechanical/Mechanical";
import Service from "../Dashboard/Service/Service";
import Fleet from "../Admin/Fleet/Fleet";
import UniformAndPresentation from "../Admin/UniformAndPresentation/UniformAndPresentation";
import Warehouse from "../Admin/Warehouse/Warehouse";
import SurveyList from "../Admin/SurveyList/SurveyList";
// import KPIEntry from "../Admin/KPIEntry/KPIEntry";
import CompanyGoalsWrapper from "../Admin/CompanyGoals/CompanyGoalsWrapper";
import TeamMemberGoalsWrapper from "../Admin/TeamMemberGoals/TeamMemberGoalsWrapper";
import TeamMemberBonusPoolWrapper from "../Admin/TeamMemberBonusPool/TeamMemberBonusPoolWrapper";
import KPIWrapper from "../Dashboard/KPI/KPIWrapper";
import IncentivePayoutWrapper from "../Admin/IncentivePayout/IncentivePayoutWrapper";
import IncentiveAnalysisWrapper from "../Admin/IncentiveAnalysis/IncentiveAnalysisWrapper";
import SecurityRolesWrapper from "../Admin/SecurityRoles/SecurityRolesWrapper";
import ConditionApproval from "../ConditionApproval/ConditionApproval";
import WeeklyTimesheet from "../Admin/WeeklyTimesheet/WeeklyTimesheet";
import SafetySurvey from "../Admin/SafetySurvey/SafetySurvey";
import SafetyTrainingWrapper from "../Admin/SafetyTraining/SafetyTrainingWrapper";
import Browser2 from "../Browser2/Browser2";
import AccountingReports from "../Admin/AccountingReports/AccountingReports";
import BrowserArchive from "../BrowserArchive/BrowserArchive";
import ConditionTemplateWrapper from "../Admin/ConditionTemplates/ConditionTemplateWrapper";

import InclusionListV2 from "../../screens/projects/inclusions/List";
import InclusionViewV2 from "../../screens/projects/inclusions/View";

import { toBackendUri } from "../../api/env";
import { CurrentProjectProvider } from "../../screens/projects/CurrentProject";
class SidebarContainer extends Component {
  state = {
    animation: "overlay",
    direction: "left",
    dimmed: false,
    visible: false,
    modalOpen: false,
    projectName: "",
    loading: false,
  };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  handleAnimationChange = (animation) => () =>
    this.setState({ animation, visible: !this.state.visible });

  handleDimmedChange = (e, { checked }) => this.setState({ dimmed: checked });

  handleDirectionChange = (direction) => () =>
    this.setState({ direction, visible: false });

  handlePusherClick = () => {
    const { visible } = this.state;
    if (visible) {
      this.setState({ visible: false });
    }
  };

  handleNewProjectClick = () => {
    if (this.state.projectName === "") {
      return false;
    }
    this.setState({ loading: true });
    Axios.post(`${API_ROOT}/api/project/addproject`, {
      params: { projectName: this.state.projectName },
    })
      .then((res) => {
        let project = res ? res.data.project[0] : "";
        // this.props.dispatch(fetchProjects()).then(() => {
        this.setState({
          visible: false,
          projectName: "",
          modalOpen: false,
          loading: false,
        });
        this.props.history.push(`/browser/projects/${project.ProjectID}`);
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleProjectNameChange = (event) => {
    this.setState({
      projectName: event.target.value,
    });
  };

  handleRefreshAssignmentsClick = () => {
    Axios.get(`${API_ROOT}/api/acumatica/project-assignments`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  handleRecalculateClick = () => {
    Axios.get(`${API_ROOT}/api/acumatica/project-actuals`)
      .then((res) => {
        // setTimeout(() => {
        //   alert("Dashboard has been recalculated");
        // }, 7000);

        console.log(res);
      })
      .catch((err) => {
        alert("An error occurred while recalculating the dashboard");
        console.log(err);
      });
  };

  render() {
    const { animation, dimmed, direction, visible } = this.state;

    const user = this.props.user;

    // Check if user is admin or superuser
    // Some menu items and routes are only accessible to admininstrators
    let userIsAdmin =
      user && user.Roles
        ? user.Roles.some((el) => el.RoleID === 2 || el.RoleID === 1)
        : false;

    // const loggedIn = this.props.loggedIn;

    return (
      <div className="SidebarContainer">
        <TopMenu
          click={this.handlePusherClick}
          handleAnimationChange={this.handleAnimationChange}
        />
        <Sidebar.Pushable as={"div"} style={{ minHeight: "100vh" }}>
          {/* <VerticalSidebar
              animation={animation}
              direction={direction}
              visible={visible}
            /> */}

          <Sidebar
            className="VerticalSidebar"
            as={Menu}
            animation={animation}
            direction={direction}
            icon="labeled"
            // inverted
            vertical
            visible={visible}
            // width="wide"
            onClick={this.handlePusherClick}
          >
            <Menu.Item to="/browser" as={Link}>
              Browser
            </Menu.Item>
            {/* <Menu.Item to="/browser2" as={Link}>
              Browser2
            </Menu.Item> */}
            <Modal
              open={this.state.modalOpen}
              size="mini"
              onClose={this.handleClose}
              trigger={
                <Menu.Item onClick={this.handleOpen} as="a">
                  New Project
                </Menu.Item>
              }
            >
              <Header content="Create a new project" />
              <Modal.Content>
                <Form>
                  <Form.Input
                    autoFocus
                    placeholder="Give your project a name..."
                    value={this.state.projectName}
                    onChange={this.handleProjectNameChange}
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button color="green" onClick={this.handleNewProjectClick}>
                  {this.state.loading ? (
                    <React.Fragment>
                      <Icon loading name="spinner" /> Creating...{" "}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Icon name="checkmark" /> Create it
                    </React.Fragment>
                  )}
                </Button>
              </Modal.Actions>
            </Modal>
            {/* <Menu.Item to="/crm" as={Link}>
              CRM
            </Menu.Item> */}
            <Menu.Item>
              <Menu.Header
              // style={{ marginTop: 10, marginBottom: 10, fontWeight: "bold" }}
              >
                Dashboard
              </Menu.Header>
              <Menu.Menu>
                <Menu.Item as={Link} to={`/dashboard/group`}>
                  Group
                </Menu.Item>
                <Menu.Item as={Link} to={`/dashboard/construction`}>
                  Construction Division
                </Menu.Item>
                <Menu.Item as={Link} to={`/dashboard/mechanical`}>
                  Mechanical Division
                </Menu.Item>
                <Menu.Item as={Link} to={`/dashboard/service`}>
                  Service Division
                </Menu.Item>
                <Menu.Item as={Link} to={`/dashboard/kpi`}>
                  KPI
                </Menu.Item>
              </Menu.Menu>
            </Menu.Item>
            <Menu.Item>
              <Menu.Header>Administration</Menu.Header>
              <Menu.Menu>
                <Menu.Item as={Link} to={`/survey-list`}>
                  Client Surveys
                </Menu.Item>
                <Menu.Item as={Link} to={`/crm`}>
                  CRM
                </Menu.Item>
                <Menu.Item as={Link} to={`/fleet`}>
                  Fleet
                </Menu.Item>
                {/* <Menu.Item as={Link} to={`/kpi-entry`}>
                  KPI Entry
                </Menu.Item> */}
                <Menu.Item onClick={this.handleRefreshAssignmentsClick} as="a">
                  Refresh Assignments
                </Menu.Item>
                <Menu.Item as={Link} to={`/safety-inspection`}>
                  Safety Inspection
                </Menu.Item>
                <Menu.Item as={Link} to={`/safety-training`}>
                  Training
                </Menu.Item>

                <Menu.Item as={Link} to={`/uniform-and-presentation`}>
                  Uniform and Presentation
                </Menu.Item>

                <Menu.Item as={Link} to={`/warehouse`}>
                  Warehouse
                </Menu.Item>
                <Menu.Item as={Link} to={`/weekly-timesheet`}>
                  Weekly Timesheet
                </Menu.Item>
                <hr
                  style={{
                    width: "80%",
                    height: 1,
                    backgroundColor: "rgba(0,0,0,.1)",
                    border: "none",
                  }}
                />
                <Menu.Item as={Link} to={`/incentive-analysis`}>
                  Realtime Incentive Analysis
                </Menu.Item>

                {!userIsAdmin && user.hasRole("Accounting") && (
                  <>
                    <hr
                      style={{
                        width: "80%",
                        height: 1,
                        backgroundColor: "rgba(0,0,0,.1)",
                        border: "none",
                      }}
                    />
                    <Menu.Item as={Link} to={`/accounting-reports`}>
                      Accounting Reports
                    </Menu.Item>
                  </>
                )}

                {/* ADMIN MENU ITEMS */}
                {userIsAdmin ? (
                  <>
                    <hr
                      style={{
                        width: "80%",
                        height: 1,
                        backgroundColor: "rgba(0,0,0,.1)",
                        border: "none",
                      }}
                    />
                    <Menu.Item
                      as="a"
                      href={toBackendUri("/admin/queues")}
                      target="_blank"
                    >
                      Background Jobs
                    </Menu.Item>
                    <hr
                      style={{
                        width: "80%",
                        height: 1,
                        backgroundColor: "rgba(0,0,0,.1)",
                        border: "none",
                      }}
                    />
                    <Menu.Item as={Link} to={`/accounting-reports`}>
                      Accounting Reports
                    </Menu.Item>
                    <Menu.Item as={Link} to={`/company-goals`}>
                      Company Goals
                    </Menu.Item>
                    <Menu.Item as={Link} to={`/condition-template-approval`}>
                      Cond. Template Approval
                    </Menu.Item>
                    <Menu.Item as={Link} to={`/incentive-payout`}>
                      Incentive Payout
                    </Menu.Item>
                    <Menu.Item as={Link} to={`/security-roles`}>
                      Security Roles
                    </Menu.Item>
                    <Menu.Item as={Link} to={`/team-member-goals`}>
                      Team Member Goals
                    </Menu.Item>
                    <Menu.Item as={Link} to={`/team-member-bonus-pool`}>
                      Team Member Pool
                    </Menu.Item>
                    {/* <Menu.Item as={Link} to={`/condition-approval`}>
                      Condition Template Approval
                    </Menu.Item> */}
                    <hr
                      style={{
                        width: "80%",
                        height: 1,
                        backgroundColor: "rgba(0,0,0,.1)",
                        border: "none",
                      }}
                    />
                    <Menu.Item onClick={this.handleRecalculateClick} as="a">
                      Recalculate Dashboard
                    </Menu.Item>
                  </>
                ) : null}
              </Menu.Menu>
            </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher
            style={{
              minHeight: "100vh",
              paddingTop: 80,
            }}
            dimmed={dimmed && visible}
            onClick={this.handlePusherClick}
          >
            <Switch>
              <Route exact path="/crm" component={CRM} />
              <Route exact path="/browser" component={Browser2} />
              {/* <Route exact path="/browser2" component={Browser2} /> */}
              <Route exact path="/browser-archive" component={BrowserArchive} />
              <Route exact path="/browser/projects/:id">
                <CurrentProjectProvider>
                  <Project />
                </CurrentProjectProvider>
              </Route>
              <Route
                exact
                path="/browser/projects/inclusions/:id/:contractid?"
                component={Inclusions}
              />

              {/* V2 Routes */}
              <Route
                exact
                path="/browser/projects/:id/inclusions/:status?"
                component={InclusionListV2}
              />
              <Route
                exact
                path="/browser/projects/:id/inclusions/:mainCategory/:subCategory"
                component={InclusionViewV2}
              />
              <Route
                exact
                path="/browser/projects/:id/tracking"
                component={InclusionListV2}
              />
              {/* END V2 Routes */}

              <Route exact path="/dashboard/group/">
                <Group />
              </Route>
              <Route exact path="/dashboard/construction">
                <Construction />
              </Route>
              <Route exact path="/dashboard/service">
                <Service />
              </Route>
              <Route exact path="/dashboard/mechanical">
                <Mechanical />
              </Route>
              <Route exact path="/dashboard/kpi">
                <KPIWrapper />
              </Route>
              <Route exact path="/fleet">
                <Fleet />
              </Route>
              <Route exact path="/safety-inspection">
                <SafetySurvey />
              </Route>
              <Route exact path="/safety-training">
                <SafetyTrainingWrapper />
              </Route>
              <Route exact path="/survey-list">
                <SurveyList />
              </Route>
              <Route exact path="/uniform-and-presentation">
                <UniformAndPresentation />
              </Route>
              <Route exact path="/warehouse">
                <Warehouse />
              </Route>
              {/* <Route exact path="/kpi-entry">
                <KPIEntry />
              </Route> */}
              <Route exact path="/weekly-timesheet">
                <WeeklyTimesheet />
              </Route>
              <Route exact path="/incentive-analysis">
                <IncentiveAnalysisWrapper />
              </Route>
              {/* ADMIN ROUTES */}
              <Route exact path="/accounting-reports">
                {userIsAdmin || user.hasRole("Accounting") ? (
                  <AccountingReports />
                ) : (
                  <FourOhFour />
                )}
              </Route>
              <Route exact path="/company-goals">
                {userIsAdmin ? <CompanyGoalsWrapper /> : <FourOhFour />}
              </Route>
              <Route path="/condition-template-approval">
                {userIsAdmin ? <ConditionTemplateWrapper /> : <FourOhFour />}
              </Route>
              <Route exact path="/team-member-goals">
                {userIsAdmin ? <TeamMemberGoalsWrapper /> : <FourOhFour />}
              </Route>
              <Route exact path="/team-member-bonus-pool">
                {userIsAdmin ? <TeamMemberBonusPoolWrapper /> : <FourOhFour />}
              </Route>
              <Route exact path="/security-roles">
                {userIsAdmin ? <SecurityRolesWrapper /> : <FourOhFour />}
              </Route>
              <Route exact path="/incentive-payout">
                {userIsAdmin ? <IncentivePayoutWrapper /> : <FourOhFour />}
              </Route>
              <Route exact path="/condition-approval">
                {userIsAdmin ? <ConditionApproval /> : <FourOhFour />}
              </Route>
              <Route component={FourOhFour} />
            </Switch>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

SidebarContainer.propTypes = {
  animation: PropTypes.string,
  direction: PropTypes.string,
  visible: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const userArray = state.auth.user ? JSON.parse(state.auth.user) : null;
  const user =
    Array.isArray(userArray) && userArray.length ? userArray[0] : null;
  return {
    loggedIn: state.auth.loggedIn,
    user: patchUser(user),
  };
};

export default withRouter(connect(mapStateToProps)(SidebarContainer));
