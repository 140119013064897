import React from "react";
import { Header } from "semantic-ui-react";

export default () => (
  <div>
    <center>
      <Header
        as="h1"
        style={{
          width: 600,
          height: 140,
          display: "block",
          margin: "auto",
          position: "relative",
          textAlign: "center",
          fontSize: 36,
          marginTop: "30vh",
        }}
      >
        That contract could not be found. Please contact the N5 Project Manager.
      </Header>
    </center>
  </div>
);
