const isNumeric = (num) => {
  return !isNaN(num);
};

export const columnDefs = [
  {
    headerName: "Position Incentive",
    children: [
      {
        headerName: "Year",
        field: "Year",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        headerName: "Quarter",
        field: "Quarter",
        minWidth: 100,
        maxWidth: 100,
      },
      {
        headerName: "Position",
        field: "Position",
        sortable: true,
      },
      {
        headerName: "Cnt",
        field: "PoolSize",
        sortable: true,
      },
      {
        headerName: "Stars",
        field: "stars",
        editable: true,
        sortable: true,
        valueParser: (params) => {
          const { newValue, oldValue } = params;

          if (!newValue) {
            return 0;
          } else if (isNumeric(newValue)) {
            return parseInt(newValue);
          } else {
            return oldValue;
          }
        },
      },
    ],
  },
];
