import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Button,
  Divider,
  Form,
  Icon,
  Input,
  Message,
  Modal,
  Radio,
} from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../../../api-config";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { getUser } from "../../../../../util";
import jsPDF from "jspdf";
import Compressor from "compressorjs";

class CustomImage extends Image {
  constructor(mimeType) {
    super();
  }

  // `imageType` is a required input for generating a PDF for an Image
  get imageType() {
    return this.mimeType.split("/")[1];
  }
}

const fileToImageUrl = (file) => {
  return new Promise((resolve, reject) => {
    const image = new CustomImage(file.type);

    image.onload = () => {
      resolve(image);
    };

    image.onerror = () => {
      reject(new Error("Failed to convert File to Image"));
    };

    image.src = URL.createObjectURL(file);
  });
};

// The dimensions are in millimeters.
const A4_PAPER_DIMENSIONS = {
  width: 210,
  height: 297,
};

const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

// Calculates the best possible position of an image on the A4 paper format,
// so that the maximal area of A4 is used and the image ratio is preserved.
const imageDimensionsOnA4 = (dimensions) => {
  const isLandScapeImage = dimensions.width >= dimensions.height;

  // If the image is in landscape, the full width of A4 is used.
  if (isLandScapeImage) {
    return {
      width: A4_PAPER_DIMENSIONS.width,
      height:
        A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
    };
  }

  // If the image is in portrait and the full height of A4 would skew
  // the image ratio, we scale the image dimensions.
  const imageRatio = dimensions.width / dimensions.height;
  if (imageRatio > A4_PAPER_RATIO) {
    const imageScaleFactor =
      (A4_PAPER_RATIO * dimensions.height) / dimensions.width;

    const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

    return {
      height: scaledImageHeight,
      width: scaledImageHeight * imageRatio,
    };
  }

  // The full height of A4 can be used without skewing the image ratio.
  return {
    width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
    height: A4_PAPER_DIMENSIONS.height,
  };
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const MaterialModal = ({
  conditionID,
  open,
  setOpen,
  date,
  project,
  userID,
  proxyUserDisplayName,
  // category,
  // conditionName,
  // conditionInfo,
  // fetchConditionInfo,
}) => {
  const [PONumber, setPONumber] = useState("0000-0000-0000");
  const [lastFourDigits, setLastFourDigits] = useState("0000");
  const [receiptFileURL, setReceiptFileURL] = useState("");
  const [analyzerLoading, setAnalyzerLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [vendor, setVendor] = useState("");
  const [transactionDate, setTransactionDate] = useState("");
  const [total, setTotal] = useState("");
  const [notes, setNotes] = useState("");
  const [PDF, setPDF] = useState();
  const [uploadedImages, setUploadedImages] = useState([]);
  const [fileMimeType, setFileMimeType] = useState("");
  const [sentToAccountingInd, setSentToAccountingInd] = useState(false);
  const [scrolledToBottomInd, setScrolledToBottomInd] = useState(false);
  const [creditCardInd, setCreditCardInd] = useState("");

  const user = useSelector((state) => getUser(state));

  const fileInputRef = useRef();

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  // Creates a PDF document containing all the uploaded images.
  const generatePdfFromImages = (images) => {
    // Default export is A4 paper, portrait, using millimeters for units.
    const doc = new jsPDF();

    // We let the images add all pages,
    // therefore the first default page can be removed.
    doc.deletePage(1);

    images.forEach((image) => {
      const imageDimensions = imageDimensionsOnA4({
        width: image.width,
        height: image.height,
      });

      doc.addPage();
      doc.addImage(
        image.src,
        fileMimeType,
        // Images are vertically and horizontally centered on the page.
        (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
        (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
        imageDimensions.width,
        imageDimensions.height
      );
    });

    // Creates a PDF and sets it to component state.
    setPDF(doc.output("blob"));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const generatePONumber = (projectID, conditionID, userID) => {
    const random = Math.floor(Math.random() * 10000) + 1;
    const PO = `${userID}-${conditionID}-${random}`;
    if (PO) {
      setPONumber(PO);
      setLastFourDigits(random);
    }
  };

  const handleLastFourDigitsChange = (event) => {
    setLastFourDigits(event.target.value);
    setPONumber(`${userID}-${conditionID}-${event.target.value}`);
  };

  const analyzeReceipt = (blobName) => {
    Axios.post(`${API_ROOT}/api/project/analyze-receipt`, {
      blobName,
    })
      .then((res) => {
        setVendor(res.data.vendor);
        setTransactionDate(res.data.transactionDate);
        setTotal(res.data.total);
        setAnalyzerLoading(false);
        setScrolledToBottomInd(true);
      })
      .catch((err) => alert("We encountered an error:", err));
  };

  useEffect(() => {
    if (open && userID && conditionID && date && project.projectID) {
      generatePONumber(project.projectID, conditionID, userID);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setVendor("");
      setTransactionDate("");
      setTotal("");
      setPDF("");
      setUploadedImages([]);
      setReceiptFileURL("");
      setFileMimeType("");
      setSentToAccountingInd(false);
      setNotes("");
      setScrolledToBottomInd(false);
      setCreditCardInd("");
    }
  }, [open, userID, conditionID, date, project.projectID]);

  const uploadReceipt = async () => {
    try {
      setAnalyzerLoading(true);
      const blobName = `${PONumber}.pdf`;

      const form = new FormData();
      form.append("file", PDF, blobName);

      const results = await Axios.post(
        `${API_ROOT}/api/project/receipt-sas`,
        form
      ).then((results) => results.data);

      if (results.clientRequestId) {
        setReceiptFileURL(results.blobUrl);
        analyzeReceipt(blobName);
      }
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    if (PDF) {
      uploadReceipt();
    }
  }, [PDF]);

  // const handleImageUpload = useCallback(
  //   (event) => {
  //     // `event.target.files` is of type `FileList`,
  //     // we convert it to Array for easier manipulation.
  //     const fileList = event.target.files;
  //     const fileArray = fileList ? Array.from(fileList) : [];

  //     setFileMimeType(fileArray[0].type);

  //     // Uploaded images are read and the app state is updated.
  //     const fileToImagePromises = fileArray.map(fileToImageUrl);
  //     Promise.all(fileToImagePromises).then(setUploadedImages);
  //   },
  //   [setUploadedImages]
  // );

  const handleImageUpload = useCallback(
    (file) => {
      // `event.target.files` is of type `FileList`,
      // we convert it to Array for easier manipulation.
      // const fileList = event.target.files;
      const fileArray = [file];

      setFileMimeType(fileArray[0].type);

      // Uploaded images are read and the app state is updated.
      const fileToImagePromises = fileArray.map(fileToImageUrl);
      Promise.all(fileToImagePromises).then(setUploadedImages);
    },
    [setUploadedImages]
  );

  const handleCompressedUpload = (e) => {
    const image = e.target.files[0];
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        handleImageUpload(compressedResult);
      },
    });
  };

  // const cleanUpUploadedImages = useCallback(() => {
  //   // setUploadedImages([]);
  //   uploadedImages.forEach((image) => {
  //     // The URL.revokeObjectURL() releases an existing object URL
  //     // which was previously created by URL.createObjectURL().
  //     // It lets the browser know not to keep the reference to the file any longer.
  //     URL.revokeObjectURL(image.src);
  //   });
  // }, [setUploadedImages, uploadedImages]);

  const handleGeneratePdfFromImages = useCallback(() => {
    generatePdfFromImages(uploadedImages);
  }, [uploadedImages]);

  const submitToAccounting = () => {
    Axios.post(`${API_ROOT}/api/project/save-receipt`, {
      projectID: project.projectID,
      receiptFileURL,
      PONumber,
      vendor,
      transactionDate,
      total: total || total === 0 ? formatter.format(total) : "",
      unformattedTotal: total,
      contentType: "application/pdf",
      fileName: `${PONumber}.pdf`,
      displayName: proxyUserDisplayName || user.DisplayName,
      conditionID,
      notes,
      userID,
    })
      .then((res) => {
        setSubmitLoading(false);
        setSentToAccountingInd(true);
      })
      .catch((err) => {
        setSubmitLoading(false);
        alert(err);
      });
  };

  const handleSubmit = () => {
    setSubmitLoading(true);
    submitToAccounting();
  };

  return open ? (
    <Modal
      size="tiny"
      dimmer="blurring"
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <h1
          style={{ fontWeight: "bold", display: "inline-block" }}
        >{`PO#: ${PONumber}`}</h1>
      </Modal.Header>
      <Modal.Content>
        {/* <h4 style={{ color: "#2185d0" }}>
          {`${project.projectID} - ${project.companyName}`}
          <br />
          {`${project.projectName}`}
        </h4>

        <h5 style={{ color: "rgba(254, 80, 0, 0.8)" }}>
          {category} - {conditionName ? conditionName : ""}
        </h5> */}

        {sentToAccountingInd ? (
          <Message
            color="green"
            header="Success"
            icon="check"
            floating
            content="The Accounting team has received your receipt."
          />
        ) : (
          <>
            <Divider
              style={{ marginBottom: 30, color: "rgb(254, 80, 0)" }}
              horizontal
            >
              Step 1
            </Divider>
            <p style={{ fontWeight: "bold" }}>
              Give the PO# displayed at the top to the vendor. If the PO# listed
              above does not match what was given to the vendor, please correct
              the number below.
            </p>
            <Form>
              <Input
                disabled={analyzerLoading || submitLoading}
                fluid
                label={`${userID}-${conditionID}-`}
                value={lastFourDigits}
                onChange={handleLastFourDigitsChange}
              />
            </Form>
            <Divider
              style={{
                marginTop: 60,
                marginBottom: 30,
                color: "rgb(254, 80, 0)",
              }}
              horizontal
            >
              Step 2
            </Divider>
            <Form>
              <Form.Field>
                <label>Select the receipt (.jpg files or camera only)</label>
                <input
                  disabled={analyzerLoading || submitLoading}
                  type="file"
                  // accept="image/png, image/jpeg;capture=camera"
                  accept="image/jpeg,image/jpg"
                  ref={fileInputRef}
                  onChange={handleCompressedUpload}
                  // onChange={handleImageUpload}
                />
              </Form.Field>
              {uploadedImages.length > 0 ? (
                <Fragment>
                  {!scrolledToBottomInd ? <AlwaysScrollToBottom /> : null}
                  <Divider
                    style={{
                      marginTop: 60,
                      marginBottom: 30,
                      color: "rgb(254, 80, 0)",
                    }}
                    horizontal
                  >
                    Step 3
                  </Divider>
                  <Button
                    icon
                    labelPosition="left"
                    loading={analyzerLoading}
                    disabled={analyzerLoading || submitLoading}
                    onClick={handleGeneratePdfFromImages}
                    fluid
                    color="orange"
                  >
                    <Icon name="search" />
                    View the receipt
                    <br />
                    <span style={{ fontSize: 10 }}>
                      (This'll just take a moment)
                    </span>
                  </Button>
                  {receiptFileURL ? (
                    <>
                      <Divider
                        style={{
                          marginTop: 60,
                          marginBottom: 30,
                          color: "rgb(254, 80, 0)",
                        }}
                        horizontal
                      >
                        Step 4
                      </Divider>
                      <p
                        style={{
                          fontSize: 14,
                          fontStyle: "italic",
                          marginBottom: 20,
                        }}
                      >
                        *The receipt analyzer isn't perfect. Please provide any
                        information not auto-populated below.
                      </p>

                      <Form.Field disabled={analyzerLoading || submitLoading}>
                        <label>Vendor Name</label>
                        <input
                          type="text"
                          value={vendor}
                          onChange={(e) => setVendor(e.target.value)}
                        />
                      </Form.Field>
                      <Form.Field
                        required
                        disabled={analyzerLoading || submitLoading}
                      >
                        <label>Transaction Date</label>
                        <input
                          type="date"
                          value={transactionDate}
                          onChange={(e) => setTransactionDate(e.target.value)}
                        />
                      </Form.Field>
                      <Form.Field
                        required
                        disabled={analyzerLoading || submitLoading}
                      >
                        <label>Total</label>
                        <input
                          type="number"
                          value={total}
                          onChange={(e) => setTotal(e.target.value)}
                        />
                      </Form.Field>
                      <Form.Field disabled={analyzerLoading || submitLoading}>
                        <label>
                          Was this purchased with a Visa, gas card, or Home
                          Depot card? <span style={{ color: "red" }}>*</span>
                        </label>
                        <Radio
                          label="Yes"
                          name="radioGroup"
                          value="yes"
                          checked={creditCardInd === "yes"}
                          onChange={(e, { value }) => setCreditCardInd(value)}
                        />
                      </Form.Field>
                      <Form.Field disabled={analyzerLoading || submitLoading}>
                        <Radio
                          label="No"
                          name="radioGroup"
                          value="no"
                          checked={creditCardInd === "no"}
                          onChange={(e, { value }) => setCreditCardInd(value)}
                        />
                      </Form.Field>
                      <Form.Field disabled={analyzerLoading || submitLoading}>
                        <label>Notes</label>
                        <textarea
                          placeholder="Please provide any additional information here..."
                          onChange={(e) => setNotes(e.target.value)}
                          value={notes}
                        />
                      </Form.Field>
                    </>
                  ) : null}

                  {transactionDate &&
                  total &&
                  (creditCardInd === "yes" || creditCardInd === "no") ? (
                    <Fragment>
                      <Divider
                        style={{
                          marginTop: 60,
                          marginBottom: 30,
                          color: "rgb(254, 80, 0)",
                        }}
                        horizontal
                      >
                        Step 5
                      </Divider>
                      <Form.Button
                        onClick={handleSubmit}
                        fluid
                        color="orange"
                        loading={submitLoading}
                        disabled={
                          analyzerLoading ||
                          submitLoading ||
                          !transactionDate ||
                          !total
                        }
                      >
                        <Icon name="send" /> Submit to accounting
                      </Form.Button>
                    </Fragment>
                  ) : null}
                </Fragment>
              ) : null}
            </Form>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={analyzerLoading || submitLoading}
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  ) : null;
};

export default MaterialModal;
