import React, { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import CurrentProject from "../screens/projects/CurrentProject";

// the query string for you.
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search.toLocaleLowerCase()), [
    search,
  ]);
};

export default useQuery;

export const useShowV1 = () => {
  const { useV2 } = useContext(CurrentProject);
  const searchParams = useQuery();
  return useMemo(
    () =>
      useV2 &&
      searchParams.has("showv1") &&
      searchParams.get("showv1") === "true",
    [useV2(), searchParams]
  );
};
