import numeral from "numeral";

const isNumeric = (num) => {
  return !isNaN(num);
};

export const columnDefs = [
  {
    headerName: "Year",
    field: "Year",
    pinned: "left",
    minWidth: 80,
    maxWidth: 80,
  },
  {
    headerName: "Division",
    field: "Division",
    minWidth: 120,
    maxWidth: 120,
    pinned: "left",
  },
  {
    headerName: "Estimator",
    field: "Estimator",
    minWidth: 150,
    maxWidth: 150,
    pinned: "left",
  },
  {
    headerName: "Goal Amount",
    field: "GoalAmount",
    minWidth: 120,
    maxWidth: 120,
    editable: true,
    type: "numericColumn",

    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return null;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return params.value ? numeral(params.value).format("$0,0") : null;
    },
  },
  {
    headerName: "Jan",
    field: "1",
    type: "numericColumn",
    minWidth: 80,
    editable: true,
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return null;
      } else if (isNumeric(newValue)) {
        return parseFloat(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return params.value ? numeral(params.value / 100).format("0%") : null;
    },
  },
  {
    headerName: "Feb",
    field: "2",
    type: "numericColumn",
    minWidth: 80,
    editable: true,
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return null;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return params.value ? numeral(params.value / 100).format("0%") : null;
    },
  },
  {
    headerName: "Mar",
    field: "3",
    type: "numericColumn",
    minWidth: 80,
    editable: true,
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return null;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return params.value ? numeral(params.value / 100).format("0%") : null;
    },
  },
  {
    headerName: "Apr",
    field: "4",
    type: "numericColumn",
    minWidth: 80,
    editable: true,
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return null;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return params.value ? numeral(params.value / 100).format("0%") : null;
    },
  },
  {
    headerName: "May",
    field: "5",
    type: "numericColumn",
    minWidth: 80,
    editable: true,
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return null;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return params.value ? numeral(params.value / 100).format("0%") : null;
    },
  },
  {
    headerName: "Jun",
    field: "6",
    type: "numericColumn",
    minWidth: 80,
    editable: true,
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return null;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return params.value ? numeral(params.value / 100).format("0%") : null;
    },
  },
  {
    headerName: "Jul",
    field: "7",
    type: "numericColumn",
    minWidth: 80,
    editable: true,
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return null;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return params.value ? numeral(params.value / 100).format("0%") : null;
    },
  },
  {
    headerName: "Aug",
    field: "8",
    type: "numericColumn",
    minWidth: 80,
    editable: true,
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return null;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return params.value ? numeral(params.value / 100).format("0%") : null;
    },
  },
  {
    headerName: "Sep",
    field: "9",
    type: "numericColumn",
    minWidth: 80,
    editable: true,
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return null;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return params.value ? numeral(params.value / 100).format("0%") : null;
    },
  },
  {
    headerName: "Oct",
    field: "10",
    type: "numericColumn",
    minWidth: 80,
    editable: true,
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return null;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return params.value ? numeral(params.value / 100).format("0%") : null;
    },
  },
  {
    headerName: "Nov",
    field: "11",
    type: "numericColumn",
    minWidth: 80,
    editable: true,
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return null;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return params.value ? numeral(params.value / 100).format("0%") : null;
    },
  },
  {
    headerName: "Dec",
    field: "12",
    type: "numericColumn",
    minWidth: 80,
    editable: true,
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return null;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return params.value ? numeral(params.value / 100).format("0%") : null;
    },
  },
  {
    headerName: "Total",
    field: "Total",
    minWidth: 80,
    maxWidth: 80,
    type: "numericColumn",
    valueGetter: (params) => {
      const { data } = params;
      const totes =
        (1 * data[1] +
          1 * data[2] +
          1 * data[3] +
          1 * data[4] +
          1 * data[5] +
          1 * data[6] +
          1 * data[7] +
          1 * data[8] +
          1 * data[9] +
          1 * data[10] +
          1 * data[11] +
          1 * data[12]) /
        100;
      return numeral(totes).format("0%");
    },
  },
];
