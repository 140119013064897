import React, { useState, useEffect, Fragment } from "react";
import {
  Accordion,
  Loader,
  Dimmer,
  Grid,
  Menu,
  Form,
  Header,
  Icon,
} from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";
import AssignmentItem from "./AssignmentItem/AssignmentItem";
import { useSelector } from "react-redux";
import { userIsInRole } from "../../../util";
import moment from "moment";
import { useLoggedInUser } from "../hooks";

const Assignments = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [assignments, setAssignments] = useState(null);
  const [regularHoursTotal, setRegularHoursTotal] = useState(0);
  const [overtimeHoursTotal, setOvertimeHoursTotal] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userID, setUserID] = useState("");
  const [proxyUserID, setProxyUserID] = useState("");
  const [proxyUserOptions, setProxyUserOptions] = useState([]);
  const [day, setDay] = useState(moment().day());
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    setDay(moment(date).day());
  }, [date]);

  useEffect(() => {
    switch (day) {
      case 0:
        setActiveIndex(1);
        break;
      case 1:
        setActiveIndex(2);
        break;
      case 2:
        setActiveIndex(3);
        break;
      case 3:
        setActiveIndex(4);
        break;
      case 4:
        setActiveIndex(5);
        break;
      case 5:
        setActiveIndex(6);
        break;
      case 6:
        setActiveIndex(0);
        break;
      default:
        setActiveIndex(null);
    }
  }, [day]);

  const loggedInUserID = useLoggedInUser("UserID");
  const state = useSelector((state) => state);

  let userIsInOfficeRole = userIsInRole(state, 5);
  let userIsSuperuser = userIsInRole(state, 1);

  useEffect(() => {
    if (loggedInUserID) {
      setUserID(loggedInUserID);
    }
  }, [loggedInUserID]);

  useEffect(() => {
    if (proxyUserID || userID) {
      setLoading(true);

      Axios.get(`${API_ROOT}/api/project/weekly-assignments-by-pay-period`, {
        params: {
          userID: proxyUserID || userID,
          payPeriodDate: date,
        },
      })
        .then((res) => {
          if (res.data.assignments) {
            setAssignments(res.data.assignments);
          }

          if (res.data.totals) {
            setRegularHoursTotal(res.data.totals.regularHoursTotal || 0);
          }

          if (res.data.totals) {
            setOvertimeHoursTotal(res.data.totals.overtimeHoursTotal || 0);
          }

          setLoading(false);
        })
        .catch((err) => {
          setError(true);
          setLoading(false);

          console.log(err);
        });
    }
  }, [userID, proxyUserID, date]);

  useEffect(() => {
    if (userID) {
      Axios.get(`${API_ROOT}/api/user/displaynames`)
        .then((res) => {
          setProxyUserOptions(
            JSON.parse(res.data.users).filter((el) => el.UserID !== userID)
          );
        })
        .catch((err) => console.log(err));
    }
  }, [userID]);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  const handleLeftArrowClick = () => {
    const prevWeek = moment(date)
      .subtract(1, "week")
      .format("YYYY-MM-DD");
    setDate(prevWeek);
  };

  const handleRightArrowClick = () => {
    const nextWeek = moment(date)
      .add(1, "week")
      .format("YYYY-MM-DD");
    setDate(nextWeek);
  };

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader inverted />
      </Dimmer>
    );
  }

  if (error) {
    return (
      <div>
        <p style={{ textAlign: "center", paddingTop: 20 }}>
          There was an error loading the weekly assignment data. Please contact
          IT.
        </p>
      </div>
    );
  }

  return (
    <Fragment>
      <div style={{ width: "100%" }}>
        {userIsInOfficeRole || userIsSuperuser ? (
          <Form>
            <Form.Dropdown
              clearable
              placeholder="Proxy user"
              selectOnBlur={false}
              selection
              loading={!proxyUserOptions.length}
              value={proxyUserID}
              onChange={(e, data) => setProxyUserID(data.value)}
              options={proxyUserOptions.map((option) => {
                return {
                  value: option.UserID,
                  text: option.DisplayName,
                  key: option.UserID,
                };
              })}
            />
          </Form>
        ) : null}
        <div
          style={{
            width: "100%",
            background: "#2185d0",
            paddingTop: 16,
            paddingBottom: 10,
          }}
        >
          <Header as="h3" textAlign="center">
            <span style={{ color: "white" }}>
              <Icon
                // onClick={() => setWeekDiff(weekDiff - 1)}
                onClick={handleLeftArrowClick}
                link
                size="large"
                name="angle left"
              />
              <div style={{ width: 200, display: "inline-block" }}>
                {/* {` Previous | Next `} */}
              </div>

              <Icon
                // onClick={() => setWeekDiff(weekDiff + 1)}
                onClick={handleRightArrowClick}
                link
                size="large"
                name="angle right"
              />
            </span>
          </Header>
        </div>
      </div>

      <Accordion styled fluid style={{ borderRadius: 0 }}>
        {Array.isArray(assignments) && assignments.length
          ? assignments.map((assignment, i) => (
              <Fragment key={assignment.date}>
                <Accordion.Title
                  style={{
                    color: "#FFF",
                    textAlign: "center",
                    background: "grey",
                  }}
                  active
                  index={i}
                  onClick={handleClick}
                >
                  {/* <Icon name="dropdown" /> */}
                  {`${assignment.dayOfWeek} ${assignment.longDate}`}
                </Accordion.Title>
                <Accordion.Content style={{ padding: 0 }} active>
                  <Menu fluid vertical>
                    {Object.keys(assignment.projects[0]).length !== 0 ? (
                      assignment.projects.map((project) => (
                        <AssignmentItem
                          key={project.projectID}
                          date={assignment.date}
                          dayOfWeek={`${assignment.dayOfWeek} ${assignment.longDate}`}
                          project={project}
                          setAssignments={setAssignments}
                          setRegularHoursTotal={setRegularHoursTotal}
                          setOvertimeHoursTotal={setOvertimeHoursTotal}
                          setLoading={setLoading}
                          setError={setError}
                          userID={proxyUserID || userID}
                        />
                      ))
                    ) : (
                      <Menu.Item>
                        <p>No projects assigned</p>
                      </Menu.Item>
                    )}
                  </Menu>
                </Accordion.Content>
              </Fragment>
            ))
          : null}
      </Accordion>
      <Grid style={{ margin: 0, padding: 0, marginBottom: 40 }}>
        <Grid.Row
          style={{
            margin: 0,
            padding: 0,
            fontWeight: "bold",
            borderBottom: "1px solid rgba(34,36,38,.15)",
            marginTop: -0.5,
          }}
        >
          <Grid.Column
            style={{ background: "#48BF91", color: "#FFF", padding: 14 }}
            width={13}
          >
            Total REGULAR hours for the week
          </Grid.Column>
          <Grid.Column
            width={3}
            style={{ padding: 14, fontSize: 18, textAlign: "center" }}
          >
            {regularHoursTotal ? regularHoursTotal.toFixed(2) : 0}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          style={{
            margin: 0,
            padding: 0,
            fontWeight: "bold",
            borderBottom: "1px solid rgba(34,36,38,.15)",
            marginTop: -0.5,
            marginBottom: 40,
          }}
        >
          <Grid.Column
            style={{ background: "#0076BE", color: "#FFF", padding: 14 }}
            width={13}
          >
            Total OVERTIME hours for the week
          </Grid.Column>
          <Grid.Column
            width={3}
            style={{ padding: 14, fontSize: 18, textAlign: "center" }}
          >
            {overtimeHoursTotal ? overtimeHoursTotal.toFixed(2) : 0}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};

export default Assignments;
