import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import { getConfiguration } from "./api/configuration";

dayjs.extend(require("dayjs/plugin/customParseFormat"));
dayjs.extend(require("dayjs/plugin/isSameOrAfter"));

const NexusConfig = React.createContext({});
NexusConfig.displayName = "NexusConfig";
export default NexusConfig;

export const NexusConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(false);

  const getConfig = () => {
    setLoading(true);
    getConfiguration().then((results) => {
      setConfig(results);
      setLoading(false);
    });
  };

  useEffect(() => getConfig(), []);

  return (
    <NexusConfig.Provider value={{ loading, ...config }}>
      {children}
    </NexusConfig.Provider>
  );
};
