import * as moment from "moment";
import {
  SET_INCENTIVE_ANALYSIS_MENU_SELECTION,
  RESET_INCENTIVE_ANALYSIS_MENU_SELECTION,
} from "../actions/topMenu/incentiveAnalysisActions";

const year = moment().year();
const quarter = moment().quarter();

const INITIAL_DATA = {
  year,
  quarter,
};

const incentiveAnalysisMenuReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case SET_INCENTIVE_ANALYSIS_MENU_SELECTION:
      return {
        ...state,
        year: action.payload.year,
        quarter: action.payload.quarter,
      };
    case RESET_INCENTIVE_ANALYSIS_MENU_SELECTION:
      return INITIAL_DATA;
    default:
      return state;
  }
};

export default incentiveAnalysisMenuReducer;
