import React, { useState, useEffect } from "react";
import { Form, Button, Comment } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import Note from "../../../Browser/ProjectsTable/IconGrid/NotesModal/Note/Note";

const Notes = ({
  isAdmin,
  projectID,
  projectNotes,
  notifications,
  setProjectNotes,
}) => {
  const [note, setNote] = useState("");
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState([]);
  const [clientInd, setClientInd] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getDisplayNames();
  }, []);

  useEffect(() => {
    setValue(
      Array.isArray(notifications) && notifications.length
        ? notifications.map((noti) => noti.UserID)
        : []
    );
  }, [notifications]);

  const getDisplayNames = () => {
    Axios.get(`${API_ROOT}/api/user/displaynames`).then((res) => {
      const users = JSON.parse(res.data.users);
      const options = users.map((user) => {
        return {
          key: user.UserID,
          value: user.UserID,
          text: user.DisplayName,
        };
      });
      setOptions(options);
    });
  };

  const saveNotifications = (value) => {
    setValue(value);
    Axios.post(`${API_ROOT}/api/project/merge-project-notifications`, {
      notifications: value.length ? value.join(",") : null,
      projectID,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddNoteButtonClick = () => {
    setLoading(true);
    Axios.post(`${API_ROOT}/api/project/project-note-mobile`, {
      projectID,
      note,
      includeClientInd: clientInd ? 1 : 0,
    })
      .then((res) => {
        setProjectNotes(
          res.data
            ? res.data.projectNotes
              ? JSON.parse(res.data.projectNotes)
              : []
            : []
        );
        setNote("");
        setClientInd(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const deleteNote = (noteID) => {
    Axios.delete(`${API_ROOT}/api/project/project-note-mobile/${noteID}`).then(
      (res) => {
        setProjectNotes(
          res.data
            ? res.data.projectNotes
              ? JSON.parse(res.data.projectNotes)
              : []
            : []
        );
      }
    );
  };

  return (
    <div
      style={{
        overflowY: "auto",
        height: "calc(100vh - 150px)",
        maxHeight: "calc(100vh - 150px)",
        maxWidth: "100%",
        margin: 10,
      }}
    >
      <Form size="mini">
        <Form.TextArea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          rows="3"
          label="Note"
        />
        <Form.Dropdown
          basic
          fluid
          multiple
          selection
          selectOnBlur={false}
          label="Recipients (notified each time a new note is created)"
          options={options}
          value={value}
          onChange={(e, data) => {
            saveNotifications(data.value);
          }}
        />
        {isAdmin ? (
          <Form.Checkbox
            label="Include Client"
            checked={clientInd}
            onChange={() => setClientInd(!clientInd)}
          />
        ) : null}
        <Button
          size="small"
          onClick={handleAddNoteButtonClick}
          content="Add Note"
          labelPosition="left"
          icon="edit"
          loading={loading}
          primary
          disabled={!note}
        />
      </Form>
      <Comment.Group size="small" style={{ maxWidth: "none" }}>
        <div
          style={{
            height: "calc(100vh - 410px)",
            minHeight: 160,
            overflow: "auto",
            maxWidth: "none",
          }}
        >
          {projectNotes.map((note) => (
            <Note
              deleteNote={deleteNote}
              key={note.NoteID}
              noteID={note.NoteID}
              projectID={note.ProjectID}
              author={note.DisplayName}
              text={note.NoteText}
              date={note.CreatedDate}
              includeClientInd={note.IncludeClientInd}
            />
          ))}
        </div>
      </Comment.Group>
    </div>
  );
};

export default Notes;
