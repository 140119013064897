import React from "react";
import { Dropdown } from "semantic-ui-react";
import { userIsInRole } from "../../../../../util";
import { useSelector } from "react-redux";
import Axios from "axios";
import { API_ROOT } from "../../../../../api-config";

const StatusDropdown = (props) => {
  const {
    paymentApplicationStatuses,
    i,
    projectID,
    getPaymentApplicationStatuses,
    setClientInvoicing,
  } = props;

  //   Check if user is in the accounting role
  const userIsAccounting = useSelector((state) => userIsInRole(state, 3));

  //   Check if user is in the Superuser role
  const userIsSuperuser = useSelector((state) => userIsInRole(state, 1));

  const handleOnChange = (value) => {
    const status = value;
    const paymentApplicationType = `App #${i + 1}`;

    Axios.post(`${API_ROOT}/api/invoice/payment-application-status`, {
      params: {
        projectID,
        paymentApplicationType,
        status,
      },
    })
      .then((res) => {
        if (res.data.invoicing) {
          setClientInvoicing(res.data.invoicing);
          getPaymentApplicationStatuses();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (userIsAccounting || userIsSuperuser) {
    return (
      <Dropdown
        onChange={(e, { value }) => handleOnChange(value)}
        compact
        disabled={!userIsAccounting && !userIsSuperuser}
        defaultValue={paymentApplicationStatuses[`App #${i + 1}`]}
        inline
        options={[
          {
            key: "Preparing",
            text: "Preparing",
            value: "Preparing",
            content: "Preparing",
          },
          {
            key: "Pending",
            text: "Pending",
            value: "Pending",
            content: "Pending",
          },
          {
            key: "Approved",
            text: "Approved",
            value: "Approved",
            content: "Approved",
          },
          {
            key: "Invoiced",
            text: "Invoiced",
            value: "Invoiced",
            content: "Invoiced",
          },
          {
            key: "Paid",
            text: "Paid",
            value: "Paid",
            content: "Paid",
          },
        ]}
      />
    );
  } else {
    return <span>{paymentApplicationStatuses[`App #${i + 1}`]}</span>;
  }
};

export default StatusDropdown;
