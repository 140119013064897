import numeral from "numeral";

const isNumeric = (num) => {
  return !isNaN(num);
};

export const columnDefs = [
  {
    headerName: "Year",
    field: "year",
    pinned: "left",
    minWidth: 80,
    maxWidth: 80,
  },
  {
    headerName: "Division",
    field: "division",
    minWidth: 100,
    maxWidth: 100,
    pinned: "left",
  },
  {
    headerName: "Category",
    field: "goaltype",
    minWidth: 180,
    maxWidth: 180,
    pinned: "left",
  },
  {
    headerName: "Jan",
    field: "1",
    type: "numericColumn",
    minWidth: 80,
    editable: (params) => {
      return params.data.division !== "Total";
    },
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return 0;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0");
    },
  },
  {
    headerName: "Feb",
    field: "2",
    type: "numericColumn",
    minWidth: 80,
    editable: (params) => {
      return params.data.division !== "Total";
    },
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return 0;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0");
    },
  },
  {
    headerName: "Mar",
    field: "3",
    type: "numericColumn",
    minWidth: 80,
    editable: (params) => {
      return params.data.division !== "Total";
    },
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return 0;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0");
    },
  },
  {
    headerName: "Apr",
    field: "4",
    type: "numericColumn",
    minWidth: 80,
    editable: (params) => {
      return params.data.division !== "Total";
    },
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return 0;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0");
    },
  },
  {
    headerName: "May",
    field: "5",
    type: "numericColumn",
    minWidth: 80,
    editable: (params) => {
      return params.data.division !== "Total";
    },
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return 0;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0");
    },
  },
  {
    headerName: "Jun",
    field: "6",
    type: "numericColumn",
    minWidth: 80,
    editable: (params) => {
      return params.data.division !== "Total";
    },
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return 0;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0");
    },
  },
  {
    headerName: "Jul",
    field: "7",
    type: "numericColumn",
    minWidth: 80,
    editable: (params) => {
      return params.data.division !== "Total";
    },
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return 0;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0");
    },
  },
  {
    headerName: "Aug",
    field: "8",
    type: "numericColumn",
    minWidth: 80,
    editable: (params) => {
      return params.data.division !== "Total";
    },
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return 0;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0");
    },
  },
  {
    headerName: "Sep",
    field: "9",
    type: "numericColumn",
    minWidth: 80,
    editable: (params) => {
      return params.data.division !== "Total";
    },
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return 0;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0");
    },
  },
  {
    headerName: "Oct",
    field: "10",
    type: "numericColumn",
    minWidth: 80,
    editable: (params) => {
      return params.data.division !== "Total";
    },
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return 0;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0");
    },
  },
  {
    headerName: "Nov",
    field: "11",
    type: "numericColumn",
    minWidth: 80,
    editable: (params) => {
      return params.data.division !== "Total";
    },
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return 0;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0");
    },
  },
  {
    headerName: "Dec",
    field: "12",
    type: "numericColumn",
    minWidth: 80,
    editable: (params) => {
      return params.data.division !== "Total";
    },
    valueParser: (params) => {
      const { newValue, oldValue } = params;

      if (!newValue) {
        return 0;
      } else if (isNumeric(newValue)) {
        return parseInt(newValue);
      } else {
        return oldValue;
      }
    },
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0");
    },
  },
  {
    headerName: "Total",

    field: "total",
    type: "numericColumn",
    minWidth: 80,
    cellStyle: () => {
      return { color: "gray" };
    },
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0");
    },
  },
];
