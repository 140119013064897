import React, { useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";
import ClientInvoicing from "./ClientInvoicing/ClientInvoicing";
import useIsMounted from "../../../hooks/isMounted";

const Invoice = (props) => {
  const [clientInvoicing, setClientInvoicing] = useState([]);
  const [clientInvoicingTotals, setClientInvoicingTotals] = useState({});
  const [paymentApplicationStatuses, setPaymentApplicationStatuses] = useState(
    null
  );
  const [addPayAppLoading, setAddPayAppLoading] = useState(false);

  const isMounted = useIsMounted();
  useEffect(() => {
    getPaymentApplicationStatuses();
    getClientInvoicing();
    getClientInvoicingTotals();
  }, []);

  const getClientInvoicing = () => {
    Axios.get(`${API_ROOT}/api/invoice/client-invoicing`, {
      params: {
        projectID: props.projectID,
      },
    }).then((res) => {
      if (isMounted.current) {
        setClientInvoicing(res.data.invoicing ? res.data.invoicing : []);
      }
    });
  };

  const getClientInvoicingTotals = () => {
    Axios.get(`${API_ROOT}/api/invoice/client-invoicing-totals`, {
      params: {
        projectID: props.projectID,
      },
    }).then((res) => {
      if (isMounted.current) {
        setClientInvoicingTotals(
          res.data.invoicingTotals ? res.data.invoicingTotals : {}
        );
      }
    });
  };

  const getPaymentApplicationStatuses = () => {
    Axios.get(`${API_ROOT}/api/invoice/payment-application-statuses`, {
      params: {
        projectID: props.projectID,
      },
    }).then((res) => {
      if (isMounted.current) {
        setPaymentApplicationStatuses(res.data.statuses);
      }
    });
  };

  const addPaymentApplication = () => {
    setAddPayAppLoading(true);
    Axios.post(`${API_ROOT}/api/invoice/add-payment-application`, {
      params: {
        projectID: props.projectID,
      },
    }).then((res) => {
      if (isMounted.current) {
        setClientInvoicing(res.data.invoicing ? res.data.invoicing : []);
        setAddPayAppLoading(false);
      }
      getPaymentApplicationStatuses();
      getClientInvoicingTotals();
    });
  };

  return (
    <Container
      fluid
      className="Invoice"
      style={{
        paddingTop: 13,

        overflow: "auto",
      }}
    >
      {/* <Header style={{ color: "#fe5000", display: "block" }} as="h1">
        Invoice
      </Header> */}
      <ClientInvoicing
        projectID={props.projectID}
        clientInvoicing={clientInvoicing}
        clientInvoicingTotals={clientInvoicingTotals}
        setClientInvoicing={setClientInvoicing}
        getClientInvoicingTotals={getClientInvoicingTotals}
        paymentApplicationStatuses={paymentApplicationStatuses}
        getPaymentApplicationStatuses={getPaymentApplicationStatuses}
        addPayAppLoading={addPayAppLoading}
        addPaymentApplication={addPaymentApplication}
      />
    </Container>
  );
};

export default Invoice;
