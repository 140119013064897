import React from "react";
import { Table } from "semantic-ui-react";

const AlternateTableDetailRow = ({ contractType, category, conditions }) => {
  function groupBy(objectArray, property) {
    return objectArray.reduce(function(acc, obj) {
      var key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  // const filteredConditions = conditions.filter(
  //   (item) => item.Category === category
  // );
  const grouped = groupBy(conditions, "subcategoryCode");
  const subCategories = [
    ...new Set(conditions.map((item) => item.subcategoryCode)),
  ];

  return (
    <>
      {subCategories.map((subCategory, index) => (
        <React.Fragment key={index}>
          <Table.Row key={subCategory}>
            <Table.Cell>{contractType}</Table.Cell>
            <Table.Cell style={{ color: "gray", fontWeight: "bold" }}>
              {subCategory}
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          {grouped[subCategory].map((condition, index) => {
            return (
              <Table.Row key={index}>
                <Table.Cell></Table.Cell>
                <Table.Cell>
                  {condition.conditionName +
                    " " +
                    (condition.internalComments
                      ? "- " + condition.internalComments
                      : "")}
                </Table.Cell>
                <Table.Cell style={{ color: "#fe5000", fontWeight: "bold" }}>
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(condition.preTax)}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </React.Fragment>
      ))}
    </>
  );
};

export default AlternateTableDetailRow;
