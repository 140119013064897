import React, { useState, useEffect, Fragment } from "react";
import {
  Modal,
  Button,
  Form,
  Table,
  Message,
  TextArea,
} from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import CriteriaOptions from "./CriteriaOptions";

const AddInspectionModal = ({ open, setOpen, getSafetySurveyData }) => {
  const [KPIEventID, setKPIEventID] = useState("");
  const [projectOptions, setProjectOptions] = useState([]);
  const [sections, setSections] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [projectID, setProjectID] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [comments, setComments] = useState("");
  const [surveyDate, setSurveyDate] = useState("");
  const [edited, setEdited] = useState(false);
  const [location, setLocation] = useState("");

  useEffect(() => {
    setKPIEventID(uuidv4());
  }, [open]);

  useEffect(() => {
    getProjects();
    getKPICriteria();
  }, []);

  useEffect(() => {
    if (projectID && projectOptions.length) {
      setProjectName(
        projectOptions.filter((option) => option.value === projectID)[0]
          .projectname
      );
    }
  }, [projectID, projectOptions]);

  const getProjects = () => {
    Axios.get(`${API_ROOT}/api/kpi/project-options`)
      .then((res) => {
        setProjectOptions(res.data.projectOptions);
      })
      .catch((err) => console.log(err));
  };

  const getKPICriteria = () => {
    Axios.get(`${API_ROOT}/api/kpi/safety-criteria`)

      .then((res) => {
        setCriteria(JSON.parse(res.data.criteria));
        setSections(JSON.parse(res.data.sections));
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    if (edited) {
      handleSave();
    } else {
      setOpen(false);
      setProjectID(null);
      setSurveyDate("");
      setLocation("");
      setComments("");
      setEdited(false);
    }
  };

  const saveResult = (value, id) => {
    Axios.post(`${API_ROOT}/api/kpi/safety-results`, {
      params: {
        KPIEventID,
        projectID,
        location,
        comments,
        surveyDate,
        criteriaID: id,
        value,
      },
    })
      .then((res) => {
        // console.log(res);
        setEdited(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSave = () => {
    Axios.post(`${API_ROOT}/api/kpi/safety-results`, {
      params: {
        KPIEventID,
        projectID,
        location,
        comments,
        surveyDate,
        criteriaID: null,
        value: null,
      },
    })
      .then((res) => {
        getSafetySurveyData(true);
        setOpen(false);
        setProjectID(null);
        setSurveyDate("");
        setLocation("");
        setComments("");
        setEdited(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      closeOnDimmerClick={false}
      open={open}
      onClose={handleClose}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>
        Safety Inspection{projectName ? `: ${projectName}` : null}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Dropdown
            selectOnBlur={false}
            search
            selection
            required
            label="Project"
            onChange={(e, { value }) => setProjectID(value)}
            value={projectID}
            options={projectOptions}
          />
          <Form.Group widths="equal">
            {/* <Form.Input
              disabled={true}
              label="Project Name"
              value={projectName}
            /> */}
            <Form.Input
              // disabled={true}
              label="Area of Inspection"
              value={location}
              onChange={(e, { value }) => setLocation(value)}
            />
            <SemanticDatepicker
              datePickerOnly={true}
              format="MM-DD-YYYY"
              label={
                <span>
                  Survey Date{" "}
                  <span
                    style={{
                      color: "#db2828",
                    }}
                  >
                    *
                  </span>
                </span>
              }
              onChange={(e, { value }) => setSurveyDate(value)}
            />
          </Form.Group>
        </Form>
        {/* <Header>{projectName ? `Project: ${projectName}` : null}</Header> */}
        <Message
          color="blue"
          content="Leave the score unchanged if a particular criteria is not applicable to the current project."
        />

        {Array.isArray(sections) && sections.length
          ? sections.map((object) => (
              <Fragment key={object.section}>
                <Table striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width="12">
                        {object.section}
                      </Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Header>
                    {/* <Table.Row>
                      <Table.HeaderCell>Criteria</Table.HeaderCell>
                      <Table.HeaderCell>Score</Table.HeaderCell>
                    </Table.Row> */}
                  </Table.Header>
                  <Table.Body>
                    {Array.isArray(criteria) && criteria.length
                      ? criteria
                          .filter((obj) => obj.Section === object.section)
                          .map((item) => (
                            <Table.Row key={item.ID}>
                              <Table.Cell>
                                {item.RowNumber + ") " + item.Criteria}
                              </Table.Cell>
                              <Table.Cell>
                                <CriteriaOptions
                                  setEdited={setEdited}
                                  projectID={projectID}
                                  location={location}
                                  comments={comments}
                                  surveyDate={surveyDate}
                                  KPIEventID={KPIEventID}
                                  options={item.Options}
                                  id={item.ID}
                                  saveResult={saveResult}
                                />
                              </Table.Cell>
                            </Table.Row>
                          ))
                      : null}
                  </Table.Body>
                </Table>
              </Fragment>
            ))
          : null}
        <Form>
          <Form.Field>
            <label>Solutions</label>
            <TextArea
              row="8"
              onChange={(e, { value }) => setComments(value)}
              value={comments}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AddInspectionModal;
