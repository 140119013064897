import React, { useEffect, useState } from "react";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import { Table, TableHeader } from "semantic-ui-react";

const ResponseQuestions = ({ KPIEventID, setLoading, comments }) => {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (KPIEventID) {
      Axios.post(`${API_ROOT}/api/kpi/survey-questions`, {
        projectID: null,
        type: null,
        kpiEventID: KPIEventID,
        clientDisplayInd: 0,
      })
        .then((res) => {
          setLoading(false);
          setQuestions(res.data.surveyData.questions);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [KPIEventID]);
  return (
    <div className="ResponseQuestion">
      <Table>
        <TableHeader>
          <Table.Row>
            <Table.HeaderCell>Question</Table.HeaderCell>
            <Table.HeaderCell>Score</Table.HeaderCell>
          </Table.Row>
        </TableHeader>
        <Table.Body>
          {questions.map((question) => {
            return (
              <Table.Row key={question.id}>
                <Table.Cell>{question.criteria}</Table.Cell>
                <Table.Cell>{question.value}</Table.Cell>
              </Table.Row>
            );
          })}
          <Table.Row>
            <Table.Cell colSpan={2}>Comments: "{comments}"</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};

export default ResponseQuestions;
