import React, { useEffect, useState } from "react";
import ConditionInfo from "./ConditionInfo";
import { useParams } from "react-router-dom";
import moment from "moment";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import { useSelector } from "react-redux";
import { getUser } from "../../../../util";

const ConditionInfoWrapper = ({ projectInfo, fetchProjectInfo }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const user = useSelector((state) => getUser(state));

  let { id, category } = useParams();
  const [conditions, setConditions] = useState([]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    if (projectInfo && user) {
      setIsAdmin(
        user.hasOwnProperty("Roles") && Array.isArray(user.Roles)
          ? user.Roles.some(
              (role) => role.Role === "Office" || role.Role === "Superuser"
            ) ||
              projectInfo.projectManager === user.UserID ||
              projectInfo.projectEngineer === user.UserID ||
              projectInfo.superintendent === user.UserID
          : false
      );
    }
  }, [projectInfo.projectID, user.UserID]);

  useEffect(() => {
    if (id && category) {
      let projectID = parseInt(id);
      Axios.get(`${API_ROOT}/api/condition/conditions-by-category-mobile`, {
        params: {
          projectID,
          subCategoryCode: category,
        },
      })
        .then((res) => {
          if (res.data) {
            setConditions(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, category]);

  const conditionOptions = conditions.map((condition) => {
    return {
      key: condition.ConditionID,
      text: condition.ConditionName,
      value: condition.ConditionID,
      desc: condition.InternalComments,
      subscope: condition.SubcontractorScope,
      disablelogging: condition.disableLoggingInd,
    };
  });

  return (
    <ConditionInfo
      conditionOptions={conditionOptions}
      date={date}
      setDate={setDate}
      category={category}
      isAdmin={isAdmin}
      user={user}
      projectID={projectInfo.projectID}
      projectInfo={projectInfo}
      fetchProjectInfo={fetchProjectInfo}
    />
  );
};

export default ConditionInfoWrapper;
