import React, { useState, useEffect } from "react";
import {
  Container,
  Icon,
  Input,
  Segment,
  Modal,
  Header,
  Button,
} from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
import numeral from "numeral";
import moment from "moment";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";
import EditInspectionModal from "./EditInspectionModal/EditInspectionModal";
import AddInspectionModal from "./AddInspectionModal/AddInspectionModal";

const percentageFormatter = (params) => {
  return numeral(params.data.score).format("0 %");
};

const dateFormatter = (params) => {
  return moment(params.data.SurveyDate).format("l");
};

const dateComparator = (filterLocalDateAtMidnight, cellValue) => {
  //using moment js
  var dateAsString = moment(new Date(cellValue), "YYYY-MM-DD").format(
    "DD/MM/YYYY"
  );
  var dateParts = dateAsString.split("/");
  var cellDate = new Date(
    Number(dateParts[2]),
    Number(dateParts[1]) - 1,
    Number(dateParts[0])
  );

  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    return 0;
  }

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }

  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
};

const Warehouse = (props) => {
  const [gridApi, setGridApi] = useState();
  // const [columnApi, setColumnApi] = useState();
  const [rowData, setRowData] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const getWarehouseData = (refresh) => {
    gridApi && gridApi.showLoadingOverlay();
    Axios.get(`${API_ROOT}/api/kpi/warehouse-events`)
      .then((res) => {
        setRowData(res.data.data || []);
        gridApi && gridApi.hideOverlay();
        if (refresh) {
          gridApi.refreshCells();
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getWarehouseData(false);
  }, []);

  const columnDefs = [
    {
      headerName: "",
      width: 100,
      field: "KPIEventID",
      suppressMovable: true,
      // headerComponentParams: {
      //   template: <h3>Hi</h3>,
      // },
      headerComponentFramework: () => {
        return (
          <Icon
            link
            size="large"
            name="plus square outline"
            onClick={() => setAddModalOpen(true)}
          />
        );
      },
      cellRendererFramework: function(params) {
        return (
          <span>
            <Icon
              size="large"
              title="View inspection"
              link
              onClick={() => setEditModalOpen(true)}
              name="search"
            />
            {/* <Icon
              size="large"
              title="Delete inspection"
              link
              onClick={() => handleDeleteClick(params.value)}
              name="close"
            /> */}
          </span>
        );
      },
    },
    {
      headerName: "Score",
      field: "score",
      sortable: true,
      filter: true,
      valueGetter: percentageFormatter,
    },
    {
      headerName: "Inspection Date",
      field: "SurveyDate",
      sortable: true,
      sort: "desc",
      filter: "agDateColumnFilter",
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: dateComparator,
      },
      // filterValueGetter: dateFormatter,
      valueGetter: dateFormatter,
    },
    {
      headerName: "Inspected By",
      field: "surveyor",
      sortable: true,
      filter: true,
    },
  ];

  const onFilterTextBoxChanged = (e, { value }) => {
    gridApi.setQuickFilter(value);
  };

  const deleteInspection = (KPIEventID) => {
    Axios.delete(`${API_ROOT}/api/kpi/kpi-event`, {
      data: {
        KPIEventID,
      },
    })
      .then((res) => {
        console.log(res.data.message);
        setDeleteModalOpen(false);
      })
      .catch((err) => console.log(err));
  };

  const onRemoveSelected = () => {
    var selectedData = gridApi.getSelectedRows();
    var res = gridApi.applyTransaction({ remove: selectedData });

    if (res) {
      res.remove.forEach((node) => deleteInspection(node.data.KPIEventID));
    }
  };

  // const handleDeleteClick = () => {
  //   setDeleteModalOpen(true);
  // };

  return (
    <Container style={{ paddingLeft: 10, paddingRight: 10 }} fluid>
      <div>
        <h1 style={{ color: "rgb(254, 80, 0)", marginLeft: 40 }}>Warehouse</h1>
        <Segment style={{ marginTop: -64 }} basic textAlign="right">
          <Input
            type="text"
            id="filter-text-box"
            placeholder="Filter..."
            onChange={onFilterTextBoxChanged}
          />
        </Segment>
      </div>

      <div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>
        <AgGridReact
          enableCellChangeFlash={true}
          columnDefs={columnDefs}
          rowData={rowData}
          rowSelection="single"
          // onRowSelected={(params) => {
          //   console.log(params);
          // }}
          onGridReady={(params) => {
            setGridApi(params.api);
            params.api.sizeColumnsToFit();
            window.onresize = () => {
              params.api.sizeColumnsToFit();
            };
            // setColumnApi(params.columnApi);
          }}
        />
      </div>
      <Modal
        basic
        size="mini"
        open={deleteModalOpen}
        onOpen={() => setDeleteModalOpen(true)}
        onClose={() => setDeleteModalOpen(false)}
      >
        <Header icon="trash" content="Delete Inspection" />
        <Modal.Content>
          <p>Are you sure you want to delete this inspection?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="red"
            inverted
            onClick={() => setDeleteModalOpen(false)}
          >
            <Icon name="remove" /> No
          </Button>
          <Button color="green" inverted onClick={onRemoveSelected}>
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
      {editModalOpen ? (
        <EditInspectionModal
          getWarehouseData={getWarehouseData}
          open={editModalOpen}
          setOpen={setEditModalOpen}
          gridApi={gridApi}
        />
      ) : null}
      <AddInspectionModal
        getWarehouseData={getWarehouseData}
        open={addModalOpen}
        setOpen={setAddModalOpen}
        gridApi={gridApi}
      />
    </Container>
  );
};

export default Warehouse;
