import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import Axios from "axios";
import { saveAs } from "file-saver";

import { API_ROOT } from "../../../api-config";
import "./ContractToolbar.scss";

const ContractToolbar = (props) => {
  const [downloadUrl, setDownloadUrl] = useState();
  const [showDownloadButton, setShowDownloadButton] = useState(false);

  const checkContractExists = (guid, timer) => {
    Axios.get(`${API_ROOT}/contract/checkcontractexists`, {
      params: {
        guid,
      },
    }).then((res) => {
      if (res.data.contractExists) {
        setShowDownloadButton(true);
        setDownloadUrl(res.data.uri);
        clearTimeout(timer);
      }
    });
  };

  useEffect(() => {
    if (props.approvedInd === 1) {
      let timer = setTimeout(() => {
        timer = setTimeout(() => checkContractExists(props.guid, timer), 1000);
      }, 1000);
    }
  }, []);

  return (
    <Container className="ContractToolbar">
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            {showDownloadButton === true ? (
              <Segment placeholder>
                <Header icon>
                  <Icon name="pdf file outline" />
                  Contract is ready for download.
                </Header>
                <Button
                  icon
                  primary
                  labelPosition="left"
                  onClick={() => saveAs(downloadUrl, `${props.guid}.pdf`)}
                >
                  <Icon name="download" />
                  Download .pdf
                </Button>
              </Segment>
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default ContractToolbar;
