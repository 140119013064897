import React from "react";
import { Form } from "semantic-ui-react";

const SearchBar = ({ filterTerm, setFilterTerm }) => {
  return (
    <div>
      <Form>
        <Form.Input
          transparent
          style={{
            height: 40,
            paddingLeft: 10,
          }}
          placeholder="Search..."
          // icon="search"
          value={filterTerm}
          onChange={(e, { value }) => setFilterTerm(value)}
          type="text"
        />
      </Form>
    </div>
  );
};

export default SearchBar;
