import React, { useState, useEffect, useRef } from "react";
import { Rating, Form } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";

const CriteriaOptions = ({
  options,
  id,
  KPIEventID,
  userID,
  location,
  surveyDate,
  setEdited,
  result,
  open,
}) => {
  const [radio, setRadio] = useState(null);
  const [rating, setRating] = useState(null);
  const [selection, setSelection] = useState(null);

  const prevRadioRef = useRef();
  const prevRatingRef = useRef();
  const prevSelectionRef = useRef();
  useEffect(() => {
    prevRadioRef.current = radio;
    prevRatingRef.current = rating;
    prevSelectionRef.current = selection;
  });
  const prevRadio = prevRadioRef.current;
  const prevRating = prevRatingRef.current;
  const prevSelection = prevSelectionRef.current;

  useEffect(() => {
    if (radio && prevRadio) {
      save(radio);
    }
  }, [radio]);

  useEffect(() => {
    if (rating && prevRating) {
      save(rating - 1);
    }
  }, [rating]);

  useEffect(() => {
    if (selection && prevSelection) {
      save(selection);
    }
  }, [selection]);

  useEffect(() => {
    if (result) {
      if (result.Options === "No,Yes" || result.Options === "Low,OK") {
        setRadio(result.Value);
      } else if (result.Options === "0,1,2,3,4") {
        setRating(result.Value);
      } else if (result.Options === ">300,-300,-200,-100,C") {
        setSelection(result.Value);
      }
    }
  }, [result]);

  // useEffect(() => {
  //   if (!open) {
  //     setSelection(null);
  //     setRating(null);
  //     setRadio(null);
  //   }
  // }, [open]);

  const save = (value) => {
    Axios.post(`${API_ROOT}/api/kpi/uniform-results`, {
      params: {
        KPIEventID,
        userID,
        location,
        surveyDate,
        criteriaID: id,
        value,
      },
    })
      .then((res) => {
        // console.log(res);
        setEdited(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const disabled = !userID || !van || !surveyDate;

  if (options === "0,1,2,3,4") {
    return (
      <Rating
        disabled={true}
        onRate={(e, { rating }) => setRating(rating)}
        size="huge"
        clearable
        icon="star"
        maxRating={5}
        rating={rating}
      />
    );
  } else if (options === "No,Yes") {
    return (
      <Form>
        <Form.Group style={{ marginBottom: 0 }} inline>
          <Form.Radio
            readOnly={true}
            onChange={() => setRadio("No")}
            label="No"
            checked={radio === "No"}
          />
          <Form.Radio
            readOnly={true}
            onChange={() => setRadio("Yes")}
            label="Yes"
            checked={radio === "Yes"}
          />
        </Form.Group>
      </Form>
    );
  } else if (options === "Low,OK") {
    return (
      <Form>
        <Form.Group style={{ marginBottom: 0 }} inline>
          <Form.Radio
            readOnly={true}
            onChange={() => setRadio("Low")}
            label="Low"
            checked={radio === "Low"}
          />
          <Form.Radio
            readOnly={true}
            onChange={() => setRadio("OK")}
            label="OK"
            checked={radio === "OK"}
          />
        </Form.Group>
      </Form>
    );
  } else if (options === ">300,-300,-200,-100,C") {
    return (
      <Form>
        <Form.Dropdown
          // className="readonly"
          readOnly
          // disabled={true}
          style={{ width: 110 }}
          //   width={110}
          selectOnBlur={false}
          selection
          compact
          // onChange={(e, { value }) => setSelection(value)}
          value={selection}
          options={[
            { key: ">300", text: ">300", value: ">300" },
            { key: "-300", text: "-300", value: "-300" },
            { key: "-200", text: "-200", value: "-200" },
            { key: "-100", text: "-100", value: "-100" },
            { key: "C", text: "Current", value: "C" },
          ]}
        />
      </Form>
    );
  } else {
    return <>{options}</>;
  }
};

export default CriteriaOptions;
