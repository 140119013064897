export const SET_COMPANY_GOALS_MENU_SELECTION =
  "SET_COMPANY_GOALS_MENU_SELECTION";
export const RESET_COMPANY_GOALS_MENU_SELECTION =
  "RESET_COMPANY_GOALS_MENU_SELECTION";

export const setCompanyGoalsMenuSelection = (year) => ({
  type: SET_COMPANY_GOALS_MENU_SELECTION,
  payload: year,
});

export const resetCompanyGoalsMenuSelection = () => ({
  type: RESET_COMPANY_GOALS_MENU_SELECTION,
});
