import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  USER_REQUEST_SUCCESS,
  LOGOUT,
} from "../constants/userConstants";

export default (state = {}, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        loggedIn: action.payload.isAuthenticated,
        name: action.payload.user.displayName,
        upn: action.payload.user.upn,
      });

    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        loggedIn: false,
      });

    case USER_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        user: action.payload,
      });

    case LOGOUT:
      return Object.assign({}, state, {
        user: null,
        loggedIn: false,
        name: null,
        upn: null,
      });

    default:
      return state;
  }
};
