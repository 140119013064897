import React from "react";
// import { Link } from "react-router-dom";
import { Header } from "semantic-ui-react";

const FourOhFour = (props) => (
  <div>
    <center style={{ overflowX: "auto" }}>
      <Header
        as="h1"
        style={{
          width: 600,
          height: 140,
          display: "block",
          margin: "auto",
          position: "relative",
          textAlign: "center",
          fontSize: 100,
          marginTop: "30vh",
        }}
      >
        NOT FOUND
      </Header>
      {/* <Link to="/browser">Return to Home Page</Link> */}
    </center>
  </div>
);

export default FourOhFour;
