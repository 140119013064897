import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Axios from "axios";
import {
  Table,
  Modal,
  Form,
  TextArea,
  Icon,
  Button,
  Dropdown,
  Header,
} from "semantic-ui-react";

import "./ClientContractTracking.scss";
import { API_ROOT } from "../../../../api-config";
import ApprovedClientContracts from "./ApprovedClientContracts/ApprovedClientContracts";
import PendingClientContracts from "./PendingClientContracts/PendingClientContracts";
import InactiveClientContracts from "./InactiveClientContracts/InactiveClientContracts";
import ClientContractDetailWrapper from "./ClientContractDetailWrapper/ClientContractDetailWrapper";

import { setInclusionMenuSelection } from "../../../../actions/project/inclusion/inclusionActions";
import ClientContractDetail from "./ClientContractDetailWrapper/ClientContractDetail/ClientContractDetail";
import useIsMounted from "../../../../hooks/isMounted";

import CurrentProject, {
  CurrentProjectProvider,
} from "../../../../screens/projects/CurrentProject";
import { getContracts } from "../../../../api/project";
import { useShowV1 } from "../../../../hooks/useQuery";

// Create our number formatter.
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const ClientContractTracking = (props) => {
  const { useV2 } = useContext(CurrentProject);
  const [approvedContracts, setApprovedContracts] = useState([]);
  const [pendingContracts, setPendingContracts] = useState([]);
  const [inactiveContracts, setInactiveContracts] = useState([]);
  const [proposalCompanyID, setProposalCompanyID] = useState(null);
  const [grandTotal, setGrandTotal] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [confirmProposalModalOpen, setConfirmProposalModalOpen] = useState(
    false
  );
  const [proposalToSend, setProposalToSend] = useState("");
  const [proposalSending, setProposalSending] = useState(false);
  const [contractRecipients, setContractRecipients] = useState([]);
  const [currentRecipientValue, setCurrentRecipientValue] = useState([]);
  const [emailMessage, setEmailMessage] = useState("");
  const [
    clientInclusionDescriptionModalOpen,
    setClientInclusionDescriptionModalOpen,
  ] = useState(false);

  const [contractErrors, setContractErrors] = useState([]);
  const [PMEmail, setPMEmail] = useState("");

  const isMounted = useIsMounted();

  const splitScreen = useShowV1();
  const [v2Contracts, setV2Contracts] = useState(null);

  const getContractByGUIDTotal = (type, guid) => {
    if (!v2Contracts) return null;
    const contract = v2Contracts.find(
      (x) => x.type === type && x.guid === guid
    );
    if (!contract) return null;
    return contract.grand_total;
  };

  const getContractsByTypeTotal = (type) => {
    if (!v2Contracts) return null;
    return v2Contracts
      .filter((x) => x.type === type)
      .reduce((a, b) => a + b.grand_total, 0);
  };

  const getContractsTotal = () => {
    if (!v2Contracts) return null;
    return v2Contracts.reduce((a, b) => a + b.grand_total, 0);
  };

  useEffect(() => {
    Axios.get(`${API_ROOT}/api/contract/clientcontracts`, {
      params: {
        projectID: props.projectID,
      },
    })
      .then((res) => {
        loadContracts(res);
      })
      .catch((err) => {
        console.log(err);
      });
    getClientContractsTotals();
    getProjectManagerEmail();
    // getClientContactEmail();

    getContracts(props.projectID).then(setV2Contracts);
  }, [props.projectID]);

  const getClientContactEmail = () => {
    Axios.get(`${API_ROOT}/api/project/client-contact-email`, {
      params: {
        projectID: props.projectID,
      },
    }).then((res) => {
      const clientEmail = res.data.email.email;
      if (clientEmail && isMounted.current) {
        setContractRecipients([
          // ...contractRecipients,
          { text: clientEmail, value: clientEmail },
        ]);
      }
    });
  };

  const getProjectManagerEmail = () => {
    Axios.get(`${API_ROOT}/api/project/project-manager-email`, {
      params: {
        projectID: props.projectID,
      },
    }).then((res) => {
      const PMEmail = res.data.emailAddress.emailAddress;
      if (PMEmail && isMounted.current) {
        setPMEmail(PMEmail);
      }
    });
  };

  const loadContracts = (res) => {
    if (res.data.clientContracts && isMounted.current) {
      const clientContracts = res.data.clientContracts;
      let approved = [];
      let pending = [];
      let inactive = [];
      approved = clientContracts.filter(
        (contract) => contract.ContractStageCategory === "Approved"
      );

      pending = clientContracts.filter(
        (contract) => contract.ContractStageCategory === "Pending"
      );
      inactive = clientContracts.filter(
        (contract) => contract.ContractStageCategory === "Inactive"
      );
      setApprovedContracts(approved);
      setPendingContracts(pending);
      setInactiveContracts(inactive);
    }
  };

  const getClientContractsTotals = () => {
    Axios.get(`${API_ROOT}/api/contract/clientcontractstotals`, {
      params: {
        projectID: props.projectID,
      },
    })
      .then((res) => {
        if (isMounted.current) {
          setGrandTotal(res.data.clientContractsTotals.GrandTotal || 0);
          // setInvoicedTotal(res.data.clientContractsTotals.InvoicedTotal || 0);
          // setReceivedTotal(res.data.clientContractsTotals.ReceivedTotal || 0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setContractSubmitted = (guid) => {
    Axios.post(`${API_ROOT}/api/contract/setclientcontractsubmitted`, {
      params: {
        guid,
        projectID: props.projectID,
      },
    })
      .then((res) => {
        loadContracts(res);
        handleClose();
        setProposalSending(false);
        setProposalToSend("");
      })
      .catch((err) => {
        console.log(err);
      });
    getClientContractsTotals();
  };

  const sendProposal = (guid, companyID) => {
    const email = currentRecipientValue ? currentRecipientValue.join(";") : "";

    if (!email.length || !emailMessage.length) {
      return;
    }

    setProposalSending(true);

    let baseUrl = "";

    const hostname = window && window.location && window.location.hostname;

    if (hostname === "nexsys1-staging.azurewebsites.net") {
      baseUrl = "https://nexsys1-staging.azurewebsites.net";
    } else if (hostname === "ns1.nexus5group.com") {
      baseUrl = "https://ns1.nexus5group.com";
    } else if (hostname === "nexsys1.azurewebsites.net") {
      baseUrl = "https://nexsys1.azurewebsites.net";
    } else {
      // backEndHost = 'https://nexsys1api-staging.azurewebsites.net';
      baseUrl = "http://localhost:3000";
    }

    const company =
      pendingContracts[0] && proposalCompanyID
        ? pendingContracts[0].Client.find(
            (client) => client.companyid === proposalCompanyID
          )
        : null;
    const proposal = company
      ? company.Contracts.find(
          (contract) =>
            contract.GUID === proposalToSend && contract.displayType === "data"
        )
      : null;
    const projectName = proposal ? proposal.projectName : "";
    const workLocationName = proposal ? proposal.workLocationName : "";
    const contractType = proposal ? proposal.ContractType : "";

    let subject = "";
    if (contractType === "Base") {
      subject = `Nexus 5 Proposal - ${props.projectID}-${projectName}${
        workLocationName ? "-" + workLocationName : ""
      }`;
    } else {
      subject = `Nexus 5 - ${contractType} - ${props.projectID}-${projectName}${
        workLocationName ? "-" + workLocationName : ""
      }`;
    }

    let message = "";
    if (!useV2()) {
      if (contractType === "Base") {
        message = `${emailMessage}<br /><br />Click <a href='${baseUrl}/contract/?r=c&id=${guid}&c=${companyID}'>here</a> to view the proposal.</p>`;
      } else {
        message = `${emailMessage}<br /><br />Click <a href='${baseUrl}/contract/?r=c&id=${guid}&c=${companyID}'>here</a> to view ${contractType}.</p>`;
      }
    } else {
      if (contractType === "Base") {
        message = `${emailMessage}<br /><br />Click <a href='${baseUrl}/contracts/${guid}/client'>here</a> to view the proposal.</p>`;
      } else {
        message = `${emailMessage}<br /><br />Click <a href='${baseUrl}/contracts/${guid}/client'>here</a> to view ${contractType}.</p>`;
      }
    }

    // SEND PROPOSAL EMAIL
    Axios.post(`${API_ROOT}/send-email`, {
      email,
      cc: PMEmail,
      bcc: "SoftwareDevelopment@cohenesrey.com",
      subject,
      // text: `${emailMessage}. Please visit ${baseUrl}/contract/?r=c&id=${guid}&c=${companyID} to view the proposal.`,
      html: message,
    })
      .then((res) => {
        setContractSubmitted(guid);
      })
      .catch((err) => {
        setProposalSending(false);
        alert(err);
      });
  };

  const checkContractReady = (guid, companyID) => {
    setCurrentRecipientValue([]);

    getClientContactEmail();
    Axios.get(`${API_ROOT}/api/contract/checkclientcontractready`, {
      params: {
        guid,
      },
    })
      .then((res) => {
        if (isMounted.current) {
          const {
            contractDescriptionDoneInd,
            clientWorkDaysDoneInd,
            // inclusionDescriptionsDoneInd,
            baseContractReadyInd,
            hasAlternateConditionsInd,
            hasBudgetConditionsInd,
          } = res.data.doneInds;

          // if (inclusionDescriptionsDoneInd === 1) {
          //   // Open client inclusions descriptions modal
          //   setClientInclusionDescriptionModalOpen(true);
          //   setProposalToSend("");
          // } else

          const err = [];
          if (clientWorkDaysDoneInd === 0)
            err.push("Client work days must be filled out.");
          if (contractDescriptionDoneInd === 0)
            err.push("Contract description is missing.");
          if (baseContractReadyInd === 0)
            err.push("Base contract must be approved before CO can be sent.");
          if (hasAlternateConditionsInd === 1)
            err.push("This project has unresolved alternate inclusions.");
          if (hasBudgetConditionsInd === 1)
            err.push("This project still has placeholder budget inclusions.");
          setContractErrors(err);

          setModalTitle("Project Errors Detected");

          if (
            clientWorkDaysDoneInd === 0 ||
            contractDescriptionDoneInd === 0 ||
            baseContractReadyInd === 0 ||
            hasAlternateConditionsInd === 1 ||
            hasBudgetConditionsInd === 1
          ) {
            setModalOpen(true);
            setProposalToSend("");
          } else {
            getClientContactEmail();
            setProposalToSend(guid);
            setProposalCompanyID(companyID);
            setConfirmProposalModalOpen(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDaysBlur = (guid, clientWorkDays) => {
    Axios.post(`${API_ROOT}/api/contract/clientworkdays`, {
      params: {
        guid,
        clientWorkDays: clientWorkDays ? parseInt(clientWorkDays) : null,
      },
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e, { value }) => {
    setCurrentRecipientValue(value);
  };

  const handleAddition = (e, { value }) => {
    setContractRecipients((contractRecipients) => [
      ...contractRecipients,
      {
        text: value,
        value,
      },
    ]);
  };

  const handleClose = () => {
    setConfirmProposalModalOpen(false);
    setContractRecipients([]);
    setEmailMessage("");
  };

  const dispatch = useDispatch();

  const handleInclusionChange = (event, data) => {
    const subCategoryCode = data.value;

    const options = data.options;
    const selectedOption = options.filter(
      (option) => option.text === subCategoryCode
    );
    const categoryID = selectedOption[0].categoryid;
    dispatch(
      setInclusionMenuSelection({
        selectedCategoryID: categoryID,
        selectedSubCategoryCode: subCategoryCode,
      })
    );
    props.history.push(`/browser/projects/inclusions/${props.projectID}`);
  };

  const uploadClientContract = (contractFile, contractGUID) => {
    const form = new FormData();
    form.append("file", contractFile[0], contractGUID + ".pdf");
    form.append("contractGUID", contractGUID);

    Axios.post(`${API_ROOT}/api/contract/uploadclientcontract`, form).then(
      (res) => {
        loadContracts(res);
      }
    );
  };

  const setUnsignedContract = (guid, companyID) => {
    Axios.post(`${API_ROOT}/api/contract/no-signature-client`, {
      params: {
        recipient: "c",
        guid,
        companyID,
      },
    })
      .then((res) => {
        console.log(res);
        loadContracts(res);
        // window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <CurrentProjectProvider>
      <div className="ClientContractTracking" style={{ padding: "1rem" }}>
        <h1 style={{ color: "rgb(254, 80, 0)" }}>Client Contracts</h1>

        <Table structured unstackable compact="very" style={{ fontSize: 12 }}>
          <ApprovedClientContracts
            approvedContracts={approvedContracts}
            handleInclusionChange={handleInclusionChange}
            uploadClientContract={uploadClientContract}
            getContractByGUIDTotal={(guid) =>
              getContractByGUIDTotal("Approved", guid)
            }
            getContractsByTypeTotal={() => getContractsByTypeTotal("Approved")}
          />
          <PendingClientContracts
            handleDaysBlur={handleDaysBlur}
            checkContractReady={checkContractReady}
            loadContracts={loadContracts}
            pendingContracts={pendingContracts}
            handleInclusionChange={handleInclusionChange}
            uploadClientContract={uploadClientContract}
            setUnsignedContract={setUnsignedContract}
            getContractByGUIDTotal={(guid) =>
              getContractByGUIDTotal("Pending", guid)
            }
            getContractsByTypeTotal={() => getContractsByTypeTotal("Pending")}
          />
          <InactiveClientContracts
            handleDaysBlur={handleDaysBlur}
            inactiveContracts={inactiveContracts}
            loadContracts={loadContracts}
            handleInclusionChange={handleInclusionChange}
            getContractByGUIDTotal={(guid) =>
              getContractByGUIDTotal("Inactive", guid)
            }
            getContractsByTypeTotal={() => getContractsByTypeTotal("Inactive")}
          />
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="7"></Table.HeaderCell>
              {splitScreen && (
                <>
                  <Table.HeaderCell
                    style={{ color: "purple", fontWeight: "bold" }}
                    textAlign="center"
                  >
                    <span title="OLD">{formatter.format(grandTotal)}</span>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <span title="NEW">
                      {formatter.format(getContractsTotal())}
                    </span>
                  </Table.HeaderCell>
                </>
              )}
              {!splitScreen && (
                <Table.HeaderCell
                  style={{ color: "purple", fontWeight: "bold" }}
                  textAlign="center"
                >
                  {useV2() && formatter.format(getContractsTotal())}
                  {!useV2() && formatter.format(grandTotal)}
                </Table.HeaderCell>
              )}
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <ClientContractDetailWrapper
          projectID={props.projectID}
          WrappedComponent={ClientContractDetail}
        />
        <Modal
          size="small"
          open={confirmProposalModalOpen}
          onClose={() => handleClose()}
        >
          <Modal.Header>Send Contract</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>Add Contract Recipients' Email Addresses</label>
                <Dropdown
                  options={contractRecipients}
                  search
                  noResultsMessage=""
                  closeOnChange={true}
                  selection
                  selectOnBlur={false}
                  fluid
                  multiple
                  allowAdditions
                  value={currentRecipientValue}
                  onAddItem={handleAddition}
                  onChange={handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Add Message</label>
                <TextArea
                  onChange={(e, { value }) => setEmailMessage(value)}
                  value={emailMessage}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color="yellow" onClick={() => handleClose()}>
              <Icon name="cancel" /> Cancel
            </Button>
            <Button
              loading={proposalSending}
              color="green"
              onClick={() => sendProposal(proposalToSend, proposalCompanyID)}
            >
              <Icon name="checkmark" /> Go for it
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          basic
          size="tiny"
        >
          <Header icon="exclamation" content={modalTitle} />
          <Modal.Content>
            <ul>
              {contractErrors.map((err, idx) => (
                <li key={idx}>
                  <h3>{err}</h3>
                </li>
              ))}
            </ul>
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={() => setModalOpen(false)}>
              <Icon name="checkmark" /> Got it
            </Button>
          </Modal.Actions>
        </Modal>

        {/* Client Inclusion Descriptions Modal */}
        <Modal
          open={clientInclusionDescriptionModalOpen}
          onClose={() => setClientInclusionDescriptionModalOpen(false)}
        >
          <Modal.Header>Inclusion Description</Modal.Header>
          <Modal.Content>Inclusion table goes here</Modal.Content>
          <Modal.Actions>
            <Button
              color="green"
              onClick={() => setClientInclusionDescriptionModalOpen(false)}
            >
              <Icon name="checkmark" /> Done
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    </CurrentProjectProvider>
  );
};

export default withRouter(ClientContractTracking);
