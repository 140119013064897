import React, { useEffect, useState } from "react";
import Axios from "axios";
import { isEmpty } from "lodash";
import { Loader, Table, Modal, Button } from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";

import columnDefs from "../columnDefs";
import { API_ROOT } from "../../../../api-config";

const columns = columnDefs()
  .filter(
    (x) =>
      ["Status", "ApprovalType", "CreatedByLastName", "ConditionID"].indexOf(
        x.field
      ) === -1
  )
  .map(({ headerName, field }) => ({
    headerName,
    field,
  }));

export default ({ match: { params }, updateData }) => {
  const [data, setData] = useState({});
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Axios.get(`${API_ROOT}/api/condition/condition-template/${params.id}`).then(
      (res) => {
        setData(res.data);

        setLoading(false);
      }
    );
  }, [params.id]);

  const hasFieldChanged = (field) => {
    return (
      data.oldValue &&
      data.newValue &&
      data.oldValue[field] !== data.newValue[field]
    );
  };

  const handleRejectClick = () => {
    setLoading(true);
    Axios.post(`${API_ROOT}/api/condition/reject-condition-template`, {
      conditionID: params.id,
    }).then((res) => {
      updateData(res.data);
      setLoading(false);
      setOpen(false);
    });
  };

  const handleApproveClick = () => {
    setLoading(true);
    Axios.post(`${API_ROOT}/api/condition/approve-condition-template`, {
      conditionID: params.id,
    }).then((res) => {
      updateData(res.data);
      setLoading(false);
      setOpen(false);
    });
  };

  return (
    <>
      {!open && <Redirect to="/condition-template-approval" />}
      <Modal open={open && !isEmpty(data)}>
        <Loader active={loading} />
        <Modal.Header>
          Approve Overwite: {data.newValue && data.newValue.ConditionName}
        </Modal.Header>
        <Modal.Content>
          <Table striped definition>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>Original Value</Table.HeaderCell>
                <Table.HeaderCell>New Value</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {columns.map((x) => (
                <Table.Row key={x.field} negative={hasFieldChanged(x.field)}>
                  <Table.Cell>{x.headerName}</Table.Cell>
                  <Table.Cell>
                    {data.oldValue && data.oldValue[x.field]}
                  </Table.Cell>
                  <Table.Cell>
                    {data.newValue && data.newValue[x.field]}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button as={Link} to="/condition-template-approval" color="black">
            Cancel
          </Button>
          <Button
            content="Reject"
            labelPosition="right"
            icon="close"
            negative
            onClick={() => handleRejectClick()}
          />
          <Button
            content="Approve"
            labelPosition="right"
            icon="checkmark"
            positive
            onClick={() => handleApproveClick()}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
