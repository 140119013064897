import React, { useState, useEffect } from "react";
import {
  TableHeader,
  CategoryHeader,
  Cell,
  Percent,
  LoadingAndEmpty,
} from "./Table";

import axios from "../../../../api/axios";

import InclusionSummary from "./InclusionSummary";

export default ({ projectId, status = "approved" }) => {
  const [loading, setLoading] = useState(true);
  const [inclusions, setInclusions] = useState();
  const [subsWithChildren, setSubsWithChildren] = useState();
  const [categories, setCategories] = useState();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/projects/${projectId}/inclusions/totals/${status}`)
      .then((x) => {
        setInclusions(x.data.inclusions);
        setSubsWithChildren(x.data.subs_with_children);
        const cats = x.data.inclusions.reduce((p, c) => {
          if (!p.some((x) => x === c.category_code)) p.push(c.category_code);
          return p;
        }, []);
        setCategories(cats);
      })
      .finally(() => setLoading(false));
  }, [projectId, status]);

  const sum = (field) => {
    if (!inclusions) return 0;
    return inclusions.reduce((p, c) => (p += c[field]), 0);
  };

  const markupPercent = (costField, profitField) => {
    if (!inclusions) return 0;
    const cost = sum(costField);
    const profit = sum(profitField);

    return (profit - cost) / cost;
  };

  const hasChildren = (sub) =>
    subsWithChildren.find((x) => x.sub_category_code === sub).has_children ===
    1;

  return (
    <div
      className="ui fluid container InclusionTable"
      style={{
        textAlign: "left",
        fontSize: "12px",
        padding: "1rem",
        overflowX: "scroll",
      }}
    >
      <table className="ui structured unstackable very compact table">
        <TableHeader />
        {/* loop over categories to start displaying info */}
        {categories &&
          categories.map((category_code) => (
            <CategoryHeader
              name={category_code}
              colspan={24}
              key={category_code}
            >
              <tbody>
                {inclusions
                  .filter((x) => x.category_code === category_code)
                  .map((row, idx) => {
                    return (
                      <InclusionSummary
                        key={row.sub_category_code}
                        hasDetails={hasChildren(row.sub_category_code)}
                        row={row}
                      />
                    );
                  })}
              </tbody>
            </CategoryHeader>
          ))}

        <LoadingAndEmpty categories={categories} loading={loading} />

        <tfoot>
          <tr>
            <th style={{ paddingLeft: "2rem" }}></th>
            <Cell as="th" className="footer-text aligned border-left">
              {sum("total")}
            </Cell>
            <Cell as="th" className="footer-text">
              {sum("taxable_total")}
            </Cell>
            <Cell as="th" className="footer-text">
              {sum("tax")}
            </Cell>
            <Cell as="th" className="footer-text">
              {sum("grand_total")}
            </Cell>
            <Cell as="th" className="footer-text border-left">
              {sum("labor_hours")}
            </Cell>
            <Cell as="th" className="footer-text">
              {sum("labor_rate")}
            </Cell>
            <Cell as="th" className="footer-text">
              {sum("labor_cost")}
            </Cell>
            <Percent as="th" className="footer-text">
              {markupPercent("labor_cost", "labor_total")}
            </Percent>
            <Cell as="th" className="footer-text">
              {sum("labor_total")}
            </Cell>
            <Cell as="th" className="footer-text border-left">
              {sum("material_cost")}
            </Cell>
            <Percent as="th" className="footer-text">
              {markupPercent("material_cost", "material_total")}
            </Percent>
            <Cell as="th" className="footer-text">
              {sum("material_total")}
            </Cell>
            <Cell as="th" className="footer-text border-left">
              {sum("equipment_cost")}
            </Cell>
            <Percent as="th" className="footer-text">
              {markupPercent("equipment_cost", "equipment_total")}
            </Percent>
            <Cell as="th" className="footer-text">
              {sum("equipment_total")}
            </Cell>
            <Cell as="th" className="footer-text border-left">
              {sum("other_cost")}
            </Cell>
            <Percent as="th" className="footer-text">
              {markupPercent("other_cost", "other_total")}
            </Percent>
            <Cell as="th" className="footer-text">
              {sum("other_total")}
            </Cell>
            <Cell as="th" className="footer-text border-left">
              {sum("subcontractor_cost_live")}
            </Cell>
            <Percent as="th" className="footer-text">
              {markupPercent(
                "subcontractor_cost_live",
                "subcontractor_total_live"
              )}
            </Percent>
            <Cell as="th" className="footer-text">
              {status.toLowerCase() === "approved"
                ? sum("subcontractor_total")
                : sum("subcontractor_total_live")}
            </Cell>
            <Cell as="th" />
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
