import React, { useState } from "react";
import { Icon, Popup } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Cell, Percent } from "./Table";
import axios from "../../../../api/axios";

import { setInclusionMenuSelection } from "../../../../actions/project/inclusion/inclusionActions";

export default ({ row, hasDetails }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);

  const {
    contract_stage_type: status,
    project_id: projectId,
    sub_category_id: subCategoryId,
    category_id: categoryId,
  } = row;

  const fetchDetails = () => {
    // we don't need to keep updating things
    if (loaded) {
      setShow(!show);
      return;
    }

    setLoading(true);
    axios
      .get(
        `/projects/${projectId}/inclusions/summaries/${categoryId}/${subCategoryId}/${status}`
      )
      .then((x) => {
        setDetails(x.data);
      })
      .finally(() => {
        setLoading(false);
        setShow(true);
        setLoaded(true);
      });
  };

  const getChevron = () => {
    if (loaded && show) return "chevron down";
    return "chevron right";
  };

  const getDetailIcons = (detail) => {
    const icons = [];
    if (detail.client_contract_id)
      icons.push(
        <Popup
          content={`Client contract #${detail.client_contract_id}`}
          //   need to make this a link one day to access contract
          trigger={<Icon name="sun" color="orange" />}
        />
      );

    return icons;
  };

  const buildLink = (categoryId, subCategoryCode) => {
    dispatch(
      setInclusionMenuSelection({
        returnUrl: history.location,
        selectedCategoryID: parseInt(categoryId),
        selectedSubCategoryCode: subCategoryCode,
      })
    );
    history.push(
      `/browser/projects/${projectId}/inclusions/${categoryId}/${encodeURI(
        subCategoryCode
      )}`
    );
  };

  return (
    <>
      <tr>
        <th
          style={{
            position: "relative",
            paddingLeft: hasDetails ? "auto" : "15px",
          }}
        >
          {hasDetails && !loading && (
            <Icon
              name={getChevron()}
              color="orange"
              style={{
                cursor: "pointer",
              }}
              onClick={() => fetchDetails()}
            />
          )}

          {loading && <Icon name="spinner" color="orange" />}

          <Link onClick={() => buildLink(categoryId, row.sub_category_code)}>
            {row.sub_category_code}
          </Link>
        </th>
        <Cell className="aligned border-left">{row.total}</Cell>
        <Cell>{row.taxable_total}</Cell>
        <Cell>{row.tax}</Cell>
        <Cell>{row.grand_total}</Cell>
        <Cell className="border-left">{row.labor_hours}</Cell>
        <Cell>{row.labor_rate}</Cell>
        <Cell>{row.labor_cost}</Cell>
        <Percent>{row.labor_markup}</Percent>
        <Cell>{row.labor_total}</Cell>
        <Cell className="border-left">{row.material_cost}</Cell>
        <Percent>{row.material_markup}</Percent>
        <Cell>{row.material_total}</Cell>
        <Cell className="border-left">{row.equipment_cost}</Cell>
        <Percent>{row.equipment_markup}</Percent>
        <Cell>{row.equipment_total}</Cell>
        <Cell className="border-left">{row.other_cost}</Cell>
        <Percent>{row.other_markup}</Percent>
        <Cell>{row.other_total}</Cell>
        <Cell className="border-left">{row.subcontractor_cost_live}</Cell>
        <Percent>{row.subcontractor_markup_live}</Percent>
        <Cell>
          {status.toLowerCase() === "approved"
            ? row.subcontractor_total
            : row.subcontractor_total_live}
        </Cell>
        <Cell>
          {row.has_subcontactor ? (
            <div className="ui orange tiny label">Subs</div>
          ) : (
            undefined
          )}
        </Cell>
      </tr>
      {show &&
        hasDetails &&
        details.map((detail, idx) => {
          const style =
            idx + 1 === details.length
              ? { borderBottom: "1px solid rgb(254, 78, 0)" }
              : {};
          return (
            <tr key={idx} style={{ ...style, textAlign: "right" }}>
              <th>{getDetailIcons(detail)}</th>
              <Cell className="aligned border-left" style={style}>
                {detail.total}
              </Cell>
              <Cell style={style}>{detail.taxable_total}</Cell>
              <Cell style={style}>{detail.tax}</Cell>
              <Cell style={style}>{detail.grand_total}</Cell>
              <Cell style={style} className="border-left">
                {detail.labor_hours}
              </Cell>
              <Cell style={style}>{detail.labor_rate}</Cell>
              <Cell style={style}>{detail.labor_cost}</Cell>
              <Percent style={style}>{detail.labor_markup_percent}</Percent>
              <Cell style={style}>{detail.labor_total}</Cell>
              <Cell style={style} className="border-left">
                {detail.material_cost}
              </Cell>
              <Percent style={style}>{detail.material_markup_percent}</Percent>
              <Cell style={style}>{detail.material_total}</Cell>
              <Cell style={style} className="border-left">
                {detail.equipment_cost}
              </Cell>
              <Percent style={style}>{detail.equipment_markup_percent}</Percent>
              <Cell style={style}>{detail.equipment_total}</Cell>
              <Cell style={style} className="border-left">
                {detail.other_cost}
              </Cell>
              <Percent style={style}>{detail.other_markup_percent}</Percent>
              <Cell style={style}>{detail.other_total}</Cell>

              <Cell style={style} className="border-left">
                {detail.subcontractor_cost_live}
              </Cell>
              <Percent style={style}>
                {detail.subcontractor_markup_percent_live}
              </Percent>
              <Cell style={style}>
                {status.toLowerCase() === "approved"
                  ? detail.subcontractor_total
                  : detail.subcontractor_total_live}
              </Cell>
              {detail.has_subcontactor === 1 && (
                <Cell style={style}>
                  <div className="ui orange tiny label">Subs</div>
                </Cell>
              )}
              {detail.has_subcontactor === 0 && <Cell style={style}></Cell>}
            </tr>
          );
        })}
    </>
  );
};
