import React from "react";
import {
  Modal,
  Header,
  Button,
  Icon,
  Table,
  Form,
  Dropdown,
} from "semantic-ui-react";
import ConfirmDeleteUserRoleModal from "./ConfirmDeleteUserRoleModal/ConfirmDeleteUserRoleModal";

const SecurityRolesModal = ({
  open,
  setOpen,
  users,
  roleName,
  displayNames,
  roleID,
  deleteUserRole,
  addUserRole,
}) => {
  const filterUserIDs =
    users && users.length ? users.map((item) => item.UserID) : [];

  let filteredDisplayNames = [];

  if (Array.isArray(users) && users.length) {
    filteredDisplayNames = displayNames.filter(function(item) {
      return !filterUserIDs.includes(item.UserID);
    });
  } else {
    filteredDisplayNames = displayNames;
  }

  const handleAddUser = (userID) => {
    addUserRole(roleID, userID);
    // setConfirmModalOpen(false);
  };

  const handleConfirmDelete = (userID) => {
    deleteUserRole(roleID, userID);
  };

  const options =
    Array.isArray(filteredDisplayNames) && filteredDisplayNames.length
      ? filteredDisplayNames.map((user) => {
          return {
            key: user.UserID,
            text: user.DisplayName,
            value: user.UserID,
          };
        })
      : [];

  return (
    <Modal size="small" open={open} onClose={() => setOpen(false)}>
      <Header icon="user secret" content={`${roleName} Security Role`} />
      <Modal.Content>
        {roleName !== "Superuser" ? (
          <Form>
            <Form.Field>
              <Dropdown
                search
                placeholder="Add user to role"
                selection
                selectOnBlur={false}
                options={options}
                value={""}
                onChange={(e, data) => handleAddUser(data.value)}
              />
            </Form.Field>
          </Form>
        ) : null}

        <Table unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}></Table.HeaderCell>
              <Table.HeaderCell>Security Role Member</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Array.isArray(users) && users.length
              ? users.map((user) => {
                  return (
                    <Table.Row key={user.UserID}>
                      <Table.Cell>
                        {roleName !== "Superuser" ? (
                          <ConfirmDeleteUserRoleModal
                            user={user}
                            handleConfirmDelete={handleConfirmDelete}
                          />
                        ) : null}
                      </Table.Cell>
                      <Table.Cell>{user.DisplayName}</Table.Cell>
                    </Table.Row>
                  );
                })
              : null}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" onClick={() => setOpen(false)}>
          <Icon name="checkmark" /> Done
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SecurityRolesModal;
