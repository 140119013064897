import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Accordion, Icon, List } from "semantic-ui-react";
import "./ProjectDispatch.scss";

const ProjectDispatch = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState([]);

  useEffect(() => {
    setActiveIndex([]);
  }, [data]);

  const handleClick = (index) => {
    let newActiveIndex = [...activeIndex];
    if (activeIndex.indexOf(index) === -1) {
      newActiveIndex.push(index);
      setActiveIndex(newActiveIndex);
    } else {
      const where = newActiveIndex.indexOf(index);
      newActiveIndex.splice(where, 1);
      setActiveIndex(newActiveIndex);
    }
  };

  return Array.isArray(data) && data.length
    ? data.map((obj, index) => (
        <Accordion
          key={index}
          fluid
          styled
          className="ProjectDispatch"
          style={{ borderRadius: 0, borderBottom: "1px solid gray" }}
        >
          <Accordion.Title
            index={index}
            active={activeIndex.indexOf(index) === -1 ? false : true}
            onClick={() => handleClick(index)}
          >
            <div
              style={{
                display: "inline-block",
                height: 26,
                verticalAlign: "middle",
                width: "10%",
              }}
            >
              <Icon style={{ fontSize: 22 }} name="dropdown" />
            </div>
            <div
              style={{
                display: "inline-block",
                width: "80%",
                textAlign: "center",
              }}
            >
              <Link to={`/m/projects/${obj.projectID}`}>
                {obj.projectID ? `${obj.workLocationName}` : ""}
                <br />
                {obj.projectID ? `${obj.projectID} - ${obj.projectName}` : ""}
              </Link>
            </div>
            <div style={{ width: "10%", display: "inline-block" }}></div>
          </Accordion.Title>
          <Accordion.Content
            style={{ background: "#767676", paddingBottom: 0 }}
            active={activeIndex.indexOf(index) === -1 ? false : true}
          >
            <List divided verticalAlign="middle">
              {Array.isArray(obj.assignments) && obj.assignments.length
                ? obj.assignments.map((assignment, i) => (
                    <Fragment key={i}>
                      <List.Item>
                        <List.Content>
                          <List.Header
                            style={{
                              textAlign: "center",
                              color: "#fff",
                              // fontSize: 18,
                            }}
                          >
                            {assignment.displayName
                              ? `${assignment.displayName}`
                              : ""}
                          </List.Header>
                        </List.Content>
                      </List.Item>
                    </Fragment>
                  ))
                : null}
            </List>
          </Accordion.Content>
        </Accordion>
      ))
    : null;
};

export default ProjectDispatch;
