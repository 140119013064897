import Axios from "axios";
import { API_ROOT } from "../api-config";

export const get = async () =>
  await Axios.get(`${API_ROOT}/api/user/potential-leadership-pool-members`)
    .then((results) => results.data)
    .catch((err) => Promise.reject(err));

export const create = async (payload) =>
  await Axios.post(`${API_ROOT}/api/user/potential-leadership-pool-members`, payload)
    .then((results) => results.data)
    .catch((err) => Promise.reject(err));
