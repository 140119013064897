import React, { Fragment, useEffect, useState } from "react";
import { Dropdown, MenuItem, Icon } from "semantic-ui-react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  setTeamMemberGoalsMenuSelection,
  resetTeamMemberGoalsMenuSelection,
} from "../../../../actions/topMenu/teamMemberGoalsMenuActions";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";

const START_YEAR = 2017;
let date = moment();
let currentYear = date.year();

function generate_year_range(start, end) {
  var years = [];
  for (var year = start; year <= end; year++) {
    years.push(year);
  }
  return years;
}

const TeamMemberGoalsItems = (props) => {
  const [loading, setLoading] = useState(false);
  const year = useSelector((state) => state.teamMemberGoalsMenu.year);
  const division = useSelector((state) => state.teamMemberGoalsMenu.division);
  const role = useSelector((state) => state.teamMemberGoalsMenu.role);
  const dispatch = useDispatch();

  var my_years = generate_year_range(START_YEAR, currentYear + 1).sort(
    (a, b) => b - a
  );

  const optionsArrays = my_years.map((y) => {
    return { key: y, text: y, value: y };
  });

  useEffect(() => {
    dispatch(resetTeamMemberGoalsMenuSelection());
  }, []);

  const options = [].concat(...optionsArrays);

  const handleDateChange = (data) => {
    const selection = {
      year: data.value,
      division,
      role,
    };
    dispatch(setTeamMemberGoalsMenuSelection(selection));
  };

  const handleDivisionChange = (data) => {
    const selection = {
      year,
      division: data.value,
      role,
    };
    dispatch(setTeamMemberGoalsMenuSelection(selection));
  };

  const handleRoleChange = (data) => {
    const selection = {
      year,
      division,
      role: data.value,
    };
    dispatch(setTeamMemberGoalsMenuSelection(selection));
  };

  const handleRecalculateClick = () => {
    setLoading(true);
    Axios.get(`${API_ROOT}/api/dashboard/load-financials`)
      .then((res) => {
        setLoading(false);
        alert("Dashboard has been recalculated");
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        alert("An error occurred while recalculating the dashboard");
        console.log(err);
      });
  };

  return (
    <Fragment>
      <Dropdown
        scrolling
        selectOnBlur={false}
        text={`Showing ${year}`}
        value={year}
        item
        options={options}
        onChange={(e, data) => handleDateChange(data)}
      />
      <Dropdown
        selectOnBlur={false}
        text={division}
        value={division}
        item
        options={[
          { key: "Construction", value: "Construction", text: "Construction" },
          { key: "Mechanical", value: "Mechanical", text: "Mechanical" },
          { key: "Service", value: "Service", text: "Service" },
        ]}
        onChange={(e, data) => handleDivisionChange(data)}
      />
      <Dropdown
        selectOnBlur={false}
        text={role}
        value={role}
        item
        options={[
          {
            key: "Project Engineer",
            value: "Project Engineer",
            text: "Project Engineer",
          },
          {
            key: "Project Manager",
            value: "Project Manager",
            text: "Project Manager",
          },
          {
            key: "Superintendent",
            value: "Superintendent",
            text: "Superintendent",
          },
        ]}
        onChange={(e, data) => handleRoleChange(data)}
      />
      <MenuItem as="a" onClick={handleRecalculateClick}>
        <Icon loading={loading} name={loading ? "spinner" : "cogs"} />
        Recalculate Dashboard
      </MenuItem>
    </Fragment>
  );
};

export default TeamMemberGoalsItems;
