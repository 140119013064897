import axios from "axios";

import { API_ROOT } from "../../api-config";

import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  USER_REQUEST_SUCCESS,
  LOGOUT,
} from "../../constants/userConstants";

axios.defaults.withCredentials = true;

const loginRequest = () => {
  return (dispatch) => {
    return axios
      .get(`${API_ROOT}/auth/user`)
      .then((res) => {
        if (res.data.user) {
          dispatch(loginSuccess(res.data));
          dispatch(getUser());
        } else {
          dispatch(loginFailure());
        }
        // this.setState({
        //   user: res.data.user.name.givenName,
        //   isAuthenticated: true
        // });
      })
      .catch((err) => {
        dispatch(loginFailure(err.message));
      });
  };
};

const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: { error },
});

const getUser = () => {
  return (dispatch) => {
    return axios.get(`${API_ROOT}/api/user/`).then((res) => {
      dispatch(getUserSuccess(res.data.user));
    });
  };
};

const getUserSuccess = (user) => ({
  type: USER_REQUEST_SUCCESS,
  payload: user,
});

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: LOGOUT });
  };
};

export default loginRequest;
