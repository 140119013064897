import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Label } from "semantic-ui-react";
import { API_ROOT } from "../../../../api-config";

// import { setForecast } from "../../../../../actions/project/projectActions";

import "./MonthInput.scss";

const MonthInput = ({
  gridApi,
  forecast,
  color,
  projectID,
  monthProp,
  total,
  rowData,
  setRowData,
}) => {
  const [focus, setFocus] = useState(false);
  const [date] = useState(new Date());
  const [forecastError, setForecastError] = useState(false);
  const [forecasting, setForecasting] = useState();

  useEffect(() => {
    setForecasting(forecast);
  }, []);

  const saveForecast = (values) => {
    let year;

    switch (values.yearNum) {
      case 1:
        year = date.getFullYear() - 1;
        break;
      case 2:
        year = date.getFullYear();
        break;
      case 3:
        year = date.getFullYear() + 1;
        break;
      default:
        return;
    }

    const month =
      new Date(Date.parse(values.month + " 1, 2012")).getMonth() + 1;
    const projectForecast = values.forecast === "" ? 0 : values.forecast / 100;

    Axios.post(`${API_ROOT}/api/project/browser-forecast`, {
      params: {
        projectID,
        forecastYear: year,
        forecastMonth: month,
        forecastPercent: projectForecast,
        monthStr: values.monthStr,
      },
    })
      .then((res) => {
        const updatedProject = JSON.parse(res.data.project)[0];
        let updatedRowData = rowData.map((item) => {
          if (item.ProjectID === projectID) {
            const updated = JSON.parse(res.data.project)[0];
            // // const updated = {
            // //   ...item,
            // //   [monthProp]: updatedProject[`${monthProp}`],
            // // };
            // console.log(updated);
            return updated; //gets everything that was already in item, and updates "done"
          }

          return item; // else return unmodified item
        });

        setFocus(false);
        setForecastError(false);

        setRowData(updatedRowData); // set state to new object with updated list?

        setForecasting(updatedProject[`${monthProp}`]);

        // return res.data.project;
      })
      .catch((error) => console.log(error));
  };

  const handleFocus = (e) => {
    setFocus(true);
    setForecastError(false);
  };

  const handleBlur = (e) => {
    if (e.target.defaultValue === e.target.value) {
      setFocus(false);
      return;
    }

    const monthStr = monthProp;

    const month = monthProp.substring(0, 3);
    const yearNum = parseInt(monthProp.substring(3, 4));
    const forecast = e.target.value;

    // let variance =
    //   forecast / 100 +
    //   (total - (e.target.defaultValue ? e.target.defaultValue / 100 : 0));

    if (forecast > 100) {
      setForecastError(true);
      return false;
    }

    const values = {
      projectID,
      month,
      yearNum,
      forecast,
      monthStr,
    };

    saveForecast(values);
  };

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      if (e.target.defaultValue === e.target.value) {
        setFocus(false);
        return;
      }

      const month = monthProp.substring(0, 3);
      const yearNum = parseInt(monthProp.substring(3, 4));
      const forecast = e.target.value;

      // let variance =
      //   forecast / 100 +
      //   (total - (e.target.defaultValue ? e.target.defaultValue / 100 : 0));

      if (forecast > 100) {
        setForecastError(true);
        return false;
      }

      const values = {
        projectID,
        month,
        yearNum,
        forecast,
      };

      saveForecast(values);
    }
  };

  // console.log(updatedProject[`${monthProp}`]);

  let yearNumber = parseInt(monthProp.substring(3, 4));
  if (focus) {
    return (
      <input
        style={
          forecastError
            ? { color: "red", backgroundColor: "pink", width: 30 }
            : { color: "black", backgroundColor: "white", width: 30 }
        }
        className="input__forecast"
        defaultValue={
          forecasting * 100 === 0 ? "" : (forecasting * 100).toFixed(0)
        }
        onKeyDown={handleEnter}
        onBlur={handleBlur}
        // onBlur={() => console.log("yo")}
        autoFocus
        type="text"
      />
    );
  } else {
    return (
      <Label
        className="MonthInput"
        tabIndex="0"
        onFocus={handleFocus}
        style={{
          fontSize: 13,
          padding: "4px 3px",
          marginLeft: "0px",
          width: 30,
          backgroundColor:
            yearNumber === 1 || yearNumber === 3 ? "#eee" : color,
          color:
            forecasting === 0 || forecasting === "0"
              ? yearNumber === 2
                ? color
                : "#eee"
              : yearNumber !== 2
              ? color
              : "#fff",
          // color: "black",
          textAlign: "center",
        }}
      >
        {(forecasting * 100).toFixed(0)}
      </Label>
    );
  }
};

export default MonthInput;
