import React, { Fragment, useEffect, useState } from "react";
import { Dropdown, MenuItem, Icon } from "semantic-ui-react";
// import { useLocation, useHistory, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setTeamMemberBonusPoolMenuSelection,
  resetTeamMemberBonusPoolMenuSelection,
} from "../../../../actions/topMenu/teamMemberBonusPoolActions";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import moment from "moment";

let date = moment();
let currentYear = date.year();

function generate_year_range(start, end) {
  var years = [];
  for (var iyear = start; iyear <= end; iyear++) {
    years.push(iyear);
  }
  return years;
}

const TeamMemberBonusPoolItems = (props) => {
  // const [dropdownValue, setDropdownValue] = useState("");
  const { year, quarter } = useSelector((state) => state.teamMemberBonusPool);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  var my_years = generate_year_range(2020, currentYear + 1).sort(
    (a, b) => b - a
  );
  const quarters = [4, 3, 2, 1];

  //   let my_years = [2019, 2018, 2020, 2014].sort((a, b) => b - a);
  //   console.log(my_years);

  //   const options = my_years.map((y) =>
  //     quarters.map((q) => {
  //       return q === "divider" ? (
  //         my_years.length > 1 ? (
  //           <Dropdown.Divider key={`${q}${y}`} />
  //         ) : null
  //       ) : (
  //         <Dropdown.Item
  //           key={`${q}${y}`}
  //           value={`Q${q} ${y}`}
  //           text={`Q${q} ${y}`}
  //         />
  //       );
  //     })
  //   );

  const optionsArrays = my_years.map((y) =>
    quarters.map((q) => {
      // let value = `Q${q} ${y}`;
      return q === 5
        ? {
            key: `Q${q} ${y}`,
            // text: `Q${q} ${y}`,
            disabled: true,
            // value: JSON.stringify({ q, y }),
            value: "",
            content: <hr className="dropdown-hr" />,
            // className: "divider",
          }
        : {
            key: `Q${q} ${y}`,
            text: `Q${q} ${y}`,
            value: JSON.stringify({ q, y }),
          };
    })
  );

  const options = [].concat(...optionsArrays);

  // useEffect(() => {
  //   setDropdownValue(JSON.stringify({ q: quarter, y: year }));
  // }, []);

  const handleChange = (e, { value }) => {
    const parsedValue = JSON.parse(value);
    // setQuarter(parsedValue.q);
    // setYear(parsedValue.y);
    // setDropdownValue(value);
    dispatch(
      setTeamMemberBonusPoolMenuSelection({
        year: parsedValue.y,
        quarter: parsedValue.q,
      })
    );
  };

  useEffect(() => {
    dispatch(resetTeamMemberBonusPoolMenuSelection());
  }, []);

  const handleRecalculateClick = () => {
    setLoading(true);
    Axios.get(`${API_ROOT}/api/dashboard/load-financials`)
      .then((res) => {
        setLoading(false);
        alert("Dashboard has been recalculated");
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        alert("An error occurred while recalculating the dashboard");
        console.log(err);
      });
  };

  return (
    <Fragment>
      <Dropdown
        scrolling
        text={`Showing Q${quarter} ${year}`}
        value={JSON.stringify({ q: quarter, y: year })}
        item
        options={options}
        onChange={handleChange}
        selectOnBlur={false}
      />
      {/* Recalculate dashboard button goes here */}
      <MenuItem as="a" onClick={handleRecalculateClick}>
        <Icon loading={loading} name={loading ? "spinner" : "cogs"} />
        Recalculate Dashboard
      </MenuItem>
    </Fragment>
  );
};

export default TeamMemberBonusPoolItems;
