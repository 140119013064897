import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Grid, Button } from "semantic-ui-react";
import {
  Route,
  useRouteMatch,
  Switch,
  Redirect,
  NavLink,
} from "react-router-dom";

import ConditionsTable from "./ConditionsTable";
import OverwriteModal from "./Overwrite/Modal";
import { API_ROOT } from "../../../api-config";

const ConditionTemplateWrapper = ({ status }) => {
  const { path } = useRouteMatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    Axios.get(`${API_ROOT}/api/condition/condition-template`, {
      params: { status },
    }).then((res) => {
      setData(res.data);
    });
  }, [status]);

  const updateConditionData = (condition) => {
    const index = data.findIndex(
      (obj) => obj.ConditionID === condition.ConditionIDs
    );
    const newData = [...data];
    newData[index] = condition;
    setData(newData);
  };

  return (
    <>
      <Container
        style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 40 }}
        fluid
      >
        <Grid columns={2} style={{ paddingLeft: 40, paddingBottom: 20 }}>
          <Grid.Column>
            <h1 style={{ color: "rgb(254, 80, 0)" }}>
              Condition Templates - {status}
            </h1>
          </Grid.Column>
          <Grid.Column style={{ textAlign: "right" }}>
            <div>
              <Button
                basic
                as={NavLink}
                to={`/condition-template-approval/awaiting-approval`}
              >
                Awaiting Approval
              </Button>
              <Button
                basic
                as={NavLink}
                to={`/condition-template-approval/active`}
              >
                Active
              </Button>
              <Button
                basic
                as={NavLink}
                to={`/condition-template-approval/inactive`}
              >
                Inactive
              </Button>
            </div>
          </Grid.Column>
        </Grid>
        <ConditionsTable data={data} setData={setData} />
      </Container>

      <Route
        exact
        path={`${path}/:id`}
        render={(props) => (
          <OverwriteModal
            {...props}
            updateData={(x) => updateConditionData(x)}
          />
        )}
      />
    </>
  );
};

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/active`}
        render={() => <ConditionTemplateWrapper status="Active" />}
      />
      <Route
        path={`${path}/inactive`}
        render={() => <ConditionTemplateWrapper status="Inactive" />}
      />
      <Route
        path={`${path}/awaiting-approval`}
        render={() => <ConditionTemplateWrapper status="Awaiting Approval" />}
      />
      <Redirect to={`${path}/awaiting-approval`} />
    </Switch>
  );
};
