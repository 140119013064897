// import Axios from "axios";
import moment from 'moment'
import React, { forwardRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { Form, Table } from 'semantic-ui-react'
import { API_ROOT } from '../../../../api-config'
import * as XLSX from 'xlsx'
import Axios from 'axios'
import { trim } from 'lodash'

const GMReportUpload = () => {
  const [startDate, setStartDate] = useState(new Date())
  // const [csvData, setCsvData] = useState(null);
  const [loading, setLoading] = useState(false)
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick}>
      <Form>
        <Form.Input value={value} className="example-custom-input" />
      </Form>
    </div>
  ))

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file)
      fileReader.onload = (e) => {
        const bufferArray = e.target.result
        const wb = XLSX.read(bufferArray, { type: 'buffer', cellDates: true })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        const results = XLSX.utils.sheet_to_json(ws)

        const data = results.map((x) =>
          Object.keys(x).reduce((acc, key) => {
            acc[trim(key)] = x[key]
            return acc
          }, {}),
        )

        resolve(data)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })

    promise
      .then((d) => {
        const JSONString = JSON.stringify(d)
        if (JSONString) {
          const date = moment(startDate)
          const month = date.month() + 1
          const year = date.year()

          Axios.post(`${API_ROOT}/api/acumatica/gross-profit-report`, {
            month,
            year,
            json: JSONString,
          })
            .then((res) => {
              if (res) {
                if (res.data[0].ReturnValue === 1) {
                  alert('Success!')
                }
                setLoading(false)
              } else {
                alert(
                  'Something went wrong. Please contact your IT Administrator.',
                )
                setLoading(false)
              }
            })
            .catch((err) => {
              setLoading(false)
              alert(err)
            })
        } else {
          alert('Something went wrong. Please contact your IT Administrator.')
          loading(false)
        }
      })
      .catch((err) => {
        alert(err)
        loading(false)
      })
  }

  return (
    <Table.Row>
      <Table.Cell>GM Report Upload</Table.Cell>
      <Table.Cell>
        <ReactDatePicker
          selected={startDate}
          onChange={(date) => {
            setStartDate(date)
            // setCsvData(null);
          }}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          customInput={<CustomInput />}
        />
      </Table.Cell>
      <Table.Cell>
        <Form>
          <Form.Input
            loading={loading}
            type="file"
            onChange={(e) => {
              setLoading(true)
              const file = e.target.files[0]
              readExcel(file)
              e.target.value = ''
            }}
          />
        </Form>
      </Table.Cell>
    </Table.Row>
  )
}

export default GMReportUpload
