import React, { useState, useEffect, Fragment } from "react";
import { Table, Popup, Label, Icon } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import useIsMounted from "../../../../hooks/isMounted";
import { setInclusionMenuSelection } from "../../../../actions/project/inclusion/inclusionActions";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

const Alternate = ({ projectID, type }) => {
  const [inclusions, setInclusions] = useState(null);
  const [inclusionSummary, setInclusionSummary] = useState(null);

  const isMounted = useIsMounted();

  const dispatch = useDispatch();

  useEffect(() => {
    if (projectID && type) {
      Axios.get(`${API_ROOT}/api/inclusion/alternate-inclusions`, {
        params: {
          projectID,
          alternateContractType: type,
        },
      })
        .then((res) => {
          if (
            res.data.inclusion &&
            res.data.inclusion.inclusions &&
            isMounted.current
          ) {
            setInclusions(res.data.inclusion.inclusions);
          }
          if (
            res.data.inclusion &&
            res.data.inclusion.inclusionSummary &&
            res.data.inclusion.inclusionSummary.summary &&
            isMounted.current
          ) {
            setInclusionSummary(res.data.inclusion.inclusionSummary.summary[0]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [projectID, type]);

  return (
    <Fragment>
      <Table.Row style={{ background: "#eee" }}>
        <Table.Cell colSpan={22}>
          <span style={{ fontWeight: "bold" }}>{type}</span>{" "}
          {inclusionSummary && inclusionSummary.alternateSelectedInd ? (
            <Popup
              content="Selected by client"
              trigger={<Icon name="checkmark" color="green" />}
            />
          ) : null}
        </Table.Cell>
      </Table.Row>
      {Array.isArray(inclusions) && inclusions.length ? (
        inclusions[0].category.map((category) => {
          return (
            <React.Fragment key={category.mainCategoryID}>
              <Table.Row>
                <Table.Cell
                  style={{
                    color: "rgb(254, 80, 0)",
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                >
                  {category.categoryCode.toUpperCase()}
                </Table.Cell>
                <Table.Cell
                  style={{ borderLeft: "none" }}
                  colSpan={21}
                ></Table.Cell>
              </Table.Row>
              {category.subcategory.map((subCategory) => {
                return (
                  <Table.Row
                    key={subCategory.subCategoryCode}
                    style={{ fontSize: 12 }}
                  >
                    <Table.Cell style={{ paddingLeft: 30 }}>
                      <Link
                        onClick={() => {
                          console.log(category);
                          dispatch(
                            setInclusionMenuSelection({
                              selectedCategoryID: category.mainCategoryID,
                              selectedSubCategoryCode:
                                subCategory.subCategoryCode,
                            })
                          );
                        }}
                        to={`/browser/projects/inclusions/${projectID}`}
                      >
                        {subCategory.subCategoryCode}
                      </Link>
                    </Table.Cell>
                    <Table.Cell textAlign="center" className="border-left">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(subCategory.preTax)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(subCategory.tax)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(subCategory.grandTotal)}
                    </Table.Cell>
                    <Table.Cell textAlign="center" className="border-left">
                      {subCategory.laborHours.toFixed(2)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(subCategory.laborRate)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(subCategory.laborCost)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {subCategory.laborMarkup}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(subCategory.laborTotal)}
                    </Table.Cell>
                    <Table.Cell textAlign="center" className="border-left">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(subCategory.materialCost)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {subCategory.materialMarkup}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(subCategory.materialTotal)}
                    </Table.Cell>

                    <Table.Cell textAlign="center" className="border-left">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(subCategory.equipmentCost)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {subCategory.equipmentMarkup}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(subCategory.equipmentTotal)}
                    </Table.Cell>

                    <Table.Cell textAlign="center" className="border-left">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(subCategory.otherCostsCost)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {subCategory.otherCostsMarkup}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(subCategory.otherCostsTotal)}
                    </Table.Cell>

                    <Table.Cell textAlign="center" className="border-left">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(subCategory.subCost)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {subCategory.subMarkup}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(subCategory.subTotal)}
                    </Table.Cell>
                    <Table.Cell
                      style={{ paddingLeft: 0, paddingRight: 0, width: 44 }}
                    >
                      {subCategory.subList ? (
                        <Popup
                          position="top right"
                          trigger={
                            <Label size="tiny" color="orange">
                              Subs
                            </Label>
                          }
                          content={
                            <ul
                              style={{
                                listStyle: "none",
                                padding: 0,
                              }}
                            >
                              {[...new Set(subCategory.subList.split(","))].map(
                                (sub) => (
                                  <li style={{ paddingBottom: 2 }} key={sub}>
                                    {sub}
                                  </li>
                                )
                              )}
                            </ul>
                          }
                        />
                      ) : null}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
              {inclusionSummary ? (
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  <Table.Cell
                    textAlign="center"
                    className="border-left footer-text"
                  >
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(inclusionSummary.preTax || null)}
                  </Table.Cell>
                  <Table.Cell textAlign="center" className="footer-text">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(inclusionSummary.tax || null)}
                  </Table.Cell>
                  <Table.Cell textAlign="center" className="footer-text">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(inclusionSummary.grandTotal || null)}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="center"
                    className="border-left footer-text"
                  >
                    {inclusionSummary.laborHours === 0 ||
                    inclusionSummary.laborHours
                      ? inclusionSummary.laborHours.toFixed(2)
                      : null}
                  </Table.Cell>
                  <Table.Cell textAlign="center" className="footer-text">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(inclusionSummary.laborRate || null)}
                  </Table.Cell>
                  <Table.Cell textAlign="center" className="footer-text">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(inclusionSummary.laborCost || null)}
                  </Table.Cell>
                  <Table.Cell textAlign="center" className="footer-text">
                    {inclusionSummary.laborMarkup === 0
                      ? "0%"
                      : inclusionSummary.laborMarkup || null}
                  </Table.Cell>
                  <Table.Cell textAlign="center" className="footer-text">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(inclusionSummary.laborTotal)}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="center"
                    className="border-left footer-text"
                  >
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(inclusionSummary.materialCost)}
                  </Table.Cell>
                  <Table.Cell textAlign="center" className="footer-text">
                    {inclusionSummary.materialMarkup === 0
                      ? "0%"
                      : inclusionSummary.materialMarkup}
                  </Table.Cell>
                  <Table.Cell textAlign="center" className="footer-text">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(inclusionSummary.materialTotal)}
                  </Table.Cell>

                  <Table.Cell
                    textAlign="center"
                    className="border-left footer-text"
                  >
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(inclusionSummary.equipmentCost)}
                  </Table.Cell>
                  <Table.Cell textAlign="center" className="footer-text">
                    {inclusionSummary.equipmentMarkup === 0
                      ? "0%"
                      : inclusionSummary.equipmentMarkup}
                  </Table.Cell>
                  <Table.Cell textAlign="center" className="footer-text">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(inclusionSummary.equipmentTotal)}
                  </Table.Cell>

                  <Table.Cell
                    textAlign="center"
                    className="border-left footer-text"
                  >
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(inclusionSummary.otherCostsCost)}
                  </Table.Cell>
                  <Table.Cell textAlign="center" className="footer-text">
                    {inclusionSummary.equipmentMarkup === 0
                      ? "0%"
                      : inclusionSummary.otherCostsMarkup}
                  </Table.Cell>
                  <Table.Cell textAlign="center" className="footer-text">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(inclusionSummary.otherCostsTotal)}
                  </Table.Cell>

                  <Table.Cell
                    textAlign="center"
                    className="border-left footer-text"
                  >
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(inclusionSummary.subCost)}
                  </Table.Cell>
                  <Table.Cell textAlign="center" className="footer-text">
                    {inclusionSummary.subMarkup === 0
                      ? "0%"
                      : inclusionSummary.subMarkup}
                  </Table.Cell>
                  <Table.Cell textAlign="center" className="footer-text">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(inclusionSummary.subTotal)}
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              ) : null}
            </React.Fragment>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell
            style={{
              // color: "rgb(254, 80, 0)",
              color: "gray",
              fontWeight: "bold",
              fontSize: 12,
            }}
          >
            No inclusions
          </Table.Cell>
          <Table.Cell style={{ borderLeft: "none" }} colSpan={21}></Table.Cell>
        </Table.Row>
      )}
    </Fragment>
  );
};

export default Alternate;
