import React, { useEffect, useState } from "react";
import Project from "./Project";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";
import { Dimmer, Loader } from "semantic-ui-react";
// import moment from "moment";

const ProjectWrapper = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [projectID, setProjectID] = useState();
  const [projectInfo, setProjectInfo] = useState();
  // const [displayDate, setDisplayDate] = useState()

  useEffect(() => {
    if (id && !isNaN(id)) {
      setProjectID(parseInt(id));
    } else {
      setErrorMessage("An error occurred: Invalid ProjectID");
      setError(true);
      setLoading(false);
    }
  }, [id]);

  const fetchProjectInfo = () => {
    Axios.get(`${API_ROOT}/api/project/project-info-mobile`, {
      params: {
        projectID,
      },
    })
      .then((res) => {
        if (res && res.data) {
          setProjectInfo(res.data);
          setLoading(false);
        } else {
          setErrorMessage(
            "An error occurred while fetching the project data. Please contact IT."
          );
          setError(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        setErrorMessage("An error occurred: " + err);
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (projectID) {
      fetchProjectInfo();
    }
  }, [projectID]);

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader inverted />
      </Dimmer>
    );
  }

  if (error && errorMessage) {
    return (
      <p style={{ paddingTop: 20, paddingLeft: 10, paddingRight: 10 }}>
        {errorMessage}
      </p>
    );
  }

  return (
    <Project
      style={{ paddingTop: 60 }}
      projectInfo={projectInfo}
      projectID={projectID}
      fetchProjectInfo={fetchProjectInfo}
    />
  );
};

export default ProjectWrapper;
