import React, { useEffect, useState } from "react";
import { Button, Form, Icon, Input, Modal } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../../../api-config";
import InventoryList from "./InventoryList/InventoryList";
import InventoryListOtherConditions from "./InventoryListOtherConditions/InventoryListOtherConditions";

const InventoryModal = ({
  projectID,
  project,
  conditionID,
  date,
  open,
  setOpen,
  userID,
  category,
  conditionName,
}) => {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [stockItems, setStockItems] = useState([]);
  const [selectedStockItem, setSelectedStockItem] = useState("");
  const [quantity, setQuantity] = useState("");
  const [inventoryLog, setInventoryLog] = useState([]);

  const resetForm = () => {
    setSelectedStockItem("");
    setQuantity("");
  };

  const fetchStockItems = () => {
    setLoading(true);
    Axios.get(`${API_ROOT}/api/acumatica/stock-items`).then((res) => {
      if (res.data) {
        setSelectedStockItem("");
        setStockItems(res.data);
        setLoading(false);
      } else {
        setStockItems([]);
        setLoading(false);
      }
    });
  };

  const fetchInventoryLog = () => {
    Axios.get(
      `${API_ROOT}/api/project/inventory-log?conditionID=${conditionID}`
    ).then((res) => {
      if (res.data) {
        setInventoryLog(res.data);
      } else {
        setInventoryLog([]);
      }
    });
  };

  useEffect(() => {
    if (open) {
      fetchStockItems();
    } else {
      resetForm();
    }
  }, [open, conditionID]);

  useEffect(() => {
    if (open) {
      fetchInventoryLog();
    } else {
      resetForm();
    }
  }, [date, open, conditionID, userID]);

  const options = stockItems.map((obj) => {
    return {
      key: obj.id,
      text: obj.Description.value,
      value: obj.id,
      description: obj.BaseUOM.value,
    };
  });

  const stockItemOptions = options.sort((a, b) => a.text.localeCompare(b.text));

  const handleQtyChange = (e, { value }) => {
    const qty = value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1");
    setQuantity(qty);
  };

  const selectedItemObject = selectedStockItem
    ? stockItems.find((obj) => obj.Description.value === selectedStockItem)
    : "";

  const rawCost = selectedItemObject
    ? selectedItemObject.AverageCost.value.toFixed(2)
    : "";

  const total = quantity > 0 ? (rawCost * quantity).toFixed(2) : "";

  const handleAdd = () => {
    setSaving(true);

    Axios.post(`${API_ROOT}/api/project/inventory-item`, {
      userID,
      projectID,
      conditionID,
      acumaticaUniqueID: selectedItemObject.id,
      inventoryID: selectedItemObject.InventoryID.value,
      description: selectedStockItem,
      baseUOM: selectedItemObject.BaseUOM.value,
      date,
      itemCost: selectedItemObject.AverageCost.value,
      quantity,
    })
      .then((res) => {
        if (res.data) {
          setInventoryLog(res.data);
          resetForm();
          setSaving(false);
        }
      })
      .catch((err) => {
        alert(err);
        setSaving(false);
      });
  };

  return (
    <Modal
      closeOnDimmerClick={false}
      size="small"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header>Log Inventory</Modal.Header>
      <Modal.Content>
        <h4 style={{ color: "#2185d0" }}>
          {`${project.projectID} - ${project.companyName}`}
          <br />
          {`${project.projectName}`}
        </h4>
        <h4 style={{ color: "rgba(254, 80, 0, 0.8)" }}>
          {category} - {conditionName ? conditionName : ""}
        </h4>

        <Form>
          <Form.Field required>
            <label>Inventory</label>
            <Input
              loading={loading}
              placeholder="Select an inventory item..."
              fluid
              list="projectlist"
              value={selectedStockItem}
              onChange={(e, { value }) => {
                setSelectedStockItem(value);
              }}
            />
            <datalist id="projectlist">
              {stockItemOptions.map((option) => (
                <option key={option.value} value={option.text}>
                  {option.description}
                </option>
              ))}
            </datalist>
          </Form.Field>
          <Form.Group widths="equal" unstackable>
            <Form.Input
              width={4}
              style={{ fontSize: 12 }}
              required
              label="Qty"
              type="number"
              onChange={handleQtyChange}
              value={quantity}
            />
            <span
              style={{
                display: "inline-block",
                fontSize: 14,
                fontWeight: "bold",
                marginTop: 28,
                paddingLeft: 0,
                paddingRight: 0,
                cursor: "default",
              }}
            >
              X
            </span>
            <Form.Input
              width={5}
              style={{ fontSize: 12 }}
              label="Cost"
              labelPosition="left"
            >
              <input
                style={{ background: "#eee", cursor: "default" }}
                value={rawCost}
                readOnly
              />
            </Form.Input>
            <span
              style={{
                display: "inline-block",
                fontSize: 22,
                fontWeight: "bold",
                marginTop: 20,
                paddingLeft: 0,
                paddingRight: 0,
                cursor: "default",
              }}
            >
              =
            </span>
            <Form.Input
              style={{ fontSize: 12 }}
              width={5}
              label="Total"
              labelPosition="left"
            >
              <input
                style={{ background: "#eee", cursor: "default" }}
                readOnly
                value={total}
              />
            </Form.Input>
          </Form.Group>

          <Form.Button
            loading={saving}
            style={{ marginTop: 22 }}
            disabled={
              !selectedStockItem ||
              !quantity ||
              quantity === 0 ||
              quantity === "0" ||
              saving
            }
            color="orange"
            fluid
            onClick={handleAdd}
          >
            <Icon name="plus" /> Add Item
          </Form.Button>
        </Form>
        <InventoryList
          inventoryLog={inventoryLog}
          setInventoryLog={setInventoryLog}
        />
        <InventoryListOtherConditions
          projectID={projectID}
          conditionID={conditionID}
          open={open}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Close" onClick={() => setOpen(false)} color="black" />
      </Modal.Actions>
    </Modal>
  );
};

export default InventoryModal;
