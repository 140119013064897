import Axios from "axios";

import { API_ROOT } from "../../api-config";

export const FETCH_PROJECTS_BEGIN = "FETCH_PROJECTS_BEGIN";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECTS_FAILURE = "FETCH_PROJECTS_FAILURE";

export const SET_FORECAST_BEGIN = "SET_FORECAST_BEGIN";
export const SET_FORECAST_SUCCESS = "SET_FORECAST_SUCCESS";
export const SET_FORECAST_FAILURE = "SET_FORECAST_FAILURE";

export const SET_PROBABILITY_BEGIN = "SET_PROBABILITY_BEGIN";
export const SET_PROBABILITY_SUCCESS = "SET_PROBABILITY_SUCCESS";
export const SET_PROBABILITY_FAILURE = "SET_PROBABILITY_FAILURE";

export const SET_STAGETYPE_BEGIN = "SET_STAGETYPE_BEGIN";
export const SET_STAGETYPE_SUCCESS = "SET_STAGETYPE_SUCCESS";
export const SET_STAGETYPE_FAILURE = "SET_STAGETYPE_FAILURE";

export const SET_PROJECTINFO = "SET_PROJECTINFO";

Axios.defaults.withCredentials = true;

//----------- FETCH PROJECT ACTIONS ----------------//

export function fetchProjects() {
  return dispatch => {
    dispatch(fetchProjectsBegin());
    return Axios.get(`${API_ROOT}/api/project`)
      .then(res => {
        dispatch(fetchProductSuccess(JSON.parse(res.data.projects || [])));
        return JSON.parse(res.data.projects || []);
      })
      .catch(error => dispatch(fetchProjectsFailure(error)));
  };
}

export const fetchProjectsBegin = () => ({
  type: FETCH_PROJECTS_BEGIN
});

export const fetchProductSuccess = projects => ({
  type: FETCH_PROJECTS_SUCCESS,
  payload: { projects }
});

export const fetchProjectsFailure = error => ({
  type: FETCH_PROJECTS_FAILURE,
  payload: { error }
});

//----------- SET FORECAST ACTIONS ----------------//

export function setForecast(
  projectID,
  forecastYear,
  forecastMonth,
  forecastPercent,
  monthStr
) {
  return dispatch => {
    dispatch(setForecastBegin());
    return Axios.post(`${API_ROOT}/api/project/forecast`, {
      params: {
        projectID,
        forecastYear,
        forecastMonth,
        forecastPercent,
        monthStr
      }
    })
      .then(res => {
        dispatch(setForecastSuccess(res.data.project));
        return res.data.project;
      })
      .catch(error => dispatch(setForecastFailure(error)));
  };
}

export const setForecastBegin = () => ({
  type: SET_FORECAST_BEGIN
});

export const setForecastSuccess = project => ({
  type: SET_FORECAST_SUCCESS,
  payload: { project }
});

export const setForecastFailure = error => ({
  type: SET_FORECAST_FAILURE,
  payload: { error }
});

//----------- SET PROBABILITY ACTIONS ----------------//
export function setProbability(projectID, probability) {
  return dispatch => {
    dispatch(setProbabilityBegin());
    return Axios.post(`${API_ROOT}/api/project/probability`, {
      params: {
        projectID,
        probability
      }
    })
      .then(res => {
        const project = JSON.parse(res.data.project)[0];
        dispatch(setProbabilitySuccess(project));
        return project;
      })
      .catch(error => {
        console.log(error);
        dispatch(setProbabilityFailure(error));
      });
  };
}

export const setProbabilityBegin = () => ({
  type: SET_PROBABILITY_BEGIN
});

export const setProbabilitySuccess = project => ({
  type: SET_PROBABILITY_SUCCESS,
  payload: { project }
});

export const setProbabilityFailure = error => ({
  type: SET_PROBABILITY_FAILURE,
  payload: { error }
});

//----------- SET STAGE TYPE ACTIONS ----------------//
export function setStageType(projectID, stageType) {
  return dispatch => {
    dispatch(setStageTypeBegin());
    return Axios.post(`${API_ROOT}/api/project/stagetype`, {
      params: {
        projectID,
        stageType
      }
    })
      .then(res => {
        const project = JSON.parse(res.data.project)[0];
        dispatch(setStageTypeSuccess(project));
        return project;
      })
      .catch(error => {
        console.log(error);
        dispatch(setStageTypeFailure(error));
      });
  };
}

export const setStageTypeBegin = () => ({
  type: SET_STAGETYPE_BEGIN
});

export const setStageTypeSuccess = project => ({
  type: SET_STAGETYPE_SUCCESS,
  payload: { project }
});

export const setStageTypeFailure = error => ({
  type: SET_STAGETYPE_FAILURE,
  payload: { error }
});

//----------- SET PROJECTINFO ACTIONS ----------------//
export const setProjectInfo = project => ({
  type: SET_PROJECTINFO,
  payload: project
});
