import { useSelector } from "react-redux";

export const patchUser = (user) => {
  if (!user) return null;

  return {
    ...user,
    hasRole: (role) => {
      if (user.Roles.some((x) => x.Role.toUpperCase() === "SUPERUSER"))
        return true;
      if (user.Roles.some((x) => x.Role.toUpperCase() === role.toUpperCase()))
        return true;
      return false;
    },
  };
};

export default () => {
  const user = useSelector((state) => state._user.current);
  return patchUser(user);
};
