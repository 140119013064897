import Axios from "axios";

import { API_ROOT } from "../../../api-config";

export const SET_INCLUSION_MENU_SELECTION = "SET_INCLUSION_MENU_SELECTION";
export const GET_CONDITION_LIST_BEGIN = "GET_CONDITION_LIST_BEGIN";
export const GET_CONDITION_LIST_SUCCESS = "GET_CONDITION_LIST_SUCCESS";
export const GET_CONDITION_LIST_FAILURE = "GET_CONDITION_LIST_FAILURE";
export const SET_CONDITION_BEGIN = "SET_CONDITION_BEGIN";
export const SET_CONDITION_SUCCESS = "SET_CONDITION_SUCCESS";
export const SET_CONDITION_FAILURE = "SET_CONDITION_FAILURE";
export const ADD_CONDITION_BEGIN = "ADD_CONDITION_BEGIN";
export const ADD_CONDITION_SUCCESS = "ADD_CONDITION_SUCCESS";
export const ADD_CONDITION_FAILURE = "ADD_CONDITION_FAILURE";
export const DEL_CONDITION_BEGIN = "DEL_CONDITION_BEGIN";
export const DEL_CONDITION_SUCCESS = "DEL_CONDITION_SUCCESS";
export const DEL_CONDITION_FAILURE = "DEL_CONDITION_FAILURE";

export const setInclusionMenuSelection = selection => ({
  type: SET_INCLUSION_MENU_SELECTION,
  payload: selection
});

export const getConditionList = (projectID, subCategoryCode) => {
  return dispatch => {
    dispatch(getConditionListBegin());

    Axios.get(`${API_ROOT}/api/condition/`, {
      params: {
        projectID,
        subCategoryCode
      }
    })
      .then(res => {
        const conditionList = res.data.conditions.conditionList || [];

        dispatch(getConditionListSuccess(conditionList));
      })
      .catch(err => {
        dispatch(getConditionListFailure(err.message));
      });
  };
};

export const setCondition = (
  conditionID,
  projectID,
  subCategoryCode,
  field,
  value
) => {
  return dispatch => {
    dispatch(setConditionBegin());

    Axios.post(`${API_ROOT}/api/condition/setcondition`, {
      conditionID,
      projectID,
      subCategoryCode,
      field,
      value
    })
      .then(res => {
        const conditionList = res.data.conditions.conditionList || [];

        dispatch(setConditionSuccess(conditionList));
      })
      .catch(err => {
        dispatch(setConditionFailure(err.message));
      });
  };
};

export const addCondition = (conditionID, projectID, subCategoryCode) => {
  return dispatch => {
    dispatch(addConditionBegin());

    Axios.post(`${API_ROOT}/api/condition/addcondition`, {
      conditionID,
      projectID,
      subCategoryCode
    })
      .then(res => {
        const conditionList = res.data.conditions.conditionList || [];
        dispatch(addConditionSuccess(conditionList));
      })
      .catch(err => {
        dispatch(addConditionFailure(err.message));
      });
  };
};

export const delCondition = (conditionID, projectID, subCategoryCode) => {
  return dispatch => {
    dispatch(delConditionBegin());

    Axios.post(`${API_ROOT}/api/condition/delcondition`, {
      conditionID,
      projectID,
      subCategoryCode
    })
      .then(res => {
        const conditionList = res.data.conditions.conditionList || [];
        dispatch(delConditionSuccess(conditionList));
      })
      .catch(err => {
        dispatch(delConditionFailure(err.message));
      });
  };
};

const getConditionListBegin = () => ({
  type: GET_CONDITION_LIST_BEGIN
});

const getConditionListSuccess = conditionList => ({
  type: GET_CONDITION_LIST_SUCCESS,
  payload: conditionList
});

const getConditionListFailure = error => ({
  type: GET_CONDITION_LIST_FAILURE,
  payload: error
});

const setConditionBegin = () => ({
  type: SET_CONDITION_BEGIN
});

const setConditionSuccess = conditionList => ({
  type: SET_CONDITION_SUCCESS,
  payload: conditionList
});

const setConditionFailure = error => ({
  type: SET_CONDITION_FAILURE,
  payload: error
});

const addConditionBegin = () => ({
  type: ADD_CONDITION_BEGIN
});

const addConditionSuccess = conditionList => ({
  type: ADD_CONDITION_SUCCESS,
  payload: conditionList
});

const addConditionFailure = error => ({
  type: ADD_CONDITION_FAILURE,
  payload: error
});

const delConditionBegin = () => ({
  type: ADD_CONDITION_BEGIN
});

const delConditionSuccess = conditionList => ({
  type: ADD_CONDITION_SUCCESS,
  payload: conditionList
});

const delConditionFailure = error => ({
  type: ADD_CONDITION_FAILURE,
  payload: error
});
