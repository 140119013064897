import Axios from "axios";
import React, { useEffect, useState } from "react";
import { API_ROOT } from "../../api-config";
import { AgGridReact } from "ag-grid-react";
import {
  Button,
  Dimmer,
  Grid,
  Icon,
  Input,
  Loader,
  Popup,
  Segment,
} from "semantic-ui-react";
import "./BrowserArchive.scss";
import { Link } from "react-router-dom";
// import NotesModal from "../Browser/ProjectsTable/IconGrid/NotesModal/NotesModal";
// import Probability from "./Probability/Probability";
import StageType from "./StageType/StageType";
import numeral from "numeral";
import Forecast from "./Forecast/Forecast";
// import { ChangeDetectionService } from "ag-grid-react/lib/changeDetectionService";
import NotesModal from "../Browser2/NotesModal/NotesModal";
// import PipelineReport from "../Browser/ProjectsTable/PipelineReport/PipelineReport";
// import StageButtons from "./StageButtons/StageButtons";

const BrowserArchive = () => {
  const [gridApi, setGridApi] = useState(null);
  // const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  // const [typee, setTypee] = useState("active");

  const onGridReady = (params) => {
    setGridApi(params.api);
    // setGridColumnApi(params.columnApi);
  };

  // useEffect(() => {
  //   if (gridColumnApi) {
  //     gridColumnApi.autoSizeAllColumns();
  //   }
  // }, [gridColumnApi]);

  const getProjects = () => {
    Axios.get(`${API_ROOT}/api/project/browser-archive`)
      .then((res) => {
        setRowData(res.data.projects);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getProjects();
  }, []);

  const onFilterTextBoxChanged = (e, { value }) => {
    gridApi.setQuickFilter(value);
  };

  const currencyFormatter = (currency, sign) => {
    var sansDec = currency.toFixed(0);
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return sign + `${formatted}`;
  };

  const columnDefs = [
    {
      field: null,
      // pinned: "left",
      headerName: "",
      filter: false,
      sortable: false,
      resizable: false,
      suppressMovable: true,
      cellStyle: { padding: "8px 0px 0px 0px" },
      maxWidth: 60,
      minWidth: 60,
      cellRendererFramework: (params) => {
        return (
          <Grid style={{ margin: 0, padding: 0 }}>
            <Grid.Row style={{ margin: 0, padding: 0, paddingTop: 4 }}>
              <Grid.Column
                style={{ fontSize: 12, margin: 0, padding: 0, width: 26 }}
              >
                <Link to={`/browser/projects/${params.data.ProjectID}`}>
                  <Icon
                    style={{ padding: 10 }}
                    bordered
                    color="blue"
                    link
                    name="search"
                  />
                </Link>
              </Grid.Column>
              <Grid.Column
                style={{ fontSize: 12, margin: 0, padding: 0, width: 26 }}
              >
                <Link
                  target="_blank"
                  to={
                    params.data.ClientContactID
                      ? `/crm?uid=${params.data.ClientContactID}`
                      : "/crm"
                  }
                >
                  <Icon link bordered name="user" />
                </Link>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Grid.Column
                style={{ fontSize: 12, margin: 0, padding: 0, width: 26 }}
              >
                <NotesModal
                  color={params.data.NotesColor}
                  projectID={params.data.ProjectID}
                  projects={rowData}
                  getProjects={getProjects}
                />
              </Grid.Column>
              <Grid.Column
                style={{ fontSize: 12, margin: 0, padding: 0, width: 26 }}
              >
                <Popup
                  content={
                    <ul className="ul__stoplight">
                      <li>
                        <Icon
                          inverted
                          bordered
                          name={params.data.ForecastIcon}
                          color={params.data.ForecastColor}
                        />{" "}
                        Forecast
                      </li>
                      <li>
                        <Icon
                          inverted
                          bordered
                          name={params.data.ScopeAckIcon}
                          color={params.data.ScopeAckColor}
                        />{" "}
                        Job Start Up
                      </li>
                      <li>
                        <Icon
                          inverted
                          bordered
                          name={params.data.BidsIcon}
                          color={params.data.BidsColor}
                        />{" "}
                        Subcontractor Bids
                      </li>
                      <li>
                        <Icon
                          inverted
                          bordered
                          name={params.data.SubcontractorContractIcon}
                          color={params.data.SubcontractorContractColor}
                        />{" "}
                        Subcontractor Contract
                      </li>
                      <li>
                        <Icon
                          inverted
                          bordered
                          name={params.data.ClientContractIcon}
                          color={params.data.ClientContractColor}
                        />{" "}
                        Client Contract
                      </li>
                    </ul>
                  }
                  on="click"
                  header="Stoplight"
                  position="right center"
                  trigger={
                    <Icon
                      link
                      bordered
                      inverted
                      color={params.data.MasterWarningColor}
                      name={params.data.MasterWarningIcon}
                    />
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      },
    },
    {
      field: "ProjectName",
      headerName: "Project",
      flex: 2,
      minWidth: 280,
      cellStyle: { padding: "10px 0px 0px 0px" },
      cellRendererFramework: (params) => {
        return (
          <span className="project-description">
            {params.data.ClientCompanyName
              ? `${params.data.ClientCompanyName}`
              : ""}
            <br />
            {params.data.WorkLocationName}
            <br />
            {params.data.ProjectID}-{params.data.ProjectName}
          </span>
        );
      },
      valueGetter: (params) => {
        return `${params.data.ClientCompanyName} ${params.data.WorkLocationName} ${params.data.ProjectID} ${params.data.ProjectName}`;
      },
    },
    {
      field: "PMInitials",
      headerName: "PM",
      resizable: false,
      filter: false,
      maxWidth: 44,
      minWidth: 44,
      cellStyle: { color: "#fe5000", paddingTop: "26px" },
      headerClass: "browser-initials-header",
    },
    {
      field: "PEInitials",
      headerName: "PE",
      resizable: false,
      filter: false,
      maxWidth: 44,
      minWidth: 44,
      cellStyle: { color: "black", paddingTop: "26px" },
      headerClass: "browser-initials-header",
    },
    {
      field: "SIInitials",
      headerName: "SI",
      resizable: false,
      filter: false,
      maxWidth: 44,
      minWidth: 44,
      cellStyle: { color: "gray", paddingTop: "26px" },
      headerClass: "browser-initials-header",
    },
    {
      field: "BidTypeShort",
      filter: false,
      headerName: "Type",
      cellStyle: { paddingTop: "26px", paddingRight: "0px" },
      minWidth: 86,
      maxWidth: 120,
    },
    // {
    //   headerName: "Prob",
    //   minWidth: 50,
    //   cellStyle: { paddingTop: "26px" },
    //   // filter: "agNumberColumnFilter",
    //   filter: false,
    //   field: "Probability",
    //   cellRendererFramework: (params) => (
    //     <Probability
    //       projectID={params.data.ProjectID}
    //       prob={params.data.Probability}
    //       rowData={rowData}
    //       setRowData={setRowData}
    //     />
    //   ),
    // },
    {
      headerName: "Stage",
      minWidth: 76,
      maxWidth: 120,
      sort: "asc",
      filter: false,
      cellStyle: { paddingTop: "26px", paddingRight: "0px" },
      field: "ProjectStageAbbr",
      cellRendererFramework: (params) => (
        <StageType
          projectID={params.data.ProjectID}
          stage={params.value}
          rowData={rowData}
          setRowData={setRowData}
          stageType={params.data.StageType}
          // setStageType={this.props.setStageType}
          // loadProjectCounts={this.loadProjectCounts}
        />
      ),
    },
    {
      field: "Days",
      // filter: "agNumberColumnFilter",
      minWidth: 54,
      maxWidth: 140,
      filter: false,
      cellStyle: { paddingTop: "26px" },
      headerName: "Days",
    },
    {
      field: "ContractAmount",
      headerName: "Contract",
      minWidth: 80,
      maxWidth: 160,
      // filter: "agNumberColumnFilter",
      cellStyle: { paddingTop: "26px", paddingRight: "0px" },
      filter: false,
      valueFormatter: (params) =>
        currencyFormatter(params.data.ContractAmount, "$"),
    },
    {
      field: "EstMarginPercent",
      headerName: "Mar %",
      minWidth: 60,
      maxWidth: 140,
      filter: false,
      cellStyle: { paddingTop: "26px", paddingRight: "0px" },
      // filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.data.EstMarginPercent
          ? params.data.EstMarginPercent.toFixed(1) + "%"
          : "0.0%",
    },
    {
      field: "EstMargin",
      headerName: "Mar $",
      minWidth: 80,
      maxWidth: 160,
      cellStyle: {
        paddingTop: "26px",
        paddingRight: "0px",
      },
      // filter: "agNumberColumnFilter",
      filter: false,
      valueFormatter: (params) => currencyFormatter(params.data.EstMargin, "$"),
    },
    {
      field: "Variance",
      headerName: "Var",
      minWidth: 50,
      maxWidth: 50,
      // filter: "agNumberColumnFilter",

      filter: false,
      cellStyle: (params) => {
        if (params.data.Variance >= 0) {
          return {
            padding: "0px",
            color: "black",
            fontWeight: "normal",
            paddingTop: "20px",
          };
        }
        return {
          padding: "0px",
          color: "red",
          fontWeight: "bold",
          paddingTop: "20px",
        };
      },
      valueFormatter: (params) => numeral(params.value).format("0.00%"),
    },
    {
      headerName: "Jan",
      maxWidth: 34,
      resizable: false,
      minWidth: 34,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "jan",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "5px 0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="jan"
          rowData={rowData}
          setRowData={setRowData}
          color="#fe5000"
        />
      ),
    },
    {
      headerName: "Feb",
      maxWidth: 34,
      resizable: false,
      minWidth: 34,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "feb",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "5px 0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="feb"
          rowData={rowData}
          setRowData={setRowData}
          color="#fe5000"
        />
      ),
    },
    {
      headerName: "Mar",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "mar",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "5px 0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="mar"
          rowData={rowData}
          setRowData={setRowData}
          color="#fe5000"
        />
      ),
    },
    {
      headerName: "Apr",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "apr",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "5px 0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="apr"
          rowData={rowData}
          setRowData={setRowData}
          color="#626262"
        />
      ),
    },
    {
      headerName: "May",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "may",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "5px 0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="may"
          rowData={rowData}
          setRowData={setRowData}
          color="#626262"
        />
      ),
    },
    {
      headerName: "Jun",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "jun",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "5px 0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="jun"
          rowData={rowData}
          setRowData={setRowData}
          color="#626262"
        />
      ),
    },
    {
      headerName: "Jul",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "jul",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "5px 0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="jul"
          rowData={rowData}
          setRowData={setRowData}
          color="#fe5000"
        />
      ),
    },
    {
      headerName: "Aug",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "aug",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "5px 0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="aug"
          rowData={rowData}
          setRowData={setRowData}
          color="#fe5000"
        />
      ),
    },
    {
      headerName: "Sep",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "sep",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "5px 0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="sep"
          rowData={rowData}
          setRowData={setRowData}
          color="#fe5000"
        />
      ),
    },
    {
      headerName: "Oct",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "oct",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "5px 0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="oct"
          rowData={rowData}
          setRowData={setRowData}
          color="#626262"
        />
      ),
    },
    {
      headerName: "Nov",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "nov",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "5px 0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="nov"
          rowData={rowData}
          setRowData={setRowData}
          color="#626262"
        />
      ),
    },
    {
      headerName: "Dec",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      field: "dec",
      suppressMovable: true,
      headerClass: "browser-initials-header",
      cellStyle: { padding: "5px 0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="dec"
          rowData={rowData}
          setRowData={setRowData}
          color="#626262"
        />
      ),
    },
  ];

  // const externalFilterChanged = (newValue) => {
  //   type = newValue;
  //   setTypee(newValue);
  //   gridApi.onFilterChanged();
  // };

  const isExternalFilterPresent = () => {
    return type !== "active";
  };

  const doesExternalFilterPass = (node) => {
    switch (type) {
      case "4PREP":
        return node.data.ProjectStageAbbr === "4PREP";
      case "3CLIENT":
        return node.data.ProjectStageAbbr === "3CLIENT";
      case "2APRVD":
        return node.data.ProjectStageAbbr === "2APRVD";
      case "1WIP":
        return node.data.ProjectStageAbbr === "1WIP";
      default:
        return true;
    }
  };

  return Array.isArray(rowData) && rowData.length ? (
    <div className="Browser2" style={{ marginTop: 46, height: "100%" }}>
      <div style={{ height: "100%" }}>
        <Segment style={{ marginTop: -64 }} basic textAlign="right">
          {/* <Button
            active={typee === "active"}
            onClick={() => externalFilterChanged("active")}
          >
            Active
          </Button> */}

          <Button size="small" basic as={Link} to="/browser">
            Active
          </Button>

          {/* <StageButtons
            typee={typee}
            externalFilterChanged={externalFilterChanged}
          /> */}

          {/* <ButtonGroup>
            <Button
              active={typee === "1WIP"}
              onClick={() => externalFilterChanged("1WIP")}
            >
              WIP
            </Button>
            <Button
              active={typee === "2APRVD"}
              onClick={() => externalFilterChanged("2APRVD")}
            >
              APRVD
            </Button>
            <Button
              active={typee === "3CLIENT"}
              onClick={() => externalFilterChanged("3CLIENT")}
            >
              CLIENT
            </Button>
            <Button
              active={typee === "4PREP"}
              onClick={() => externalFilterChanged("4PREP")}
            >
              PREP
            </Button>
          </ButtonGroup> */}

          <Input
            size="small"
            type="text"
            id="filter-text-box"
            placeholder="Filter..."
            onChange={onFilterTextBoxChanged}
          />
        </Segment>
      </div>
      <div
        className="ag-theme-balham"
        style={{
          height: "calc(100vh - 130px)",
          // width: "100%",
          // overflowY: "scroll",
        }}
      >
        <AgGridReact
          // animateRows={true}
          immutableData={true}
          rowSelection={"single"}
          getRowNodeId={(data) => data.ProjectID}
          // rowDataChangeDetectionStrategy={ChangeDetectionService.DeepValueCheck}
          // rowHeight={66}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          rowHeight={76}
          // domLayout="autoHeight"
          onGridReady={onGridReady}
          onFirstDataRendered={(params) => {
            params.api.sizeColumnsToFit();
          }}
          onGridSizeChanged={(params) => {
            params.api.sizeColumnsToFit();
          }}
          rowData={rowData}
          columnDefs={columnDefs}
          // autoHeight={true}
          defaultColDef={{
            cellStyle: { "white-space": "normal", "line-height": "14px" },
            flex: 1,
            suppressSizeToFit: false,
            resizable: true,
            sortable: true,
            filter: true,
            // wrapText: true,
          }}
        />
      </div>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>
  );
};

var type = "active";

// function headerHeightGetter() {
//   var columnHeaderTexts = [
//     ...document.querySelectorAll(".ag-header-cell-text"),
//   ];
//   var clientHeights = columnHeaderTexts.map(
//     (headerText) => headerText.clientHeight
//   );
//   var tallestHeaderTextHeight = Math.max(...clientHeights);

//   return tallestHeaderTextHeight;
// }

export default BrowserArchive;
