import React, { useEffect, useState } from "react";
import IncentivePayout from "./IncentivePayout";
import { useSelector } from "react-redux";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";

const IncentivePayoutWrapper = () => {
  const year = useSelector((state) => state.incentivePayout.year);
  const [incentiveSummary, setIncentiveSummary] = useState(null);
  const [payoutWorkSheetQ1, setPayoutWorkSheetQ1] = useState(null);
  const [payoutWorkSheetQ2, setPayoutWorkSheetQ2] = useState(null);
  const [payoutWorkSheetQ3, setPayoutWorkSheetQ3] = useState(null);
  const [payoutWorkSheetQ4, setPayoutWorkSheetQ4] = useState(null);
  const [incentivePool, setIncentivePool] = useState(null);

  const getIncentivePool = () => {
    Axios.get(`${API_ROOT}/api/kpi/incentive-pool`, {
      params: {
        year,
        division: "Group",
      },
    })
      .then((res) => {
        setIncentivePool(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getIncentiveSummary = () => {
    Axios.get(`${API_ROOT}/api/kpi/incentive-summary`, {
      params: {
        year,
      },
    })
      .then((res) => {
        setIncentiveSummary(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getPayoutWorksheet = (quarter) => {
    Axios.get(`${API_ROOT}/api/kpi/payout-worksheet`, {
      params: {
        year,
        quarter,
      },
    }).then((res) => {
      switch (quarter) {
        case 1:
          setPayoutWorkSheetQ1(res.data);
          break;
        case 2:
          setPayoutWorkSheetQ2(res.data);
          break;
        case 3:
          setPayoutWorkSheetQ3(res.data);
          break;
        case 4:
          setPayoutWorkSheetQ4(res.data);
          break;
        default:
          break;
      }
    });
  };

  useEffect(() => {
    getIncentiveSummary();
    getPayoutWorksheet(1);
    getPayoutWorksheet(2);
    getPayoutWorksheet(3);
    getPayoutWorksheet(4);
    getIncentivePool();
  }, [year]);

  return (
    <IncentivePayout
      year={year}
      incentiveSummary={incentiveSummary}
      payoutWorkSheetQ1={payoutWorkSheetQ1}
      payoutWorkSheetQ2={payoutWorkSheetQ2}
      payoutWorkSheetQ3={payoutWorkSheetQ3}
      payoutWorkSheetQ4={payoutWorkSheetQ4}
      incentivePool={incentivePool}
    />
  );
};

export default IncentivePayoutWrapper;
