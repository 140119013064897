import React from "react";

export default ({ summary, inclusions }) => {
  const colspan = summary.recipient === "sub" ? 3 : 2;

  return (
    <div className="inclusions">
      <table className="ui table">
        <thead>
          <tr>
            <th>Scope - {summary.contract_type}</th>
            {summary.Recipient === "sub" && <th>Description</th>}
            <th className="right aligned">Price</th>
          </tr>
        </thead>

        {inclusions &&
          Object.keys(inclusions).map((category) => {
            const subCategories = inclusions[category];

            return (
              <React.Fragment key={category}>
                <thead>
                  <tr className="category">
                    <td colSpan={colspan}>{category.toUpperCase()}</td>
                  </tr>
                </thead>
                <tbody>
                  {subCategories &&
                    Object.keys(subCategories).map((subCategory) => {
                      const details = subCategories[subCategory].inclusions;

                      return (
                        <React.Fragment key={subCategory}>
                          <tr className="sub-category">
                            <th colSpan={colspan}>
                              {subCategory.toUpperCase()}
                            </th>
                          </tr>

                          {details &&
                            details.map((row) => {
                              let description = row.condition_name;
                              description += row.internal_comments
                                ? " - " + row.internal_comments
                                : "";
                              const total = new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(row.total);

                              return (
                                <tr
                                  key={row.condition_id}
                                  className="inclusion"
                                >
                                  <td>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: description,
                                      }}
                                    ></span>
                                  </td>
                                  <td className="total">{total}</td>
                                </tr>
                              );
                            })}
                        </React.Fragment>
                      );
                    })}
                </tbody>
              </React.Fragment>
            );
          })}
        <tbody className="footer">
          <tr>
            <th>
              <h2>Proposed Total Price</h2>
            </th>
            <th className="total">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(summary.grand_total)}
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
