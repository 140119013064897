import React from "react";
import { Table } from "semantic-ui-react";

const SummaryContractRow = (props) => {
  function groupBy(objectArray, property) {
    return objectArray.reduce(function(acc, obj) {
      var key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  const conditions = props.conditions;
  // const summary = props.summary;

  const filteredConditions = conditions.filter(
    (item) => item.Category === props.category
  );
  const grouped = groupBy(filteredConditions, "SubCategory");
  const subCategories = [
    ...new Set(filteredConditions.map((item) => item.SubCategory)),
  ];
  return (
    <>
      {subCategories.map((subCategory) => (
        <Table.Row key={subCategory}>
          <Table.Cell verticalAlign="top">Base</Table.Cell>
          <Table.Cell verticalAlign="top">{subCategory}</Table.Cell>
          <Table.Cell
            verticalAlign="top"
            style={{ color: "#fe5000", fontWeight: "bold" }}
          >
            $
            {grouped[subCategory].reduce((accumulator, currentValue) => {
              return (
                parseFloat(accumulator) + parseFloat(currentValue.Price)
              ).toFixed(2);
            }, 0)}
          </Table.Cell>
          <Table.Cell verticalAlign="top">
            <span
              dangerouslySetInnerHTML={{
                __html: grouped[subCategory][0].SummaryDescription,
              }}
            ></span>
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default SummaryContractRow;
