import React, { useEffect, useState, Fragment, useContext } from "react";
import {
  Container,
  Grid,
  Header,
  Dimmer,
  Loader,
  Table,
  Icon,
} from "semantic-ui-react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import numeral from "numeral";

import "./Tracking.scss";
import { API_ROOT } from "../../../api-config";
import { useShowV1 } from "../../../hooks/useQuery";
import { setInclusionMenuSelection } from "../../../actions/project/inclusion/inclusionActions";
import AdditionalCostInput from "./AdditionalCostInput";
import useIsMounted from "../../../hooks/isMounted";
import CostManagement from "./CostManagement/CostManagement";
import MaterialPurchases from "./MaterialPurchases/MaterialPurchases";
import Inventory from "./Inventory/Inventory";

import TrackingList from "../../../screens/projects/tracking/List";
import CurrentProject from "../../../screens/projects/CurrentProject";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const percentageFormatter = (param) => {
  return numeral(param).format("0%");
};

const Tracking = ({ projectID }) => {
  const { useV2 } = useContext(CurrentProject);
  const showV1 = useShowV1();

  const [trackingData, setTrackingData] = useState(null);
  const [trackingTotalsData, setTrackingTotalsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [materialOpen, setMaterialOpen] = useState(false);
  const [inventoryOpen, setInventoryOpen] = useState(false);

  const dispatch = useDispatch();

  const isMounted = useIsMounted();

  const getTrackingData = () => {
    Axios.get(`${API_ROOT}/api/project/cost-tracking`, {
      params: {
        projectID,
      },
    })
      .then((res) => {
        if (res.data && isMounted.current) {
          setTrackingData(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTrackingTotalsData = () => {
    Axios.get(`${API_ROOT}/api/project/cost-tracking-totals`, {
      params: {
        projectID,
      },
    })
      .then((res) => {
        if (res.data && isMounted.current) {
          setTrackingTotalsData(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (projectID && isMounted.current) {
      setLoading(true);
      getTrackingData();
      getTrackingTotalsData();
    }
  }, [projectID]);

  return (
    <Container
      className="Tracking"
      fluid
      style={{ overflow: "auto", minHeight: "calc(100vh - 160px)" }}
    >
      <Grid style={{ maxWidth: "100%", margin: 0 }}>
        {(!useV2() || showV1) && (
          <>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header style={{ color: "#fe5000" }} as="h1">
                  Tracking (OLD)
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                {loading ? (
                  <Dimmer active inverted>
                    <Loader size="large" inverted>
                      Loading
                    </Loader>
                  </Dimmer>
                ) : (
                  <Table compact="very" unstackable style={{ fontSize: 12 }}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell
                          textAlign="center"
                          // className="border-left"
                          colSpan={3}
                        >
                          Current Contract
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          textAlign="center"
                          className="border-left"
                          colSpan={3}
                        >
                          Committed Costs
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          textAlign="center"
                          className="border-left"
                          colSpan={4}
                        >
                          Actual Costs
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          textAlign="center"
                          className="border-left"
                          colSpan={4}
                        >
                          Worksheet
                        </Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{ fontSize: 9 }}>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell
                          // className="border-left"
                          textAlign="center"
                        >
                          Base
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          Approved Changes
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          Adjusted (Current)
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          className="border-left"
                          textAlign="center"
                        >
                          Committed Cost
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          Committed Profit
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          Committed Profit %
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          className="border-left"
                          textAlign="center"
                        >
                          Actual Cost
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          Balance
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          Actual Profit
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          Actual Profit %
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          className="border-left"
                          textAlign="center"
                        >
                          Additional Cost (+/-)
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          Estimated Cost
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          Estimated Profit
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          Estimated Profit %
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {Array.isArray(trackingData) && trackingData.length
                        ? trackingData.map((category) => (
                            <Fragment key={category.categoryCode}>
                              <Table.Row>
                                <Table.Cell
                                  style={{
                                    color: "rgb(254, 80, 0)",
                                    fontWeight: "bold",
                                    fontSize: 12,
                                  }}
                                  colSpan={11}
                                >
                                  {category.categoryCode
                                    ? category.categoryCode.toUpperCase()
                                    : ""}
                                </Table.Cell>
                                <Table.Cell
                                  className="border-left"
                                  style={{ background: "#f9fafb" }}
                                  colSpan={4}
                                ></Table.Cell>
                              </Table.Row>
                              {category.subCategories.map((subCategory) => (
                                <Table.Row key={subCategory.subCategoryCode}>
                                  <Table.Cell style={{ paddingLeft: 20 }}>
                                    <Link
                                      onClick={() =>
                                        dispatch(
                                          setInclusionMenuSelection({
                                            selectedCategoryID:
                                              category.mainCategoryID,
                                            selectedSubCategoryCode:
                                              subCategory.subCategoryCode,
                                          })
                                        )
                                      }
                                      to={`/browser/projects/inclusions/${projectID}`}
                                    >
                                      {subCategory.subCategoryCode === "Total"
                                        ? ""
                                        : subCategory.subCategoryCode}
                                    </Link>
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    style={
                                      subCategory.subCategoryCode === "Total"
                                        ? { color: "blue", fontWeight: "bold" }
                                        : {}
                                    }
                                  >
                                    {formatter.format(subCategory.base)}
                                  </Table.Cell>
                                  <Table.Cell
                                    style={
                                      subCategory.subCategoryCode === "Total"
                                        ? { color: "blue", fontWeight: "bold" }
                                        : {}
                                    }
                                    textAlign="center"
                                  >
                                    {formatter.format(
                                      subCategory.approvedChanges
                                    )}
                                  </Table.Cell>
                                  <Table.Cell
                                    style={
                                      subCategory.subCategoryCode === "Total"
                                        ? { color: "blue", fontWeight: "bold" }
                                        : { color: "rgb(254, 80, 0)" }
                                    }
                                    textAlign="center"
                                  >
                                    {formatter.format(subCategory.adjusted)}
                                  </Table.Cell>
                                  <Table.Cell
                                    style={
                                      subCategory.subCategoryCode === "Total"
                                        ? { color: "blue", fontWeight: "bold" }
                                        : {}
                                    }
                                    textAlign="center"
                                    className="border-left"
                                  >
                                    {formatter.format(
                                      subCategory.committedCost
                                    )}
                                  </Table.Cell>
                                  <Table.Cell
                                    style={
                                      subCategory.subCategoryCode === "Total"
                                        ? { color: "blue", fontWeight: "bold" }
                                        : {}
                                    }
                                    textAlign="center"
                                  >
                                    {formatter.format(
                                      subCategory.committedProfit
                                    )}
                                  </Table.Cell>
                                  <Table.Cell
                                    style={
                                      subCategory.subCategoryCode === "Total"
                                        ? { color: "blue", fontWeight: "bold" }
                                        : {}
                                    }
                                    textAlign="center"
                                  >
                                    {subCategory.committedProfitPercent ||
                                    subCategory.committedProfitPercent === 0
                                      ? percentageFormatter(
                                          subCategory.committedProfitPercent
                                        )
                                      : null}
                                  </Table.Cell>
                                  <Table.Cell
                                    style={
                                      subCategory.subCategoryCode === "Total"
                                        ? { color: "blue", fontWeight: "bold" }
                                        : {}
                                    }
                                    textAlign="center"
                                    className="border-left"
                                  >
                                    {formatter.format(subCategory.actualCost)}
                                  </Table.Cell>
                                  <Table.Cell
                                    style={
                                      subCategory.subCategoryCode === "Total"
                                        ? { color: "blue", fontWeight: "bold" }
                                        : {}
                                    }
                                    textAlign="center"
                                  >
                                    {formatter.format(subCategory.balance)}
                                  </Table.Cell>
                                  <Table.Cell
                                    style={
                                      subCategory.subCategoryCode === "Total"
                                        ? { color: "blue", fontWeight: "bold" }
                                        : {}
                                    }
                                    textAlign="center"
                                  >
                                    {formatter.format(subCategory.actualProfit)}
                                  </Table.Cell>
                                  <Table.Cell
                                    style={
                                      subCategory.subCategoryCode === "Total"
                                        ? { color: "blue", fontWeight: "bold" }
                                        : {}
                                    }
                                    textAlign="center"
                                  >
                                    {subCategory.actualProfitPercent ||
                                    subCategory.actualProfitPercent === 0
                                      ? percentageFormatter(
                                          subCategory.actualProfitPercent
                                        )
                                      : null}
                                  </Table.Cell>
                                  <Table.Cell
                                    style={
                                      subCategory.subCategoryCode === "Total"
                                        ? {
                                            color: "blue",
                                            fontWeight: "bold",
                                            background: "#f9fafb",
                                          }
                                        : { background: "#f9fafb" }
                                    }
                                    textAlign="center"
                                    className="border-left"
                                  >
                                    {subCategory.subCategoryCode === "Total" ? (
                                      formatter.format(
                                        subCategory.additionalCost
                                      )
                                    ) : (
                                      <AdditionalCostInput
                                        projectID={projectID}
                                        subCategoryCode={
                                          subCategory.subCategoryCode
                                        }
                                        setTrackingData={setTrackingData}
                                        getTrackingTotalsData={
                                          getTrackingTotalsData
                                        }
                                        additionalCost={
                                          subCategory.additionalCost
                                        }
                                      />
                                    )}
                                  </Table.Cell>
                                  <Table.Cell
                                    style={
                                      subCategory.subCategoryCode === "Total"
                                        ? {
                                            color: "blue",
                                            fontWeight: "bold",
                                            background: "#f9fafb",
                                          }
                                        : { background: "#f9fafb" }
                                    }
                                    textAlign="center"
                                  >
                                    {formatter.format(
                                      subCategory.estimatedCost
                                    )}
                                  </Table.Cell>
                                  <Table.Cell
                                    style={
                                      subCategory.subCategoryCode === "Total"
                                        ? {
                                            color: "blue",
                                            fontWeight: "bold",
                                            background: "#f9fafb",
                                          }
                                        : { background: "#f9fafb" }
                                    }
                                    textAlign="center"
                                  >
                                    {formatter.format(
                                      subCategory.estimatedProfit
                                    )}
                                  </Table.Cell>
                                  <Table.Cell
                                    style={
                                      subCategory.subCategoryCode === "Total"
                                        ? {
                                            color: "blue",
                                            fontWeight: "bold",
                                            background: "#f9fafb",
                                          }
                                        : { background: "#f9fafb" }
                                    }
                                    textAlign="center"
                                  >
                                    {subCategory.estimatedProfitPercent ||
                                    subCategory.estimatedProfitPercent === 0
                                      ? percentageFormatter(
                                          subCategory.estimatedProfitPercent
                                        )
                                      : null}
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </Fragment>
                          ))
                        : null}
                    </Table.Body>
                    {trackingTotalsData ? (
                      <Table.Footer>
                        <Table.Row>
                          <Table.HeaderCell></Table.HeaderCell>
                          <Table.HeaderCell
                            textAlign="center"
                            style={{ color: "purple", fontWeight: "bold" }}
                          >
                            {formatter.format(trackingTotalsData.baseTotal)}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            textAlign="center"
                            style={{ color: "purple", fontWeight: "bold" }}
                          >
                            {formatter.format(
                              trackingTotalsData.approvedChangesTotal
                            )}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            textAlign="center"
                            style={{ color: "purple", fontWeight: "bold" }}
                          >
                            {formatter.format(trackingTotalsData.adjustedTotal)}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="border-left"
                            textAlign="center"
                            style={{ color: "purple", fontWeight: "bold" }}
                          >
                            {formatter.format(
                              trackingTotalsData.committedCostTotal
                            )}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            textAlign="center"
                            style={{ color: "purple", fontWeight: "bold" }}
                          >
                            {formatter.format(
                              trackingTotalsData.committedProfitTotal
                            )}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            textAlign="center"
                            style={{ color: "purple", fontWeight: "bold" }}
                          >
                            {trackingTotalsData.committedProfitPercentTotal ||
                            trackingTotalsData.committedProfitPercentTotal === 0
                              ? percentageFormatter(
                                  trackingTotalsData.committedProfitPercentTotal
                                )
                              : null}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="border-left"
                            textAlign="center"
                            style={{ color: "purple", fontWeight: "bold" }}
                          >
                            {formatter.format(
                              trackingTotalsData.actualCostTotal
                            )}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            textAlign="center"
                            style={{ color: "purple", fontWeight: "bold" }}
                          >
                            {formatter.format(trackingTotalsData.balanceTotal)}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            textAlign="center"
                            style={{ color: "purple", fontWeight: "bold" }}
                          >
                            {formatter.format(
                              trackingTotalsData.actualProfitTotal
                            )}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            textAlign="center"
                            style={{ color: "purple", fontWeight: "bold" }}
                          >
                            {trackingTotalsData.actualProfitPercentTotal ||
                            trackingTotalsData.actualProfitPercentTotal === 0
                              ? percentageFormatter(
                                  trackingTotalsData.actualProfitPercentTotal
                                )
                              : null}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="border-left"
                            textAlign="center"
                            style={{ color: "purple", fontWeight: "bold" }}
                          >
                            {formatter.format(
                              trackingTotalsData.additionalCostTotal
                            )}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            textAlign="center"
                            style={{ color: "purple", fontWeight: "bold" }}
                          >
                            {formatter.format(
                              trackingTotalsData.estimatedCostTotal
                            )}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            textAlign="center"
                            style={{ color: "purple", fontWeight: "bold" }}
                          >
                            {formatter.format(
                              trackingTotalsData.estimatedProfitTotal
                            )}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            textAlign="center"
                            style={{ color: "purple", fontWeight: "bold" }}
                          >
                            {percentageFormatter(
                              trackingTotalsData.estimatedProfitPercentTotal
                            )}
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                    ) : null}
                  </Table>
                )}
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        {useV2() && (
          <>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header style={{ color: "#fe5000" }} as="h1">
                  Tracking {showV1 && "(NEW)"}
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                {loading ? (
                  <Dimmer active inverted>
                    <Loader size="large" inverted>
                      Loading
                    </Loader>
                  </Dimmer>
                ) : (
                  <TrackingList projectId={projectID} />
                )}
              </Grid.Column>
            </Grid.Row>
          </>
        )}

        <Grid.Row>
          <Grid.Column width={16}>
            <Header style={{ color: "#fe5000" }} as="h1">
              N5 Cost Management
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <CostManagement projectID={projectID} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Header
              style={{ color: "#fe5000", cursor: "pointer" }}
              as="h1"
              onClick={() => setMaterialOpen(!materialOpen)}
            >
              Material{""}
              {materialOpen ? (
                <Icon
                  color="grey"
                  name="caret down"
                  // onClick={() => setMaterialOpen(false)}
                />
              ) : (
                <Icon
                  color="grey"
                  name="caret right"
                  // onClick={() => setMaterialOpen(true)}
                />
              )}
            </Header>
          </Grid.Column>
        </Grid.Row>
        {materialOpen ? (
          <Grid.Row>
            <Grid.Column width={16}>
              <MaterialPurchases projectID={projectID} />
            </Grid.Column>
          </Grid.Row>
        ) : null}

        <Grid.Row>
          <Grid.Column width={16}>
            <Header
              style={{ color: "#fe5000", cursor: "pointer" }}
              as="h1"
              onClick={() => setInventoryOpen(!inventoryOpen)}
            >
              Inventory{""}
              {inventoryOpen ? (
                <Icon
                  color="grey"
                  name="caret down"
                  // onClick={() => setMaterialOpen(false)}
                />
              ) : (
                <Icon
                  color="grey"
                  name="caret right"
                  // onClick={() => setMaterialOpen(true)}
                />
              )}
            </Header>
          </Grid.Column>
        </Grid.Row>
        {inventoryOpen ? (
          <Grid.Row>
            <Grid.Column width={16}>
              <Inventory projectID={projectID} />
            </Grid.Column>
          </Grid.Row>
        ) : null}
      </Grid>
    </Container>
  );
};

export default Tracking;
