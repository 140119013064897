import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

import Axios from "axios";
import { API_ROOT } from "../../../api-config";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./ConditionsTable.scss";

import columnDefs from "./columnDefs";
import CheckboxGroupFilter from "./CheckboxGroupFilter";

const ConditionsTable = ({ data, setData }) => {
  const [gridApi, setGridApi] = useState();

  const subCategories = data
    ? data
        .map((x) => x.SubCategoryCode)
        .reduce((acc, item) => {
          if (acc.indexOf(item) === -1) acc.push(item);
          return acc;
        }, [])
    : [];

  const onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleChange = (params) => {
    const { field } = params.colDef;
    const value = params.newValue;
    const conditionID = params.data.ConditionID;
    const rowNode = gridApi.getDisplayedRowAtIndex(params.rowIndex);

    Axios.post(`${API_ROOT}/api/condition/update-condition-template`, {
      params: {
        conditionID,
        field,
        value,
      },
    })
      .then((res) => {
        gridApi.setRowData(res.data);
        gridApi.flashCells({
          rowNodes: [rowNode],
          flashDelay: 3000,
          fadeDelay: 2000,
        });
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      className="ag-theme-balham ConditionsTable"
      style={{
        height: "calc(100vh - 200px)",
        width: "100%",
      }}
    >
      <AgGridReact
        defaultColDef={{
          autoHeight: true,
          cellClass: "cell-wrap-text",
        }}
        frameworkComponents={{
          checkboxGroupFilter: CheckboxGroupFilter,
        }}
        columnDefs={columnDefs(data, setData, subCategories)}
        rowData={data}
        onColumnResized={onColumnResized}
        onGridReady={onGridReady}
        enableCellChangeFlash
        onCellValueChanged={(params) => handleChange(params)}
      />
    </div>
  );
};

export default ConditionsTable;
