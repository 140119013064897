import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useSelector } from "react-redux";
import { Container, Grid, Card, Table, Icon } from "semantic-ui-react";

import { API_ROOT } from "../../../api-config";
import { useLoggedInUser } from "../../Mobile/hooks";

const EqualsIcon = () => (
  <span
    style={{
      color: "#767676",
      // lineHeight: 1,
      verticalAlign: "middle",
      fontSize: "3.2em",
      fontWeight: 900,
      display: "inline-block",
      padding: 0,
      paddingBottom: 10,
    }}
  >
    =
  </span>
);

const DivisionIcon = () => (
  <span
    style={{
      color: "#767676",
      // lineHeight: 1,
      verticalAlign: "middle",
      fontSize: "3.2em",
      fontWeight: 900,
      display: "inline-block",
      padding: 0,
      paddingBottom: 10,
    }}
  >
    ÷
  </span>
);

const IncentiveAnalysisWrapper = () => {
  const { year, quarter } = useSelector((state) => state.incentiveAnalysis);
  const userID = useLoggedInUser("UserID");
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!!year && !!quarter && !!userID) {
      Axios.get(`${API_ROOT}/api/kpi/incentive-analysis`, {
        params: {
          year,
          quarter,
          userID,
        },
      })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [year, quarter, userID]);

  useEffect(() => {}, [data]);
  return data ? (
    <Container
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 40 }}
      fluid
    >
      <div>
        <h1
          style={{ color: "rgb(254, 80, 0)", marginLeft: 40, marginBottom: 20 }}
        >
          {`Quarter-to-Date Incentive Progress for ${data.TeamMember}`}
          <small style={{ display: "block", fontSize: "16px" }}>
            You have to be employed on the day that the incentive payout gets
            distributed to your account to receive the incentive.
          </small>
        </h1>
      </div>
      <Grid stackable columns="equal">
        <Grid.Row stretched>
          <Grid.Column>
            <Card style={{ width: "100%" }}>
              <Card.Content
                style={{ background: "#eee", flexGrow: 0, height: 47 }}
              >
                <Card.Header>Team Performance</Card.Header>
              </Card.Content>
              <Card.Content>
                <Table unstackable basic="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell>{data.GroupGrossProfit}</Table.Cell>
                      <Table.Cell>N5 Group Gross Profit</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <Icon name="times" size="big" color="grey" />
                      </Table.Cell>
                      <Table.Cell>{data.GroupPoolPercent}</Table.Cell>
                      <Table.Cell>
                        N5 Group Profit Share Pool Percent
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <EqualsIcon />
                      </Table.Cell>
                      <Table.Cell>{data.PotentialProfitSharePool}</Table.Cell>
                      <Table.Cell>
                        N5 Group Potential Profit Share Pool
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <Icon name="times" size="big" color="grey" />
                      </Table.Cell>
                      <Table.Cell>{data.KPIPercent}</Table.Cell>
                      <Table.Cell>KPI Performance</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <EqualsIcon />
                      </Table.Cell>
                      <Table.Cell>{data.ProfitSharePool}</Table.Cell>
                      <Table.Cell>N5 Group Profit Share Pool</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card style={{ width: "100%" }}>
              <Card.Content
                style={{ background: "#eee", flexGrow: 0, height: 47 }}
              >
                <Card.Header>Individual Incentive</Card.Header>
              </Card.Content>
              <Card.Content>
                <Table unstackable basic="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell>{data.Stars}</Table.Cell>
                      <Table.Cell>{`${data.Position} Stars`}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <Icon name="times" size="big" color="grey" />
                      </Table.Cell>
                      <Table.Cell>{data.Hours}</Table.Cell>
                      <Table.Cell>Hours</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <EqualsIcon />
                      </Table.Cell>
                      <Table.Cell>{data.Points}</Table.Cell>
                      <Table.Cell>Points</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <DivisionIcon />
                      </Table.Cell>
                      <Table.Cell>{data.TotalPoints}</Table.Cell>
                      <Table.Cell>Total Points</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <EqualsIcon />
                      </Table.Cell>
                      <Table.Cell>{data.ProfitSharePercent}</Table.Cell>
                      <Table.Cell>
                        Your Portion of the N5 Group Profit Share Pool
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <Icon name="times" size="big" color="grey" />
                      </Table.Cell>
                      <Table.Cell>{data.ProfitSharePool}</Table.Cell>
                      <Table.Cell>Profit Share Pool</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <EqualsIcon />
                      </Table.Cell>
                      <Table.Cell>{data.ProfitShareAmount}</Table.Cell>
                      <Table.Cell>Your N5 Group Profit Share Amount</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card.Content>
            </Card>
          </Grid.Column>
          {data.LeadershipPercent !== ".00%" ? (
            <Grid.Column>
              <Card style={{ width: "100%" }}>
                <Card.Content
                  style={{ background: "#eee", flexGrow: 0, height: 47 }}
                >
                  <Card.Header>Leadership Incentive</Card.Header>
                </Card.Content>
                <Card.Content>
                  <Table unstackable basic="very">
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>{data.DivisionGrossProfit}</Table.Cell>
                        <Table.Cell>
                          {data.LeadershipDivision + " Gross Profit"}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <Icon name="times" size="big" color="grey" />
                        </Table.Cell>
                        <Table.Cell>{data.DivisionGoalPercent}</Table.Cell>
                        <Table.Cell>
                          {data.LeadershipDivision + " Goal Performance"}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <EqualsIcon />
                        </Table.Cell>
                        <Table.Cell>{data.LeadershipPool}</Table.Cell>
                        <Table.Cell>Leadership Profit Share Pool</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <Icon name="times" size="big" color="grey" />
                        </Table.Cell>
                        <Table.Cell>{data.LeadershipPercent}</Table.Cell>
                        <Table.Cell>
                          Your Leadership Profit Share Percent
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <EqualsIcon />
                        </Table.Cell>
                        <Table.Cell>{data.LeadershipAmount}</Table.Cell>
                        <Table.Cell>
                          Your Leadership Profit Share Amount
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <Icon name="plus" size="big" color="grey" />
                        </Table.Cell>
                        <Table.Cell>{data.ProfitShareAmount}</Table.Cell>
                        <Table.Cell>
                          Your N5 Group Profit Share Amount
                        </Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell>
                          <EqualsIcon />
                        </Table.Cell>
                        <Table.Cell>{data.TotalProfitShareAmount}</Table.Cell>
                        <Table.Cell>Your Total Profit Share Amount</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Card.Content>
              </Card>
            </Grid.Column>
          ) : null}
        </Grid.Row>
      </Grid>
    </Container>
  ) : (
    <div>Loading...</div>
  );
};

export default IncentiveAnalysisWrapper;
