import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Grid,
  Card,
  Loader,
  Segment,
  Accordion,
  Icon,
  Modal,
  ModalActions,
  Button,
  Header,
} from "semantic-ui-react";
import {
  LinearGauge,
  Scale,
  Label,
  SubvalueIndicator,
  Size,
} from "devextreme-react/linear-gauge";
import {
  CircularGauge,
  ValueIndicator,
  Geometry,
} from "devextreme-react/circular-gauge";
import {
  Chart,
  Series,
  Format,
  CommonSeriesSettings,
  Legend,
} from "devextreme-react/chart";
import DataGrid, { Column, MasterDetail } from "devextreme-react/data-grid";

import ResultsModalContent from "./ResultsModalContent/ResultsModalContent";
import DescriptionModal from "./DescriptionModal";
import TimelyInvoicingDetails from "./TimelyInvoicingDetails";

const KPI = ({
  KPIData,
  NPSData,
  surveyLeaderboard,
  fleet,
  uniform,
  warehouse,
  safety,
  tableData,
  timelyInvoicingSummary,
}) => {
  const [accountsReceiveableAging, setAccountsReceivableAging] = useState(null);
  const [average, setAverage] = useState(null);
  const [customerFulfillment, setCustomerFulfillment] = useState(null);
  const [jobStartUp, setJobStartUp] = useState(null);
  const [professionalImage, setProfessionalImage] = useState(null);
  const [timelyInvoicing, setTimelyInvoicing] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowData, setRowData] = useState();
  const [safetySurveys, setSafetySurveys] = useState(null);
  const [trainingAttendance, setTrainingAttendance] = useState(null);
  const [timelyTimesheet, setTimelyTimesheet] = useState(null);
  const [projectNotes, setProjectNotes] = useState(null);
  const [callbacks, setCallbacks] = useState(null);
  const [plusMinusEstimatedProfit, setPlusMinusEstimatedProfit] = useState(
    null
  );
  const [missedCosts, setMissedCosts] = useState(null);
  const [open, setOpen] = useState(false);

  const prevKPIRef = useRef();
  useEffect(() => {
    prevKPIRef.current = KPIData;
  });
  const prevKPIData = prevKPIRef.current;

  useEffect(() => {
    if (JSON.stringify(prevKPIData) !== JSON.stringify(KPIData)) {
      // if (Array.isArray(KPIData) && KPIData.length) {
      setAccountsReceivableAging(
        KPIData.find((obj) => obj.uid === "AccountsReceivableAging")
      );
      setAverage(KPIData.find((obj) => obj.uid === "Average"));
      setCustomerFulfillment(
        KPIData.find((obj) => obj.uid === "CustomerFulfillment")
      );
      setJobStartUp(KPIData.find((obj) => obj.uid === "JobStartUp"));
      setProfessionalImage(
        KPIData.find((obj) => obj.uid === "ProfessionalImage")
      );
      setTimelyInvoicing(KPIData.find((obj) => obj.uid === "TimelyInvoicing"));

      setSafetySurveys(KPIData.find((obj) => obj.uid === "SafetySurvey"));
      setTrainingAttendance(
        KPIData.find((obj) => obj.uid === "TrainingAttendance")
      );
      setTimelyTimesheet(KPIData.find((obj) => obj.uid === "TimelyTimesheet"));
      setProjectNotes(KPIData.find((obj) => obj.uid === "ProjectNotes"));
      setCallbacks(KPIData.find((obj) => obj.uid === "Callbacks"));
      setPlusMinusEstimatedProfit(
        KPIData.find((obj) => obj.uid === "Plus/MinusEstimatedProfit")
      );
      setMissedCosts(KPIData.find((obj) => obj.uid === "MissedCosts"));
    }
  }, [KPIData]);

  const isNonEmptyArray = (KPIData) => {
    return Array.isArray(KPIData) && KPIData.length;
  };

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    if (rowData) {
      setModalOpen(true);
    }
  }, [rowData]);

  const cellRenderer = (data) => {
    return (
      <Icon
        onClick={() => {
          setRowData(data.data);
        }}
        link
        color="teal"
        name="magnify"
      />
    );
  };

  return (
    <Container
      style={{
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 20,
      }}
      fluid
    >
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={16}>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  Incentive KPIs
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 350, background: "#2a2a2a" }}>
                {/* {isNonEmptyArray(KPIData) ? ( */}
                <Grid stackable columns="equal">
                  <Grid.Row>
                    {average ? (
                      <Grid.Column style={{ textAlign: "center" }}>
                        <Chart
                          title={{
                            text: "Bonus Eligible %",
                            font: {
                              size: 18,
                            },
                          }}
                          animation={{ enabled: false }}
                          id="chart"
                          dataSource={[average]}
                          valueAxis={[{ min: 0, max: 100 }]}
                        >
                          <Size height="500" />
                          <CommonSeriesSettings
                            argumentField="payoutpercent"
                            type="bar"
                            hoverMode="allArgumentPoints"
                            selectionMode="allArgumentPoints"
                            barWidth={140}
                          >
                            <Legend visible={false} />
                            <Label visible={true}>
                              <Format type="fixedPoint" />
                            </Label>
                          </CommonSeriesSettings>
                          <Series
                            showInLegend={false}
                            label={{
                              visible: true,
                              backgroundColor: "transparent",
                              font: {
                                color: "white",
                                weight: 900,
                                size: 40,
                              },
                            }}
                            color="rgb(254, 80, 0)"
                            valueField="payoutpercent"
                          />
                        </Chart>
                      </Grid.Column>
                    ) : null}

                    {timelyInvoicing &&
                    accountsReceiveableAging &&
                    jobStartUp &&
                    customerFulfillment &&
                    professionalImage
                      ? [
                          timelyInvoicing,
                          accountsReceiveableAging,
                          jobStartUp,
                          customerFulfillment,
                          professionalImage,
                        ].map((item) => (
                          <Grid.Column
                            key={item.kpi}
                            style={{ textAlign: "center" }}
                          >
                            <DescriptionModal item={item} />
                          </Grid.Column>
                        ))
                      : null}
                  </Grid.Row>

                  {/* Second row of kpi charts */}
                  <Grid.Row style={{ marginTop: 30 }}>
                    {safetySurveys &&
                    trainingAttendance &&
                    timelyTimesheet &&
                    projectNotes &&
                    callbacks &&
                    missedCosts
                      ? [
                          safetySurveys,
                          trainingAttendance,
                          timelyTimesheet,
                          projectNotes,
                          callbacks,
                          missedCosts,
                        ].map((item) => (
                          <Grid.Column
                            key={item.kpi}
                            style={{ textAlign: "center" }}
                          >
                            <DescriptionModal item={item} />
                          </Grid.Column>
                        ))
                      : null}
                    {/* Plus/Minus Estimated Profit */}

                    {plusMinusEstimatedProfit
                      ? [plusMinusEstimatedProfit].map((item) => (
                          <Grid.Column
                            key={item.kpi}
                            style={{ textAlign: "center" }}
                          >
                            <Modal
                              // basic
                              onClose={() => setOpen(false)}
                              onOpen={() => setOpen(true)}
                              open={open}
                              size="small"
                              closeIcon
                              trigger={
                                <div>
                                  <LinearGauge
                                    animation={{ enabled: false }}
                                    style={{ display: "inline-block" }}
                                    size={{ width: 120, height: 360 }}
                                    title={{
                                      text: item.kpi,
                                      font: {
                                        size: 18,
                                      },
                                    }}
                                    geometry={{ orientation: "vertical" }}
                                    valueIndicator={{
                                      type: "rangeBar",
                                      color: "rgb(254, 80, 0)",
                                      offset: 10,
                                      size: 10,
                                      text: { indent: 15, format: "percent" },
                                    }}
                                    id="c1"
                                    value={item.score}
                                    subvalues={[item.threshold, item.goal]}
                                    scale={{
                                      startValue: -0.5,
                                      endValue: 0.5,
                                      tickInterval: 0.2,
                                      label: { format: "percent" },
                                    }}
                                    tooltip={{
                                      enabled: true,
                                      format: "percent",
                                      precision: 2,
                                    }}
                                    subvalueIndicator={{
                                      type: "triangleMarker",
                                      color: "rgb(254, 80, 0)",
                                    }}
                                    rangeContainer={{
                                      ranges: [
                                        {
                                          startValue: 0,
                                          endValue: item.threshold,
                                          color: "gray",
                                        },
                                        {
                                          startValue: item.threshold,
                                          endValue: 1,
                                          color: "white",
                                        },
                                      ],
                                    }}
                                  >
                                    <Scale
                                      startValue={10}
                                      endValue={50}
                                      tickInterval={10}
                                    >
                                      <Label></Label>
                                    </Scale>
                                    <SubvalueIndicator
                                      type="rectangle"
                                      color="#9B870C"
                                    ></SubvalueIndicator>
                                  </LinearGauge>
                                  <CircularGauge
                                    animation={{ enabled: false }}
                                    size={{ height: 160 }}
                                    id="rangebar"
                                    value={
                                      item.payoutpercent
                                        ? parseInt(item.payoutpercent)
                                        : 0
                                    }
                                    title={{
                                      text: item.payoutpercent
                                        ? item.payoutpercent + "%"
                                        : "0%",
                                      font: {
                                        size: 28,
                                        weight: 900,
                                      },
                                      verticalAlignment: "bottom",
                                    }}
                                  >
                                    <ValueIndicator
                                      type="rangebar"
                                      color="rgb(254, 80, 0)"
                                    ></ValueIndicator>
                                    <Scale
                                      startValue={0}
                                      endValue={100}
                                      tickInterval={50}
                                    ></Scale>
                                    <Geometry
                                      startAngle={180}
                                      endAngle={0}
                                    ></Geometry>
                                  </CircularGauge>
                                  <span style={{ color: "white" }}>
                                    Bonus Eligible
                                  </span>
                                </div>
                              }
                            >
                              <Header>{item.kpi}</Header>
                              <Modal.Content>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.KPIDescription,
                                  }}
                                ></p>
                              </Modal.Content>
                            </Modal>
                          </Grid.Column>
                        ))
                      : null}
                  </Grid.Row>
                </Grid>
                {/* ) : (
                  <Loader active size="big" />
                )} */}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  NPS By Division
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 350 }}>
                {isNonEmptyArray(NPSData.NPSByBidType) ? (
                  <Chart
                    animation={{ enabled: false }}
                    id="chart"
                    dataSource={NPSData.NPSByDivision}
                    rotated={true}
                  >
                    <CommonSeriesSettings
                      argumentField="Division"
                      type="bar"
                      hoverMode="allArgumentPoints"
                      selectionMode="allArgumentPoints"
                    >
                      <Legend visible={false} />
                      <Label visible={true}>
                        <Format type="fixedPoint" />
                      </Label>
                    </CommonSeriesSettings>
                    <Series
                      showInLegend={false}
                      label={{
                        visible: true,
                        backgroundColor: "transparent",
                        font: {
                          color: "white",
                          weight: 800,
                          size: 36,
                        },
                      }}
                      color="gray"
                      valueField="NPS"
                      argumentField="division"
                    />
                  </Chart>
                ) : (
                  <Loader active size="big" />
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column width={6}>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Fragment>
                <Card.Content>
                  <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                    NPS By Work Type
                  </Card.Header>
                </Card.Content>
                <Card.Content style={{ minHeight: 350 }}>
                  {isNonEmptyArray(NPSData.NPSByBidType) ? (
                    <Chart
                      animation={{ enabled: false }}
                      id="chart"
                      dataSource={NPSData.NPSByBidType}
                      rotated={true}
                    >
                      <CommonSeriesSettings
                        argumentField="bidtype"
                        type="bar"
                        hoverMode="allArgumentPoints"
                        selectionMode="allArgumentPoints"
                      >
                        <Legend visible={false} />
                        <Label visible={true}>
                          <Format type="fixedPoint" />
                        </Label>
                      </CommonSeriesSettings>
                      <Series
                        showInLegend={false}
                        label={{
                          visible: true,
                          backgroundColor: "transparent",
                          font: {
                            color: "gray",
                            weight: 700,
                            size: 24,
                          },
                        }}
                        color="white"
                        valueField="NPS"
                      />
                    </Chart>
                  ) : (
                    <Loader active size="big" />
                  )}
                </Card.Content>
              </Fragment>
            </Card>
          </Grid.Column>
          <Grid.Column width={4}>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Fragment>
                <Card.Content>
                  <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                    Total NPS
                  </Card.Header>
                </Card.Content>
                <Card.Content style={{ minHeight: 350 }}>
                  {isNonEmptyArray(NPSData.NPSByCompany) ? (
                    <Chart
                      animation={{ enabled: false }}
                      id="chart"
                      dataSource={NPSData.NPSByCompany}
                      valueAxis={[{ min: 0, max: 100 }]}
                    >
                      <CommonSeriesSettings
                        argumentField="type"
                        type="bar"
                        hoverMode="allArgumentPoints"
                        selectionMode="allArgumentPoints"
                        barWidth={140}
                      >
                        <Legend visible={false} />
                        <Label visible={true}>
                          <Format type="fixedPoint" />
                        </Label>
                      </CommonSeriesSettings>
                      <Series
                        showInLegend={false}
                        label={{
                          visible: true,
                          backgroundColor: "transparent",
                          font: {
                            color: "white",
                            weight: 900,
                            size: 40,
                          },
                        }}
                        color="rgb(254, 80, 0)"
                        valueField="NPS"
                      />
                    </Chart>
                  ) : (
                    <Loader active size="big" />
                  )}
                </Card.Content>
              </Fragment>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment className="segment-devextreme-datagrid" inverted>
              <Accordion fluid inverted>
                <Accordion.Title
                  active={activeIndex === 99}
                  index={99}
                  onClick={handleClick}
                >
                  <h3>
                    <Icon name="dropdown" />
                    Timely Invoicing
                  </h3>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 99}>
                  <DataGrid dataSource={timelyInvoicingSummary} width={500}>
                    <Column dataField="displayName" caption="Project Manager" />
                    <Column
                      dataField="invoicedOnTime"
                      caption="Invoiced On Time"
                    />
                    <Column
                      dataField="totalInvoices"
                      caption="Total Invoices"
                    />
                    <Column
                      dataField="score"
                      format="percent"
                      caption="Score"
                    />
                    <MasterDetail enabled component={TimelyInvoicingDetails} />
                  </DataGrid>
                  <br />
                </Accordion.Content>
              </Accordion>
              <Accordion fluid inverted>
                <Accordion.Title
                  active={activeIndex === 10}
                  index={10}
                  onClick={handleClick}
                >
                  <h3>
                    <Icon name="dropdown" />
                    Job Startup
                  </h3>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 10}>
                  <DataGrid
                    dataSource={
                      tableData.hasOwnProperty("jobStartup")
                        ? tableData.jobStartup
                        : null
                    }
                    width={500}
                  >
                    <Column dataField="DisplayName" caption="Superintendent" />
                    {/* <Column dataField="surveyor" caption="Audited By" /> */}
                    {/* <Column dataField="Type" caption="Type" /> */}
                    <Column dataField="ProjectCount" caption="Project Count" />
                    <Column
                      dataField="SignatureCount"
                      caption="Signature Count"
                    />
                    <Column
                      dataField="Score"
                      format="percent"
                      caption="Score"
                    />
                  </DataGrid>
                  <br />
                </Accordion.Content>
              </Accordion>
              <Accordion fluid inverted>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={handleClick}
                >
                  <h3>
                    <Icon name="dropdown" />
                    Customer Fulfillment Survey Results
                  </h3>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <DataGrid
                    dataSource={surveyLeaderboard || null}
                    columns={[
                      { dataField: "place", width: 50 },
                      {
                        dataField: "n5representative",
                        width: 200,
                        caption: "Team Member",
                      },
                      { dataField: "score", format: "percent" },
                      { dataField: "Invites", format: "fixedPoint" },
                      { dataField: "responses", format: "fixedPoint" },
                      { dataField: "TotalScore", format: "fixedPoint" },
                    ]}
                  />
                  <br />
                </Accordion.Content>
              </Accordion>
              <Accordion fluid inverted>
                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={handleClick}
                >
                  <h3>
                    <Icon name="dropdown" />
                    Fleet Survey Results
                  </h3>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <DataGrid dataSource={fleet}>
                    <Column width={40} cellRender={cellRenderer} />
                    <Column dataField="name" caption="Team Member" />
                    <Column dataField="surveyor" caption="Audited By" />
                    <Column dataField="Type" caption="Type" />
                    <Column
                      dataField="SurveyDate"
                      dataType="date"
                      format="MM/dd/yyyy"
                      caption="Audit Date"
                    />
                    <Column
                      dataField="score"
                      format="percent"
                      caption="Score"
                    />
                  </DataGrid>
                  <br />
                </Accordion.Content>
              </Accordion>

              <Accordion fluid inverted>
                <Accordion.Title
                  active={activeIndex === 3}
                  index={3}
                  onClick={handleClick}
                >
                  <h3>
                    <Icon name="dropdown" />
                    Warehouse Survey Results
                  </h3>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 3}>
                  <DataGrid dataSource={warehouse}>
                    <Column width={40} cellRender={cellRenderer} />
                    <Column dataField="surveyor" caption="Audited By" />
                    <Column dataField="Type" caption="Type" />
                    <Column
                      dataField="SurveyDate"
                      dataType="date"
                      format="MM/dd/yyyy"
                      caption="Audit Date"
                    />
                    <Column
                      dataField="score"
                      format="percent"
                      caption="Score"
                    />
                  </DataGrid>
                  <br />
                </Accordion.Content>
              </Accordion>
              <Accordion fluid inverted>
                <Accordion.Title
                  active={activeIndex === 4}
                  index={4}
                  onClick={handleClick}
                >
                  <h3>
                    <Icon name="dropdown" />
                    Uniform Survey Results
                  </h3>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 4}>
                  <DataGrid dataSource={uniform}>
                    <Column width={40} cellRender={cellRenderer} />
                    <Column dataField="name" caption="Team Member" />
                    <Column dataField="surveyor" caption="Audited By" />
                    <Column dataField="Type" caption="Type" />
                    <Column
                      dataField="SurveyDate"
                      dataType="date"
                      format="MM/dd/yyyy"
                      caption="Audit Date"
                    />
                    <Column
                      dataField="score"
                      format="percent"
                      caption="Score"
                    />
                  </DataGrid>
                  <br />
                </Accordion.Content>
              </Accordion>

              <Accordion fluid inverted>
                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={handleClick}
                >
                  <h3>
                    <Icon name="dropdown" />
                    Safety Survey Results
                  </h3>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <DataGrid dataSource={safety}>
                    <Column width={40} cellRender={cellRenderer} />
                    <Column dataField="surveyor" caption="Audited By" />
                    <Column dataField="Type" caption="Type" />
                    <Column
                      dataField="SurveyDate"
                      dataType="date"
                      format="MM/dd/yyyy"
                      caption="Audit Date"
                    />
                    <Column
                      dataField="score"
                      format="percent"
                      caption="Score"
                    />
                  </DataGrid>
                  <br />
                </Accordion.Content>
              </Accordion>

              {/* New Tables */}
              <Accordion fluid inverted>
                <Accordion.Title
                  active={activeIndex === 5}
                  index={5}
                  onClick={handleClick}
                >
                  <h3>
                    <Icon name="dropdown" />
                    Timely Timesheets Results
                  </h3>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 5}>
                  <DataGrid
                    dataSource={
                      tableData.hasOwnProperty("timelyTimesheets")
                        ? tableData.timelyTimesheets
                        : null
                    }
                    width={500}
                  >
                    <Column dataField="DisplayName" caption="Team Member" />
                    {/* <Column dataField="surveyor" caption="Audited By" /> */}
                    {/* <Column dataField="Type" caption="Type" /> */}

                    <Column
                      dataField="AssignmentCount"
                      caption="Job Count"
                      width={100}
                    />
                    <Column
                      dataField="MissedCount"
                      caption="Missing Time"
                      width={120}
                    />
                    <Column
                      dataField="Score"
                      format="percent"
                      caption="Score"
                      width={80}
                    />
                  </DataGrid>
                  <br />
                </Accordion.Content>
              </Accordion>
              <Accordion fluid inverted>
                <Accordion.Title
                  active={activeIndex === 6}
                  index={6}
                  onClick={handleClick}
                >
                  <h3>
                    <Icon name="dropdown" />
                    Project Notes Results
                  </h3>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 6}>
                  <DataGrid
                    dataSource={
                      tableData.hasOwnProperty("projectNotes")
                        ? tableData.projectNotes
                        : null
                    }
                    width={500}
                  >
                    <Column dataField="DisplayName" caption="Team Member" />
                    {/* <Column dataField="surveyor" caption="Audited By" /> */}
                    {/* <Column dataField="Type" caption="Type" /> */}

                    <Column
                      dataField="TotalJobs"
                      caption="Total Jobs"
                      width={100}
                    />
                    <Column
                      dataField="MissingNotesCount"
                      caption="Missing Notes"
                      width={120}
                    />
                    <Column
                      dataField="Score"
                      format="percent"
                      caption="Score"
                      width={80}
                    />
                  </DataGrid>
                  <br />
                </Accordion.Content>
              </Accordion>
              <Accordion fluid inverted>
                <Accordion.Title
                  active={activeIndex === 7}
                  index={7}
                  onClick={handleClick}
                >
                  <h3>
                    <Icon name="dropdown" />
                    Call Back Results
                  </h3>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 7}>
                  <DataGrid
                    dataSource={
                      tableData.hasOwnProperty("callbacks")
                        ? tableData.callbacks
                        : null
                    }
                    width={500}
                  >
                    <Column dataField="DisplayName" caption="Project Manager" />
                    {/* <Column dataField="surveyor" caption="Audited By" /> */}
                    {/* <Column dataField="Type" caption="Type" /> */}

                    <Column
                      dataField="ClosedProjectCount"
                      caption="# Closed Projects"
                    />
                    <Column dataField="CallbackCount" caption="# Call Backs" />
                    <Column
                      dataField="Score"
                      format="percent"
                      caption="Score"
                    />
                  </DataGrid>
                  <br />
                </Accordion.Content>
              </Accordion>

              <Accordion fluid inverted>
                <Accordion.Title
                  active={activeIndex === 9}
                  index={9}
                  onClick={handleClick}
                >
                  <h3>
                    <Icon name="dropdown" />
                    Missed Cost Results
                  </h3>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 9}>
                  <DataGrid
                    dataSource={
                      tableData.hasOwnProperty("missedCosts")
                        ? tableData.missedCosts
                        : null
                    }
                    width={500}
                  >
                    <Column dataField="DisplayName" caption="Project Manager" />
                    {/* <Column dataField="surveyor" caption="Audited By" /> */}
                    {/* <Column dataField="Type" caption="Type" /> */}

                    <Column
                      dataField="ClosedProjectCount"
                      caption="# Closed Projects"
                    />
                    <Column
                      dataField="MissedCostCount"
                      caption="# Missed Cost"
                    />
                    <Column
                      dataField="Score"
                      format="percent"
                      caption="Score"
                    />
                  </DataGrid>
                  <br />
                </Accordion.Content>
              </Accordion>

              <Accordion fluid inverted>
                <Accordion.Title
                  active={activeIndex === 8}
                  index={8}
                  onClick={handleClick}
                >
                  <h3>
                    <Icon name="dropdown" />
                    Plus/Minus Estimated Profit Results
                  </h3>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 8}>
                  <DataGrid
                    dataSource={
                      tableData.hasOwnProperty("plusMinusEstimatedProfit")
                        ? tableData.plusMinusEstimatedProfit
                        : null
                    }
                    width={500}
                  >
                    <Column dataField="DisplayName" caption="Project Manager" />
                    {/* <Column dataField="surveyor" caption="Audited By" /> */}
                    {/* <Column dataField="Type" caption="Type" /> */}

                    <Column
                      dataField="Score"
                      format="percent"
                      caption="Score"
                    />
                  </DataGrid>
                  <br />
                </Accordion.Content>
              </Accordion>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Modal
        open={modalOpen}
        onOpen={() => setModalOpen(true)}
        onClose={() => {
          setModalOpen(false);
          setRowData(null);
        }}
      >
        <ResultsModalContent rowData={rowData} />
        <ModalActions>
          <Button
            primary
            onClick={() => {
              setModalOpen(false);
              setRowData(null);
            }}
          >
            Close
          </Button>
        </ModalActions>
      </Modal>
    </Container>
  );
};

export default KPI;
