import React, { useState } from "react";
import { Container } from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
import { columnDefs } from "./columnDefs";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";

const CompanyGoals = ({ rowData, setData }) => {
  const [gridApi, setGridApi] = useState();

  const handleChange = (params) => {
    const { year, division, goaltype } = params.data;
    const month = parseInt(params.colDef.field);
    const value = params.newValue;

    Axios.post(`${API_ROOT}/api/dashboard/financials`, {
      params: {
        year,
        month,
        division,
        goaltype,
        value,
      },
    })
      .then((res) => {
        const financials = res.data.financials;
        // setData(financials);
        gridApi.setRowData(financials);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container style={{ paddingLeft: 10, paddingRight: 10 }} fluid>
      <h1 style={{ color: "rgb(254, 80, 0)", marginLeft: 40 }}>
        Company Goals
      </h1>
      <div
        className="ag-theme-balham"
        style={{
          height: "calc(100vh - 170px)",
          // height: "100%",
          width: "100%",
          minHeight: 300,
          // maxWidth: 1600,
        }}
      >
        <AgGridReact
          enableCellChangeFlash={true}
          defaultColDef={{ sortable: false }}
          suppressRowDrag={true}
          suppressMovableColumns={true}
          lockPinned={true}
          columnDefs={columnDefs}
          rowData={rowData}
          rowSelection="multiple"
          onCellValueChanged={(params) => {
            // const { newValue} = params;
            // console.log(newValue);

            handleChange(params);
          }}
          getRowStyle={(params) => {
            return params.data.division === "Total" ? { color: "gray" } : {};
          }}
          onGridReady={(params) => {
            setGridApi(params.api);
            params.api.sizeColumnsToFit();
            window.onresize = () => {
              params.api.sizeColumnsToFit();
            };
            // setColumnApi(params.columnApi);
          }}
        />
      </div>
    </Container>
  );
};

export default CompanyGoals;
