import React from "react";

const ConditionOverwriteApproval = () => {
  return (
    <div>
      <p>Condition overwrite approval</p>
    </div>
  );
};

export default ConditionOverwriteApproval;
