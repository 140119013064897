import React from "react";
import numeral from "numeral";
import ApprovalIcons from "./ApprovalIcons/ApprovalIcons";

export default (data = [], setData = () => {}, subCategories = []) => [
  {
    headerName: "",
    field: "ConditionID",
    pinned: "left",
    width: 74,
    cellRendererFramework: (params) => {
      // return null;
      return (
        // <Modal
        //   basic="very"
        //   trigger={<Icon color="blue" link name="checkmark" />}
        // >
        //   <Modal.Actions>
        //     <Button onClick={() => handleConfirmClick(params.value)}>
        //       Confirm
        //     </Button>
        //   </Modal.Actions>
        // </Modal>
        <ApprovalIcons obj={params.data} data={data} setData={setData} />
      );
    },
  },
  // {
  //   width: 100,
  //   headerName: "Status",
  //   field: "Status",
  //   sortable: true,
  //   pinned: "left",
  //   filter: "checkboxGroupFilter",
  //   filterParams: { filterOptions: ["Active", "Inactive"] },
  // },
  // {
  //   width: 100,
  //   headerName: "ApprovalType",
  //   field: "ApprovalType",
  //   sortable: true,
  //   pinned: "left",
  //   filter: "checkboxGroupFilter",
  //   filterParams: { filterOptions: ["Overwrite", "New"] },
  // },
  {
    headerName: "Category",
    field: "SubCategoryCode",
    sortable: true,
    pinned: "left",
    filter: "checkboxGroupFilter",
    filterParams: { filterOptions: subCategories },
  },
  {
    headerName: "Condition",
    field: "ConditionName",
    pinned: "left",
    sortable: true,
    editable: true,
    filter: "agTextColumnFilter",
    filterParams: { filterOptions: ["contains", "notContains"] },
  },
  {
    headerName: "Condition Description",
    field: "InternalComments",
    cellEditor: "agLargeTextCellEditor",
    editable: true,
  },
  {
    headerName: "Tech/Subcontractor Description",
    cellEditor: "agLargeTextCellEditor",
    field: "SubcontractorScope",
    editable: true,
  },
  {
    headerName: "Quantity",
    field: "TotalQuantities",
    width: 100,
    editable: true,
  },
  {
    headerName: "UOM",
    field: "MeasurementType",
    editable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [
        "EA",
        "LS",
        "SQFT",
        "SQYD",
        "LINFT",
        "LINYD",
        "FT",
        "CUBFT",
        "CUBYD",
        "GAL",
        "MINUTE",
        "MANHR",
        "HR",
        "WK",
        "MO",
        "YR",
        "LB",
        "MILE",
        "OZ",
        "PA",
        "QT",
        "SACK",
        "IN",
      ],
    },
  },
  {
    headerName: "Labor Hrs",
    field: "LaborHours",
    width: 80,
    editable: true,
  },
  {
    headerName: "Labor Rate",
    field: "LaborRate",
    width: 100,
    editable: true,
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0.00");
    },
  },
  {
    headerName: "Labor Markup",
    field: "LaborMarkup",
    width: 110,
    valueFormatter: (params) => {
      return numeral(params.value).format("0.00%");
    },
  },
  {
    headerName: "Labor Total",
    field: "LaborTotal",
    width: 120,
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0.00");
    },
  },
  {
    headerName: "Material Cost",
    field: "MaterialCost",
    width: 100,
    editable: true,
    valueFormatter: (params) => {
      return numeral(params.value).format("0,00.00");
    },
  },
  {
    headerName: "Material Markup",
    field: "MaterialMarkup",
    width: 120,
    editable: true,
    valueFormatter: (params) => {
      return numeral(params.value).format("0.00%");
    },
  },
  {
    headerName: "Material Total",
    field: "MaterialTotal",
    width: 120,
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0.00");
    },
  },
  {
    headerName: "Equipment Cost",
    field: "EquipmentCost",
    editable: true,
    width: 130,
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0.00");
    },
  },
  {
    headerName: "Equipment Markup",
    field: "EquipmentMarkup",
    width: 130,
    editable: true,
    valueFormatter: (params) => {
      return numeral(params.value).format("0.00%");
    },
  },
  {
    headerName: "Equipment Total",
    field: "EquipmentTotal",
    width: 120,
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0.00");
    },
  },
  {
    headerName: "Other Cost",
    field: "OtherCostsCost",
    width: 120,
    editable: true,
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0.00");
    },
  },
  {
    headerName: "Other Markup",
    field: "OtherCostsMarkup",
    width: 120,
    editable: true,
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0.00");
    },
  },
  {
    headerName: "Other Total",
    field: "OtherCostsTotal",
    width: 120,
    valueFormatter: (params) => {
      return numeral(params.value).format("0,0.00");
    },
  },

  {
    headerName: "Created By",
    field: "CreatedByLastName",
    width: 120,
    sortable: true,
  },
];
