import React, { useContext } from "react";
import { Table, Input } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setInclusionMenuSelection } from "../../../actions/project/inclusion/inclusionActions";
import WorksheetContext from "./WorksheetContext";

const money = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const profitPercent = (profit, cost) => {
  if (!profit || profit === 0) return "0.00%";
  return percent((profit - cost) / profit);
};

const percent = (value) => {
  return value
    ? value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        style: "percent",
      })
    : "0.00%";
};

export const TableHeader = () => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell></Table.HeaderCell>
      <Table.HeaderCell
        textAlign="center"
        // className="border-left"
        colSpan={3}
      >
        Contract Amounts
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center" className="border-left" colSpan={3}>
        Committed Costs
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center" className="border-left" colSpan={3}>
        Actual Costs
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center" className="border-left" colSpan={4}>
        Worksheet
      </Table.HeaderCell>
    </Table.Row>
    <Table.Row style={{ fontSize: 9 }}>
      <Table.HeaderCell></Table.HeaderCell>
      <Table.HeaderCell
        // className="border-left"
        textAlign="center"
      >
        Original
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center">Approved Changes</Table.HeaderCell>
      <Table.HeaderCell textAlign="center">Revised</Table.HeaderCell>
      <Table.HeaderCell className="border-left" textAlign="center">
        Committed
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center">Committed GP</Table.HeaderCell>
      <Table.HeaderCell textAlign="center">Committed GM</Table.HeaderCell>
      <Table.HeaderCell className="border-left" textAlign="center">
        Actual Cost
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center">Balance</Table.HeaderCell>
      {/* <Table.HeaderCell textAlign="center">Actual Profit</Table.HeaderCell> */}
      <Table.HeaderCell textAlign="center">Complete %</Table.HeaderCell>
      <Table.HeaderCell className="border-left" textAlign="center">
        Additional Cost (+/-)
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center">Estimated Cost</Table.HeaderCell>
      <Table.HeaderCell textAlign="center">Estimated GP</Table.HeaderCell>
      <Table.HeaderCell textAlign="center">Estimated GM</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

export const TableBody = ({ data }) => {
  return (
    <Table.Body>
      {Object.keys(data)
        .filter((key) => key !== "totals")
        .map((key) => {
          return (
            <CategoryCode
              key={key}
              totals={data[key].totals}
              categoryCode={key}
            >
              {data[key].subs &&
                data[key].subs.map((sub) => {
                  return (
                    <SubCategoryRow totals={sub} key={sub.sub_category_code} />
                  );
                })}
            </CategoryCode>
          );
        })}
    </Table.Body>
  );
};

export const CategoryCode = ({ categoryCode, totals, children }) => {
  const { sum: sumWorksheetCategory } = useContext(WorksheetContext);
  return (
    <>
      <Table.Row>
        <Table.Cell
          style={{
            color: "rgb(254, 80, 0)",
            fontWeight: "bold",
            fontSize: 12,
          }}
          colSpan={10}
        >
          {categoryCode.toUpperCase()}
        </Table.Cell>
        <Table.Cell
          className="border-left"
          style={{ background: "#f9fafb" }}
          colSpan={4}
        ></Table.Cell>
      </Table.Row>

      {children}

      <Table.Row>
        <Table.Cell style={{ paddingLeft: "20px" }}>&nbsp;</Table.Cell>
        <Cell type="total">{totals.base_total}</Cell>
        <Cell type="total">{totals.change_orders_total}</Cell>
        <Cell type="total">{totals.adjusted_total}</Cell>
        <Cell type="total" className="border-left">
          {totals.committed_cost}
        </Cell>
        <Cell type="total">{totals.committed_profit}</Cell>
        <Cell type="total">
          {profitPercent(totals.adjusted_total, totals.committed_cost)}
        </Cell>
        <Cell type="total" className="border-left">
          {totals.actual_cost_total}
        </Cell>
        <Cell type="total">{totals.balance_total}</Cell>
        {/* <Cell type="total">{totals.actual_profit_total}</Cell> */}
        <Cell type="total">
          {percent(totals.actual_cost_total / totals.committed_cost)}
        </Cell>

        <Table.Cell
          style={{ color: "blue", fontWeight: "bold", background: "#f9fafb" }}
          textAlign="center"
          className="border-left"
        >
          {money.format(sumWorksheetCategory(categoryCode, "estimated_cost"))}
        </Table.Cell>
        <Table.Cell
          style={{ color: "blue", fontWeight: "bold", background: "#f9fafb" }}
          textAlign="center"
        >
          {money.format(sumWorksheetCategory(categoryCode, "estimatedCost"))}
        </Table.Cell>
        <Table.Cell
          style={{ color: "blue", fontWeight: "bold", background: "#f9fafb" }}
          textAlign="center"
        >
          {money.format(sumWorksheetCategory(categoryCode, "estimatedProfit"))}
        </Table.Cell>
        <Table.Cell
          style={{ color: "blue", fontWeight: "bold", background: "#f9fafb" }}
          textAlign="center"
        >
          {percent(
            sumWorksheetCategory(categoryCode, (item) =>
              item.estimatedProfitPercent(false)
            )
          )}
        </Table.Cell>
      </Table.Row>
    </>
  );
};

export const SubCategoryRow = ({ totals }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { get: getWorksheet, update: updateWorksheet } = useContext(
    WorksheetContext
  );
  const worksheet = getWorksheet(totals.sub_category_code);

  const handleLink = () => {
    dispatch(
      setInclusionMenuSelection({
        returnUrl: history.location,
        selectedCategoryID: parseInt(totals.category_id),
        selectedSubCategoryCode: totals.sub_category_code,
      })
    );
    history.push(
      `/browser/projects/${totals.project_id}/inclusions/${
        totals.category_id
      }/${encodeURI(totals.sub_category_code)}`
    );
  };

  return (
    <Table.Row>
      <Table.Cell style={{ paddingLeft: 20 }}>
        <Link onClick={() => handleLink()}>{totals.sub_category_code}</Link>
      </Table.Cell>
      <Cell>{totals.base_total}</Cell>
      <Cell>{totals.change_orders_total}</Cell>
      <Cell style={{ color: "rgb(254, 80, 0)" }}>{totals.adjusted_total}</Cell>
      <Cell className="border-left">{totals.committed_cost}</Cell>
      <Cell>{totals.committed_profit}</Cell>
      <Cell>{profitPercent(totals.adjusted_total, totals.committed_cost)}</Cell>
      <Cell className="border-left">{totals.actual_cost_total}</Cell>
      <Cell>{totals.balance_total}</Cell>
      {/* <Cell>{totals.actual_profit_total}</Cell> */}
      <Cell>{percent(totals.actual_cost_total / totals.committed_cost)}</Cell>

      <Table.Cell
        style={{ background: "#f9fafb" }}
        textAlign="center"
        className="border-left"
      >
        <Input
          style={{ width: 90 }}
          size="mini"
          type="number"
          onChange={(e) =>
            updateWorksheet(totals.sub_category_code, e.target.value)
          }
        />
      </Table.Cell>
      <Table.Cell style={{ background: "#f9fafb" }} textAlign="center">
        {worksheet && money.format(worksheet.estimatedCost())}
      </Table.Cell>
      <Table.Cell style={{ background: "#f9fafb" }} textAlign="center">
        {worksheet && money.format(worksheet.estimatedProfit())}
      </Table.Cell>
      <Table.Cell style={{ background: "#f9fafb" }} textAlign="center">
        {worksheet && worksheet.estimatedProfitPercent()}
      </Table.Cell>
    </Table.Row>
  );
};

export const GrandTotalRow = ({ totals }) => {
  const { worksheet } = useContext(WorksheetContext);

  if (!totals) return null;

  return (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell
          style={{ paddingLeft: "20px", background: "#f9fafb" }}
        >
          &nbsp;
        </Table.HeaderCell>
        <Cell type="grandTotal">{totals.base_total}</Cell>
        <Cell type="grandTotal">{totals.change_orders_total}</Cell>
        {/* revised column */}
        <Cell type="grandTotal">{totals.adjusted_total}</Cell>
        <Cell type="grandTotal" className="border-left">
          {totals.committed_cost}
        </Cell>
        <Cell type="grandTotal">{totals.committed_profit}</Cell>
        <Cell type="grandTotal">
          {percent(totals.committed_profit / totals.adjusted_total)}
        </Cell>
        <Cell type="grandTotal" className="border-left">
          {totals.actual_cost_total}
        </Cell>
        <Cell type="grandTotal">{totals.balance_total}</Cell>
        {/* <Cell type="grandTotal">{totals.actual_profit_total}</Cell> */}
        <Cell type="grandTotal">
          {percent(totals.actual_cost_total / totals.committed_cost)}
        </Cell>

        <Table.HeaderCell
          style={{ color: "purple", fontWeight: "bold", background: "#f9fafb" }}
          textAlign="center"
          className="border-left"
        >
          {worksheet &&
            money.format(worksheet.reduce((c, x) => c + x.estimated_cost, 0))}
        </Table.HeaderCell>
        <Table.HeaderCell
          style={{ color: "purple", fontWeight: "bold", background: "#f9fafb" }}
          textAlign="center"
        >
          {worksheet &&
            money.format(worksheet.reduce((c, x) => c + x.estimatedCost(), 0))}
        </Table.HeaderCell>
        <Table.HeaderCell
          style={{ color: "purple", fontWeight: "bold", background: "#f9fafb" }}
          textAlign="center"
        >
          {worksheet &&
            money.format(
              worksheet.reduce((c, x) => c + x.estimatedProfit(), 0)
            )}
        </Table.HeaderCell>
        <Table.HeaderCell
          style={{ color: "purple", fontWeight: "bold", background: "#f9fafb" }}
          textAlign="center"
        >
          {worksheet &&
            percent(
              worksheet.reduce((c, x) => c + x.estimatedProfit(), 0) /
                totals.adjusted_total
            )}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );
};

export const Cell = ({ children, type, ...props }) => {
  const style = {
    grandTotal: { background: "#f9fafb", color: "purple", fontWeight: "bold" },
    total: { color: "blue", fontWeight: "bold" },
  };
  const data = typeof children == "number" ? money.format(children) : children;

  if (!type || type === "total")
    return (
      <Table.Cell textAlign="center" style={style[type]} {...props}>
        {data}
      </Table.Cell>
    );

  return (
    <Table.HeaderCell textAlign="center" style={style[type]} {...props}>
      {data}
    </Table.HeaderCell>
  );
};
