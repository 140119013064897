import React from "react";
import moment from "moment";
import converter from "number-to-words";
import "./ContractRequirements.scss";

import exampleContractOLD from "../N5Group_ExampleContract.png";
import exampleContract20230920 from "../N5Group_ExampleContract_20230920.jpg";

const ContractRequirements = ({ summary }) => {
  if (summary.Recipient === "client") {
    return (
      <div className="ContractRequirements" style={{ position: "relative" }}>
        <p>The Client and Contractor agree as follows.</p>
        <p>
          <strong>1. THE CONTRACT DOCUMENTS </strong>
          The Contract Documents consist of this Agreement, Drawings,
          Specifications, Addenda issued prior to execution of this Agreement,
          other documents listed in this Agreement and Modifications issued
          after execution of this Agreement, all of which form the Contract, and
          are as fully a part of the Contract as if attached to this Agreement
          or repeated herein. The Contract represents the entire and integrated
          agreement between the parties hereto and supersedes prior
          negotiations, representations or agreements, either written or oral.
          An enumeration of the Contract Documents, other than a Modification,
          appears in Section 9.
        </p>
        <p>
          <strong>2. THE WORK OF THIS CONTRACT </strong>
          The Contractor shall fully execute the Work described in the Contract
          Documents, except as specifically indicated in the Contract Documents
          to be the responsibility of others.
        </p>
        <p>
          <strong>3. DATE OF COMMENCEMENT AND SUBSTANTIAL COMPLETION</strong>
        </p>
        <p>
          <strong>3.1</strong> The date of commencement of the Work shall be the
          date of this Agreement unless a different date is stated below or
          provision is made for the date to be fixed in a notice to proceed
          issued by the Client.
        </p>
        <p>
          <strong>3.2</strong> The Contract Time shall be measured from the date
          of commencement.
        </p>
        <p>
          <strong>3.3</strong> The Contractor shall achieve Substantial
          Completion of the entire Work not later than {`${summary.WorkDays}`}{" "}
          days from the date of commencement.
        </p>
        <p>
          <strong>4. CONTRACT AMOUNT</strong>
        </p>
        <p>
          <strong>4.1</strong> The Client shall pay the Contractor the Contract
          Sum in current funds for the Contractor’s performance of the Contract.
          The Contract Sum shall be{" "}
          <strong>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(summary.GrandTotal)}
          </strong>{" "}
          ({`${converter.toWords(summary.GrandTotal)}`}), subject to additions
          and deductions as provided in the Contract Documents.
        </p>
        <p>
          <strong>4.2</strong> Any Alternates, Unit Pricing, or Allowances that
          are included in this Contract Sum are listed in the attached proposal
          listed as Exhibit A.
        </p>
        <p>
          <strong>5. PROGRESS PAYMENTS</strong>
        </p>
        <p>
          <strong>5.1</strong> Provided an invoice is received by the Client not
          later than the first (1) day of a month, the Client shall make payment
          to the Contractor not later than the tenth (10) day of the same month.
          If an Invoice is received by the Client after the invoice date fixed
          above, payment shall be made by the Client not later than ten (10)
          days after the receipt of the current invoice.
        </p>
        <strong>5.2</strong> Each Invoice shall be based on the work completed
        in the current month. This work shall be described on the invoice.
        <p></p>
        <strong>5.3</strong> Invoices shall show the percentage of completion of
        each portion of the Work as of the end of the period covered by the
        Invoice.
        <p></p>
        <strong>5.4</strong> Except with the Client’s prior approval, the
        Contractor shall not make advance payments to suppliers for materials or
        equipment which have not been delivered and stored at the site.
        <p></p>
        <p>
          <strong>5.5</strong> The Client’s final payment to the Contractor
          shall be made no later than 10 days after the issuance of the final
          invoice and upon the Client’s acceptance of the work as described in
          this Contract.
        </p>
        <p>
          <strong>6. DISPUTE RESOLUTION </strong>
          For any Claim subject to, but not resolved by mediation, the method of
          binding dispute resolution shall be thru Arbitration.
        </p>
        <p>
          <strong>7. TERMINATION </strong>
          The Contract may be terminated by the Client or the Contractor for
          probable cause or convenience. If the Client terminates the contract
          for probable cause or convenience, the contractor shall be paid any
          and all cost for work completed prior to the termination plus the cost
          for demobilization.
        </p>
        <p>
          <strong>8. MISCELLANEOUS PROVISIONS</strong>
        </p>
        <p>
          <strong>8.1</strong> Payments due and unpaid under the Contract shall
          bear interest from the date payment is due at the rate stated below,
          or in the absence thereof, at the legal rate prevailing from time to
          time at the place where the Project is located.
          <br />
          Two percent (2%) above prime rate
        </p>
        <p>
          <strong>8.2</strong> Standard Exclusions:
        </p>
        <p>
          <strong>8.2.1</strong> Architectural Services
          <br />
          <strong>8.2.2</strong> Structural Engineering Services
          <br />
          <strong>8.2.3</strong> Civil Engineering Services
          <br />
          <strong>8.2.4</strong> Mechanical Engineering Services
          <br />
          <strong>8.2.5</strong> Plumbing Engineering Services
          <br />
          <strong>8.2.6</strong> Electrical Engineering Services
          <br />
          <strong>8.2.7</strong> Geotechnical Engineering Services
          <br />
          <strong>8.2.8</strong> Landscape Architectural Services
          <br />
          <strong>8.2.9</strong> Hazardous Material abatement
          <br />
          <strong>8.2.10</strong> Performance & Payment Bonds
          <br />
          <strong>8.2.11</strong> Builders’ Risk Insurance
          <br />
          <strong>8.2.12</strong> Mud Bonds
          <br />
          <strong>8.2.13</strong> Special Inspections
          <br />
          <strong>8.2.14</strong> Liquidated Damages
          <br />
          <strong>8.2.15</strong> Material increases due to material shortages,
          allocation, natural disasters, terrorist attacks, shifts in the
          economy, or acts of God.
          <br />
          <strong>8.2.16</strong> Any and all code upgrades
          <br />
          <strong>8.2.17</strong> Cost for any permits required
        </p>
        <p>
          <strong>8.3</strong> Project Specific Exclusions
        </p>
        <p>
          <strong>8.3.1</strong> {`${summary.Exclusions}`}
        </p>
        <p>
          <strong>8.3.2</strong> Anything NOT specifically spelled out on the
          contract documents or the attached Nexus 5 Group Proposal.
        </p>
        <p>EPIDEMIC RIDER:</p>
        <p>
          Notwithstanding any provision(s) of this contract, if as a direct or
          indirect result of any virus, disease, contagion, including but not
          limited to COVID-19 (individually or collectively, “Epidemic”),
          Contractor’s work is delayed, disrupted, suspended, or otherwise
          impacted, including, but limited to, by (1) disruptions to material
          and/or equipment supply; (2) illness of Contractor’s workforce and/or
          unavailability of labor; (3) government quarantines, shelter-in-place
          orders, closures, or other mandates, restrictions, and/or directives;
          (4) Owner or Contractor restrictions and/or directives; and/or (5)
          fulfillment of Contractor’s contractual or legal health and safety
          obligations associated with an Epidemic; then Contractor shall be
          entitled to an equitable adjustment to the contract schedule and
          duration to account for such disruptions, suspensions, and impacts. To
          the extent any of the causes identified herein results in an increase
          in the price of labor, materials, or equipment used in the performance
          of this contract, or other costs of performance of the contract,
          Contractor shall be entitled to an equitable adjustment to the
          contract price for such increases, provided contractor presents
          documentation of such increases (including the original prices and/or
          estimates) and evidence of contractor’s reasonable efforts to find
          alternative sources of material or equipment supply and/or labor at
          the original/nonimpacted prices and/or estimate
        </p>
        <p>
          <strong style={{ fontSize: "10px" }}>NOTICE TO OWNER:</strong>
        </p>
        <p style={{ fontSize: "10px", textAlign: "justify" }}>
          <strong>
            FAILURE OF THIS CONTRACTOR TO PAY THOSE PERSONS SUPPLYING MATERIAL
            OR SERVICES TO COMPLETE THIS CONTRACT CAN RESULT IN THE FILING OF A
            MECHANIC'S LIEN ON THE PROPERTY WHICH IS THE SUBJECT OF THIS
            CONTRACT PURSUANT TO CHAPTER 429, RSMO. TO AVOID THIS RESULT YOU MAY
            ASK THIS CONTRACTOR FOR "LIEN WAIVERS" FROM ALL PERSONS SUPPLYING
            MATERIAL OR SERVICES FOR THE WORK DESCRIBED IN THIS CONTRACT.
            FAILURE TO SECURE LIEN WAIVERS MAY RESULT IN YOUR PAYING FOR LABOR
            AND MATERIAL TWICE.
          </strong>
        </p>
      </div>
    );
  } else if (summary.Recipient === "sub") {
    let exampleContract = exampleContract20230920;
    if (moment(summary.ApprovalDate).isBefore("2023-09-20"))
      exampleContract = exampleContractOLD;

    return (
      <div className="ContractRequirements">
        <p>
          <strong>PROJECT:</strong>
          {`${summary.ProjectID}-${summary.ProjectName} - ${summary.ProjectDescription}`}
        </p>
        <p>hereinafter "Project” </p>

        <hr />

        <p>
          PRICE: Contractor agrees to pay the Subcontractor upon substantial
          completion of the Work in the amount of{" "}
        </p>
        <p>
          <strong>AMOUNT:</strong>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(summary.GrandTotal)}
        </p>
        <p>Dollars: {`${converter.toWords(summary.GrandTotal)}`}</p>
        <p>
          Retention Held:
          {`${summary.Retention}%`}
          {/* <xmod:format type="Float" value="[[retention]]" pattern="0.00%" /> */}
        </p>
        <p>(the “Price”).</p>

        <hr />

        <p>CONTRACT DOCUMENTS & GENERAL REQUIREMENTS:</p>
        <p>
          <p>A. This Subcontract.</p>
          <p>
            B. Exhibit A to the Subcontract "Subcontractor’s Proposal/Scope of
            Work”, attached hereto.
          </p>
          <p>
            C. Exhibit B to the Subcontract “Sample Insurance Certificate”,
            attached hereto.
          </p>
        </p>

        <p style={{ fontSize: "1.2em" }}>GENERAL CONDITIONS:</p>

        <p>SECTION 1. THE WORK</p>

        <p>
          1.1 Materials to be furnished and the work to be done by the
          Subcontractor consist of
        </p>
        <p>
          See attached “Exhibit A” for Scope of Work. <br />
          (the “Work”).
        </p>
        <p>
          {" "}
          1.2 Subcontractor shall furnish all the labor, materials, supplies,
          equipment to and perform the Work.
        </p>
        <p>
          {" "}
          1.3 If, during the performance of the Work, Subcontractor deems that
          any necessary condition is unsatisfactory, unsuitable or differs from
          good construction practice, it shall stop work and give prompt written
          notification of the condition to Contractor before proceeding with
          further work.
        </p>
        <p>
          {" "}
          1.4 Any reference contained herein to the subcontractor's proposal(s)
          is only for the convenience of clarifying the scope of work. The
          Subcontractor shall provide complete installations of their scope(s)
          of work, as indicated by the contract documents and Industry practice.
          The terms and conditions of this Subcontract shall take precedence
          over any other terms or conditions expressed or implied by the
          subcontractor proposal(s).
        </p>

        <p>SECTION 2. PAYMENT</p>

        <p>
          2.1 Contractor agrees to pay Subcontractor for the Work as set forth
          in this Subcontract. No payment shall be due until Subcontractor
          provides lien/claim waivers, fully executed by Subcontractor and its
          subcontractors and suppliers in a form reasonably acceptable to
          Contractor. Contractor shall have the right to withhold from payment
          to Subcontractor (i) such sums which Contractor determines are
          reasonably necessary to protect Contractor from cost or expense as a
          result of any default by Subcontractor of its obligations hereunder
          and (ii) the Retainage which shall be withheld until all retainage
          withheld by Owner is released under the Prime Contract.
        </p>
        <p>
          2.2 The Subcontractor shall pay for all materials, equipment, and
          labor used in connection with the performance of this Subcontract
          through the period covered by previous payments received from the
          Contractor, and shall furnish satisfactory evidence, when requested by
          the Contractor, to verify compliance with the above requirements.{" "}
        </p>
        <p>
          2.3 All invoices are to be submitted together with the lien waiver
          showing all previous payments made. All invoices are due no later than
          the 15th of each month and should include all progress and materials
          on site through the date of issuance only. This includes any required
          backup documentation that should be submitted with invoices. If
          progress billing is not received prior to Nexus 5 Group close of
          business on the 15th, invoices may not be recognized as received until
          the following month.
        </p>
        <p>
          2.4 Contractor will pay Subcontractor after the current contract’s
          payment application has been paid by the Owner. Provided that the
          Subcontractor turns in invoices(s) for payment to the Contractor no
          later than the 15th day of the month. The Owner shall make payment of
          the certified amount to the Contractor not later than the 15th day of
          the following month. The Subcontractor will receive payment not later
          than 15 days after the Owner has released payments to the Contractor.
        </p>

        <p>SECTION 3. SCHEDULE OF WORK</p>

        <p>
          3.1 Time is of the essence for completion of the Work. Subcontractor
          shall begin work on the Project at the direction of Contractor and
          shall carry the same forward as determined by Contractor.
        </p>
        <p>
          3.2 An extension of time shall not be allowed to the Subcontractor for
          any cause or under any circumstances unless the Subcontractor files a
          written claim therefore with the Contractor within forty-eight (48)
          hours after the cause therefore or circumstances underlying the same
          commenced. If the parties shall fall to agree on the extension, if
          any, to be allowed on such application, the Architect shall determine
          the same, and his decision, to the extent concurred to by the Owner,
          shall be final and binding on both parties.
        </p>
        <p>
          3.3 Contractor shall not be liable to the Subcontractor for delay to
          Subcontractor's Work by the act, neglect or default of the Owner, the
          Architect or any other contractor, or by reason of fire or other
          casualty, or on account of riots, strikes, labor trouble or other
          combined action of workmen or others or on account of any act of God,
          or by reason of any other cause beyond Contractor's control, or on
          account of any circumstances caused or contributed to by the
          Subcontractor.
        </p>

        <p>SECTION 4. WARRANTY</p>

        <p>
          4.1 Subcontractor unconditionally warrants that all labor, material
          and services employed and furnished by it in performing the work will
          be of first class quality and free from defects. Subcontractor agrees
          to promptly correct any and all defects in its work at its own cost
          for a period of one (1) year after the Work is accepted by Owner.
          Subcontractor shall and does hereby assign any and all manufacturer’s
          warranties for the Work to Contractor. Subcontractor authorizes
          Contractor to present this Subcontract to any manufacturer to effect
          such assignment and Subcontractor agrees to execute such other
          documents as may be required to finalize the assignment of
          manufacturer warranties.
        </p>

        <p>SECTION 5. INDEMNITY AND INSURANCE</p>

        <p>
          5.1 Subcontractor agrees to indemnify, defend and hold harmless
          Contractor and Owner, to the fullest extent allowed by law, from and
          against (i) all claims, causes of action and expenses arising out of
          injury to including death of any persons or damage to property alleged
          to have been caused in whole or in part by any act or omission of
          Subcontractor, its agents, employees, sub­ subcontractors, suppliers
          or invitees, or growing out of or incidental, directly or indirectly,
          to the performance of the work requested by Contractor regardless of
          how such injury, death or damage be caused, and (ii) all claims,
          causes of action and expenses caused by any act or omission (whether
          or not negligent) of Subcontractor, its agents, employees, sub­
          subcontractors, suppliers or invitees, in the prosecution of the work
          requested by Contractor. Subcontractor shall defend all suits brought
          against Contractor and/or Owner on account of any such claims of
          liability, shall pay any settlements made or judgments rendered with
          respect thereto, and shall reimburse and indemnify Contractor for all
          expenses, including court costs and attorneys' fees, incurred by
          Contractor.
        </p>
        <p>
          5.2 Subcontractor has included in the price to complete the work
          requested by Contractor the cost of purchasing insurance to fulfill
          the obligations of this section, and the extent of Subcontractor's
          obligation to indemnify any third party for that third party's own
          negligence is limited to the policy limits of such insurance.
        </p>
        <p>
          5.3 Subcontractor agrees to procure and carry, at its sole cost, until
          completion of the Work requested by Contractor and all applicable
          warranty periods, any insurance required by Insurance Requirements
          listed below.
        </p>
        <p>
          5.4 Subcontractor shall comply with all federal, state or local laws,
          ordinances, rules and regulations which are applicable to
          Subcontractor's work. Subcontractor shall indemnify and hold harmless
          Contractor on the account of any violations thereof.
        </p>
        <p>
          5.5 Should Contractor employ an attorney to enforce any provision of
          this Subcontract or to collect damages for default of the Subcontract,
          Subcontractor agrees to pay Contractor such attorney's fees and costs
          of collection as Contractor may expend with respect thereto.
        </p>

        <p>
          5.6{" "}
          <strong>
            SUBCONTRACTOR DOES HEREBY WAIVE ITS STATUTORY WORKERS COMPENSATION
            IMMUNITY.
          </strong>
        </p>

        <p>SECTION 6. JOBSITE ISSUES:</p>

        <p>
          6.1 Subcontractor is responsible for prompt removal of all debris
          associated with its Work.
        </p>
        <p>
          6.2 Subcontractor is responsible for verifying all existing dimensions
          and conditions before proceeding with its Work.
        </p>
        <p>
          6.3 No changes in the Work will be allowed unless authorized in
          writing by Contractor.
        </p>

        <p>SECTION 7. SAFETY:</p>

        <p>
          7.1 Subcontractor agrees to comply with all OSHA regulations and all
          Nexus 5 Group's safety requirements and jobsite rules and regulations.
        </p>

        <p>SECTION 8. FAILURE TO COMPLETE WORK:</p>

        <p>
          8.1 If a petition in bankruptcy or for an arrangement shall be filed
          by or against Subcontractor, or if Subcontractor shall fail to furnish
          materials of the quality or do work in the manner required by the
          General Contractor, or if Subcontractor shall fail to provide workmen
          in number and experience and equipment in kind and capacity suitable
          to the Architect, Owner or Contractor, or if Subcontractor shall fail
          in any manner to perform the whole or any part of any covenant or
          agreement contained or assumed therein, then and upon the occurrence
          of any such event (and the Contractor's finding and judgment with
          respect thereto shall be final and bind the subcontractor absolutely),
          Contractor shall have the right, after sending the Subcontractor
          notice of its intention by mail seven days (7) in advance as follows:
        </p>
        <p>
          8.1 a) to provide materials and labor in addition to or in place of
          any supplied by Subcontractor and deduct the cost thereof and expense
          relating thereto from the contract price payable hereunder, and after
          or without exercising said privilege, and
        </p>
        <p>
          8.1 b) to bar Subcontractor from said project (with or without
          terminating this subcontract) and take over and complete the Work
          covered hereby and/or to contract with others to do so, at the expense
          of Subcontractor, and for that purpose to take possession of, use and
          consume without notice, demand or legal process and without liability
          therefore or on account thereof, all materials, tools, appliances,
          machinery, equipment and facilities of Subcontractor at, near or in
          transit to the site of said project together with all contracts of
          Subcontractor for materials on order and bills of lading for materials
          in route, same being hereby assigned to Contractor.{" "}
        </p>
        <p>
          8.1 c) In every such case Subcontractor shall not be entitled to
          receive any further payment hereunder until all Work provided for
          herein shall be wholly finished, whereupon, if the cost, expense and
          damage incurred by the Contractor in completing the work covered
          hereby and performing the other obligations of Subcontractor
          hereunder, shall exceed the unpaid balance which otherwise would have
          been due to Subcontractor therefore to do so, the Contractor shall
          have the right, pursuant to the lien provided for herein, to sell all
          materials, tools, appliances, equipment and facilities of
          Subcontractor not previously used or consumed in the performance of
          said work and to apply the proceeds of such sales(s) on the obligation
          of Subcontractor who nevertheless shall remain liable for any
          deficiency.
        </p>

        <p>SECTION 9. ARBITRATION:</p>
        <p>
          THIS SUBCONTRACT CONTAINS A BINDING ARBITRATION CLAUSE WHICH MAY BE
          ENFORCED BY THE PARTIES.
        </p>
        <p>
          9.1 Claims in Arbitration shall be decided in accordance with the
          construction industry arbitration rules of the American Arbitration
          Association currently in effect. Demand for Arbitration shall be filed
          in writing with the other party to this subcontract and with the
          American Arbitration Association.
        </p>
        <p>
          9.2 A demand for Arbitration shall be made within the time limits
          specified in the conditions of the contract as applicable, and in
          other cases within a reasonable time after the claim has arisen, and
          in no event shall it be made after the date when institution of legal
          or equitable proceedings based on such claim would be barred by the
          applicable statute of limitations.
        </p>
        <p>
          9.3 Judgment on final award. The award rendered by the Arbitrator or
          Arbitrators shall be final, and judgment may be entered upon it in
          accordance with applicable law in any court having jurisdiction
          thereof.
        </p>

        <p>SECTION 10. INSURANCE REQUIREMENTS:</p>
        <p>
          Subcontractor shall comply with the insurance requirements set-forth
          below:
        </p>
        <p>
          10.1 Subcontractor agrees to procure and carry, at its sole cost,
          until completion the Work requested by the Contractor and all
          applicable warranty periods: (a) all insurance, with identical limits
          of liability and scope of coverage, as required of Contractor in the
          Prime Contract under which the subcontract work requested by
          Contractor is governed, or such higher amounts as set forth below; (b)
          and coverages obtained by Subcontractor shall be on an occurrence
          policy form and not on a claims made policy form; (c) insurance
          certificates, and additional insured endorsements, written on a
          standard ACORD form, and must be received by Contractor prior to
          commencement of work; (d) all insurance is to be issued by companies
          and with liability limits acceptable to Contractor; (e) all insurance
          certificates and policies will state that all coverages are in effect
          and will not be canceled or non-renewed without thirty (30) days prior
          written notice to Contractor; and (f) Contractor reserves the right to
          review certified copies of any and all insurance policies to which the
          subcontract work requested by Contractor is applicable. If
          Subcontractor should further subcontract or issue purchase orders
          covering any of this work to a third party, Subcontractor shall see to
          it that such third party maintains such insurance and shall furnish
          evidence thereof to Contractor. Subcontractor shall require each
          designer providing design services engaged by Subcontractor to provide
          identical coverage.
        </p>
        <p>
          10.2 SEVERABILITY OF INTEREST. All insurance carried shall be endorsed
          to provide that, inasmuch as this policy is written to cover more than
          one insured, all terms, conditions, insuring agreements and
          endorsements, with the exception of limits of liability, shall operate
          in the same manner as if there were a separate policy covering each
          insured.
        </p>

        <p>
          10.3 COMPREHENSIVE AUTOMOBILE LIABILITY INSURANCE. Subcontractor shall
          maintain comprehensive automobile insurance, including contractual
          liabilities insuring the indemnities set forth in this Master
          Subcontract covering all owned, non-owned and hired automobiles used
          in connection with the services or other work hereunder and shall have
          minimum bodily injury and property damage limits of $1,000,000.00
          combined single limit per occurrence. An MCS-90 endorsement shall be
          procured when applicable.
        </p>

        <p>
          10.4 WORKERS' COMPENSATION AND EMPLOYER'S LIABILITY INSURANCE.
          Subcontractor shall maintain Workers’ Compensation Insurance and
          Employer’s Liability Insurance to cover the statutory limits of the
          Workers’ Compensation and Employer’s Liability laws of the state in
          which any work is to be performed and when applicable to Federal Laws.
          At a minimum, Subcontractor is to provide evidence of workers’
          compensation and employers’ liability coverage with (i) a limit of
          bodily injury by accident of at least $100,000 per accident; and (ii)
          the limit of bodily injury by disease of at least $500,000 per policy
          and $100,000 per employee.
        </p>

        <p>
          10.5 COMMERCIAL GENERAL LIABILITY INSURANCE. Subcontractor shall
          obtain and maintain the Standard Insurance Services Office (ISO)
          Commercial General Liability Insurance (CG0001) or its equivalent, on
          an occurrence form for the hazards of (i) construction operation, (ii)
          subcontractor and sub­ subcontractors, (iii) independent contractors,
          (iv) products and completed operations (with completed operations to
          remain in force for as long as Subcontractor or those included as
          Additional Insureds bear exposure under all applicable statutes of
          limitation following project completion), (v) explosion, collapse and
          underground, and (vi) contractual liability insuring the indemnities
          set forth in this Master Subcontract. Such Commercial General
          Liability insurance shall provide broad coverage with no exclusions
          for subcontractors work. Each Project shall have and maintain minimum
          limits of $1,000,000.00 per occurrence, $2,000,000.00 per project
          general aggregate, $1,000,000.00 for personal and advertising injury,
          and $2,000,000.00 products-completed operations aggregate and ISO
          endorsement form CG 2417 10 93 or equivalent will apply. Any Umbrella
          policy shall also be endorsed to include a per project general
          aggregate.{" "}
        </p>
        <p>
          10.6 EXCESS LIABILITY. Subcontractor shall maintain Excess Liability
          coverage on an umbrella form with minimum limits of $2,000,000.00 per
          occurrence and $2,000,000.00 aggregate.
        </p>
        <p>
          10.7 WAIVER OF SUBROGATION. All insurance policies supplied shall
          include a waiver of any right of subrogation of the Insurers
          thereunder against Contractor and all its assigns, subsidiaries,
          affiliates, employees, insurers and underwriters, to the extent that
          such waiver is legal under the laws of the state which govern the
          subcontract work requested by Contractor.
        </p>
        <p>
          10.8 ADDITIONAL INSUREDS. Subcontractor’s furnished Insurance (except
          Workers' Compensation Insurance) shall include Owner, Contractor and
          any other person or entity required by the Prime Contract, and all
          their assigns, subsidiaries and affiliates as Additional Insureds as
          their respective interest may appear. Additional insured coverage
          shall apply to ongoing operations via ISO form CG 2010 or equivalent
          coverage, and shall apply to completed operations via ISO form CG 2037
          or equivalent coverage. Copies of endorsements confirming the coverage
          of any additional insured party shall be delivered along with any
          applicable certificate of insurance.{" "}
        </p>
        <p>
          10.9 INSURANCE PRIMARY. All policies of insurance provided pursuant to
          this article, including additional insured coverage, shall be written
          on a primary and non-contributing basis, via ISO form CG 2001 or its
          equivalent.
        </p>
        <p>
          10.10 NO LIMITATION OF LIABILITY. The required coverages referred to
          and set forth herein shall in no way affect, nor are they intended as
          a limitation on, Subcontractors liability with respect to its
          performance of the subcontract work requested by Contractor.
        </p>
        <p>
          10.11 SUB-SUBCONTRACTORS' AND VENDORS' INSURANCE. Subcontractor shall
          require all those sub-subcontractors, vendors, or any other
          third-party providing equipment materials or services directly to
          Subcontractor in connection with the subcontract work requested by
          Contractor to obtain, to maintain and keep in force coverages in
          accordance with the insurance requirements set forth herein during the
          time they are involved in performance of services or other work
          hereunder. Subcontractor shall obtain certificates of Insurance
          evidencing such coverage and provide contractor with such certificates
          no later than [30 days] after entering into an agreement with such
          third-party. Subcontractor shall not be excused from its obligations
          to cause such sub-subcontractor, vendor or other third-party to meet
          the insurance coverage requirements set forth under this section
          unless Subcontractor shall have obtained in writing from Contractor a
          waiver, which shall be effective only as to such requirements and for
          such sub-subcontractor or vendor specifically included therein.
        </p>
        <p>
          10.12 DESIGN LIABILITY. If any design responsibility is included in
          the subcontract, Subcontractor shall purchase, and maintain for a
          period of three years after the date of Final Completion, Insurance
          covering claims arising out of the performance or furnishing of Design
          Professional Services and for claims arising out of allegations of
          errors, omissions or negligent acts in connection with the subcontract
          work requested by Contractor. The policy shall be at least as broad as
          the coverage provided in Contractors Design Liability Policy, Member
          Companies of CNA Insurance, Form G- 115692-A (Ed 02196), with a
          minimum policy limit of $2,000,000.
        </p>

        <p>EXHIBIT B: SAMPLE CERTIFICATE OF INSURANCE</p>
        <p>
          <img
            src={exampleContract}
            alt="example contract"
            style={{ width: "100%" }}
          />
        </p>

        <p>EPIDEMIC RIDER:</p>
        <p>
          Notwithstanding any provision(s) of this Subcontract, if as a direct
          or indirect result of any virus, disease, contagion, including but not
          limited to COVID-19 (individually or collectively, “Epidemic”),
          Subcontractor’s work is delayed, disrupted, suspended, or otherwise
          impacted, including, but limited to, by (1) disruptions to material
          and/or equipment supply; (2) illness of Subcontractor’s workforce
          and/or unavailability of labor; (3) government quarantines,
          shelter-in-place orders, closures, or other mandates, restrictions,
          and/or directives; (4) Owner or Contractor restrictions and/or
          directives; and/or (5) fulfillment of Subcontractor’s contractual or
          legal health and safety obligations associated with an Epidemic; then
          Subcontractor shall be entitled to an equitable adjustment to the
          Subcontract schedule and duration to account for such disruptions,
          suspensions, and impacts. To the extent any of the causes identified
          herein results in an increase in the price of labor, materials, or
          equipment used in the performance of this Subcontract, or other costs
          of performance of the Subcontract, Subcontractor shall be entitled to
          an equitable adjustment to the Subcontract price for such increases,
          provided Subcontractor presents documentation of such increases
          (including the original prices and/or estimates) and evidence of
          Subcontractor’s reasonable efforts to find alternative sources of
          material or equipment supply and/or labor at the original/non-impacted
          prices and/or estimates.
        </p>
      </div>
    );
  }
};

export default ContractRequirements;
