import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Table, Icon, Popup, Input, Segment } from "semantic-ui-react";
import { useTable, useSortBy, useFilters, useGlobalFilter } from "react-table";
import matchSorter from "match-sorter";
import axios from "axios";
import { API_ROOT } from "../../api-config";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import "./CRM.scss";
import ContactFormModal from "./ContactFormModal/ContactFormModal";

// Define a default UI for filtering
const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const count = preGlobalFilteredRows.length;

  return (
    <Segment style={{ margin: 0 }} compact clearing basic floated="right">
      <Input
        value={globalFilter || ""}
        icon={<Icon name="search" />}
        placeholder={`${count} records...`}
        onChange={(e) => {
          setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    </Segment>
  );
};

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
};

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const MyTable = ({ columns, data, loading, fetchData, formModalLoading }) => {
  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy // useSortBy must be placed after useFilters!
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <Table stackable={false} compact="very" {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Table.HeaderCell
                  className="disable-select"
                  {...column.getHeaderProps()}
                >
                  {/* Add a sort direction indicator */}
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <Icon name="sort descending" />
                      ) : (
                        <Icon name="sort ascending" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Table.Cell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};
const CRM = (props) => {
  const [contactOptions, setContactOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contactID, setContactID] = useState(null);
  const [formModalLoading, setFormModalLoading] = useState(false);
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [contact, setContact] = useState({
    ContactID: null,
    ContactName: "",
    ContactTitle: "",
    ContactEmail: "",
    ContactOfficePhone: "",
    ContactCellPhone: "",
    ContactRelationshipOwner: null,
    ReferredByContactID: null,
    ContactType: "Client",
    ContactAddress: "",
    ContactCity: "",
    ContactState: "",
    ContactZip: "",
    CompanyID: null,
    CompanyName: "",
    IndustryType: "",
    CorporateAddress: "",
    CorporateCity: "",
    CorporateState: "",
    CorporateZip: "",
    CorporatePhone: "",
    VendorInd: 0,
    ClientInd: 0,
    SubcontractorInd: 0,
    CorporateEmail: "",
    Status: "Active",
  });
  const [error, setError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);

  const [modalHeader, setModalHeader] = useState("");
  const fetchIdRef = useRef(0);

  useEffect(() => {
    const parsed = queryString.parse(props.location.search);
    const uid = parsed.uid ? parseInt(parsed.uid) : null;

    if (uid) {
      handleContactClick("new", uid);
    }
  }, []);

  const fetchOptions = () => {
    axios
      .all([
        axios.get(`${API_ROOT}/api/project/contacts`),
        axios.get(`${API_ROOT}/api/project/clientcompanies`),
        axios.get(`${API_ROOT}/api/crm/users`),
        axios.get(`${API_ROOT}/api/crm/industries`),
      ])
      .then(
        axios.spread((contacts, companies, users, industries) => {
          setContactOptions(contacts.data.contacts || []);
          setCompanyOptions(companies.data.companies || []);
          setUserOptions(users.data.users || []);
          setIndustryOptions(industries.data.industries || []);
        })
      );
  };

  const fetchContact = (contactID) => {
    if (contactID) {
      setModalHeader("Edit Contact");
      setFormModalLoading(true);
      // fetch contact data
      axios
        .get(`${API_ROOT}/api/crm/contactbrowser`, {
          params: {
            contactID: contactID,
          },
        })
        .then((res) => {
          setFormModalLoading(false);
          setContact(res.data.contact[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setModalHeader("Add Contact");
      setFormModalLoading(false);
    }
  };

  const fetchCompany = (companyID) => {
    if (companyID) {
      setFormModalLoading(true);
      // fetch contact data
      axios
        .get(`${API_ROOT}/api/crm/company`, {
          params: {
            companyID,
          },
        })
        .then((res) => {
          const {
            CompanyID,
            CompanyName,
            IndustryType,
            CorporateAddress,
            CorporateCity,
            CorporateState,
            CorporateZip,
            CorporatePhone,
            VendorInd,
            ClientInd,
            SubcontractorInd,
            CorporateEmail,
          } = res.data.company[0];
          setFormModalLoading(false);
          setContact({
            ...contact,
            CompanyID,
            CompanyName,
            IndustryType,
            CorporateAddress,
            CorporateCity,
            CorporateState,
            CorporateZip,
            CorporatePhone,
            VendorInd,
            ClientInd,
            SubcontractorInd,
            CorporateEmail,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setModalHeader("Add Contact");
      setFormModalLoading(false);
    }
  };

  const fetchData = useCallback(() => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    // Set the loading state
    setLoading(true);

    axios.get(`${API_ROOT}/api/crm/contactbrowser`).then((res) => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setData(res.data.contact || []);
        setLoading(false);
      }
    });
  }, []);

  const handleContactClick = (source, value) => {
    setFormModalLoading(true);
    setError(false);
    setErrorArray(false);
    setContactID(value);
    fetchContact(value);
    setFormModalOpen(true);
  };

  const handleNewContactClick = () => {
    setContactID(null);
    setFormModalOpen(true);
  };

  const handleModalClose = () => {
    fetchOptions();
    setFormModalOpen(false);
    setContact({
      ContactID: null,
      ContactName: null,
      ContactTitle: "",
      ContactEmail: "",
      ContactOfficePhone: "",
      ContactCellPhone: "",
      ContactRelationshipOwner: null,
      ReferredByContactID: null,
      ContactType: "Client",
      ContactAddress: "",
      ContactCity: "",
      ContactState: "",
      ContactZip: "",
      CompanyID: null,
      CompanyName: "",
      IndustryType: "",
      CorporateAddress: "",
      CorporateCity: "",
      CorporateState: "",
      CorporateZip: "",
      CorporatePhone: "",
      VendorInd: 0,
      ClientInd: 0,
      SubcontractorInd: 0,
      CorporateEmail: "",
      Status: "Active",
    });
    setError(false);
    setErrorArray(false);
  };

  const handleContactNameChange = (event, data) => {
    let optionIsExisting = false;
    data.options.forEach((option) => {
      if (option.value === data.value) optionIsExisting = true;
    });

    if (optionIsExisting && data.value !== 0) {
      fetchContact(data.value);
    } else {
      setContact({
        ...contact,
        ContactID: 0,
        ContactName: data.value,
        ContactTitle: "",
        ContactEmail: "",
        ContactOfficePhone: "",
        ContactCellPhone: "",
        ContactRelationshipOwner: null,
        ReferredByContactID: null,
        ContactType: "Client",
        ContactAddress: "",
        ContactCity: "",
        ContactState: "",
        ContactZip: "",
        // CompanyID: null,
        // CompanyName: "",
        // IndustryType: "",
        // CorporateAddress: "",
        // CorporateCity: "",
        // CorporateState: "",
        // CorporateZip: "",
        // CorporatePhone: "",
        // VendorInd: 0,
        // ClientInd: 0,
        // SubcontractorInd: 0,
        // CorporateEmail: "",
        Status: "Active",
      });
    }
  };

  const handleCompanyNameChange = (event, data) => {
    let optionIsExisting = false;
    data.options.forEach((option) => {
      if (option.value === data.value) optionIsExisting = true;
    });

    if (optionIsExisting && data.value !== 0) {
      fetchCompany(data.value);
    } else {
      let text = "";
      if (data.value === 0) {
        const option = data.options.find((o) => o.value === data.value);
        text = option.text;
      }

      setContact({
        ...contact,
        CompanyID: 0,
        CompanyName: data.value === 0 ? text : data.value,
        IndustryType: "",
        CorporateAddress: "",
        CorporateCity: "",
        CorporateState: "",
        CorporateZip: "",
        CorporatePhone: "",
        VendorInd: 0,
        ClientInd: 0,
        SubcontractorInd: 0,
        CorporateEmail: "",
      });
    }
  };

  const handleContactNameAddition = (event, data) => {
    const contactName = data.value;

    const filteredContactOptions = contactOptions.filter(
      (option) => option.value !== 0
    );

    setContactOptions([
      ...filteredContactOptions,
      { value: 0, text: contactName, key: 0 },
    ]);

    setContactID(0);
  };

  const handleCompanyNameAddition = (event, data) => {
    const companyName = data.value;

    const filteredCompanyOptions = companyOptions.filter(
      (option) => option.value !== 0
    );

    setCompanyOptions([
      ...filteredCompanyOptions,
      { value: 0, text: companyName, key: 0 },
    ]);

    setContact({
      ...contact,
      CompanyID: 0,
      CompanyName: companyName,
      IndustryType: "",
      CorporateAddress: "",
      CorporateCity: "",
      CorporateState: "",
      CorporateZip: "",
      CorporatePhone: "",
      VendorInd: 0,
      ClientInd: 0,
      SubcontractorInd: 0,
      CorporateEmail: "",
    });
  };

  const validate = () => {
    let err = false;
    let errList = [];

    if (
      !contact.ContactName ||
      !contact.ContactEmail ||
      !contact.CompanyName ||
      !contact.ContactOfficePhone ||
      !contact.CorporateEmail ||
      !contact.CorporateAddress ||
      !contact.CorporateCity ||
      !contact.CorporateState ||
      !contact.CorporateZip
    ) {
      err = true;
      if (!contact.ContactName) {
        errList.push("ContactName");
      }

      if (!contact.ContactEmail) {
        errList.push("ContactEmail");
      }

      if (!contact.CompanyName) {
        errList.push("CompanyName");
      }

      if (!contact.ContactOfficePhone) {
        errList.push("ContactOfficePhone");
      }

      if (!contact.CorporateEmail) {
        errList.push("CorporateEmail");
      }

      if (!contact.CorporateAddress) {
        errList.push("CorporateAddress");
      }

      if (!contact.CorporateCity) {
        errList.push("CorporateCity");
      }

      if (!contact.CorporateState) {
        errList.push("CorporateState");
      }

      if (!contact.CorporateZip) {
        errList.push("CorporateZip");
      }

      setError(err);
      setErrorArray(errList);
    }

    return !err;
  };

  const handleSubmit = () => {
    const isValid = validate();
    if (!isValid) {
      return;
    } else {
      axios
        .post(`${API_ROOT}/api/crm/setcontact`, {
          params: {
            contact,
          },
        })
        .then((res) => {
          if (res.data.contact) {
            fetchData();
            handleModalClose();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleChange = (event, { name, value }) => {
    setContact({
      ...contact,
      [name]: value,
    });
  };

  const handleCheckboxChange = (event, { name, checked }) => {
    setContact({
      ...contact,
      [name]: checked ? 1 : 0,
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <Popup
            content="Add contact"
            trigger={
              <Icon
                name={"plus"}
                link
                onClick={() => handleNewContactClick()}
              />
            }
          />
        ),
        accessor: "ContactID",
        disableSortBy: true,
        Filter: false,
        Cell: ({ cell: { value } }) => {
          return (
            <Popup
              content="Edit contact"
              trigger={
                <Icon
                  color="blue"
                  link
                  name="user"
                  onClick={() => handleContactClick("new", value)}
                />
              }
            />
          );
        },
      },
      {
        Header: "Contact Name",
        accessor: "ContactName",
        Filter: false,
      },
      {
        Header: "Referrer",
        accessor: "IconReferrer",
        Filter: false,
        Cell: ({ cell: { value } }) => {
          return <Icon name={value} />;
        },
      },
      {
        Header: "Company",
        accessor: "CompanyName",
        Filter: false,
      },
      {
        Header: "Industry",
        accessor: "IndustryType",
        Filter: false,
      },
      {
        Header: "Client",
        accessor: "IconClient",
        Filter: false,
        Cell: ({ cell: { value } }) => {
          return <Icon name={value} />;
        },
      },
      {
        Header: "Vendor",
        accessor: "IconVendor",
        Filter: false,
        Cell: ({ cell: { value } }) => {
          return <Icon name={value} />;
        },
      },
      {
        Header: "Sub",
        accessor: "IconSubcontractor",
        Filter: false,
        Cell: ({ cell: { value } }) => {
          return <Icon name={value} />;
        },
      },
      {
        Header: "Owner",
        accessor: "RelationshipOwnerLastName",
        Filter: false,
      },
      {
        Header: "Status",
        accessor: "Status",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
    ],
    []
  );
  return (
    <div className="CRM">
      <MyTable
        formModalLoading={formModalLoading}
        loading={loading}
        fetchData={fetchData}
        columns={columns}
        data={data}
      />
      <ContactFormModal
        contactID={contactID}
        formModalOpen={formModalOpen}
        setFormModalOpen={setFormModalOpen}
        formModalLoading={formModalLoading}
        contact={contact}
        handleModalClose={handleModalClose}
        modalHeader={modalHeader}
        handleContactNameChange={handleContactNameChange}
        handleCompanyNameChange={handleCompanyNameChange}
        handleContactNameAddition={handleContactNameAddition}
        handleCompanyNameAddition={handleCompanyNameAddition}
        contactOptions={contactOptions}
        companyOptions={companyOptions}
        userOptions={userOptions}
        industryOptions={industryOptions}
        fetchOptions={fetchOptions}
        handleSubmit={handleSubmit}
        error={error}
        errorArray={errorArray}
        handleChange={handleChange}
        handleCheckboxChange={handleCheckboxChange}
      />
    </div>
  );
};

export default withRouter(CRM);
