import React from "react";
import { Container, Table } from "semantic-ui-react";

// import "react-datepicker/dist/react-datepicker.css";
import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";
import GMByPM from "./GMByPM/GMByPM";
// import GMReport from "./GMReport/GMReport";
import GMReportUpload from "./GMReportUpload/GMReportUpload";
import InvoiceLog from "./InvoiceLog/InvoiceLog";
import ProjectBudgets from "./ProjectBudgets/ProjectBudgets";
import ProjectBudgetsUniversal from "./ProjectBudgetsUniversal/ProjectBudgetsUniversal";
import UnbilledProjects from "./UnbilledProjects/UnbilledProjects";

const AccountingReports = () => {
  return (
    <Container style={{ paddingLeft: 10, paddingRight: 10 }} fluid>
      <div>
        <h1 style={{ color: "rgb(254, 80, 0)", marginLeft: 40 }}>
          Accounting Reports
        </h1>
      </div>
      <div style={{ marginTop: 40 }}>
        <Table padded={false}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Report</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {/* <GMReport /> */}
            <GMByPM />
            <GMReportUpload />
            <InvoiceLog />
            <ProjectBudgets />
            <ProjectBudgetsUniversal />
            <UnbilledProjects />
          </Table.Body>
        </Table>
        {/* <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          dateFormat="MM/yyyy"
          showMonthYearPicker
        /> */}
      </div>
    </Container>
  );
};

export default AccountingReports;
