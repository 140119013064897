import React, { Fragment, useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Icon, Popup } from "semantic-ui-react";

const UploadSubContractPopup = ({ contract, uploadSubContract }) => {
  const proposalUploadInput = useRef(null);

  const onDrop = useCallback((acceptedFile) => {
    const guid = proposalUploadInput.current.dataset.guid;
    // console.log(acceptedFile);
    uploadSubContract(acceptedFile, guid);
  }, []);

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    // isDragReject,
    // acceptedFiles,
    // rejectedFiles
  } = useDropzone({
    onDrop,
    accept: "application/pdf",
    minSize: 0,
  });
  return (
    <Fragment>
      <span ref={proposalUploadInput} data-guid={contract.GUID}></span>
      {contract.CompanyName !== "Placeholder" ? (
        <Popup
          content={"Upload approved proposal pdf"}
          position="top center"
          trigger={
            <div style={{ display: "inline-block" }} {...getRootProps()}>
              <input {...getInputProps()} />
              <Icon
                link
                name="cloud upload"
                color={isDragActive ? "orange" : "black"}
                size={isDragActive ? "big" : null}
              />
            </div>
          }
        />
      ) : null}
    </Fragment>
  );
};

export default UploadSubContractPopup;
