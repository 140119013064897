import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Header,
  Form,
  Button,
  Icon,
  Message,
  Table,
  Image,
} from "semantic-ui-react";
import SignaturePad from "react-signature-canvas";
import "./JobStartup.scss";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";

const JobStartup = ({ projectID }) => {
  const sigCanvas = useRef({});
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signatures, setSignatures] = useState([]);

  useEffect(() => {
    if (projectID) {
      Axios.get(`${API_ROOT}/api/project/job-startup-signatures`, {
        params: {
          projectID,
        },
      })
        .then((res) => {
          setSignatures(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [projectID]);

  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setLoading(true);
    // const recipient = summary.Recipient;
    const isEmpty = sigCanvas.current.isEmpty();

    if (name && company && !isEmpty) {
      setError(false);
      const signature = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setSignature(signature, name, company);
      // handleModalClose();
    } else if (isEmpty) {
      setError(true);
      setLoading(false);
      return;
    } else {
      setLoading(false);
      return;
    }
  };

  const setSignature = (signature, name, company) => {
    Axios.post(`${API_ROOT}/api/project/job-startup-signature`, {
      projectID,
      name,
      company,
      signature,
    })
      .then((res) => {
        setSignatures(res.data);
        sigCanvas.current.clear();
        setName("");
        setCompany("");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Grid
      className="JobStartup"
      style={{
        overflowY: "auto",
        marginTop: 0,
        maxHeight: "calc(100vh - 120px)",
        padding: 10,
        maxWidth: "100%",
        marginLeft: 0,
      }}
    >
      <Grid.Row style={{ marginBottom: 40 }}>
        <Grid.Column style={{ padding: 0 }}>
          <Header textAlign="center" as="h3">
            Job Startup
          </Header>
          <p style={{ fontStyle: "italic" }}>
            I have been given the project expectations, including safety, and I
            understand the extent of the scope in which my company is
            contracted.
          </p>
          {/* <div style={{ width: "100%" }}>
            
          </div> */}
          <Form error={error}>
            <Form.Field style={{ width: "100%" }}>
              <label>Signature</label>
              <SignaturePad
                ref={sigCanvas}
                canvasProps={{
                  width: window.innerWidth - 34,
                  height: 160,
                  className: "job-startup-signature-canvas",
                }}
              />
              <Message
                error
                header="Missing signature"
                content="Please include a signature before clicking submit."
              />
            </Form.Field>
            <Form.Field required>
              <label>Name</label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
            </Form.Field>
            <Form.Field required>
              <label>Company</label>
              <input
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                placeholder="Company"
              />
            </Form.Field>
            <Button disabled={loading} icon color="yellow" onClick={clear}>
              <Icon name="eraser" /> Clear Signature
            </Button>
            <Button
              onClick={save}
              // disabled={!name || !company || sigCanvas.current.isEmpty()}
              icon
              primary
              disabled={loading}
              loading={loading}
            >
              <Icon name={loading ? "spinner" : "save"} /> Save
            </Button>
          </Form>
          <Table style={{ width: "100%", marginTop: 30 }} unstackable>
            <Table.Body>
              {signatures.map((signature) => (
                <Table.Row key={signature.Signature}>
                  <Table.Cell>
                    <Image style={{ width: 100 }} src={signature.Signature} />
                  </Table.Cell>
                  <Table.Cell>{signature.Name}</Table.Cell>
                  <Table.Cell>{signature.Company}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default JobStartup;
