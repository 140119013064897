import React, { useState, useRef } from "react";
import { Modal, Button, Form, Icon } from "semantic-ui-react";
import SignaturePad from "react-signature-canvas";
import { isEmpty } from "lodash";

import "./signature-modal.scss";

export default ({ onSubmit }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [approvedBy, setApprovedBy] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const signatureCanvas = useRef({});

  const isValid = () => {
    let err = {};
    if (isEmpty(approvedBy)) err.approvedBy = "Name is required";

    if (signatureCanvas.current.isEmpty())
      err.signatureCanvas = "Signature is required";

    setErrors(err);
    return Object.keys(err).length === 0;
  };

  const clear = () => {
    setApprovedBy("");
    if (signatureCanvas.current) signatureCanvas.current.clear();
  };

  const handlesSave = async () => {
    if (isValid()) {
      setLoading(true);
      const signature = signatureCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");

      await onSubmit({ approvedBy, signature });

      clear();
      handleModalClose();
    }
  };

  const handleModalOpen = () => {
    setApprovedBy("");
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const hasError = (field) => errors[field] && !isEmpty(errors[field]);

  return (
    <Modal
      className="signature-modal"
      closeOnDimmerClick={false}
      open={modalOpen}
      onClose={handleModalClose}
      size={"tiny"}
      trigger={
        <div>
          <Button primary onClick={handleModalOpen}>
            Sign Approval
          </Button>
        </div>
      }
    >
      <Modal.Header>Sign Approval</Modal.Header>
      <Modal.Content>
        <Form style={{ marginBottom: 10 }}>
          <label>Your name</label>
          <Form.Input
            value={approvedBy}
            error={hasError("approvedBy")}
            onChange={(e) => setApprovedBy(e.target.value)}
          />
          {hasError("approvedBy") && (
            <p className="error">{errors.approvedBy}</p>
          )}
        </Form>

        <div>
          <label>Please sign below</label>
          <SignaturePad
            ref={signatureCanvas}
            canvasProps={{ className: "contractSignatureCanvas" }}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={loading}
          loading={loading}
          color="green"
          onClick={handlesSave}
        >
          <Icon name="save" /> Save
        </Button>
        <Button color="yellow" disabled={loading} onClick={clear}>
          <Icon name="eraser" /> Clear
        </Button>
        <Button disabled={loading} onClick={() => setModalOpen(false)}>
          <Icon name="remove" /> Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
