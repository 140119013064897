import React, { useContext } from "react";
import { Table, Label, Popup, Icon, Dropdown } from "semantic-ui-react";

import moment from "moment";
// import UploadProposalPopup from "../PendingClientContracts/UploadProposalPopup";
// import PreviewContractPDF from "../../PreviewContractPDF/PreviewContractPDF";
import { downloadContract } from "../../../../../util";
import SetToPendingModal from "./SetToPendingModal";

import CurrentProject from "../../../../../screens/projects/CurrentProject";
import { useShowV1 } from "../../../../../hooks/useQuery";
import SyncToAcumatica from "./SyncToAcumatica";

const ApprovedClientContracts = (props) => {
  const { useV2 } = useContext(CurrentProject);
  const splitScreen = useShowV1();

  // Create our number formatter.
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const {
    approvedContracts,
    handleInclusionChange,
    getContractByGUIDTotal,
    getContractsByTypeTotal,
  } = props;

  return (
    <>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="2"></Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Proposal</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Upload</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">View</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Days</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Retention</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Contract</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Cont Sent</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Cont Rcvd</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Inclusions</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell colSpan="2" style={{ color: "rgb(254, 80, 0)" }}>
            Approved Contracts
          </Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Array.isArray(approvedContracts) && approvedContracts.length ? (
          approvedContracts[0].Client.map((client, index) => {
            return (
              <React.Fragment key={index}>
                {client.Contracts.map((contract) => {
                  return contract.displayType === "data" ? (
                    <Table.Row key={contract.GUID}>
                      <Table.Cell>
                        <Label>{contract.ContractType}</Label>
                        <SetToPendingModal guid={contract.GUID} type="Client" />
                        <SyncToAcumatica guid={contract.GUID} />
                      </Table.Cell>
                      <Table.Cell>{contract.ContractDescription}</Table.Cell>
                      <Table.Cell textAlign="center">
                        <Popup
                          content="Proposal has been sent"
                          position="top center"
                          trigger={
                            <Icon
                              // size="large"
                              color="green"
                              name="check"
                            />
                          }
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Popup
                          content={"Save uploaded proposal pdf"}
                          position="top center"
                          trigger={
                            <Icon
                              link
                              // size="large"
                              onClick={() => downloadContract(contract.GUID)}
                              name="download"
                            />
                          }
                        />
                        {/* ) : null} */}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {splitScreen && (
                          <>
                            <Popup
                              content={"OLD :: View client proposal link"}
                              position="top center"
                              trigger={
                                <a
                                  href={`/contract/?r=c&id=${contract.GUID}&c=${contract.CompanyID}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Icon link name="external alternate" />
                                </a>
                              }
                            />
                            &nbsp;|&nbsp;&nbsp;
                            <Popup
                              content={"NEW :: View client proposal link"}
                              position="top center"
                              trigger={
                                <a
                                  href={`/contracts/${contract.GUID}/client`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Icon link name="external alternate" />
                                </a>
                              }
                            />
                          </>
                        )}
                        {!splitScreen && (
                          <Popup
                            content={"View client proposal link"}
                            position="top center"
                            trigger={
                              <a
                                href={
                                  useV2()
                                    ? `/contracts/${contract.GUID}/client`
                                    : `/contract/?r=c&id=${contract.GUID}&c=${contract.CompanyID}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Icon link name="external alternate" />
                              </a>
                            }
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.ClientWorkDays}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.Retention
                          ? contract.Retention.toFixed(2) + "%"
                          : "0.00%"}
                      </Table.Cell>
                      {splitScreen && (
                        <Table.Cell textAlign="center">
                          <span title="OLD">
                            {formatter.format(contract.GrandTotal || 0)}
                          </span>
                          &nbsp;&nbsp;|&nbsp;&nbsp;
                          <span title="NEW">
                            {formatter.format(
                              getContractByGUIDTotal(contract.GUID)
                            )}
                          </span>
                        </Table.Cell>
                      )}
                      {!splitScreen && (
                        <Table.Cell textAlign="center">
                          {useV2 &&
                            formatter.format(
                              getContractByGUIDTotal(contract.GUID)
                            )}
                          {!useV2 && formatter.format(contract.GrandTotal || 0)}
                        </Table.Cell>
                      )}
                      <Table.Cell textAlign="center">
                        {contract.ContractSentDate
                          ? moment(contract.ContractSentDate).format("l")
                          : ""}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.ClientApprovalDate
                          ? moment(contract.ClientApprovalDate).format("l")
                          : ""}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.Inclusions[0].text ? (
                          <Dropdown
                            selection
                            selectOnNavigation={false}
                            selectOnBlur={false}
                            options={contract.Inclusions}
                            onChange={(event, data) =>
                              handleInclusionChange(event, data)
                            }
                          />
                        ) : null}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    <Table.Row
                      key={contract.GUID}
                      style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}
                    >
                      <Table.Cell colSpan="7"></Table.Cell>
                      {splitScreen && (
                        <>
                          <Table.Cell
                            style={{ color: "blue", fontWeight: "bold" }}
                            textAlign="center"
                          >
                            <span title="OLD">
                              {formatter.format(contract.GrandTotal || 0)}
                            </span>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <span title="NEW">
                              {formatter.format(getContractsByTypeTotal())}
                            </span>
                          </Table.Cell>
                        </>
                      )}
                      {!splitScreen && (
                        <Table.Cell
                          style={{ color: "blue", fontWeight: "bold" }}
                          textAlign="center"
                        >
                          {useV2 && formatter.format(getContractsByTypeTotal())}
                          {!useV2 && formatter.format(contract.GrandTotal || 0)}
                        </Table.Cell>
                      )}

                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })
        ) : (
          <Table.Row style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}>
            <Table.Cell colSpan="11">No approved contracts</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </>
  );
};

export default ApprovedClientContracts;
