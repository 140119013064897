import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Header, Segment, Dimmer, Loader } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../api-config";

import SummaryContract from "./SummaryContract/SummaryContract";
// import DetailedContract from "./DetailedContract/DetailedContract";
import DetailedContractNew from "./DetailedContract/DetailedContractNew";
import SummaryCO from "./SummaryCO/SummaryCO";
import DetailedCONew from "./DetailedCO/DetailedCONew";
// import DetailedCO from "./DetailedCO/DetailedCO";
import ContractToolbar from "./ContractToolbar/ContractToolbar";

const ContractWrapper = (props) => {
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [recipient, setRecipient] = useState("");
  const [guid, setGuid] = useState("");
  const [contract, setContract] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [approvedInd, setApprovedInd] = useState(0);

  // Use regex to check if GUID is valid
  const isGuid = (str) => {
    if (str[0] === "{") {
      str = str.substring(1, str.length - 1);
    }
    var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(str);
  };

  const fetchContract = () => {
    // api call to get contract
    Axios.get(`${API_ROOT}/contract/contract`, {
      params: {
        recipient,
        guid,
        companyID,
      },
    })
      .then((res) => {
        if (res.data.err) {
          setLoading(false);
          setValid(false);
        } else if (res.data.contract) {
          setApprovedInd(res.data.contract.summary.ApprovedInd ? 1 : 0);
          setLoading(false);
          setValid(true);
          setContract(res.data.contract);
        } else {
          setLoading(false);
          setValid(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const emailContractSigned = (emailDetails) => {
    const {
      email,
      bcc,
      contractType,
      GUID,
      companyID,
      projectName,
      projectID,
      recipient,
    } = emailDetails;

    let baseUrl = "";

    const hostname = window && window.location && window.location.hostname;

    if (hostname === "nexsys1-staging.azurewebsites.net") {
      baseUrl = "https://nexsys1-staging.azurewebsites.net";
    } else if (hostname === "ns1.nexus5group.com") {
      baseUrl = "https://ns1.nexus5group.com";
    } else if (hostname === "nexsys1.azurewebsites.net") {
      baseUrl = "https://nexsys1.azurewebsites.net";
    } else {
      // backEndHost = 'https://nexsys1api-staging.azurewebsites.net';
      baseUrl = "http://localhost:3000";
    }

    const subject =
      recipient === "c"
        ? `${contractType} Contract Approved For ${projectName}-${projectID}`
        : `${contractType} Subcontract Approved For ${projectName}-${projectID}`;
    const html = `<p>Thank you for signing the ${contractType} ${
      recipient === "c" ? "contract" : "subcontract"
    } for ${projectName}. To view and download this ${
      recipient === "c" ? "contract" : "subcontract"
    }, please click <a href="${baseUrl}/contract/?r=${recipient}&id=${GUID}&c=${companyID}">here</a>.</p>`;

    // MODIFY Email and BCC WHEN GOING LIVE!!!!

    Axios.post(`${API_ROOT}/send-email`, {
      email: email,
      cc: "",
      bcc: bcc,
      subject,
      text: `Thank you for signing the ${contractType} ${
        recipient === "c" ? "proposal" : "contract"
      } for ${projectName}. To view and download this contract, please visit ${baseUrl}/contract/?r=${recipient}&id=${GUID}&c=${companyID} .`,
      html,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEmailDetails = () => {
    Axios.get(`${API_ROOT}/contract/email-details`, {
      params: {
        GUID: guid,
        recipient: recipient,
      },
    })
      .then((res) => {
        if (res.data.emailDetails) {
          // console.log(res.data.emailDetails);
          emailContractSigned(res.data.emailDetails);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setSignature = (signature, approvedBy) => {
    Axios.post(`${API_ROOT}/contract/signature`, {
      params: {
        recipient,
        guid,
        signature,
        approvedBy,
        companyID,
      },
    })
      .then((res) => {
        console.log(res);
        setContract(res.data.contract);
        setApprovedInd(res.data.contract.summary.ApprovedInd === 1 ? 1 : 0);
        if (res.data.contract.summary.ApprovedInd === 1) {
          getEmailDetails();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    const values = queryString.parse(props.location.search);
    setRecipient(values.r || "");
    setGuid(values.id);
    setCompanyID((values.c ? parseInt(values.c) : null) || "");

    const isValidGuid = values.id ? isGuid(values.id) : false;

    // Check if url params exist and GUID is valid
    if (values.r && isValidGuid && values.c) {
      setValid(true);
    } else {
      setValid(false);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // When recipient or GUID loads or changes, check url param values and validity
    // If valid fetch contract
    if ((recipient === "s" || recipient === "c") && guid && valid) {
      fetchContract();
    }
  }, [recipient, guid]);

  if (!loading && !valid) {
    return (
      <div>
        <center>
          <Header
            as="h1"
            style={{
              width: 600,
              height: 140,
              display: "block",
              margin: "auto",
              position: "relative",
              textAlign: "center",
              fontSize: 36,
              marginTop: "30vh",
            }}
          >
            That contract could not be found. Please contact the N5 Project
            Manager.
          </Header>
          {/* <Link to="/browser">Return to Home Page</Link> */}
        </center>
      </div>
    );
  } else if (loading) {
    return (
      <Segment style={{ height: "100vh" }}>
        <Dimmer inverted active>
          <Loader size="massive" inverted indeterminate>
            Finding Contract
          </Loader>
        </Dimmer>
      </Segment>
    );
  } else if (!loading && valid && contract) {
    if (
      contract.summary.Recipient === "client" &&
      contract.summary.ContractType === "Base" &&
      contract.summary.ProposalFormatType === "Summary"
    ) {
      return (
        <React.Fragment>
          <ContractToolbar guid={guid} approvedInd={approvedInd} />
          <SummaryContract setSignature={setSignature} contract={contract} />
        </React.Fragment>
      );
    } else if (
      contract.summary.Recipient === "client" &&
      contract.summary.ContractType !== "Base" &&
      contract.summary.ProposalFormatType === "Summary"
    ) {
      return (
        <React.Fragment>
          <ContractToolbar guid={guid} approvedInd={approvedInd} />
          <SummaryCO setSignature={setSignature} contract={contract} />
        </React.Fragment>
      );
    } else if (
      contract.summary.ContractType === "Base" &&
      contract.summary.ProposalFormatType === "Detail"
    ) {
      return (
        <React.Fragment>
          <ContractToolbar guid={guid} approvedInd={approvedInd} />
          {/* <DetailedContract setSignature={setSignature} contract={contract} /> */}
          <DetailedContractNew
            setSignature={setSignature}
            contract={contract}
          />
        </React.Fragment>
      );
    } else if (
      contract.summary.ContractType !== "Base" &&
      contract.summary.ProposalFormatType === "Detail"
    ) {
      return (
        <React.Fragment>
          <ContractToolbar guid={guid} approvedInd={approvedInd} />
          {/* <DetailedCO setSignature={setSignature} contract={contract} /> */}
          <DetailedCONew setSignature={setSignature} contract={contract} />
        </React.Fragment>
      );
    } else {
      return (
        <div>
          <center>
            <Header
              as="h1"
              style={{
                width: 600,
                height: 140,
                display: "block",
                margin: "auto",
                position: "relative",
                textAlign: "center",
                fontSize: 36,
                marginTop: "30vh",
              }}
            >
              That contract could not be found. Please contact the N5 Project
              Manager.
            </Header>
            {/* <Link to="/browser">Return to Home Page</Link> */}
          </center>
        </div>
      );
    }
  } else {
    return null;
  }
};

export default withRouter(ContractWrapper);
