import React, { Component } from "react";
import Condition from "./Condition/Condition";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";
import { connect } from "react-redux";
import {
  getConditionList,
  delCondition,
  setCondition,
} from "../../../actions/project/inclusion/inclusionActions";

class ConditionWrapper extends Component {
  _isMounted = false;
  state = {
    projectID: this.props.projectID,
    subCategoryCode: this.props.subCategoryCode,
    menuSelected: this.props.menuSelected,
    conditionNameOptions: [],
    conditionList: this.props.conditionList || [],
    clientContractOptions: [],
    subContractOptions: [],
  };

  fetchContractOptions = async () => {
    const result = await Axios.get(`${API_ROOT}/api/contract/contractoptions`, {
      params: {
        projectID: this.props.projectID,
      },
    });

    this.setState({
      clientContractOptions: result.data.contractOptions.ClientContractOptions,
      subContractOptions: result.data.contractOptions.subContractOptions,
    });
  };

  setClientContractOptions = (clientContractOptions) => {
    this.setState({
      clientContractOptions,
    });
  };

  setSubContractOptions = (subContractOptions) => {
    this.setState({
      subContractOptions,
    });
  };

  isMenuSelected = () => {
    if (this.state.menuSelected) {
      this.props.getConditions(
        this.props.projectID,
        this.state.subCategoryCode
      );
    }

    this.fetchContractOptions();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    this.abortController.abort();
  };

  abortController = new window.AbortController();

  componentDidMount() {
    this._isMounted = true;
    this.isMenuSelected();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.subCategoryCode !== this.state.subCategoryCode &&
      this._isMounted
    ) {
      this.setState(
        {
          subCategoryCode: nextProps.subCategoryCode,
          conditionList: nextProps.conditionList || [],
        },
        () => {
          this.isMenuSelected();
        }
      );
    }

    if (
      nextProps.conditionList.length !==
      (this.props.conditionList ? this.props.conditionList.length : 0)
    ) {
      this.setState({
        conditionList: nextProps.conditionList || [],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.conditionList) !==
      JSON.stringify(this.props.conditionList)
    ) {
      let missingSubDescription = false;

      if (this.props.conditionList.length > 0) {
        this.props.conditionList.forEach((item) => {
          if (item.SelectedSubcontractor && !item.SubcontractorScope) {
            missingSubDescription = true;
          }
        });
      }

      this.props.setMissingSubDescription(missingSubDescription);

      // this.setState({ conditionList });
      this.props.getInclusionDescriptions();
    }
  }

  handleBlur = (field, value, conditionID) => {
    this.props.setCondition(
      conditionID,
      this.state.projectID,
      this.state.subCategoryCode,
      field,
      value
    );
    // Axios.post(
    //   `${API_ROOT}/api/condition/setcondition`,
    //   {
    //     conditionID,
    //     projectID: this.state.projectID,
    //     subCategoryCode: this.state.subCategoryCode,
    //     field,
    //     value
    //   },
    //   {
    //     signal: this.abortController.signal
    //   }
    // ).then(res => {
    //   if (this._isMounted) {
    //     const conditionList = res.data.conditions.conditionList || [];
    //     let missingSubDescription = false;

    //     if (conditionList.length > 0) {
    //       conditionList.forEach(item => {
    //         if (item.SelectedSubcontractor && !item.SubcontractorScope) {
    //           missingSubDescription = true;
    //         }
    //       });
    //     }

    //     this.props.setMissingSubDescription(missingSubDescription);

    //     this.setState({ conditionList });
    //     this.props.getInclusionDescriptions();
    //   }
    // });
  };

  handleSelect = (event, field, value, conditionID) => {
    this.props.setCondition(
      conditionID,
      this.state.projectID,
      this.state.subCategoryCode,
      field,
      value
    );
    // Axios.post(`${API_ROOT}/api/condition/setcondition`, {
    //   conditionID,
    //   projectID: this.state.projectID,
    //   subCategoryCode: this.state.subCategoryCode,
    //   field,
    //   value
    // }).then(res => {
    //   if (this._isMounted) {
    //     const conditionList = res.data.conditions.conditionList || [];
    //     this.setState({ conditionList });
    //   }
    // });
  };

  handleDelete = (conditionID) => {
    const projectID = this.state.projectID;
    const subCategoryCode = this.props.subCategoryCode;
    this.props.delCondition(conditionID, projectID, subCategoryCode);
  };

  render() {
    return (
      <React.Fragment>
        {this.props.conditionList &&
        this._isMounted &&
        this.props.conditionList.length > 0
          ? this.props.conditionList.map((condition, idx) => {
              return (
                <Condition
                  conditionid={condition.ConditionID}
                  key={condition.ConditionID + "_" + idx}
                  handleBlur={this.handleBlur}
                  handleSelect={this.handleSelect}
                  handleDelete={this.handleDelete}
                  condition={condition}
                  conditionNameOptions={this.state.conditionNameOptions}
                  clientContractOptions={this.state.clientContractOptions}
                  subContractOptions={this.state.subContractOptions}
                  setClientContractOptions={this.setClientContractOptions}
                  setSubContractOptions={this.setSubContractOptions || []}
                  getInclusionDescriptions={this.props.getInclusionDescriptions}
                  setMissingSubDescription={this.props.setMissingSubDescription}
                  setMissingInternalDescription={
                    this.props.setMissingInternalDescription
                  }
                  getConditions={this.props.getConditions}
                />
              );
            })
          : ""}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getConditions: (projectID, subCategoryCode) => {
      dispatch(getConditionList(projectID, subCategoryCode));
    },
    delCondition: (conditionID, projectID, subCategoryCode) => {
      dispatch(delCondition(conditionID, projectID, subCategoryCode));
    },
    setCondition: (conditionID, projectID, subCategoryCode, field, value) => {
      dispatch(
        setCondition(conditionID, projectID, subCategoryCode, field, value)
      );
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    conditionList: state.inclusion.conditionList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConditionWrapper);
