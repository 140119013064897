import { LOGOUT, USER_REQUEST_SUCCESS } from "../constants/userConstants";

export default (state = {}, action) => {
  switch (action.type) {
    case USER_REQUEST_SUCCESS:
      const current = JSON.parse(action.payload);
      if (current.length > 0)
        return Object.assign({}, state, {
          current: current[0],
          roles: current[0].Roles ? current[0].Roles.map((x) => x.Role) : [],
          roleIds: current[0].Roles
            ? current[0].Roles.map((x) => x.RoleID)
            : [],
        });
      break;

    case LOGOUT:
      return null;

    default:
      return state;
  }
};
