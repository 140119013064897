import React, { useState, useEffect } from "react";
import { Menu, Grid, Modal, Button, Form, Table } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import { useHistory } from "react-router-dom";

const AssignmentItem = ({
  dayOfWeek,
  project,
  date,
  setAssignments,
  setRegularHoursTotal,
  setOvertimeHoursTotal,
  userID,
}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [conditionOptions, setConditionOptions] = useState([]);
  const [conditionID, setConditionID] = useState("");
  const [regularHours, setRegularHours] = useState("");
  const [overtimeHours, setOvertimeHours] = useState("");
  const [estimateToCompleteHours, setEstimateToCompleteHours] = useState("");
  const [tableValues, setTableValues] = useState(null);

  const history = useHistory();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleSave = () => {
    setLoading(true);
    Axios.post(`${API_ROOT}/api/project/assignment-task`, {
      userID,
      conditionID,
      date,
      regularHours:
        !!regularHours || regularHours === 0 ? parseFloat(regularHours) : null,
      overtimeHours:
        !!overtimeHours || overtimeHours === 0
          ? parseFloat(overtimeHours)
          : null,
      estimateToCompleteHours:
        !!estimateToCompleteHours || estimateToCompleteHours === 0
          ? parseFloat(estimateToCompleteHours)
          : null,
    })
      .then((res) => {
        if (res.data.assignments) {
          setAssignments(res.data.assignments);
        }

        if (
          res.data.totals &&
          (res.data.totals.regularHoursTotal ||
            res.data.totals.regularHoursTotal === 0)
        ) {
          setRegularHoursTotal(res.data.totals.regularHoursTotal);
        }

        if (
          res.data.totals &&
          (res.data.totals.overtimeHoursTotal ||
            res.data.totals.overtimeHoursTotal === 0)
        ) {
          setOvertimeHoursTotal(res.data.totals.overtimeHoursTotal);
        }

        setLoading(false);
        fetchTableValues();
      })
      .catch((err) => {
        // setError(true);
        setLoading(false);

        console.log(err);
      });
    // handleClose();
  };

  const resetForm = () => {
    setConditionID(null);
    setRegularHours("");
    setOvertimeHours("");
    setEstimateToCompleteHours("");
  };

  const fetchTableValues = () => {
    Axios.get(`${API_ROOT}/api/project/assignment-values-by-project`, {
      params: {
        projectID: project.projectID,
        userID: userID,
        date,
      },
    })
      .then((res) => {
        setTableValues(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (project.projectID) {
      Axios.get(`${API_ROOT}/api/project/assignment-condition-options`, {
        params: {
          projectID: project.projectID,
        },
      })
        .then((res) => {
          setConditionOptions(res.data);
          fetchTableValues();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [project.projectID]);

  useEffect(() => {
    if (conditionID) {
      Axios.get(`${API_ROOT}/api/project/assignment-hours-by-condition`, {
        params: {
          conditionID,
          userID,
          date,
        },
      })
        .then((res) => {
          setRegularHours(
            res.data.actualHoursRegular || res.data.actualHoursRegular === 0
              ? res.data.actualHoursRegular
              : ""
          );
          setOvertimeHours(
            res.data.actualHoursOT || res.data.actualHoursOT === 0
              ? res.data.actualHoursOT
              : ""
          );
          setEstimateToCompleteHours(
            res.data.estimateToCompleteHours ||
              res.data.estimateToCompleteHours === 0
              ? res.data.estimateToCompleteHours
              : ""
          );
        })
        .catch((err) => console.log(err));
    }
  }, [conditionID]);

  return (
    <Menu.Item link key={project.projectID} style={{ padding: 0, margin: 0 }}>
      <Grid style={{ padding: 0, margin: 0 }}>
        <Grid.Column
          onClick={() => history.push(`/m/projects/${project.projectID}`)}
          width={11}
          style={{ padding: 4, margin: 0, fontSize: 12 }}
        >
          {`${project.projectID} - ${project.companyName}`}
          <br />
          {`${project.projectName}`}
        </Grid.Column>
        <Grid.Column
          onClick={handleOpen}
          width={5}
          style={{ padding: 0, margin: 0 }}
        >
          <Grid
            style={{
              padding: 0,
              margin: 0,
              // height: "100%",
            }}
            stackable={false}
          >
            <Grid.Row style={{ padding: 0, margin: 0 }} columns="equal">
              <Grid.Column
                textAlign="center"
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 3,
                  paddingBottom: 3,
                  margin: 0,
                  background: "#48BF91",
                  color: "#fff",
                  borderLeft: "1px solid rgba(34,36,38,.15)",
                }}
              >
                Reg hrs
              </Grid.Column>
              <Grid.Column
                textAlign="center"
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 3,
                  paddingBottom: 3,
                  margin: 0,
                  background: "#0076BE",
                  color: "#fff",
                  borderLeft: "1px solid rgba(34,36,38,.15)",
                }}
              >
                OT hrs
              </Grid.Column>
            </Grid.Row>
            <Grid.Row
              style={{
                padding: 0,
                margin: 0,
                // height: "100%",
              }}
              columns="equal"
            >
              <Grid.Column
                stretched
                textAlign="center"
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 3,
                  paddingBottom: 3,
                  margin: 0,
                  borderLeft: "1px solid rgba(34,36,38,.15)",
                }}
              >
                {project.regularHours}
              </Grid.Column>
              <Grid.Column
                stretched
                textAlign="center"
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 3,
                  paddingBottom: 3,
                  margin: 0,
                  borderLeft: "1px solid rgba(34,36,38,.15)",
                }}
              >
                {project.overtimeHours}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid>
      <Modal
        dimmer="blurring"
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <Modal.Header>{dayOfWeek}</Modal.Header>
        <Modal.Content>
          <h4>
            {`${project.projectID} - ${project.companyName}`}
            <br />
            {`${project.projectName}`}
          </h4>
          <Form>
            <Form.Dropdown
              selection
              label="Condition"
              options={conditionOptions}
              selectOnBlur={false}
              value={conditionID}
              onChange={(e, data) => {
                setConditionID(data.value);
              }}
            />

            <Form.Input
              value={regularHours}
              onChange={(e, { value }) => setRegularHours(value)}
              type="number"
              label="Regular hours"
            />
            <Form.Input
              value={overtimeHours}
              onChange={(e, { value }) => setOvertimeHours(value)}
              type="number"
              label="Overtime hours"
            />
            <Form.Input
              value={estimateToCompleteHours}
              onChange={(e, { value }) => setEstimateToCompleteHours(value)}
              type="number"
              label="Estimate to complete hours"
            />

            <Form.Button
              loading={loading}
              disabled={!conditionID}
              primary
              onClick={handleSave}
            >
              Save
            </Form.Button>
          </Form>
          {Array.isArray(tableValues) && tableValues.length ? (
            <Table
              style={{ fontSize: 10 }}
              size="small"
              compact="very"
              unstackable
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Condition</Table.HeaderCell>
                  <Table.HeaderCell>Reg Hrs</Table.HeaderCell>
                  <Table.HeaderCell>OT Hrs</Table.HeaderCell>
                  <Table.HeaderCell>ETC Hrs</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {tableValues.map((el) => (
                  <Table.Row key={el.condition}>
                    <Table.Cell>{el.condition}</Table.Cell>
                    <Table.Cell>{el.ActualHoursRegular}</Table.Cell>
                    <Table.Cell>{el.ActualHoursOT}</Table.Cell>
                    <Table.Cell>{el.EstimateToCompleteHours}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          <Button color="yellow" onClick={handleClose}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </Menu.Item>
  );
};

export default AssignmentItem;
