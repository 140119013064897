import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { API_ROOT } from "../../api-config";
import { AgGridReact } from "ag-grid-react";
import {
  Button,
  Dimmer,
  Grid,
  Icon,
  Input,
  Loader,
  Popup,
  Segment,
} from "semantic-ui-react";
import "./Browser2.scss";
import { Link } from "react-router-dom";
// import NotesModal from "../Browser/ProjectsTable/IconGrid/NotesModal/NotesModal";
// import Probability from "./Probability/Probability";
import StageType from "./StageType/StageType";
import numeral from "numeral";
import Forecast from "./Forecast/Forecast";
// import { ChangeDetectionService } from "ag-grid-react/lib/changeDetectionService";
import PipelineReport from "../Browser/ProjectsTable/PipelineReport/PipelineReport";
import StageButtons from "./StageButtons/StageButtons";
import { useSelector } from "react-redux";
import NotesModal from "./NotesModal/NotesModal";

const Browser2 = () => {
  const [gridApi, setGridApi] = useState(null);
  // const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [type, setType] = useState("active");
  const [initials, setInitials] = useState("");
  // const [typee, setTypee] = useState("active");

  const user = useSelector((state) => JSON.parse(state.auth.user)[0]);

  useEffect(() => {
    setInitials(user.FirstName.charAt(0) + user.LastName.charAt(0));
  }, [user]);

  const ref = useRef(type);

  const onGridReady = (params) => {
    setGridApi(params.api);
    // setGridColumnApi(params.columnApi);
  };

  useEffect(() => {
    if (gridApi) {
      ref.current = type;
      gridApi.onFilterChanged();
    }
  }, [type]);

  const getProjects = () => {
    Axios.get(`${API_ROOT}/api/project/browser`)
      .then((res) => {
        setRowData(res.data.projects);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getProjects();
  }, []);

  const onFilterTextBoxChanged = (e, { value }) => {
    gridApi.setQuickFilter(value);
  };

  const currencyFormatter = (currency, sign) => {
    var sansDec = currency.toFixed(0);
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return sign + `${formatted}`;
  };

  const columnDefs = [
    {
      field: null,
      headerName: "",
      filter: false,
      sortable: false,
      resizable: false,
      pinned: "left",
      cellStyle: { padding: "0px 0px 0px 0px" },
      suppressMovable: true,
      maxWidth: 60,
      minWidth: 60,
      cellRendererFramework: (params) => {
        return (
          <Grid style={{ margin: 0, padding: 0 }}>
            <Grid.Row style={{ margin: 0, padding: 0, paddingTop: 0 }}>
              <Grid.Column
                style={{ fontSize: 12, margin: 0, padding: 0, width: 26 }}
              >
                <Link to={`/browser/projects/${params.data.ProjectID}`}>
                  <Icon
                    style={{ padding: 10 }}
                    bordered
                    color="blue"
                    link
                    name="search"
                  />
                </Link>
              </Grid.Column>
              <Grid.Column
                style={{ fontSize: 12, margin: 0, padding: 0, width: 26 }}
              >
                <Link
                  target="_blank"
                  to={
                    params.data.ClientContactID
                      ? `/crm?uid=${params.data.ClientContactID}`
                      : "/crm"
                  }
                >
                  <Icon link bordered name="user" />
                </Link>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Grid.Column
                style={{ fontSize: 12, margin: 0, padding: 0, width: 26 }}
              >
                <NotesModal
                  color={params.data.NotesColor}
                  projectID={params.data.ProjectID}
                  projects={rowData}
                  getProjects={getProjects}
                />
              </Grid.Column>
              <Grid.Column
                style={{ fontSize: 12, margin: 0, padding: 0, width: 26 }}
              >
                <Popup
                  content={
                    <ul
                      className="ul__stoplight"
                      style={{
                        listStyle: "none",
                        fontSize: 11,
                        paddingLeft: 0,
                      }}
                    >
                      <li style={{ paddingBottom: 2 }}>
                        <Icon
                          inverted
                          bordered
                          name={params.data.ForecastIcon}
                          color={params.data.ForecastColor}
                        />{" "}
                        Forecast
                      </li>
                      <li style={{ paddingBottom: 2 }}>
                        <Icon
                          inverted
                          bordered
                          name={params.data.ScopeAckIcon}
                          color={params.data.ScopeAckColor}
                        />{" "}
                        Job Start Up
                      </li>
                      <li style={{ paddingBottom: 2 }}>
                        <Icon
                          inverted
                          bordered
                          name={params.data.BidsIcon}
                          color={params.data.BidsColor}
                        />{" "}
                        Subcontractor Bids
                      </li>
                      <li style={{ paddingBottom: 2 }}>
                        <Icon
                          inverted
                          bordered
                          name={params.data.SubcontractorContractIcon}
                          color={params.data.SubcontractorContractColor}
                        />{" "}
                        Subcontractor Contract
                      </li>
                      <li>
                        <Icon
                          inverted
                          bordered
                          name={params.data.ClientContractIcon}
                          color={params.data.ClientContractColor}
                        />{" "}
                        Client Contract
                      </li>
                    </ul>
                  }
                  on="click"
                  header="Stoplight"
                  position="right center"
                  trigger={
                    <Icon
                      link
                      bordered
                      inverted
                      color={params.data.MasterWarningColor}
                      name={params.data.MasterWarningIcon}
                    />
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      },
    },
    {
      field: "ProjectName",
      headerName: "Project",
      flex: 2,
      minWidth: 280,
      cellRendererFramework: (params) => {
        return (
          <span className="project-description">
            {params.data.ClientCompanyName
              ? `${params.data.ClientCompanyName}`
              : ""}
            <br />
            {params.data.WorkLocationName}
            <br />
            {params.data.ProjectID}-{params.data.ProjectName}
          </span>
        );
      },
      valueGetter: (params) => {
        return `${params.data.ClientCompanyName} ${params.data.WorkLocationName} ${params.data.ProjectID} ${params.data.ProjectName}`;
      },
    },
    {
      field: "PMInitials",
      headerName: "PM",
      resizable: false,
      filter: false,
      maxWidth: 44,
      minWidth: 44,
      cellStyle: { color: "#fe5000" },
      headerClass: "browser-initials-header",
    },
    {
      field: "PEInitials",
      headerName: "PE",
      resizable: false,
      filter: false,
      maxWidth: 44,
      minWidth: 44,
      cellStyle: { color: "black" },
      headerClass: "browser-initials-header",
    },
    {
      field: "SIInitials",
      headerName: "SI",
      resizable: false,
      filter: false,
      maxWidth: 44,
      minWidth: 44,
      cellStyle: { color: "gray" },
      headerClass: "browser-initials-header",
    },
    {
      field: "BidTypeShort",
      filter: false,
      headerName: "Type",
      minWidth: 74,
      maxWidth: 120,
      cellStyle: { paddingRight: "0px" },
    },
    // {
    //   headerName: "Prob",
    //   minWidth: 50,
    //   // filter: "agNumberColumnFilter",
    //   filter: false,
    //   field: "Probability",
    //   cellRendererFramework: (params) => (
    //     <Probability
    //       projectID={params.data.ProjectID}
    //       prob={params.data.Probability}
    //       rowData={rowData}
    //       setRowData={setRowData}
    //     />
    //   ),
    // },
    {
      headerName: "Stage",
      minWidth: 76,
      maxWidth: 120,
      wrapText: false,
      sort: "asc",
      filter: false,
      cellStyle: { paddingRight: "0px" },
      field: "ProjectStageAbbr",
      cellRendererFramework: (params) => (
        <StageType
          projectID={params.data.ProjectID}
          stage={params.value}
          rowData={rowData}
          setRowData={setRowData}
          stageType={params.data.StageType}
          // setStageType={this.props.setStageType}
          // loadProjectCounts={this.loadProjectCounts}
        />
      ),
    },
    {
      field: "Days",
      // filter: "agNumberColumnFilter",
      cellStyle: { paddingRight: "0px" },
      filter: false,
      minWidth: 54,
      maxWidth: 140,
      headerName: "Days",
    },
    {
      field: "ContractAmount",
      headerName: "Contract",
      // filter: "agNumberColumnFilter",
      cellStyle: { paddingRight: "0px" },
      wrapText: false,
      minWidth: 80,
      maxWidth: 160,
      filter: false,
      valueFormatter: (params) =>
        currencyFormatter(params.data.ContractAmount, "$"),
    },
    {
      field: "EstMarginPercent",
      headerName: "Mar %",
      wrapText: false,
      minWidth: 60,
      maxWidth: 140,
      filter: false,
      cellStyle: { paddingRight: "0px" },
      // filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.data.EstMarginPercent
          ? params.data.EstMarginPercent.toFixed(1) + "%"
          : "0.0%",
    },
    {
      field: "EstMargin",
      headerName: "Mar $",
      wrapText: false,
      minWidth: 80,
      maxWidth: 160,
      cellStyle: {
        paddingRight: "0px",
      },
      // filter: "agNumberColumnFilter",
      filter: false,
      valueFormatter: (params) => currencyFormatter(params.data.EstMargin, "$"),
    },
    {
      field: "Variance",
      headerName: "Var",
      // filter: "agNumberColumnFilter",
      minWidth: 70,
      maxWidth: 70,
      wrapText: false,
      filter: false,
      cellStyle: (params) => {
        if (params.data.Variance >= 0) {
          return {
            paddingRight: "0px",
            color: "black",
            fontWeight: "normal",
          };
        }
        return {
          paddingRight: "0px",
          color: "red",
          fontWeight: "bold",
        };
      },
      valueFormatter: (params) => numeral(params.value).format("0.000%"),
    },
    {
      headerName: "Jan",
      maxWidth: 34,
      resizable: false,
      minWidth: 34,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "jan",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "0px" },

      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="jan"
          rowData={rowData}
          setRowData={setRowData}
          color="#fe5000"
        />
      ),
    },
    {
      headerName: "Feb",
      maxWidth: 34,
      resizable: false,
      minWidth: 34,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "feb",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="feb"
          rowData={rowData}
          setRowData={setRowData}
          color="#fe5000"
        />
      ),
    },
    {
      headerName: "Mar",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "mar",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="mar"
          rowData={rowData}
          setRowData={setRowData}
          color="#fe5000"
        />
      ),
    },
    {
      headerName: "Apr",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "apr",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="apr"
          rowData={rowData}
          setRowData={setRowData}
          color="#626262"
        />
      ),
    },
    {
      headerName: "May",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "may",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="may"
          rowData={rowData}
          setRowData={setRowData}
          color="#626262"
        />
      ),
    },
    {
      headerName: "Jun",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "jun",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="jun"
          rowData={rowData}
          setRowData={setRowData}
          color="#626262"
        />
      ),
    },
    {
      headerName: "Jul",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "jul",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="jul"
          rowData={rowData}
          setRowData={setRowData}
          color="#fe5000"
        />
      ),
    },
    {
      headerName: "Aug",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "aug",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="aug"
          rowData={rowData}
          setRowData={setRowData}
          color="#fe5000"
        />
      ),
    },
    {
      headerName: "Sep",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "sep",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="sep"
          rowData={rowData}
          setRowData={setRowData}
          color="#fe5000"
        />
      ),
    },
    {
      headerName: "Oct",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "oct",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="oct"
          rowData={rowData}
          setRowData={setRowData}
          color="#626262"
        />
      ),
    },
    {
      headerName: "Nov",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "nov",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="nov"
          rowData={rowData}
          setRowData={setRowData}
          color="#626262"
        />
      ),
    },
    {
      headerName: "Dec",
      maxWidth: 34,
      minWidth: 34,
      resizable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
      field: "dec",
      headerClass: "browser-initials-header",
      cellStyle: { padding: "0px" },
      cellRendererFramework: (params) => (
        <Forecast
          gridApi={gridApi}
          projectID={params.data.ProjectID}
          month="dec"
          rowData={rowData}
          setRowData={setRowData}
          color="#626262"
        />
      ),
    },
  ];

  const externalFilterChanged = (newValue) => {
    // type = newValue;
    setType(newValue);
    // setTypee(newValue);
    gridApi.onFilterChanged();
  };

  // const isExternalFilterPresent = () => {
  //   return type !== "active";
  // };

  const isExternalFilterPresent = () => {
    return ref.current !== "active";
  };

  const doesExternalFilterPass = (node) => {
    switch (ref.current) {
      case "4PREP":
        return node.data.ProjectStageAbbr === "4PREP";
      case "3CLIENT":
        return node.data.ProjectStageAbbr === "3CLIENT";
      case "2APRVD":
        return node.data.ProjectStageAbbr === "2APRVD";
      case "1WIP":
        return node.data.ProjectStageAbbr === "1WIP";
      case "ME":
        return (
          node.data.PMInitials === initials ||
          node.data.PEInitials === initials ||
          node.data.SIInitials === initials
        );
      default:
        return true;
    }
  };

  // const doesExternalFilterPass = (node) => {
  //   switch (type) {
  //     case "4PREP":
  //       return node.data.ProjectStageAbbr === "4PREP";
  //     case "3CLIENT":
  //       return node.data.ProjectStageAbbr === "3CLIENT";
  //     case "2APRVD":
  //       return node.data.ProjectStageAbbr === "2APRVD";
  //     case "1WIP":
  //       return node.data.ProjectStageAbbr === "1WIP";
  //     default:
  //       return true;
  //   }
  // };

  return Array.isArray(rowData) && rowData.length ? (
    <div className="Browser2" style={{ marginTop: 46, height: "100%" }}>
      <div style={{ height: "100%" }}>
        <Segment style={{ marginTop: -64 }} basic textAlign="right">
          <Button
            basic
            active={type === "active"}
            onClick={() => externalFilterChanged("active")}
          >
            Active
          </Button>
          <Button basic as={Link} to="/browser-archive">
            Archive
          </Button>

          <StageButtons
            type={type}
            externalFilterChanged={externalFilterChanged}
          />

          <div style={{ display: "inline-block", marginRight: 14 }}>
            <PipelineReport />
          </div>

          <Input
            type="text"
            id="filter-text-box"
            placeholder="Filter..."
            onChange={onFilterTextBoxChanged}
          />
        </Segment>
      </div>
      <div
        className="ag-theme-balham"
        style={{
          height: "calc(100vh - 130px)",
          // height: 300,
          // width: "100%",
          // overflowY: "scroll",
          // overflowX: "scroll",
          // minWidth: "1200px",
        }}
      >
        <AgGridReact
          immutableData={true}
          rowSelection={"single"}
          getRowNodeId={(data) => data.ProjectID}
          enableCellTextSelection={true}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          rowHeight={76}
          onFirstDataRendered={(params) => {
            params.api.sizeColumnsToFit();
          }}
          onGridSizeChanged={(params) => {
            params.api.sizeColumnsToFit();
          }}
          onGridReady={onGridReady}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={{
            cellStyle: { "white-space": "normal", "line-height": "14px" },
            suppressSizeToFit: false,
            flex: 1,
            resizable: true,
            sortable: true,
            filter: true,
          }}
        />
      </div>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>
  );
};

// var type = "active";

export default Browser2;
