import React, { Fragment, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import moment from "moment";
import "./TimesheetTable.scss";
import { Button, Header, Icon } from "semantic-ui-react";
import CheckboxCellRenderer from "./CheckboxCellRenderer/CheckboxCellRenderer";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";
import CsvDownloader from "react-csv-downloader";

const TimesheetTable = ({
  rowData,
  fromDate,
  periodStartDate,
  activities,
  weekDiff,
  setWeekDiff,
  to_date,
  from_date,
}) => {
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleCheckboxChange = function(userID, checked, periodStartDate) {
    Axios.post(`${API_ROOT}/api/project/timesheet-approval`, {
      params: {
        userID,
        checked,
        periodStartDate,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      id: "Key",
      displayName: "Key",
    },
    {
      id: "Employee",
      displayName: "Employee",
    },
    {
      id: "Employee Name",
      displayName: "Employee Name",
    },
    {
      id: "Date",
      displayName: "Date",
    },
    {
      id: "Week Number",
      displayName: "Week Number",
    },
    {
      id: "Earning Type",
      displayName: "Earning Type",
    },
    {
      id: "Labor Item",
      displayName: "Labor Item",
    },
    {
      id: "Branch",
      displayName: "Branch",
    },
    {
      id: "Project",
      displayName: "Project",
    },
    {
      id: "Project Task",
      displayName: "Project Task",
    },
    {
      id: "Cost Code",
      displayName: "Cost Code",
    },
    {
      id: "Time Spent",
      displayName: "Time Spent",
    },
    {
      id: "Billable",
      displayName: "Billable",
    },
    {
      id: "Billable Time",
      displayName: "Billable Time",
    },
    {
      id: "Description",
      displayName: "Description",
    },
  ];

  const width = 60;
  const cellStyle = { textAlign: "right" };

  const colDefs = [
    {
      headerName: "",
      children: [
        {
          field: "displayname",
          headerName: "",
          pinned: "left",
        },
      ],
    },
    {
      headerName: "",
      children: [
        {
          field: "approvalInd",
          headerName: "Approved",
          cellRenderer: "checkboxRenderer",
          cellRendererParams: {
            handleCheckboxChange,
            periodStartDate,
          },
          width: 100,
          cellStyle: { textAlign: "center" },
          pinned: "left",
        },
      ],
    },
    {
      headerName: moment(fromDate).format("ddd M/D"),

      children: [
        {
          headerName: "Reg",
          field: "satr",
          width,
          cellStyle,
        },
        {
          headerName: "OT",
          field: "sato",
          width,
          cellStyle,
        },
      ],
    },
    {
      headerName: moment(fromDate)
        .add(1, "days")
        .format("ddd M/D"),
      children: [
        {
          headerName: "Reg",
          field: "sunr",
          width,
          cellStyle,
        },
        {
          headerName: "OT",
          field: "suno",
          width,
          cellStyle,
        },
      ],
    },
    {
      headerName: moment(fromDate)
        .add(2, "days")
        .format("ddd M/D"),
      children: [
        {
          headerName: "Reg",
          field: "monr",
          width,
          cellStyle,
        },
        {
          headerName: "OT",
          field: "mono",
          width,
          cellStyle: { textAlign: "center" },
        },
      ],
    },
    {
      headerName: moment(fromDate)
        .add(3, "days")
        .format("ddd M/D"),
      children: [
        {
          headerName: "Reg",
          field: "tuer",
          width,
          cellStyle,
        },
        {
          headerName: "OT",
          field: "tueo",
          width,
          cellStyle,
        },
      ],
    },
    {
      headerName: moment(fromDate)
        .add(4, "days")
        .format("ddd M/D"),
      children: [
        {
          headerName: "Reg",
          field: "wedr",
          width,
          cellStyle,
        },
        {
          headerName: "OT",
          field: "wedo",
          width,
          cellStyle,
        },
      ],
    },
    {
      headerName: moment(fromDate)
        .add(5, "days")
        .format("ddd M/D"),
      children: [
        {
          headerName: "Reg",
          field: "thur",
          width,
          cellStyle,
        },
        {
          headerName: "OT",
          field: "thuo",
          width,
          cellStyle,
        },
      ],
    },
    {
      headerName: moment(fromDate)
        .add(6, "days")
        .format("ddd M/D"),
      children: [
        {
          headerName: "Reg",
          field: "frir",
          width,
          cellStyle,
        },
        {
          headerName: "OT",
          field: "frio",
          width,
          cellStyle,
        },
      ],
    },
    {
      headerName: "Total",
      children: [
        {
          headerName: "Reg",
          field: "totalr",
          width,
          cellStyle: { textAlign: "right", backgroundColor: "bisque" },
        },
        {
          headerName: "OT",
          field: "totalo",
          width,
          cellStyle: { textAlign: "right", backgroundColor: "bisque" },
        },
      ],
    },
    {
      headerName: "Total MO",
      children: [
        {
          headerName: "Reg",
          field: "mo_rh",
          width,
          cellStyle: { textAlign: "right", backgroundColor: "#eee" },
        },
        {
          headerName: "OT",
          field: "mo_ot",
          width,
          cellStyle: { textAlign: "right", backgroundColor: "#eee" },
        },
      ],
    },
    {
      headerName: "Total KS",
      children: [
        {
          headerName: "Reg",
          field: "ks_rh",
          width,
          cellStyle: { textAlign: "right", backgroundColor: "#eee" },
        },
        {
          headerName: "OT",
          field: "ks_ot",
          width,
          cellStyle: { textAlign: "right", backgroundColor: "#eee" },
        },
      ],
    },
    {
      headerName: "Total KCMO",
      children: [
        {
          headerName: "Reg",
          field: "kcmo_rh",
          width,
          cellStyle: { textAlign: "right", backgroundColor: "#eee" },
        },
        {
          headerName: "OT",
          field: "kcmo_ot",
          width,
          cellStyle: { textAlign: "right", backgroundColor: "#eee" },
        },
      ],
    },
  ];

  // const onBtnExport = () => {
  //   var params = getParams();
  //   // if (params.suppressQuotes || params.columnSeparator) {
  //   //   alert(
  //   //     "NOTE: you are downloading a file with non-standard quotes or separators - it may not render correctly in Excel."
  //   //   );
  //   // }
  //   gridApi.exportDataAsCsv(params);
  // };

  // function getParams() {
  //   return {
  //     suppressQuotes: true,
  //     columnSeparator: ",",
  //   };
  // }

  function setPrinterFriendly(api) {
    var eGridDiv = document.querySelector(".TimesheetTable");
    eGridDiv.style.height = "";
    eGridDiv.style.width = "";
    eGridDiv.style.maxWidth = "";
    api.setDomLayout("print");
  }
  function setNormal(api) {
    var eGridDiv = document.querySelector(".TimesheetTable");
    eGridDiv.style.width = "100%";
    eGridDiv.style.height = "calc(100vh - 230px)";
    eGridDiv.style.maxWidth = 1312;
    api.setDomLayout(null);
  }

  const onBtnPrint = () => {
    var api = gridApi;
    setPrinterFriendly(api);
    setTimeout(function() {
      window.print();
      setNormal(api);
    }, 2000);
  };

  return (
    <Fragment>
      <div style={{ display: "inline-block", width: "20%" }}>
        <Button onClick={onBtnPrint}>Print</Button>
        <CsvDownloader
          columns={columns}
          datas={activities}
          filename="TimeActivities"
          separator=","
        >
          <Button>Export CSV</Button>
        </CsvDownloader>
      </div>
      <div style={{ display: "inline-block", width: "60%" }}>
        <Header as="h3" textAlign="center" style={{ maxWidth: 1312 }}>
          <span>
            <Icon
              onClick={() => setWeekDiff(weekDiff - 1)}
              color="blue"
              link
              size="large"
              name="angle left"
            />
            {` ${from_date.format("l")}  —  ${to_date.format("l")} `}
            {weekDiff + 1 > 0 ? null : (
              <Icon
                onClick={() => setWeekDiff(weekDiff + 1)}
                color="blue"
                link
                size="large"
                name="angle right"
              />
            )}
          </span>
        </Header>
      </div>
      <div style={{ display: "inline-block", width: "20%" }}></div>

      <div
        id="myGrid"
        className="ag-theme-balham TimesheetTable"
        style={{ height: "calc(100vh - 230px)", width: "100%" }}
      >
        {/* <Button onClick={onBtnExport}>Export</Button> */}

        <AgGridReact
          columnDefs={colDefs}
          onGridReady={onGridReady}
          gridOptions={{
            frameworkComponents: {
              checkboxRenderer: CheckboxCellRenderer,
            },
          }}
          rowData={rowData}
        ></AgGridReact>
      </div>
    </Fragment>
  );
};

export default TimesheetTable;
