import React from "react";
import { Grid, Header, Divider, Table, Label } from "semantic-ui-react";

// Create our number formatter.
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const ProjectInfo = ({ projectID, projectInfo }) => {
  return (
    <Grid
      style={{
        overflowY: "auto",
        marginTop: 0,
        maxHeight: "calc(100vh - 120px)",
        maxWidth: "100%",
        marginLeft: 0,
      }}
    >
      <Grid.Row>
        <Grid.Column style={{ padding: "10px 10px", marginBottom: 40 }}>
          <Header textAlign="center" as={"h3"}>
            Project Info
          </Header>
          <Divider />
          <Header style={{ color: "rgb(254, 80, 0)" }} as={"h4"}>
            Description
          </Header>
          <p>{projectInfo.projectDescription}</p>
          <Divider />
          <Header style={{ color: "rgb(254, 80, 0)" }} as={"h4"}>
            Address
          </Header>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://maps.google.com/?q=${projectInfo.workLocationAddress}`}
            >
              {projectInfo.workLocationAddress}
            </a>
          </p>
          <Divider />
          <Header style={{ color: "rgb(254, 80, 0)" }} as={"h4"}>
            Project Staff
          </Header>
          <Table basic="very" unstackable>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{projectInfo.PMDisplayName}</Table.Cell>
                <Table.Cell>Project Manager</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{projectInfo.PEDisplayName}</Table.Cell>
                <Table.Cell>Project Engineer</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{projectInfo.SIDisplayName}</Table.Cell>
                <Table.Cell>Superintendent</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider />
          <Header style={{ color: "rgb(254, 80, 0)" }} as={"h4"}>
            Flags
          </Header>
          <div>
            <Label
              style={{ marginBottom: 10 }}
              color={projectInfo.forecastColor}
            >
              Forecast
            </Label>
            <Label
              color={projectInfo.JobStartupColor}
              style={{ marginBottom: 10 }}
            >
              Job Startup
            </Label>
            <Label
              style={{ marginBottom: 10 }}
              color={projectInfo.clientContractColor}
            >
              Client Contracts
            </Label>
            <Label style={{ marginBottom: 10 }} color={projectInfo.bidsColor}>
              Sub Bids
            </Label>
            <Label
              style={{ marginBottom: 10 }}
              color={projectInfo.subcontractorContractColor}
            >
              Sub Contracts
            </Label>
          </div>
          <Divider />
          <div>
            <Table compact="very" basic="very" unstackable>
              <Table.Body>
                <Table.Row>
                  <Table.Cell style={{ fontWeight: "bold" }}>
                    Est. Labor (Hrs)
                  </Table.Cell>
                  <Table.Cell>
                    {projectInfo.laborHoursTotal
                      ? projectInfo.laborHoursTotal.toFixed(2)
                      : (0).toFixed(2)}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell style={{ fontWeight: "bold", color: "red" }}>
                    Remaining Labor
                  </Table.Cell>
                  <Table.Cell style={{ color: "red" }}>
                    {projectInfo.remainingLaborHoursTotal
                      ? projectInfo.remainingLaborHoursTotal.toFixed(2)
                      : (0).toFixed(2)}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell style={{ fontWeight: "bold" }}>
                    Est. Material
                  </Table.Cell>
                  <Table.Cell>
                    {formatter.format(projectInfo.materialCostTotal)}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell style={{ fontWeight: "bold", color: "red" }}>
                    Remaining Materials
                  </Table.Cell>
                  <Table.Cell style={{ color: "red" }}>
                    {formatter.format(projectInfo.remainingMaterialsTotal)}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell style={{ fontWeight: "bold" }}>
                    Est. Equipment
                  </Table.Cell>
                  <Table.Cell>
                    {formatter.format(projectInfo.equipmentCostTotal)}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell style={{ fontWeight: "bold", color: "red" }}>
                    Remaining Equipment
                  </Table.Cell>
                  <Table.Cell style={{ color: "red" }}>
                    {formatter.format(projectInfo.remainingEquipmentTotal)}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ProjectInfo;
