import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button, Header, Icon } from "semantic-ui-react";
import { API_ROOT } from "../../../api-config";
import useQueryString from "../../../hooks/useQueryString";
import ProjectDispatch from "./ProjectDispatch/ProjectDispatch";
import TeamMemberDispatch from "./TeamMemberDispatch/TeamMemberDispatch";

const DispatchWrapper = () => {
  const [view, setView] = useState("teamMember");
  const [assignmentDate, setAssignmentDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [displayDate, setDisplayDate] = useState();

  const [data, setData] = useState();

  const [queryStringDate, setQueryStringDate] = useQueryString(
    "assignmentDate"
  );

  const [queryStringView, setQueryStringView] = useQueryString("v");

  useEffect(() => {
    setAssignmentDate(queryStringDate);
  }, [queryStringDate]);

  useEffect(() => {
    setView(queryStringView);
  }, [queryStringView]);

  useEffect(() => {
    if (view && assignmentDate) {
      if (view === "teamMember") {
        Axios.get(
          `${API_ROOT}/api/acumatica/dispatch-by-team-member?assignmentDate=${assignmentDate}`
        )
          .then((res) => {
            setData(res.data);
          })
          .catch((err) => console.log(err));
      } else if (view === "project") {
        Axios.get(
          `${API_ROOT}/api/acumatica/dispatch-by-project?assignmentDate=${assignmentDate}`
        )
          .then((res) => {
            setData(res.data);
          })
          .catch((err) => console.log(err));
      }
    }
  }, [view, assignmentDate]);

  useEffect(() => {
    if (assignmentDate) {
      setDisplayDate(moment(assignmentDate).format("dddd MM/DD/YY"));
    }
  }, [assignmentDate]);

  const handleRightArrowClick = () => {
    setQueryStringDate(
      moment(assignmentDate)
        .add(1, "d")
        .format("YYYY-MM-DD")
    );
  };

  const handleLeftArrowClick = () => {
    setQueryStringDate(
      moment(assignmentDate)
        .add(-1, "d")
        .format("YYYY-MM-DD")
    );
  };

  return (
    <Fragment>
      <div
        style={{
          width: "100%",
          background: "#2185d0",
          paddingTop: 16,
          paddingBottom: 10,
        }}
      >
        <Header as="h3" textAlign="center">
          <span style={{ color: "white" }}>
            <Icon
              // onClick={() => setWeekDiff(weekDiff - 1)}
              onClick={handleLeftArrowClick}
              link
              size="large"
              name="angle left"
            />
            <div
              style={{ width: 200, display: "inline-block" }}
            >{` ${displayDate} `}</div>

            <Icon
              // onClick={() => setWeekDiff(weekDiff + 1)}
              onClick={handleRightArrowClick}
              link
              size="large"
              name="angle right"
            />
          </span>
        </Header>
      </div>
      <Button.Group size="large" fluid style={{ borderRadius: 0 }}>
        <Button
          color={view === "teamMember" ? "orange" : null}
          style={{ borderRadius: 0 }}
          onClick={() => setQueryStringView("teamMember")}
          active={view === "teamMember"}
        >
          Person
        </Button>
        <Button
          color={view === "project" ? "orange" : null}
          style={{ borderRadius: 0 }}
          onClick={() => setQueryStringView("project")}
          active={view === "project"}
        >
          Project
        </Button>
        <Button
          style={{ borderRadius: 0 }}
          color="grey"
          as={Link}
          to="/m/projects"
        >
          Project List
        </Button>
      </Button.Group>

      <div
        style={{
          overflowY: "auto",
          height: "100%",
          maxHeight: "calc(100vh - 180px)",
        }}
      >
        {view === "teamMember" ? (
          <TeamMemberDispatch data={data} />
        ) : (
          <ProjectDispatch data={data} />
        )}
      </div>
    </Fragment>
  );
};

export default DispatchWrapper;
