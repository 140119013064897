import React, { useEffect, useState } from "react";
import KPI from "./KPI";
import { withRouter } from "react-router-dom";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";
const queryString = require("query-string");

const KPIWrapper = (props) => {
  const [quarter, setQuarter] = useState();
  const [year, setYear] = useState();
  const [KPIData, setKPIData] = useState();
  const [NPSByDivision, setNPSByDivision] = useState();
  const [NPSByBidType, setNPSByBidType] = useState();
  const [NPSByCompany, setNPSByCompany] = useState();
  const [surveyLeaderboard, setSurveyLeaderboard] = useState();
  const [profImageLeaderboard, setProfImageLeaderboard] = useState();
  const [tableData, setTableData] = useState({});
  const [timelyInvoicingSummary, setTimelyInvoicingSummary] = useState(null);

  useEffect(() => {
    const { q, y } = queryString.parse(props.location.search);
    if (quarter !== q) {
      setQuarter(parseInt(q));
    }
    if (year !== y) {
      setYear(parseInt(y));
    }
  });

  const getKPIData = () => {
    Axios.get(`${API_ROOT}/api/kpi/kpi`, {
      params: {
        quarter,
        year,
      },
    })
      .then((res) => {
        if (!!res.data.kpi) {
          setKPIData(res.data.kpi);
        } else {
          setKPIData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTimelyInvoicing = async () => {
    try {
      const results = await Axios.get(`${API_ROOT}/api/kpi/timely-invoicing`, {
        params: {
          quarter,
          year,
        },
      }).then((results) => results.data);

      setTimelyInvoicingSummary(results);
    } catch (e) {}
  };

  const getNPSByDivision = () => {
    Axios.get(`${API_ROOT}/api/kpi/nps-by-division`)
      .then((res) => {
        if (!!res.data.NPSByDivision) {
          setNPSByDivision(res.data.NPSByDivision);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNPSByBidType = () => {
    Axios.get(`${API_ROOT}/api/kpi/nps-by-bid-type`)
      .then((res) => {
        if (!!res.data.NPSByBidType) {
          setNPSByBidType(res.data.NPSByBidType);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNPSByCompany = () => {
    Axios.get(`${API_ROOT}/api/kpi/nps-by-company`)
      .then((res) => {
        if (!!res.data.NPSByCompany) {
          setNPSByCompany(res.data.NPSByCompany);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSurveyLeaderboard = () => {
    Axios.get(`${API_ROOT}/api/kpi/survey-leaderboard`, {
      params: {
        quarter,
        year,
      },
    })
      .then((res) => {
        if (!!res.data) {
          setSurveyLeaderboard(res.data);
        } else {
          setSurveyLeaderboard(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProfImageLeaderboard = () => {
    Axios.get(`${API_ROOT}/api/kpi/prof-image-leaderboard`, {
      params: {
        quarter,
        year,
      },
    })
      .then((res) => {
        if (!!res.data) {
          setProfImageLeaderboard(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTableData = () => {
    Axios.get(`${API_ROOT}/api/kpi/data-tables`, {
      params: {
        quarter,
        year,
      },
    })
      .then((res) => {
        if (!!res.data) {
          // setProfImageLeaderboard(res.data);
          setTableData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(async () => {
    if (!!quarter && !!year) {
      await getKPIData();
      await getTimelyInvoicing();
      getNPSByDivision();
      getNPSByBidType();
      getNPSByCompany();
      getSurveyLeaderboard();
      getProfImageLeaderboard();
      getTableData();
    }
  }, [quarter, year]);

  const NPSData = { NPSByDivision, NPSByBidType, NPSByCompany };

  return (
    <div>
      <KPI
        KPIData={KPIData}
        NPSData={NPSData}
        surveyLeaderboard={surveyLeaderboard}
        fleet={profImageLeaderboard ? profImageLeaderboard.fleet : null}
        warehouse={profImageLeaderboard ? profImageLeaderboard.warehouse : null}
        uniform={profImageLeaderboard ? profImageLeaderboard.uniform : null}
        safety={profImageLeaderboard ? profImageLeaderboard.safety : null}
        timelyInvoicingSummary={
          timelyInvoicingSummary ? timelyInvoicingSummary : null
        }
        tableData={tableData}
      />
    </div>
  );
};

export default withRouter(KPIWrapper);
