import React from "react";
import { AgGridReact } from "ag-grid-react";
import { columnDefs } from "./columnDefs";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const PositionIncentive = ({ rowData, save }) => {
  const handleChange = (params) => {
    const { Year, Quarter, PositionID } = params.data;
    const value = params.newValue;
    // console.log(year, quarter, empuserid, division, value);
    save(Year, Quarter, null, PositionID, value, "stars");
  };

  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: "calc(100vh - 170px)",
        width: "100%",
        minHeight: 300,
      }}
    >
      <AgGridReact
        enableCellChangeFlash={true}
        defaultColDef={{ sortable: false }}
        suppressRowDrag={true}
        suppressMovableColumns={true}
        lockPinned={true}
        columnDefs={columnDefs}
        rowData={rowData}
        rowSelection="multiple"
        onCellValueChanged={(params) => {
          handleChange(params);
        }}
        onGridReady={(params) => {
          params.api.sizeColumnsToFit();
          window.onresize = () => {
            params.api.sizeColumnsToFit();
          };
        }}
      />
    </div>
  );
};

export default PositionIncentive;
