import React, { useState, useEffect, createContext } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";

import { getProject } from "../../api/project";

dayjs.extend(require("dayjs/plugin/customParseFormat"));
dayjs.extend(require("dayjs/plugin/isSameOrAfter"));

const CurrentProject = createContext({});
CurrentProject.displayName = "CurrentProject";
export default CurrentProject;

export const CurrentProjectProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState();
  const { id } = useParams();

  const init = async () => {
    setLoading(true);
    // get the details of this project
    getProject(id)
      .then((results) => setProject(results))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    init(id);
  }, [id]);

  const value = {
    loading,
    project,
    useV2: () => project && project.api_version === "v2",
  };

  return (
    <CurrentProject.Provider value={value}>{children}</CurrentProject.Provider>
  );
};
