import React, { useState } from "react";
import {
  Table,
  Popup,
  Icon,
  Input,
  Label,
  Dropdown,
  Modal,
  Header,
  Button,
} from "semantic-ui-react";
import Moment from "moment";
import { API_ROOT } from "../../../../../api-config";
import Axios from "axios";
// import { useDropzone } from "react-dropzone";
import UploadSubContractPopup from "./UploadSubContractPopup";
import { useSelector } from "react-redux";
import { userIsInRole } from "../../../../../util";
import PushContractModal from "./PushContractModal/PushContractModal";
// import PreviewContractPDF from "../../PreviewContractPDF/PreviewContractPDF";

const PendingSubContracts = (props) => {
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const [contractGUIDToDeactivate, setContractGUIDToDeactivate] = useState(
    null
  );
  // const [pushContractModalOpen, setPushContractModalOpen] = useState(false);

  const userIsContractApprover = useSelector((state) =>
    userIsInRole(state, 10)
  );

  // const [pdfLoading, setPdfLoading] = useState(false);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  // const proposalUploadInput = useRef(null);

  const openDeactivateContractModal = (guid) => {
    setContractGUIDToDeactivate(guid);
    setDeactivateModalOpen(true);
  };

  const deactivateContract = () => {
    const guid = contractGUIDToDeactivate;
    Axios.post(`${API_ROOT}/api/contract/subcontractinactive`, {
      params: {
        guid,
      },
    })
      .then((res) => {
        props.loadContracts(res);
        setDeactivateModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const previewPDF = (recipient, guid, companyID) => {
  //   setPdfLoading(true);

  //   Axios.get(`${API_ROOT}/contract/previewcontract`, {
  //     params: {
  //       recipient,
  //       guid,
  //       companyID,
  //     },
  //     responseType: "blob",
  //   })
  //     .then((res) => {
  //       setPdfLoading(false);
  //       const file = new Blob([res.data], { type: "application/pdf" });
  //       const fileURL = URL.createObjectURL(file);
  //       window.open(fileURL);
  //     })
  //     .catch((err) => {
  //       setPdfLoading(false);
  //       console.log(err);
  //     });
  // };

  // const onDrop = useCallback(acceptedFile => {
  //   const guid = proposalUploadInput.current.dataset.guid;

  //   props.uploadSubContract(acceptedFile, guid);
  // }, []);

  // const {
  //   isDragActive,
  //   getRootProps,
  //   getInputProps
  //   // isDragReject,
  //   // acceptedFiles,
  //   // rejectedFiles
  // } = useDropzone({
  //   onDrop,
  //   accept: "application/pdf",
  //   minSize: 0
  // });

  const { pendingContracts, handleInclusionChange } = props;

  return (
    <React.Fragment>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="2" style={{ color: "rgb(254, 80, 0)" }}>
            Pending Contracts
          </Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Array.isArray(pendingContracts) && pendingContracts.length ? (
          pendingContracts[0].SubContractor.map((sub) => {
            return (
              <React.Fragment key={sub.companyname}>
                {sub.SubContracts.map((contract) => {
                  return contract.displayType === "data" ? (
                    <Table.Row key={contract.GUID}>
                      <Table.Cell>
                        <Label
                          color={contract.SubContractSentInd ? "grey" : "red"}
                        >
                          {contract.SubContractType}
                        </Label>
                        <Popup
                          content="Deactivate Contract"
                          trigger={
                            <Icon
                              color="red"
                              link
                              name="unlink"
                              onClick={() =>
                                openDeactivateContractModal(contract.GUID)
                              }
                            />
                          }
                        />
                        {userIsContractApprover ? (
                          <PushContractModal
                            setUnsignedContract={props.setUnsignedContract}
                            contract={contract}
                          />
                        ) : null}
                      </Table.Cell>
                      <Table.Cell>{contract.CompanyName}</Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.CompanyName !== "Placeholder" ? (
                          <>
                            <Popup
                              position="top center"
                              content="Upload Bid"
                              trigger={
                                <Icon
                                  link
                                  onClick={() =>
                                    props.handleBidIconClick(contract.GUID)
                                  }
                                  name="cloud upload"
                                  // size="large"
                                />
                              }
                            />
                            {contract.BidDate ? (
                              <Popup
                                position="top center"
                                content="Download Bid"
                                trigger={
                                  <Icon
                                    // size="large"
                                    name="download"
                                    link
                                    onClick={() =>
                                      props.downloadBid(contract.GUID)
                                    }
                                  />
                                }
                              />
                            ) : null}
                          </>
                        ) : null}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.CompanyName !== "Placeholder" ? (
                          <Popup
                            content={
                              contract.SubContractSentInd
                                ? "Proposal has been sent. Click to resend."
                                : "Send Proposal"
                            }
                            position="top center"
                            trigger={
                              contract.SubContractSentInd ? (
                                <Icon
                                  onClick={() =>
                                    props.checkSubContractReady(
                                      contract.GUID,
                                      contract.CompanyID
                                    )
                                  }
                                  link
                                  color="green"
                                  name="check"
                                />
                              ) : (
                                <Icon
                                  onClick={() =>
                                    props.checkSubContractReady(
                                      contract.GUID,
                                      contract.CompanyID
                                    )
                                  }
                                  color="red"
                                  link
                                  // size="large"
                                  name="send"
                                />
                              )
                            }
                          />
                        ) : null}
                      </Table.Cell>

                      <Table.Cell textAlign="center">
                        <UploadSubContractPopup
                          contract={contract}
                          uploadSubContract={props.uploadSubContract}
                        />
                      </Table.Cell>

                      <Table.Cell textAlign="center">
                        {contract.CompanyName !== "Placeholder" ? (
                          <>
                            <Popup
                              content={"View client proposal link"}
                              position="top center"
                              trigger={
                                <a
                                  href={`/contract/?r=s&id=${contract.GUID}&c=${contract.CompanyID}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Icon
                                    // size="large"
                                    link
                                    name="external alternate"
                                  />
                                </a>
                              }
                            />
                            {/* <PreviewContractPDF
                              contract={contract}
                              recipient="s"
                            /> */}
                          </>
                        ) : // <PreviewContractPDF
                        //   contract={contract}
                        //   recipient="s"
                        // />
                        null}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Input
                          style={{ minWidth: 70, width: 70 }}
                          defaultValue={contract.SubWorkDays}
                          readOnly={contract.SubContractSentInd ? true : false}
                          onBlur={(e) =>
                            props.handleDaysBlur(contract.GUID, e.target.value)
                          }
                          type="number"
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Input
                          style={{ minWidth: 80, width: 80 }}
                          defaultValue={
                            contract.Retention || contract.Retention === 0
                              ? contract.Retention.toFixed(2)
                              : (0.0).toFixed(2)
                          }
                          readOnly={contract.SubContractSentInd ? true : false}
                          onBlur={(e) =>
                            props.handleRetentionBlur(
                              contract.GUID,
                              e.target.value
                            )
                          }
                          type="number"
                        />
                        {/* {contract.Retention || contract.Retention === 0
                          ? contract.Retention.toFixed(2) + "%"
                          : "10.00%"} */}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {formatter.format(contract.Total)}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {formatter.format(contract.Invoiced)}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {formatter.format(contract.Received)}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.BidDate
                          ? Moment(contract.BidDate).format("l")
                          : ""}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.SubContractSentDate
                          ? Moment(contract.SubContractSentDate).format("l")
                          : ""}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.SubApprovalDate
                          ? Moment(contract.ApprovalDate).format("l")
                          : ""}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {contract.Inclusions[0].text ? (
                          <Dropdown
                            selection
                            selectOnNavigation={false}
                            selectOnBlur={false}
                            options={contract.Inclusions}
                            onChange={(event, data) =>
                              handleInclusionChange(event, data)
                            }
                          />
                        ) : null}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    <Table.Row
                      key={contract.GUID}
                      style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}
                    >
                      <Table.Cell colSpan="8"></Table.Cell>
                      <Table.Cell
                        style={{ color: "blue", fontWeight: "bold" }}
                        textAlign="center"
                      >
                        {formatter.format(contract.Total || 0)}
                      </Table.Cell>
                      <Table.Cell
                        style={{ color: "blue", fontWeight: "bold" }}
                        textAlign="center"
                      >
                        {formatter.format(contract.Invoiced || 0)}
                      </Table.Cell>
                      <Table.Cell
                        style={{ color: "blue", fontWeight: "bold" }}
                        textAlign="center"
                      >
                        {formatter.format(contract.Received || 0)}
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })
        ) : (
          <Table.Row style={{ borderBottom: "1px solid rgba(34,36,38,.1)" }}>
            <Table.Cell colSpan="15">No pending contracts</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>

      {/* Confirm deactivate contract modal */}
      <Modal
        basic
        size="tiny"
        open={deactivateModalOpen}
        onClose={() => setDeactivateModalOpen(false)}
      >
        <Header icon="unlink" content="Deactivate Contract" />
        <Modal.Content>
          <p>
            Are you sure you want to set this contract's status to inactive?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="red"
            inverted
            onClick={() => setDeactivateModalOpen(false)}
          >
            <Icon name="remove" /> No
          </Button>
          <Button color="green" inverted onClick={() => deactivateContract()}>
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};

export default PendingSubContracts;
