import Axios from "axios";
import React, { useState } from "react";
import { Modal, Button, Form } from "semantic-ui-react";
import { API_ROOT } from "../../../../../api-config";
import { useHistory } from "react-router-dom";

const DuplicateProjectModal = ({ open, setOpen, projectID }) => {
  const [projectName, setProjectName] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
    setProjectName("");
  };

  const handleChange = (e, { value }) => {
    setProjectName(value);
  };

  const handleClick = () => {
    setLoading(true);
    console.log(projectID, projectName);
    Axios.post(`${API_ROOT}/api/project/duplicate-project`, {
      params: {
        projectName,
        projectIDToCopy: projectID,
      },
    })
      .then((res) => {
        if (res) {
          const newProjectID = res.data[0].ProjectID;
          handleClose();
          setLoading(false);
          history.push(`/browser/projects/${newProjectID}`);
          window.location.reload();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      onClose={handleClose}
      onOpen={() => setOpen(true)}
      open={open}
      size="tiny"
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      <Modal.Header>Duplicate Project</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Input
              disabled={loading}
              label="Project Name"
              onChange={handleChange}
              value={projectName}
            />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={loading} color="black" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={loading || !projectName}
          loading={loading}
          content="Create it!"
          labelPosition="right"
          icon="checkmark"
          onClick={handleClick}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default DuplicateProjectModal;
