import React from "react";
import { Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";

const ProjectMenu = ({ projectID, projectCategories }) => {
  return (
    <Menu
      style={{
        marginTop: -1,
        borderRadius: 0,
        overflowY: "auto",
        maxHeight: "calc(100vh - 149px)",
        border: "none",
      }}
      vertical
      fluid
    >
      <Menu.Item
        as={Link}
        to={`/m/projects/${projectID}/project-info`}
        style={{ background: "#767676", color: "white", borderRadius: 0 }}
        // key={projectID}
      >
        Project Info
      </Menu.Item>
      <Menu.Item
        as={Link}
        to={`/m/projects/${projectID}/job-startup`}
        style={{ background: "#767676", color: "white", borderRadius: 0 }}
        // key={projectID}
      >
        Job Startup
      </Menu.Item>
      <Menu.Item
        as={Link}
        to={`/m/projects/${projectID}/notes`}
        style={{ background: "#767676", color: "white", borderRadius: 0 }}
        // key={projectID}
      >
        Notes
      </Menu.Item>
      {projectCategories.map((category, i) => (
        <Menu.Item
          key={category.SubCategoryCode}
          as={Link}
          to={`/m/projects/${projectID}/${category.SubCategoryCode}`}
          style={{
            background: "rgb(254, 80, 0, 0.8)",
            color: "white",
            borderRadius: 0,
          }}
        >
          {/* {`${category.rowNum}-${category.contractType} |  */}
          {category.SubCategoryCode}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default ProjectMenu;
