import React from "react";
import { Table } from "semantic-ui-react";
import "./SurveyQuestion.scss";
import SurveyOptions from "./SurveyOptions/SurveyOptions";

const SurveyQuestion = ({ question, dispatch, kpiEventID }) => {
  return (
    <Table.Row>
      <Table.Cell>
        <div>
          <h3>{question.criteria}</h3>
        </div>
        <SurveyOptions
          question={question}
          dispatch={dispatch}
          kpiEventID={kpiEventID}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default SurveyQuestion;
