import React, { Component } from "react";
import { Comment, Icon, Divider, Popup } from "semantic-ui-react";
import moment from "moment";
import { connect } from "react-redux";
import _ from "lodash";

class Note extends Component {
  state = {
    isAdmin: 0,
  };

  componentDidMount() {
    // Check to see if user is NS1Admin or Administrator
    const roles = this.props.user[0].Roles;
    const isAdmin =
      _.some(roles, { RoleID: 1 }) || _.some(roles, { RoleID: 2 });
    this.setState({
      isAdmin,
    });
  }

  render() {
    return (
      <Comment style={{ width: "97%" }}>
        <Comment.Content>
          <Comment.Text>
            <p>{this.props.text}</p>
          </Comment.Text>
          <Comment.Author as="span">{this.props.author}</Comment.Author>
          <Comment.Metadata>
            <div>
              {moment.utc(this.props.date).calendar() + " "}
              <Comment.Action>
                {this.props.includeClientInd === 1 ? (
                  <Popup
                    trigger={<Icon color="orange" name="user circle" />}
                    content="Client included"
                    size="mini"
                    position="top center"
                  />
                ) : (
                  ""
                )}
                {this.props.author === this.props.user[0].DisplayName ||
                this.state.isAdmin ? (
                  <Icon
                    onClick={() => this.props.deleteNote(this.props.noteID)}
                    link
                    name="trash alternate outline"
                  />
                ) : null}
              </Comment.Action>
            </div>
          </Comment.Metadata>
        </Comment.Content>
        <Divider />
      </Comment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: JSON.parse(state.auth.user),
  };
};

export default connect(mapStateToProps)(Note);
