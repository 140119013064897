import Axios from "axios";
import React, { useState } from "react";
import { ButtonGroup, Form, Table } from "semantic-ui-react";
import { API_ROOT } from "../../../../api-config";
import CsvDownloader from "react-csv-downloader";

const ProjectBudgetsUniversal = () => {
  const [csvData, setCsvData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    setCsvData(null);

    Axios.get(`${API_ROOT}/api/acumatica/project-budgets-universal`).then(
      (res) => {
        // console.log(res);
        if (res.data && res.data.length > 0) {
          setCsvData(res.data);
        } else {
          alert("There are no project budgets to retrieve.");
        }

        setLoading(false);
      }
    );
  };

  return (
    <Table.Row>
      <Table.Cell>Project Budgets - Universal</Table.Cell>
      <Table.Cell></Table.Cell>
      <Table.Cell>
        <ButtonGroup>
          <Form.Button
            style={{ marginRight: 10 }}
            loading={loading}
            onClick={handleClick}
          >
            Generate Report
          </Form.Button>
          {csvData ? (
            <CsvDownloader
              // columns={columns}
              datas={csvData}
              filename={`Project Budgets`}
              separator=","
              wrapColumnChar='"'
            >
              <Form.Button color="green">Export CSV</Form.Button>
            </CsvDownloader>
          ) : null}
        </ButtonGroup>
      </Table.Cell>
    </Table.Row>
  );
};

export default ProjectBudgetsUniversal;
