import React from 'react';
import { Icon, Label } from 'semantic-ui-react';
import './LabelList.scss';

const LabelList = props => {
  return (
    <div className="LabelList">
      {props.labels
        ? props.labels.map(label => {
            return (
              <Label className="Label" key={label.UserID}>
                {label.DisplayName}
                <Icon
                  onClick={() => props.deleteLabel(label.UserID)}
                  name="delete"
                />
              </Label>
            );
          })
        : ''}
    </div>
  );
};

export default LabelList;
