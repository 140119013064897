import React, { Fragment } from "react";
import { Table, Checkbox } from "semantic-ui-react";
import AlternateTableDetailRow from "./AlternateTableDetailRow";

const AlternateTableDetail = ({
  alternate,
  selectedContracts,
  setSelectedContracts,
  alternatesSelectedInd,
}) => {
  const toggleArrayItem = (value, data) => {
    const updatedContracts = selectedContracts.includes(value)
      ? selectedContracts.filter((el) => el !== value)
      : [...selectedContracts, value];
    setSelectedContracts(updatedContracts);
  };

  const { ContractType } = alternate;
  return (
    <Fragment>
      {ContractType === "Base" ? (
        <h1 style={{ color: "rgb(254, 80, 0)" }}>Base</h1>
      ) : null}
      {ContractType !== "Base" ? (
        <Checkbox
          disabled={alternatesSelectedInd}
          style={{
            fontSize: 12,
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
          label={`Select ${ContractType}`}
          onChange={(e, data) => toggleArrayItem(ContractType, data)}
          checked={selectedContracts.includes(ContractType)}
        />
      ) : null}
      <Table
        unstackable
        compact="very"
        style={{ fontSize: 12, marginBottom: 30 }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="3">
              {ContractType === "Base" ? "Contract" : null}
            </Table.HeaderCell>
            <Table.HeaderCell width="11">Scope</Table.HeaderCell>
            <Table.HeaderCell width="2">Price</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {alternate.category.map((category) => (
            <Fragment key={category.categoryCode}>
              <Table.Row
                style={{ fontSize: 12, backgroundColor: "lightyellow" }}
              >
                <Table.Cell></Table.Cell>
                <Table.Cell>
                  <strong>{category.categoryCode}</strong>
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <AlternateTableDetailRow
                contractType={ContractType}
                category={category}
                conditions={category.inclusion}
              />
            </Fragment>
          ))}
        </Table.Body>
      </Table>
      {ContractType === "Base" ? (
        <h1 style={{ color: "rgb(254, 80, 0)" }}>Alternate(s)</h1>
      ) : null}
    </Fragment>
  );
};

export default AlternateTableDetail;
