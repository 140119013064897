import {
  SET_SURVEY_MENU_SELECTION,
  RESET_SURVEY_MENU_SELECTION,
} from "../actions/surveyMenu/surveyMenuActions";
import * as moment from "moment";

const quarter = moment().quarter();
const year = moment().year();

const INITIAL_DATA = {
  quarter,
  year,
  userID: 1,
  surveyStatus: 2,
};

const surveyMenuReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case SET_SURVEY_MENU_SELECTION:
      return {
        ...state,
        quarter: action.payload.quarter,
        year: action.payload.year,
        userID: action.payload.userID,
        surveyStatus: action.payload.surveyStatus,
      };
    case RESET_SURVEY_MENU_SELECTION:
      return INITIAL_DATA;
    default:
      return state;
  }
};

export default surveyMenuReducer;
