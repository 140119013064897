import React from 'react'

export default ({ summary }) => {
  return (
    <div className="totals-summary">
      <ChangeOrderBreakout summary={summary} />

      <table className="ui compact celled table ">
        <tbody>
          {summary.contract_type === 'Base' && (
            <tr>
              <th>Approximate Project Duration</th>
              <td className="right aligned">
                {summary.work_days && `${summary.work_days} Days`}
                {!summary.work_days && '\u00A0'}
              </td>
            </tr>
          )}
          <tr>
            <th>Net Payment Due</th>
            <td className="right aligned">30 Days</td>
          </tr>
          <tr>
            <th>Retention</th>
            <td className="right aligned">
              {!summary.retention && summary.retention !== 0
                ? ''
                : summary.retention + '%'}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const ChangeOrderBreakout = ({ summary }) => {
  if (summary.contract_type === 'Base') return null

  return (
    <table className="ui compact definition table change-order-summary">
      <thead>
        <tr>
          <th></th>
          <th className="center aligned">Price</th>
          <th className="center aligned">Duration (Days)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Original Contract Amount</th>
          <td className="right aligned price">
            {summary.original_contract_amount
              ? new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(summary.original_contract_amount)
              : ''}
          </td>
          <td className="right aligned">
            {summary.original_contract_work_days}
          </td>
        </tr>
        <tr>
          <th>Net Change from Previous Change Orders</th>
          <td className="right aligned price">
            {summary.net_change
              ? new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(summary.net_change)
              : '$0.00'}
          </td>
          <td className="right aligned">{summary.net_change_work_days}</td>
        </tr>
        <tr>
          <th>Contract Amount Prior to Current Change Order</th>
          <td className="right aligned ">
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(
              (summary.original_contract_amount
                ? summary.original_contract_amount
                : 0) + (summary.net_change ? summary.net_change : 0),
            )}
          </td>
          <td className="right aligned">
            {summary.original_contract_work_days + summary.net_change_work_days}
          </td>
        </tr>
        <tr>
          <th>Current Change Order Amount</th>
          <td className="right aligned price">
            {' '}
            {summary.grand_total
              ? new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(summary.grand_total)
              : '$0.00'}
          </td>
          <td className="right aligned">{summary.work_days}</td>
        </tr>
        <tr>
          <th>Adjusted Contract Amount</th>
          <td className="right aligned">
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(
              (summary.original_contract_amount
                ? summary.original_contract_amount
                : 0) +
                (summary.net_change ? summary.net_change : 0) +
                (summary.grand_total ? summary.grand_total : 0),
            )}
          </td>
          <td className="right aligned">
            {summary.original_contract_work_days +
              summary.net_change_work_days +
              summary.work_days}
          </td>
        </tr>
      </tbody>
    </table>
  )
}
