import Axios from "axios";
import moment from "moment";
import React, { forwardRef, useState } from "react";
import { ButtonGroup, Form, Table } from "semantic-ui-react";
import { API_ROOT } from "../../../../api-config";
import CsvDownloader from "react-csv-downloader";
import ReactDatePicker from "react-datepicker";

const UnbilledProjects = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [csvData, setCsvData] = useState(null);
  const [loading, setLoading] = useState(false);
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick}>
      <Form>
        <Form.Input value={value} className="example-custom-input" />
      </Form>
    </div>
  ));

  const handleClick = () => {
    setLoading(true);
    setCsvData(null);

    const month = moment(startDate).month() + 1;
    const year = moment(startDate).year();

    Axios.get(
      `${API_ROOT}/api/project/unbilled-projects?month=${month}&year=${year}`
    ).then((res) => {
      // console.log(res);
      if (res.data && res.data.length > 0) {
        setCsvData(res.data);
      } else {
        alert("There are no unbilled projects for the selected month.");
      }

      setLoading(false);
    });
  };

  return (
    <Table.Row>
      <Table.Cell>Unbilled Projects</Table.Cell>
      <Table.Cell>
        <ReactDatePicker
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            setCsvData(null);
          }}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          customInput={<CustomInput />}
        />
      </Table.Cell>
      <Table.Cell>
        <ButtonGroup>
          <Form.Button
            style={{ marginRight: 10 }}
            loading={loading}
            onClick={handleClick}
          >
            Generate Report
          </Form.Button>
          {csvData ? (
            <CsvDownloader
              // columns={columns}
              datas={csvData}
              filename={`Unbilled Projects - ${moment(startDate).format(
                "MMMM YYYY"
              )}`}
              separator=","
              wrapColumnChar='"'
            >
              <Form.Button color="green">Export CSV</Form.Button>
            </CsvDownloader>
          ) : null}
        </ButtonGroup>
      </Table.Cell>
    </Table.Row>
  );
};

export default UnbilledProjects;
