import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table } from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import CriteriaOptions from "./CriteriaOptions";

const AddInspectionModal = ({ open, setOpen, getWarehouseData }) => {
  const [KPIEventID, setKPIEventID] = useState("");
  const [criteria, setCriteria] = useState([]);
  const [surveyDate, setSurveyDate] = useState("");
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    setKPIEventID(uuidv4());
  }, [open]);

  useEffect(() => {
    getKPICriteria();
  }, []);

  const getKPICriteria = () => {
    Axios.get(`${API_ROOT}/api/kpi/criteria?type=Warehouse`)
      .then((res) => {
        setCriteria(res.data.criteria);
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setOpen(false);
    setSurveyDate(null);
    if (edited) {
      getWarehouseData(true);
    }
    setEdited(false);
  };

  return (
    <Modal
      closeOnDimmerClick={false}
      open={open}
      onClose={handleClose}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>Uniform and Presentation Checklist</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group width={8}>
            <SemanticDatepicker
              datePickerOnly={true}
              format="MM-DD-YYYY"
              label={
                <span>
                  Survey Date{" "}
                  <span
                    style={{
                      color: "#db2828",
                    }}
                  >
                    *
                  </span>
                </span>
              }
              onChange={(e, { value }) => setSurveyDate(value)}
            />
          </Form.Group>
        </Form>
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Criteria</Table.HeaderCell>
              <Table.HeaderCell>Score</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Array.isArray(criteria) && criteria.length
              ? criteria.map((item) => (
                  <Table.Row key={item.ID}>
                    <Table.Cell>
                      {item.RowNumber + ") " + item.Criteria}
                    </Table.Cell>
                    <Table.Cell>
                      <CriteriaOptions
                        setEdited={setEdited}
                        surveyDate={surveyDate}
                        KPIEventID={KPIEventID}
                        options={item.Options}
                        id={item.ID}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))
              : null}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AddInspectionModal;
