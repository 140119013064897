import React, { useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import {
  setIncentivePayoutMenuSelection,
  resetIncentivePayoutMenuSelection,
} from "../../../../actions/topMenu/incentivePayoutMenuActions";

const START_YEAR = 2019;
let date = moment();
let currentYear = date.year();

function generate_year_range(start, end) {
  var years = [];
  for (var year = start; year <= end; year++) {
    years.push(year);
  }
  return years;
}

const IncentivePayout = (props) => {
  const year = useSelector((state) => state.incentivePayout.year);
  const dispatch = useDispatch();

  var my_years = generate_year_range(START_YEAR, currentYear).sort(
    (a, b) => b - a
  );

  const optionsArrays = my_years.map((y) => {
    return { key: y, text: y, value: y };
  });

  useEffect(() => {
    dispatch(resetIncentivePayoutMenuSelection());
  }, []);

  const options = [].concat(...optionsArrays);

  const handleDateChange = (data) => {
    const selection = {
      year: data.value,
    };
    dispatch(setIncentivePayoutMenuSelection(selection));
  };

  return (
    <Dropdown
      scrolling
      selectOnBlur={false}
      text={`Showing ${year}`}
      value={year}
      item
      options={options}
      onChange={(e, data) => handleDateChange(data)}
    />
  );
};

export default IncentivePayout;
