import React, { useState } from "react";
import { Modal, Header } from "semantic-ui-react";
import {
  LinearGauge,
  Scale,
  Label,
  SubvalueIndicator,
} from "devextreme-react/linear-gauge";
import {
  CircularGauge,
  ValueIndicator,
  Geometry,
} from "devextreme-react/circular-gauge";

const DescriptionModal = ({ item }) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal
      // basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      closeIcon
      trigger={
        <div>
          <LinearGauge
            animation={{ enabled: false }}
            style={{ display: "inline-block" }}
            size={{ width: 120, height: 360 }}
            title={{
              text: item.kpi,
              font: {
                size: 18,
              },
            }}
            geometry={{ orientation: "vertical" }}
            valueIndicator={{
              type: "rangeBar",
              color: "rgb(254, 80, 0)",
              offset: 10,
              size: 10,
              text: { indent: 15, format: "percent" },
            }}
            id="c1"
            value={item.score}
            subvalues={[item.threshold, item.goal]}
            scale={{
              startValue: 0,
              endValue: 1,
              tickInterval: 0.2,
              label: { format: "percent" },
            }}
            tooltip={{
              enabled: true,
              format: "percent",
              precision: 2,
            }}
            subvalueIndicator={{
              type: "triangleMarker",
              color: "rgb(254, 80, 0)",
            }}
            rangeContainer={{
              ranges: [
                {
                  startValue: 0,
                  endValue: item.threshold,
                  color: "gray",
                },
                {
                  startValue: item.threshold,
                  endValue: 1,
                  color: "white",
                },
              ],
            }}
          >
            <Scale startValue={10} endValue={50} tickInterval={10}>
              <Label></Label>
            </Scale>
            <SubvalueIndicator
              type="rectangle"
              color="#9B870C"
            ></SubvalueIndicator>
          </LinearGauge>
          <CircularGauge
            animation={{ enabled: false }}
            size={{ height: 160 }}
            id="rangebar"
            value={item.payoutpercent ? parseInt(item.payoutpercent) : 0}
            title={{
              text: item.payoutpercent ? item.payoutpercent + "%" : "0%",
              font: {
                size: 28,
                weight: 900,
              },
              verticalAlignment: "bottom",
            }}
          >
            <ValueIndicator
              type="rangebar"
              color="rgb(254, 80, 0)"
            ></ValueIndicator>
            <Scale startValue={0} endValue={100} tickInterval={50}></Scale>
            <Geometry startAngle={180} endAngle={0}></Geometry>
          </CircularGauge>
          <span style={{ color: "white" }}>Bonus Eligible</span>
        </div>
      }
    >
      <Header>{item.kpi}</Header>
      <Modal.Content>
        <p dangerouslySetInnerHTML={{ __html: item.KPIDescription }}></p>
      </Modal.Content>
    </Modal>
  );
};

export default DescriptionModal;
