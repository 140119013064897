import React, { Component } from "react";
// import InclusionsApproved from './InclusionsApproved/InclusionsApproved';
import { withRouter, Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { setInclusionMenuSelection } from "../../../actions/project/inclusion/inclusionActions";
import InclusionTable from "./InclusionTable/InclusionTable";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";
import AlternatesWrapper from "./Alternates/AlternatesWrapper";

class Inclusions extends Component {
  state = {
    inclusions: [],
    inclusionSummary: [],
  };
  handleNewInclusionClick = () => {
    this.props.setInclusionMenuSelection({
      selectedCategoryID: "",
      selectedSubCategoryCode: "",
    });
  };

  fetchData = () => {
    if (this.props.match.params.id) {
      Axios.get(`${API_ROOT}/api/inclusion`, {
        params: {
          projectID: parseInt(this.props.match.params.id),
        },
      })
        .then((res) => {
          if (this._ismounted) {
            this.setState({
              inclusions: res.data.inclusion
                ? res.data.inclusion[0].inclusions
                : [],
              inclusionSummary: res.data.inclusion
                ? res.data.inclusion[0].inclusionSummary
                : [],
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  componentDidMount() {
    this.fetchData();
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  render() {
    const approved = this.state.inclusions
      ? this.state.inclusions.filter(
          (inclusion) => inclusion.contractStageCategory === "Approved"
        )
      : [];
    const pending = this.state.inclusions
      ? this.state.inclusions.filter(
          (inclusion) => inclusion.contractStageCategory === "Pending"
        )
      : [];
    const inactive = this.state.inclusions
      ? this.state.inclusions.filter(
          (inclusion) => inclusion.contractStageCategory === "Inactive"
        )
      : [];
    const potentialCO = this.state.inclusions
      ? this.state.inclusions.filter(
          (inclusion) => inclusion.contractStageCategory === "Potential CO"
        )
      : [];

    const approvedSummary = this.state.inclusionSummary
      ? this.state.inclusionSummary.filter(
          (summary) => summary.contractStageCategory === "Approved"
        )
      : [];
    const pendingSummary = this.state.inclusionSummary
      ? this.state.inclusionSummary.filter(
          (summary) => summary.contractStageCategory === "Pending"
        )
      : [];
    const inactiveSummary = this.state.inclusionSummary
      ? this.state.inclusionSummary.filter(
          (summary) => summary.contractStageCategory === "Inactive"
        )
      : [];
    const potentialCOSummary = this.state.inclusionSummary
      ? this.state.inclusionSummary.filter(
          (summary) => summary.contractStageCategory === "Potential CO"
        )
      : [];
    return (
      <div
        style={{ overflow: "auto", paddingLeft: "1rem", paddingRight: "1rem" }}
      >
        <Button
          size="tiny"
          color="teal"
          style={{ marginLeft: 30, marginTop: 20 }}
          icon
          labelPosition="left"
          onClick={this.handleNewInclusionClick}
          as={Link}
          to={`/browser/projects/inclusions/${this.props.match.params.id}`}
        >
          <Icon name="add" />
          Add new inclusion
        </Button>

        {/* <InclusionsApproved /> */}
        <InclusionTable
          setInclusionMenuSelection={this.props.setInclusionMenuSelection}
          stage="Approved"
          inclusions={approved}
          summary={approvedSummary}
          propertyID={this.props.match.params.id}
        />
        <InclusionTable
          setInclusionMenuSelection={this.props.setInclusionMenuSelection}
          stage="Pending"
          inclusions={pending}
          summary={pendingSummary}
          propertyID={this.props.match.params.id}
        />
        <InclusionTable
          setInclusionMenuSelection={this.props.setInclusionMenuSelection}
          stage="Potential CO"
          inclusions={potentialCO}
          summary={potentialCOSummary}
          propertyID={this.props.match.params.id}
        />
        <AlternatesWrapper projectID={this.props.match.params.id} />
        <InclusionTable
          setInclusionMenuSelection={this.props.setInclusionMenuSelection}
          stage="Inactive"
          inclusions={inactive}
          summary={inactiveSummary}
          propertyID={this.props.match.params.id}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setInclusionMenuSelection,
    },
    dispatch
  );
};
const mapStateToProps = (state) => {
  const userArray = state.auth.user ? JSON.parse(state.auth.user) : null;
  const user =
    Array.isArray(userArray) && userArray.length ? userArray[0] : null;

  user.hasRole = (role) => {
    if (!user) return false;
    return user.Roles.filter((x) => x.Role === role).length > 0;
  };

  return {
    loggedIn: state.auth.loggedIn,
    user,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Inclusions)
);
