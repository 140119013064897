import React, { useEffect, useState } from "react";
import usePrevious from "../../../../hooks/usePrevious";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import FleetContent from "./FleetContent/FleetContent";
import UniformContent from "./UniformContent/UniformContent";
import WarehouseContent from "./WarehouseContent/WarehouseContent";
import SafetyContent from "./SafetyContent/SafetyContent";
import { Loader, Modal } from "semantic-ui-react";

const ResultsModalContent = ({ rowData }) => {
  const [KPIEventID, setKPIEventID] = useState(null);
  const [usersOptions, setUsersOptions] = useState([]);
  const [userID, setUserID] = useState(null);
  const [type, setType] = useState(null);
  const [criteria, setCriteria] = useState([]);
  const [loading, setLoading] = useState(false);
  const [warehouseResults, setWarehouseResults] = useState([]);
  const [safetyResults, setSafetyResults] = useState([]);
  const [fleetResults, setFleetResults] = useState([]);
  const [uniformResults, setUniformResults] = useState([]);
  const [surveyDate, setSurveyDate] = useState(null);
  const [vanOptions, setVanOptions] = useState([]);
  const [location, setLocation] = useState("");
  const [van, setVan] = useState("");

  const prevKPIEvenID = usePrevious(KPIEventID);

  useEffect(() => {
    if (prevKPIEvenID !== KPIEventID) {
      setKPIEventID(rowData.KPIEventID);
      setType(rowData.Type);
      setUserID(rowData.UserID);
      setVan(rowData.van);
      setSurveyDate(rowData.SurveyDate);
      setLocation(rowData.location);
    }
  }, [rowData]);

  useEffect(() => {
    if (KPIEventID && type) {
      setLoading(true);
      getKPICriteria();
      getKPIResults();
    }
  }, [KPIEventID, type]);

  useEffect(() => {
    getUsersOptions();
    getVans();
  }, []);

  const getVans = () => {
    Axios.get(`${API_ROOT}/api/kpi/vans`)
      .then((res) => {
        setVanOptions(res.data.vans);
      })
      .catch((err) => console.log(err));
  };

  const getUsersOptions = () => {
    Axios.get(`${API_ROOT}/api/user/displaynames`)
      .then((res) => {
        const users = JSON.parse(res.data.users);
        const usersOptionsMapped = users.map((user) => {
          return {
            key: user.UserID,
            value: user.UserID,
            text: user.DisplayName,
          };
        });
        setUsersOptions(usersOptionsMapped);
      })
      .catch((err) => console.log(err));
  };

  const getKPIResults = () => {
    if (type === "Warehouse") {
      Axios.get(
        `${API_ROOT}/api/kpi/warehouse-results?KPIEventID=${KPIEventID}`
      )
        .then((res) => {
          setLoading(false);
          setWarehouseResults(res.data.results || []);
        })
        .catch((err) => console.log(err));
    } else if (type === "Fleet") {
      Axios.get(`${API_ROOT}/api/kpi/van-results?KPIEventID=${KPIEventID}`)
        .then((res) => {
          setLoading(false);
          setFleetResults(res.data.results || []);
        })
        .catch((err) => console.log(err));
    } else if (type === "Uniform") {
      Axios.get(`${API_ROOT}/api/kpi/uniform-results?KPIEventID=${KPIEventID}`)
        .then((res) => {
          setLoading(false);
          setUniformResults(res.data.results || []);
        })
        .catch((err) => console.log(err));
    } else if (type === "Safety") {
      Axios.get(`${API_ROOT}/api/kpi/safety-results?KPIEventID=${KPIEventID}`)
        .then((res) => {
          setLoading(false);
          setSafetyResults(res.data.results || []);
        })
        .catch((err) => console.log(err));
    }
  };

  const getKPICriteria = () => {
    Axios.get(
      `${API_ROOT}/api/kpi/criteria?type=${type === "Fleet" ? "Van" : type}`
    )
      .then((res) => {
        setCriteria(res.data.criteria);
      })
      .catch((err) => console.log(err));
  };

  if (loading) {
    return (
      <Modal.Content>
        <Loader active size="big" />
      </Modal.Content>
    );
  } else if (type === "Fleet") {
    return (
      <FleetContent
        criteria={criteria}
        usersOptions={usersOptions}
        userID={userID}
        van={van}
        vanOptions={vanOptions}
        results={fleetResults}
        surveyDate={surveyDate}
      />
    );
  } else if (type === "Uniform") {
    return (
      <UniformContent
        criteria={criteria}
        usersOptions={usersOptions}
        userID={userID}
        location={location}
        surveyDate={surveyDate}
        results={uniformResults}
      />
    );
  } else if (type === "Warehouse") {
    return (
      <WarehouseContent
        criteria={criteria}
        surveyDate={surveyDate}
        results={warehouseResults}
      />
    );
  } else if (type === "Safety") {
    return (
      <SafetyContent
        criteria={criteria}
        surveyDate={surveyDate}
        results={safetyResults}
      />
    );
  } else {
    return null;
  }
};

export default ResultsModalContent;
