import React from "react";
import { Grid, Icon, Menu, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
import "./ProjectList.scss";

const ProjectList = ({ projects, loading }) => {
  if (loading) {
    return null;
  }
  if (Array.isArray(projects) && projects.length) {
    return (
      <Menu
        style={{
          marginTop: -1,
          borderRadius: 0,
          overflowY: "auto",
          maxHeight: "calc(100vh - 206px)",
        }}
        vertical
        fluid
      >
        {projects.map((project) => (
          <Menu.Item
            // as={Link}
            // to={`/m/projects/${project.projectID}`}
            className="ProjectList__Menu--Item"
            style={{ background: "#767676", color: "white", borderRadius: 0 }}
            key={project.projectID}
          >
            <Grid>
              <Grid.Row>
                {/* <Link
                  to={`/m/projects/${project.projectID}`}
                  style={{ color: "white" }}
                > */}
                <Grid.Column
                  style={{ color: "white" }}
                  as={Link}
                  to={`/m/projects/${project.projectID}`}
                  width="14"
                >
                  {/* {`${project.companyName} - ${project.workLocationName}`} */}
                  {`${project.workLocationName}`}
                  <br />
                  {`${project.projectID} - ${project.projectName}`}
                </Grid.Column>
                {/* </Link> */}
                <Grid.Column width="2" style={{ textAlign: "right" }}>
                  {project.awaitingJobStartupInd ? (
                    <Popup
                      openOnTriggerClick
                      content="Job Start Up signature is missing"
                      trigger={
                        <Icon color="yellow" size="big" name="warning sign" />
                      }
                    />
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        ))}
      </Menu>
    );
  } else {
    return "No results found";
  }
};

export default ProjectList;
