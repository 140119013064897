import React from "react";
import { Table } from "semantic-ui-react";

const options = { style: "currency", currency: "USD" };
const currencyFormat = new Intl.NumberFormat("en-US", options);

const AlternateTableSummaryRow = (props) => {
  function groupBy(objectArray, property) {
    return objectArray.reduce(function(acc, obj) {
      var key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  const conditions = props.conditions;
  // const summary = props.summary;

  // const filteredConditions = conditions.filter(
  //   (item) => item.Category === props.category
  // );
  const grouped = groupBy(conditions, "subcategoryCode");
  const subCategories = [
    ...new Set(conditions.map((item) => item.subcategoryCode)),
  ];

  return (
    <>
      {subCategories.map((subCategory) => (
        <Table.Row key={subCategory}>
          <Table.Cell>{props.contractType}</Table.Cell>
          <Table.Cell>{subCategory}</Table.Cell>
          <Table.Cell style={{ color: "#fe5000", fontWeight: "bold" }}>
            {currencyFormat.format(
              grouped[subCategory].reduce((accumulator, currentValue) => {
                return (
                  parseFloat(accumulator) + parseFloat(currentValue.preTax)
                );
              }, 0)
            )}
          </Table.Cell>
          <Table.Cell>{grouped[subCategory][0].summaryDescription}</Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default AlternateTableSummaryRow;
