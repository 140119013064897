import React, { useEffect, useState } from "react";

import { Container, Divider } from "semantic-ui-react";
import { useParams } from "react-router-dom";

import axios from "../../api/axios";

import "./styles.scss";
import Heading from "./components/Heading";
import NotFound from "./components/NotFound";
import Loading from "./components/Loading";
import ProjectSummary from "./components/ProjectSummary";
import Inclusions from "./components/Inclusions";
import ClarificationsExclusions from "./components/ClarificationsExclusions";
import TotalsSummary from "./components/TotalsSummary";
import ContractRequirements from "./components/ContractRequirements";
import Signatures from "./components/Signatures";

import ContractToolbar from "../../components/ContractWrapper/ContractToolbar/ContractToolbar";

export default () => {
  const { contractId } = useParams();
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [contract, setContract] = useState();

  useEffect(() => {
    axios
      .get(`/contracts/${contractId}/client`)
      .then((results) => {
        setContract(results.data);
        setLoading(false);
      })
      .catch(() => {
        setNotFound(true);
        setLoading(false);
      });
  }, [contractId]);

  const handleSignatureUpload = async ({ approvedBy, signature }) => {
    const contract = await axios
      .post(`/contracts/${contractId}/client/signature`, {
        approvedBy,
        signature,
      })
      .then((results) => results.data);
    setContract(contract);
  };

  if (loading) return <Loading />;
  if (notFound) return <NotFound />;

  return (
    <>
      <ContractToolbar
        guid={contractId}
        approvedInd={contract.summary.approved_ind}
      />
      <Container className="client-contract-container">
        <Heading client={contract.summary.client} pm={contract.summary.pm} />
        <Divider />
        <ProjectSummary summary={contract.summary} />
        <Inclusions
          summary={contract.summary}
          inclusions={contract.inclusions}
        />
        <ClarificationsExclusions summary={contract.summary} />
        <TotalsSummary summary={contract.summary} />
        <Signatures
          summary={contract.summary}
          onSignatureUpload={handleSignatureUpload}
        />
        <ContractRequirements summary={contract.summary} />
      </Container>
    </>
  );
};
