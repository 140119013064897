export const SET_INCENTIVE_PAYOUT_MENU_SELECTION =
  "SET_PAYOUT_INCENTIVE_MENU_SELECTION";
export const RESET_INCENTIVE_PAYOUT_MENU_SELECTION =
  "RESET_PAYOUT_INCENTIVE_MENU_SELECTION";

export const setIncentivePayoutMenuSelection = (selection) => ({
  type: SET_INCENTIVE_PAYOUT_MENU_SELECTION,
  payload: selection,
});

export const resetIncentivePayoutMenuSelection = () => ({
  type: RESET_INCENTIVE_PAYOUT_MENU_SELECTION,
});
