import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Loader, Table } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
// import moment from "moment";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import CriteriaOptions from "./CriteriaOptions";
import "./EditInspectionModal.scss";

const parseDate = (input) => {
  var parts = input.match(/(\d+)/g);
  // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
};

const EditInspectionModal = ({ open, setOpen, gridApi, getFleetData }) => {
  const [usersOptions, setUsersOptions] = useState([]);
  const [vanOptions, setVanOptions] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [userID, setUserID] = useState(null);
  const [van, setVan] = useState("");
  const [surveyDate, setSurveyDate] = useState(null);
  const [KPIEventID, setKPIEventID] = useState("");
  const [loading, setLoading] = useState(false);
  const [edited, setEdited] = useState(false);
  const [results, setResults] = useState([]);

  //   var dateAsString = moment(new Date(cellValue), "YYYY-MM-DD").format(
  //     "DD/MM/YYYY"
  //   );

  useEffect(() => {
    if (open && gridApi.getSelectedRows()[0]) {
      const selectedData = gridApi.getSelectedRows();
      if (selectedData) {
        setVan(selectedData[0].Van);
        setUserID(selectedData[0].UserID);
        setSurveyDate(parseDate(selectedData[0].SurveyDate));
        setKPIEventID(selectedData[0].KPIEventID);
        Axios.get(
          `${API_ROOT}/api/kpi/van-results?KPIEventID=${selectedData[0].KPIEventID}`
        )
          .then((res) => {
            setLoading(false);
            setResults(res.data.results || []);
          })
          .catch((err) => console.log(err));
      }
    }
  }, [open, gridApi.getSelectedRows()[0]]);

  useEffect(() => {
    getUsersOptions();
    getVans();
    getKPICriteria();
  }, []);

  //   useEffect(() => {
  //     if (KPIEventID) {
  //       console.log("Get Event Details: ", KPIEventID);
  //       Axios.get(`${API_ROOT}/api/kpi/van-results?KPIEventID=${KPIEventID}`)
  //         .then((res) => {
  //           setLoading(false);
  //           setResults(res.data.results || []);
  //         })
  //         .catch((err) => console.log(err));
  //     }
  //   }, [KPIEventID]);

  const getVans = () => {
    Axios.get(`${API_ROOT}/api/kpi/vans`)
      .then((res) => {
        setVanOptions(res.data.vans);
      })
      .catch((err) => console.log(err));
  };

  const getUsersOptions = () => {
    Axios.get(`${API_ROOT}/api/user/displaynames`)
      .then((res) => {
        const users = JSON.parse(res.data.users);
        const usersOptionsMapped = users.map((user) => {
          return {
            key: user.UserID,
            value: user.UserID,
            text: user.DisplayName,
          };
        });
        setUsersOptions(usersOptionsMapped);
      })
      .catch((err) => console.log(err));
  };

  const getKPICriteria = () => {
    Axios.get(`${API_ROOT}/api/kpi/criteria?type=Van`)
      .then((res) => {
        setCriteria(res.data.criteria);
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setOpen(false);
    setSurveyDate(null);
    setVan(null);
    if (edited) {
      getFleetData(true);
    }
    setEdited(false);
  };

  if (loading) {
    return <Loader active inline="centered" />;
  }
  return (
    <Modal
      className="EditInspectionModal"
      open={open}
      closeOnDimmerClick={false}
      onClose={handleClose}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>Fleet Checklist</Modal.Header>
      <Modal.Content>
        {loading ? (
          <Loader inverted active inline="centered" />
        ) : (
          <>
            <Form>
              <Form.Group widths="equal">
                <Form.Dropdown
                  style={{ opacity: 1 }}
                  selectOnBlur={false}
                  // disabled

                  search
                  selection
                  label="Team Member"
                  //   onChange={(e, { value }) => setUserID(value)}
                  value={userID}
                  options={usersOptions}
                />
                <Form.Dropdown
                  selectOnBlur={false}
                  selection
                  //   disabled
                  label="Vehicle"
                  value={van}
                  //   onChange={(e, { value }) => setVan(value)}
                  options={vanOptions}
                />
                <SemanticDatepicker
                  datePickerOnly={true}
                  readOnly
                  clearable={false}
                  format="MM-DD-YYYY"
                  label="Survey Date"
                  value={surveyDate}
                  //   onChange={(e, { value }) => false}
                />
              </Form.Group>
            </Form>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Criteria</Table.HeaderCell>
                  <Table.HeaderCell>Score</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Array.isArray(criteria) && criteria.length
                  ? criteria.map((item) => (
                      <Table.Row key={item.ID}>
                        <Table.Cell>
                          {item.RowNumber + ") " + item.Criteria}
                        </Table.Cell>
                        <Table.Cell>
                          <CriteriaOptions
                            open={open}
                            result={
                              results
                                ? results.find(
                                    (result) => result.CriteriaID === item.ID
                                  )
                                : null
                            }
                            setEdited={setEdited}
                            userID={userID}
                            van={van}
                            surveyDate={surveyDate}
                            KPIEventID={KPIEventID}
                            options={item.Options}
                            id={item.ID}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))
                  : null}
              </Table.Body>
            </Table>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EditInspectionModal;
