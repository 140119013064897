import React, { Fragment, useEffect, useState } from "react";
import { Dimmer, Icon, Loader, Table } from "semantic-ui-react";
import Axios from "axios";
import "../Tracking.scss";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setInclusionMenuSelection } from "../../../../actions/project/inclusion/inclusionActions";
import { API_ROOT } from "../../../../api-config";
import useIsMounted from "../../../../hooks/isMounted";
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const CostManagement = ({ projectID }) => {
  const [loading, setLoading] = useState(false);
  const [totalsLoading, setTotalsLoading] = useState(false);
  const [data, setData] = useState();
  const [totals, setTotals] = useState();
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [conditionDetail, setConditionDetail] = useState([]);

  useEffect(() => {
    if (selectedSubcategories.length) {
      const subcategories = selectedSubcategories.join("|");
      console.log(projectID, subcategories);
      Axios.get(`${API_ROOT}/api/project/cost-management-detail`, {
        params: {
          projectID,
          subcategories,
        },
      })
        .then((res) => {
          if (res) {
            console.log(res);
            setConditionDetail(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [projectID, selectedSubcategories]);

  const isMounted = useIsMounted();

  const dispatch = useDispatch();

  // console.log(selectedSubcategories);

  const fetchData = (projectID) => {
    Axios.get(`${API_ROOT}/api/project/cost-management`, {
      params: {
        projectID,
      },
    })
      .then((res) => {
        if (res.data && isMounted) {
          setLoading(false);
          setData(res.data);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setLoading(false);
          console.log(err);
        }
      });
  };

  const fetchTotals = (projectID) => {
    Axios.get(`${API_ROOT}/api/project/cost-management-totals`, {
      params: {
        projectID,
      },
    })
      .then((res) => {
        if (res.data && isMounted) {
          setTotals(res.data);
          setTotalsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setTotalsLoading(false);
          console.log(err);
        }
      });
  };

  useEffect(() => {
    if (projectID) {
      fetchData(projectID);
      fetchTotals(projectID);
    }
  }, [projectID]);

  return loading || totalsLoading ? (
    <Dimmer active inverted>
      <Loader size="large" inverted>
        Loading
      </Loader>
    </Dimmer>
  ) : (
    <Table compact="very" unstackable style={{ fontSize: 12 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell
            textAlign="center"
            // className="border-left"
            colSpan={5}
          >
            Labor
          </Table.HeaderCell>

          <Table.HeaderCell
            textAlign="center"
            className="border-left"
            colSpan={2}
          >
            Materials
          </Table.HeaderCell>

          <Table.HeaderCell
            textAlign="center"
            className="border-left"
            colSpan={2}
          >
            Equipment
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="center"
            className="border-left"
            colSpan={2}
          >
            Other
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="center"
            className="border-left"
            colSpan={2}
          >
            Sub
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row style={{ fontSize: 9 }}>
          <Table.HeaderCell />
          <Table.HeaderCell textAlign="right">Original Hrs</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Pending Hrs</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Actual Hrs</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Original Cost</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Actual Cost</Table.HeaderCell>
          <Table.HeaderCell textAlign="right" className="border-left">
            Original Cost
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Actual</Table.HeaderCell>
          <Table.HeaderCell textAlign="right" className="border-left">
            Original Cost
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Actual</Table.HeaderCell>
          <Table.HeaderCell textAlign="right" className="border-left">
            Original Cost
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Actual</Table.HeaderCell>
          <Table.HeaderCell textAlign="right" className="border-left">
            Original Cost
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Actual</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Array.isArray(data) && data.length
          ? data.map((category) => (
              <Fragment key={category.categoryCode}>
                <Table.Row>
                  <Table.Cell
                    style={{
                      color: "rgb(254, 80, 0)",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                    colSpan={1}
                  >
                    {category.categoryCode
                      ? category.categoryCode.toUpperCase()
                      : ""}
                  </Table.Cell>
                  <Table.Cell colSpan={13} />
                </Table.Row>
                {category.subcategories.map((subCategory) => (
                  <Fragment key={subCategory.subCategoryCode}>
                    <Table.Row>
                      <Table.Cell style={{ paddingRight: 20 }}>
                        {subCategory.subCategoryCode ===
                        "Total" ? null : selectedSubcategories.some(
                            (item) => item === subCategory.subCategoryCode
                          ) ? (
                          <Icon
                            size="large"
                            loading={
                              !conditionDetail.some(
                                (e) =>
                                  e.SubCategoryCode ===
                                  subCategory.subCategoryCode
                              )
                            }
                            onClick={() => {
                              setSelectedSubcategories(
                                selectedSubcategories.filter(
                                  (i) => i !== subCategory.subCategoryCode
                                )
                              );
                              setConditionDetail(
                                conditionDetail.filter(
                                  (item) =>
                                    item.SubCategoryCode !==
                                    subCategory.subCategoryCode
                                )
                              );
                            }}
                            link
                            disabled={
                              !conditionDetail.some(
                                (e) =>
                                  e.SubCategoryCode ===
                                  subCategory.subCategoryCode
                              )
                            }
                            name={
                              conditionDetail.some(
                                (e) =>
                                  e.SubCategoryCode ===
                                  subCategory.subCategoryCode
                              )
                                ? "caret down"
                                : "spinner"
                            }
                            style={{ marginLeft: 10, color: "rgb(254, 80, 0)" }}
                          />
                        ) : (
                          <Icon
                            onClick={() =>
                              selectedSubcategories.some(
                                (e) => e === subCategory.subCategoryCode
                              )
                                ? false
                                : setSelectedSubcategories([
                                    ...selectedSubcategories,
                                    subCategory.subCategoryCode,
                                  ])
                            }
                            link
                            size="large"
                            name="caret right"
                            style={{ marginLeft: 10, color: "rgb(254, 80, 0)" }}
                          />
                        )}
                        <Link
                          onClick={() =>
                            dispatch(
                              setInclusionMenuSelection({
                                selectedCategoryID: category.mainCategoryID,
                                selectedSubCategoryCode:
                                  subCategory.subCategoryCode,
                              })
                            )
                          }
                          to={`/browser/projects/inclusions/${projectID}`}
                        >
                          {subCategory.subCategoryCode === "Total"
                            ? ""
                            : subCategory.subCategoryCode}
                        </Link>
                      </Table.Cell>
                      <Table.Cell
                        textAlign="right"
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                      >
                        {subCategory.originalHours}
                      </Table.Cell>
                      <Table.Cell
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                        textAlign="right"
                      >
                        {subCategory.actualHours}
                      </Table.Cell>
                      <Table.Cell
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                        textAlign="right"
                      >
                        {subCategory.actualLaborHoursTotal ||
                        subCategory.actualLaborHoursTotal === 0
                          ? subCategory.actualLaborHoursTotal
                          : 0}
                      </Table.Cell>
                      <Table.Cell
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                        textAlign="right"
                      >
                        {formatter.format(subCategory.originalLabor)}
                      </Table.Cell>
                      <Table.Cell
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                        textAlign="right"
                      >
                        {formatter.format(subCategory.actualLabor)}
                      </Table.Cell>
                      <Table.Cell
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                        textAlign="right"
                        className="border-left"
                      >
                        {formatter.format(subCategory.originalMaterial)}
                      </Table.Cell>
                      <Table.Cell
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                        textAlign="right"
                      >
                        {formatter.format(subCategory.actualMaterial)}
                      </Table.Cell>
                      <Table.Cell
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                        textAlign="right"
                        className="border-left"
                      >
                        {formatter.format(subCategory.originalEquipment)}
                      </Table.Cell>
                      <Table.Cell
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                        textAlign="right"
                      >
                        {formatter.format(subCategory.actualEquipment)}
                      </Table.Cell>

                      <Table.Cell
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                        textAlign="right"
                        className="border-left"
                      >
                        {formatter.format(subCategory.originalOtherCosts)}
                      </Table.Cell>
                      <Table.Cell
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                        textAlign="right"
                      >
                        {formatter.format(subCategory.actualOther)}
                      </Table.Cell>
                      <Table.Cell
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                        textAlign="right"
                        className="border-left"
                      >
                        {formatter.format(subCategory.originalSub)}
                      </Table.Cell>
                      <Table.Cell
                        style={
                          subCategory.subCategoryCode === "Total"
                            ? { color: "blue", fontWeight: "bold" }
                            : {}
                        }
                        textAlign="right"
                      >
                        {formatter.format(subCategory.actualSub)}
                      </Table.Cell>
                    </Table.Row>
                    {subCategory.subCategoryCode !== "Total" &&
                    Array.isArray(conditionDetail) &&
                    conditionDetail.length &&
                    selectedSubcategories.some(
                      (item) => item === subCategory.subCategoryCode
                    ) &&
                    conditionDetail.some(
                      (item) =>
                        item.SubCategoryCode === subCategory.subCategoryCode
                    )
                      ? conditionDetail
                          .filter(
                            (item) =>
                              item.SubCategoryCode ===
                              subCategory.subCategoryCode
                          )
                          .map((detail) => (
                            <Table.Row
                              key={detail.ProjectTaskID}
                              style={{
                                backgroundColor: "oldlace",
                              }}
                            >
                              <Table.Cell
                                style={{ paddingLeft: 40, fontWeight: "bold" }}
                              >
                                {`${detail.ConditionName} (#${detail.ProjectTaskID})`}
                              </Table.Cell>
                              <Table.Cell textAlign="right">
                                {detail.BudgetedLaborHours}
                              </Table.Cell>
                              <Table.Cell textAlign="right">
                                {detail.PendingLaborHours}
                              </Table.Cell>
                              <Table.Cell textAlign="right">
                                {detail.ActualLaborHoursTotal}
                              </Table.Cell>
                              <Table.Cell textAlign="right">
                                {formatter.format(detail.BudgetedLaborCost)}
                              </Table.Cell>
                              <Table.Cell textAlign="right">
                                {formatter.format(detail.ActualLaborCost)}
                              </Table.Cell>
                              <Table.Cell
                                textAlign="right"
                                className="border-left"
                              >
                                {formatter.format(detail.BudgetedMaterialCost)}
                              </Table.Cell>
                              <Table.Cell textAlign="right">
                                {formatter.format(detail.ActualMaterialCost)}
                              </Table.Cell>
                              <Table.Cell
                                textAlign="right"
                                className="border-left"
                              >
                                {formatter.format(detail.BudgetedEquipmentCost)}
                              </Table.Cell>
                              <Table.Cell textAlign="right">
                                {formatter.format(detail.ActualEquipmentCost)}
                              </Table.Cell>
                              <Table.Cell
                                textAlign="right"
                                className="border-left"
                              >
                                {formatter.format(detail.BudgetedOtherCost)}
                              </Table.Cell>
                              <Table.Cell textAlign="right">
                                {formatter.format(detail.ActualOtherCost)}
                              </Table.Cell>
                              <Table.Cell
                                textAlign="right"
                                className="border-left"
                              >
                                {formatter.format(detail.BudgetedSubCost)}
                              </Table.Cell>
                              <Table.Cell textAlign="right">
                                {formatter.format(detail.ActualSubCost)}
                              </Table.Cell>
                            </Table.Row>
                          ))
                      : null}
                  </Fragment>
                ))}
              </Fragment>
            ))
          : null}
      </Table.Body>
      {totals ? (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell
              textAlign="right"
              style={{ color: "purple", fontWeight: "bold" }}
            >
              {totals.originalHours}
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="right"
              style={{ color: "purple", fontWeight: "bold" }}
            >
              {totals.actualHours}
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="right"
              style={{ color: "purple", fontWeight: "bold" }}
            >
              {totals.actualLaborHoursTotal}
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="right"
              style={{ color: "purple", fontWeight: "bold" }}
            >
              {formatter.format(totals.originalLabor)}
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="right"
              style={{ color: "purple", fontWeight: "bold" }}
            >
              {formatter.format(totals.actualLabor)}
            </Table.HeaderCell>

            <Table.HeaderCell
              textAlign="right"
              className="border-left"
              style={{ color: "purple", fontWeight: "bold" }}
            >
              {formatter.format(totals.originalMaterial)}
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="right"
              style={{ color: "purple", fontWeight: "bold" }}
            >
              {formatter.format(totals.actualMaterial)}
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="right"
              className="border-left"
              style={{ color: "purple", fontWeight: "bold" }}
            >
              {formatter.format(totals.originalEquipment)}
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="right"
              style={{ color: "purple", fontWeight: "bold" }}
            >
              {formatter.format(totals.actualEquipment)}
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="right"
              className="border-left"
              style={{ color: "purple", fontWeight: "bold" }}
            >
              {formatter.format(totals.originalOtherCosts)}
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="right"
              style={{ color: "purple", fontWeight: "bold" }}
            >
              {formatter.format(totals.actualOther)}
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="right"
              className="border-left"
              style={{ color: "purple", fontWeight: "bold" }}
            >
              {formatter.format(totals.originalSub)}
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="right"
              style={{ color: "purple", fontWeight: "bold" }}
            >
              {formatter.format(totals.actualSub)}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      ) : null}
    </Table>
  );
};

export default CostManagement;
