import React from "react";
import { AgGridReact } from "ag-grid-react";
import { Button } from "semantic-ui-react";

import { columnDefs } from "./columnDefs";
import AddLeaderModal from "./AddLeaderModal";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const LeadershipIncentive = ({ rowData, save, onUpdate }) => {
  const [open, setOpen] = React.useState(false);

  const handleChange = (params) => {
    const { year, quarter, empuserid } = params.data;
    const value = params.newValue;
    // console.log(year, quarter, empuserid, division, value);
    save(year, quarter, empuserid, null, value, "IncentivePercent");
  };

  const handleSave = (user) => {
    if (onUpdate) onUpdate();
    setOpen(false);
  };

  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{
          height: "calc(100vh - 170px)",
          width: "100%",
          minHeight: 300,
          postion: "relative",
        }}
      >
        <Button
          style={{
            position: "absolute",
            zIndex: 100,
            right: "20px",
            top: "20px",
          }}
          onClick={() => setOpen(true)}
        >
          Add Leader
        </Button>
        <AgGridReact
          enableCellChangeFlash={true}
          defaultColDef={{ sortable: false }}
          suppressRowDrag={true}
          suppressMovableColumns={true}
          lockPinned={true}
          columnDefs={columnDefs}
          rowData={rowData}
          rowSelection="multiple"
          onCellValueChanged={(params) => {
            handleChange(params);
          }}
          onGridReady={(params) => {
            params.api.sizeColumnsToFit();
            window.onresize = () => {
              params.api.sizeColumnsToFit();
            };
          }}
        />
      </div>

      <AddLeaderModal
        open={open}
        onClose={() => setOpen(false)}
        onSave={handleSave}
      />
    </>
  );
};

export default LeadershipIncentive;
