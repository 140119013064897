import Axios from "axios";
import { API_ROOT } from "./api-config";
import qs from "query-string";
import { saveAs } from "file-saver";

export const getUser = (state) => {
  return JSON.parse(state.auth.user)[0];
};

export const getUserRoles = (state) => {
  let user = JSON.parse(state.auth.user)[0];
  return user.Roles ? user.Roles : null;
};

export const userIsInRole = (state, roleID) => {
  let user = state.auth.user ? JSON.parse(state.auth.user)[0] : {};
  if (user.Roles) {
    return user.Roles.some((el) => el.RoleID === roleID);
  } else {
    return false;
  }
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }
  return null;
};

// Send email
export const sendEmail = (email, cc, bcc, subject, text, html) => {
  Axios.post(`${API_ROOT}/send-email`, {
    email,
    cc,
    bcc,
    subject,
    text,
    html,
  })
    .then((res) => console.log(res))
    .catch((err) => {
      console.log(err);
    });
};

export const sendEmailToGroup = (group, cc, bcc, subject, text, html) => {
  Axios.post(`${API_ROOT}/send-email/to-group`, {
    group,
    cc,
    bcc,
    subject,
    text,
    html,
  })
    .then((res) => console.log(res))
    .catch((err) => {
      console.log(err);
    });
};

export const sendEmailBccGroup = (group, to, cc, subject, text, html) => {
  Axios.post(`${API_ROOT}/send-email/bcc-group`, {
    group,
    to,
    cc,
    subject,
    text,
    html,
  })
    .then((res) => console.log(res))
    .catch((err) => {
      console.log(err);
    });
};

// Set query string without page reload
export const setQueryStringWithoutPageReload = (qsValue) => {
  const newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    qsValue;

  window.history.pushState({ path: newurl }, "", newurl);
};

// Set query string value: updates the query string parameters only for the provided key, keeping the remaining parameters intact.
export const setQueryStringValue = (
  key,
  value,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString);
  const newQsValue = qs.stringify({ ...values, [key]: value });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

// Get query string value: returns the query string value for the provided key.
export const getQueryStringValue = (
  key,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString);
  return values[key];
};

export const getEnv = () => {
  const hostname = window && window.location && window.location.hostname;

  let env = "local";
  if (hostname === "nexsys1-staging.azurewebsites.net") {
    env = "staging";
  } else if (hostname === "ns1.nexus5group.com") {
    env = "production";
  } else if (hostname === "nexsys1.azurewebsites.net") {
    env = "production";
  }

  return env;
};

export const isEnv = (targetEnv) => {
  return getEnv() === targetEnv;
};

export const isNotEnv = (targetEnv) => !isEnv(targetEnv);

// Get base url
export const getBaseUrl = () => {
  let baseUrl = "";

  const hostname = window && window.location && window.location.hostname;

  if (hostname === "nexsys1-staging.azurewebsites.net") {
    baseUrl = "https://nexsys1-staging.azurewebsites.net";
  } else if (hostname === "ns1.nexus5group.com") {
    baseUrl = "https://ns1.nexus5group.com";
  } else if (hostname === "nexsys1.azurewebsites.net") {
    baseUrl = "https://nexsys1.azurewebsites.net";
  } else {
    // backEndHost = 'https://nexsys1api-staging.azurewebsites.net';
    baseUrl = "http://localhost:3000";
  }

  return baseUrl;
};

// Originally inspired by  David Walsh (https://davidwalsh.name/javascript-debounce-function)

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// `wait` milliseconds.
export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const downloadContract = (guid) => {
  console.log(guid);

  const fileName = guid + ".pdf";
  Axios.get(`${API_ROOT}/api/contract/contract-uri`, {
    params: {
      fileName,
    },
  })
    .then((res) => {
      saveAs(res.data, fileName);
    })
    .catch((err) => {
      console.log(err);
    });
};
