import React, { useEffect, useState } from "react";
import {
  Container,
  Segment,
  Input,
  Icon,
  Label,
  Popup,
} from "semantic-ui-react";
import { useSelector } from "react-redux";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";
import { AgGridReact } from "ag-grid-react";
import numeral from "numeral";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./SurveyList.scss";
import moment from "moment";
import ResponseModal from "./ResponseModal/ResponseModal";

const dateFormatter = (params) => {
  return moment(params.data.batchdate).format("l");
};

const dateComparator = (filterLocalDateAtMidnight, cellValue) => {
  //using moment js
  var dateAsString = moment(new Date(cellValue), "YYYY-MM-DD").format(
    "DD/MM/YYYY"
  );
  var dateParts = dateAsString.split("/");
  var cellDate = new Date(
    Number(dateParts[2]),
    Number(dateParts[1]) - 1,
    Number(dateParts[0])
  );

  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    return 0;
  }

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }

  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
};

const SurveyList = () => {
  const quarter = useSelector((state) => state.surveyMenu.quarter);
  const year = useSelector((state) => state.surveyMenu.year);
  const userID = useSelector((state) => state.surveyMenu.userID);
  const surveyStatus = useSelector((state) => state.surveyMenu.surveyStatus);
  const [surveys, setSurveys] = useState([]);
  const [gridApi, setGridApi] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  const columnDefs = [
    {
      headerName: "Rep",
      field: "n5representative",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Client",
      field: "clientdescription",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Project",
      field: "projectdescription",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Batch Date",
      field: "batchdate",
      sortable: true,
      sort: "desc",
      filter: "agDateColumnFilter",
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: dateComparator,
      },
      // filterValueGetter: dateFormatter,
      valueGetter: dateFormatter,
    },
    {
      headerName: "Project Type",
      field: "projecttype",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Invitation",
      field: "senddate",
      sortable: true,
      filter: true,
      filterParams: {
        // provide comparator function
        comparator: dateComparator,
      },
      // filterValueGetter: dateFormatter,
      valueGetter: dateFormatter,
      cellRendererFramework: (params) => {
        return params.data.donotsendind === false ? (
          <span>
            {/* <Icon
              size="large"
              title="View inspection"
              link
              onClick={() => setModalOpen(true)}
              name="search"
            /> */}
            <Label color="green">
              <Icon name="mail" />
              {moment(params.data.senddate).format("l")}
            </Label>
          </span>
        ) : (
          <Popup
            inverted
            content={params.data.donotsendreason}
            trigger={
              <Label>
                <Icon style={{ marginLeft: 0 }} name="close" />
              </Label>
            }
          />
        );
      },
    },
    {
      headerName: "Response",
      field: "ResponseDate",
      sortable: true,
      filter: true,
      valueGetter: (params) => {
        return moment(params.data.ResponseDate).format("l");
      },
      cellRendererFramework: (params) => {
        if (params.data.ResponseDate) {
          return (
            <span>
              <Label onClick={() => setModalOpen(true)} color="blue" as="a">
                <Icon style={{ marginLeft: 0, marginRight: 0 }} name="file" />
              </Label>{" "}
              {moment(params.data.ResponseDate).format("l")}
            </span>
          );
        } else {
          return null;
        }
      },
    },
    {
      headerName: "Overall Score",
      field: "percentScore",
      sortable: true,
      filter: true,
      valueGetter: (params) => {
        return params.data.ResponseDate
          ? numeral(params.data.percentScore).format("0.00 %")
          : null;
      },
    },
    {
      headerName: "NPS",
      field: "nps",
      sortable: true,
      filter: true,
      cellRendererFramework: (params) => {
        if (params.data.nps) {
          return (
            <span>
              <Label
                icon={params.data.npsicon}
                content={params.data.nps}
                color={params.data.npsclass}
              />
            </span>
          );
        } else {
          return null;
        }
      },
    },
  ];

  useEffect(() => {
    gridApi && gridApi.showLoadingOverlay();
    Axios.post(`${API_ROOT}/api/kpi/survey-list`, {
      quarter,
      year,
      userID,
      surveyStatus,
    })
      .then((res) => {
        setSurveys(res.data.surveyList || []);
        gridApi && gridApi.hideOverlay();
        !res.data.surveyList && gridApi && gridApi.showNoRowsOverlay();
        // gridApi && gridApi.hideOverlay();
        // gridApi && gridApi.refreshCells();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [quarter, year, userID, surveyStatus]);

  const onFilterTextBoxChanged = (e, { value }) => {
    gridApi.setQuickFilter(value);
  };

  return (
    <Container
      className="SurveyList"
      style={{ paddingLeft: 10, paddingRight: 10 }}
      fluid
    >
      <div>
        <h1 style={{ color: "rgb(254, 80, 0)", marginLeft: 40 }}>Surveys</h1>
        <Segment style={{ marginTop: -64 }} basic textAlign="right">
          <Input
            type="text"
            id="filter-text-box"
            placeholder="Filter..."
            onChange={onFilterTextBoxChanged}
          />
        </Segment>
        <div
          className="ag-theme-alpine"
          style={{ height: "78vh", width: "100%" }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={surveys}
            rowSelection="single"
            // enableCellChangeFlash={true}
            onGridReady={(params) => {
              setGridApi(params.api);
              params.api.sizeColumnsToFit();
              window.onresize = () => {
                params.api.sizeColumnsToFit();
              };
              // setColumnApi(params.columnApi);
              var defaultSortModel = [
                {
                  colId: "n5representative",
                  sort: "asc",
                },
                {
                  colId: "batchdate",
                  sort: "desc",
                },
              ];
              params.api.setSortModel(defaultSortModel);
            }}
          />
        </div>
      </div>
      <ResponseModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        gridApi={gridApi}
      />
    </Container>
  );
};

export default SurveyList;
