import React, { useState, useEffect } from "react";
import { Table } from "semantic-ui-react";
import { cloneDeep } from "lodash";

import { getTracking } from "../../../api/project";
import { WorksheetProvider } from "./WorksheetContext";
import { TableBody, TableHeader, GrandTotalRow } from "./Table";

const getTrackingData = (projectId) =>
  getTracking(projectId).then((results) => {
    // need to reduc this data down to row types
    return results.reduce((carry, row) => {
      if (!row.project_id) {
        // set the grand totals row
        carry.totals = row;
      } else if (row.category_code in carry) {
        // do we have this category already?
        if (row.sub_category_code === null)
          carry[row.category_code].totals = row;
        carry[row.category_code].subs.push(row);
      } else {
        // never seen this one before...
        // lets prime the pump
        if (row.sub_category_code === null)
          carry[row.category_code] = {
            totals: row,
            subs: [],
          };
        else carry[row.category_code] = { totals: null, subs: [row] };
      }

      return carry;
    }, {});
  });

export default ({ projectId }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    getTrackingData(projectId).then(setData);
  }, [projectId]);

  if (data === null) return null;

  return (
    <WorksheetProvider data={cloneDeep(data)}>
      <Table compact="very" unstackable style={{ fontSize: 12 }}>
        <TableHeader />
        <TableBody data={data} />
        <GrandTotalRow totals={data.totals} />
      </Table>
    </WorksheetProvider>
  );
};
