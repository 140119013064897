import React, { useEffect, useState } from "react";
import { Image } from "semantic-ui-react";
import bonusCertificate from "./bonuscertificate.jpg";
import "./IncentivePayoutCertificate.scss";
import useQueryString from "../../../hooks/useQueryString";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

const IncentivePayoutCertificate = () => {
  const [y] = useQueryString("y");
  const [q] = useQueryString("q");

  const [certificates, setCertificates] = useState(null);

  useEffect(() => {
    if (!!y && !!q && !isNaN(parseInt(y)) && !isNaN(parseInt(q))) {
      const year = parseInt(y);
      const quarter = parseInt(q);

      Axios.get(`${API_ROOT}/api/kpi/payout-certificate`, {
        params: {
          year,
          quarter,
        },
      })
        .then((res) => {
          setCertificates(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [y, q]);

  return (
    <div
      className="IncentivePayoutCertificate"
      style={{ margin: 0, padding: 0 }}
    >
      {Array.isArray(certificates) && certificates.length
        ? certificates.map((obj) => (
            <div className="cer" key={obj.teammember}>
              <Image
                className="bonus-certificate-image"
                src={bonusCertificate}
                // style={{
                //   marginLeft: 0,
                //   marginRight: 0,
                //   marginTop: ".455in",
                //   marginBottom: ".455in",
                //   width: "9.56in",
                // }}
              />
              <div
                style={{
                  textAlign: "center",
                  fontSize: "36pt",
                  color: "black",
                  position: "absolute",
                  left: 115,
                  top: 280,
                  width: 820,
                  height: 100,
                  fontFamily: ["Tangerine", "cursive"],
                }}
              >
                {obj.teammember}
              </div>
              <div
                style={{
                  position: "absolute",
                  left: 150,
                  top: 365,
                  width: 360,
                  height: 145,
                  fontFamily: "arial",
                  fontWeight: "bold",
                  fontSize: "10pt",
                }}
              >
                <table
                  width="100%"
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <tbody>
                    <tr>
                      <th></th>
                      <th>Q1</th>
                      <th>Q2</th>
                      <th>Q3</th>
                      <th>Q4</th>
                    </tr>
                    <tr>
                      <td width="15%">Incentive</td>
                      <td width="17%">{formatter.format(obj.Q1)}</td>
                      <td width="17%">{formatter.format(obj.Q2)}</td>
                      <td width="17%">{formatter.format(obj.Q3)}</td>
                      <td width="17%">{formatter.format(obj.Q4)}</td>
                    </tr>
                    <tr>
                      <td>Hours</td>
                      <td>{Math.trunc(obj.Q1HRS)}</td>
                      <td>{Math.trunc(obj.Q2HRS)}</td>
                      <td>{Math.trunc(obj.Q3HRS)}</td>
                      <td>{Math.trunc(obj.Q4HRS)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  textAlign: "left",
                  position: "absolute",
                  left: 540,
                  top: 350,
                  width: 370,
                  height: 145,
                  color: "black",
                  fontFamily: "arial",
                  fontSize: "8.5pt",
                }}
              >
                <p>
                  We are pleased to present you with your share of Nexus 5 Group
                  incentive pool.
                </p>
                <p>
                  Thank you very much for all your hard work. Your contribution
                  to this team has helped make this incentive possible. Keep up
                  the great work.
                </p>
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

export default IncentivePayoutCertificate;
