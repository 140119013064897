import {
  SET_COMPANY_GOALS_MENU_SELECTION,
  RESET_COMPANY_GOALS_MENU_SELECTION,
} from "../actions/topMenu/companyGoalsMenuActions";
import * as moment from "moment";

const year = moment().year();

const INITIAL_DATA = {
  year,
};

const companyGoalsMenuReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case SET_COMPANY_GOALS_MENU_SELECTION:
      return {
        ...state,
        year: action.payload,
      };
    case RESET_COMPANY_GOALS_MENU_SELECTION:
      return INITIAL_DATA;
    default:
      return state;
  }
};

export default companyGoalsMenuReducer;
