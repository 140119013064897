export const SET_TEAM_MEMBER_GOALS_MENU_SELECTION =
  "SET_TEAM_MEMBER_GOALS_MENU_SELECTION";
export const RESET_TEAM_MEMBER_GOALS_MENU_SELECTION =
  "RESET_TEAM_MEMBER_GOALS_MENU_SELECTION";

export const setTeamMemberGoalsMenuSelection = (selection) => ({
  type: SET_TEAM_MEMBER_GOALS_MENU_SELECTION,
  payload: selection,
});

export const resetTeamMemberGoalsMenuSelection = () => ({
  type: RESET_TEAM_MEMBER_GOALS_MENU_SELECTION,
});
