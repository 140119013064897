import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import validate from "uuid-validate";
import Axios from "axios";
import { API_ROOT } from "../../api-config";
import {
  Dimmer,
  Loader,
  Container,
  Grid,
  Image,
  Divider,
  Segment,
  Table,
  Button,
  Header,
  Icon,
} from "semantic-ui-react";

import Logo from "./N5Group.png";
import AlternateTableSummary from "./AlternateTableSummary/AlternateTableSummary";
import AlternateTableDetail from "./AlternateTableDetail/AlternateTableDetail";

const options = { style: "currency", currency: "USD" };
const currencyFormat = new Intl.NumberFormat("en-US", options);

const makeCommaSeparatedString = (arr, useOxfordComma) => {
  const listStart = arr.slice(0, -1).join(", ");
  const listEnd = arr.slice(-1);
  const conjunction =
    arr.length <= 1
      ? ""
      : useOxfordComma && arr.length > 2
      ? ", and "
      : " and ";

  return [listStart, listEnd].join(conjunction);
};

const AlternateApprovalWrapper = () => {
  const [loading, setLoading] = useState(true);
  const [baseGuid, setBaseGuid] = useState(null);
  const [project, setProject] = useState(null);
  const [alternates, setAlternates] = useState(null);
  const [selectedContracts, setSelectedContracts] = useState(["Base"]);
  const [submitted, setSubmitted] = useState(false);
  const [emailSending, setEmailSending] = useState(false);
  const { guid } = useParams();

  const ref = useRef(false);
  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);

  useEffect(() => {
    if (validate(guid) && ref.current) {
      setBaseGuid(guid);
    } else {
      setLoading(false);
    }
  }, [guid]);

  useEffect(() => {
    if (baseGuid) {
      Axios.get(`${API_ROOT}/contract/alternate-approval-items`, {
        params: {
          guid: baseGuid,
        },
      })
        .then((res) => {
          if (
            res.data &&
            res.data.alternates &&
            res.data.project &&
            ref.current
          ) {
            setProject(JSON.parse(res.data.project));
            setAlternates(JSON.parse(res.data.alternates));

            setSelectedContracts(
              JSON.parse(res.data.alternates)
                .filter((alt) => alt.AlternateSelectedInd)
                .map((selected) => selected.ContractType)
            );

            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [baseGuid]);

  const selectedAlternatesArray =
    Array.isArray(selectedContracts) && Array.isArray(alternates)
      ? selectedContracts.map((contractType) => {
          return alternates.find(
            (alternate) => alternate.ContractType === contractType
          );
        })
      : [];

  const selectedTotal = selectedAlternatesArray.reduce(function(prev, cur) {
    return prev + cur.Total;
  }, 0);

  const selectedGrandTotal = selectedAlternatesArray.reduce(function(
    prev,
    cur
  ) {
    return prev + cur.GrandTotal;
  },
  0);

  const selectedTax = selectedAlternatesArray.reduce(function(prev, cur) {
    return prev + cur.KansasRemodelTax;
  }, 0);

  const formattedTotal = currencyFormat.format(selectedTotal);
  const formattedTax = currencyFormat.format(selectedTax);
  const formattedGrandTotal = currencyFormat.format(selectedGrandTotal);

  const handleClick = () => {
    saveAlternateSelection();
    setEmailSending(true);
    let selected = makeCommaSeparatedString(selectedContracts, true);
    if (selected === "Base") {
      selected = "only the Base, without any alternates,";
    }
    const subject = `Alternate Approval form for ${project.ProjectName} #${project.ProjectID} has been submitted.`;
    const text = `The alternate approval form for ${project.ProjectName} #${project.ProjectID} has been submitted by the client, ${project.contactName}, at ${project.CompanyName}. They have selected ${selected} for inclusion in the proposal with a subtotal of ${formattedTotal}, total taxes of ${formattedTax}, and a grand total of ${formattedGrandTotal}.`;
    const email = project.PMEmail;
    // const email = "mlyons@cohenesrey.com";
    Axios.post(`${API_ROOT}/send-email`, {
      email,
      cc: "",
      bcc: "softwaredevelopment@cohenesrey.com",
      subject,
      text,
      html: text,
    })
      .then((res) => {
        console.log(res);
        setSubmitted(true);
        setEmailSending(false);
      })
      .catch((err) => {
        setEmailSending(false);
        alert(
          "An error occurred while submitting. Please contact the Project Manager above."
        );
      });
  };

  const postSelection = (projectID, contractType, alternateSelectedInd) => {
    Axios.post(`${API_ROOT}/contract/alternate-selected-ind`, {
      projectID,
      contractType,
      alternateSelectedInd,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const saveAlternateSelection = () => {
    // let allAlts = alternates.filter(
    //   (alternate) => alternate.ContractType !== "Base"
    // );
    alternates.forEach((alt) => {
      if (
        selectedContracts.some(
          (selectedAlt) => selectedAlt === alt.ContractType
        )
      ) {
        postSelection(alt.ProjectID, alt.ContractType, true);
      } else {
        postSelection(alt.ProjectID, alt.ContractType, false);
      }
    });
  };

  if (loading) {
    return (
      <Dimmer active inverted>
        <Loader size="big">Loading...</Loader>
      </Dimmer>
    );
  }

  if (!baseGuid && !loading) {
    return (
      <div>
        <p>
          No project found. Please contact your project manager for assistance.
        </p>
      </div>
    );
  }

  if (submitted) {
    return (
      <Container>
        <Segment placeholder>
          <Header icon>
            <Icon color="green" name="thumbs up outline" />
            <p>Thank you for submitting your selection.</p>
            <p>
              If you have any questions please email the Project Manager,{" "}
              {project.PMDisplayName}, at{" "}
              <a href={`mailto:${project.PMEmail}`}>{project.PMEmail}</a>.
            </p>
          </Header>
        </Segment>
      </Container>
    );
  }
  return (
    <Container>
      <Grid>
        <Grid.Row columns={3} style={{ paddingTop: 40 }}>
          <Grid.Column style={{ fontSize: 12 }}>
            <p>
              {project.contactName ? project.contactName : null}
              {project.contactName ? <br /> : null}
              {project.CompanyName ? project.CompanyName : null}
              {project.CompanyName ? <br /> : null}
              {project.contactPhone ? project.contactPhone : null}
              {project.contactPhone ? <br /> : null}
              {project.contactEmail ? (
                <a href={`mailto:${project.contactEmail}`}>
                  {project.contactEmail}
                </a>
              ) : null}
            </p>
          </Grid.Column>
          <Grid.Column>
            <Image src={Logo} alt="Nexus 5 Group logo" />
          </Grid.Column>
          <Grid.Column style={{ fontSize: 12 }}>
            <p style={{ textAlign: "right" }}>
              {project.PMDisplayName ? project.PMDisplayName : null}
              {project.PMDisplayName ? <br /> : null}
              Nexus 5 Group
              <br />
              {project.PMPhone ? project.PMPhone : null}
              {project.PMPhone ? <br /> : null}
              {project.PMEmail ? (
                <a href={`mailto:${project.PMEmail}`}>{project.PMEmail}</a>
              ) : null}
            </p>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <p>
              <strong>{project.ProjectName}</strong> #{project.ProjectID}
              <br />
              {project.ProjectDescription}
            </p>
            {project && !project.AlternatesSelectedInd ? (
              <Segment inverted color="blue">
                Please review the scope of work and select the checkbox for the
                alternates, if any, you wish to be included in your proposal.
                When you have completed your selection, submit your response
                below. If you have any questions, contact the Project Manager
                listed above.
              </Segment>
            ) : null}

            {alternates.map((alternate) =>
              alternate.proposalFormatType === "Summary" ? (
                <AlternateTableSummary
                  key={alternate.ContractType}
                  alternate={alternate}
                  selectedContracts={selectedContracts}
                  setSelectedContracts={setSelectedContracts}
                  saveAlternateSelection={saveAlternateSelection}
                  alternatesSelectedInd={project.AlternatesSelectedInd}
                />
              ) : (
                <AlternateTableDetail
                  key={alternate.ContractType}
                  alternate={alternate}
                  selectedContracts={selectedContracts}
                  setSelectedContracts={setSelectedContracts}
                  saveAlternateSelection={saveAlternateSelection}
                  alternatesSelectedInd={project.AlternatesSelectedInd}
                />
              )
            )}
            {project && project.ProposalFormatType === "Summary" ? (
              <Table
                selectable
                unstackable
                compact="very"
                color="blue"
                style={{ marginBottom: 40 }}
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan="2" width="8"></Table.HeaderCell>
                    <Table.HeaderCell width="2">Price</Table.HeaderCell>
                    <Table.HeaderCell width="6"></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body style={{ fontWeight: "bold" }}>
                  <Table.Row>
                    <Table.Cell colSpan="2">Subtotal</Table.Cell>
                    <Table.Cell style={{ color: "gray" }}>
                      {formattedTotal}
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colSpan="2">KS Remodeling Tax</Table.Cell>
                    <Table.Cell style={{ color: "gray" }}>
                      {formattedTax}
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colSpan="2">Total</Table.Cell>
                    <Table.Cell style={{ color: "rgb(254, 80, 0)" }}>
                      {formattedGrandTotal}
                    </Table.Cell>

                    <Table.Cell></Table.Cell>
                  </Table.Row>
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan={4}>
                      <Button
                        loading={emailSending}
                        primary
                        onClick={handleClick}
                      >
                        Click here to submit selection
                      </Button>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            ) : (
              <Table
                selectable
                unstackable
                compact="very"
                color="blue"
                style={{ marginBottom: 40 }}
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="3"></Table.HeaderCell>
                    <Table.HeaderCell width="11"></Table.HeaderCell>
                    <Table.HeaderCell width="2">Price</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body style={{ fontWeight: "bold" }}>
                  <Table.Row>
                    <Table.Cell>Subtotal</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell style={{ color: "gray" }}>
                      {formattedTotal}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>KS Remodeling Tax</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell style={{ color: "gray" }}>
                      {formattedTax}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Total</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell style={{ color: "rgb(254, 80, 0)" }}>
                      {formattedGrandTotal}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
                {project && !project.AlternatesSelectedInd ? (
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell colSpan={4}>
                        <Button
                          loading={emailSending}
                          primary
                          onClick={handleClick}
                        >
                          Click here to submit selection
                        </Button>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                ) : null}
              </Table>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default AlternateApprovalWrapper;
