import { combineReducers } from "redux";
import authReducer from "./authReducer";
import projectReducer from "./projectReducer";
import inclusionReducer from "./inclusionReducer";
import surveyMenuReducer from "./surveyMenuReducer";
import companyGoalsMenuReducer from "./companyGoalsMenuReducer";
import teamMembersGoalsMenuReducer from "./teamMemberGoalsMenuReducer";
import teamMembersBonusPoolReducer from "./teamMemberBonusPoolReducer";
import incentivePayoutMenuReducer from "./incentivePayoutMenuReducer";
import incentiveAnalysisMenuReducer from "./incentiveAnalysisMenuReducer";
import userAuthReducer from "./userAuthReducer";

export default combineReducers({
  _user: userAuthReducer,
  auth: authReducer,
  project: projectReducer,
  inclusion: inclusionReducer,
  surveyMenu: surveyMenuReducer,
  companyGoalsMenu: companyGoalsMenuReducer,
  teamMemberGoalsMenu: teamMembersGoalsMenuReducer,
  teamMemberBonusPool: teamMembersBonusPoolReducer,
  incentivePayout: incentivePayoutMenuReducer,
  incentiveAnalysis: incentiveAnalysisMenuReducer,
});
