import Axios from "axios";
import jsPDF from "jspdf";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Dropdown,
  Form,
  Icon,
  Input,
  Loader,
  Message,
  Radio,
} from "semantic-ui-react";
import { API_ROOT } from "../../../../api-config";
import { getUser } from "../../../../util";
import Compressor from "compressorjs";

class CustomImage extends Image {
  constructor(mimeType) {
    super();
  }

  // `imageType` is a required input for generating a PDF for an Image
  get imageType() {
    return this.mimeType.split("/")[1];
  }
}

const fileToImageUrl = (file) => {
  return new Promise((resolve, reject) => {
    const image = new CustomImage(file.type);

    image.onload = () => {
      resolve(image);
    };

    image.onerror = () => {
      reject(new Error("Failed to convert File to Image"));
    };

    image.src = URL.createObjectURL(file);
  });
};

// The dimensions are in millimeters.
const A4_PAPER_DIMENSIONS = {
  width: 210,
  height: 297,
};

const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

// Calculates the best possible position of an image on the A4 paper format,
// so that the maximal area of A4 is used and the image ratio is preserved.
const imageDimensionsOnA4 = (dimensions) => {
  const isLandScapeImage = dimensions.width >= dimensions.height;

  // If the image is in landscape, the full width of A4 is used.
  if (isLandScapeImage) {
    return {
      width: A4_PAPER_DIMENSIONS.width,
      height:
        A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
    };
  }

  // If the image is in portrait and the full height of A4 would skew
  // the image ratio, we scale the image dimensions.
  const imageRatio = dimensions.width / dimensions.height;
  if (imageRatio > A4_PAPER_RATIO) {
    const imageScaleFactor =
      (A4_PAPER_RATIO * dimensions.height) / dimensions.width;

    const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

    return {
      height: scaledImageHeight,
      width: scaledImageHeight * imageRatio,
    };
  }

  // The full height of A4 can be used without skewing the image ratio.
  return {
    width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
    height: A4_PAPER_DIMENSIONS.height,
  };
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const ProjectReceipts = () => {
  const [projectOptions, setProjectOptions] = useState([]);
  const [projectID, setProjectID] = useState("");
  const [conditionOptions, setConditionOptions] = useState([]);
  const [conditionID, setConditionID] = useState("");
  const [projectOptionsLoading, setProjectOptionsLoading] = useState(false);
  const [conditionOptionsLoading, setConditionOptionsLoading] = useState(false);
  const [vendor, setVendor] = useState("");
  const [transactionDate, setTransactionDate] = useState("");
  const [total, setTotal] = useState("");
  const [notes, setNotes] = useState("");
  const [PDF, setPDF] = useState();
  const [uploadedImages, setUploadedImages] = useState([]);
  const [fileMimeType, setFileMimeType] = useState("");
  const [sentToAccountingInd, setSentToAccountingInd] = useState(false);
  const [PONumber, setPONumber] = useState("0000-0000-0000");
  const [lastFourDigits, setLastFourDigits] = useState("0000");
  const [analyzerLoading, setAnalyzerLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [receiptFileURL, setReceiptFileURL] = useState("");
  const [scrolledToBottomInd, setScrolledToBottomInd] = useState(false);
  const [creditCardInd, setCreditCardInd] = useState("");

  const user = useSelector((state) => getUser(state));

  const fileInputRef = useRef();

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  // Creates a PDF document containing all the uploaded images.
  const generatePdfFromImages = (images) => {
    // Default export is A4 paper, portrait, using millimeters for units.
    const doc = new jsPDF();

    // We let the images add all pages,
    // therefore the first default page can be removed.
    doc.deletePage(1);

    images.forEach((image) => {
      const imageDimensions = imageDimensionsOnA4({
        width: image.width,
        height: image.height,
      });

      doc.addPage();
      doc.addImage(
        image.src,
        fileMimeType,
        // Images are vertically and horizontally centered on the page.
        (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
        (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
        imageDimensions.width,
        imageDimensions.height
      );
    });

    // Creates a PDF and sets it to component state.
    setPDF(doc.output("blob"));
  };

  useEffect(() => {
    setProjectOptionsLoading(true);
    Axios.get(`${API_ROOT}/api/project/receipt-project-options`)
      .then((res) => {
        setProjectOptions(res.data);
        setProjectOptionsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setProjectOptionsLoading(false);
      });
  }, []);

  const generatePONumber = () => {
    const random = Math.floor(Math.random() * 10000) + 1;
    const PO = `${user.UserID}-${conditionID}-${random}`;
    if (PO) {
      setPONumber(PO);
      setLastFourDigits(random);
    }
  };

  useEffect(() => {
    if (conditionID && user.UserID) {
      generatePONumber(conditionID && user.UserID);
    }
  }, [conditionID, user.UserID]);

  useEffect(() => {
    if (
      projectID &&
      projectOptions.some((option) => option.text === projectID)
    ) {
      const selectedProjectID = projectID.substring(0, 5);
      setConditionID("");
      setConditionOptionsLoading(true);
      Axios.get(
        `${API_ROOT}/api/project/receipt-condition-options?projectID=${selectedProjectID}`
      )
        .then((res) => {
          setConditionOptions(res.data);
          setConditionOptionsLoading(false);
        })
        .catch((err) => {
          setConditionOptionsLoading(false);
          console.log(err);
        });
    } else {
      setConditionID("");
      setConditionOptions([]);
    }
  }, [projectID]);

  let condition = conditionID
    ? conditionOptions.find((cond) => cond.value === conditionID)
    : null;

  const handleLastFourDigitsChange = (event) => {
    setLastFourDigits(event.target.value);
    setPONumber(`${user.UserID}-${conditionID}-${event.target.value}`);
  };

  const analyzeReceipt = (blobName) => {
    Axios.post(`${API_ROOT}/api/project/analyze-receipt`, {
      blobName,
    })
      .then((res) => {
        setVendor(res.data.vendor);
        setTransactionDate(res.data.transactionDate);
        setTotal(res.data.total);
        setAnalyzerLoading(false);
        setScrolledToBottomInd(true);
      })
      .catch((err) => alert("We encountered an error:", err));
  };

  const uploadReceipt = async () => {
    try {
      setAnalyzerLoading(true);
      const blobName = `${PONumber}.pdf`;

      const form = new FormData();
      form.append("file", PDF, blobName);

      const results = await Axios.post(
        `${API_ROOT}/api/project/receipt-sas`,
        form
      ).then((results) => results.data);

      if (results.clientRequestId) {
        setReceiptFileURL(results.blobUrl);
        analyzeReceipt(blobName);
      }
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    if (PDF) {
      uploadReceipt();
    }
  }, [PDF]);

  const handleImageUpload = useCallback(
    (file) => {
      // `event.target.files` is of type `FileList`,
      // we convert it to Array for easier manipulation.
      // const fileList = event.target.files;
      const fileArray = [file];

      setFileMimeType(fileArray[0].type);

      // Uploaded images are read and the app state is updated.
      const fileToImagePromises = fileArray.map(fileToImageUrl);
      Promise.all(fileToImagePromises).then(setUploadedImages);
    },
    [setUploadedImages]
  );

  const handleCompressedUpload = (e) => {
    const image = e.target.files[0];
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        handleImageUpload(compressedResult);
      },
    });
  };

  const handleGeneratePdfFromImages = useCallback(() => {
    generatePdfFromImages(uploadedImages);
  }, [uploadedImages]);

  const submitToAccounting = () => {
    Axios.post(`${API_ROOT}/api/project/save-receipt`, {
      projectID: parseInt(projectID.substring(0, 5)),
      receiptFileURL,
      PONumber,
      vendor,
      transactionDate,
      total: total || total === 0 ? formatter.format(total) : "",
      unformattedTotal: total,
      contentType: "application/pdf",
      fileName: `${PONumber}.pdf`,
      displayName: user.DisplayName,
      conditionID,
      notes,
      userID: user.UserID,
    })
      .then((res) => {
        setSubmitLoading(false);
        setSentToAccountingInd(true);
      })
      .catch((err) => {
        setSubmitLoading(false);
        alert(err);
      });
  };

  const handleSubmit = () => {
    setSubmitLoading(true);
    submitToAccounting();
  };

  return (
    <Container style={{ paddingBottom: 60 }}>
      {" "}
      {sentToAccountingInd ? (
        <Message
          style={{ marginTop: 60 }}
          color="green"
          header="Success"
          icon="check"
          floating
          content="The Accounting team has received your receipt."
        />
      ) : (
        <>
          <Divider
            style={{
              marginTop: 60,
              marginBottom: 30,
              color: "rgb(254, 80, 0)",
            }}
            horizontal
          >
            Step 1
          </Divider>
          <p>
            <em>
              Select the project for which you would like to upload a receipt.
              Only projects with a status of Approved or Work In Progress are
              included in this dropdown menu. If you need to upload a receipt
              for a completed job, please find and log material to the project
              in the <Link to="/m/projects">project list</Link>.
            </em>
          </p>
          <Form.Field>
            <Input
              placeholder="Select a project..."
              fluid
              list="projectlist"
              loading={projectOptionsLoading}
              value={projectID}
              onChange={(e, { value }) => {
                console.log(value);
                setProjectID(value);
              }}
            />
            <datalist id="projectlist">
              {projectOptions.map((option) => (
                <option key={option.value} value={option.text} />
              ))}
            </datalist>
          </Form.Field>
          {/* <Dropdown
            placeholder="Select a project..."
            loading={projectOptionsLoading}
            selection
            fluid
            options={projectOptions}
            search
            selectOnBlur={false}
            value={projectID}
            onChange={(e, { value }) => setProjectID(value)}
          /> */}
          {projectOptions.some((option) => option.text === projectID) &&
          Array.isArray(conditionOptions) &&
          conditionOptions.length &&
          !conditionOptionsLoading ? (
            <>
              <Divider
                style={{
                  marginTop: 60,
                  marginBottom: 30,
                  color: "rgb(254, 80, 0)",
                }}
                horizontal
              >
                Step 2
              </Divider>
              <p>
                <em>
                  Select the condition for which you are purchasing materials.
                </em>
              </p>
              <Dropdown
                placeholder="Select a condition..."
                loading={conditionOptionsLoading}
                selection
                fluid
                options={conditionOptions}
                selectOnBlur={false}
                value={conditionID}
                onChange={(e, { value }) => setConditionID(value)}
              />
              {condition ? (
                <div style={{ marginTop: 24 }}>
                  {condition.internalcomments ? (
                    <p>
                      <strong>Internal comments: </strong>
                      {condition.internalcomments}
                    </p>
                  ) : null}
                  {condition.subcontractorscope ? (
                    <p>
                      <strong>Tech/Subcontractor scope: </strong>
                      {condition.subcontractorscope}
                    </p>
                  ) : null}
                </div>
              ) : null}
            </>
          ) : projectOptions.some((option) => option.text === projectID) &&
            Array.isArray(conditionOptions) &&
            conditionOptions.length === 0 &&
            !conditionOptionsLoading ? (
            <Message
              color="blue"
              content="There are no conditions associated with this project. Please contact the Project Manager."
            />
          ) : conditionOptionsLoading ? (
            <Loader style={{ marginTop: 60 }} active inline="centered" />
          ) : null}
        </>
      )}
      {conditionID && !sentToAccountingInd ? (
        <>
          <Divider
            style={{
              marginTop: 60,
              marginBottom: 30,
              color: "rgb(254, 80, 0)",
            }}
            horizontal
          >
            Step 3
          </Divider>
          <p>
            <em>
              Give the following PO# to the vendor when you make your purchase.
            </em>
          </p>
          <h2 style={{ marginBottom: 24, textAlign: "center" }}>
            <span
              style={{
                background: "lightblue",
                padding: "5px 10px",
                border: "3px dashed black",
              }}
            >
              {PONumber}
            </span>
          </h2>
          <p>
            <em>
              If the PO# listed above does not match what was given to the
              vendor, please correct the number below.
            </em>
          </p>
          <Form>
            <Input
              disabled={analyzerLoading || submitLoading}
              fluid
              label={`${user.UserID}-${conditionID}-`}
              value={lastFourDigits}
              onChange={handleLastFourDigitsChange}
            />
          </Form>
          <Divider
            style={{
              marginTop: 60,
              marginBottom: 30,
              color: "rgb(254, 80, 0)",
            }}
            horizontal
          >
            Step 4
          </Divider>
          <Form>
            <Form.Field>
              <label>Select the receipt (.jpg files or camera only)</label>
              <input
                disabled={analyzerLoading || submitLoading}
                type="file"
                // accept="image/png, image/jpeg;capture=camera"
                accept="image/jpeg,image/jpg"
                ref={fileInputRef}
                // onChange={handleImageUpload}
                onChange={handleCompressedUpload}
              />
            </Form.Field>
            {uploadedImages.length > 0 ? (
              <Fragment>
                {!scrolledToBottomInd ? <AlwaysScrollToBottom /> : null}

                <Divider
                  style={{
                    marginTop: 60,
                    marginBottom: 30,
                    color: "rgb(254, 80, 0)",
                  }}
                  horizontal
                >
                  Step 5
                </Divider>

                <Button
                  icon
                  labelPosition="left"
                  loading={analyzerLoading}
                  disabled={analyzerLoading || submitLoading}
                  onClick={handleGeneratePdfFromImages}
                  fluid
                  color="orange"
                >
                  <Icon name="search" />
                  View the receipt
                  <br />
                  <span style={{ fontSize: 10 }}>
                    (This'll just take a moment)
                  </span>
                </Button>
                {receiptFileURL ? (
                  <>
                    <Divider
                      style={{
                        marginTop: 60,
                        marginBottom: 30,
                        color: "rgb(254, 80, 0)",
                      }}
                      horizontal
                    >
                      Step 6
                    </Divider>
                    <p
                      style={{
                        fontSize: 14,
                        fontStyle: "italic",
                        marginBottom: 20,
                      }}
                    >
                      *The receipt analyzer isn't perfect. Please provide any
                      information not auto-populated below.
                    </p>

                    <Form.Field disabled={analyzerLoading || submitLoading}>
                      <label>Vendor Name</label>
                      <input
                        type="text"
                        value={vendor}
                        onChange={(e) => setVendor(e.target.value)}
                      />
                    </Form.Field>
                    <Form.Field
                      required
                      disabled={analyzerLoading || submitLoading}
                    >
                      <label>Transaction Date</label>
                      <input
                        type="date"
                        value={transactionDate}
                        onChange={(e) => setTransactionDate(e.target.value)}
                      />
                    </Form.Field>
                    <Form.Field
                      required
                      disabled={analyzerLoading || submitLoading}
                    >
                      <label>Total</label>
                      <input
                        type="number"
                        value={total}
                        onChange={(e) => setTotal(e.target.value)}
                      />
                    </Form.Field>
                    <Form.Field disabled={analyzerLoading || submitLoading}>
                      <label>
                        Was this purchased with a Visa, gas card, or Home Depot
                        card? <span style={{ color: "red" }}>*</span>
                      </label>
                      <Radio
                        label="Yes"
                        name="radioGroup"
                        value="yes"
                        checked={creditCardInd === "yes"}
                        onChange={(e, { value }) => setCreditCardInd(value)}
                      />
                    </Form.Field>
                    <Form.Field disabled={analyzerLoading || submitLoading}>
                      <Radio
                        label="No"
                        name="radioGroup"
                        value="no"
                        checked={creditCardInd === "no"}
                        onChange={(e, { value }) => setCreditCardInd(value)}
                      />
                    </Form.Field>
                    <Form.Field disabled={analyzerLoading || submitLoading}>
                      <label>Notes</label>
                      <textarea
                        placeholder="Please provide any additional information here..."
                        onChange={(e) => setNotes(e.target.value)}
                        value={notes}
                      />
                    </Form.Field>
                  </>
                ) : null}

                {transactionDate &&
                total &&
                (creditCardInd === "yes" || creditCardInd === "no") ? (
                  <Fragment>
                    <Divider
                      style={{
                        marginTop: 60,
                        marginBottom: 30,
                        color: "rgb(254, 80, 0)",
                      }}
                      horizontal
                    >
                      Step 7
                    </Divider>
                    <Form.Button
                      onClick={handleSubmit}
                      fluid
                      color="orange"
                      loading={submitLoading}
                      disabled={
                        analyzerLoading ||
                        submitLoading ||
                        !transactionDate ||
                        !total
                      }
                    >
                      <Icon name="send" /> Submit to accounting
                    </Form.Button>
                  </Fragment>
                ) : null}
              </Fragment>
            ) : null}
          </Form>
        </>
      ) : null}
    </Container>
  );
};

export default ProjectReceipts;
