import Axios from "axios";
import React, { useState } from "react";
import { Button, Confirm, Header, Icon, Modal } from "semantic-ui-react";
import { API_ROOT } from "../../../api-config";

import "./StageType.scss";

const StageType = ({ projectID, stage, stageType, rowData, setRowData }) => {
  const [approvedConfirmOpen, setApprovedConfirmOpen] = useState(false);
  const [WIPConfirmOpen, setWIPConfirmOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleApprovedConfirm = () => {
    setStageType(projectID, "In Progress");
    setApprovedConfirmOpen(false);
    // setTimeout(() => props.loadProjectCounts(), 3000);
  };

  const handleWIPConfirm = () => {
    setStageType(projectID, "Completed");
    setWIPConfirmOpen(false);
    // setTimeout(() => props.loadProjectCounts(), 2000);
  };

  const handleCancelRejectConfirm = (nextStage) => {
    setStageType(projectID, nextStage);
    setOpen(false);
  };

  const setStageType = (projectID, stageType) => {
    Axios.post(`${API_ROOT}/api/project/stagetype`, {
      params: {
        projectID,
        stageType,
      },
    })
      .then((res) => {
        console.log(JSON.parse(res.data.project)[0]);

        if (res) {
          if (stageType === "Rejected" || stageType === "Canceled") {
            let filteredRowData = rowData.filter(
              (item) => item.ProjectID !== projectID
            );
            setRowData(filteredRowData);
          } else {
            const project = JSON.parse(res.data.project)[0];
            let updatedRowData = rowData.map((item) => {
              if (item.ProjectID === projectID) {
                const updated = {
                  ...item,
                  ProjectStageAbbr: project.ProjectStageAbbr,
                  StageType: project.StageType,
                };
                return updated; //gets everything that was already in item, and updates "done"
              }

              return item; // else return unmodified item
            });

            setRowData(updatedRowData); // set state to new object with updated list
          }
        }
      })
      .catch((err) => alert(err));
  };

  if (stageType === "Approved") {
    return (
      <>
        <span
          className="span-project-stage-advance"
          onClick={() => setApprovedConfirmOpen(true)}
        >
          APRVD
        </span>
        <Confirm
          size="mini"
          open={approvedConfirmOpen}
          content="Are you sure you want to advance to WIP?"
          onCancel={() => setApprovedConfirmOpen(false)}
          onConfirm={() => handleApprovedConfirm()}
        />
      </>
    );
  } else if (stageType === "In Progress") {
    return (
      <>
        <span
          className="span-project-stage-advance"
          onClick={() => setWIPConfirmOpen(true)}
        >
          WIP
        </span>
        <Confirm
          size="mini"
          open={WIPConfirmOpen}
          content="Are you sure you want to advance to Completed?"
          onCancel={() => setWIPConfirmOpen(false)}
          onConfirm={() => handleWIPConfirm()}
        />
      </>
    );
  } else if (
    stageType === "Preparing Proposal" ||
    stageType === "Awaiting Approval"
  ) {
    return (
      <Modal
        closeIcon
        // basic
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="tiny"
        trigger={
          <span className="span-project-stage-advance">{stage.substr(1)}</span>
        }
      >
        <Header icon>
          <Icon name="archive" />
          Cancel or Reject Project
        </Header>
        <Modal.Content>
          <p>Are you sure you want to cancel or reject this project?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="yellow" onClick={() => setOpen(false)}>
            <Icon name="remove" /> Nevermind
          </Button>
          <Button
            color="red"
            onClick={() => handleCancelRejectConfirm("Canceled")}
          >
            <Icon name="remove" /> Cancel Project
          </Button>
          <Button
            color="red"
            onClick={() => handleCancelRejectConfirm("Rejected")}
          >
            <Icon name="remove" /> Reject Project
          </Button>
        </Modal.Actions>
      </Modal>
    );
  } else {
    return stage.substr(1);
  }
};

export default StageType;
