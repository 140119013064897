import React, { useState, useEffect } from "react";
import { Grid, Segment, Header, Label } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import useIsMounted from "../../../../hooks/isMounted";

const Flags = (props) => {
  // const [forecastColor, setForecastColor] = useState("grey");
  // const [jobStartUpColor, setJobStartUpColor] = useState("grey");
  // const [subBidsColor, setSubBidsColor] = useState("grey");
  // const [subcontractorContractColor, setSubcontractorContractColor] = useState("grey");
  // const [clientContractColor, setClientContractColor] = useState("grey");
  const [projectID, setProjectID] = useState(parseInt(props.match.params.id));
  const [project, setProject] = useState();

  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted.current) {
      setProjectID(parseInt(props.match.params.id));
    }
  }, [props.match.params.id]);

  useEffect(() => {
    if (projectID) {
      Axios.get(`${API_ROOT}/api/project/project-flags?projectID=${projectID}`)
        .then((res) => {
          if (res.data && isMounted.current) {
            setProject(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [projectID]);

  // componentDidMount() {
  //   const projectID = parseInt(this.props.match.params.id);
  //   const currentProject = this.props.projects.find(
  //     (project) => project.ProjectID === projectID
  //   );
  //   if (currentProject) {
  //     this.setState({
  //       forecastColor: currentProject.ForecastColor || "grey",
  //       jobStartUpColor: currentProject.ScopeAckColor || "grey",
  //       bidsColor: currentProject.BidsColor || "grey",
  //       subcontractorContractColor:
  //         currentProject.SubcontractorContractColor || "grey",
  //       clientContractColor: currentProject.ClientContractColor || "grey",
  //     });
  //   }
  // }

  if (project) {
    const {
      ForecastColor,
      ScopeAckColor,
      BidsColor,
      SubcontractorContractColor,
      ClientContractColor,
    } = project;
    return (
      <Grid>
        <Segment clearing basic style={{ margin: 0, padding: 0 }}>
          <Header
            floated="left"
            style={{
              color: "#fe5000",
              display: "inline-block",
              marginLeft: 20,
            }}
            as="h1"
          >
            Flags
          </Header>
        </Segment>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Label.Group style={{ fontSize: 10 }}>
              <Label
                color={ForecastColor}
                style={{ width: 62, paddingRight: 5, fontSize: 11 }}
              >
                Forecast
              </Label>

              <Label
                color={ScopeAckColor}
                style={{ width: 84, paddingRight: 5, fontSize: 11 }}
              >
                Job Start Up
              </Label>

              <Label
                color={BidsColor}
                style={{ width: 65, paddingRight: 5, fontSize: 11 }}
              >
                Sub Bids
              </Label>

              <Label
                color={SubcontractorContractColor}
                style={{ width: 90, paddingRight: 5, fontSize: 11 }}
              >
                Sub Contracts
              </Label>

              <Label
                color={ClientContractColor}
                style={{ width: 100, paddingRight: 5, fontSize: 11 }}
              >
                Client Contracts
              </Label>
            </Label.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  } else {
    return null;
  }
};

export default withRouter(Flags);

// import React, { Component } from "react";
// import { Grid, Segment, Header, Label } from "semantic-ui-react";
// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";

// class Flags extends Component {
//   state = {
//     forecastColor: "grey",
//     jobStartUpColor: "grey",
//     subBidsColor: "grey",
//     subcontractorContractColor: "grey",
//     clientContractColor: "grey",
//   };

//   componentDidMount() {
//     const projectID = parseInt(this.props.match.params.id);
//     const currentProject = this.props.projects.find(
//       (project) => project.ProjectID === projectID
//     );
//     if (currentProject) {
//       this.setState({
//         forecastColor: currentProject.ForecastColor || "grey",
//         jobStartUpColor: currentProject.ScopeAckColor || "grey",
//         bidsColor: currentProject.BidsColor || "grey",
//         subcontractorContractColor:
//           currentProject.SubcontractorContractColor || "grey",
//         clientContractColor: currentProject.ClientContractColor || "grey",
//       });
//     }
//   }

//   render() {
//     const {
//       forecastColor,
//       jobStartUpColor,
//       bidsColor,
//       subcontractorContractColor,
//       clientContractColor,
//     } = this.state;
//     return (
//       <Grid>
//         <Segment clearing basic style={{ margin: 0, padding: 0 }}>
//           <Header
//             floated="left"
//             style={{
//               color: "#fe5000",
//               display: "inline-block",
//               marginLeft: 20,
//             }}
//             as="h1"
//           >
//             Flags
//           </Header>
//         </Segment>
//         <Grid.Row columns={1}>
//           <Grid.Column>
//             <Label.Group style={{ fontSize: 10 }}>
//               <Label
//                 color={forecastColor}
//                 style={{ width: 62, paddingRight: 5, fontSize: 11 }}
//               >
//                 Forecast
//               </Label>

//               <Label
//                 color={jobStartUpColor}
//                 style={{ width: 84, paddingRight: 5, fontSize: 11 }}
//               >
//                 Job Start Up
//               </Label>

//               <Label
//                 color={bidsColor}
//                 style={{ width: 65, paddingRight: 5, fontSize: 11 }}
//               >
//                 Sub Bids
//               </Label>

//               <Label
//                 color={subcontractorContractColor}
//                 style={{ width: 90, paddingRight: 5, fontSize: 11 }}
//               >
//                 Sub Contracts
//               </Label>

//               <Label
//                 color={clientContractColor}
//                 style={{ width: 100, paddingRight: 5, fontSize: 11 }}
//               >
//                 Client Contracts
//               </Label>
//             </Label.Group>
//           </Grid.Column>
//         </Grid.Row>
//       </Grid>
//     );
//   }
// }

// const mapStateToProps = (state) => {
//   return {
//     projects: state.project.projects,
//   };
// };

// export default withRouter(Flags);
