import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { withRouter, Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setInclusionMenuSelection } from "../../../actions/project/inclusion/inclusionActions";
import InclusonsBreakDownTable from "./components/InclusonsBreakDownTable";
import { useShowV1 } from "../../../hooks/useQuery";

const InclusionSummary = () => {
  const dispatch = useDispatch();
  const showV1 = useShowV1();
  const { id: projectId } = useParams();

  const handleNewInclusionClick = () =>
    dispatch(
      setInclusionMenuSelection({
        selectedCategoryID: "",
        selectedSubCategoryCode: "",
      })
    );

  return (
    <div
      style={{ overflow: "auto", paddingLeft: "1rem", paddingRight: "1rem" }}
    >
      <Button
        size="tiny"
        color="teal"
        style={{ marginLeft: 30, marginTop: 20 }}
        icon
        labelPosition="left"
        onClick={() => handleNewInclusionClick()}
        as={Link}
        to={`/browser/projects/inclusions/${projectId}`}
      >
        <Icon name="add" />
        Add new inclusion
      </Button>
      <div
        className="ui fluid container InclusionTable"
        style={{ paddingTop: "40px", paddingBottom: "40px" }}
      >
        <h1
          className="ui header"
          style={{ color: "rgb(254, 80, 0)", display: "block" }}
        >
          Inclusions - Approved {showV1 && "(NEW)"}
        </h1>
        <InclusonsBreakDownTable projectId={projectId} />
      </div>

      <div
        className="ui fluid container InclusionTable"
        style={{ paddingTop: "40px", paddingBottom: "40px" }}
      >
        <h1
          className="ui header"
          style={{ color: "rgb(254, 80, 0)", display: "block" }}
        >
          Inclusions - Pending {showV1 && "(NEW)"}
        </h1>
        <InclusonsBreakDownTable projectId={projectId} status="Pending" />
      </div>

      <div
        className="ui fluid container InclusionTable"
        style={{ paddingTop: "40px", paddingBottom: "40px" }}
      >
        <h1
          className="ui header"
          style={{ color: "rgb(254, 80, 0)", display: "block" }}
        >
          Inclusions - Potential CO {showV1 && "(NEW)"}
        </h1>
        <InclusonsBreakDownTable projectId={projectId} status="Potential CO" />
      </div>

      <div
        className="ui fluid container InclusionTable"
        style={{ paddingTop: "40px", paddingBottom: "40px" }}
      >
        <h1
          className="ui header"
          style={{ color: "rgb(254, 80, 0)", display: "block" }}
        >
          Inclusions - Inactive {showV1 && "(NEW)"}
        </h1>
        <InclusonsBreakDownTable projectId={projectId} status="inactive" />
      </div>
    </div>
  );
};

export default withRouter(InclusionSummary);
