import React, { Fragment } from "react";
import { Modal, Form, Table } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import FleetOptions from "./FleetOptions";

const parseDate = (input) => {
  var parts = input.match(/(\d+)/g);
  // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
};

const FleetContent = ({
  criteria,
  userID,
  usersOptions,
  surveyDate,
  vanOptions,
  van,
  results,
}) => {
  return (
    <Fragment>
      <Modal.Header>Fleet Inspection</Modal.Header>
      <Modal.Content>
        <>
          <Form>
            <Form.Group widths="equal">
              <Form.Dropdown
                style={{ opacity: 1 }}
                selectOnBlur={false}
                // disabled

                search
                selection
                label="Team Member"
                //   onChange={(e, { value }) => setUserID(value)}
                value={userID}
                options={usersOptions}
              />
              <Form.Dropdown
                selectOnBlur={false}
                selection
                //   disabled
                label="Vehicle"
                value={van}
                //   onChange={(e, { value }) => setVan(value)}
                options={vanOptions}
              />
              <SemanticDatepicker
                datePickerOnly={true}
                readOnly
                clearable={false}
                format="MM-DD-YYYY"
                label="Survey Date"
                value={parseDate(surveyDate)}
                //   onChange={(e, { value }) => false}
              />
            </Form.Group>
          </Form>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Criteria</Table.HeaderCell>
                <Table.HeaderCell>Score</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Array.isArray(criteria) && criteria.length
                ? criteria.map((item) => (
                    <Table.Row key={item.ID}>
                      <Table.Cell>
                        {item.RowNumber + ") " + item.Criteria}
                      </Table.Cell>
                      <Table.Cell>
                        <FleetOptions
                          result={
                            results
                              ? results.find(
                                  (result) => result.CriteriaID === item.ID
                                )
                              : null
                          }
                          options={item.Options}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))
                : null}
            </Table.Body>
          </Table>
        </>
      </Modal.Content>
    </Fragment>
  );
};

export default FleetContent;
