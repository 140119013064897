import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Icon, Input, Modal, Table } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../../../api-config";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const OtherCostsModal = ({
  conditionID,
  open,
  setOpen,
  date,
  project,
  userID,
  category,
  conditionName,
  conditionInfo,
  fetchConditionInfo,
}) => {
  const [purchaseOrderAmount, setPurchaseOrderAmount] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [estimateToComplete, setEstimateToComplete] = useState("");
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAmountChange = (e) => {
    if (e.target.validity.valid) {
      setPurchaseOrderAmount(e.target.value);
    }
  };

  const handleETCChange = (e) => {
    if (e.target.validity.valid) {
      setEstimateToComplete(e.target.value);
    }
  };

  useEffect(() => {
    if (userID && conditionID && date) {
      Axios.get(`${API_ROOT}/api/condition/purchase-orders`, {
        params: {
          userID,
          conditionID,
          purchaseOrderDate: date,
          purchaseOrderType: "Other",
        },
      })
        .then((res) => {
          if (res.data) {
            setPurchaseOrders(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [userID, conditionID, date]);

  const handleSave = () => {
    Axios.post(`${API_ROOT}/api/condition/purchase-order`, {
      userID,
      conditionID,
      purchaseOrderAmount,
      invoiceNo,
      purchaseOrderDate: date,
      purchaseOrderType: "Other",
      estimateToComplete,
    })
      .then((res) => {
        if (res.data) {
          setPurchaseOrderAmount("");
          setInvoiceNo("");
          setEstimateToComplete("");
          setPurchaseOrders(res.data);
          fetchConditionInfo();
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = (purchaseOrderID) => {
    Axios.post(`${API_ROOT}/api/condition/delete-purchase-order`, {
      purchaseOrderID,
      userID,
      conditionID,
      purchaseOrderDate: date,
      purchaseOrderType: "Other",
    })
      .then((res) => {
        if (res.data) {
          setPurchaseOrders(res.data);
          fetchConditionInfo();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      size="tiny"
      dimmer="blurring"
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Modal.Header>{`${moment(date).format(
        "l"
      )} - Log Other Costs`}</Modal.Header>
      <Modal.Content>
        <h4 style={{ color: "#2185d0" }}>
          {`${project.projectID} - ${project.companyName}`}
          <br />
          {`${project.projectName}`}
        </h4>

        <h4 style={{ color: "rgba(254, 80, 0, 0.8)" }}>
          {category} - {conditionName ? conditionName : ""}
        </h4>
        <Table compact="very" basic unstackable>
          <Table.Body>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold" }}>
                Est. Other Costs ($)
              </Table.Cell>
              <Table.Cell>
                {conditionInfo &&
                (conditionInfo.otherCostsTotal ||
                  conditionInfo.otherCostsTotal === 0)
                  ? conditionInfo.otherCostsTotal.toFixed(2)
                  : (0).toFixed(2)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold", color: "red" }}>
                Remaining Other Costs
              </Table.Cell>
              <Table.Cell style={{ color: "red" }}>
                {conditionInfo &&
                (conditionInfo.otherCostsRemaining ||
                  conditionInfo.otherCostsRemaining === 0)
                  ? conditionInfo.otherCostsRemaining.toFixed(2)
                  : (0).toFixed(2)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Form>
          <Form.Field>
            <label>Post Other Costs</label>
            <Input
              label="$"
              type="tel"
              pattern="[0-9]*\.?[0-9]*"
              value={purchaseOrderAmount}
              onChange={handleAmountChange}
            />
          </Form.Field>
          <Form.TextArea
            rows={3}
            label="Invoice No."
            value={invoiceNo}
            onChange={(e) => setInvoiceNo(e.target.value)}
          />
          <Form.Field>
            <label>Estimate to Complete</label>
            <Input
              label="$"
              type="tel"
              pattern="[0-9]*\.?[0-9]*"
              value={estimateToComplete}
              onChange={handleETCChange}
            />
          </Form.Field>
          <Button
            disabled={!purchaseOrderAmount || !estimateToComplete}
            onClick={handleSave}
            primary
          >
            Save
          </Button>
        </Form>
        <Table compact="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.HeaderCell>Invoice No.</Table.HeaderCell>
              <Table.HeaderCell>Estimate to Complete</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {purchaseOrders.map((item) => (
              <Table.Row key={item.PurchaseOrderID}>
                <Table.Cell width="1">
                  <Icon
                    onClick={() => handleDelete(item.PurchaseOrderID)}
                    title="Delete purchase order"
                    link
                    name="delete"
                  />
                </Table.Cell>
                <Table.Cell>
                  {formatter.format(item.PurchaseOrderAmount)}
                </Table.Cell>
                <Table.Cell>{item.InvoiceNo}</Table.Cell>
                <Table.Cell>
                  {formatter.format(item.EstimateToComplete)}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default OtherCostsModal;
