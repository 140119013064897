import React, { useState, useEffect, Fragment } from "react";
import { Modal, Button, Form, Table, TextArea } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import CriteriaOptions from "./CriteriaOptions";
import "./EditInspectionModalNew.scss";

const parseDate = (input) => {
  var parts = input.match(/(\d+)/g);
  // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
};

const EditInspectionModalNew = ({
  open,
  setOpen,
  gridApi,
  getSafetySurveyData,
}) => {
  const [KPIEventID, setKPIEventID] = useState("");
  const [projectOptions, setProjectOptions] = useState([]);
  const [sections, setSections] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [projectID, setProjectID] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [comments, setComments] = useState("");
  const [surveyDate, setSurveyDate] = useState("");
  const [edited, setEdited] = useState(false);
  const [location, setLocation] = useState("");
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (open && gridApi.getSelectedRows()[0]) {
      const selectedData = gridApi.getSelectedRows();
      console.log(selectedData);
      if (selectedData[0]) {
        setLocation(selectedData[0].Location);
        setProjectID(selectedData[0].ProjectID);
        setSurveyDate(parseDate(selectedData[0].SurveyDate));
        setComments(selectedData[0].Comments);
        setKPIEventID(selectedData[0].KPIEventID);
        Axios.get(
          `${API_ROOT}/api/kpi/safety-results?KPIEventID=${selectedData[0].KPIEventID}`
        )
          .then((res) => {
            setResults(res.data.results || []);
          })
          .catch((err) => console.log(err));
      }
    }
  }, [open, gridApi.getSelectedRows()[0]]);

  console.log(KPIEventID);

  useEffect(() => {
    if (projectID && projectOptions.length) {
      setProjectName(
        projectOptions.filter((option) => option.value === projectID)[0]
          .projectname
      );
    }
  }, [projectID, projectOptions]);

  // useEffect(() => {
  //   setKPIEventID(uuidv4());
  // }, [open]);

  useEffect(() => {
    getProjects();
    getKPICriteria();
  }, []);

  const getProjects = () => {
    Axios.get(`${API_ROOT}/api/kpi/project-options`)
      .then((res) => {
        setProjectOptions(res.data.projectOptions);
      })
      .catch((err) => console.log(err));
  };

  const getKPICriteria = () => {
    Axios.get(`${API_ROOT}/api/kpi/safety-criteria`)

      .then((res) => {
        setCriteria(JSON.parse(res.data.criteria));
        setSections(JSON.parse(res.data.sections));
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    if (edited) {
      handleSave();
    } else {
      setOpen(false);
      setProjectID(null);
      setSurveyDate(null);
      setEdited(false);
    }
  };

  const saveResult = (value, id) => {
    console.log(id);
    Axios.post(`${API_ROOT}/api/kpi/safety-results`, {
      params: {
        KPIEventID,
        projectID,
        location,
        comments,
        surveyDate,
        criteriaID: id,
        value,
      },
    })
      .then((res) => {
        // console.log(res);
        setEdited(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSave = () => {
    Axios.post(`${API_ROOT}/api/kpi/safety-results`, {
      params: {
        KPIEventID,
        projectID,
        location,
        comments,
        surveyDate,
        criteriaID: null,
        value: null,
      },
    })
      .then((res) => {
        getSafetySurveyData(true);
        setProjectID(null);
        setSurveyDate(null);
        setEdited(false);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      className="EditInspectionModalNew"
      closeOnDimmerClick={false}
      open={open}
      onClose={handleClose}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>
        Safety Inspection{projectName ? `: ${projectName}` : null}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Dropdown
            className="disabled"
            selectOnBlur={false}
            search
            selection
            disabled
            label="Project"
            // onChange={(e, { value }) => setProjectID(value)}
            value={projectID}
            options={projectOptions}
          />
          <Form.Group widths="equal">
            {/* <Form.Input
              disabled={true}
              label="Project Name"
              value={projectName}
            /> */}
            <Form.Input
              className="disabled"
              disabled
              label="Area of Inspection"
              value={location}
              // onChange={(e, { value }) => setLocation(value)}
            />
            <SemanticDatepicker
              datePickerOnly={true}
              format="MM-DD-YYYY"
              disabled
              clearable={false}
              readOnly
              label="Survey Date"
              value={surveyDate}
              // onChange={(e, { value }) => setSurveyDate(value)}
            />
          </Form.Group>
        </Form>

        {Array.isArray(sections) && sections.length
          ? sections.map((object) => (
              <Fragment key={object.section}>
                <Table striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width="12">
                        {object.section}
                      </Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Header></Table.Header>
                  <Table.Body>
                    {Array.isArray(criteria) && criteria.length
                      ? criteria
                          .filter((obj) => obj.Section === object.section)
                          .map((item) => (
                            <Table.Row key={item.ID}>
                              <Table.Cell>
                                {item.RowNumber + ") " + item.Criteria}
                              </Table.Cell>
                              <Table.Cell>
                                <CriteriaOptions
                                  open={open}
                                  result={
                                    results
                                      ? results.find(
                                          (result) =>
                                            result.CriteriaID === item.ID
                                        )
                                      : null
                                  }
                                  setEdited={setEdited}
                                  projectID={projectID}
                                  location={location}
                                  comments={comments}
                                  surveyDate={surveyDate}
                                  KPIEventID={KPIEventID}
                                  options={item.Options}
                                  id={item.ID}
                                  saveResult={saveResult}
                                />
                              </Table.Cell>
                            </Table.Row>
                          ))
                      : null}
                  </Table.Body>
                </Table>
              </Fragment>
            ))
          : null}
        <Form>
          <Form.Field>
            <label>Solutions</label>
            <TextArea
              rows="8"
              readOnly
              // onChange={(e, { value }) => setComments(value)}
              value={comments}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EditInspectionModalNew;
