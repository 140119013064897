import React, { useState, useEffect } from "react";
import { Icon, Modal, Header, Button, Form } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../../../api-config";
import {
  sendEmail,
  getBaseUrl,
  getUser,
  sendEmailToGroup,
} from "../../../../../util";
import { useSelector } from "react-redux";
import useIsMounted from "../../../../../hooks/isMounted";

const SendPayAppModal = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [guid, setGuid] = useState(null);

  // AIA Modal
  const [radioValue, setRadioValue] = useState("accounting");
  const [emailOptions, setEmailOptions] = useState([]);
  const [emailValue, setEmailValue] = useState([]);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [project, setProject] = useState();

  const isMounted = useIsMounted();

  const {
    paymentApplicationType,
    projectID,
    getPaymentApplicationStatuses,
    setClientInvoicing,
  } = props;

  const fetchProject = () => {
    Axios.get(`${API_ROOT}/api/project/project?projectID=${projectID}`)
      .then((res) => {
        if (isMounted.current && res) {
          setProject(res.data.projects[0]);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (projectID) {
      fetchProject();
    }
  }, [projectID]);

  const user = useSelector((state) => getUser(state));

  const AIAInd = project ? project.BudgetInd : 0;
  const clientEmail = project ? project.ClientContactEmail : "";

  // console.log(clientEmail);

  const emailAccounting = () => {
    const subject = `Pay ${paymentApplicationType} for Project #${projectID} has been Submitted`;

    const baseUrl = getBaseUrl();
    const html = `<p>Pay ${paymentApplicationType} has been submitted for Project #${projectID}. Click <a href="${baseUrl}/browser/projects/${projectID}?i=6">here</a> to view the payment application details.</p>`;

    // Add N5 accounting email address here when going live
    sendEmailToGroup(
      "Pay App Notifications",
      "",
      "SoftwareDevelopment@cohenesrey.com",
      subject,
      "",
      html
    );
  };

  const handleClick = () => {
    setLoading(true);
    Axios.post(`${API_ROOT}/api/invoice/payment-application-status`, {
      params: {
        projectID,
        paymentApplicationType,
        status: "Approved",
      },
    })
      .then((res) => {
        if (res.data.invoicing) {
          setClientInvoicing(res.data.invoicing);
          getPaymentApplicationStatuses();
          emailAccounting();
        }
        setLoading(false);
        setIsModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleAIAClick = () => {
    setLoading(true);
    setError(false);
    if (Array.isArray(emailValue) && emailValue.length) {
      const emailList = emailValue.join(",");
      console.log(emailList);
      const emailMessage = "<p>" + message + "<p/>";
      const subject = `Payment Application for ${project.ProjectName} - ${project.WorkLocationName} Needs Approval`;
      const bcc = user.EmailAddress + ";softwaredevelopment@cohenesrey.com";
      const verbiage = `<p>A payment application for ${project.ProjectName} - ${
        project.WorkLocationName
      } needs your approval. Please click <a href="${getBaseUrl()}/payapp?i=${guid}">here</a> to view the payment application. If you have any questions or concerns, please email ${
        user.DisplayName
      } at <a href="mailto:${user.EmailAddress}">${user.EmailAddress}</a></p>`;
      const html = `${message ? verbiage + emailMessage : verbiage}`;

      Axios.post(`${API_ROOT}/api/invoice/payment-application-status`, {
        params: {
          projectID,
          paymentApplicationType,
          status: "Pending",
        },
      })
        .then((res) => {
          if (res.data.invoicing) {
            setClientInvoicing(res.data.invoicing);
            getPaymentApplicationStatuses();
            sendEmail(emailList, "", bcc, subject, "", html);
          }
          setLoading(false);
          setIsModalOpen(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const getGUID = () => {
    Axios.get(`${API_ROOT}/api/invoice/payment-application-guid`, {
      params: {
        projectID,
        paymentApplicationType,
      },
    })
      .then((res) => {
        if (isMounted.current) setGuid(res.data.guid ? res.data.guid : null);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setEmailOptions([{ text: clientEmail, value: clientEmail }]);
    getGUID();
  }, []);

  return (
    <>
      <Icon
        onClick={() => setIsModalOpen(true)}
        link
        name="send"
        color="blue"
      />

      {AIAInd ? (
        <Modal
          size="small"
          open={isModalOpen}
          onOpen={() => {
            setRadioValue("accounting");
            setMessage("");
            setIsModalOpen(true);
            setError(false);
            setEmailValue([clientEmail]);
          }}
          onClose={() => {
            setRadioValue("accounting");
            setIsModalOpen(false);
            setMessage("");
            setError(false);
            setEmailValue([]);
            setEmailOptions([{ text: clientEmail, value: clientEmail }]);
          }}
        >
          <Header icon="send" content={`Pay ${paymentApplicationType}`} />
          <Modal.Content>
            <Form>
              <Form.Group>
                <Form.Radio
                  checked={radioValue === "client"}
                  value="client"
                  label="Send to client for approval"
                  onChange={() => {
                    setRadioValue("client");
                    setEmailValue([clientEmail]);
                    setMessage("");
                  }}
                />
                <Form.Radio
                  checked={radioValue === "accounting"}
                  value="accounting"
                  label="Send to accounting for invoicing"
                  onChange={() => setRadioValue("accounting")}
                />
              </Form.Group>
              {radioValue === "client" ? (
                <>
                  <Form.Dropdown
                    required
                    error={!emailValue.length && error}
                    label="Client Email Address"
                    onAddItem={(e, { value }) =>
                      setEmailOptions([{ text: value, value }, ...emailOptions])
                    }
                    onChange={(e, { value }) => setEmailValue(value)}
                    value={emailValue}
                    options={emailOptions}
                    multiple
                    allowAdditions
                    selection
                    search
                    closeOnChange
                  />
                  <Form.TextArea
                    onChange={(e, { value }) => setMessage(value)}
                    value={message}
                    label="Include Message to Client"
                    rows="4"
                  />
                </>
              ) : null}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                setIsModalOpen(false);
                setRadioValue("accounting");
                setError(false);
                setMessage("");
                setEmailValue([]);
                setEmailOptions([{ text: clientEmail, value: clientEmail }]);
              }}
              // basic
              color="yellow"
              // inverted
            >
              <Icon name="remove" /> Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() =>
                radioValue === "client" ? handleAIAClick() : handleClick()
              }
              color="green"
              // inverted
            >
              <Icon name="send" /> Send
            </Button>
          </Modal.Actions>
        </Modal>
      ) : (
        <Modal
          basic
          size="mini"
          open={isModalOpen}
          onOpen={() => setIsModalOpen(true)}
          onClose={() => setIsModalOpen(false)}
        >
          <Header icon="send" content={`Pay ${paymentApplicationType}`} />
          <Modal.Content>
            <p>
              {`Are you sure you want to send Pay ${paymentApplicationType} to
            accounting?`}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => setIsModalOpen(false)}
              basic
              color="yellow"
              inverted
            >
              <Icon name="remove" /> Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() => handleClick()}
              color="green"
              inverted
            >
              <Icon name="checkmark" /> Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

export default SendPayAppModal;
