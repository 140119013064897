import React, { Fragment, useState } from "react";
import { Accordion, Button, Divider, Icon, Table } from "semantic-ui-react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
import Axios from "axios";
import { API_ROOT } from "../../../../../../api-config";
dayjs.extend(utc);
dayjs.extend(timezone);

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const InventoryList = ({ inventoryLog, setInventoryLog }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [activeDeleteIndex, setActiveDeleteIndex] = useState(-1);
  const [deleting, setDeleting] = useState(false);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
    setActiveDeleteIndex(-1);
  };

  const handleDeleteClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeDeleteIndex === index ? -1 : index;

    setActiveDeleteIndex(newIndex);
  };

  const confirmDelete = (id, conditionID) => {
    setDeleting(true);
    Axios.post(`${API_ROOT}/api/project/delete-inventory-item`, {
      id,
      conditionID,
    })
      .then((res) => {
        if (res.data) {
          setInventoryLog(res.data);
          setActiveIndex(-1);
          setActiveDeleteIndex(-1);
          setDeleting(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setDeleting(false);
      });
  };

  const inventoryLogLength = inventoryLog.length;
  return (
    <div style={{ marginTop: 40 }}>
      {Array.isArray(inventoryLog) && inventoryLogLength ? (
        <>
          <Divider horizontal>This Condition</Divider>
          <Accordion styled fluid>
            {inventoryLog.map((obj, i) => {
              return (
                <Fragment key={obj.ID}>
                  <Accordion.Title
                    style={{ background: "#b5ddea6b" }}
                    active={activeIndex === i}
                    index={i}
                    onClick={handleClick}
                    key={obj.ID}
                  >
                    <Icon name="dropdown" />
                    {obj.Description}
                    <h6
                      style={{
                        padding: 0,
                        margin: 0,
                        color: "grey",
                        marginLeft: 20,
                      }}
                    >{`${obj.Quantity} - ${obj.BaseUOM}`}</h6>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === i}>
                    <Table compact="very" unstackable definition>
                      <Table.Header>
                        <Table.Row>
                          <Table.Cell>Name</Table.Cell>
                          <Table.Cell>{obj.DisplayName}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Date</Table.Cell>
                          <Table.Cell>
                            {dayjs
                              .tz(new Date(obj.Date), "America/Chicago")
                              .format("M/D/YYYY")}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Unit Cost</Table.Cell>
                          <Table.Cell>
                            {formatter.format(obj.ItemCost)}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Quantity</Table.Cell>
                          <Table.Cell>{obj.Quantity}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>UOM</Table.Cell>
                          <Table.Cell>{obj.BaseUOM}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>Total Cost</Table.Cell>
                          <Table.Cell>
                            {formatter.format(obj.TotalCost)}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Header>
                    </Table>
                    <Accordion styled style={{ border: 0 }}>
                      <Accordion.Title
                        active={activeDeleteIndex === i}
                        index={i}
                        onClick={handleDeleteClick}
                      >
                        <Icon name="remove" /> Delete Inventory Item
                      </Accordion.Title>
                      <Accordion.Content active={activeDeleteIndex === i}>
                        Are you sure?
                        <div style={{ marginTop: 20 }}>
                          <Button
                            disabled={deleting}
                            onClick={handleDeleteClick}
                          >
                            No
                          </Button>
                          <Button
                            loading={deleting}
                            disabled={deleting}
                            color="red"
                            onClick={() =>
                              confirmDelete(obj.ID, obj.ConditionID)
                            }
                          >
                            Yes
                          </Button>
                        </div>
                      </Accordion.Content>
                    </Accordion>
                  </Accordion.Content>
                </Fragment>
              );
            })}
          </Accordion>
        </>
      ) : null}
    </div>
  );
};

export default InventoryList;
