import Axios from "axios";
import React, { useState } from "react";
import { Icon, Table } from "semantic-ui-react";
import { Link, useRouteMatch } from "react-router-dom";

import { API_ROOT } from "../../../../api-config";

const ApprovalIcons = ({ obj, data, setData }) => {
  const { url } = useRouteMatch();

  const [loading, setLoading] = useState(false);

  const handleApproveClick = (conditionID) => {
    setLoading(true);
    Axios.post(`${API_ROOT}/api/condition/approve-condition-template`, {
      conditionID,
    }).then((res) => {
      const newData = [...data];
      const index = newData.findIndex((obj) => obj.ConditionID === conditionID);

      newData[index] = res.data;
      setData(newData);
      setLoading(false);
    });
  };

  const handleRejectClick = (conditionID) => {
    setLoading(true);
    Axios.post(`${API_ROOT}/api/condition/reject-condition-template`, {
      conditionID,
    }).then((res) => {
      const newData = [...data];
      const index = newData.findIndex((obj) => obj.ConditionID === conditionID);

      newData[index] = res.data;
      setData(newData);
      setLoading(false);
    });
  };

  return (
    <Table.Cell>
      {obj.ActiveInd === 0 && obj.RejectedInd === 0 ? (
        <>
          {obj.ApprovalType === "Overwrite" && (
            <Link to={`${url}/${obj.ConditionID}`}>
              <Icon
                title="Approve Condition Template"
                loading={loading}
                color="green"
                name={loading ? "spinner" : "check"}
              />
            </Link>
          )}
          {obj.ApprovalType !== "Overwrite" && (
            <Icon
              title="Approve Condition Template"
              loading={loading}
              onClick={() => handleApproveClick(obj.ConditionID)}
              color="green"
              link
              name={loading ? "spinner" : "check"}
            />
          )}
          <Icon
            title="Reject Condition Template"
            loading={loading}
            onClick={() => handleRejectClick(obj.ConditionID)}
            color="red"
            link
            name={loading ? "spinner" : "remove"}
          />
        </>
      ) : obj.ActiveInd === 1 ? (
        <Icon
          title="Deactivate Condition Template"
          loading={loading}
          onClick={() => handleRejectClick(obj.ConditionID)}
          color="red"
          link
          name={loading ? "spinner" : "remove"}
        />
      ) : (
        <Icon
          title="Reactivate Condition Template"
          loading={loading}
          onClick={() => handleApproveClick(obj.ConditionID)}
          color="green"
          link
          name={loading ? "spinner" : "undo"}
        />
      )}
    </Table.Cell>
  );
};

export default ApprovalIcons;
