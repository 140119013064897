import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { setInclusionMenuSelection } from '../../../actions/project/inclusion/inclusionActions'
import Inclusions from '../../../components/Inclusions/Inclusions'

export default () => {
  const {
    params: { mainCategory, subCategory },
  } = useRouteMatch()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setInclusionMenuSelection({
        selectedCategoryID: parseInt(mainCategory),
        selectedSubCategoryCode: subCategory,
      }),
    )
  }, [mainCategory, subCategory])

  return <Inclusions />
}
