import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";
import useIsMounted from "../../../hooks/isMounted";
import TimesheetTable from "./TimesheetTable";
import "./WeeklyTimesheet.scss";

const WeeklyTimesheet = () => {
  const [weekDiff, setWeekDiff] = useState(0);
  const [periodStartDate, setPeriodStartDate] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [activities, setActivities] = useState([]);

  const isMounted = useIsMounted();

  const fetchTimeActivities = () => {
    if (periodStartDate && isMounted.current) {
      Axios.get(`${API_ROOT}/api/project/time-activities`, {
        params: {
          periodStartDate,
        },
      })
        .then((res) => {
          if (res.data && isMounted.current) {
            setActivities(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (periodStartDate) {
      fetchTimeActivities();
    }
  }, [periodStartDate]);

  const today = moment();
  const selectedDate = today.add(weekDiff, "week");
  // console.log(selectedDate);
  const from_date = moment(selectedDate)
    .startOf("week")
    .subtract(1, "day");
  const to_date = moment(selectedDate)
    .endOf("week")
    .subtract(1, "day");

  useEffect(() => {
    if (from_date) {
      setPeriodStartDate(from_date.format("l"));
    }
  }, [from_date]);

  useEffect(() => {
    if (periodStartDate && isMounted.current) {
      Axios.get(`${API_ROOT}/api/project/timesheet`, {
        params: {
          periodStartDate,
        },
      })
        .then((res) => {
          if (res.data && isMounted.current) {
            setRowData(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [periodStartDate]);

  return (
    <Container
      style={{ paddingLeft: 10, paddingRight: 10 }}
      fluid
      className="WeeklyTimesheet"
    >
      <h1 style={{ color: "rgb(254, 80, 0)", marginLeft: 40 }}>
        Weekly Timesheet
      </h1>

      <TimesheetTable
        rowData={rowData}
        fromDate={from_date}
        periodStartDate={periodStartDate}
        activities={activities}
        weekDiff={weekDiff}
        setWeekDiff={setWeekDiff}
        from_date={from_date}
        to_date={to_date}
      />
    </Container>
  );
};

export default WeeklyTimesheet;
