import React, { useState, useEffect } from "react";
import { withRouter, Prompt } from "react-router-dom";
import {
  Container,
  Grid,
  Header,
  Form,
  Segment,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import Axios from "axios";
import { useSelector } from "react-redux";

import "./Inclusions.scss";
import InclusionClientHOT from "./InclusionClientHOT/InclusionClientHOT";
import ConditionWrapper from "./ConditionWrapper/ConditionWrapper";
import { API_ROOT } from "../../api-config";
import useIsMounted from "../../hooks/isMounted";

const Inclusions = (props) => {
  const [projectID, setProjectID] = useState(parseInt(props.match.params.id));
  const [project, setProject] = useState();
  const [selectedCategoryID, setSelectedCategoryID] = useState("");
  const [selectedSubCategoryCode, setSelectedSubCategoryCode] = useState("");
  const [menuSelected, setMenuSelected] = useState(false);
  const [inclusionDescriptions, setInclusionDescriptions] = useState([]);
  const [
    missingInclusionDescription,
    setMissingInclusionDescription,
  ] = useState(false);
  const [missingSubDescription, setMissingSubDescription] = useState(false);
  const [missingInternalDescription, setMissingInternalDescription] = useState(
    false
  );

  const inclusion = useSelector((state) => state.inclusion);

  const isMounted = useIsMounted();

  useEffect(() => {
    setSelectedCategoryID(inclusion.selectedCategoryID);
    setSelectedSubCategoryCode(inclusion.selectedSubCategoryCode);
  }, [inclusion]);

  useEffect(() => {
    setProjectID(parseInt(props.match.params.id));
  }, [props.match.params.id]);

  const fetchProject = () => {
    if (projectID) {
      Axios.get(`${API_ROOT}/api/project/project?projectID=${projectID}`)
        .then((res) => {
          if (isMounted.current && res) {
            setProject(res.data.projects[0]);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    fetchProject();
  }, [projectID]);

  useEffect(() => {
    if (projectID && project && selectedSubCategoryCode) {
      getInclusionDescriptions();
    }
  }, [projectID, project, selectedSubCategoryCode]);

  const getInclusionDescriptions = () => {
    if (project.ProposalFormatType === "Summary") {
      Axios.get(`${API_ROOT}/api/inclusion/descriptions`, {
        params: {
          projectID,
          subCategoryCode: selectedSubCategoryCode,
        },
      })
        .then((res) => {
          let missingDescription = false;

          if (res.data.inclusionDescriptions) {
            res.data.inclusionDescriptions.forEach((item) =>
              !item.Description && !item.ReadOnlyInd
                ? (missingDescription = true)
                : null
            );
          }

          setInclusionDescriptions(res.data.inclusionDescriptions || []);
          setMissingInclusionDescription(missingDescription);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if ((selectedCategoryID, selectedSubCategoryCode)) {
      setMenuSelected(true);
    }
  }, [project, selectedCategoryID, selectedSubCategoryCode]);

  useEffect(() => {
    if (menuSelected && project) {
      getInclusionDescriptions();
    }
  }, [menuSelected, project]);

  if (!project) {
    return (
      <Segment basic style={{ marginTop: 40 }}>
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      </Segment>
    );
  }

  if (menuSelected) {
    let message = "";
    if (
      missingInternalDescription ||
      missingInclusionDescription ||
      missingSubDescription
    ) {
      message =
        "There are missing inclusion, internal, or subcontractor descriptions. Are you sure you want to go to leave this page?";
    }

    return (
      <Container
        key={selectedSubCategoryCode}
        style={{ paddingLeft: 40, paddingRight: 40 }}
        className="Inclusions"
        fluid
      >
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Header>
                {project.ProjectName}
                <Header.Subheader>{`Project #${projectID}`}</Header.Subheader>
              </Header>

              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column
                    className="HotColumn"
                    width={16}
                    // style={{ padding: 20, overflow: 'hidden' }}
                  >
                    {/* Only show textarea if project's proposal format is 'Detailed' */}
                    {project.ProposalFormatType === "Summary" ? (
                      <>
                        <label style={{ color: "#f2711c" }}>
                          Summarize all of the conditions below. These are the
                          only descriptions that will show on the customer
                          summary.
                        </label>
                        <InclusionClientHOT
                          projectID={projectID}
                          subCategoryCode={selectedSubCategoryCode}
                          inclusionDescriptions={inclusionDescriptions}
                          setMissingInclusionDescription={
                            setMissingInclusionDescription
                          }
                        />
                      </>
                    ) : (
                      ""
                    )}
                    <Prompt
                      when={
                        missingInclusionDescription === true ||
                        missingSubDescription === true ||
                        missingInternalDescription === true
                      }
                      message={message}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Form size={"tiny"}>
                <div style={{ maxWidth: 1920 }}>
                  <Segment
                    basic
                    clearing
                    style={{ marginBottom: 0, paddingBottom: 0 }}
                  >
                    <Header
                      color="orange"
                      as="h4"
                      floated="left"
                      style={{
                        marginTop: 20,
                        paddingBottom: 0,
                        marginBottom: 0,
                      }}
                    >
                      Conditions
                    </Header>
                  </Segment>
                  <ConditionWrapper
                    projectID={projectID}
                    subCategoryCode={selectedSubCategoryCode}
                    menuSelected={menuSelected}
                    setMissingSubDescription={setMissingSubDescription}
                    setMissingInternalDescription={
                      setMissingInternalDescription
                    }
                    getInclusionDescriptions={getInclusionDescriptions.bind(
                      this
                    )}
                  />
                </div>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  } else {
    return "";
  }
};

export default withRouter(Inclusions);
