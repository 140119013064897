import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import Axios from "axios";

import { API_ROOT } from "../../../../api-config";
import useIsMounted from "../../../../hooks/isMounted";

const getOptions = async () => {
  try {
    const options = await Axios.get(
      `${API_ROOT}/api/project/worklocationnames`
    );
    return options.data.workLocationNames || [];
  } catch (err) {
    console.error(Error(`Error fetching results list: ${err.message}`));
  }
};

const WorkLocationName = ({ projectID, saveValue, valueProp }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(valueProp);

  const isMounted = useIsMounted();

  useEffect(async () => {
    setIsFetching(true);
    const optionsResponse = await getOptions();

    if (isMounted.current && optionsResponse) {
      setOptions(optionsResponse);
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const handleAddition = (e, { value }) => {
    setOptions((prevState) => {
      return [...prevState, { text: value, value, key: value }];
    });

    saveValue(projectID, "workLocationName", value);
  };

  const handleChange = (e, { value }) => {
    setValue(value);
    saveValue(projectID, "workLocationName", value);
  };

  return (
    <Dropdown
      style={{ marginBottom: 10 }}
      autoComplete="off"
      selectOnBlur={false}
      fluid
      clearable
      onAddItem={handleAddition}
      allowAdditions
      selection
      multiple={false}
      search={true}
      options={options || []}
      value={value}
      placeholder=""
      onChange={handleChange}
      disabled={isFetching}
      loading={isFetching}
    />
  );
};

export default WorkLocationName;
