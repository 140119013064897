import React from "react";
import { Menu, Icon, Image } from "semantic-ui-react";
import { withRouter, Route, Switch, useHistory, Link } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";

import logo from "./N5Group.png";
import Assignments from "./Assignments/Assignments";
import ProjectsWrapper from "./Projects/ProjectsWrapper";
import ProjectWrapper from "./Project/ProjectWrapper";
import IncentiveAnalysisWrapper from "../Admin/IncentiveAnalysis/IncentiveAnalysisWrapper";

import { API_ROOT } from "../../api-config";
import KPIWrapper from "../Dashboard/KPI/KPIWrapper";
import DispatchWrapper from "./Dispatch/DispatchWrapper";
import Receipts from "./Receipts/Receipts";
import Surveys from "./Surveys/Surveys";

import { useLoggedInUser } from "./hooks";
import { logout } from "../../actions/auth/actions";

const Mobile = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  let loggedInUser = useLoggedInUser("DisplayName");

  return (
    <div
      style={{
        width: "100%",
        paddingTop: 27,
        // paddingBottom: 27,
        msOverflowX: "hidden",
      }}
    >
      <Route path="/m">
        <Menu borderless icon fixed="top" fluid>
          <Link to="/home">
            <Menu.Item link name="Back">
              <Image src={logo} style={{ width: 60 }} />
            </Menu.Item>
          </Link>
          <Menu.Menu position="right">
            <Menu.Item>{loggedInUser || ""}</Menu.Item>
            <Menu.Item
              title="Log out"
              href={`${API_ROOT}/auth/logout`}
              as="a"
              onClick={() => logout()(dispatch)}
            >
              <Icon name="sign-out" />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </Route>
      <Switch>
        <Route exact path="/m">
          <Menu
            icon="labeled"
            size="massive"
            style={{ textAlign: "center", borderRadius: 0, marginBottom: 40 }}
            fluid
            color="grey"
            inverted
            vertical
          >
            <Link to="/m/assignments">
              <Menu.Item link name="Assignments / Timesheet">
                <Icon name="clock" />
                Assignments / Timesheet
              </Menu.Item>
            </Link>
            <Link
              to={`/m/dispatch?assignmentDate=${moment().format(
                "YYYY-MM-DD"
              )}&v=teamMember`}
            >
              <Menu.Item
                link
                name="Dispatch Board"
                style={{
                  borderTop: "1px solid rgba(34,36,38,.1)",
                }}
              >
                <Icon name="shipping fast" />
                Dispatch Board
              </Menu.Item>
            </Link>
            <Link to="/m/projects">
              <Menu.Item
                link
                name="Projects"
                style={{
                  borderTop: "1px solid rgba(34,36,38,.1)",
                }}
              >
                <Icon name="wrench" />
                Projects
              </Menu.Item>
            </Link>
            <Link to="/m/receipts/">
              <Menu.Item
                link
                name="Receipts"
                style={{
                  borderTop: "1px solid rgba(34,36,38,.1)",
                }}
              >
                <Icon name="camera" />
                Import Receipt
              </Menu.Item>
            </Link>
            <Link to="/m/surveys">
              <Menu.Item
                style={{
                  borderTop: "1px solid rgba(34,36,38,.1)",
                }}
                link
                name="Surveys"
              >
                <Icon name="star" />
                Surveys
              </Menu.Item>
            </Link>
            <Link to={`/m/kpi?q=${moment().quarter()}&y=${moment().year()}`}>
              <Menu.Item
                link
                name="KPI / Analytics"
                style={{
                  borderTop: "1px solid rgba(34,36,38,.1)",
                }}
              >
                <Icon name="chart line" />
                KPI / Analytics
              </Menu.Item>
            </Link>
            <Menu.Item link name="CRM / Vendors / Contacts">
              <Icon name="users" />
              CRM / Vendors / Contacts
            </Menu.Item>

            <Menu.Item
              target="_blank"
              rel="noopener noreferrer"
              href="https://access.paylocity.com/"
              link
              name="Paylocity"
            >
              <Icon name="money" />
              Paylocity/PTO
            </Menu.Item>
            <Link to="/m/incentive-analysis">
              <Menu.Item
                link
                name="Realtime Incentive Analysis"
                style={{
                  borderTop: "1px solid rgba(34,36,38,.1)",
                }}
              >
                <Icon name="calculator" />
                Realtime Incentive Analysis
              </Menu.Item>
            </Link>
            <Menu.Item
              target="_blank"
              rel="noopener noreferrer"
              href={`https://ce-knet.com/Apps/USR/profile.html`}
              link
              name="Your Profile Page"
            >
              <Icon name="user circle" />
              Your Profile Page
            </Menu.Item>
            <Menu.Item link name="Events">
              <Icon name="calendar alternate outline" />
              Events
            </Menu.Item>
            <Menu.Item link name="Marketing Presentation">
              <Icon name="file video outline" />
              Marketing Presentation
            </Menu.Item>
          </Menu>
        </Route>
        <Route path="/m/assignments">
          <div style={{ marginTop: 20 }}>
            <Assignments />
          </div>
        </Route>
        <Route path="/m/dispatch">
          <div style={{ marginTop: 20 }}>
            <DispatchWrapper />
          </div>
        </Route>
        <Route path="/m/projects/:id">
          <ProjectWrapper style={{ marginTop: 20 }} />
        </Route>
        <Route exact path="/m/projects">
          <div style={{ marginTop: 20 }}>
            <ProjectsWrapper />
          </div>
        </Route>
        <Route path="/m/receipts/:type?">
          <div style={{ marginTop: 20 }}>
            <Receipts />
          </div>
        </Route>
        <Route exact path="/m/incentive-analysis">
          <div style={{ marginTop: 20 }}>
            <IncentiveAnalysisWrapper />
          </div>
        </Route>
        <Route path="/m/surveys">
          <div style={{ marginTop: 20 }}>
            <Surveys />
          </div>
        </Route>
        <Route path="/m/kpi">
          <div style={{ paddingTop: 40, paddingBottom: 40 }}>
            <KPIWrapper />
          </div>
        </Route>
      </Switch>
      <Route path="/m">
        <Menu borderless icon fixed="bottom" fluid widths={2}>
          <Menu.Item link onClick={() => history.goBack()} name="Back">
            <Icon name="angle left" />
          </Menu.Item>

          <Menu.Item
            onClick={() => {
              if (history.location.pathname === "/m") {
                history.replace("/m");
              } else {
                history.push("/m");
              }
            }}
            // as={Link} to="/m"
            link
            name="Home"
          >
            <Icon name="home" />
          </Menu.Item>
        </Menu>
      </Route>
    </div>
  );
};

export default withRouter(Mobile);
