import React, { Component } from "react";
import {
  Container,
  Header,
  Grid,
  Form,
  Segment,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import { setProjectInfo } from "../../../actions/project/projectActions";

import { API_ROOT } from "../../../api-config";

import "./ProjectInfo.scss";
import Axios from "axios";
import ClientContact from "./ClientContact/ClientContact";
import AutoSuggest from "../../sharedComponents/AutoSuggest/AutoSuggest";
import LabelList from "../../Browser/ProjectsTable/IconGrid/NotesModal/Notifications/LabelList/LabelList";
import WorkLocationName from "./WorkLocationName/WorkLocationName";
import Forecasting from "./Forecasting/Forecasting";
import ClientCompany from "./ClientCompany/ClientCompany";
// import WorkLocationAddress from "./WorkLocationAddress/WorkLocationAddressNew";
import WorkLocationAddressInput from "./WorkLocationAddress/WorkLocationAddressInput";
import BillingContact from "./BillingContact/BillingContact";
import BillingAddress from "./BillingAddress/BillingAddress";
import Flags from "./Flags/Flags";
import { userIsInRole } from "../../../util";
import ProjectTaskSpreadsheet from "./ProjectTaskSpreadsheet/ProjectTaskSpreadsheet";

class ProjectInfo extends Component {
  state = {
    loading: true,
    currentProjectID: "",
    teamMemberOptions: [],
    projectManager: "",
    projectName: "",
    projectDescription: "",
    exclusions: "",
    clientContact: "",
    clientCompany: "",
    workLocationName: "",
    workLocationAddress: "",
    billingContactID: "",
    billingAddress: "",
    bidType: "",
    workDays: "",
    stageType: "",
    KSRemodelingTaxInd: "",
    submittedDate: "",
    retention: "",
    budgetInd: "",
    superintendent: "",
    probability: "",
    projectEngineer: "",
    proposalFormatType: "",
    contractSentDate: "",
    clientApprovalDate: "",
    completedInd: "",
    completedDate: "",
  };

  getDisplayNames = () => {
    Axios.get(`${API_ROOT}/api/user/displaynames`)
      .then((res) => {
        if (this._isMounted) {
          this.setState({ teamMemberOptions: JSON.parse(res.data.users) });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getProjectNotifications = () => {
    Axios.get(
      `${API_ROOT}/api/project/projectnotifications/${parseInt(
        this.props.match.params.id
      )}`
    )
      .then((res) => {
        if (this._isMounted) {
          this.setState({ notifications: JSON.parse(res.data.notifications) });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addNotification = (userID) => {
    const projectID = this.state.currentProjectID;

    let userExists = false;
    if (this.state.notifications) {
      userExists = this.state.notifications.some(
        (notification) => notification.UserID === userID
      );
    }

    if (!userExists) {
      Axios.post(`${API_ROOT}/api/project/projectnotification`, {
        params: { projectID, userID },
      })
        .then((res) => {
          if (this._isMounted) {
            this.setState({
              notifications: JSON.parse(res.data.notifications),
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  deleteNotification = (userID) => {
    const projectID = this.state.currentProjectID;
    Axios.delete(`${API_ROOT}/api/project/projectnotification`, {
      params: { projectID, userID },
    })
      .then((res) => {
        if (this._isMounted) {
          this.setState({ notifications: JSON.parse(res.data.notifications) });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadData = () => {
    this.setState({ loading: true });
    const currentProjectID = parseInt(this.props.match.params.id);
    // const currentProject = this.props.projects.find(
    //   (item) => item.ProjectID === currentProjectID
    // );

    Axios.get(`${API_ROOT}/api/project/project?projectID=${currentProjectID}`)
      .then((res) => {
        const currentProject = res.data.projects[0];
        if (this._isMounted && currentProject) {
          this.setState({
            loading: false,
            currentProjectID: currentProjectID || "",
            projectManager: currentProject.ProjectManagerID || "",
            projectName: currentProject.ProjectName || "",
            jobNum: "",
            projectDescription: currentProject.ProjectDescription || "",
            exclusions:
              currentProject.Exclusions ||
              "Only the items specifically listed above are included in the pricing.  Additional items are subject to additional cost. Bonds | Overtime | Phasing | Permits | Dumpsters",
            clientContact: currentProject.ClientContactID || "",
            clientCompany: currentProject.ClientCompanyID || "",
            workLocationName: currentProject.WorkLocationName || "",
            workLocationAddress: currentProject.WorkLocationAddress || "",
            billingContactID: currentProject.BillingContactID || "",
            billingAddress: currentProject.BillingAddress || "",
            bidType: currentProject.BidType || "",
            workDays: currentProject.Days || "",
            stageType: currentProject.StageType || "",
            KSRemodelingTaxInd: currentProject.KSRemodelingTaxInd,
            submittedDate: currentProject.SubmittedDate || "",
            retention: currentProject.Retention
              ? currentProject.Retention * 100
              : 0,
            budgetInd: currentProject.BudgetInd,
            superintendent: currentProject.SuperintendentID || "",
            probability: currentProject.Probability * 100 || "",
            projectEngineer: currentProject.ProjectEngineerID || "",
            options: [],
            //notifications: [],
            proposalFormatType: currentProject.ProposalFormatType || "",
            completedInd: currentProject.CompletedInd,
            completedDate: currentProject.CompletedDate || "",
            contractSentDate: currentProject.ContractSentDate || "",
            clientApprovalDate: currentProject.ClientApprovalDate || "",
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.loggedIn) {
      this.props.history.push("/");
    }
    this.getDisplayNames();
    this.loadData();
    this.getProjectNotifications();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  saveValue = (projectID, field, value) => {
    Axios.post(`${API_ROOT}/api/project/setprojectinfo`, {
      params: { projectID, field, value },
    })
      .then((res) => {
        // let project = res.data.project[0];
        // this.props.dispatch(setProjectInfo(project));
        if (this._isMounted) {
          this.setState({ [field]: value });
        }

        // this.loadData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name) && this._isMounted) {
      this.setState({ [name]: value });
    }
  };

  handleSelect = (event, { name, value }) => {
    const projectID = this.state.currentProjectID;
    if (this.state.hasOwnProperty(name) && this._isMounted) {
      this.setState({ [name]: value }, () => {
        this.saveValue(projectID, name, value);
      });
    }
  };

  handleBlur = (event) => {
    const projectID = this.state.currentProjectID;
    const field = event.target.name;
    const value = this.state[field].toString();
    this.saveValue(projectID, field, value);
  };

  render() {
    const teamMemberDropdownOptions = this.state.teamMemberOptions.map(
      (option) => {
        return {
          key: option.UserID,
          text: option.DisplayName,
          value: option.UserID,
        };
      }
    );

    const teamMemberOptions = this.state.teamMemberOptions.map((option) => {
      return {
        label: option.DisplayName,
        value: option.UserID,
      };
    });

    return this.state.loading ? (
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    ) : (
      <Container
        className="ProjectInfo"
        style={{
          // paddingLeft: 30,
          paddingTop: 10,
          // paddingRight: 30,
          paddingBottom: 40,
          overflow: "auto",
          minHeight: "calc(100vh - 160px)",
        }}
        fluid
      >
        <Grid stackable style={{ margin: 0 }}>
          <Grid.Row columns={4}>
            <Grid.Column tablet={8} computer={8} largeScreen={3} widescreen={3}>
              {/* <div style={{ display: 'inline-block' }}> */}
              <Segment
                clearing
                basic
                style={{ margin: "0 0 13px 0", padding: 0 }}
              >
                <Header
                  floated="left"
                  style={{ color: "#fe5000", display: "inline" }}
                  as="h1"
                >
                  Project Info
                </Header>
                {/* <Button size="stiny" floated="right">
                  Copy Proposal
                </Button>
                <Button size="tiny" color="orange" floated="right">
                  Job Start Up
                </Button> */}
              </Segment>

              {/* </div> */}
              <Form autoComplete="off">
                <Form.Group>
                  <Form.Input
                    autoComplete="off"
                    name="projectName"
                    label="Project Name"
                    width={16}
                    value={this.state.projectName}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Input
                    autoComplete="off"
                    style={{ minWidth: 80 }}
                    className="projectid"
                    readOnly
                    label="Project ID"
                    width={4}
                    value={this.state.currentProjectID}
                  />
                </Form.Group>
                <Form.TextArea
                  name="projectDescription"
                  rows="7"
                  label="Project Description"
                  value={this.state.projectDescription}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />
                <Form.TextArea
                  name="exclusions"
                  rows="7"
                  label="Clarifications/Exclusions"
                  value={this.state.exclusions}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />
              </Form>
            </Grid.Column>
            <Grid.Column
              style={{ marginTop: 50 }}
              tablet={8}
              computer={8}
              largeScreen={4}
              widescreen={4}
            >
              {/* <Button style={{ marginBottom: 14 }}>Copy Proposal</Button> */}
              <Form>
                <Form.Group autoComplete="off">
                  <ClientContact
                    projectID={this.state.currentProjectID}
                    saveValue={this.saveValue}
                    valueProp={this.state.clientContact}
                  />

                  <ClientCompany
                    projectID={this.state.currentProjectID}
                    saveValue={this.saveValue}
                    valueProp={this.state.clientCompany}
                  />
                </Form.Group>
                <label
                  style={{
                    display: "block",
                    marginBottom: 3,
                    fontSize: ".92857143em",
                  }}
                >
                  Work Location Name
                </label>
                <WorkLocationName
                  projectID={this.state.currentProjectID}
                  saveValue={this.saveValue}
                  valueProp={this.state.workLocationName}
                />

                {/* <WorkLocationAddress
                  projectID={this.state.currentProjectID}
                  saveValue={this.saveValue}
                  value={this.state.workLocationAddress}
                /> */}

                <WorkLocationAddressInput
                  projectID={this.state.currentProjectID}
                  saveValue={this.saveValue}
                  value={this.state.workLocationAddress}
                />

                <BillingContact
                  projectID={this.state.currentProjectID}
                  saveValue={this.saveValue}
                  valueProp={this.state.billingContactID}
                />

                <BillingAddress
                  projectID={this.state.currentProjectID}
                  saveValue={this.saveValue}
                  valueProp={this.state.billingAddress}
                />

                <Segment>
                  <div className="AutoSuggest">
                    <label style={{ display: "block", marginBottom: 3 }}>
                      Notifications
                    </label>
                    <AutoSuggest
                      placeholder="Add a recipient"
                      options={teamMemberOptions}
                      addLabel={this.addNotification}
                    />
                    <LabelList
                      deleteLabel={this.deleteNotification}
                      labels={this.state.notifications}
                      projectID={this.state.currentProjectID}
                    />
                  </div>
                </Segment>
              </Form>
            </Grid.Column>
            <Grid.Column
              style={{ paddingTop: 50 }}
              tablet={8}
              computer={8}
              largeScreen={4}
              widescreen={4}
            >
              <Form autoComplete="off">
                <Form.Group autoComplete="off">
                  <Form.Dropdown
                    name="projectManager"
                    selection
                    selectOnBlur={false}
                    fluid
                    width={8}
                    search
                    clearable
                    label="Project Manager"
                    value={this.state.projectManager}
                    options={teamMemberDropdownOptions}
                    onChange={this.handleSelect}
                    // onBlur={this.handleBlur}
                  />
                  <Form.Input
                    name="probability"
                    width={8}
                    icon="percent"
                    label="Probability"
                    type="number"
                    min="0"
                    max="100"
                    value={this.state.probability}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Select
                    value={this.state.projectEngineer}
                    selectOnBlur={false}
                    name="projectEngineer"
                    fluid
                    search
                    clearable
                    width={8}
                    label="Project Engineer"
                    options={teamMemberDropdownOptions}
                    onChange={this.handleSelect}
                  />
                  <Form.Select
                    value={this.state.proposalFormatType}
                    selectOnBlur={false}
                    name="proposalFormatType"
                    search
                    disabled={true}
                    fluid
                    width={8}
                    label="Client Proposal Type"
                    options={[
                      {
                        key: "Detail",
                        text: "Detailed Conditions",
                        value: "Detail",
                      },
                      {
                        key: "Summary",
                        text: "Summarized Conditions",
                        value: "Summary",
                      },
                    ]}
                    onChange={this.handleSelect}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Select
                    value={this.state.superintendent}
                    name="superintendent"
                    fluid
                    selectOnBlur={false}
                    clearable
                    search
                    width={8}
                    label="Superintendent"
                    options={teamMemberDropdownOptions}
                    onChange={this.handleSelect}
                  />
                  {/* <Form.Input
                    name="stageType"
                    fluid
                    // selectOnBlur={false}
                    width={8}
                    label="Stage"
                    value={this.state.stageType}
                    // options={[
                    //   {
                    //     key: "Preparing Proposal",
                    //     text: "Preparing Proposal",
                    //     value: "Preparing Proposal"
                    //   },
                    //   {
                    //     key: "Awaiting Approval",
                    //     text: "Awaiting Approval",
                    //     value: "Awaiting Approval"
                    //   },
                    //   {
                    //     key: "Approved",
                    //     text: "Approved",
                    //     value: "Approved"
                    //   },
                    //   {
                    //     key: "In Progress",
                    //     text: "In Progress",
                    //     value: "In Progress"
                    //   },
                    //   {
                    //     key: "Completed",
                    //     text: "Completed",
                    //     value: "Completed"
                    //   },
                    //   {
                    //     key: "Rejected",
                    //     text: "Rejected",
                    //     value: "Rejected"
                    //   },
                    //   { key: "Canceled", text: "Canceled", value: "Canceled" }
                    // ]}
                    disabled
                  /> */}
                  <Form.Select
                    name="stageType"
                    fluid
                    selectOnBlur={false}
                    width={8}
                    label="Stage"
                    value={this.state.stageType}
                    onChange={this.handleSelect}
                    options={[
                      {
                        key: "Preparing Proposal",
                        text: "Preparing Proposal",
                        value: "Preparing Proposal",
                        disabled: !this.props.hasRole("PROJECT STAGE MANAGER"),
                      },
                      {
                        key: "Awaiting Approval",
                        text: "Awaiting Approval",
                        value: "Awaiting Approval",
                        disabled: !this.props.hasRole("PROJECT STAGE MANAGER"),
                      },
                      {
                        key: "Approved",
                        text: "Approved",
                        value: "Approved",
                        disabled: !this.props.hasRole("PROJECT STAGE MANAGER"),
                      },
                      {
                        key: "In Progress",
                        text: "In Progress",
                        value: "In Progress",
                        disabled: !this.props.hasRole("PROJECT STAGE MANAGER"),
                      },
                      {
                        key: "Completed",
                        text: "Completed",
                        value: "Completed",
                      },
                      {
                        key: "Rejected",
                        text: "Rejected",
                        value: "Rejected",
                      },
                      { key: "Canceled", text: "Canceled", value: "Canceled" },
                    ]}
                    disabled={!this.props.hasRole("PROJECT STAGE MANAGER")}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Dropdown
                    selection
                    name="bidType"
                    value={this.state.bidType}
                    onChange={this.handleSelect}
                    fluid
                    selectOnBlur={false}
                    search
                    width={8}
                    label="Bid Type"
                    options={[
                      {
                        key: "0",
                        text: "",
                        value: null,
                      },
                      {
                        key: "2",
                        text: "Construction -> Project",
                        value: "Construction -> Project",
                      },
                      {
                        key: "3",
                        text: "Construction -> T&M",
                        value: "Construction -> T&M",
                      },
                      {
                        key: "4",
                        text: "Service -> Flat Rate",
                        value: "Service -> Flat Rate",
                      },
                      {
                        key: "5",
                        text: "Service -> Project",
                        value: "Service -> Project",
                      },
                      {
                        key: "6",
                        text: "Service -> T&M",
                        value: "Service -> T&M",
                      },
                      {
                        key: "7",
                        text: "Mechanical -> Project",
                        value: "Mechanical -> Project",
                      },
                      {
                        key: "8",
                        text: "Mechanical -> T&M",
                        value: "Mechanical -> T&M",
                      },
                    ]}
                  />
                  <DateInput
                    name="submittedDate"
                    hideMobileKeyboard
                    dateFormat={"MM/DD/YYYY"}
                    readOnly={true}
                    width={8}
                    iconPosition="right"
                    label="Submitted Date"
                    value={this.state.contractSentDate}
                    onChange={this.handleSelect}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Select
                    name="KSRemodelingTaxInd"
                    fluid
                    selectOnBlur={false}
                    disabled={
                      this.state.stageType !== "Preparing Proposal" &&
                      this.state.stageType !== "Awaiting Approval"
                    }
                    // disabled={true}
                    width={8}
                    label="Kan Remodel Tax"
                    value={this.state.KSRemodelingTaxInd}
                    options={[
                      { key: "no", text: "No", value: 0 },
                      { key: "yes", text: "Yes", value: 1 },
                      { key: "exempt", text: "Exempt", value: -1 },
                    ]}
                    onChange={this.handleSelect}
                  />
                  <DateInput
                    value={this.state.clientApprovalDate}
                    onChange={this.handleSelect}
                    readOnly={true}
                    hideMobileKeyboard
                    dateFormat={"MM/DD/YYYY"}
                    width={8}
                    iconPosition="right"
                    label="Approved Date"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Select
                    fluid
                    width={8}
                    selectOnBlur={false}
                    label="Progress Billing Needed"
                    value={this.state.budgetInd}
                    options={[
                      { key: "no", text: "No", value: 0 },
                      { key: "yes", text: "Yes", value: 1 },
                    ]}
                    name="budgetInd"
                    onChange={this.handleSelect}
                  />
                  <Form.Select
                    fluid
                    name="completedInd"
                    value={this.state.completedInd}
                    onChange={this.handleSelect}
                    selectOnBlur={false}
                    width={8}
                    label="Complete"
                    options={[
                      { key: "no", text: "No", value: 0 },
                      { key: "yes", text: "Yes", value: 1 },
                    ]}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Input
                    name="retention"
                    width={8}
                    icon="percent"
                    label="Retention"
                    type="number"
                    min="0"
                    max="100"
                    value={this.state.retention}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                  <DateInput
                    value={this.state.completedDate}
                    readOnly={true}
                    onChange={this.handleSelect}
                    hideMobileKeyboard
                    dateFormat={"MM/DD/YYYY"}
                    width={8}
                    iconPosition="right"
                    label="Completed Date"
                  />
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column tablet={8} computer={8} largeScreen={4} widescreen={4}>
              <Forecasting />
              <Flags />
              <ProjectTaskSpreadsheet projectID={this.state.currentProjectID} />
            </Grid.Column>
          </Grid.Row>
          {/* <Grid.Column style={{ width: '50%', minWidth: 480 }}> */}

          {/* </Grid.Column> */}
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // projects: state.project.projects,
    loggedIn: state.auth.loggedIn,
    hasRole: (role) => {
      if (
        state._user.current.Roles.some(
          (x) => x.Role.toUpperCase() === "SUPERUSER"
        )
      )
        return true;
      if (
        state._user.current.Roles.some(
          (x) => x.Role.toUpperCase() === role.toUpperCase()
        )
      )
        return true;
      return false;
    },
  };
};

export default withRouter(connect(mapStateToProps)(ProjectInfo));
