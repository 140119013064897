import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Checkbox } from "semantic-ui-react";

export default forwardRef((props, ref) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { filterOptions: options, column } = props;

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        return selectedOptions.indexOf(params.data[column.colId]) !== -1;
      },

      isFilterActive() {
        return selectedOptions.length > 0;
      },

      // this example isn't using getModel() and setModel(),
      // so safe to just leave these empty. don't do this in your code!!!
      getModel() {},

      setModel() {},
    };
  });

  const handleSelectAll = (event, { checked }) => {
    if (checked) setSelectedOptions(options);
    else setSelectedOptions([]);
  };

  const handleChange = (event, { checked, value }) => {
    if (checked) setSelectedOptions([...selectedOptions, value]);
    else setSelectedOptions(selectedOptions.filter((x) => x !== value));
  };

  useEffect(() => {
    props.filterChangedCallback();
  }, [selectedOptions]);

  return (
    <div
      style={{
        display: "inline-block",
        width: "350px",
        maxHeight: "300px",
        overflowX: "hidden",
        overflowY: "scroll",
        padding: "10px",
      }}
    >
      <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        <Checkbox
          label="All"
          style={{ fontWeight: "bold" }}
          onChange={handleSelectAll}
        />
      </div>
      {options.map((option, idx) => (
        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          <Checkbox
            label={option}
            value={option}
            key={idx}
            checked={selectedOptions.indexOf(option) !== -1}
            onChange={handleChange}
          />
        </div>
      ))}
    </div>
  );
});
