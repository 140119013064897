import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
// import "devextreme/dist/css/dx.common.css";
// import "devextreme/dist/css/dx.dark.css";

import { Message, Container, Grid, Card, Loader } from "semantic-ui-react";
// import YTDRevenue from "./YTDRevenue/YTDRevenue";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";

import Chart, {
  Label,
  Format,
  Series,
  CommonSeriesSettings,
  Legend,
} from "devextreme-react/chart";
import DataGrid from "devextreme-react/data-grid";
import PieChart, {
  Series as PieSeries,
  Label as PieLabel,
  Legend as PieLegend,
  Connector,
} from "devextreme-react/pie-chart";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Group = () => {
  let query = useQuery();
  let date = moment();
  const [year, setYear] = useState(parseInt(query.get("y")) || date.year());
  // const [quarter, setQuarter] = useState(query.get("q") || date.quarter());
  const [loading, setLoading] = useState(true);
  const [YTDdata, setYTDData] = useState(null);
  const [monthlySummaryData, setMonthlySummaryData] = useState(null);
  const [revenuePIVOT, setRevenuePIVOT] = useState(null);
  const [grossPIVOT, setGrossPIVOT] = useState(null);
  const [netPIVOT, setNetPIVOT] = useState(null);
  const [divisionSummary, setDivisionSummary] = useState(null);

  //   const year = parseInt(query.get("y")) || date.year();
  //   const quarter = query.get("q") || date.quarter();

  // useEffect(() => {
  //     if (quarter !== props.date.quarter) {
  //       setQuarter(props.date.quarter);
  //       //   console.log("hello quarter");
  //     }

  //     if (year !== props.date.year) {
  //       setYear(props.date.year);
  //       //   console.log("hello year");
  //     }
  //   }, [props]);

  useEffect(() => {
    getYTDData();
    getMonthlySummary();
    getMonthlySummaryPIVOT("RV");
    getMonthlySummaryPIVOT("GM");
    getMonthlySummaryPIVOT("NP");
    getDivisionSummary();
  }, [year]);

  useEffect(() => {
    setYear(query.get("y") || date.year());
  }, [query.get("y"), query.get("d")]);

  useEffect(() => {
    setLoading(false);
  }, [
    YTDdata,
    monthlySummaryData,
    revenuePIVOT,
    grossPIVOT,
    netPIVOT,
    divisionSummary,
  ]);

  const getYTDData = async () => {
    // console.log("getData");
    const response = await Axios.get(
      `${API_ROOT}/api/dashboard/company-summary?year=${year}`
    );

    setYTDData(response.data.data);
  };

  const getMonthlySummary = async () => {
    const response = await Axios.get(
      `${API_ROOT}/api/dashboard/company-summary-by-month?year=${year}`
    );

    setMonthlySummaryData(response.data.summary);
  };

  const getMonthlySummaryPIVOT = async (type) => {
    if (type === "RV") {
      let response = await Axios.get(
        `${API_ROOT}/api/dashboard/company-summary-by-month-pivot?year=${year}&type=RV`
      );

      setRevenuePIVOT(response.data.summary);
    } else if (type === "GM") {
      let response = await Axios.get(
        `${API_ROOT}/api/dashboard/company-summary-by-month-pivot?year=${year}&type=GM`
      );

      setGrossPIVOT(response.data.summary);
    } else if (type === "NP") {
      let response = await Axios.get(
        `${API_ROOT}/api/dashboard/company-summary-by-month-pivot?year=${year}&type=NP`
      );

      setNetPIVOT(response.data.summary);
    }
  };

  const getDivisionSummary = async () => {
    const response = await Axios.get(
      `${API_ROOT}/api/dashboard/division-summary?year=${year}`
    );

    setDivisionSummary(response.data.divisionSummary);
  };

  if (year < 2020 || year > date.year() + 1) {
    return <Message warning>That year is out of range</Message>;
  }

  return (
    <Container
      style={{
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 20,
        // backgroundColor: "#2a2a2a",
      }}
      fluid
    >
      <Grid stackable>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  YTD Revenue
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <div className="dx-viewport">
                    <Chart
                      animation={{ enabled: false }}
                      legend={{ visible: false }}
                      // theme="generic.dark"
                      dataSource={[
                        {
                          arg: "Goal",
                          goal: YTDdata ? YTDdata.RevGoal : null,
                        },
                        {
                          arg: "Act+Goal",
                          actgoal: revenuePIVOT
                            ? revenuePIVOT.find((x) => x.Item === "Act+Goal")
                                .Total
                            : null,
                        },
                        {
                          arg: "Pipeline",
                          pipeline: YTDdata ? YTDdata.RevForecast : null,
                        },
                        {
                          arg: "Act+Pipe",
                          actpipe: revenuePIVOT
                            ? revenuePIVOT.find((x) => x.Item === "Act+Pipe")
                                .Total
                            : null,
                        },
                        {
                          arg: "Actual",
                          actual: YTDdata ? YTDdata.RevActual : null,
                        },
                      ]}
                    >
                      <CommonSeriesSettings
                        barPadding={0}
                        type="bar"
                        hoverMode="allArgumentPoints"
                        selectionMode="allArgumentPoints"
                        barWidth={50}
                      >
                        <Label visible={true}>
                          <Format type="currency" />
                        </Label>
                      </CommonSeriesSettings>

                      <Series
                        name="Goal"
                        color="gray"
                        argumentField="arg"
                        valueField="goal"
                      />

                      <Series
                        name="Act+Goal"
                        color="#4a4949"
                        argumentField="arg"
                        valueField="actgoal"
                      />

                      <Series
                        label={{
                          font: {
                            color: "#000",
                            weight: 600,
                          },
                        }}
                        name="Pipeline"
                        color="#fff"
                        argumentField="arg"
                        valueField="pipeline"
                      />

                      <Series
                        name="Act+Pipe"
                        color="#ffa176"
                        argumentField="arg"
                        valueField="actpipe"
                      />

                      <Series
                        name="Actual"
                        color="rgb(254, 80, 0)"
                        argumentField="arg"
                        valueField="actual"
                      />
                    </Chart>
                  </div>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  YTD Gross Profit
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <div className="dx-viewport">
                    <Chart
                      animation={{ enabled: false }}
                      legend={{ visible: false }}
                      // theme="generic.dark"
                      dataSource={[
                        {
                          arg: "Goal",
                          goal: YTDdata ? YTDdata.GMGoal : null,
                        },
                        {
                          arg: "Act+Goal",
                          actgoal: grossPIVOT
                            ? grossPIVOT.find((x) => x.Item === "Act+Goal")
                                .Total
                            : null,
                        },
                        {
                          arg: "Pipeline",
                          pipeline: YTDdata ? YTDdata.GMForecast : null,
                        },
                        {
                          arg: "Act+Pipe",
                          actpipe: grossPIVOT
                            ? grossPIVOT.find((x) => x.Item === "Act+Pipe")
                                .Total
                            : null,
                        },
                        {
                          arg: "Actual",
                          actual: YTDdata ? YTDdata.GMActual : null,
                        },
                      ]}
                    >
                      <CommonSeriesSettings
                        barPadding={0}
                        argumentField="state"
                        type="bar"
                        hoverMode="allArgumentPoints"
                        selectionMode="allArgumentPoints"
                      >
                        <Label visible={true}>
                          <Format type="currency" />
                        </Label>
                      </CommonSeriesSettings>
                      <Series
                        width="33%"
                        name="Goal"
                        color="gray"
                        argumentField="arg"
                        valueField="goal"
                      />

                      <Series
                        name="Act+Goal"
                        color="#4a4949"
                        argumentField="arg"
                        valueField="actgoal"
                      />

                      <Series
                        label={{
                          font: {
                            color: "#000",
                            weight: 600,
                          },
                        }}
                        name="Pipeline"
                        color="#fff"
                        argumentField="arg"
                        valueField="pipeline"
                      />
                      <Series
                        name="Act+Pipe"
                        color="#ffa176"
                        argumentField="arg"
                        valueField="actpipe"
                      />
                      <Series
                        name="Actual"
                        color="rgb(254, 80, 0)"
                        argumentField="arg"
                        valueField="actual"
                      />
                    </Chart>
                  </div>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  YTD Net Profit
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <div className="dx-viewport">
                    <Chart
                      animation={{ enabled: false }}
                      legend={{ visible: false }}
                      // theme="generic.dark"
                      dataSource={[
                        {
                          arg: "Goal",
                          goal: YTDdata ? YTDdata.NetProfitGoal : null,
                        },
                        {
                          arg: "Act+Goal",
                          actgoal: netPIVOT
                            ? netPIVOT.find((x) => x.Item === "Act+Goal").Total
                            : null,
                        },
                        {
                          arg: "Pipeline",
                          pipeline: YTDdata ? YTDdata.NetProfitforecast : null,
                        },
                        {
                          arg: "Act+Pipe",
                          actpipe: netPIVOT
                            ? netPIVOT.find((x) => x.Item === "Act+Pipe").Total
                            : null,
                        },
                        {
                          arg: "Actual",
                          actual: YTDdata ? YTDdata.NetProfitActual : null,
                        },
                      ]}
                    >
                      <CommonSeriesSettings
                        barPadding={0}
                        argumentField="state"
                        type="bar"
                        hoverMode="allArgumentPoints"
                        selectionMode="allArgumentPoints"
                      >
                        <Label visible={true}>
                          <Format type="currency" />
                        </Label>
                      </CommonSeriesSettings>
                      <Series
                        width="33%"
                        name="Goal"
                        color="gray"
                        argumentField="arg"
                        valueField="goal"
                      />
                      <Series
                        name="Act+Goal"
                        color="#4a4949"
                        argumentField="arg"
                        valueField="actgoal"
                      />
                      <Series
                        label={{
                          font: {
                            color: "#000",
                            weight: 600,
                          },
                        }}
                        name="Pipeline"
                        color="#fff"
                        argumentField="arg"
                        valueField="pipeline"
                      />
                      <Series
                        name="Act+Pipe"
                        color="#ffa176"
                        argumentField="arg"
                        valueField="actpipe"
                      />
                      <Series
                        name="Actual"
                        color="rgb(254, 80, 0)"
                        argumentField="arg"
                        valueField="actual"
                      />
                    </Chart>
                  </div>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  Monthly Revenue
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <div className="dx-viewport">
                    <Chart
                      animation={{ enabled: false }}
                      // theme="generic.dark"
                      dataSource={monthlySummaryData ? monthlySummaryData : []}
                    >
                      <CommonSeriesSettings
                        argumentField="ShortMonth"
                        type="line"
                      >
                        <Label
                          visible={true}
                          backgroundColor="transparent"
                          overlappingBehavior="hide"
                        >
                          <Format type="currency" />
                        </Label>
                      </CommonSeriesSettings>
                      <Legend
                        verticalAlignment="bottom"
                        horizontalAlignment={"center"}
                      />
                      <Series name="Goal" color="gray" valueField="RevGoal" />

                      <Series
                        name="Pipeline"
                        color="#fff"
                        valueField="RevForecast"
                      />
                      <Series
                        name="Actual"
                        color="rgb(254, 80, 0)"
                        valueField="RevActual"
                      />
                      {/* <Tooltip
                        color="white"
                        font={{ color: "black" }}
                        format="currency"
                        enabled={true}
                        zIndex="5000"
                      /> */}
                    </Chart>
                    <DataGrid
                      dataSource={revenuePIVOT ? revenuePIVOT : []}
                      showBorders={true}
                      columns={[
                        "type",
                        { dataField: "Jan", format: "fixedPoint" },
                        { dataField: "Feb", format: "fixedPoint" },
                        { dataField: "Mar", format: "fixedPoint" },
                        { dataField: "Apr", format: "fixedPoint" },
                        { dataField: "May", format: "fixedPoint" },
                        { dataField: "Jun", format: "fixedPoint" },
                        { dataField: "Jul", format: "fixedPoint" },
                        { dataField: "Aug", format: "fixedPoint" },
                        { dataField: "Sep", format: "fixedPoint" },
                        { dataField: "Oct", format: "fixedPoint" },
                        { dataField: "Nov", format: "fixedPoint" },
                        { dataField: "Dec", format: "fixedPoint" },
                        { dataField: "Total", format: "fixedPoint" },
                      ]}
                    ></DataGrid>
                  </div>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  Monthly Gross Profit
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <div>
                    <Chart
                      animation={{ enabled: false }}
                      //   theme="generic.dark"
                      dataSource={monthlySummaryData ? monthlySummaryData : []}
                    >
                      <CommonSeriesSettings
                        argumentField="ShortMonth"
                        type="line"
                        hoverMode="allArgumentPoints"
                        selectionMode="allArgumentPoints"
                      >
                        <Label
                          visible={true}
                          backgroundColor="transparent"
                          overlappingBehavior="hide"
                        >
                          <Format type="currency" />
                        </Label>
                      </CommonSeriesSettings>
                      <Legend
                        verticalAlignment="bottom"
                        horizontalAlignment={"center"}
                      />
                      <Series name="Goal" color="gray" valueField="GMGoal" />
                      <Series
                        name="Pipeline"
                        color="#fff"
                        valueField="GMForecast"
                      />
                      <Series
                        name="Actual"
                        color="rgb(254, 80, 0)"
                        valueField="GMActual"
                      />
                      {/* <Tooltip
                        color="white"
                        font={{ color: "black" }}
                        format="currency"
                        enabled={true}
                        zIndex="5000"
                      /> */}
                    </Chart>
                    <DataGrid
                      dataSource={grossPIVOT ? grossPIVOT : []}
                      showBorders={true}
                      columns={[
                        "type",
                        { dataField: "Jan", format: "fixedPoint" },
                        { dataField: "Feb", format: "fixedPoint" },
                        { dataField: "Mar", format: "fixedPoint" },
                        { dataField: "Apr", format: "fixedPoint" },
                        { dataField: "May", format: "fixedPoint" },
                        { dataField: "Jun", format: "fixedPoint" },
                        { dataField: "Jul", format: "fixedPoint" },
                        { dataField: "Aug", format: "fixedPoint" },
                        { dataField: "Sep", format: "fixedPoint" },
                        { dataField: "Oct", format: "fixedPoint" },
                        { dataField: "Nov", format: "fixedPoint" },
                        { dataField: "Dec", format: "fixedPoint" },
                        { dataField: "Total", format: "fixedPoint" },
                      ]}
                    ></DataGrid>
                  </div>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  Monthly Net Profit
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <div>
                    <Chart
                      animation={{ enabled: false }}
                      theme="generic.dark"
                      dataSource={monthlySummaryData ? monthlySummaryData : []}
                    >
                      <CommonSeriesSettings
                        argumentField="ShortMonth"
                        type="line"
                        hoverMode="allArgumentPoints"
                        selectionMode="allArgumentPoints"
                      >
                        <Label
                          visible={true}
                          backgroundColor="transparent"
                          overlappingBehavior="hide"
                        >
                          <Format type="currency" />
                        </Label>
                      </CommonSeriesSettings>
                      <Legend
                        verticalAlignment="bottom"
                        horizontalAlignment={"center"}
                      />
                      <Series
                        name="Goal"
                        color="gray"
                        valueField="NetProfitGoal"
                      />
                      <Series
                        name="Pipeline"
                        color="#fff"
                        valueField="NetProfitforecast"
                      />
                      <Series
                        name="Actual"
                        color="rgb(254, 80, 0)"
                        valueField="NetProfitActual"
                      />
                      {/* <Tooltip
                        color="white"
                        font={{ color: "black" }}
                        format="currency"
                        enabled={true}
                        zIndex="5000"
                      /> */}
                    </Chart>
                    <DataGrid
                      dataSource={netPIVOT ? netPIVOT : []}
                      showBorders={true}
                      columns={[
                        "type",
                        { dataField: "Jan", format: "fixedPoint" },
                        { dataField: "Feb", format: "fixedPoint" },
                        { dataField: "Mar", format: "fixedPoint" },
                        { dataField: "Apr", format: "fixedPoint" },
                        { dataField: "May", format: "fixedPoint" },
                        { dataField: "Jun", format: "fixedPoint" },
                        { dataField: "Jul", format: "fixedPoint" },
                        { dataField: "Aug", format: "fixedPoint" },
                        { dataField: "Sep", format: "fixedPoint" },
                        { dataField: "Oct", format: "fixedPoint" },
                        { dataField: "Nov", format: "fixedPoint" },
                        { dataField: "Dec", format: "fixedPoint" },
                        { dataField: "Total", format: "fixedPoint" },
                      ]}
                    ></DataGrid>
                  </div>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  Divisional Revenue
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <div className="dx-viewport">
                    <Chart
                      animation={{ enabled: false }}
                      rotated={true}
                      // theme="generic.dark"
                      dataSource={divisionSummary ? divisionSummary : []}
                    >
                      <CommonSeriesSettings
                        argumentField="Division"
                        type="bar"
                        hoverMode="allArgumentPoints"
                        selectionMode="allArgumentPoints"
                      >
                        <Label visible={true}>
                          <Format type="currency" />
                        </Label>
                      </CommonSeriesSettings>
                      <Legend
                        verticalAlignment="bottom"
                        horizontalAlignment={"center"}
                      />
                      <Series
                        label={{
                          backgroundColor: "transparent",
                        }}
                        width="33%"
                        name="Goal"
                        color="gray"
                        valueField="RevGoal"
                      />
                      <Series
                        label={{
                          font: {
                            color: "gray",
                            weight: 600,
                          },
                          backgroundColor: "transparent",
                        }}
                        name="Pipeline"
                        color="#fff"
                        valueField="RevForecast"
                      />
                      <Series
                        name="Actual"
                        color="rgb(254, 80, 0)"
                        valueField="RevActual"
                        label={{
                          backgroundColor: "transparent",
                        }}
                      />
                    </Chart>
                  </div>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  Divisional Gross Margin
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <div className="dx-viewport">
                    <Chart
                      animation={{ enabled: false }}
                      rotated={true}
                      // theme="generic.dark"
                      dataSource={divisionSummary ? divisionSummary : []}
                    >
                      <CommonSeriesSettings
                        argumentField="Division"
                        type="bar"
                        hoverMode="allArgumentPoints"
                        selectionMode="allArgumentPoints"
                      >
                        <Label visible={true}>
                          <Format type="currency" />
                        </Label>
                      </CommonSeriesSettings>
                      <Legend
                        verticalAlignment="bottom"
                        horizontalAlignment={"center"}
                      />
                      <Series
                        label={{
                          backgroundColor: "transparent",
                        }}
                        width="33%"
                        name="Goal"
                        color="gray"
                        type="bar"
                        // argumentField="arg"
                        valueField="GMGoal"
                      />
                      <Series
                        name="Pipeline"
                        color="#fff"
                        type="bar"
                        // argumentField="arg"
                        valueField="GMForecast"
                        label={{
                          backgroundColor: "transparent",
                          font: {
                            color: "gray",
                            weight: 600,
                          },
                        }}
                      />
                      <Series
                        label={{
                          backgroundColor: "transparent",
                        }}
                        name="Actual"
                        color="rgb(254, 80, 0)"
                        type="bar"
                        // argumentField="arg"
                        valueField="GMActual"
                      />
                    </Chart>
                  </div>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  Divisional Net Profit
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <div className="dx-viewport">
                    <Chart
                      animation={{ enabled: false }}
                      rotated={true}
                      // theme="generic.dark"
                      dataSource={divisionSummary ? divisionSummary : []}
                    >
                      <CommonSeriesSettings
                        argumentField="Division"
                        type="bar"
                        hoverMode="allArgumentPoints"
                        selectionMode="allArgumentPoints"
                      >
                        <Label visible={true}>
                          <Format type="currency" />
                        </Label>
                      </CommonSeriesSettings>
                      <Legend
                        verticalAlignment="bottom"
                        horizontalAlignment={"center"}
                      />
                      <Series
                        label={{
                          backgroundColor: "transparent",
                        }}
                        width="33%"
                        name="Goal"
                        color="gray"
                        type="bar"
                        valueField="NetProfitGoal"
                      />
                      <Series
                        label={{
                          backgroundColor: "transparent",
                          font: {
                            color: "gray",
                            weight: 600,
                          },
                        }}
                        name="Pipeline"
                        color="#fff"
                        type="bar"
                        valueField="NetProfitforecast"
                      />
                      <Series
                        label={{
                          backgroundColor: "transparent",
                        }}
                        name="Actual"
                        color="rgb(254, 80, 0)"
                        type="bar"
                        valueField="NetProfitActual"
                      />
                    </Chart>
                  </div>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  YTD Revenue by Division
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <div className="dx-viewport">
                    <PieChart
                      animation={{ enabled: false }}
                      customizePoint={(pointInfo) => {
                        if (pointInfo.argument === "Construction") {
                          return {
                            color: "rgb(254, 80, 0)",
                          };
                        } else if (pointInfo.argument === "Service") {
                          return {
                            color: "gray",
                          };
                        } else if (pointInfo.argument === "Mechanical") {
                          return {
                            color: "white",
                          };
                        }
                      }}
                      customizeLabel={(labelInfo) => {
                        if (labelInfo.argument === "Mechanical") {
                          return { font: { color: "#000", weight: 600 } };
                        }
                      }}
                      dataSource={divisionSummary}
                    >
                      <PieSeries
                        argumentField="Division"
                        valueField="RevActual"
                      >
                        <PieLabel visible={true}>
                          <Connector visible={true} width={1} />
                          <Format type="currency" />
                        </PieLabel>
                      </PieSeries>
                      <PieLegend
                        verticalAlignment="bottom"
                        horizontalAlignment={"center"}
                      />
                    </PieChart>
                  </div>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  YTD Gross Profit by Division
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <div>
                    <PieChart
                      animation={{ enabled: false }}
                      customizePoint={(pointInfo) => {
                        if (pointInfo.argument === "Construction") {
                          return {
                            color: "rgb(254, 80, 0)",
                          };
                        } else if (pointInfo.argument === "Service") {
                          return {
                            color: "gray",
                          };
                        } else if (pointInfo.argument === "Mechanical") {
                          return {
                            color: "white",
                          };
                        }
                      }}
                      customizeLabel={(labelInfo) => {
                        if (labelInfo.argument === "Mechanical") {
                          return { font: { color: "#000", weight: 600 } };
                        }
                      }}
                      dataSource={divisionSummary}
                    >
                      <PieSeries argumentField="Division" valueField="GMActual">
                        <PieLabel visible={true}>
                          <Connector visible={true} width={1} />
                          <Format type="currency" />
                        </PieLabel>
                      </PieSeries>
                      <PieLegend
                        verticalAlignment="bottom"
                        horizontalAlignment={"center"}
                      />
                    </PieChart>
                  </div>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid style={{ background: "#2a2a2a" }}>
              <Card.Content>
                <Card.Header textAlign="center" style={{ color: "#FFFFFF" }}>
                  YTD Net Profit by Division
                </Card.Header>
              </Card.Content>
              <Card.Content style={{ minHeight: 300, background: "#2a2a2a" }}>
                {loading ? (
                  <Loader active size="big" />
                ) : (
                  <div>
                    <PieChart
                      animation={{ enabled: false }}
                      customizePoint={(pointInfo) => {
                        if (pointInfo.argument === "Construction") {
                          return {
                            color: "rgb(254, 80, 0)",
                          };
                        } else if (pointInfo.argument === "Service") {
                          return {
                            color: "gray",
                          };
                        } else if (pointInfo.argument === "Mechanical") {
                          return {
                            color: "white",
                          };
                        }
                      }}
                      customizeLabel={(labelInfo) => {
                        if (labelInfo.argument === "Mechanical") {
                          return { font: { color: "#000", weight: 600 } };
                        }
                      }}
                      dataSource={divisionSummary}
                    >
                      <PieSeries
                        argumentField="Division"
                        valueField="NetProfitActual"
                      >
                        <PieLabel visible={true}>
                          <Connector visible={true} width={1} />
                          <Format type="currency" />
                        </PieLabel>
                      </PieSeries>
                      <PieLegend
                        verticalAlignment="bottom"
                        horizontalAlignment={"center"}
                      />
                    </PieChart>
                  </div>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default Group;
