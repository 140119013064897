import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import ReactExport from "react-export-excel";
import moment from "moment";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";
import useIsMounted from "../../../hooks/isMounted";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const SuperintendentReport = ({ division, year }) => {
  const [dataSet, setDataSet] = useState([]);

  const isMounted = useIsMounted();

  useEffect(() => {
    Axios.get(
      `${API_ROOT}/api/dashboard/superintendent-pipeline-report?year=${year}&division=${division}`
    )
      .then((res) => {
        if (isMounted.current && res.data) {
          res.data.forEach((item, index) => {
            if (item.JobName === "TOTAL") {
              res.data.splice(index + 1, 0, {
                TeamMember: null,
                Job: null,
                JobName: null,
                Jan: null,
                Feb: null,
                Mar: null,
                Apr: null,
                May: null,
                Jun: null,
                Jul: null,
                Aug: null,
                Sep: null,
                Oct: null,
                Nov: null,
                Dec: null,
                Total: null,
              });
            }
          });
          setDataSet(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, [year, division]);

  return Array.isArray(dataSet) && dataSet.length ? (
    <ExcelFile
      filename={`Superintendent Pipeline Report ${moment(new Date()).format(
        "MM-DD-YYYY"
      )}`}
      element={<Icon link color="blue" name="cloud download" />}
    >
      <ExcelSheet data={dataSet} name="Pipeline Report">
        <ExcelColumn label="Team Member" value="TeamMember" />
        <ExcelColumn label="Project ID" value="Job" />
        <ExcelColumn label="Project Name" value="JobName" />
        <ExcelColumn label="Jan" value="Jan" />
        <ExcelColumn label="Feb" value="Feb" />
        <ExcelColumn label="Mar" value="Mar" />
        <ExcelColumn label="Apr" value="Apr" />
        <ExcelColumn label="May" value="May" />
        <ExcelColumn label="Jun" value="Jun" />
        <ExcelColumn label="Jul" value="Jul" />
        <ExcelColumn label="Aug" value="Aug" />
        <ExcelColumn label="Sep" value="Sep" />
        <ExcelColumn label="Oct" value="Oct" />
        <ExcelColumn label="Nov" value="Nov" />
        <ExcelColumn label="Dec" value="Dec" />
        <ExcelColumn label="Total" value="Total" />
      </ExcelSheet>
    </ExcelFile>
  ) : null;
};

export default SuperintendentReport;
