import React, { useEffect, useState } from "react";
import TeamMemberBonusPool from "./TeamMemberBonusPool";
import { useSelector } from "react-redux";
import Axios from "axios";
import { API_ROOT } from "../../../api-config";

const TeamMemberBonusPoolWrapper = () => {
  const { year, quarter } = useSelector((state) => state.teamMemberBonusPool);
  const [leadershipIncentiveData, setLeadershipIncentiveData] = useState(null);
  const [positionIncentiveData, setPositionIncentiveData] = useState(null);

  const fetchLeadershipIncentive = () => {
    Axios.get(`${API_ROOT}/api/kpi/leadership-incentive`, {
      params: {
        year,
        quarter,
      },
    })
      .then((res) => {
        if (res.data.leadershipIncentive) {
          setLeadershipIncentiveData(res.data.leadershipIncentive);
        } else {
          setLeadershipIncentiveData(null);
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchPositionIncentive = () => {
    Axios.get(`${API_ROOT}/api/kpi/position-incentive`, {
      params: {
        year,
        quarter,
      },
    })
      .then((res) => {
        if (res.data.positionIncentive) {
          setPositionIncentiveData(res.data.positionIncentive);
        } else {
          setPositionIncentiveData(null);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (year && quarter) {
      // console.log(year, quarter);
      fetchLeadershipIncentive();
      fetchPositionIncentive();
    }
  }, [year, quarter]);

  return (
    <TeamMemberBonusPool
      leadershipIncentiveData={leadershipIncentiveData}
      positionIncentiveData={positionIncentiveData}
      onAddNewLeader={fetchLeadershipIncentive}
    />
  );
};

export default TeamMemberBonusPoolWrapper;
