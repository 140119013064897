let backEndHost;

const hostname = window && window.location && window.location.hostname;

if (hostname === "nexsys1-staging.azurewebsites.net") {
  backEndHost = "https://nexsys1api-staging.azurewebsites.net";
} else if (hostname === "ns1.nexus5group.com") {
  backEndHost = "https://api.nexus5group.com";
} else if (hostname === "nexsys1.azurewebsites.net") {
  backEndHost = "https://nexsys1api.azurewebsites.net";
} else {
  // backEndHost = 'https://nexsys1api-staging.azurewebsites.net';
  backEndHost = "http://localhost:5000";
}

export const API_ROOT = `${backEndHost}`;
