import React, { Fragment, useEffect, useState } from "react";
import { Dropdown, MenuItem, Icon } from "semantic-ui-react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  setCompanyGoalsMenuSelection,
  resetCompanyGoalsMenuSelection,
} from "../../../../actions/topMenu/companyGoalsMenuActions";
import Axios from "axios";
import { API_ROOT } from "../../../../api-config";
// import Axios from "axios";
// import { API_ROOT } from "../../../../api-config";

const START_YEAR = 2017;
let date = moment();
let currentYear = date.year();

function generate_year_range(start, end) {
  var years = [];
  for (var year = start; year <= end; year++) {
    years.push(year);
  }
  return years;
}

const CompanyGoalsItems = (props) => {
  const [loading, setLoading] = useState(false);
  const year = useSelector((state) => state.companyGoalsMenu.year);
  const dispatch = useDispatch();

  var my_years = generate_year_range(START_YEAR, currentYear + 1).sort(
    (a, b) => b - a
  );

  const optionsArrays = my_years.map((y) => {
    return { key: y, text: y, value: y };
  });

  useEffect(() => {
    dispatch(resetCompanyGoalsMenuSelection());
  }, []);

  const options = [].concat(...optionsArrays);

  const handleDateChange = (data) => {
    dispatch(setCompanyGoalsMenuSelection(data.value));
  };

  const handleRecalculateClick = () => {
    setLoading(true);
    Axios.get(`${API_ROOT}/api/acumatica/project-actuals`)
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          alert("Dashboard has been recalculated");
        }, 7000);

        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        alert("An error occurred while recalculating the dashboard");
        console.log(err);
      });
  };

  return (
    <Fragment>
      <Dropdown
        scrolling
        selectOnBlur={false}
        text={`Showing ${year}`}
        value={year}
        item
        options={options}
        onChange={(e, data) => handleDateChange(data)}
      />
      <MenuItem as="a" onClick={handleRecalculateClick}>
        <Icon loading={loading} name={loading ? "spinner" : "cogs"} />
        Recalculate Dashboard
      </MenuItem>
    </Fragment>
  );
};

export default CompanyGoalsItems;
