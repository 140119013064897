import React, { useContext, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { Message, Icon, Button } from "semantic-ui-react";

import useCurrentUser from "../../hooks/useCurrentUser";
import useQuery from "../../hooks/useQuery";
import CurrentProject from "../../screens/projects/CurrentProject";

import "./VersionBanner.css";

export default () => {
  const [enableV1, setEnableV1] = useState(false);
  const { loading, useV2 } = useContext(CurrentProject);
  const searchParams = useQuery();
  const history = useHistory();
  const location = useLocation();
  const user = useCurrentUser();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setEnableV1(["1", "3", "4"].includes(params.get("i")));
  }, [location.search]);

  const showV1 = () =>
    useV2 &&
    searchParams.has("showv1") &&
    searchParams.get("showv1") === "true";

  const toggleVersions = () => {
    const params = new URLSearchParams(location.search.toLocaleLowerCase());
    showV1() ? params.delete("showv1") : params.append("showV1", "true");
    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  if (loading || !user) return null;

  return (
    <Message info hidden={!useV2()}>
      {enableV1 && user.hasRole("Accounting") && (
        <div className="showv1">
          <Button color="blue" size="mini" onClick={toggleVersions}>
            {showV1() && "Hide V1"}
            {!showV1() && "Show V1"}
          </Button>
        </div>
      )}
      <Message.Header style={{ textAlign: "right" }}>
        <Icon name="info circle" />
        Using updated contract workflow and KRT.
      </Message.Header>
    </Message>
  );
};
