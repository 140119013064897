import React from "react";
import { getQueryStringValue } from "../../util";
import NewConditionApproval from "./NewConditionApproval/NewConditionApproval";
import ConditionOverwriteApproval from "./ConditionOverwriteApproval/ConditionOverwriteApproval";

const ConditionApproval = () => {
  const type = getQueryStringValue("t");

  return type === "o" ? (
    <ConditionOverwriteApproval />
  ) : (
    <NewConditionApproval />
  );
};

export default ConditionApproval;
